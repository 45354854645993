/*
 * NOTES:
 * haveFile prop is to manage the internal styles and text changes, please update it when the success is called and send
 * it when the orchard list is rendered.

 * @usage
 *
 * import DocumentUpload from './path/to/DocumentUpload'
 * {!loading && <DocumentUpload onLoading={(state) => setLoading(state)} path="orchard/audit_report/1" haveFile={boolState} />}
 *
 */
import { bool, func, number, string } from 'prop-types';
import PrettyBytes from 'pretty-bytes';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import utilsApi from '../../utils/api/UtilsApi';
import Portal from '../../lib/Portal';
import Message from '../Message';

const DocumentUpload = ({
  acceptFiles = '.png',
  haveFile = false,
  showConfirmationDialog = false,
  maxFileSize = 1000000,
  path,
  onSuccess,
  onError,
  onLoading,
}) => {
  const [renderOverrideMessage, setRenderOverrideMessage] = useState(false);
  const inputRef = useRef(undefined);
  const { t } = useTranslation(['documentUpload', 'Message']);

  const uploadDocument = async function (file) {
    const formData = new FormData();

    onLoading(true);
    formData.append('file', file);
    formData.append('filename', file.name);

    try {
      const res = await utilsApi.uploadDocument(path, formData);
      if (res.status === 200) onSuccess(res);
    } catch (error) {
      onError();
    } finally {
      onLoading(false);
    }
  };

  const handleMessageAction = (action) => {
    if (action) uploadDocument(inputRef.current.files[0]);
    setRenderOverrideMessage(false);
    inputRef.current.value = null;
  };

  const fileInputOnChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (maxFileSize && file.size > maxFileSize) {
      inputRef.current.value = null;
      return onError({ message: t('maxFileSizeMessage', { size: PrettyBytes(maxFileSize) }) });
    }

    if (showConfirmationDialog) {
      return setRenderOverrideMessage(true);
    }

    uploadDocument(file);
    inputRef.current.value = null;
  };

  return (
    <div className="document-upload">
      {renderOverrideMessage && (
        <Portal>
          <Message
            acceptText={t('Message:acceptUpdateFile')}
            description={t('Message:updateFileDescription')}
            handleMessage={handleMessageAction}
            title={t('Message:updateFileTitle')}
            type="confirm"
          />
        </Portal>
      )}
      <label
        className={`document-upload__label fk-button button-small dib ${
          haveFile ? 'button-primary-main' : 'button-secondary-main'
        }`}
      >
        {haveFile ? t('documentUpload:update') : t('documentUpload:uploadFile')}
        <input accept={acceptFiles} className="dn" onChange={fileInputOnChange} ref={inputRef} type="file" />
      </label>
    </div>
  );
};

DocumentUpload.propTypes = {
  acceptFiles: string,
  haveFile: bool,
  showConfirmationDialog: bool,
  maxFileSize: number,
  onError: func.isRequired,
  onLoading: func.isRequired,
  onSuccess: func.isRequired,
  path: string.isRequired,
};

export default DocumentUpload;
