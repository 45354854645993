import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../components/DatePicker';
import ProductStorage from './../../../views/Content/Market/ProductStorage/ProductStorage';
import Retail from '../../../views/Content/Market/Retail/Retail';
import ShippingPoint from '../../../views/Content/Market/USDAPrices/ShippingPoint';
import ShippingPointBehavior from '../../../views/Content/Market/ShippingPointBehavior/ShippingPointBehavior';
import USDAVolume from '../../../views/Content/Market/USDAVolume/UsdaVolume';
import UsdaPrices from '../../../views/Content/Market/USDAPrices/UsdaPrices';
import VolumeBehavior from '../../../views/Content/Market/USDAVolume/Table/VolumeBehavior';
import Weather from '../../../views/Content/Market/Weather/Weather';
import { getCurrentDateAction } from '../../../redux/actions/currentDate/actions';
import {
  getProductsAction,
  setActiveProductAction,
  getProductDetailAction,
} from '../../../redux/actions/products/actions';
import { setSelectedDateAction } from '../../../redux/actions/selectedDate/actions';
import marketHeader from '../../../utils/page_definition/market_header';
import UtilsApi from '../../../utils/api/UtilsApi';
import WebStorage from '../../../utils/WebStorage/WebStorage';
import ProductIcon from '../../../utils/product/ProductIcons';
import { GetSelectedWeek, formatHumanDate } from '../../../utils/common/DateHandler';
import { WarningIcon } from '../../../assets/icons/assets';
import './Market.scss';

const Market = () => {
  const { t } = useTranslation(['marketInformation', 'general']);
  const dispatch = useDispatch();

  const { getCookieValue } = WebStorage;
  const localeID = getCookieValue('localeId');

  const [dateState, setDateState] = useState(new Date());
  const [isActiveDT, setIsActiveDT] = useState(false);

  const productList = useSelector((state) => state.products.productList);
  const productActive = useSelector((state) => state.products.active);
  const currentDate = useSelector((state) => state.currentDate.currentDate);
  const selectedDate = useSelector((state) => state.selectedDate.date);
  const activeProduct = useSelector((state) => state.products.active);

  useEffect(() => {
    const CancelToken = UtilsApi.cancelToken();
    dispatch(getProductsAction(CancelToken.token));

    return () => {
      CancelToken.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    const CancelToken = UtilsApi.cancelToken();
    dispatch(getProductDetailAction(CancelToken.token));

    return function () {
      CancelToken.cancel();
    };
  }, [activeProduct, selectedDate, dispatch]);

  useEffect(() => {
    if (productList && productList.length) dispatch(setActiveProductAction(productList[0].abbr));
  }, [productList, dispatch]);

  useEffect(() => {
    if (!currentDate) dispatch(getCurrentDateAction());
  }, [currentDate, dispatch]);

  // Set selectedDate to given value
  const pickADate = useCallback(
    (value) => {
      dispatch(setSelectedDateAction(value));
    },
    [dispatch],
  );

  // reset selectedDate to initial value
  const resetDate = () => {
    pickADate(null);
    setDateState(new Date());
  };

  useEffect(() => {
    const checkSelect = () => {
      if (currentDate) {
        const selectedDateFormated = new Date(dateState).toISOString().substring(0, 10).replace(/-/g, '');
        const currentDateFormated = currentDate.date.substring(0, 10).replace(/-/g, '');
        if (currentDateFormated > selectedDateFormated) pickADate(dateState);
      }
    };
    checkSelect();
  }, [dateState, currentDate, pickADate]);

  // TODO - check if selectedDate is different from currentDate
  const currentDateBanner = () =>
    selectedDate ? (
      <div className="market-header-different-date-container" data-test={marketHeader.marketDifferentContainer}>
        <div className="market-header-different-date">
          <WarningIcon styleClass="market-header-warning-icon" fillColor="#E0B515" />
          <p data-test={marketHeader.weekDifferentDescription}>
            {`${t('marketInformation:diffWeekMsg1')}: `}
            <span className="market-header-remark-date">
              {t('general:date', { date: formatHumanDate(selectedDate, localeID) })}
            </span>
            {` ${t('marketInformation:diffWeekMsg2')} `}
            <span className="market-header-remark-date">
              {t('general:date', { date: GetSelectedWeek(selectedDate, localeID) })}
            </span>
            <button className="market-header-reset-button" data-test={marketHeader.resetDateButton} onClick={resetDate}>
              {t('reset')}
            </button>
          </p>
        </div>
      </div>
    ) : (
      <div className={`market-header-date-container market-header-date-picker-${isActiveDT}`}>
        <div className="market-header-date">
          <DatePicker dateState={dateState} setDateState={setDateState} setIsActive={setIsActiveDT} />
          <b />
          <p className="market-header-week-description" data-test={marketHeader.weekDescription}>
            {t('marketInformation:currWeek')}
            {currentDate && (
              <b>{`${t('marketInformation:diffWeekMsg2')} ${t('general:date', {
                date: GetSelectedWeek(dateState, localeID),
              })}`}</b>
            )}
          </p>
        </div>
      </div>
    );

  return (
    <div className="market-container ">
      <div className="market-header" data-test={marketHeader.marketHeaderContainer}>
        <div className="market-header__top">
          <div className="market-header__product-oval">{ProductIcon(productActive)}</div>
          <h2 className="market-header-title">{t('title')}</h2>
        </div>
        <div className="market-header__bottom">{currentDateBanner()}</div>
      </div>
      <div className="market-content">
        <ProductStorage />
        <UsdaPrices />
        <hr className="dashboard-divisor" />
        <ShippingPoint />
        <ShippingPointBehavior />
        <hr className="dashboard-divisor" />
        <USDAVolume />
        <VolumeBehavior />
        <hr className="dashboard-divisor" />
        <Retail />
        <hr className="dashboard-divisor" />
        <Weather />
      </div>
    </div>
  );
};

export default Market;
