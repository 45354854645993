import { useState, useEffect } from 'react';

/*
 * use program timer
 * @param {string} baseTimer iso date to calculate the interval milliseconds
 * @param {number} programType 1 is normal program, 2 spot program.
 * @return {string} timer.
 */
const useProgramTimer = (baseTimer) => {
  let baseMinutes = baseTimer;
  const [timer, setTimer] = useState('');

  const calculateLabel = () => {
    const hours = baseMinutes / 60;
    const minutes = baseMinutes - Math.trunc(hours) * 60;
    let label = false;
    if (minutes > 0 || hours > 0) label = `${Math.trunc(minutes)} min`;
    if (hours > 0) label = `${Math.trunc(hours)} hr ${label}`;
    return label;
  };

  useEffect(() => {
    let label = calculateLabel();
    setTimer(label);
    const interval = setInterval(() => {
      baseMinutes = baseMinutes - 0.5;
      label = calculateLabel();
      if (!label) clearInterval(interval);
      setTimer(label);
    }, 1000 * 30);

    return () => clearInterval(interval);
  }, []);

  return timer;
};

export default useProgramTimer;
