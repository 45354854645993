import { oneOf, string } from 'prop-types';
import React from 'react';
import './style.scss';

const Badge = ({ className = '', type, text, dataTest }) => {
  if (type) className += ` badge--${type}`;

  return (
    <span className={`badge py-s px-base fs-6 fw700 br-m wsnw ${className}`} data-test={dataTest}>
      {text}
    </span>
  );
};

Badge.propTypes = {
  className: string,
  type: oneOf(['dark', 'success', 'warning', 'danger']),
  text: string.isRequired,
  dataTest: string,
};

export default Badge;
