import switchCaseHandler from '../common/switch';

/*
 * error dictionary
 * @param {number} status for error code
 * @return {string} to manage i18n
 */
const errorDictionary = (status) => {
  return switchCaseHandler({
    101: () => 'notProducerMember',
    102: () => 'producerNotValidated',
    103: () => 'producerAlreadyCommitedProgram',
    104: () => 'programNotFound',
    105: () => 'boxesOverLimit',
    106: () => 'destinationNotFound',
    107: () => 'commitmentDetailNeeded',
    110: () => 'invalidPaylod',
    111: () => 'generalError',
  })('generalError')(status);
};

export default errorDictionary;
