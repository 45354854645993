import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import MainLayout from './views/MainLayout';
import WebStorage from './utils/WebStorage/WebStorage';
import { useTranslation } from 'react-i18next';
import './App.scss';

const { getCookieValue, setCookie } = WebStorage;

function App() {
  const { i18n } = useTranslation();
  const localeID = getCookieValue('localeId') || 'es';

  const handleLocaleId = () => {
    setCookie('localeId', localeID, 99999999999999);
  };

  useEffect(() => {
    i18n.changeLanguage(localeID, () => handleLocaleId());
  }, []);

  return (
    <div className="App">
      <Toaster containerStyle={{ top: 28 }} />
      <MainLayout />
    </div>
  );
}

export default App;
