import React from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'components/Tab';
import shippingPointSelectors from 'utils/page_definition/shipping_point';
import VolumeAndPrices from './Graphs/VolumeAndPrices/';
import './UsdaPrices.scss';

const UsdaPrices = () => {
  const { t } = useTranslation('usdaPrices');

  const graphOptions = {
    volumePrices: function VolumePricesCase() {
      return <VolumeAndPrices />;
    },
  };

  const optionsTitle = {
    0: t('volumePrices'),
  };

  const buttonOrder = ['volumePrices'];

  /**
   * Renders all buttons according tto `buttonOrder` array
   */
  const renderButtons = () => (
    <div className="usda-prices-buttons">
      <Tab optionsObject={graphOptions} optionsOrder={buttonOrder} optionsTitle={optionsTitle} />
    </div>
  );

  return (
    <div data-test={shippingPointSelectors.shippingContainer} className="usda-prices-container">
      <div className="usda-prices-header">
        <div className="usda-prices-header-wrapper">
          <h3 className="usda-prices-header-title">{t('title')}</h3>
          <p className="usda-prices-header-beta">BETA</p>
        </div>
      </div>
      <p className="usda-prices-description">{t('paragraph')}</p>
      {renderButtons()}
    </div>
  );
};

export default UsdaPrices;
