import { demandES } from './dynamic/demandTone';
import { marketES } from './dynamic/marketTone';
import { SPECIAL_CHARACTERS } from './../../constants/validations';

export default {
  loader: {
    loading: 'Cargando la información',
  },
  general: {
    contactTeam: 'contacta al equipo de FRIDA',
    date: '{{date}}',
    no: 'no',
    yes: 'si',
  },
  select: {
    selectOption: 'Selecciona',
  },
  fruits: {
    'blkberi-v': 'Zarzamora',
    Asparagus: 'Espárragos',
    Blackberries: 'Zarzamora',
    Blueberries: 'Arándanos',
    Raspberries: 'Frambuesas',
    Strawberries: 'Fresas',
    asp: 'Espárragos',
    bluby: 'Arándanos',
    rasp: 'Frambuesas',
    strby: 'Fresas',
  },
  usdaPrices: {
    title: 'Precios shipping point (USDA)',
    paragraph:
      'Revisa precios y/o volúmenes diarios y semanales. Puedes filtrarlos por ubicación, tipo de fruta y tipo de empaque.',
    volumePrices: 'Volumen y precios',
    volumeLocation: 'Volumen',
    priceLocation: 'Precio',
  },
  average: {
    graphDescription:
      'Precios máximos y mínimos por día. Puedes compararlos con el promedio de la temporada pasada y el promedio de los últimos 5 años',
    priceRange: 'Rango de precios de mayor a menor',
    lastSeasonAvg: 'Precio promedio temporada pasada',
    lastFiveAvg: 'Precio promedio últimos 5 años',
  },
  volume: {
    title: 'Volumen (USDA)',
    description:
      'Revisa el volumen diario reportado. Puedes analizarlo por cruce y tipo de fruta por día o por semana comparando la temporada actual, la temporada pasada y el volumen que calculamos usando nuestro algorítmo. También puedes comparar entre varias variables en una sóla gráfica por día o por semana.',
    graphDescription:
      'Este gráfico muestra las 52 semanas para que puedas tener una imagen completa del comportamiento de volúmen.',
    btnWeekly: 'Semanalmente',
    btnDaily: 'Diariamente',
    btnWeeklyLoc: 'Semanalmente entre variables',
    btnDailyLoc: 'Diariamente entre variables',
    selConventional: 'Convencional',
    selOrganic: 'Orgánica',
    selAll: 'Todos',
    currSeason: 'Actual temporada',
    lastSeason: 'Última temporada',
    forecast: 'Pronóstico de Frida',
    previousWeeks: 'Pronóstico semanas anteriores',
    range: 'Rango de Frida',
    pkgUnit: 'Cajas de 4.5 lbs',
    fridaPrevious: 'Pronóstico semanas anteriores',
    weeklyXAxis: 'Número de semana',
    week: 'Semana',
  },
  volumeBehavior: {
    calculate: '¿Cómo se calcula?',
    last15Days: 'últimos 15 días ',
    lastWeek: 'La semana pasada',
    lastYear: 'El año pasado',
    latestUpdate: 'Última actualización:',
    percentCalculate1: 'El porcentaje mostrado se calcula utilizando los ',
    percentCalculate2: 'de información',
    ttCalculate:
      'Movimiento reportado por USDA de la semana actual en comparación con datos del mismo día de la semana pasada.',
    volBehaviorTitle: 'Comportamiento volúmen',
    volDetailTitle: 'Detalle volúmen',
  },
  locations: {
    graphDescription: 'Compara el precio entre diferentes variables.',
  },
  selectCascaderContainer: {
    applyButton: 'Aplicar filtros',
    clearButton: 'Limpiar',
  },
  selectCascader: {
    all: 'TODO',
    conventional: 'CONVENCIONAL',
    entireMarket: 'TODO EL MERCADO',
    location: 'Ubicación',
    organic: 'ORGÁNICO',
    packageType: 'Tipo de paquete',
    type: 'Tipo',
  },
  shippingPoint: {
    averageButton: 'Entre periodos',
    locationButton: 'Entre variables',
    paragraph: 'Revisa precios diarios reportados. Puedes filtrarlos por ubicación, tipo de fruta y tipo de empaque.',
    title: 'Precios shipping point (USDA)',
  },
  shippingPointBehavior: {
    calculateMsg: 'Este cálculo se hizo utilizando el movimiento de {{commodity}} en el cruce de',
    daysAgo: 'días',
    demand: 'Demanda',
    dynamic: { ...demandES, ...marketES },
    headerTitle: 'Comportamiento de precios shipping point',
    highest: 'Precio más alto',
    latestUpdate: 'Última actualización:',
    lowest: 'Precio más bajo',
    market: 'Mercado',
    priceVersus: 'Precio vs',
    tablePrice: 'precio',
  },
  retail: {
    conventionalLabel: 'Convencional',
    headerDescription: 'Precios al por menor por semana de fruta orgánica y convencional.',
    headerTitle: 'Precios al por menor (USDA)',
    organicLabel: 'Orgánico',
    week: 'Semana',
    weekNumber: 'Número de semana',
    yaxisLabel: 'precios al por menor en USD',
  },
  tableFooter: {
    aboutSteady: 'TBD',
    conventionalModerateOrganicVeryGood: 'TBD',
    demandLabel: 'Demanda',
    fairlyLight: 'TBD',
    good: 'TBD',
    higher: 'TBD',
    light: 'TBD',
    marketLabel: 'Mercado',
    moderate: 'TBD',
    slightlyHigher: 'TBD',
    steady: 'TBD',
  },
  error: {
    errorContent:
      'Estamos teniendo problemas para recuperar la información de mercado, inténtelo de nuevo más tarde. Si el problema persiste, no dude en',
    headerTitle: 'Estamos teniendo algunos problemas para reunir todos los datos',
    sendEmail: 'enviar un correo electrónico',
    supportTeam: ' al equipo de soporte Frida 🤖.',
  },
  weatherAlerts: {
    title: 'Alertas Meteorológicas',
    hideAlerts: 'Ocultar alertas -',
    hideForecast: 'Ocultar el pronóstico -',
    moreAlerts: 'más alertas +',
    expandForecast: 'ampliar el pronóstico',
    status: 'Estado',
    region: 'Región',
    condition: 'Clima',
    highest: 'más alto',
    lowest: 'más baja',
    description: 'Alertas de los próximos 14 días en las zonas de producción más relevantes.',
    descriptionBold: 'Las alertas en rojo son las que pueden tener un impacto en el volumen.',
    units: 'Temperatura',
    weather: {
      blizzard: 'Tormenta de nieve',
      blowingSnow: 'Nieve soplada',
      brokenClouds: 'Nubes dispersas',
      clear: 'Despejado',
      cloudy: 'Nublado',
      fewClouds: 'Pocas nubes',
      fog: 'Niebla',
      freezingFog: 'Neblina congelada',
      heavyFreezingDrizzle: 'llovizna congelada pesada',
      intensityRain: 'lluvia de intensidad pesada',
      heavyRain: 'Lluvia Pesada',
      heavyRainAtTimes: 'Lluvias intermitentes fuertes',
      heavySnow: 'Nevadas fuertes',
      icePellets: 'Perdigones de hielo',
      lightDrizzle: 'Llovizna ligera',
      lightFreezingRain: 'Lluvia congelada ligera',
      lightRain: 'Lluvia ligera',
      lightRainShower: 'Chubascos ligeros',
      lightSleet: 'Aguanieve ligera',
      lightSleetShowers: 'Chubascos de aguanieve ligeros',
      lightSnow: 'Nieve ligera',
      lightSnowShowers: 'Chubascos de nieve ligeros',
      mist: 'Niebla',
      moderateOrHeavyRainShower: 'Chubascos de lluvia moderada o fuerte',
      moderateOrHeavyRainWithThunder: 'Lluvia moderada o fuerte con truenos',
      moderateOrHeavySleet: 'Aguanieve moderada o fuerte',
      moderateOrHeavySnowShowers: 'Aguanieve moderada o fuerte',
      moderateOrHeavySnowWithThunder: 'Nieve moderada o fuerte con truenos',
      moderateRain: 'Lluvia moderada',
      moderateRainAtTimes: 'lluvia moderada intermitente',
      moderateSnow: 'Nevadas moderadas',
      overcast: 'Nublado',
      overcastClouds: 'Nublado',
      partlyCloudy: 'Nublado parcial',
      patchyHeavySnow: 'Nevadas fuertes irregulares',
      patchyLightDrizzle: 'Llovizna ligera irregular',
      patchyLightRain: 'Lluvias ligeras irregulares',
      patchyLightRainWithThunder: 'Lluvias con truenos iregulares',
      patchyLightSnow: 'Nieve ligera irregular',
      patchyModerateSnow: 'Nieve moderada irregular',
      patchyRainPossible: 'Lluvias parciales irregulares',
      patchySleetPossible: 'Aguanieve irregular posible',
      patchySnowPossible: 'Nieve irregular posible',
      scatteredClouds: 'Nubes dispersas',
      skyIsClear: 'Cielo despejado',
      snow: 'Nieve',
      sunny: 'Soleado',
      thunderyOutbreaksPossible: 'Brotes de posibles truenos',
      torrentialRainShower: 'Lluvia torrencial',
    },
  },
  marketInformation: {
    title: 'Intelligence',
    currWeek: 'Semana actual',
    diffWeekMsg1: 'Estamos mostrando información de mercado para',
    diffWeekMsg2: 'Semana',
    reset: 'Regresar a fecha de hoy',
  },
  datePicker: {
    today: 'Hoy',
  },
  units: {
    box: 'cajas',
    kg: 'kilogramos',
    lb: 'libras',
    pallet: 'tarimas',
    truck: 'camiones',
    unit: 'unidad',
  },
  marketPlace: {
    checkboxLabel: 'Mostrar oportunidades terminadas',
    header: 'Mercado',
    myOppotunities: 'Mis oportunidades',
    programDetails: 'Detalles del la oportunidad',
    opportunityAvailables: 'Oportunidades disponibles',
    selectProgram: 'Selecciona uno de las oportunidades de la lista',
    welcome: 'Bienvenido',
  },
  mpDeepLinking: {
    notFoundTitle: 'Oportunidad no disponible',
    notFoundDescription:
      'La oportunidad que estas buscando parece ya no estar disponible, puedes revisar las oportunidades disponibles en el listado de la izquierda.',
    notFoundDescriptionMobile:
      'La oportunidad que estas buscando parece ya no estar disponible, usa el botón <b>“Ver otras oportunidades”</b> para ver las oportunidades disponibles.',
    notFoundCloseTextMobile: 'Ver otras oportunidades',
    notFoundCTATitle: '¿Necesitas ayuda?',
    notFoundCTADescription:
      'Puedes contactar al equipo de FRIDA en caso de que necesites ayuda con esta o alguna otra oportunidad.',
    notFoundCTAButtonText: 'Contactar a FRIDA',
  },
  //TODO: this is labels than need to be abbreviated and translated
  unitsAbbr: {
    box: 'cajas',
    kg: 'kg',
    lb: 'lb',
    pallet: 'tarimas',
    truck: 'camiones',
    unit: 'unidad',
  },
  programsCard: {
    // new program
    amount: 'Cantidad',
    deliveryDate: 'Fecha y hora de tu primera entrega',
    presentation: 'Presentación',
    flatOf: 'Caja de {{package_description}}.',
    deliveredDay: 'Debe entregarse el día: {{date}}',
    estimatedPerBox: 'Por caja de {{package_description}}',
    saleAssigned: 'Venta asignada a mí',
    timeRemaining: 'Tiempo para cerrar',
    expiredOffer: 'Oportunidad Expirada',
    conventional: 'Convencional',
    organic: 'Orgánico',
    // old program
    amountOf: 'Cantidad de',
    closeDeliveryTooltipDescription:
      'Las oportunidades tienen una fecha límite para participar, después de la fecha de cierre no podrás entrar en la opportunidad',
    closeDeliveryTooltipTitle: 'Fecha de cierre de un oportunidad',
    closeProgram: 'Cierre de la oportunidad',
    created: 'Abierto para participar',
    destination: 'Destino',
    duration: 'Duración',
    finished: 'Oportunidad terminada',
    firstDelivery: 'Primera entrega',
    firstDeliveryTootltipDescription:
      'Esta es la fecha en la que estaremos esperando tu primera entrega en el cooler correspondiente',
    firstDeliveryTootltipTitle: 'Primera entrega de la oportunidad',
    paused: 'Oportunidad en pausa',
    per: 'por',
    perWeek: 'Por semana',
    price: 'Precio',
    program: 'Programa',
    registeredProducers: 'Productores inscritos',
    sheduled: 'Oportunidad agendada',
    spot: 'Spot',
    type: 'Tipo',
    started: 'Oportunidad iniciada',
    weeks: 'Semanas',
  },
  mpMarketHeaderCard: {
    currentWeek: 'Semana actual: ',
    title: 'Mercado',
    week: 'Semana',
    statusTooltipWarningTitle: 'Tus documentos estan por expirar',
    statusTooltipWarningDescription:
      'Algunos de tus documentos expiran en menos de 30 días, si estos expiran no podrás seguir participando en tus oportunidades y afectaran negativamente tu calificación si no puedes cumplir con tus entregas.',
    statusTooltipWarningDescriptionLinkMore: 'Ver más',
    statusTooltipErrorTitle: 'Algunos de tus documentos han expirado',
    statusTooltipErrorDescription:
      'Algunos de tus documentos han expirado y necesitas actualizarlos lo antes posible tanto para poder seguir cumpliendo con tus oportunidades en curso, como para unirte a nuevas oportunidades.',
    statusTooltipErrorDescriptionLinkMore: 'Ver más',
    statusTooltipUnderReviewTitle: 'Algunos de tus documentos están en verificación',
    statusTooltipUnderReviewDescription:
      'Una vez que todo este listo podrás volver a participar en el Marketplace de FRIDA.',
  },
  mpStepper: {
    boxAmount: 'Cantidad de cajas',
    deliveryDays: 'Días de entrega',
    checkCommitment: 'Revisar compromiso',
  },
  mpBoxProgram: {
    USDC: 'USD por Caja',
    USDK: 'USD por kilogramo',
    USDL: 'USD por Libra',
    boxAmount: 'Cantidad de cajas',
    boxesOf: 'Cajas de',
    cold: 'y frio',
    comissions: 'Comisiones de Frida',
    commitmentMsg: 'Cajas comprometidas en otras oportunidades',
    customExpenses: 'Gastos Aduanales',
    doNotCommit: ' NO comprometer más del 50% ',
    estimatedPerWeek: 'Todos los retornos estimados son por cada entrega semanal',
    estimatedReturnPrice: 'Retorno estimado a productor',
    estimatedReturnUnit: 'Retorno unitario estimado',
    fridaFee: 'Comisiones de Frida',
    infoTooltipDescription:
      'El retorno estimado es después de la entrega en esta ubicación y una vez que se han cubierto todos los costos de logística y transporte',
    infoTooltipTitle: 'Este es el lugar donde debes hacer tus entregas',
    inspection: 'Inspección',
    logistics: 'Lógistica',
    packagingMaterial: 'Material de empaque',
    nationalLogistics: 'Logística Nacional',
    destinationLogistics: 'Selecciona el lugar de la entrega *',
    myCommitment: 'Me comprometo a la oportunidad con',
    recommendedAmount: 'Cantidad de cajas recomendadas',
    remember:
      'Recuerda que en la gráfica puedes darte cuenta de como impacta la cantidad seleccionada en tu curva de cosecha.',
    returnProducer: 'Desglose de retorno a productor',
    returnTooltipDescription:
      'Este es el retorno total estimado que recibirás una vez que todas las entregas se hallan llevado a cabo en tiempo y forma',
    returnTooltipTitle: 'Retorno al final de la oportunidad',
    salePrice: 'Precio de venta',
    selectAmount: 'Selecciona la cantidad *',
    selectPackaging: 'Material de empaque *',
    selectHelpText: '(cant. mínima)',
    selectTooltipDescription:
      'Comprometer sólo un porcentaje de tu producción en la oportuniad del Marketplace, te permite usar el resto para ofrecer el restante en el Spot Market',
    selectTooltipTitle: '¿Por qué no puedo comprometer el 100%?',
    step1: 'Paso 1 de 3',
    step1Label: 'Quiero comprometerme a la oportunidad con',
    totalProd: 'Tu producción total capturada',
    weRecomend: 'Te recomendamos',
    weeklyProduction: 'de tu producción total disponible para cada semana.',
    packagingMaterialRequestTitle: 'No tienes registrado este material de empaque',
    packagingMaterialRequestDescription:
      'Si estas interesado en participar en esta oportunidad usando este material de empaque, necesitas comunicarte con el equipo de FRIDA para que lo den de alta en tu cuenta de usuario.',
    packagingMaterialRequestCTA: 'Solicitar nuevo material de empaque',
  },
  mpCommittedProgram: {
    haveIssue: '¿Tienes algún problema?',
    helpMessage: 'Contacta al equipo de soporte de FRIDA y dinos como podemos ayudarte.',
    programDelivery: 'Entregas programadas',
    opportunityDetails: 'Detalles de la oportunidad',
    programDocumentation: 'Documentación',
    sendMessage: 'Enviar mensaje',
  },
  mpProgramDelivery: {
    title: '¿Qué días debo hacer mis entregas?',
    weeklyDelivery: 'Entregas semanales',
  },
  mpProgramDetails: {
    loaderMessage: 'Recibiendo información',
  },
  mpMyProgramDetails: {
    bloomPercentage: 'Porcentaje de bloom',
    boxUnit: 'Cajas de {{package_description}}',
    boxesPerPallet: 'Cajas por tarima',
    calibration: 'Calibre mínimo',
    deliverBeforeTime: 'Entregar antes de las',
    deliveryAddres: 'Dirección de entrega',
    deliveryDate: 'Fecha de entrega en {{destination}}',
    deliveryPlace: 'Lugar de entrega',
    deliveryPoint: 'Punto de entrega',
    deliveryTime: 'Fecha y hora de entrega',
    fruitCharacteristics: '¿Qué características debe tener la fruta?',
    fruitQuality: 'Calidad de la fruta',
    fruitSpecifications: 'Características de la fruta',
    minimumGauge: 'Calibre mínimo',
    oneExhibition: '1 sola exhibición',
    opportunityDuration: 'Duración de la oportunidad',
    packageCharacteristics: '¿Qué características debe tener el material de empaque?',
    packagingMaterial: 'Material de empaque',
    packingMaterial: 'Material de empaque y embalaje',
    packingSelectedMaterial: 'Material de empaque seleccionado',
    palletType: 'Tipo de tarima',
    palletUnit: 'Tarimas de {{boxes_pallet}} cajas',
    phone: 'Teléfono',
    placeLabel: 'Lugar',
    productQuality: 'Calidad de la fruta',
    programDuration: 'Oportunidad de {{weeks}} semanas',
    programWeekDuration: '{{weeks}} Semanas',
    ptiLabel: 'Etiqueta PTI',
    quantityPallets: 'Cantidad en tarimas',
    quantityToDeliver: 'Cantidad a entregar',
    saleType: 'Tipo de venta',
    shouldDeliver: '¿Qué cantidad debo entregar?',
    title: 'Resumen de la Oportunidad',
    totalQuantity: 'Cantidad total',
    truckUnit: 'Camiones de {{pallets_truck}} tarimas',
    weeklyVolume: 'Volumen semanal',
    weeks: 'semanas',
    whereDeliver: '¿Dónde realizar mis entregas?',
  },
  mpEmptyProgramList: {
    description: 'En este momento no tienes oportunidades disponibles para {{fruit}}',
  },
  mpProgramDocumentation: {
    foodSafety: 'Política de food safety',
    insurancePolicy: 'Política de seguros',
    packingMaterial: 'Material de empaque y embalaje',
    seeFile: 'Ver documento',
    termsAndConditions: 'Convenio de términos y condiciones',
    title: 'Descargas para la oportunidad',
  },
  mpCommitment: {
    bloom: 'Porcentaje de bloom',
    cmtDetail: 'Detalle del compromiso con la oportunidad',
    conventional: 'Convencional',
    deliveryPlace: 'La entrega se realizará en el Cooler ubicado en',
    fruit: 'Fruta',
    organic: 'Orgánico',
    price: 'El precio convenido es',
    type: 'Tipo',
  },
  mpWeekRow: {
    error: 'error',
    pending: 'pendiente',
    success: 'exitoso',
    warning: 'precaución',
    week: 'SEMANA',
  },
  mpFlowButtons: {
    close: 'Cerrar',
    commit: 'Aceptar convenio',
    next: 'Siguiente →',
    return: '← Regresar',
  },
  mpDetailCommitment: {
    boxDescription: 'Cajas de {{package_description}}.',
    boxesPerPallet: 'Cajas por tarima',
    commitedVolume: 'Volumen comprometido',
    deliverBeforeTime: 'Entregar antes de las',
    deliveryAddres: 'Dirección de entrega',
    deliveryDate: 'Fecha de entrega en {{destination}}',
    deliveryDates: 'Fechas de entrega en {{destination}}',
    deliveryDays: 'Días de entrega',
    deliveryPlace: 'Lugar de entrega',
    deliveryTime: 'Fecha y hora de entrega',
    estimatedReturnProducer: 'Retorno estimado a productor',
    fruitQuality: 'Calidad de la fruta',
    fruitTitle: 'Fruta',
    fruitSpecifications: 'Características de la fruta',
    minimumGauge: 'Calibre mínimo',
    numberOfBoxes: 'Cantidad de cajas',
    oneDeliveryLabel: 'Por cada entrega',
    opportunityDuration: 'Duración de la oportunidad',
    packingMaterial: 'Material de empaque y embalaje',
    packingSelectedMaterial: 'Material de empaque seleccionado',
    palletType: 'Tipo de tarima',
    phone: 'Teléfono',
    placeLabel: 'Lugar',
    presentation: 'Presentación',
    price: 'Precio',
    prices: 'Precios',
    programWeekDuration: '{{weeks}} Semanas',
    ptiLabel: 'Etiqueta PTI',
    quantityBoxes: 'Cantidad en cajas de',
    quantityPallets: 'Cantidad en tarimas',
    relatedDocuments: 'Documentos relacionados',
    requirePTI: 'Requiere etiqueta PTI',
    salePrice: 'Precio de venta',
    saleTotalProgram: 'Precio de venta total',
    saleTotalWeekly: 'Precio de venta semanal',
    step2: 'Paso 2 de 3',
    step2ProgramLabel: 'Detalle del compromiso con la oportunidad',
    tipDescription:
      'Si necesitas ayuda para validar tu etiqueta PTI o te interesa conocer el formato de etiqueta del comprador,',
    tooltipDescription:
      'Este es el retorno total estimado que recibirás una vez que todas las entregas se hallan llevado a cabo en tiempo y forma',
    tooltipTitle: 'Retorno al final de la oportunidad',
    totalBoxes: 'Total de cajas',
    usdPerBox: 'Por caja de',
    weeklyDelivery: 'Por entrega semanal',
    weeklyDeliveryAmout: '{{weeks}} entregas semanales',
  },
  Message: {
    accept: 'Aceptar',
    cancel: 'Cancelar',
    leave: 'Abandonar la oportunidad',
    leaveProgramMessageTitle: 'Estás por abandonar el proceso de compromiso',
    leaveProgramMessageDescription:
      'Estas a punto de abandonar el proceso de compromiso con la oportunidad, la cantidad seleccionada y tu espacio en el mismo se borraran si presionas el botón "Abandonar la oportunidad" si no deseas salir, solo usa el botón "Quedarme aquí”.',
    stay: 'Quedarme aquí',
    acceptUpdateFile: 'Actualizar documento aprobado',
    updateFileDescription:
      'Recuerda que cualquier documento nuevo, debe pasar por otra vez por el proceso de aprobación. Si actualizas un documento, no podrás participar en oportunidades hasta que el nuevo documento sea aprobado.',
    updateFileTitle: '¿Seguro que deseas actualizar tu documento?',
  },
  errorStatusDescriptions: {
    boxesOverLimit:
      'La cantidad seleccionada sobrepasa el volumen disponible, usa el menú desplegable para ajustar el volumen y continua con el proceso para unirte al oportunidad.',
    destinationNotFound: 'Destino no encontrado.',
    notProducerMember: 'El usuario no es un miembro del productor.',
    producerAlreadyCommitedProgram: 'El productor ya se ha comprometido a la oportunidad.',
    producerNotValidated: 'El productor no está validado.',
    programNotFound: 'Oportunidad no encontrada.',
  },
  errorStatusTitle: {
    boxesOverLimit: 'No puedes comprometer esa cantidad.',
    destinationNotFound: 'Algo salió mal',
    generalError: 'Algo salió mal',
    notProducerMember: 'Algo salió mal',
    producerAlreadyCommitedProgram: 'Algo salió mal',
    producerNotValidated: 'Algo salió mal',
    programNotFound: 'Algo salió mal',
  },
  projectionGraph: {
    harvest: 'Cosecha',
    title: 'Curva de cosecha',
    volume: 'Volumen',
  },
  selectProgram: {
    description: 'Selecciona una de las oportunidades de la lista',
  },
  mpRowCalendar: {
    tooltipTitle: 'Estos son los dias de entrega recomendados',
    tooltipDescription:
      'Si necesitas realizar tus entregas algún día diferente al recomendado, solo contacta al equipo de FRIDA para hacer el ajuste correspondiente',
  },
  mpConfirmCommitment: {
    acceptContract: 'Acepta el convenio de compra venta',
    certificatesPresented: 'y que los certificados presentados por mí corresponden a la cosecha comprometida. ',
    confirmError: 'Para continuar necesitas haber leído y aceptado los terminos y condiciones.',
    confirmIhaveRead: 'Confirmo que he leído y comprendo todo lo estipulado en el',
    estimatedTotalReturn: 'Retorno total estimado',
    pressButton: 'Al presionar el botón',
    programDuration: 'Duración de la oportunidad',
    programOverview: 'Resumen de la oportunidad',
    signContract: ' "Acepta el convenio" ',
    step3: 'Paso 3 de 3',
    step3LabelProgram: 'Comprometerme con la oportunidad',
    step3LabelSpot: 'Comprometerme con la venta spot',
    toastMessage: 'Revisa los detalles de tu oportunidad y recuerda que tu primera entrega es el día:',
    toastTitle: '¡Ya eres parte de esta oportunidad!',
    termsAndConditions: 'Convenio de términos y condiciones',
    weeklyVolume: 'Volumen semanal',
    weeks: 'Semanas',
  },
  mpProgress: {
    commited: '{{unit}} Comprometidos',
  },
  mpWelcomeMessage: {
    message: 'Revisa los detalles de tu oportunidad y recuerda que tu primera entrega es el día: {{date}}',
    title: '¡Ya eres parte de esta oportunidad!',
    accept: 'Aceptar',
  },
  mpVolumeAndPrices: {
    applyButton: 'Aplicar',
    chartData: 'Datos del gráfico',
    conventional: 'Convencional',
    crossingPoint: 'Punto de cruce',
    daily: 'Diario',
    daysToShow: 'Días para mostrar',
    endingDay: 'Día final',
    endingWeek: 'Semana final',
    extraYears: 'Años seleccionados',
    fridaForecast: 'Pronóstico Frida',
    movementSerie: 'Volumen',
    noData: 'Lo sentimos. No se encontraron datos',
    onlyPrices: 'Solo precios',
    onlyVolume: 'Solo volumen',
    organic: 'Orgánico',
    origins: 'Orígen',
    packageType: 'Empaque',
    pastFridaForecast: 'Pronóstico Frida de semanas pasadas',
    prevYears: 'Años',
    priceSerie: 'Precio',
    pricesInUsd: 'Precios en dólares',
    selectCheckbox: 'Selecciona',
    selectData: 'Selecciona un año',
    startingDate: 'Fecha de inicio',
    startingDay: 'Día de inicio',
    startingWeek: 'Semana inicial',
    timeAndPeriod: 'Tiempo y periodo',
    type: 'Tipo',
    viewAll: 'Todas',
    volumeAndPrices: 'Volumenes y precios',
    weekly: 'Semanal',
  },
  months: {
    april: 'Abril',
    august: 'Agosto',
    december: 'Diciembre',
    february: 'Febrero',
    january: 'Enero',
    july: 'Julio',
    june: 'Junio',
    march: 'Marzo',
    may: 'Mayo',
    november: 'Noviembre',
    october: 'Octubre',
    september: 'Septiembre',
  },
  graphNoData: {
    title: 'No hay información disponible',
    filtersDescriptions:
      'La combinación de filtros seleccionada no tiene información que mostrar, prueba cambiando el origen o año en el cual estas haciendo la búsqueda.',
  },
  mpCommitmentResume: {
    conventional: 'Convencional',
    estimatedTotalReturn: 'Retorno total estimado',
    flatPrice: 'Precio de venta por caja',
    oneExhibition: 'Sola exhibición',
    organic: 'Orgánico',
    program: 'Programa',
    returnPerKg: 'Retorno por kg',
    saleType: 'Tipo de venta',
    title: 'Resumen de la venta',
    totalQuantity: 'Cantidad total',
    totalSale: 'Venta total',
    type: 'Tipo',
    week: 'Semanas',
  },
  management: {
    title: 'Administración',
    logout: 'Cerrar sesión en frida',
    userOptions: {
      title: 'Opciones de usuario',
    },
    emptyStateMessage: 'Selecciona una de las opciones de la izquierda',
  },
  mgOrchards: {
    listTitle: 'Certificados de mis ranchos',
    alertTag: '{{alerts}} Alertas',
  },
  mgOrchardsDetails: {
    certificates: 'Certificados',
    title: 'Inocuidad',
    introText:
      'En esta sección puedes subir los certificados de inocuidad correspondientes a cada uno de tus ranchos. Recuerda que para participar en las oportunidades, es necesario mantener estos documentos vigentes y aprobados.',
    maxFileSizeText: 'Tamaño máximo de archivos',
    supportedFormats: 'Formatos soportados',
    certificate: 'Certificados',
    corrective_action_report: 'Reporte de Acciones Correctivas',
    pesticide_analysis: 'Análisis de Pesticidas',
    audit_report: 'Reporte de Auditoría',
    field_questionnaire: 'Cuestionario de Campo (FSMA)',
    microbiological_analysis: 'Análisis microbiológico',
    sketch_map: 'Croquis/Mapa',
    status: {
      pending: 'Pendiente de revisión',
      rejected: 'Rechazado',
      approved: 'Aprobado',
      prompt_expiration: 'Próximo a expirar',
      expired: 'Expirado',
    },
    noDataMessage: 'No hay ningún documento relacionado con este rancho.',
    expiresIn: 'Fecha de expiración',
    size: 'Tamaño',
    loadingMessage: 'Cargando documento',
    uploadSuccess: {
      title: 'Tu documento se ha cargado con éxito',
      message:
        'Revisaremos tu documento. Una vez que todo este listo podrás volver a participar en el Marketplace de FRIDA.',
    },
    uploadError: {
      title: 'No pudimos cargar tu archivo',
      message: 'Al parecer hubo un problema con tu archivo, por favor intenta nuevamente.',
    },
  },
  documentUpload: {
    uploadFile: 'Subir archivo',
    update: 'Actualizar',
    maxFileSizeMessage: 'El tamaño de tu archivo no debe ser mayor a {{size}}',
  },
  loginModal: {
    welcomeTo: 'Bienvenido al',
    heading: 'Marketplace de FRIDA',
    description:
      'Marketplace es el lugar donde puedes colocar oportunidades (Si eres un comprador) y tambien puedes participar en oportunidades vendiendo tu fruta (si eres un productor).',
    loginAsProducer: 'Inicia sesión como productor',
    email: 'Correo electrónico',
    password: 'Contraseña',
    errorLoginMessage: 'La contraseña o el correo electrónico que usaste no son válidos',
    loginButtonText: 'Iniciar Sesión',
    backButtonText: 'Regresar a Intelligence',
    backButtonTextShort: 'Ir a Intelligence',
    interestedInTitle: '¿Estas interesado en tener una cuenta de FRIDA?',
    interestedInDescription: 'Dejanos tus datos y nos pondremos en contacto contigo',
    interestedInCTA: 'Quiero saber más',
    loginAsMarketerTitle: '¿Quieres iniciar sesión como un comercializador?',
    loginAsMarketerButton: 'Iniciar sesión como comercializador',
    forgotPassword: '¿Olvidaste tu contraseña?',
  },
  modalResetPassword: {
    heading: 'Ingresa tu correo',
    description: 'Escribe el correo electrónico que usaste para crear tu cuenta de usuario',
    error: 'Algo salió mal, por favor intenta de nuevo',
    email: 'Correo electrónico',
    formActionButton: 'Recuperar contraseña',
    formActionCancel: 'Cancelar',
    successHeading: 'Revisa tu correo',
    successDescription: 'Te hemos enviado un correo a {{email}}',
    successActionButton: 'Regresar al login',
  },
  modalResetPasswordConfirm: {
    heading: 'Crear contraseña',
    description:
      'Tu contraseña debe tener 8 caractéres, una letra mayuscula y una mínuscula, un número y un caracter especial',
    newPassword: 'Nueva contraseña',
    reNewPassword: 'Confirmar nueva contraseña',
    formActionButton: 'Crear contraseña',
    formActionCancel: 'Cancelar',
    error: 'Algo salió mal, por favor intenta de nuevo',
    errorClientNotMatch: 'El nuevo password y su confirmación deben ser iguales',
    errorClientInvalidPassword: `Tu contraseña debe cumplir con los siguiente requerimientos de seguridad:
      • Mínimo de 8 caracteres
      • Usar un carácter especial: ${SPECIAL_CHARACTERS}
      • Usar una letra mayúscula y una minúscula
      • Usar un número
    `,
    successHeading: 'Listo',
    successDescription: 'Tu contraseña se ha actualizado con éxito, puedes iniciar sesión haciendo clic en el botón.',
    successActionButton: 'Regresar al login',
    invalidLinkTitle: 'Enlace invalido',
    invalidLinkDescription: 'Parece que el enlace usado para crear una nueva contraseña ha dejado de ser valido.',
    invalidLinkActionButton: 'Solicitar nueva contraseña',
  },
  dialogProducerExpiredDocuments: {
    title: 'Algunos de tus certificados han expirado',
    description:
      'Algunos de tus documentos han expirado y necesitas actualizarlos lo antes posible <b>tanto para poder seguir cumpliendo con tus oportunidades en curso, como para unirte a nuevas oportunidades</b>',
    okButtonText: 'Actualizar mis documentos',
    cancelButtonText: 'No deseo actualizar',
  },
};
