import React from 'react';

/*eslint-disable*/
const HelpIcon = ({ styleClass, customId }) => (
  <svg className={styleClass} viewBox="0 0 24 24" version="1.1">
    <title>Help</title>
    <defs>
      <path
        d="M12,2 C17.5,2 22,6.5 22,12 C22,17.5 17.5,22 12,22 C6.5,22 2,17.5 2,12 C2,6.5 6.5,2 12,2 Z M12,3.65625 C7.40625,3.65625 3.65625,7.40625 3.65625,12 C3.65625,16.59375 7.40625,20.34375 12,20.34375 C16.59375,20.34375 20.34375,16.59375 20.34375,12 C20.34375,7.40625 16.59375,3.65625 12,3.65625 Z M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M9.65424955,6.78849558 C10.4678168,6.13767383 11.5525732,5.88974174 12.6373296,6.0446993 C14.0610723,6.23064837 15.3492205,7.3773343 15.5526123,8.7099693 C15.8238014,10.2595449 14.9085382,11.7471374 13.3830996,12.3359762 C13.1119105,12.4289507 12.9424173,12.7388658 12.9424173,13.0797724 L12.9424173,13.0797724 L12.9424173,14.1632292 C12.9424173,14.6590934 12.5695323,15 12.0271541,15 C11.4847759,15 11.1118909,14.6590934 11.1118909,14.1632292 L11.1118909,14.1632292 L11.1118909,13.0797724 C11.1118909,12.0880441 11.755965,11.1892902 12.7390255,10.8483836 C13.5525927,10.6004515 13.993275,9.76368069 13.8237818,9.01988441 C13.7220859,8.33807116 13.0780118,7.78022395 12.3661405,7.68724941 C11.755965,7.62526639 11.2813841,7.68724941 10.8407018,8.02815604 C10.4678168,8.27608813 10.1966277,8.77195232 10.1966277,9.26781651 C10.1966277,9.42277407 10.2,10.5 9.3,10.5 C8.4,10.5 8.4,9.67070616 8.4,9.26781651 C8.4,8.27608813 8.84068227,7.43931732 9.65424955,6.78849558 Z"
        id="path-help-icon"
      ></path>
    </defs>
    <g id="Symbols-help-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-system-help">
        <g id="Icn/Help">
          <rect id="Icon-viewport-help" fillOpacity="0" fill="#313233" x="0" y="0" width="24" height="24"></rect>
          <mask id={`mask-2-help-icon-${customId}`} fill="white">
            <use xlinkHref="#path-help-icon"></use>
          </mask>
          <g id="Combined-Shape-help-icon" fillRule="nonzero"></g>
          <g id="Color/Neutral/900-help-icon" mask={`url(#mask-2-help-icon-${customId})`} fill="#141414">
            <path
              d="M8,0 L24,0 L24,0 L24,24 L8,24 C3.581722,24 5.41083001e-16,20.418278 0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"
              id="Neutral/900-help-icon"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

HelpIcon.defaultProps = {
  customId: '',
};

export default HelpIcon;
