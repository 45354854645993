import React, { useEffect, useState } from 'react';
import DayInformation from './DayInformation';
import PropTypes from 'prop-types';
import UtilsApi from '../../../../../utils/api/UtilsApi';
import { TempUnits } from '../../../../../utils/weather/TempUnits';
import { connect } from 'react-redux';
import './Forecast.scss';

const Forecast = ({ locationSlug, productSlug, forecastInfoMock, tempUnit, selectedDate }) => {
  const [forecastData, setForecastData] = useState(forecastInfoMock);
  const [tUnit, setTUnit] = useState(null);

  useEffect(() => {
    //TODO: here will be the service call
    const CancelToken = UtilsApi.cancelToken();
    const getForecastInfo = async () => {
      if (!forecastInfoMock.length) {
        const forecastInfo = await UtilsApi.forecastInfo(
          locationSlug,
          productSlug,
          TempUnits[tempUnit],
          selectedDate,
          CancelToken.token,
        ).then((res) => res.data);
        setForecastData(forecastInfo);
        setTUnit(tempUnit);
      }
    };
    getForecastInfo();

    return function () {
      CancelToken.cancel();
    };
  }, [forecastInfoMock, locationSlug, productSlug, tempUnit, selectedDate]);

  const renderDayInformation = () => {
    if (forecastData.length) {
      return forecastData.map((day, index) => (
        <div data-test={`forecast-day-${index}`} className="forecast-day-information-wrapper" key={index}>
          <DayInformation data={day} tempUnit={tUnit} index={index} />
        </div>
      ));
    }
  };

  return (
    <div data-test="forecast-container" className="forecast-container">
      <div data-test="forecast-content" className="forecast-content">
        {renderDayInformation()}
      </div>
    </div>
  );
};

Forecast.defaultProps = {
  forecastInfoMock: [],
};

Forecast.propTypes = {
  forecastInfoMock: PropTypes.array,
  locationSlug: PropTypes.string.isRequired,
  productSlug: PropTypes.string.isRequired,
  tempUnit: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
};

const mapStateToProps = (state) => ({
  selectedDate: state.selectedDate.date,
});

export default connect(mapStateToProps)(Forecast);
