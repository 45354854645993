/**
 * Method to handle very large weather description strings.
 * @param {string} description
 * @return {string}
 */
export const WeatherDescription = (description) => {
  const dictionary = {
    'heavy intensity rain': 'intensity rain',
    'very heavy rain': 'heavy rain',
  };
  if (dictionary[description]) return dictionary[description];
  return description;
};

/**
 * Method tho handle logic if rain exists
 * @param {string} description
 * @param {string} rain_1h
 * @param {string} rain_3h
 * @return {boolean}
 */
export const isRaining = ({ description, rain_1h, rain_3h }) => {
  const rainRegex = /(rain|storm)/i;
  return description.search(rainRegex) !== -1 || rain_1h || rain_3h;
};
