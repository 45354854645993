import { SET_VOLUME_BEHAVIOR } from '../actions/volumeBehavior/types';

// initalState for make the uTests
const initialState = { data: { last_week: -10, last_year: 10, report_date: '' } };

const volumeBehavior = (state = initialState, action) => {
  switch (action.type) {
    case SET_VOLUME_BEHAVIOR: {
      return {
        ...state,
        data: action.volumeBehavior,
      };
    }
    default:
      return state;
  }
};

export default volumeBehavior;
