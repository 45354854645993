import { string } from 'prop-types';
import React from 'react';

const VisibilityOff = ({ dataTest, ...other }) => (
  <svg data-test={dataTest} width={24} heigh={24} viewBox="0 0 24 24" {...other}>
    <path d="M23.775 11.619C23.55 11.396 18.45 5.75 12 5.75S.45 11.396.225 11.656c-.3.334-.3.891 0 1.225C.45 13.104 5.55 18.75 12 18.75s11.55-5.646 11.775-5.906c.3-.334.3-.854 0-1.225zM12 16.893c-4.425 0-8.325-3.269-9.75-4.643C3.712 10.876 7.575 7.607 12 7.607s8.325 3.269 9.75 4.643c-1.425 1.374-5.288 4.643-9.75 4.643zM15.275 11l-6.6.037C8.275 11 8 11.407 8 12s.275 1 .675 1l6.65-.037c.4 0 .675-.407.675-1-.05-.556-.325-.963-.725-.963z" />
  </svg>
);

VisibilityOff.propTypes = {
  dataTest: string,
};

export default VisibilityOff;
