import React, { useState, useEffect } from 'react';
import Checkbox from '../../../../components/Checkbox';
import OpportunityList from './opportunities/OpportunityList';
import PropTypes from 'prop-types';
import Tab from '../../../../components/Tab';
import UseLoader from '../../../../hooks/UseLoader';
import { getProgramsAction, cleanProgramsAction } from '../../../../redux/actions/programs/actions';
import { setConfirmProgram } from '../../../../redux/actions/confirmProgram/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './OpportunityTab.scss';

//TODO: refactor programs state with opportunities
const OpportunityTab = ({ opportunityCommited, setOpportunityCommited }) => {
  const [finishedProgramsCheck, setFinishedProgramsCheck] = useState(false);
  const [loader, setLoader] = useState(true);

  const confirmProgram = useSelector((state) => state.confirmProgram.confirmProgram);
  const programs = useSelector((state) => state.programs.programs);
  const selectedCommodity = useSelector((state) => state.products.active);

  const dispatch = useDispatch();

  const { t } = useTranslation(['marketPlace', 'loader']);
  const title = opportunityCommited ? t('myOppotunities') : t('opportunityAvailables');

  useEffect(() => {
    return function () {
      dispatch(cleanProgramsAction());
    };
  }, [dispatch]);

  useEffect(() => {
    let finishedProgramsCheckValue = finishedProgramsCheck;
    if (!opportunityCommited && finishedProgramsCheck) finishedProgramsCheckValue = false;

    const handleLoaderState = () => setLoader(false);

    if (selectedCommodity)
      dispatch(
        getProgramsAction(opportunityCommited, finishedProgramsCheckValue, handleLoaderState, selectedCommodity),
      );

    return function () {
      dispatch(setConfirmProgram(false));
      setLoader(true);
    };
  }, [opportunityCommited, finishedProgramsCheck, selectedCommodity, dispatch]);
  // tab order
  const buttonOrder = ['AVAIL', 'MYPROGRAMS'];

  /**
   * Set selected option
   * @param {number} index
   */
  const changeOption = (index) => {
    setOpportunityCommited(!!index);
    dispatch(cleanProgramsAction());
  };

  // program title
  const programTabTitle = {
    0: t('opportunityAvailables'),
    1: t('myOppotunities'),
  };

  // handleCheckbox change
  const handleCheckbox = (checkState) => {
    setFinishedProgramsCheck(checkState);
  };

  // reset confirm program action to false called in Tab
  const handleMessageAction = () => dispatch(setConfirmProgram(false));

  const handleLoader = () => {
    if (loader) return <UseLoader label={t('loader:loading')} />;
    return <OpportunityList opportunityCommited={opportunityCommited} opportunities={programs} />;
  };

  return (
    <div>
      <Tab
        confirmProgram={confirmProgram}
        handleMessageAction={handleMessageAction}
        optionsObject={{}}
        optionsOrder={buttonOrder}
        optionsTitle={programTabTitle}
        tabChange={changeOption}
      />
      <h2 className="programs-tab-title">{title}</h2>
      {opportunityCommited && (
        <div className="programs-tab-checkbox-wrapper">
          <Checkbox
            handleCheckboxChange={handleCheckbox}
            value="programs-tab-finished-programs-tabs"
            label={t('checkboxLabel')}
            isActive={finishedProgramsCheck}
          />
        </div>
      )}
      <div>{handleLoader()}</div>
    </div>
  );
};

OpportunityTab.defaultProps = {
  programs: undefined,
};

OpportunityTab.propTypes = {
  confirmProgram: PropTypes.bool.isRequired,
  opportunityCommited: PropTypes.bool,
  programs: PropTypes.array,
  setOpportunityCommited: PropTypes.func.isRequired,
};

export default OpportunityTab;
