import React from 'react';
import {
  AsparagusIcon,
  BlueberryIcon,
  BlackberryIcon,
  CeleryIcon,
  RaspberryIcon,
  StrawberryIcon,
} from '../../assets/icons/assets';

/**
 * @param {string} slug
 * @param {string} styleClass
 * @return {React.Element}
 **/
const ProductIcon = (slug, styleClass = '', dataTest) => {
  const iconDictionary = {
    rasp: <RaspberryIcon styleClass={styleClass} dataTest={dataTest} />,
    strby: <StrawberryIcon styleClass={styleClass} dataTest={dataTest} />,
    bluby: <BlueberryIcon styleClass={styleClass} dataTest={dataTest} />,
    'blkberi-v': <BlackberryIcon styleClass={styleClass} dataTest={dataTest} />,
    cel: <CeleryIcon styleClass={styleClass} dataTest={dataTest} />,
    asp: <AsparagusIcon styleClass={styleClass} dataTest={dataTest} />,
  };

  return iconDictionary[slug] || null;
};

export default ProductIcon;
