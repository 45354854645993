import { string } from 'prop-types';
import React from 'react';

const NavbarIntelligenceInactive = ({ dataTest, ...other }) => (
  <svg data-test={dataTest} viewBox="0 0 32 32" {...other}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M8.504 11.74l-1.186 1.745a.445.445 0 01-.62.111l-5.022-3.517c-.283-.198-.676 0-.676.341V30h26L9.17 11.684a.433.433 0 00-.666.057"
        fill="#A09EED"
      />
      <path
        d="M25.327 11.318L21.62 8.376a.433.433 0 00-.597.057L1 30h30V3.44c0-.435-.568-.605-.81-.243l-4.216 8.022a.446.446 0 01-.647.1"
        fill="#D0CFF6"
      />
    </g>
  </svg>
);

NavbarIntelligenceInactive.propTypes = {
  dataTest: string,
};

export default NavbarIntelligenceInactive;
