import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOptionAction } from '../../../../../redux/actions/management/option/actions';
import { getOrchardsListAction } from '../../../../../redux/actions/management/orchards/actions';
import React, { useEffect } from 'react';
import Badge from '../../../../../components/Badge';
import utilsApi from '../../../../../utils/api/UtilsApi';
import optionsTypes from '../optionsTypes';
import orchards from '../../../../../utils/page_definition/management/orchard/orchards';
import './index.scss';

const { cancelToken } = utilsApi;
const { orchardContainer, orchardContentContainer, orchardContentButton, orchardContentTag } = orchards;

const OrchardsLists = ({ dataMock = undefined }) => {
  const orchardsStoreList = useSelector((state) => state.orchards.orchardsList);
  const activeOption = useSelector((state) => state.option.activeOption);

  const orchardsList = dataMock || orchardsStoreList;

  const activeOrchard = activeOption.type === optionsTypes[1] ? activeOption.option : undefined;

  const dispatch = useDispatch();
  const { t } = useTranslation('mgOrchards');

  useEffect(() => {
    const CancelToken = cancelToken();

    if (!dataMock) {
      dispatch(getOrchardsListAction(CancelToken.token));
    }

    return () => {
      CancelToken.cancel();
    };
  }, [dataMock, dispatch]);

  const handleActiveOrchard = (e, id) => {
    e.preventDefault();
    dispatch(setOptionAction({ type: optionsTypes[1], option: id }));
  };

  const renderOrchardList = () => {
    if (orchardsList)
      return orchardsList.map((orchard) => {
        const { id, name, alerts } = orchard;
        const isActive = activeOrchard === id;
        const orchardWarning = alerts ? true : false;

        return (
          <button
            key={id}
            className={`option-list__button__container active-${isActive}
            warning-${orchardWarning}`}
            data-test={`${orchardContentButton}-${id}`}
            onClick={(e) => handleActiveOrchard(e, id)}
          >
            <span className="option-list__button__label">{name}</span>
            {Boolean(alerts) && (
              <Badge
                type="danger"
                text={t('mgOrchards:alertTag', { alerts })}
                className="ml-base"
                dataTest={orchardContentTag}
              />
            )}
          </button>
        );
      });
    return '';
  };

  return (
    <div className="orchards-list__container" data-test={orchardContainer}>
      <div className="orchards-list__header">
        <h5 className="option-list__header__title">{t('mgOrchards:listTitle')}</h5>
      </div>
      <div className="option-list__content__container" data-test={orchardContentContainer}>
        {renderOrchardList()}
      </div>
    </div>
  );
};

OrchardsLists.propTypes = {
  dataMock: PropTypes.array,
};

export default OrchardsLists;
