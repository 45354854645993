export const PRODUCER_STATUS = {
  VALIDATED: 'VALIDATED',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  UNDER_REVIEW: 'UNDER_REVIEW',
};

export const PRODUCER_STATUS_MAP = {
  1: PRODUCER_STATUS.VALIDATED,
  2: PRODUCER_STATUS.WARNING,
  3: PRODUCER_STATUS.ERROR,
  4: PRODUCER_STATUS.UNDER_REVIEW,
};
