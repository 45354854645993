import React, { useEffect, useRef, useState } from 'react';
import Flags from '../assets/icons/Flags';
import WebStorage from '../utils/WebStorage/WebStorage';
import switchCaseHandler from '../utils/common/switch';
import { useTranslation } from 'react-i18next';
import { CancelStatusIcon } from '../assets/icons/assets';
import './ChangeLanguage.scss';

const { getCookieValue, setCookie } = WebStorage;

// 0 es 1 en
const ChangeLanguage = () => {
  const [language, setLanguage] = useState();
  const [floatingMenu, setFloatingMenu] = useState(false);
  const trigger = useRef();
  const popover = useRef();
  const { i18n } = useTranslation();
  const localeID = getCookieValue('localeId');

  // adding event listener to hide the select and cleanup when is unmounted
  useEffect(() => {
    // set language number from cookies
    const localeLanguage = switchCaseHandler({
      es: 0,
      en: 1,
    })(0);

    setLanguage(localeLanguage(localeID));
  }, [localeID]);

  useEffect(() => {
    function handleClickOutside(ev) {
      if (trigger.current.contains(ev.target)) return;
      if (popover.current.contains(ev.target)) return;

      setFloatingMenu(false);
    }

    if (floatingMenu) {
      document.addEventListener('mousedown', handleClickOutside);

      return function cleanup() {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [floatingMenu]);

  const languageSpec = switchCaseHandler({
    0: 'es',
    1: 'en',
  })(0);

  const languageIcon = switchCaseHandler({
    0: <Flags flag={1} styleClass="change-language-icon" />,
    1: <Flags flag={2} styleClass="change-language-icon" />,
  })(0);

  // bitwise between 0 and 1.
  const handleLanguage = (option) => {
    const language = languageSpec(option);
    setLanguage(option);
    i18n.changeLanguage(language, () => setCookie('localeId', language));
    setFloatingMenu(false);
  };

  const handleFloatingMenu = () => setFloatingMenu(!floatingMenu);

  const renderTogglerIcon = () => {
    if (floatingMenu) {
      return <CancelStatusIcon width={16} height={16} styleClass="db" />;
    }

    return (
      <>
        {languageIcon(language)}
        <p>{languageSpec(language)}</p>
      </>
    );
  };

  return (
    <div className={`change-language-container navbar-anim ${floatingMenu ? 'active' : ''}`}>
      <button className="change-language-button navbar-anim__control" onClick={handleFloatingMenu} ref={trigger}>
        {renderTogglerIcon()}
      </button>
      <div className="navbar-anim__popover" ref={popover}>
        <ul className="navbar-anim__list">
          <li className="navbar-anim__list-item">
            <button
              className={`change-language-button language-selected-${language === 0} navbar-anim__list-item-btn`}
              onClick={() => handleLanguage(0)}
            >
              {languageIcon(0)}
              <p>{languageSpec(0)}</p>
            </button>
          </li>
          <li className="navbar-anim__list-item">
            <button
              className={`change-language-button language-selected-${language === 1} navbar-anim__list-item-btn`}
              onClick={() => handleLanguage(1)}
            >
              {languageIcon(1)}
              <p>{languageSpec(1)}</p>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChangeLanguage;
