/*
 * method to parse the destinations data for select
 * @param {array} destinations [id, name]
 * @return {array} {id, label, value}
 */
const destinationLogistics = (destinations) => {
  if (destinationLogistics.length) return destinations.map(({ id, name }) => ({ id, label: name, value: id }));
  return [];
};

export default destinationLogistics;
