import { func } from 'prop-types';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Button from 'components/Button';
import Image from 'assets/images/marketplace/opportunity-not-found.svg';
import './style.scss';

const OpportunityNotFound = ({ onDismiss }) => {
  const { t } = useTranslation('mpDeepLinking');

  return (
    <div className="opportunity-not-found h100 white-100-bg">
      <div className="opportunity-not-found__wrapper oa primary-50-bg border primary-300-br br-m h100 df aic jcc py-xl px-l">
        <div className="gt-xs-hide pb-l df jcfe">
          <Button onClick={onDismiss} shape="complementary" size="small" styleClass="fw600" type="primary">
            {t('notFoundCloseTextMobile')}
          </Button>
        </div>
        <div className="opportunity-not-found__content w100">
          <img src={Image} className="opportunity-not-found__content-img" width="157" alt="" />
          <h3 className="neutral-900 fs3 fw700 pb-base">{t('notFoundTitle')}</h3>
          <p className="lt-sm-hide fs-base fw400 neutral-900 pb-l">{t('notFoundDescription')}</p>
          <p className="gt-xs-hide fs-base fw400 neutral-900 pb-l">
            <Trans t={t} i18nKey="notFoundDescriptionMobile" components={{ b: <b /> }} />
          </p>
          <div className="primary-100-bg p-l br-m">
            <h6 className="fs-base fw700 neutral-900 pb-base">{t('notFoundCTATitle')}</h6>
            <p className="fs-3 fw400 neutral-900 pb-m">{t('notFoundCTADescription')}</p>
            <a className="fk-button button-small button-primary-main dib tdn" href="mailto:support@fridanet.com">
              {t('notFoundCTAButtonText')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

OpportunityNotFound.propTypes = {
  onDismiss: func,
};

export default OpportunityNotFound;
