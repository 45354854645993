export const productTypeSelect = [
  {
    id: 'viewall',
    value: 'viewAll',
    label: 'view all',
  },
  {
    id: 'conventional',
    value: 'conventional',
    label: 'conventional',
  },
  {
    id: 'organic',
    value: 'organic',
    label: 'organic',
  },
];
