import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import ProjectionGraphOptions from '../../../../../utils/Highcharts/ProjectionGraphOptions';
import PropTypes from 'prop-types';
import UseLoader from '../../../../../hooks/UseLoader';
import UtilsApi from '../../../../../utils/api/UtilsApi';
import projectionGraphSerie from '../../../../../utils/Highcharts/ProjectionGraphSerie';
import unitsHandler from '../../../../../utils/marketplace/units/unitsHandler';
import { GetYear } from '../../../../../utils/common/DateHandler';
import { useTranslation } from 'react-i18next';
import './ProjectionGraph.scss';

//TODO: initial state with default values
const ProjectionGraph = ({
  commitedBoxes,
  currentDate,
  programData = { end_week: 0, start_week: 0, boxes_pallet: 240, pallets_truck: 28, package_weight: 2.2 },
  programID,
  programType,
  showWarningMessage,
  unit,
}) => {
  const [graphOptions, setGraphOptions] = useState();
  const [projectionData, setProjectionData] = useState();
  const [loader, setLoader] = useState(true);
  const { rawValue } = unitsHandler;
  const { t } = useTranslation(['projectionGraph', 'loader']);

  // method to handle the projection data.
  const { parseSerieData, parseVolumeDataAvailablePrograms } = projectionGraphSerie;

  //Program data
  const { end_week, start_week, boxes_pallet, pallets_truck, package_weight } = programData;

  // cancel token
  const CancelToken = UtilsApi.cancelToken();

  useEffect(() => {
    const handleLoaderState = () => setLoader(false);

    const getProjectionDetails = async () => {
      try {
        const { data } = await UtilsApi.getProjectionDetails(
          CancelToken.token,
          programType,
          programID,
          currentDate.week,
        );
        if (data) {
          setProjectionData(data);
          handleLoaderState();
        }
      } catch (e) {
        if (!UtilsApi.isCancel(e)) {
          setProjectionData();
        }
      }
      return function () {
        setLoader(true);
      };
    };

    if (currentDate && !showWarningMessage) getProjectionDetails();

    return function () {
      CancelToken.cancel();
    };
  }, [programType, programID, currentDate, showWarningMessage]);

  useEffect(() => {
    if (projectionData) {
      const cloneHCH = { ...ProjectionGraphOptions };
      const hcSeries = [];

      // main data Harverst
      const mainSerieData = {
        color: '#ffbc5b',
        id: 'harvest',
        name: `${t('harvest')} ${GetYear(currentDate.date)}`,
      };

      // linked data Volumen harvest
      const linkedSerieDataAvailable = {
        color: '#7047ec',
        id: 'volumen',
        name: t('volume'),
      };

      const linkedSerieDataMyPrograms = {
        color: '#2c9ac0',
        id: 'volumen',
        name: t('volume'),
      };

      // parse the main serie data
      const mainData = parseSerieData(projectionData, 'boxes', unit, boxes_pallet, pallets_truck, package_weight);

      // add mainData to the main serie data.
      mainSerieData.data = mainData;

      // push the main serie data to the options
      hcSeries.push(mainSerieData);

      // this case is for available programs
      if (!programType) {
        // Volume data
        const commitedBoxesParsed = rawValue(unit, commitedBoxes, pallets_truck, boxes_pallet, package_weight);
        let volumeData = [];

        // if program available is selected get the volumeData from start_week and end_week
        if (start_week && end_week && commitedBoxes)
          volumeData = parseVolumeDataAvailablePrograms(start_week, end_week, commitedBoxesParsed);

        // add volumeData to the linked serie data.
        linkedSerieDataAvailable.data = volumeData;

        // push volume series to the highchart options if exist.
        if (volumeData.length) hcSeries.push(linkedSerieDataAvailable);

        //TODO: validate the current week
        // cloneHCH.xAxis.plotLines[0].value = 0;
        // cloneHCH.xAxis.plotLines[0].label.text = 'Semana actual';
      } else {
        // this case is for my programs and selected my program.
        let volumeData = [];
        volumeData = parseSerieData(projectionData, 'committed', unit, boxes_pallet, pallets_truck, package_weight);

        // add volumeData to the linked serie data.
        linkedSerieDataMyPrograms.data = volumeData;

        // push the volume series to the highcharts options.
        hcSeries.push(linkedSerieDataMyPrograms);
      }

      // added the new series to the options
      cloneHCH.series = hcSeries;

      // add the highcharts options to the state
      setGraphOptions(cloneHCH);
    }
  }, [
    boxes_pallet,
    commitedBoxes,
    end_week,
    package_weight,
    pallets_truck,
    projectionData,
    start_week,
    unit,
    t('volume'),
  ]);

  // expand highchart required for range serie
  HighchartsMore(Highcharts);

  const renderHighCharts = () => {
    if (!loader)
      return (
        <HighchartsReact
          containerProps={{ style: { height: '200px' } }}
          highcharts={Highcharts}
          options={graphOptions}
          updateArgs={[true, true, true]}
        />
      );
    return <UseLoader label={t('loader:loading')} />;
  };

  return (
    <div className="projection-graph-container">
      <div className="proj-graph-header">
        <h4>{t('title')}</h4>
      </div>
      <div className="proj-graph-content">{renderHighCharts()}</div>
    </div>
  );
};

ProjectionGraph.defaultProps = {
  commitedBoxes: 0,
  currentDate: undefined,
};

ProjectionGraph.propTypes = {
  commitedBoxes: PropTypes.number,
  currentDate: PropTypes.object,
  programData: PropTypes.object,
  programID: PropTypes.number,
  programType: PropTypes.bool.isRequired,
  showWarningMessage: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired]),
  unit: PropTypes.string.isRequired,
};

export default ProjectionGraph;
