import React from 'react';
import PropTypes from 'prop-types';
import WeatherIcons from '../../../../../utils/packages/WeatherIcons';
import { GetDateWeatherLabel } from '../../../../../utils/common/DateHandler';
import stringHandlers from '../../../../../utils/common/stringHandlers';
import { WeatherDescription } from '../../../../../utils/weather/WeatherHandlers';
import { useTranslation } from 'react-i18next';
import './DayInformation.scss';

/**
 * DayInformation component
 * @param {Object} data data array
 * - @param {string} date date string param eg. Sat 1
 * - @param {string} temp_max high temperature.
 * - @param {string} temp_min low temperature.
 * - @param {string} description weather description to render the icon.
 * - @param {Boolean} is_high_temp show if alert is needed.
 * - @param {Boolean} is_low_temp show if alert is needed.
 * @param {string} tempUnit weather unit F or C
 * @param {number} index number in the forecast row
 */
const DayInformation = ({ data, tempUnit, index }) => {
  const { t, i18n } = useTranslation('weatherAlerts');
  const { date, temp_max, temp_min, description, is_high_temp, is_low_temp } = data;
  const activeWarning = is_low_temp || is_high_temp;

  const handlerWarningCebraClass = () => (index % 2 === 0 ? 'dayinfo-warning-bg' : 'dayinfo-warning-cebra-bg');
  const handlerCebraClass = () => (index % 2 === 0 ? '' : 'dayinfo-cebra-bg');

  return (
    <div
      className={`dayinfo-container ${
        activeWarning ? `${handlerWarningCebraClass()} dayinfo-warning-border` : `${handlerCebraClass()} dayinfo-border`
      }`}
    >
      <div className="gradient-divisor" />
      <div className="dayinfo-information-container">
        <div className="dayinfo-general-information">
          <div className="dayinfo-date">
            <p>{GetDateWeatherLabel(date, i18n.language)}</p>
          </div>
          <div className={`dayinfo-temperature dayinfo-${tempUnit}-degree`}>
            <p className="dayinfo-temp-high">{Math.round(temp_max)}</p>
            <p className="dayinfo-temp-separator">/</p>
            <p className="dayinfo-temp-low">{Math.round(temp_min)}</p>
          </div>
        </div>
        <div className="dayinfo-weather-resume">
          <div className="dayinfo-icon">
            <WeatherIcons
              icon={description.toUpperCase()}
              styleClass="dayinfo-weather-icon"
              isWarning={activeWarning}
            />
          </div>
          <div className="dayinfo-description">
            {t(`weather.${stringHandlers.toKeyFormat(WeatherDescription(description))}`)}
          </div>
        </div>
      </div>
    </div>
  );
};

DayInformation.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  tempUnit: PropTypes.string.isRequired,
};

export default DayInformation;
