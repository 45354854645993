import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import Input from '../Input';
import { Visibility, VisibilityOff } from '../../assets/icons/assets';

const InputShowPassword = ({ id, label, placeholder = '', required = false, error = false, show = false }) => {
  const [showPassword, setShowPassword] = useState(show);

  const handleClick = (ev) => {
    ev.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <Input
      error={error}
      id={id}
      label={label}
      name={id}
      placeholder={placeholder}
      required={required}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <button type="button" className="df aic px-base mr-base border-none transparent-bg" onClick={handleClick}>
          {showPassword ? <VisibilityOff className="primary-900" /> : <Visibility className="neutral-400" />}
        </button>
      }
    />
  );
};

InputShowPassword.propTypes = {
  /**
   * Unique identifier for the input and label to be related.
   */
  id: string.isRequired,
  /**
   * The `label` text to be displayed along with the `input`
   */
  label: string.isRequired,
  /**
   * The short hint displayed in the `input` before the user enters a value.
   * @default ''
   */
  placeholder: string,
  /**
   * If `true`, the `input` element is required.
   * @default false
   */
  required: bool,
  /**
   * If `true`, the `input` will indicate an error.
   * @default false
   */
  error: bool,
  /**
   * If `true`, the input will be of type `text` otherwise `password`
   * @default false
   */
  show: bool,
};

export default InputShowPassword;
