import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Sider from '../../Sider/Sider';
import MarketHeaderCard from '../../Content/MarketPlace/header/MarketHeaderCard';
import OpportunityList from '../../Content/MarketPlace/opportunityTabs/opportunities/OpportunityList';
import Tab from '../../../components/Tab';
import EmptyStateMessage from '../../../components/EmptyStateMessage';
import { ModalLogin, ModalResetPassword, ModalResetPasswordConfirm } from '../../../components/Modals';
import { ROUTES, PARAM_VALUES } from '../../../constants/routes';
import { setActiveProductAction } from '../../../redux/actions/products/actions';
import switchCaseHandler from '../../../utils/common/switch';

const internals = {
  TOTAL_PROGRAMS: 4,
  getPrograms: (length = 2) =>
    Array.from({ length }, (_, i) => ({
      skeleton_mode: true,
      id: i + 1,
      program_type: i % 2 ? 2 : 1,
    })),
};

const LoginMarketplace = () => {
  const productActive = useSelector((state) => state.products.active);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation(['marketPlace', 'loader']);
  const modalParamValue = new URLSearchParams(location.search).get(ROUTES.PARAMS.MODAL);

  const renderModal = (m) => {
    return switchCaseHandler({
      [PARAM_VALUES.MODAL.LOGIN]() {
        return <ModalLogin />;
      },
      [PARAM_VALUES.MODAL.RESET_PASSWORD]() {
        return <ModalResetPassword />;
      },
      [PARAM_VALUES.MODAL.RESET_PASSWORD_CONFIRM]() {
        return <ModalResetPasswordConfirm />;
      },
    })(<ModalLogin />)(m);
  };

  useEffect(() => {
    if (!productActive) {
      dispatch(setActiveProductAction('rasp'));
    }
  }, [dispatch, productActive]);

  return (
    <div className="content-shell">
      <div className="grid-layout">
        <div className="sider-wrapper">
          <Sider />
        </div>
        <div className="content-wrapper">
          <div className="market-place-container">
            <div className="mrkt-place-inner-container grid-2-columns__fixed">
              <div className="grid-fixed__left">
                <MarketHeaderCard />
                <Tab
                  confirmProgram={false}
                  handleMessageAction={() => {}}
                  optionsObject={{}}
                  optionsOrder={['AVAIL', 'MYPROGRAMS']}
                  optionsTitle={{
                    0: t('opportunityAvailables'),
                    1: t('myOppotunities'),
                  }}
                  tabChange={() => {}}
                />
                <h2 className="programs-tab-title">{t('opportunityAvailables')}</h2>
                <OpportunityList
                  opportunityCommited={false}
                  opportunities={internals.getPrograms(internals.TOTAL_PROGRAMS)}
                />
              </div>
              <div className="grid-fixed__right">
                <div className="detail-opportunity-wrapper h100 border primary-300-br br-m">
                  <div className="df aic jcc h100">
                    <EmptyStateMessage title={'👈'} description={t('selectProgram:description')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderModal(modalParamValue)}
    </div>
  );
};

export default LoginMarketplace;
