/**
 * Document Status info
 * @url https://github.com/GitHubFreshkampo/FK-Guacamole/blob/develop/apps/documents/models.py#L10
 */
export const DOCUMENT_STATUS = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PROMPT_EXPIRATION: 'PROMPT_EXPIRATION',
  EXPIRED: 'EXPIRED',
};

export const DOCUMENT_STATUS_MAP = {
  1: DOCUMENT_STATUS.PENDING,
  2: DOCUMENT_STATUS.REJECTED,
  3: DOCUMENT_STATUS.APPROVED,
  4: DOCUMENT_STATUS.PROMPT_EXPIRATION,
  5: DOCUMENT_STATUS.EXPIRED,
};

export const MAX_FILE_SIZE = 10000000;
export const ACCEPTED_FILES = ['pdf', 'jpg', 'jpeg', 'png'];
