import { oneOf } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Tooltip from 'components/Tooltip';
import switchCaseHandler from 'utils/common/switch';
import { ROUTES } from 'constants/routes';
import { PRODUCER_STATUS } from 'constants/user';
import { SystemOkIcon, SystemErrorIcon, SystemWarning } from 'assets/icons/assets';
import profileDefinitions from 'utils/page_definition/components/profile';
import './style.scss';

const internals = {
  baseClassName: 'profile-info__status-badge br50 df aic jcc',
};

const { profileInfoCheckBadge, profileInfoWarningBadge, profileInfoErrorBadge } = profileDefinitions;

const ProfileInfoStatusBadge = ({ status }) => {
  const { t } = useTranslation('mpMarketHeaderCard');

  return switchCaseHandler({
    [PRODUCER_STATUS.VALIDATED]() {
      return (
        <div className={`${internals.baseClassName} green-900-bg`}>
          <SystemOkIcon dataTest={profileInfoCheckBadge} className="white-100" width={16} height={16} />
        </div>
      );
    },
    [PRODUCER_STATUS.WARNING]() {
      return (
        <Tooltip
          placement="bottom-start"
          title={t('statusTooltipWarningTitle')}
          text={
            <>
              <span>{t('statusTooltipWarningDescription')}</span>
              &nbsp;
              <Link to={ROUTES.MANAGEMENT} className="fw600 primary-500">
                {t('statusTooltipWarningDescriptionLinkMore')}
              </Link>
            </>
          }
          customClass="profile-info__status-badge-tooltip"
        >
          <div className={`${internals.baseClassName} yellow-900-bg`}>
            <SystemWarning dataTest={profileInfoWarningBadge} className="white-100" width={16} height={16} />
          </div>
        </Tooltip>
      );
    },
    [PRODUCER_STATUS.ERROR]() {
      return (
        <Tooltip
          placement="bottom-start"
          title={t('statusTooltipErrorTitle')}
          text={
            <>
              <span>{t('statusTooltipErrorDescription')}</span>
              &nbsp;
              <Link to={ROUTES.MANAGEMENT} className="fw600 primary-500">
                {t('statusTooltipErrorDescriptionLinkMore')}
              </Link>
            </>
          }
          customClass="profile-info__status-badge-tooltip"
        >
          <div className={`${internals.baseClassName} red-900-bg`}>
            <SystemErrorIcon dataTest={profileInfoErrorBadge} className="white-100" width={16} height={16} />
          </div>
        </Tooltip>
      );
    },
    [PRODUCER_STATUS.UNDER_REVIEW]() {
      return (
        <Tooltip
          placement="bottom-start"
          title={t('statusTooltipUnderReviewTitle')}
          text={t('statusTooltipUnderReviewDescription')}
          customClass="profile-info__status-badge-tooltip"
        >
          <div className={`${internals.baseClassName} yellow-900-bg`}>
            <SystemWarning dataTest={profileInfoWarningBadge} className="white-100" width={16} height={16} />
          </div>
        </Tooltip>
      );
    },
  })(<div className={internals.baseClassName}></div>)(status);
};

ProfileInfoStatusBadge.propTypes = {
  status: oneOf(Object.keys(PRODUCER_STATUS)),
};

export default ProfileInfoStatusBadge;
