import React from 'react';
import Loader from './Loader';
import PropTypes from 'prop-types';
import Error from './Error';

/**
 * NoData.
 *
 * @param {Object} error to handle if render or no the error component
 * @param {boolean} loading to handle if render or no the leader
 */
const NoData = ({ error, loading }) => {
  const handleNoDataRender = () => {
    if (error) return <Error />;
    if (loading) return <Loader />;
    return '';
  };
  return <div>{handleNoDataRender()}</div>;
};

NoData.defaultProps = {
  error: null,
  loading: true,
};

NoData.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
};

export default NoData;
