import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import BoxProgram from './detailNavigation/BoxProgram';
import CommitResume from './CommitResume/CommitResume';
import CommittedOpportunity from './detailNavigation/CommittedOpportunity';
import ConfirmCommitment from './detailNavigation/ConfirmCommitment';
import DetailCommitment from './detailNavigation/DetailCommitment';
import ProjectionGraph from './projectionGraph/ProjectionGraph';
import Button from 'components/Button';
import EmptyStateMessage from 'components/EmptyStateMessage';
import Message from 'components/Message';
import UseLoader from 'hooks/UseLoader';
import useProfileData from 'hooks/Profile/useProfileData';
import switchCaseHandler from 'utils/common/switch';
import utilsApi from 'utils/api/UtilsApi';
import { getCurrentDateAction } from 'redux/actions/currentDate/actions';
import { getProgramDetailsAction, cleanProgramsAction } from 'redux/actions/programs/actions';
import { setConfirmProgram } from 'redux/actions/confirmProgram/actions';
import { PRODUCER_STATUS, PRODUCER_STATUS_MAP } from 'constants/user';
import './OpportunityDetail.scss';

const OpportunityDetail = ({ opportunityCommited }) => {
  const [selectedCommitedBoxes, setSelectedCommitedBoxes] = useState();
  const [selectedPackagingMaterial, setSelectedPackagingMaterial] = useState();
  const [selectedDestination, setSelectedDestination] = useState();
  const [commitmentData, setCommitmentData] = useState();
  const [errorData, setErrorData] = useState('');
  const [current, setCurrent] = useState(0);
  const [programData, setProgramData] = useState();
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [isClosedOpportunityDetails, setIsClosedOpportunityDetails] = useState(true);
  const [loader, setLoader] = useState(false);
  const [closeButtonClicked, setCloseButtonClicked] = useState(false);
  const { producer } = useProfileData();

  const confirmProgram = useSelector((state) => state.confirmProgram.confirmProgram);
  const currentDate = useSelector((state) => state.currentDate.currentDate);
  const programDetails = useSelector((state) => state.programs.programDetails);
  const programs = useSelector((state) => state.programs.programs);
  const selectedProgram = useSelector((state) => state.programs.id);
  const unit = useSelector((state) => state.unitSelected.unit);

  const scrollDesktopRef = useRef(undefined);
  const scrollMobileRef = useRef(undefined);

  const dispatch = useDispatch();

  const { t } = useTranslation(['Message', 'loader', 'mpFlowButtons']);

  // commit steps order
  const stepOrder = ['BoxProgram', 'DetailCommitment', 'ConfirmCommitment'];

  // if the selectedPackagingMaterial has assigned then enable the next button, used on disableNextButton condition
  const enabledPackagingMaterial = selectedPackagingMaterial?.assigned;

  // disable next button on commitmentResume by selected options and enabledPackagingMaterial
  const disableNextButton =
    selectedProgram &&
    selectedCommitedBoxes &&
    selectedPackagingMaterial &&
    enabledPackagingMaterial &&
    selectedDestination &&
    PRODUCER_STATUS_MAP[producer?.status] !== PRODUCER_STATUS.ERROR
      ? false
      : true;

  const isSelectedProgram = isClosedOpportunityDetails && selectedProgram ? true : false;

  const handleLoaderState = () => setLoader((prevState) => !prevState);

  // utilsAPI METHODS
  const { commitmentSuggestions, cancelToken, isCancel } = utilsApi;

  useEffect(() => {
    if (selectedProgram) {
      dispatch(getProgramDetailsAction(opportunityCommited, handleLoaderState));
      setCurrent(0);
      setProgramData(programs.find((prog) => prog.id === selectedProgram));
      setSelectedCommitedBoxes();
      setSelectedDestination();
      setSelectedPackagingMaterial();
    } else {
      setCommitmentData();
      setProgramData();
      setSelectedCommitedBoxes();
      setSelectedDestination();
      setSelectedPackagingMaterial();
    }

    //cleanup
    return function () {
      setCloseButtonClicked(false);
      setCommitmentData();
      dispatch(setConfirmProgram(false));
      setCurrent();
      setErrorData(false);
      setLoader(false);
      setIsClosedOpportunityDetails(true);
      setProgramData();
      setSelectedCommitedBoxes();
      setSelectedDestination();
      setSelectedPackagingMaterial();
      setShowWarningMessage(0);
    };
  }, [programs, dispatch, selectedProgram, opportunityCommited]);

  useEffect(() => {
    if (!currentDate) dispatch(getCurrentDateAction());
  }, [currentDate, dispatch]);

  useEffect(() => {
    const { current } = window.innerWidth < 550 ? scrollMobileRef : scrollDesktopRef;
    current.scrollTo({
      top: 0,
      behaviour: 'smooth',
    });
  }, [current]);

  const handleNext = () => setCurrent(current + 1);

  const handlePrev = (optionToCancel = confirmProgram) => {
    if (optionToCancel) {
      setShowWarningMessage(true);
      return;
    }
    setCurrent(current - 1);
  };

  /* method to handle the message buttons actions
   * @param {boolean} action if true set the message to false and current moved to previous view
   */
  const handleMessageAction = (action) => {
    if (action) {
      if (closeButtonClicked) {
        setIsClosedOpportunityDetails(false);
        setCloseButtonClicked(false);
        dispatch(cleanProgramsAction());
        dispatch(setConfirmProgram(false));
        return;
      }
      handlePrev(false);
      dispatch(setConfirmProgram(false));
    }
    setShowWarningMessage(false);
  };

  // handle suggest commit req (USED ON BOXPROGRAM)
  const handleSuggestCommitReq = async () => {
    try {
      const CancelToken = cancelToken();
      const res = await commitmentSuggestions(CancelToken.token, selectedProgram, selectedCommitedBoxes);
      if (res.status === 200) {
        setCommitmentData(res.data);
        setErrorData(false);
        handleNext();
      }
    } catch (err) {
      if (!isCancel(err)) {
        setErrorData(true);
      }
    }
  };

  const closeOpportunityDetails = () => {
    setCloseButtonClicked(true);
    if (current !== 2) {
      setIsClosedOpportunityDetails(false);
      dispatch(cleanProgramsAction());
      return;
    }
    setShowWarningMessage(true);
  };

  /* steps to commit a program */
  const programToCommitViews = {
    BoxProgram: function boxPorgram() {
      return (
        <BoxProgram
          errorData={errorData}
          opportunityCommited={opportunityCommited}
          programData={programData}
          programDetails={programDetails}
          selectedCommitedBoxes={selectedCommitedBoxes}
          selectedDestination={selectedDestination}
          selectedPackagingMaterial={selectedPackagingMaterial?.value}
          selectedProgram={selectedProgram}
          setBoxCommitedBoxes={setSelectedCommitedBoxes}
          setSelectedDestination={setSelectedDestination}
          setSelectedPackagingMaterial={setSelectedPackagingMaterial}
          unit={unit}
        />
      );
    },
    DetailCommitment: function detailCommitment() {
      return (
        <DetailCommitment
          commitedBoxes={selectedCommitedBoxes}
          commitmentData={commitmentData}
          opportunityCommited={opportunityCommited}
          programData={programData}
          programDetails={programDetails}
          selectedDestination={selectedDestination}
          selectedPackagingMaterial={selectedPackagingMaterial?.value}
          selectedProgram={selectedProgram}
          unit={unit}
        />
      );
    },
    ConfirmCommitment: function confirmCommitment() {
      return (
        <ConfirmCommitment
          commitedBoxes={selectedCommitedBoxes}
          opportunityCommited={opportunityCommited}
          programData={programData}
          programDetails={programDetails}
          selectedDestination={selectedDestination}
          selectedPackagingMaterial={selectedPackagingMaterial?.value}
          selectedProgram={selectedProgram}
          unit={unit}
        />
      );
    },
  };

  // method to render warning message
  const renderWarningMessage = () => {
    if (showWarningMessage)
      return (
        <Message
          acceptText={t('Message:leave')}
          cancelText={t('Message:stay')}
          description={t('leaveProgramMessageDescription')}
          handleMessage={handleMessageAction}
          title={t('leaveProgramMessageTitle')}
          type="confirm"
        />
      );
    return '';
  };

  const programToCommit = switchCaseHandler(programToCommitViews)(<></>);

  // if opportunityCommited is true is a my program
  // else set to commitment steps
  const detailNavigation = () => {
    if (loader) return <UseLoader label={t('loader:loading')} />;

    if (programDetails && programData && selectedProgram) {
      if (opportunityCommited)
        return <CommittedOpportunity programDetails={programDetails} unit={unit} programData={programData} />;
      return programToCommit(stepOrder[current]);
    }
    return <EmptyStateMessage title={'👈'} description={t('selectProgram:description')} />;
  };

  return (
    <div ref={scrollMobileRef} className={`detail-opportunity-wrapper selected-program-${isSelectedProgram}`}>
      <div className={`detail-opportunity-card selected-program-${isSelectedProgram} border primary-300-br br-m`}>
        <div className={`detail-program-container render-resume-${disableNextButton}`}>
          <div ref={scrollDesktopRef} className="detail-program-scroll-wrapper">
            <div className="gt-xs-hide df jcfe">
              <Button onClick={closeOpportunityDetails} shape="complementary" size="small" type="primary">
                {t('mpFlowButtons:close')}
              </Button>
            </div>

            <div className="dtail-prog-header">
              <ProjectionGraph
                commitedBoxes={selectedCommitedBoxes}
                currentDate={currentDate}
                programData={programData}
                programID={selectedProgram}
                opportunityCommited={opportunityCommited}
                showWarningMessage={showWarningMessage}
                unit={unit}
              />
              <hr />
            </div>
            <div className="dtail-prog-content">
              <div>{detailNavigation()}</div>
              <div>{renderWarningMessage()}</div>
            </div>
          </div>
        </div>
        {!opportunityCommited && programData && programDetails && (
          <div className="dtail-prog-commit-resume-wrapper">
            <CommitResume
              commitedBoxes={selectedCommitedBoxes}
              currentStep={current}
              disableNextButton={disableNextButton}
              handleSuggestCommitReq={handleSuggestCommitReq}
              nextStep={handleNext}
              packagingMaterial={selectedPackagingMaterial?.value}
              prevStep={handlePrev}
              programData={programData}
              programDetails={programDetails}
              selectedDestination={selectedDestination}
              unit={unit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

OpportunityDetail.propTypes = {
  opportunityCommited: PropTypes.bool,
};

export default OpportunityDetail;
