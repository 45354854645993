import React from 'react';

/* eslint-disable*/
const ArrowTop = ({ styleClass, fillColor, dataTest, customId }) => (
  <svg data-test={dataTest} className={styleClass} viewBox="0 0 24 24">
    <title>Arrow Top</title>
    <defs>
      <polygon id="path-arrow-top" points="67 73 72 68 67 63"></polygon>
    </defs>
    <g id="03-Icons-arrow-top" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-arrow-top">
        <g
          id="Icon-/-Arrow-top"
          transform="translate(11.500000, 13.000000) rotate(-90.000000) translate(-11.500000, -13.000000) translate(-56.500000, -54.500000)"
        >
          <rect id="ViewBox-arrow-top" fillRule="nonzero" x="57" y="56" width="24" height="24"></rect>
          <g id="Icon-arrow-top">
            <mask id={`mask-arrow-top-${customId}`} fill="white">
              <use xlinkHref="#path-arrow-top"></use>
            </mask>
            <use id="Icon-Path-arrow-top" fill="#313233" fillRule="nonzero" xlinkHref="#path-arrow-top"></use>
            <g id="arrow-top-fill-color" mask={`url(#mask-arrow-top-${customId})`} fill={fillColor}>
              <path d="M8,0 L136,0 L136,0 L136,135 L8,135 C3.581722,135 5.41083001e-16,131.418278 0,127 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ArrowTop.defaultProps = {
  customId: '',
};

export default ArrowTop;
