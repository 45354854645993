import { string } from 'prop-types';
import React from 'react';
import './style.scss';

const Spinner = ({ className = '' }) => {
  return (
    <div className={`spinner pr ${className}`}>
      <span className="pa br50"></span>
      <span className="pa br50"></span>
      <span className="pa br50"></span>
      <span className="pa br50"></span>
      <span className="pa br50"></span>
    </div>
  );
};

Spinner.propTypes = {
  className: string,
};

export default Spinner;
