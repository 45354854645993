import React from 'react';
import Navbar from 'views/Sider/Navbar/Navbar';
import { FridaLogoTextWhite } from 'assets/icons/assets';
import './Sider.scss';

const Sider = () => {
  return (
    <div className="sider-container">
      <div className="sider-inner">
        <div className="sider-header-container">
          <div className="sider-header-logo-container">
            <FridaLogoTextWhite styleClass="sider-header-logo" />
          </div>
        </div>
        <div className="sider-content">
          <Navbar />
        </div>
      </div>
    </div>
  );
};

export default Sider;
