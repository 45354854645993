import React from 'react';

/*eslint-disable*/
const SelectArrowUp = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 12 12" version="1.1">
    <title>Select arrow up</title>
    <defs>
      <path
        d="M1.29196901,8.70650282 C1.65174981,9.06729442 2.21833451,9.09550823 2.6104552,8.79081837 L2.70463006,8.70771022 L6.0003424,5.414 L9.29513299,8.70747334 C9.65540802,9.06777033 10.2220309,9.09520574 10.6137335,8.78997747 L10.7077944,8.70674003 C11.067696,8.3460692 11.0951013,7.77882384 10.790208,7.38669085 L10.7070619,7.29252666 L6.70730573,3.29252666 C6.34738026,2.93257923 5.78142098,2.90480065 5.3896953,3.20925471 L5.29561381,3.29228978 L1.29317509,7.29228978 C0.902746462,7.68248051 0.902206482,8.31564526 1.29196901,8.70650282 Z"
        id="path-select-arrow-up"
      ></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-select-arrow-up">
        <mask id="mask-select-arrow-up" fill="white">
          <use xlinkHref="#path-select-arrow-up"></use>
        </mask>
        <use id="Path" fill="#4E5052" fillRule="nonzero" xlinkHref="#path-select-arrow-up"></use>
        <g id="Group" mask="url(#mask-select-arrow-up)">
          <g transform="translate(-0.044444, 0.500000)" id="Neutral-/-800-/-80%">
            <rect id="Neutral/900" fill="#141414" x="0" y="0" width="12.0888889" height="12"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SelectArrowUp;
