import React from 'react';
import { ProgramsEmptyStateBluBerry } from '../../../../../../assets/icons/assets';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './EmptyProgramList.scss';

const EmptyProgramList = () => {
  const { t } = useTranslation(['mpEmptyProgramList', 'fruits']);
  const activeProduct = useSelector((state) => state.products.active);

  return (
    <div className="empty-program-list-container">
      <ProgramsEmptyStateBluBerry styleClass="empty-program-icon" />
      <p className="empty-prog-list-description">{`${t('mpEmptyProgramList:description', {
        fruit: t(`fruits:${activeProduct}`),
      })}`}</p>
    </div>
  );
};

export default EmptyProgramList;
