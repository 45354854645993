import React, { useState, useEffect } from 'react';
import { array, arrayOf, bool, func, node, oneOfType, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select';
import crossingSelectData from '../../utils/crossingSelectData';
import originSelectData from '../../utils/originSelectData';
import packagesTypesSelectData from '../../utils/packagesTypesSelectData';
import { GRAPH_VALUES, graphSelectData } from '../../utils/graphSelectData';
import './style.scss';

/*
 * @typedef {Object} commodityApiData
 * @property {array} origins
 * @property {array} crossings
 * @property {array} packages
 */

/*
 * @param {commodityApiData} commodityApiData
 * @param {function} onChange function to run when any of the elements that needs its state to be tracked changes
 * @param {array} children
 * @returns {React.element} ChartsControl
 * @see {@link https://docs.google.com/document/d/1C4cdiO8yj09W5lRlBsVqgRZnz6qT5xiD2aZEvZE3GsY/edit#heading=h.n85vg2kyik2y|SPECS}
 */
const ChartControls = ({ disable = false, commodityApiData = {}, onChange, children }) => {
  const [selectedChart, setSelectedChart] = useState(null);
  const [selectedCrossing, setSelectedCrossing] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [crossingsData, setCrossingsData] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [originsData, setOriginsData] = useState([]);

  const { t, i18n } = useTranslation(['mpVolumeAndPrices']);

  useEffect(() => {
    setChartData(graphSelectData.map((e) => ({ ...e, label: t(`mpVolumeAndPrices:${e.label}`) })));
  }, [i18n.language, t]);

  useEffect(() => {
    if (Object.keys(commodityApiData).length) {
      const origins = originSelectData(commodityApiData.origins);
      const crossings = crossingSelectData(commodityApiData.crossings);
      const packages = packagesTypesSelectData(commodityApiData.packages);

      setOriginsData(origins);
      setCrossingsData(crossings);
      setPackagesData(packages);

      setSelectedOrigin(origins[0].value);
      setSelectedCrossing(crossings[0].value);
      setSelectedPackage(packages[0].value);
    }
  }, [commodityApiData, commodityApiData.origins, commodityApiData.crossings, commodityApiData.packages]);

  useEffect(() => {
    onChange({ chart: selectedChart, origin: selectedOrigin, package: selectedPackage, crossing: selectedCrossing });
  }, [selectedChart, selectedOrigin, selectedPackage, selectedCrossing, onChange]);

  return (
    <>
      <div className="pr-l">
        <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:chartData')}</p>
        <Select data={chartData} id="cc-chart-data-select" setSelectedData={setSelectedChart} disabled={disable} />
      </div>
      {children}
      {[GRAPH_VALUES.ALL, GRAPH_VALUES.PRICES].includes(selectedChart) && (
        <>
          <div className="pr-l">
            <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:crossingPoint')}</p>
            <Select
              data={crossingsData}
              id="cc-crossing-select"
              optionSelectedProp={selectedCrossing}
              setSelectedData={setSelectedCrossing}
              disabled={disable}
            />
          </div>
          <div className="pr-l">
            <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:packageType')}</p>
            <Select
              data={packagesData}
              id="cc-package-type-select"
              optionSelectedProp={selectedPackage}
              setSelectedData={setSelectedPackage}
              disabled={disable}
            />
          </div>
        </>
      )}
      {[GRAPH_VALUES.VOLUME].includes(selectedChart) && (
        <div className="pr-l">
          <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:origins')}</p>
          <Select
            data={originsData}
            id="cc-origin-select"
            optionSelectedProp={selectedOrigin}
            setSelectedData={setSelectedOrigin}
            disabled={disable}
          />
        </div>
      )}
    </>
  );
};

ChartControls.propTypes = {
  disable: bool,
  children: oneOfType([arrayOf(node), node]),
  commodityApiData: shape({
    origins: array,
    crossings: array,
    packages: array,
  }),
  onChange: func.isRequired,
};

export default ChartControls;
