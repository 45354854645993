/*
 * @param {string} name
 * @param {string} data
 * @param {number} maxAge
 */
const setCookie = (name, data, maxAge = 86400) => {
  // 1 Day = 24 Hrs = 24*60*60 = 86400
  document.cookie = `${name}=${data}; max-age=${maxAge}; path=/`;
};

/*
 * @param {object} data
 */
const saveAccessCookie = (data) => {
  setCookie('access_token', data.access);
  setCookie('refresh_token', data.refresh, 86400 * 7); //1 week expiration
};

/*
 * @param {string} cookieName
 */
const getCookieValue = (cookieName) => {
  try {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith(cookieName))
      .split('=')[1];
    return cookieValue;
  } catch (err) {
    return '';
  }
};

/*
 * @param {string} cookieName
 */
const deleteCookie = (cookieName) => {
  document.cookie = `${cookieName}=;max-age=-999999; path=/`;
};

export default { saveAccessCookie, getCookieValue, setCookie, deleteCookie };
