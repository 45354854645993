import React from 'react';
import PropTypes from 'prop-types';
import document_definitions from '../utils/page_definition/document_definitions';
import './Document.scss';

const { documentContainer, documentAnchor } = document_definitions;

const Document = ({ file, name, styleClass }) => {
  return (
    <div className={`document-container ${styleClass}`} data-test={documentContainer}>
      <a className="document-anchor" data-test={documentAnchor} href={file} rel="noopener noreferrer" target="_blank">
        {name}
      </a>
    </div>
  );
};

Document.defaultProps = {
  styleClass: '',
};

Document.propTypes = {
  file: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  styleClass: PropTypes.string,
};

export default Document;
