import { bool, func } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select';
import ToggleSwitch from 'components/ToggleSwitch';
import { weeklyDailyToogle } from '../../utils/weeklyDailyToogle';
import { monthSelect } from '../../utils/monthSelect';
import { TIME_PERIODS } from '../../utils/timeControls';
import { startingWeek, getEndingWeek, startingDay, getEndingDay } from '../../data/timeControls';
import './style.scss';

/*
 * @param {func} onChange callback to send data to father
 * @see {@link https://docs.google.com/document/d/1088Gf5KY3CMNTxCBVyrtSJ7_-OZZ2H20FHqp-xE8o1w/edit#|SPECS}
 */
const TimeControls = ({ disable = false, onChange }) => {
  const [activeTimePeriod, setActiveTimePeriod] = useState(TIME_PERIODS.WEEKLY);
  const [selectedStartingWeek, setSelectedStartingWeek] = useState(startingWeek[0].value);
  const [selectedEndingWeek, setSelectedEndingWeek] = useState(getEndingWeek(selectedStartingWeek)[0]?.value);
  const [selectedStartingDate, setSelectedStartingDate] = useState(monthSelect[0].value);
  const [selectedDay, setSelectedDay] = useState(startingDay[0].value);
  const [selectedDateRange, setSelectedDateRange] = useState(getEndingDay(selectedDay, selectedStartingDate)[0]?.value);
  const [endingWeekSelectData, setEndingWeekSelectData] = useState([]);
  const [startingDateSelectData, setStartingDateSelectData] = useState([]);
  const [daysToShowSelectData, setDaysToShowSelectData] = useState([]);

  const { t, i18n } = useTranslation(['mpVolumeAndPrices']);

  // change the select data language
  useEffect(() => {
    setStartingDateSelectData(monthSelect.map((e) => ({ ...e, label: t(`months:${e.label}`) })));
  }, [i18n.language]);

  useEffect(() => {
    onChange({
      time: activeTimePeriod,
      startingWeek: selectedStartingWeek,
      endingWeek: selectedEndingWeek,
      startingDate: selectedStartingDate,
      day: selectedDay,
      dateRange: selectedDateRange,
    });
  }, [
    onChange,
    activeTimePeriod,
    selectedStartingWeek,
    selectedEndingWeek,
    selectedStartingDate,
    selectedDay,
    selectedDateRange,
  ]);

  /*
   * daily select rule
   * @see {@link https://docs.google.com/document/d/1088Gf5KY3CMNTxCBVyrtSJ7_-OZZ2H20FHqp-xE8o1w/edit#heading=h.nku64tca7shp|SPEC}
   */
  useEffect(() => {
    setDaysToShowSelectData(getEndingDay(selectedDay, selectedStartingDate));
  }, [selectedStartingDate, selectedDay]);

  /*
   * weekly select rule
   * @see {@link https://docs.google.com/document/d/1088Gf5KY3CMNTxCBVyrtSJ7_-OZZ2H20FHqp-xE8o1w/edit#heading=h.nku64tca7shp|SPEC}
   */
  useEffect(() => {
    setEndingWeekSelectData(getEndingWeek(selectedStartingWeek));

    if (selectedStartingWeek < selectedEndingWeek) {
      const selectedEndingWeekBKP = selectedEndingWeek;
      setSelectedEndingWeek(selectedEndingWeekBKP);
    } else setSelectedEndingWeek(53);
  }, [selectedStartingWeek]);

  return (
    <>
      <div className="pr-l">
        <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:timeAndPeriod')}</p>
        <ToggleSwitch
          falseValue={t('mpVolumeAndPrices:daily')}
          inheritHook={(weeklyDaily) => setActiveTimePeriod(weeklyDailyToogle(weeklyDaily))}
          label=""
          styleClass="time-controls__toggle"
          trueValue={t('mpVolumeAndPrices:weekly')}
          disabled={disable}
        />
      </div>
      {TIME_PERIODS.WEEKLY === activeTimePeriod && (
        <>
          <div className="pr-l">
            <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:startingWeek')}</p>
            <Select
              data={startingWeek}
              disabled={disable}
              id="tc-starting-week-select"
              optionSelectedProp={selectedStartingWeek}
              setSelectedData={setSelectedStartingWeek}
            />
          </div>
          <div className="pr-l">
            <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:endingWeek')}</p>
            <Select
              data={endingWeekSelectData}
              disabled={disable}
              id="tc-ending-week-select"
              optionSelectedProp={selectedEndingWeek}
              setSelectedData={setSelectedEndingWeek}
            />
          </div>
        </>
      )}
      {TIME_PERIODS.DAILY === activeTimePeriod && (
        <>
          <div className="pr-l">
            <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:startingDate')}</p>
            <Select
              data={startingDateSelectData}
              id="tc-starting-date-select"
              setSelectedData={setSelectedStartingDate}
              optionSelectedProp={selectedStartingDate}
              disabled={disable}
            />
          </div>
          <div className="pr-l" style={{ alignSelf: 'flex-end' }}>
            <Select
              data={startingDay}
              disabled={disable}
              id="tc-day-element-select"
              optionSelectedProp={selectedDay}
              setSelectedData={setSelectedDay}
            />
          </div>
          <div className="pr-l">
            <p className="fs-4 neutral-900 pb-base">{t('mpVolumeAndPrices:daysToShow')}</p>
            <Select
              data={daysToShowSelectData}
              disabled={disable}
              id="tc-date-range-select"
              optionSelectedProp={selectedDateRange}
              setSelectedData={setSelectedDateRange}
            />
          </div>
        </>
      )}
    </>
  );
};

TimeControls.propTypes = {
  disable: bool,
  onChange: func,
};
export default TimeControls;
