import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ButtonLoading from 'components/ButtonLoading';
import Input from 'components/Input';
import InputShowPassword from 'components/InputShowPassword';
import utilsApi from 'utils/api/UtilsApi';
import WebStorage from 'utils/WebStorage/WebStorage';
import { ROUTES } from 'constants/routes';
import './style.scss';

const ModalLoginForm = () => {
  const { t } = useTranslation('loginModal');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);

  const handleOnSubmit = async (ev) => {
    ev.preventDefault();
    setErrorLogin(false);

    const email = ev.target['login-modal-form-email'].value;
    const password = ev.target['login-modal-form-password'].value;

    if (!email || !password) return;

    setLoading(true);

    try {
      const res = await utilsApi.login(email, password);
      WebStorage.saveAccessCookie(res.data);
      const userRes = await utilsApi.getProfileInfo();
      WebStorage.setCookie('user_photo', userRes.data.photo, 86400 * 7);
      setLoading(false);

      const redirectTo = history?.location?.state?.redirectAfterLogin;
      history.replace(redirectTo || ROUTES.MARKETPLACE);
    } catch (err) {
      if (err.response.status === 401) setErrorLogin(true);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="pb-xl">
        <Input
          error={errorLogin}
          focused={true}
          id="login-modal-form-email"
          label={t('email')}
          name="login-modal-form-email"
          placeholder="ie. francisco@fridanet.com"
          required={true}
          type="email"
        />
      </div>
      <InputShowPassword
        error={errorLogin}
        id="login-modal-form-password"
        label={t('password')}
        placeholder="********"
        required={true}
      />
      {errorLogin && <p className="fs-3 red-900 pt-base">{t('errorLoginMessage')}</p>}
      <p className="pt-m">
        <Link
          className="modal-login-form__forgot-password fs-4 fw500 primary-900"
          to={{
            search: ROUTES.PARAMS.MODAL_RESET_PASSWORD,
          }}
        >
          {t('forgotPassword')}
        </Link>
      </p>
      <div className="df pt-l">
        <div className="mr-m">
          <ButtonLoading loading={loading}>{t('loginButtonText')}</ButtonLoading>
        </div>
        <Link className="fk-button button-secondary-complementary button-small fw600 tdn dib" to={ROUTES.INTELLIGENCE}>
          <span className="lt-sm-hide">{t('backButtonText')}</span>
          <span className="gt-xs-hide">{t('backButtonTextShort')}</span>
        </Link>
      </div>
    </form>
  );
};

export default ModalLoginForm;
