import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NoData from '../../../../components/NoData';
import getRetailPricesOptions from '../../../../utils/Highcharts/RetailPriceOptions';
import generateWeekStructure from '../../../../utils/Highcharts/dataHandlers/retailGraph';
import isEmptyObject from '../../../../utils/common/isEmptyObject';
import shippingPointSelectors from '../../../../utils/page_definition/shipping_point';
import utilsApi from '../../../../utils/api/UtilsApi';
import { GetMonth } from '../../../../utils/common/DateHandler';
import './Retail.scss';

const Retail = () => {
  const [highChartsOptions, setHighChartsOptions] = useState();
  const [retailPrice, setRetailPrice] = useState();
  const [error, setError] = useState();

  const selectedDate = useSelector((state) => state.selectedDate.date);
  const activeProduct = useSelector((state) => state.products.active);

  // i18n
  const { t } = useTranslation('retail');

  useEffect(() => {
    const updateGraph = () => {
      // only when the currentCommodity hook exists
      if (retailPrice) {
        const { data } = retailPrice;
        // ToolTipHeaderFormat
        const ToolTipHeaderFormat = `<span style="font-size: 15px"> ${t('week')} {point.key}</span><br/><br/>`;
        // get the Highchart options.
        const cloneHCH = { ...getRetailPricesOptions(t('weekNumber')) };
        // array to parse the series array with the differents imports types: {Current, Future, Last}
        const hcSeries = [];
        // Parse the Serie for the Current Season data.

        const completeWeekStructure = generateWeekStructure(
          data.organic,
          data.traditional,
          t('organicLabel'),
          t('conventionalLabel'),
        );
        if (isEmptyObject(data)) {
          hcSeries.push(completeWeekStructure.organic);
          hcSeries.push(completeWeekStructure.traditional);
        }
        //TODO: add the mocks for Future average and last series JUST FOR DEMO.
        // add the ToolTipHeaderFormat to the tooltip options.
        cloneHCH.tooltip.headerFormat = ToolTipHeaderFormat;
        // Set the series object.
        cloneHCH.series = hcSeries;
        setHighChartsOptions(cloneHCH);
      }
    };
    updateGraph();
  }, [retailPrice, t('organicLabel')]);

  useEffect(() => {
    // service call to get organic and traditional retail price
    const CancelToken = utilsApi.cancelToken();
    const getRetailPrice = async () => {
      const organic = await utilsApi
        .commodityRetailPrices(activeProduct, 'True', selectedDate, CancelToken.token)
        .then((res) => res.data)
        .catch(() => {
          return { data: [] };
        });
      const traditional = await utilsApi
        .commodityRetailPrices(activeProduct, 'False', selectedDate, CancelToken.token)
        .then((res) => res.data)
        .catch(() => {
          return { data: [] };
        });
      if (isEmptyObject(organic.data) && isEmptyObject(traditional.data))
        setRetailPrice({
          from: GetMonth(organic.from_date),
          to: GetMonth(organic.to_date),
          data: {
            organic: organic.data,
            traditional: traditional.data,
          },
        });
      else {
        setRetailPrice();
        setError({ error: true });
      }
    };

    if (activeProduct) {
      getRetailPrice();
    }

    return function () {
      CancelToken.cancel();
    };
  }, [activeProduct, selectedDate]);

  const renderHighCharts = () =>
    retailPrice && highChartsOptions ? (
      <div className="retail-graph-container" data-test="retail-graph-container">
        <p className="retail-graph-custom-yaxis-label">{t('yaxisLabel')}</p>
        <HighchartsReact updateArgs={[true, true, true]} highcharts={Highcharts} options={highChartsOptions} />
      </div>
    ) : (
      <NoData error={error} loading={false} />
    );

  // RETURN HIGHCHART
  return (
    <div data-test={shippingPointSelectors.retailPricesChartContainer} className="retail-container">
      <div className="retail-header">
        <div className="retail-header-title">
          <h3>{t('headerTitle')}</h3>
        </div>
        <div className="retail-graph-header-description">
          <p>{t('headerDescription')}</p>
        </div>
      </div>
      {renderHighCharts()}
    </div>
  );
};

export default Retail;
