import { GetDay } from '../../common/DateHandler';

/*
 * method to handle the daily/weekly data
 * @param {string} type
 * @param {number || string} value
 * @return {number || string}
 */
const handleDailyWeeklyxAxis = (type, value) => {
  if (type === 'daily') {
    if (typeof value === 'number') return value;
    return `${GetDay(value)}`;
  }
  return value;
};

const VolumeAndPricesDataHandler = {
  /*
   * volume method to handle the trends data
   * @param {string} type weekly, daily
   * @param {array:object} data [{total, week || report_date, year}]
   * @param {number}  id year number
   * @param {string} name the serie name
   * @return {array:object} array of data for highcharts
   */
  volume: (type, data, id, name) => {
    const baseArray = [];
    const xAxis = type === 'weekly' ? 'week' : 'report_date';
    const serieName = `${name}` || '';

    if (data && data.length > 0) {
      data.forEach((obj) => baseArray.push([`${handleDailyWeeklyxAxis(type, obj[xAxis])}`, obj.total]));
      const serieOptions = {
        dashStyle: 'solid',
        data: baseArray,
        id: `${type}-volume-${id}`,
        name: serieName,
        yAxis: 0,
        yearName: id,
        connectNulls: false,
      };

      return serieOptions;
    }
    return '';
  },

  /*
   * TODO: the real serie name should be the {CROSSING.. / PACKAGE TYPE.. - YEAR }
   * prices method to handle the trend data
   * @param {string} type weekly || daily
   * @param {array:object} data [{average_price, week || report_date, year}]
   * @param {number}  id year number
   * @param {string} name the serie name
   * @return {array:object} array of data for highcharts
   */
  prices: (type, data, id, name) => {
    const baseArray = [];
    const xAxis = type === 'weekly' ? 'week' : 'report_date';
    const serieName = `${name}` || '';

    if (data && data.length > 0) {
      data.forEach((obj) => baseArray.push([`${handleDailyWeeklyxAxis(type, obj[xAxis])}`, obj.average_price]));
      const serieOptions = {
        dashStyle: 'shortdot',
        data: baseArray,
        id: `${type}-prices-${id}`,
        name: serieName,
        yAxis: 1,
        yearName: id,
        connectNulls: true,
      };

      return serieOptions;
    }
    return '';
  },
  /*
   * complete the weekly array
   * TODO: still pending check forecastFuture and forecastPast
   * @param {array} weeksArray array of all responses
   * @param {array} forecastFuture
   * @param {array} forecastPast
   * @return {object} object filtered by data
   */
  completeWeekly: (weeklyArray) => {
    // weeksArrar response filtered
    return Object.keys(weeklyArray).map((key) => weeklyArray[key]);
  },

  /*
   * comnplete daily graph data
   * @param {array} dailyArray array of data from req
   * @param {number} amountOfDays number of amount of days
   * @return {object} object of parsed and filter daily data
   */
  completeDaily: (dailyArray) => {
    // daily years array
    const dailyArrayYearsValues = Object.values(dailyArray).filter((serie) => Object.values(serie));
    // daily years serie values in the same array
    const dailyArraySerieValues = dailyArrayYearsValues
      .map((serie) => Object.values(serie))
      .flat()
      .flat();
    // sorted and cleaned
    const dailyCleanedArray = dailyArraySerieValues
      .map((element) => ({ report_date: GetDay(element.report_date) }))
      .sort((a, b) => new Date(a.report_date) - new Date(b.report_date));
    // unique array by report date,
    const uniqueDailyArray = new Set(dailyCleanedArray.map((e) => e.report_date));
    // base array with unique labels and values into null
    const baseDailyArray = [...uniqueDailyArray].map((day) => ({
      total: null,
      average_price: null,
      report_date: day,
    }));

    const dailyArrayFiltered = Object.keys(dailyArray).map((key) => dailyArray[key]);

    // filter and parsed the dailyArray with respect the baseDailyArray
    const dailyArrayFilteredParsed = dailyArrayFiltered.map((yearGraphData) => {
      /*
       * get prices and volume filtered by report date and with the baseDailyArray
       * @param {string} type volume or prices
       * @return {array}
       */
      const filterMovementsPrices = (type) =>
        baseDailyArray.map((defaultValue) => {
          const parsedValue = yearGraphData[type].find((data) => GetDay(data.report_date) === defaultValue.report_date);

          return parsedValue || defaultValue;
        });

      const volume = filterMovementsPrices('volume');
      const prices = filterMovementsPrices('prices');

      return { ...yearGraphData, volume, prices };
    });

    return dailyArrayFilteredParsed;
  },

  /*
   * forecast parsed data
   * @param {string} type future or past
   * @param {string} name serie name
   * @param {array} forecastData
   * @return {object} serie object
   */
  forecast: (type, name, forecastData) => {
    const baseArray = [];

    forecastData.forEach((element) => {
      baseArray.push([`${element.week}`, element.lowest, element.highest]);
    });

    const averagePointFormat = {
      pointFormat: `
      <div class="hc-volume-range-custom-tooltip">
        <span style="color:{point.color}">●</span>
        <div>
          <p>{series.name}: </p>
          <b>{point.high} - {point-low}</b>
        </div>
      </div>`,
    };

    const areaRangeOptions = {
      name,
      data: baseArray,
      zIndex: 0,
      tooltip: averagePointFormat,
      type: 'arearange',
    };

    if (type === 'future') {
      areaRangeOptions.fillColor = '#120dd11A';
      areaRangeOptions.lineColor = '#120dd1';
      areaRangeOptions.color = '#120dd1';
    }

    if (type === 'past') {
      areaRangeOptions.fillColor = '#fe9dd81A';
      areaRangeOptions.lineColor = '#fe9dd8';
      areaRangeOptions.color = '#fe9dd8';
    }

    return areaRangeOptions;
  },
};

export default VolumeAndPricesDataHandler;
