import React, { useEffect, useState } from 'react';
import Button from './Button';
import Message from './Message';
import PropTypes from 'prop-types';
import switchCaseHandler from '../utils/common/switch';
import { useTranslation } from 'react-i18next';
import './Tab.scss';

/*
 * @param {object} optionsObject object of the differents cases.
 * @param {object} optionsOrder the order of the objects cases.
 * @param {object} optionsTitle the title of the object used as label on the buttons.
 * @param {function} tabChange custom use when the options changes.
 * @return {React.Element} tab component
 */
const Tab = ({
  confirmProgram,
  handleMessageAction,
  optionsObject,
  optionsOrder,
  optionsTitle,
  selectedProgramTab,
  tabChange,
}) => {
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [tabOption, setTabOptions] = useState(0);
  const { t } = useTranslation('Message');

  useEffect(() => {
    if (selectedProgramTab) {
      setTabOptions(selectedProgramTab);
      tabChange(selectedProgramTab);
    }

    return function () {
      setShowWarningMessage(0);
      setTabOptions(0);
    };
  }, [selectedProgramTab]);

  const tabOptionsCase = switchCaseHandler(optionsObject)('');

  /*
   * handle tab change
   * @param {number}  optionIndex to change the tab
   * @param {boolean} continueMessage if confirmProgram is true show the warning message
   */
  const handleTabChange = (optionIndex, continueMessage = confirmProgram || false) => {
    if (!continueMessage) {
      setTabOptions(optionIndex);
      if (tabChange) tabChange(optionIndex);
    } else setShowWarningMessage(true);
  };

  const renderButtonsTab = () => (
    <div className="tab-buttons-container">
      {optionsOrder.map((option, index) => (
        <Button
          key={index}
          onClick={() => handleTabChange(index)}
          shape="main"
          size="small"
          styleClass={`tab-button tab-button-active-${index === tabOption}`}
          type="secondary"
        >
          {optionsTitle[index]}
        </Button>
      ))}
    </div>
  );

  /*
   * method to handle the message action
   * @param {boolean} action pressed
   */
  const handleMessageOnClick = (action) => {
    if (action) {
      handleTabChange(1, false);
      handleMessageAction();
    }
    setShowWarningMessage(false);
  };

  const renderWarningMessage = () => {
    if (showWarningMessage)
      return (
        <Message
          description={t('leaveProgramMessageDescription')}
          handleMessage={handleMessageOnClick}
          title={t('leaveProgramMessageTitle')}
          type="confirm"
        />
      );
    return '';
  };

  return (
    <div className="tab-container">
      {renderButtonsTab()}
      <div className="tab-content">{tabOptionsCase(optionsOrder[tabOption])}</div>
      <div>{renderWarningMessage()}</div>
    </div>
  );
};

Tab.defaultProps = {
  confirmProgram: undefined,
  handleMessageAction: undefined,
  selectedProgramTab: undefined,
  tabChange: undefined,
};

Tab.propTypes = {
  confirmProgram: PropTypes.bool,
  handleMessageAction: PropTypes.func,
  optionsObject: PropTypes.object.isRequired,
  optionsOrder: PropTypes.array.isRequired,
  optionsTitle: PropTypes.object.isRequired,
  selectedProgramTab: PropTypes.number,
  tabChange: PropTypes.func,
};

export default Tab;
