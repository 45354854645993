import React from 'react';

/* eslint-disable */
const AsparagusIcon = ({ styleClass, dataTest }) => (
  <svg className={styleClass} viewBox="0 0 24 24" data-test={dataTest}>
    <title>Asparagus</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-fruits-asparagus" fillRule="nonzero">
        <g
          id="Artboard-1-copy-6"
          transform="translate(9.000000, 12.000000) rotate(-30.000000) translate(-9.000000, -12.000000) translate(5.000000, 0.000000)"
        >
          <path
            d="M4,23 C3.44771525,23 3,22.5522847 3,22 L3,11.54 L3.39,11.6 C3.79168699,11.6900015 4.20831301,11.6900015 4.61,11.6 L5,11.54 L5,22 C5,22.5522847 4.55228475,23 4,23 Z"
            id="Path"
            fill="#456730"
          ></path>
          <path
            d="M6.31,8.87 C5.73532192,8.15215694 5.06198422,7.5192195 4.31,6.99 L4.11,6.85 L4.18,6.62 C4.4704988,5.75168442 5.06785788,5.0192084 5.86,4.56 L6.19,4.38 L6.33,4.73 C6.75085548,5.77002782 6.97792543,6.87826477 7,8 C6.99690137,8.24981676 6.96331968,8.49832122 6.9,8.74 L6.73,9.4 L6.31,8.87 Z"
            id="Path"
            fill="#AACC38"
          ></path>
          <path
            d="M6.81,8.5 C6.41393466,7.83780256 5.92192394,7.23795387 5.35,6.72 L5.21,6.59 L5.21,6.37 C5.37598671,5.58585215 5.80245092,4.88095262 6.42,4.37 L6.66,4.21 L6.75,4.54 C7.0631137,5.54089891 7.23148466,6.58143146 7.25,7.63 C7.24699225,7.86823181 7.22357323,8.10576761 7.18,8.34 L7.11,9 L6.81,8.5 Z"
            id="Path"
            fill="#BCD63D"
          ></path>
          <path
            d="M3.17,6.31 C2.71401919,6.07149825 2.23037026,5.8901299 1.73,5.77 L1.37,5.69 L1.49,5.34 C1.96508294,3.81822302 2.74035076,2.40709959 3.77,1.19 L4,1 L4.23,1.22 C4.83951145,1.91351164 5.35095086,2.68740022 5.75,3.52 L5.9,3.82 L5.6,3.97 C4.69825541,4.44905126 3.99119747,5.226815 3.6,6.17 L3.46,6.51 L3.17,6.31 Z"
            id="Path"
            fill="#AACC38"
          ></path>
          <path
            d="M3.93,5.39 C3.5391863,5.21481484 3.12996376,5.08399779 2.71,5 L2.4,5 L2.5,4.76 C2.92825303,3.67548305 3.59068806,2.69890357 4.44,1.9 L4.64,1.75 L4.84,1.9 C5.34587852,2.35608326 5.78068943,2.88527174 6.13,3.47 L6.26,3.67 L6,3.77 C5.28411152,4.06183859 4.69075961,4.59161708 4.32,5.27 L4.21,5.5 L3.93,5.39 Z"
            id="Path"
            fill="#DEE67B"
          ></path>
          <path
            d="M4,11 C2.34314575,11 1,9.65685425 1,8 C1.00648336,7.55335311 1.05334081,7.10820733 1.14,6.67 L1.2,6.34 L1.54,6.41 C3.39655911,6.97028917 5.00784992,8.14438073 6.11,9.74 L6.26,9.98 L6.06,10.17 C5.50479833,10.7000107 4.76756208,10.9970525 4,11 Z"
            id="Path"
            fill="#68973F"
          ></path>
          <path
            d="M4.37,10.16 C2.71314575,10.16 1.37,8.81685425 1.37,7.16 C1.37149917,6.71637536 1.41841039,6.27406959 1.51,5.84 L1.57,5.5 L1.9,5.57 C3.76349791,6.12722217 5.37988428,7.30598429 6.48,8.91 L6.63,9.14 L6.43,9.33 C5.87479833,9.86001069 5.13756208,10.1570525 4.37,10.16 Z"
            id="Path"
            fill="#7EAF41"
          ></path>
          <path
            d="M4.6,22.13 C4.04771525,22.13 3.6,21.6822847 3.6,21.13 L3.6,11.28 L3.51,11.28 L3.12,11.22 L3.12,21.67 C3.12,22.2222847 3.56771525,22.67 4.12,22.67 C4.53993595,22.6641139 4.91144877,22.3964649 5.05,22 C4.91410313,22.0822914 4.75884483,22.1271438 4.6,22.13 L4.6,22.13 Z"
            id="Path"
            fill="#36481F"
          ></path>
          <path
            d="M4.67,16.41 L4.67,22 C4.67,22.3700308 4.37003078,22.67 4,22.67 C3.62996922,22.67 3.33,22.3700308 3.33,22 L3.33,11.93 C3.77213602,12.0199709 4.22786398,12.0199709 4.67,11.93 L4.67,15.26 L6,15.26 L6,11.44 C7.23017814,10.7297908 7.99142615,9.42044424 8,8 C8,4.78 5.38,0 4,0 C2.62,0 0,4.78 0,8 C0.00857384502,9.42044424 0.769821856,10.7297908 2,11.44 L2,22 C2,23.1045695 2.8954305,24 4,24 C5.1045695,24 6,23.1045695 6,22 L6,16.41 L4.67,16.41 Z M6,4.85 C6.40680224,5.85244001 6.63360978,6.91877394 6.67,8 C6.6619183,8.22309115 6.62836728,8.44452788 6.57,8.66 C5.97081186,7.91566911 5.26681424,7.26219763 4.48,6.72 C4.74053544,5.93154079 5.28140313,5.2661312 6,4.85 L6,4.85 Z M4,1.43 C4.58416457,2.09533992 5.07224095,2.83923564 5.45,3.64 C4.48794823,4.1560406 3.73503536,4.99025392 3.32,6 C2.83927335,5.74930923 2.32846454,5.56111651 1.8,5.44 C2.26596461,3.97314817 3.01321481,2.61111485 4,1.43 L4,1.43 Z M1.33,8 C1.33652541,7.57319978 1.38340079,7.14797319 1.47,6.73 C3.24303538,7.27362655 4.77979192,8.40152126 5.83,9.93 C5.33749012,10.4022512 4.68233364,10.6671779 4,10.67 C2.52993406,10.6591102 1.34088982,9.47006594 1.33,8 Z"
            id="Shape"
            fill="#333232"
          ></path>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(4.325732, 4.096456) rotate(-45.020000) translate(-4.325732, -4.096456) "
            cx="4.32573232"
            cy="4.09645618"
            rx="1"
            ry="1.15"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(5.333402, 6.248851) rotate(-45.020000) translate(-5.333402, -6.248851) "
            cx="5.3334024"
            cy="6.24885142"
            rx="1"
            ry="1"
          ></ellipse>
        </g>
        <g id="Artboard-1-copy-6" transform="translate(8.000000, 0.000000)">
          <path
            d="M4,23 C3.44771525,23 3,22.5522847 3,22 L3,11.54 L3.39,11.6 C3.79168699,11.6900015 4.20831301,11.6900015 4.61,11.6 L5,11.54 L5,22 C5,22.5522847 4.55228475,23 4,23 Z"
            id="Path"
            fill="#456730"
          ></path>
          <path
            d="M6.31,8.87 C5.73532192,8.15215694 5.06198422,7.5192195 4.31,6.99 L4.11,6.85 L4.18,6.62 C4.4704988,5.75168442 5.06785788,5.0192084 5.86,4.56 L6.19,4.38 L6.33,4.73 C6.75085548,5.77002782 6.97792543,6.87826477 7,8 C6.99690137,8.24981676 6.96331968,8.49832122 6.9,8.74 L6.73,9.4 L6.31,8.87 Z"
            id="Path"
            fill="#AACC38"
          ></path>
          <path
            d="M6.81,8.5 C6.41393466,7.83780256 5.92192394,7.23795387 5.35,6.72 L5.21,6.59 L5.21,6.37 C5.37598671,5.58585215 5.80245092,4.88095262 6.42,4.37 L6.66,4.21 L6.75,4.54 C7.0631137,5.54089891 7.23148466,6.58143146 7.25,7.63 C7.24699225,7.86823181 7.22357323,8.10576761 7.18,8.34 L7.11,9 L6.81,8.5 Z"
            id="Path"
            fill="#BCD63D"
          ></path>
          <path
            d="M3.17,6.31 C2.71401919,6.07149825 2.23037026,5.8901299 1.73,5.77 L1.37,5.69 L1.49,5.34 C1.96508294,3.81822302 2.74035076,2.40709959 3.77,1.19 L4,1 L4.23,1.22 C4.83951145,1.91351164 5.35095086,2.68740022 5.75,3.52 L5.9,3.82 L5.6,3.97 C4.69825541,4.44905126 3.99119747,5.226815 3.6,6.17 L3.46,6.51 L3.17,6.31 Z"
            id="Path"
            fill="#AACC38"
          ></path>
          <path
            d="M3.93,5.39 C3.5391863,5.21481484 3.12996376,5.08399779 2.71,5 L2.4,5 L2.5,4.76 C2.92825303,3.67548305 3.59068806,2.69890357 4.44,1.9 L4.64,1.75 L4.84,1.9 C5.34587852,2.35608326 5.78068943,2.88527174 6.13,3.47 L6.26,3.67 L6,3.77 C5.28411152,4.06183859 4.69075961,4.59161708 4.32,5.27 L4.21,5.5 L3.93,5.39 Z"
            id="Path"
            fill="#DEE67B"
          ></path>
          <path
            d="M4,11 C2.34314575,11 1,9.65685425 1,8 C1.00648336,7.55335311 1.05334081,7.10820733 1.14,6.67 L1.2,6.34 L1.54,6.41 C3.39655911,6.97028917 5.00784992,8.14438073 6.11,9.74 L6.26,9.98 L6.06,10.17 C5.50479833,10.7000107 4.76756208,10.9970525 4,11 Z"
            id="Path"
            fill="#68973F"
          ></path>
          <path
            d="M4.37,10.16 C2.71314575,10.16 1.37,8.81685425 1.37,7.16 C1.37149917,6.71637536 1.41841039,6.27406959 1.51,5.84 L1.57,5.5 L1.9,5.57 C3.76349791,6.12722217 5.37988428,7.30598429 6.48,8.91 L6.63,9.14 L6.43,9.33 C5.87479833,9.86001069 5.13756208,10.1570525 4.37,10.16 Z"
            id="Path"
            fill="#7EAF41"
          ></path>
          <path
            d="M4.6,22.13 C4.04771525,22.13 3.6,21.6822847 3.6,21.13 L3.6,11.28 L3.51,11.28 L3.12,11.22 L3.12,21.67 C3.12,22.2222847 3.56771525,22.67 4.12,22.67 C4.53993595,22.6641139 4.91144877,22.3964649 5.05,22 C4.91410313,22.0822914 4.75884483,22.1271438 4.6,22.13 L4.6,22.13 Z"
            id="Path"
            fill="#36481F"
          ></path>
          <path
            d="M4.67,16.41 L4.67,22 C4.67,22.3700308 4.37003078,22.67 4,22.67 C3.62996922,22.67 3.33,22.3700308 3.33,22 L3.33,11.93 C3.77213602,12.0199709 4.22786398,12.0199709 4.67,11.93 L4.67,15.26 L6,15.26 L6,11.44 C7.23017814,10.7297908 7.99142615,9.42044424 8,8 C8,4.78 5.38,0 4,0 C2.62,0 0,4.78 0,8 C0.00857384502,9.42044424 0.769821856,10.7297908 2,11.44 L2,22 C2,23.1045695 2.8954305,24 4,24 C5.1045695,24 6,23.1045695 6,22 L6,16.41 L4.67,16.41 Z M6,4.85 C6.40680224,5.85244001 6.63360978,6.91877394 6.67,8 C6.6619183,8.22309115 6.62836728,8.44452788 6.57,8.66 C5.97081186,7.91566911 5.26681424,7.26219763 4.48,6.72 C4.74053544,5.93154079 5.28140313,5.2661312 6,4.85 L6,4.85 Z M4,1.43 C4.58416457,2.09533992 5.07224095,2.83923564 5.45,3.64 C4.48794823,4.1560406 3.73503536,4.99025392 3.32,6 C2.83927335,5.74930923 2.32846454,5.56111651 1.8,5.44 C2.26596461,3.97314817 3.01321481,2.61111485 4,1.43 L4,1.43 Z M1.33,8 C1.33652541,7.57319978 1.38340079,7.14797319 1.47,6.73 C3.24303538,7.27362655 4.77979192,8.40152126 5.83,9.93 C5.33749012,10.4022512 4.68233364,10.6671779 4,10.67 C2.52993406,10.6591102 1.34088982,9.47006594 1.33,8 Z"
            id="Shape"
            fill="#333232"
          ></path>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(4.325732, 4.096456) rotate(-45.020000) translate(-4.325732, -4.096456) "
            cx="4.32573232"
            cy="4.09645618"
            rx="1"
            ry="1.15"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(5.333402, 6.248851) rotate(-45.020000) translate(-5.333402, -6.248851) "
            cx="5.3334024"
            cy="6.24885142"
            rx="1"
            ry="1"
          ></ellipse>
        </g>
      </g>
    </g>
  </svg>
);

export default AsparagusIcon;
