export const GRAPH_VALUES = {
  ALL: 'all',
  VOLUME: 'volume',
  PRICES: 'prices',
};

export const graphSelectData = [
  {
    id: 'volumeAndPrices',
    value: GRAPH_VALUES.ALL,
    label: 'volumeAndPrices',
  },
  {
    id: 'onlyVolume',
    value: GRAPH_VALUES.VOLUME,
    label: 'onlyVolume',
  },
  {
    id: 'onlyPrices',
    value: GRAPH_VALUES.PRICES,
    label: 'onlyPrices',
  },
];
