import { managementVersion } from '../../../../../package.json';
import PropTypes from 'prop-types';
import React from 'react';
import ProfileInfo from 'components/ProfileInfo';
import useProfileData from 'hooks/Profile/useProfileData';
import useCurrentDate from 'hooks/CurrentDate/useCurrentDate';
import 'styles/components/headerCard.scss';

const Header = ({ title, icon }) => {
  const profile = useProfileData();

  const currentDate = useCurrentDate();

  const renderProfileInfo = () => {
    if (profile && currentDate) return <ProfileInfo profile={profile} currentDate={currentDate} />;
    return '';
  };

  return (
    <div className="header-card__container">
      <div className="header-card__content">
        <div className="header-card__icon__container">
          <div className="header-card__icon__wrapper">{icon}</div>
        </div>
        <div className="header-card__content__information">
          <h3 className="header-card__content__information__title capitalize-label">{title}</h3>
          <p className="header-card__content__information__version">{managementVersion}</p>
        </div>
      </div>
      <div className="header-card__footer df">{renderProfileInfo()}</div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default Header;
