import React from 'react';
import ContentWrapper from './views/Content/ContentWrapper';
import Login from './views/Login/Login';
import LoginMarketplace from './views/Login/Marketplace';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import history from './utils/router/history';
import { ROUTES } from './constants/routes';

const Routes = () => {
  return (
    <Router history={history}>
      <div className="main-layout-inner-container">
        <Switch>
          <Route exact path="/">
            <Redirect to={ROUTES.CONTENT} />
          </Route>
          <Route exact path={ROUTES.LOGIN}>
            <Login />
          </Route>
          <Route exact path={ROUTES.LOGIN_MARKETPLACE}>
            <LoginMarketplace />
          </Route>
          <Route path={ROUTES.CONTENT}>
            <ContentWrapper />
          </Route>
          <Route path="*">not found</Route>
        </Switch>
      </div>
    </Router>
  );
};

export default Routes;
