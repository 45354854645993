import { string } from 'prop-types';
import React from 'react';

const NavbarIntelligence = ({ dataTest, ...other }) => (
  <svg data-test={dataTest} viewBox="0 0 32 32" {...other}>
    <defs>
      <filter x="-58.3%" y="-64.8%" width="216.7%" height="229.6%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.0784313725 0 0 0 0 0.0784313725 0 0 0 0 0.0784313725 0 0 0 0.1 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#a)" transform="translate(1 3)" fill="none" fillRule="evenodd">
      <path
        d="M24.327 8.318L20.62 5.376a.433.433 0 00-.597.057L0 27h30V.44c0-.435-.568-.605-.81-.243l-4.216 8.022a.446.446 0 01-.647.1"
        fill="#2A25D6"
      />
      <path
        d="M6.06 12.393l-.957 1.395a.361.361 0 01-.501.089L.546 11.063c-.228-.158-.546 0-.546.273V27H21L6.598 12.347a.352.352 0 00-.537.046"
        fill="#5956DF"
      />
    </g>
  </svg>
);

NavbarIntelligence.propTypes = {
  dataTest: string,
};

export default NavbarIntelligence;
