import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import InputShowPassword from '../../../../components/InputShowPassword';
import ButtonLoading from '../../../../components/ButtonLoading';
import { ROUTES } from '../../../../constants/routes';
import { SPECIAL_CHARACTERS } from '../../../../constants/validations';
import utilsApi from '../../../../utils/api/UtilsApi';

const internals = {
  newPasswordID: 'modal-reset-password-new-password',
  reNewPasswordID: 'modal-reset-password-re-new-password',
  /**
   * Password regex. Minimun 8 character, at least one upper case English letter, one lower case English letter,
   * one number and one special character.
   * @url https://regexr.com/639tq
   */
  regPassword: new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[${SPECIAL_CHARACTERS}]).{8,}$`),
};

const ModalResetPasswordConfirmForm = ({ uid, token, onSuccess, onInvalidToken }) => {
  const { t } = useTranslation('modalResetPasswordConfirm');
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);

  const handleOnSubmit = async (ev) => {
    ev.preventDefault();
    setErrorForm(false);

    const pass = ev.target[internals.newPasswordID].value;
    const rePass = ev.target[internals.reNewPasswordID].value;

    if (!pass || !rePass) return;
    if (pass !== rePass) return setErrorForm(t('errorClientNotMatch'));
    if (internals.regPassword.test(pass) === false) return setErrorForm(t('errorClientInvalidPassword'));

    setLoading(true);

    try {
      await utilsApi.resetPasswordConfirm(uid, token, pass, rePass);
      setLoading(false);
      onSuccess();
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === 400) {
        if (err.response.data?.errors?.token || err.response.data?.errors?.uid) {
          return onInvalidToken();
        }
      }
      setErrorForm(t('error'));
    }
  };
  return (
    <>
      <h1 className="neutral-900 fs3 fw700 pb-m">{t('heading')}</h1>
      <p className="neutral-900 fs-3 pb-l">{t('description')}</p>
      <form onSubmit={handleOnSubmit}>
        <div className="pb-xl">
          <InputShowPassword
            error={errorForm && true}
            id={internals.newPasswordID}
            label={t('newPassword')}
            placeholder="********"
            required={true}
          />
        </div>
        <InputShowPassword
          error={errorForm && true}
          id={internals.reNewPasswordID}
          label={t('reNewPassword')}
          placeholder="********"
          required={true}
        />
        {errorForm && <div className="fs-3 red-900 pt-base wspl">{errorForm}</div>}
        <div className="df pt-l">
          <div className="mr-m">
            <ButtonLoading loading={loading}>{t('formActionButton')}</ButtonLoading>
          </div>
          <Link
            className="fk-button button-primary-main button-small fw600 tdn dib"
            to={{
              search: ROUTES.PARAMS.MODAL_LOGIN,
            }}
          >
            {t('formActionCancel')}
          </Link>
        </div>
      </form>
    </>
  );
};

ModalResetPasswordConfirmForm.propTypes = {
  /**
   * uid to send on form submit.
   */
  uid: string.isRequired,
  /**
   * token to send on form submit.
   */
  token: string.isRequired,
  /**
   * Callback fired when the component endpoint call is ok.
   */
  onSuccess: func.isRequired,
  /**
   * Callback fired when the server returns a 400 error indicating issues with the given token
   */
  onInvalidToken: func,
};

export default ModalResetPasswordConfirmForm;
