import { LocationsSerie } from './SeriesColors';

/**
 * HighChart options object for the locations graph
 */
const LocationsOptions = {
  legend: {
    align: 'left',
    itemDistance: 20,
    itemMarginTop: 20,
    itemMarginBottom: 5,
    itemStyle: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 2.4,
      color: '#4e5052',
    },
    squareSymbol: true,
    symbolWidth: 12,
    symbolHeight: 12,
    symbolRadius: 6,
    x: 37,
  },
  colors: LocationsSerie,
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
    allowDecimals: false,
    title: {
      text: '',
    },
    labels: {
      format: '{value}',
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return `$${this.value.toFixed(2)}`;
      },
    },
  },
  tooltip: {
    backgroundColor: '#313233',
    pointFormat: `
        <div class="hc-custom">
          <span style="color:{point.color}">●</span><p> {series.name}: <b style="margin-left: 5px">{point.y}</b></p><br/>
        </div>
      `,
    padding: 20,
    borderRadius: 16,
    valuePrefix: '$',
    shared: true,
    style: {
      color: '#fff',
      fontSize: 14,
    },
    useHTML: true,
  },
  plotOptions: {
    series: {
      connectNulls: true,
      marker: {
        enabled: false,
        symbol: 'circle',
      },
    },
  },
};

export default LocationsOptions;
