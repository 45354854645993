const documentsTypes = {
  EVIDENCE: 1,
  INVOICE: 2,
  MISCELLANEOUS: 3,
  RECEIPT: 4,
  AGREEMENT: 5,
  FOOD_SAFETY: 6,
  RETAIL_REQUIREMENT: 7,
  PACKAGE_PROCESS: 8,
  TERMS_AND_CONDITIONS: 9,
  INSURANCE_POLICIES: 10,
};

export default documentsTypes;
