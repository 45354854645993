import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';

const ResetPasswordConfirmInvalidLink = () => {
  const { t } = useTranslation('modalResetPasswordConfirm');

  return (
    <>
      <h1 className="neutral-900 fs3 fw700 pb-m">{t('invalidLinkTitle')}</h1>
      <p className="neutral-900 fs-3 pb-l">{t('invalidLinkDescription')}</p>
      <Link
        className="fk-button button-secondary-main button-small fw600 tdn dib"
        to={{
          search: ROUTES.PARAMS.MODAL_RESET_PASSWORD,
        }}
      >
        {t('invalidLinkActionButton')}
      </Link>
    </>
  );
};

export default ResetPasswordConfirmInvalidLink;
