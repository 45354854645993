const crossingsSelect = (data) => {
  if (Array.isArray(data) && data.length) {
    return data.map((element) => {
      return { id: element.abbr, label: element.name, value: element.slug };
    });
  }
  return [];
};

export default crossingsSelect;
