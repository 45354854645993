import React from 'react';

/* eslint-disable */
const WeatherMist = ({ styleClass, isWarning }) => (
  <svg className={styleClass} viewBox="0 0 24 24">
    <title>Weather Mist</title>
    <defs>
      <path
        d="M17.5,12 C17.776,12 18,12.224 18,12.5 C18,12.776 17.776,13 17.5,13 L17.5,13 L3.5,13 C3.224,13 3,12.776 3,12.5 C3,12.224 3.224,12 3.5,12 L3.5,12 Z M20.5,9 C20.776,9 21,9.224 21,9.5 C21,9.776 20.776,10 20.5,10 L20.5,10 L0.5,10 C0.224,10 0,9.776 0,9.5 C0,9.224 0.224,9 0.5,9 L0.5,9 Z M17.5,6 C17.776,6 18,6.224 18,6.5 C18,6.776 17.776,7 17.5,7 L17.5,7 L3.5,7 C3.224,7 3,6.776 3,6.5 C3,6.224 3.224,6 3.5,6 L3.5,6 Z M20.5,3 C20.776,3 21,3.224 21,3.5 C21,3.776 20.776,4 20.5,4 L20.5,4 L0.5,4 C0.224,4 0,3.776 0,3.5 C0,3.224 0.224,3 0.5,3 L0.5,3 Z M16.1,0 C16.3208,0 16.5,0.224 16.5,0.5 C16.5,0.776 16.3208,1 16.1,1 L16.1,1 L4.9,1 C4.6792,1 4.5,0.776 4.5,0.5 C4.5,0.224 4.6792,0 4.9,0 L4.9,0 Z"
        id="path-weather-mist"
      ></path>
    </defs>
    <g id="Symbols-weather-mist" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-weather-mist">
        <g id="Weather_Icons-weather-mist" transform="translate(2.000000, 5.500000)">
          <mask id="mask-weather-mist" fill="white">
            <use xlinkHref="#path-weather-mist"></use>
          </mask>
          <use
            id="Combined-Shape-weather-mist"
            stroke={isWarning ? '#e40d2b' : '#141414'}
            strokeWidth="0.5"
            fill={isWarning ? '#e40d2b' : '#141414'}
            fillRule="nonzero"
            xlinkHref="#path-weather-mist"
          ></use>
          <g id="Neutral-/-900-weather-mist" mask="url(#mask-weather-mist)" fill={isWarning ? '#e40d2b' : '#141414'}>
            <g transform="translate(-2.000000, -6.000000)">
              <path d="M8,0 L24,0 L24,0 L24,24 L8,24 C3.581722,24 5.41083001e-16,20.418278 0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WeatherMist;
