import React, { useEffect, useRef } from 'react';
import { bool, string, element } from 'prop-types';
import './Input.scss';

const Input = ({
  id,
  label,
  name,
  type,
  dataTest = undefined,
  placeholder = '',
  error = false,
  focused = false,
  required = false,
  endAdornment = undefined,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (focused && inputRef?.current) {
      requestAnimationFrame(() => {
        inputRef.current.focus();
      });
    }
  }, [focused]);

  return (
    <div className={`fk-input-container submit-error-${error}`}>
      <label>
        <p className="fk-input-label neutral-900 fs-3 pb-base">{label}</p>
        <div className="fk-input__input-base df white-100-bg">
          <input
            className="border-none br-inherit neutral-800 fs-2"
            data-test={dataTest}
            id={id}
            name={name}
            placeholder={placeholder}
            ref={inputRef}
            required={required}
            type={type}
          />
          {endAdornment}
        </div>
      </label>
    </div>
  );
};

Input.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  type: string.isRequired,
  dataTest: string,
  placeholder: string,
  error: bool,
  focused: bool,
  required: bool,
  endAdornment: element,
};

export default Input;
