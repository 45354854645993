/*
 * @param {string||number} price
 * @return {string}
 */

const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
const fixDigits = (price) => {
  return parseFloat(price).toLocaleString(undefined, options);
};
export default fixDigits;
