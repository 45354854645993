import React from 'react';
import PropTypes from 'prop-types';
import WebStorage from '../../../../../utils/WebStorage/WebStorage';
import dayCard from '../../../../../utils/page_definition/marketplace/dayCard';
import statusHandler from '../../../../../utils/marketplace/status/statusHandler';
import unitsHandler from '../../../../../utils/marketplace/units/unitsHandler';
import { GetDayName, GetDayNumber, GetDay } from '../../../../../utils/common/DateHandler';
import { intFormat } from '../../../../../utils/common/numberHandler';
import { useTranslation } from 'react-i18next';
import './DayCard.scss';

const DayCard = ({ data, currentDate, eventData, unit, palletsTruck, packageWeight, boxesPallet }) => {
  // page_definition
  const { dayCardContainer, dayCardName, dayCardNumber, dayCardValue } = dayCard;
  const { date, delivery_date, boxes } = data;
  const { unitIcons, rawValue } = unitsHandler;
  const receivedDate = date || delivery_date;

  const { commitmentStatus, dayCardIcons } = statusHandler;

  const eventStatus = commitmentStatus(eventData.status) || '';
  const boxesFiltered = rawValue(unit, boxes, palletsTruck, boxesPallet, packageWeight) || '--';
  const valueHandler = () => (boxesFiltered !== '--' ? intFormat(boxesFiltered) : boxesFiltered);

  const { t } = useTranslation('general');
  const { getCookieValue } = WebStorage;
  const localeID = getCookieValue('localeId');

  const isToday = GetDay(receivedDate) === GetDay(currentDate);

  const renderContentValue = (valueIsComming) => {
    if (valueIsComming) {
      if (eventStatus && eventStatus !== 'pending') return dayCardIcons(eventStatus, 'event-status-icon');
      return (
        <div className="day-crd-label-container">
          {unitIcons(unit, 'day-crd-icon')}
          <p className="day-crd-value" data-test={dayCardValue}>
            {valueHandler()}
          </p>
        </div>
      );
    }
    return (
      <p className="day-crd-value" data-test={dayCardValue}>
        {valueHandler()}
      </p>
    );
  };

  return (
    <div
      className={`day-card-container day-crd-container-value-${!isNaN(boxesFiltered)}
      day-crd-status-${eventStatus}`}
      data-test={dayCardContainer}
    >
      <h4 className="day-crd-day-name" data-test={dayCardName}>
        {t('general:date', { date: GetDayName(receivedDate, localeID) })}
      </h4>
      <p className={`day-crd-day-number day-crd-current-date-${isToday}`} data-test={dayCardNumber}>
        {t('general:date', { date: GetDayNumber(receivedDate, localeID) })}
      </p>
      <div className="day-crd-label-container">{renderContentValue(!isNaN(boxesFiltered))}</div>
    </div>
  );
};

DayCard.defaultProps = {
  eventData: { status: undefined },
};

DayCard.propTypes = {
  boxesPallet: PropTypes.number.isRequired,
  currentDate: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  eventData: PropTypes.object,
  packageWeight: PropTypes.number.isRequired,
  palletsTruck: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};

export default DayCard;
