/*
 * crossingSelectData
 * @param {array} crossing
 * @return {array}
 */
const crossingSelectData = (crossings) =>
  crossings.map((crossing) => ({
    id: crossing.abbr,
    label: crossing.name,
    value: crossing.abbr,
  }));

export default crossingSelectData;
