import React from 'react';
import Document from './Document';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/*
 * @param {number} type
 * @return {React.Element || string}
 */

const DocumentHandler = ({ documents, type }) => {
  // TODO: i18n label.
  const doc = documents.find((doc) => doc.type === type);

  const { t } = useTranslation('mpDetailCommitment');
  if (doc) {
    const { file, name } = doc;
    return (
      <div className="document-component-container">
        <h5 className="content-data-inner-title">{t('mpDetailCommitment:relatedDocuments')}</h5>
        <Document file={file} name={name} />
      </div>
    );
  }
  return '';
};

DocumentHandler.propTypes = {
  documents: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
};

export default DocumentHandler;
