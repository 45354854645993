import React, { useState, useEffect, useReducer } from 'react';
import Button from './Button';
import PropTypes from 'prop-types';
import SelectCascader from './SelectCascader';
import crossingsSelect from '../utils/Select/crossingsSelect';
import packageTypeSelect from '../utils/Select/packageTypeSelect';
import { AddSelect, RemoveSelect } from '../assets/icons/assets';
import { productTypeSelect } from '../utils/Select/productTypeSelect';
import { useTranslation } from 'react-i18next';
import './SelectCascaderContainer.scss';
import selectBoxSelector from '../utils/page_definition/select_menu';

const SelectCascaderContainer = ({ productDetail, getResults }) => {
  const [defaultOptions, setDefaultOptions] = useState();
  const [optionSelected, setOptionSelected] = useState();
  const [productDetailState, setProductDetailState] = useState();
  const initialState = { currentID: 1, options: [] };

  // i18n
  const { t } = useTranslation('selectCascaderContainer');

  useEffect(() => {
    if (productDetail) setProductDetailState(productDetail);

    return function cleanup() {
      // clean the main state hook when the component is unmounted
      setProductDetailState();
      dispatch({ type: 'CLEAN_STATE' });
    };
  }, [productDetail]);

  useEffect(() => {
    if (productDetailState) {
      const { crossings } = productDetailState;
      const locationsParsed = crossingsSelect(crossings);

      const defaultLocation = locationsParsed.length > 0 ? locationsParsed[0].value : 0;
      if (defaultLocation) {
        const currentLocation = crossings.filter((crossing) => crossing.slug === defaultLocation)[0];

        // data parsed and filtered
        const productTypesFiltered = productTypeSelect(currentLocation);
        const packageTypesFiltered = packageTypeSelect(currentLocation[productTypesFiltered[0].value]);

        // data select default
        const productTypeSelected = productTypesFiltered[0].value;
        const packageTypeSelected = packageTypesFiltered[0].value;

        const defaultOption = {
          location: defaultLocation,
          productType: productTypeSelected,
          packageType: packageTypeSelected,
        };

        setDefaultOptions([defaultOption]);
        getResults([defaultOption]);
        dispatch({ type: 'ADD_DEFAULT_OPTION', option: { ...defaultOption, id: initialState.currentID } });
      }
    }
  }, [productDetailState]);

  useEffect(() => {
    if (optionSelected) dispatch({ type: 'UPDATE_SELECTED_OPTION', option: optionSelected, id: optionSelected.id });
  }, [optionSelected]);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'ADD_DEFAULT_OPTION':
        return {
          ...state,
          options: [action.option],
        };
      case 'ADD_SELECT_CASCADER':
        return { ...state, options: [...state.options, action.option], currentID: state.currentID + 1 };
      case 'CLEAN_STATE':
        return initialState;
      case 'REMOVE_SELECT_CASCADER':
        return { ...state, options: state.options.filter(({ id }) => id !== action.id) };
      case 'UPDATE_SELECTED_OPTION': {
        const allOptions = state.options;
        allOptions[state.options.findIndex(({ id }) => id === action.id)] = action.option;
        return { ...state, options: allOptions };
      }
      case 'CLEAR_SELECT_OPTIONS':
        return {
          ...state,
          options: defaultOptions,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAddClick = () => {
    if (state.options.length)
      dispatch({ type: 'ADD_SELECT_CASCADER', option: { ...state.options[0], id: state.currentID + 1 } });
  };

  const handleRemoveClick = (id) => {
    dispatch({ type: 'REMOVE_SELECT_CASCADER', id });
  };

  const handleApplyClick = () => {
    getResults(state.options);
  };

  const handleCancelClick = () => {
    if (defaultOptions) {
      dispatch({ type: 'CLEAR_SELECT_OPTIONS' });
      getResults(defaultOptions);
    }
  };

  const renderSelectCascader = () => {
    if (state && state.options.length) {
      return state.options.map((option) => {
        return (
          <div className={`select-cascader-row-container-${state.options.length > 1}`} key={`${option.id}`}>
            <div className="select-cascader-row" data-test={selectBoxSelector.selectsRow}>
              <SelectCascader
                actions={false}
                id={option.id}
                productDetail={productDetailState}
                setOptionsSelected={setOptionSelected}
                styleClass="no-border-select no-arrow-select no-label-title-select ellipsis-text-minor box-shadow-select"
              />
              {state.options.length > 1 ? (
                <Button
                  onClick={() => handleRemoveClick(option.id)}
                  shape="complementary"
                  size="x-small"
                  styleClass="select-cascader-button remove-select-button"
                  type="destructive"
                  dataTest="remove-select-button"
                >
                  <RemoveSelect styleClass="select-button-icon remove-select-button-icon" />
                </Button>
              ) : (
                ''
              )}
              <Button
                onClick={handleAddClick}
                shape="complementary"
                size="x-small"
                styleClass="select-cascader-button add-select-button select-cascader-button-desktop"
                type="secondary"
                dataTest={selectBoxSelector.addSelectButton}
              >
                <AddSelect styleClass="select-button-icon add-select-button-icon" />
              </Button>
            </div>
            <hr className="dashboard-divisor dashboard-divisor-responsive" />
          </div>
        );
      });
    }
  };

  return (
    <div className="select-cascader-container">
      {renderSelectCascader()}

      <Button
        onClick={handleAddClick}
        shape="complementary"
        size="x-small"
        styleClass="select-cascader-button add-select-button select-cascader-button-responsive"
        type="secondary"
      >
        <AddSelect styleClass="select-button-icon add-select-button-icon" />
      </Button>
      <Button
        dataTest="apply-button"
        styleClass="select-apply-filters-button"
        size="small"
        type="secondary"
        onClick={() => handleApplyClick()}
      >
        {t('applyButton')}
      </Button>
      {state.options.length > 1 ? (
        <Button
          dataTest={selectBoxSelector.clearButton}
          size="small"
          type="primary"
          shape="complementary"
          onClick={() => handleCancelClick()}
        >
          {t('clearButton')}
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

SelectCascaderContainer.propTypes = {
  getResults: PropTypes.func,
  productDetail: PropTypes.object,
};

export default SelectCascaderContainer;
