import unitsHandler from '../marketplace/units/unitsHandler';
import { toFixed } from '../common/numberHandler';
const { rawValue } = unitsHandler;

const projectionGraphSerie = {
  /*
   * @param {array} data
   * @param {string} unit
   * @param {number} boxes_pallet
   * @param {number} pallets_truck
   * @param {number} package_weight
   * @return {array} main data array
   */
  parseSerieData: (data, value, unit, boxes_pallet, pallets_truck, package_weight) => {
    const baseArray = [];
    data.forEach((element) =>
      baseArray.push([
        `${element.week}`,
        parseFloat(toFixed(rawValue(unit, element[value], pallets_truck, boxes_pallet, package_weight))),
      ]),
    );
    return baseArray;
  },
  /*
   * @param {number} start_week
   * @param {number} end_week
   * @param {number} commitedBoxes
   * @return {array} volume array
   */
  parseVolumeDataAvailablePrograms: (start_week, end_week, commitedBoxes) => {
    const baseArray = [];
    for (let i = start_week; i <= end_week; i++) baseArray.push([`${i}`, parseFloat(toFixed(commitedBoxes))]);
    return baseArray;
  },
};

export default projectionGraphSerie;
