import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/';
import Select from 'components/Select';
import SelectCheckbox from 'components/SelectCheckbox';
import utilsApi from 'utils/api/UtilsApi';
import { GetYear } from 'utils/common/DateHandler';
import ChartControls from './components/ChartControls';
import ChartRenderer from './components/ChartRenderer';
import SelectedYearsList from './components/SelectedYearsList';
import TimeControls from './components/TimeControls';
import useAPICommodity from './hooks/useAPICommodity';
import { prevYearsSelect } from './utils/prevYearsSelect';
import { GRAPH_VALUES } from './utils/graphSelectData';
import { productTypeSelect } from './data';
import './style.scss';

/*
 * VolumeAndPrices component
 * @see {@link https://docs.google.com/document/d/1kt4lPVhiaIWT4HFBqJPtYXRTPs_VaJTqD2iL0IoinrU/edit#heading=h.s3elk2zd3215|SPECS}
 */
const internals = {
  productTypeOptions: {
    viewAll: '',
    organic: 'true',
    conventional: 'false',
  },
};

const VolumeAndPrices = () => {
  const activeProduct = useSelector((state) => state.products.active);
  const currentDate = useSelector((state) => state.currentDate.currentDate) || { date: undefined };

  const [yearsList, setYearsList] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [productTypeData, setProductTypeData] = useState([]);
  const [timeControls, setTimeControls] = useState({});
  const [chartControls, setChartControls] = useState({});
  const [trendsAPIData, setTrendsAPIData] = useState(null);
  const [stateWatcher, setStateWatcher] = useState('');

  const [isCommodityDataReady, setIsCommodityDataReady] = useState(false);
  const [requestTrendsData, setRequestTrendsData] = useState(null);
  const [isFetchingTrendsData, setIsFetchingTrendsData] = useState(true);
  const [disabledApplyButton, setDisabledApplyButton] = useState(true);

  const commodityApiData = useAPICommodity(
    activeProduct,
    useMemo(() => {
      if (!selectedYears) return [];
      return selectedYears.map((year) => year.value);
    }, [selectedYears]),
  );
  const { t, i18n } = useTranslation(['mpVolumeAndPrices']);

  const getWatchState = useCallback(() => {
    return JSON.stringify({ chartControls, timeControls, selectedProductType, selectedYears });
  }, [chartControls, timeControls, selectedProductType, selectedYears]);

  useEffect(() => {
    setProductTypeData(productTypeSelect.map((e) => ({ ...e, label: t(`mpVolumeAndPrices:${e.value}`) })));
  }, [i18n.language, t]);

  useEffect(() => {
    if (activeProduct) setIsCommodityDataReady(false);
  }, [activeProduct]);

  useEffect(() => {
    if (currentDate.date) {
      const yearsData = prevYearsSelect(GetYear(currentDate.date));
      setYearsList(yearsData);
    }
  }, [currentDate.date]);

  useEffect(() => {
    const { origins, packages, crossings } = commodityApiData?.data;
    if (
      origins &&
      packages &&
      crossings &&
      (chartControls.origin || (chartControls.package && chartControls.crossing))
    ) {
      setIsCommodityDataReady(true);
    }
  }, [
    commodityApiData.data,
    commodityApiData.data.origins,
    commodityApiData.data.packages,
    commodityApiData.data.crossings,
    chartControls.origin,
    chartControls.package,
    chartControls.crossing,
  ]);

  useEffect(() => {
    if (isCommodityDataReady) setRequestTrendsData(Math.random() * (100 - 1) + 1);
  }, [isCommodityDataReady]);

  useEffect(() => {
    async function getTrends() {
      setIsFetchingTrendsData(true);
      try {
        const { chart, crossing, origin } = chartControls;
        const { dateRange, day, endingWeek, startingDate, startingWeek, time } = timeControls;
        const yearsString = selectedYears.map((y) => y.id).join();

        const { data } = await utilsApi.getVolumeAndPrice(
          chart,
          time,
          dateRange,
          endingWeek,
          day,
          startingWeek,
          startingDate,
          yearsString,
          activeProduct,
          internals.productTypeOptions[selectedProductType],
          [GRAPH_VALUES.ALL, GRAPH_VALUES.PRICES].includes(chart) ? crossing : undefined,
          [GRAPH_VALUES.VOLUME].includes(chart) ? origin : undefined,
          [GRAPH_VALUES.ALL, GRAPH_VALUES.PRICES].includes(chart) ? chartControls.package : undefined,
        );
        setTrendsAPIData(data);
        setStateWatcher(getWatchState());
      } catch (e) {
        setTrendsAPIData(undefined);
      } finally {
        setIsFetchingTrendsData(false);
      }
    }
    if (requestTrendsData) getTrends();
  }, [requestTrendsData]);

  useEffect(() => {
    if (stateWatcher === getWatchState() || !selectedYears?.length) {
      setDisabledApplyButton(true);
    } else {
      setDisabledApplyButton(false);
    }
  }, [selectedYears, selectedProductType, chartControls, timeControls, getWatchState, stateWatcher]);

  const handleRemoveYears = (yearId) => {
    const selectedYearsBKP = [...yearsList];
    const index = selectedYearsBKP.findIndex((year) => year.id === yearId);
    if (index < 0) return;
    selectedYearsBKP[index].isActive = false;
    setYearsList(selectedYearsBKP);
  };

  return (
    <>
      <div className="neutral-100-bg br-m border neutral-300-br">
        <div className="vap__filters-container df fww pt-l px-xl neutral-50-bg br-inherit">
          <ChartControls
            commodityApiData={commodityApiData.data}
            disable={isFetchingTrendsData}
            onChange={useCallback((chartControlsData) => {
              setChartControls(chartControlsData);
            }, [])}
          >
            <TimeControls
              disable={isFetchingTrendsData}
              onChange={useCallback((timeControlsData) => {
                setTimeControls(timeControlsData);
              }, [])}
            />
            <div className="pr-l">
              <p className="fs-4 neutral-900 pb-base">{t('prevYears')}</p>
              <SelectCheckbox
                data={yearsList}
                id="vap-prev-years-select"
                label={t('selectCheckbox')}
                setSelectedData={(years) => setSelectedYears(years?.filter((y) => y.isActive))}
                disabled={isFetchingTrendsData}
              />
            </div>
            <div className="pr-l">
              <p className="fs-4 neutral-900 pb-base">{t('type')}</p>
              <Select
                data={productTypeData}
                id="vap-product-type-select"
                setSelectedData={setSelectedProductType}
                disabled={isFetchingTrendsData}
              />
            </div>
          </ChartControls>
          <div style={{ alignSelf: 'flex-end' }}>
            <Button
              disabled={disabledApplyButton || isFetchingTrendsData}
              onClick={() => setRequestTrendsData(Math.random() * (100 - 1) + 1)}
              shape={disabledApplyButton ? 'complementary' : 'main'}
              size="small"
              styleClass="vap__apply-btn"
              type={disabledApplyButton ? 'disabled' : 'secondary'}
            >
              {t('applyButton')}
            </Button>
          </div>
        </div>
        {selectedYears && selectedYears.length > 1 && (
          <div className="vap__selected-years-container py-m px-xl">
            <p className="fs-4 neutral-900 pb-m">{t('extraYears')}</p>
            <SelectedYearsList selectedYearsList={selectedYears} removeYear={handleRemoveYears} />
          </div>
        )}
      </div>
      <ChartRenderer
        chart={chartControls?.chart}
        packageType={chartControls?.package}
        loading={isFetchingTrendsData}
        time={timeControls?.time}
        trendsAPIData={trendsAPIData}
      />
    </>
  );
};

export default VolumeAndPrices;
