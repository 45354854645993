import React from 'react';

/*eslint-disable*/
const ExtraYearsCancel = ({ ...others }) => (
  <svg viewBox="0 0 13 12" {...others}>
    <g id="Symbols-extra-years-cancel" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Pill/Dark-Purple-extra-years-cancel"
        transform="translate(-43.800464, -6.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="Grou-extra-years-cancelp" transform="translate(8.000000, 4.000000)">
          <g transform="translate(34.000000, 0.000000)" id="Icn/System/Cancel-input-extra-years-cancel">
            <path
              d="M8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 Z M9.41952113,5.16937441 L8.00530757,6.58358797 L6.591094,5.16937441 C6.23754061,4.81582102 5.53043383,4.81582102 5.17688044,5.16937441 C4.82332705,5.5229278 4.82332705,6.23003458 5.17688044,6.58358797 L6.591094,7.99780153 L5.17688044,9.4120151 C4.80478742,9.78195729 4.82332705,10.4726753 5.17688044,10.8262287 C5.53043383,11.179782 6.21900098,11.1961709 6.591094,10.8262287 L8.00530757,9.4120151 L9.41952113,10.8262287 C9.76835834,11.2192922 10.4801813,11.179782 10.8337347,10.8262287 C11.1872881,10.4726753 11.1872881,9.76556849 10.8337347,9.4120151 L9.41952113,7.99780153 L10.8337347,6.58358797 C11.2058277,6.21364577 11.1872881,5.5229278 10.8337347,5.16937441 C10.4801813,4.81582102 9.77307452,4.81582102 9.41952113,5.16937441 Z"
              id="Combined-Shape-extra-years-cancel"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ExtraYearsCancel;
