import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';

const ResetPasswordSuccessMessage = ({ email }) => {
  const { t } = useTranslation('modalResetPassword');

  return (
    <>
      <h1 className="neutral-900 fs3 fw700 pb-m">{t('successHeading')}</h1>
      <p className="neutral-900 fs-3 pb-l">{t('successDescription', { email })}</p>
      <Link
        className="fk-button button-secondary-main button-small fw600 tdn dib"
        to={{
          search: ROUTES.PARAMS.MODAL_LOGIN,
        }}
      >
        {t('successActionButton')}
      </Link>
    </>
  );
};

ResetPasswordSuccessMessage.propTypes = {
  /**
   * The email to show on the message.
   */
  email: string.isRequired,
};

export default ResetPasswordSuccessMessage;
