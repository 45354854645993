import { useSelector } from 'react-redux';
import React from 'react';
import handlerOptionCases from '../../../../utils/management/optionsCases';
import './index.scss';

const ManagementDetails = () => {
  const activeOption = useSelector((state) => state.option.activeOption);

  return (
    <div
      className={`management-details__container h100
      ${activeOption.type ? 'active' : ''}`}
    >
      <div className="h100 border primary-300-br br-m">{handlerOptionCases(activeOption)}</div>
    </div>
  );
};

export default ManagementDetails;
