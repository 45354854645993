import React from 'react';
import { element, node, oneOf, string } from 'prop-types';
import './style.scss';

const Tooltip = ({ children, text, title, customClass = '', placement = '' }) => (
  <div className={`tooltip tooltip--${placement} ${customClass}`}>
    {children}
    <div className="tooltiptext pa">
      <div className="tooltip-text__content p-m white-100 br-base">
        {title ? (
          <div>
            <p className="tooltip__title-text fs-base px-base fw700">{title}</p>
            <div className="tooltip__title-separator" />
          </div>
        ) : (
          ''
        )}
        <p className="tooltip__body-text fs-4 px-base fw400">{text}</p>
      </div>
    </div>
  </div>
);

Tooltip.propTypes = {
  children: element.isRequired,
  text: node.isRequired,
  title: string,
  customClass: string,
  placement: oneOf(['bottom', 'bottom-start', 'bottom-end']),
};

export default Tooltip;
