import { GET_ORCHARDS_LIST } from './types';
import UtilsApi from '../../../../utils/api/UtilsApi';

export const getOrchardsListAction = (CancelToken) => (dispatch) => {
  //TODO: SHOULD WE MANAGE THE ERROR ON THE ORCHARD REQUESTS?????
  UtilsApi.getOrchards(CancelToken).then((res) => {
    dispatch({
      type: GET_ORCHARDS_LIST,
      payload: res.data,
    });
  });
};
