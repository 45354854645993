import React from 'react';
import { string } from 'prop-types';
import './style.scss';

const EmptyStateMessage = ({ title, description }) => {
  return (
    <div className="empty-state-message">
      <h1 className="empty-state-message__title">{title}</h1>
      <p className="empty-state-message__description">{description}</p>
    </div>
  );
};

EmptyStateMessage.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
};

export default EmptyStateMessage;
