// source: https://github.com/tobiasahlin/SpinKit
import React from 'react';
import './Loader.scss';

const Loader = () => {
  const renderLoader = () => (
    <div className="loader-container">
      <div className="loader-wrapper">
        <div className="loader-cube1 loader-cube" />
        <div className="loader-cube2 loader-cube" />
        <div className="loader-cube4 loader-cube" />
        <div className="loader-cube3 loader-cube" />
      </div>
    </div>
  );
  return <div>{renderLoader()}</div>;
};

export default Loader;
