import React from 'react';

/* eslint-disable */
const WeatherClearSky = ({ styleClass, isWarning = false }) => (
  <svg className={styleClass} viewBox="0 0 24 24">
    <title>Clear Sky</title>
    <defs>
      <path
        d="M8,16 C12.411,16 16,12.411 16,8 C16,3.589 12.411,0 8,0 C3.589,0 0,3.589 0,8 C0,12.411 3.589,16 8,16 Z M8,1 C11.86,1 15,4.14 15,8 C15,11.86 11.86,15 8,15 C4.14,15 1,11.86 1,8 C1,4.14 4.14,1 8,1 Z"
        id="path-weather-clear-sky"
      ></path>
    </defs>
    <g id="Symbols-clear-sky" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-weather-clear-sky">
        <g id="Weather_Icons-clear-sky" transform="translate(4.000000, 4.000000)">
          <mask id="mask-clear-sky" fill="white">
            <use xlinkHref="#path-weather-clear-sky"></use>
          </mask>
          <use
            id="Shape-clear-sky"
            stroke={isWarning ? '#e40d2b' : '#231F20'}
            strokeWidth="0.5"
            fill={isWarning ? '#e40d2b' : '#231F20'}
            fillRule="nonzero"
            xlinkHref="#path-weather-clear-sky"
          ></use>
          <g id="Neutral-/-900-clear-sky" mask="url(#mask-clear-sky)">
            <g transform="translate(-4.000000, -4.000000)">
              <path d="M8,0 L24,0 L24,0 L24,24 L8,24 C3.581722,24 5.41083001e-16,20.418278 0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WeatherClearSky;
