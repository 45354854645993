import React from 'react';

/*eslint-disable*/
const Mexico = ({ styleClass }) => (
  <svg
    className={styleClass}
    id="mexico-flag"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{ enableBackground: 'new  0 0 512 512' }}
    xmlSpace="preserve"
  >
    <circle style={{ fill: '#F0F0F0' }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: '#D80027' }}
      d="M512,256c0-101.494-59.065-189.19-144.696-230.598v461.195C452.935,445.19,512,357.494,512,256z"
    />
    <g>
      <path
        style={{ fill: '#6DA544' }}
        d="M0,256c0,101.494,59.065,189.19,144.696,230.598V25.402C59.065,66.81,0,154.506,0,256z"
      />
      <path
        style={{ fill: '#6DA544' }}
        d="M189.217,256c0,36.883,29.9,66.783,66.783,66.783s66.783-29.9,66.783-66.783v-22.261H189.217V256z"
      />
    </g>
    <path
      style={{ fill: '#FF9811' }}
      d="M345.043,211.478h-66.783c0-12.294-9.967-22.261-22.261-22.261s-22.261,9.967-22.261,22.261h-66.783
	c0,12.295,10.709,22.261,23.002,22.261h-0.741c0,12.295,9.966,22.261,22.261,22.261c0,12.295,9.966,22.261,22.261,22.261h44.522
	c12.295,0,22.261-9.966,22.261-22.261c12.295,0,22.261-9.966,22.261-22.261h-0.742C334.335,233.739,345.043,223.773,345.043,211.478
	z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default Mexico;
