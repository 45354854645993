import React from 'react';

/*eslint-disable*/
const KgIcon = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 16 16">
    <g id="Symbols-kg-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icn/Package">
        <rect id="Icon-viewport-kg-icon" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="16" height="16"></rect>
        <path
          d="M12.8722847,4.71892906 C12.8722847,3.93812977 12.2284702,3 11.432599,3 L11,3 L11,1.99238196 C10.9999345,1.44012278 10.5522592,0.992418129 10,0.992316465 L6,0.992054493 C5.44771525,0.991952832 4.99997068,1.43963876 4.99993451,1.99192351 C4.99993451,1.99194534 4.99993451,1.99196717 5,1.991989 L5,3 L5,3 L4.5663226,3 C3.77368664,3 3.12987213,3.93812977 3.12987213,4.71892906 L1,14.5855868 C1.00114131,14.9622118 1.15364686,15.3229308 1.42390548,15.5882438 C1.6941641,15.8535568 2.05999233,16.0016863 2.44076413,16.0000689 L13.5624711,16.0000689 C14.3541904,16.0017554 14.9976209,15.3686779 15,14.5855868 L12.8722847,4.71892906 Z M10,8.99205448 L11,8.99205448 L11,10.9920545 L10,10.9920545 L10,8.99205448 Z M10,2 L10,3 L6,3 L6,2 L10,2 Z M6.02560778,10.0279898 L6.02560778,10.9557643 L7,10.9557643 L7,12 L5.9643436,12 L5.9643436,11.0164416 L5.05121556,11.0164416 L5.05121556,12 L4,12 L4,7 L5.05121556,7 L5.05121556,9.96633392 L5.9643436,9.96633392 L5.9643436,8.98277549 L7,8.98277549 L7,10.0279898 L6.02560778,10.0279898 Z M11,14 L11,15 L9.98403909,15 L9.98403909,14 L11,14 L11,12 L9,12 L9,8 L12,8.01493272 L12,14 L11,14 Z"
          id="Shape-kg-icon"
          fill="#141414"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default KgIcon;
