import TagManager from 'react-gtm-module';

// object declaration used for initialize google tag manager
const initializeArguments = {
  gtmId: process.env.REACT_APP_TAG_MANAGER,
};

/*
 * Google Tag manager singleton
 */
const GoogleTagManager = {
  // init google tag manager
  init: () => {
    TagManager.initialize(initializeArguments);
  },
};

export default GoogleTagManager;
