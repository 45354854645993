const fillGraphValues = (mainStructure, givenValues, keyName) => {
  const fixDateGivenValues = givenValues.map((e) => ({ [keyName]: e[keyName], report_date: e.report_date }));
  return mainStructure.map((currVal) => {
    const receivedValue = fixDateGivenValues.find((element) => element.report_date === currVal.report_date);
    return receivedValue || currVal;
  });
};

const fillAreaGraphValues = (mainStructure, givenValues, keyNames) => {
  const fixDateGivenValues = givenValues.map((e) => ({
    [keyNames[0]]: e[keyNames[0]], //TODO: We need to make it dynamic
    [keyNames[1]]: e[keyNames[1]],
    report_date: e.report_date,
  }));
  return mainStructure.map((currVal) => {
    const receivedValue = fixDateGivenValues.find((element) => element.report_date === currVal.report_date);
    return receivedValue || currVal;
  });
};

const generateDataAverage = (data) => {
  const allDate = [...data.data, ...data.average_price]
    .map((e) => e.report_date)
    .flat()
    .sort((a, b) => new Date(a) - new Date(b));
  const uniqueDates = new Set(allDate.map((e) => e));
  const uniqueLabels = [...uniqueDates].map((date) => ({
    price: null,
    last_season_price: null,
    last_five_years_price: null,
    lowest_price: null,
    highest_price: null,
    report_date: date,
  }));
  const filledLastSeason = fillGraphValues(uniqueLabels, data.data, 'last_season_price');
  const filledLastFiveYears = fillGraphValues(uniqueLabels, data.data, 'last_five_years_price');
  const filledArea = fillAreaGraphValues(uniqueLabels, data.data, ['lowest_price', 'highest_price']);
  const filledAverage = fillGraphValues(uniqueLabels, data.average_price, 'price');
  return { filledAverage, filledLastSeason, filledArea, filledLastFiveYears };
};

export { generateDataAverage };
