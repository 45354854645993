import { string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './GraphNoData.scss';

/*
 * GraphNoData
 * for now we don't support custom messages
 * @return {React.element}
 */
const GraphNoData = ({ className = '' }) => {
  const { t } = useTranslation(['graphNoData']);
  const classNames = ['graph-no-data-container', className].join(' ');

  return (
    <div className={classNames}>
      <div className="grph-no-dta-header">
        <h4 className="grph-no-dta-title">{t('graphNoData:title')}</h4>
      </div>
      <div className="grph-no-dta-content">
        <p className="graph-no-dta-description">{t('graphNoData:filtersDescriptions')}</p>
      </div>
    </div>
  );
};

GraphNoData.propTypes = {
  className: string,
};

export default GraphNoData;
