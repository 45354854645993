import React from 'react';
import PropTypes from 'prop-types';
import './FlowButtonsContainer.scss';

const FlowButtonsContainer = ({ children, styleClass }) => (
  <div className={`flow-buttons-container ${styleClass}`}>{children}</div>
);

FlowButtonsContainer.defaultProps = {
  styleClass: '',
};

FlowButtonsContainer.propTypes = {
  children: PropTypes.element.isRequired,
  styleClass: PropTypes.string,
};
export default FlowButtonsContainer;
