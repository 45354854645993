import React, { useState } from 'react';
import Average from './Graphs/Average';
import Locations from './Graphs/Locations';
import switchCaseHandler from '../../../../utils/common/switch';
import Button from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import './ShippingPoint.scss';

const ShippingPoint = () => {
  const { t } = useTranslation('shippingPoint');
  const [selectedGraph, setSelectedGraph] = useState(0);

  const shippingPointGraphCases = switchCaseHandler({
    average: function AverageGraphCase() {
      return <Average />;
    },
    locations: function LocationGraphCase() {
      return <Locations />;
    },
  })('');

  const shippingPointLabelCases = switchCaseHandler({
    average: t('shippingPoint:averageButton'),
    locations: t('shippingPoint:locationButton'),
  })('');

  // graphOrder
  const graphOrder = ['average', 'locations'];

  /**
   * According to position (index) we change button styles
   * @param {number} index
   */
  const getButtonClassName = (i) => {
    let buttonClass = 'shipping-point-category-button';
    if (!i) buttonClass += ' shipping-point-first-button';
    if (i === graphOrder.length - 1) buttonClass += ' shipping-point-last-button';
    if (i === selectedGraph) buttonClass += ' shipping-point-category-button-selected';

    return buttonClass;
  };

  /**
   * Set selected option
   * @param {number} index
   */
  const changeOption = (index) => {
    setSelectedGraph(index);
  };

  /**
   * Renders all buttons according tto `graphOrder` array
   */
  const renderButtons = () => (
    <div className="shipping-point-prices-buttons">
      {graphOrder.map((option, index) => (
        <Button
          styleClass={getButtonClassName(index)}
          key={index}
          onClick={() => changeOption(index)}
          size="small"
          shape={index === selectedGraph ? 'main' : 'complementary'}
          type="secondary"
        >
          {shippingPointLabelCases(option)}
        </Button>
      ))}
    </div>
  );

  return (
    <div className="shipping-point-prices-container">
      <div className="shipping-point-prices-header">
        <div className="shipping-point-prices-header-title">
          <h3>{t('shippingPoint:title')}</h3>
        </div>
      </div>
      <p className="shipping-point-prices-description">{t('shippingPoint:paragraph')}</p>
      {renderButtons()}
      <div className="shipping-point-prices-content">{shippingPointGraphCases(graphOrder[selectedGraph])}</div>
    </div>
  );
};

export default ShippingPoint;
