import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoData from '../../../../../components/NoData';
import UtilsApi from '../../../../../utils/api/UtilsApi';
import VolumeOptions from '../../../../../utils/Highcharts/VolumeOptionsDaily';
import VolumeSelectCascader from '../../../../../components/VolumeSelectCascader';
import isEmptyObject from '../../../../../utils/common/isEmptyObject';
import { GetMonth } from '../../../../../utils/common/DateHandler';
import { VolumeSerieDaily } from '../../../../../utils/Highcharts/VolumeSerie';
import { useDispatch, useSelector } from 'react-redux';
import { generateDateStructure } from '../../../../../utils/Highcharts/dataHandlers/dailyGraph';
import { getCurrentDateAction } from '../../../../../redux/actions/currentDate/actions';
import { useTranslation } from 'react-i18next';
import './Daily.scss';

const DailyGraph = () => {
  const { t } = useTranslation('volume');
  const [error, setError] = useState();
  const [highChartsOptions, setHighChartsOptions] = useState();
  const [movementSeasons, setVolumeSeasons] = useState();
  const [optionsSelected, setOptionsSeleted] = useState();
  const CancelToken = UtilsApi.cancelToken();

  const dispatch = useDispatch();

  const currentDate = useSelector((state) => state.currentDate.currentDate);
  const selectedDate = useSelector((state) => state.selectedDate.date);
  const productDetail = useSelector((state) => state.products[state.products.active]);

  useEffect(() => {
    if (!currentDate) dispatch(getCurrentDateAction());
  }, [currentDate, getCurrentDateAction]);

  // Use effect to handle the highchart options with the series and plot data.
  useEffect(() => {
    const updateGraph = () => {
      // only when the currentCommodity hook exists
      const { data } = movementSeasons;
      // get the Highchart options.
      const cloneHCH = { ...VolumeOptions };
      // array to parse the series array with the differents imports types: {Current, Future, Last}
      const hcSeries = [];
      // Parse the Serie for the Current Season data.
      // Right now the data from this service is broken.
      if (data.current.length) {
        hcSeries.push(VolumeSerieDaily(t('currSeason'), data.current));
        // set the plotline used from the state
        cloneHCH.xAxis.plotLines[0].value = data.indexCurrentDate;
      }
      if (data.last.length) {
        hcSeries.push(VolumeSerieDaily(t('lastSeason'), data.last));
      }
      cloneHCH.series = hcSeries;
      // Set the plotLine {CURRENT DAY}
      setHighChartsOptions(cloneHCH);
    };
    if (movementSeasons) updateGraph();
  }, [movementSeasons, t('currSeason')]);

  // Use effect to get the CommodityData
  useEffect(() => {
    // Method to get the Current day import
    const getCommodityData = async () => {
      try {
        const { abbr } = productDetail;
        const { location, productType } = optionsSelected;
        const { data } = await UtilsApi.productVolume(
          abbr,
          location,
          productType,
          'daily',
          selectedDate,
          CancelToken.token,
        );

        if (isEmptyObject(data)) {
          const completeDateStructure = generateDateStructure(
            data.current,
            data.last_season,
            selectedDate || currentDate.date,
          );
          setVolumeSeasons({
            data: {
              current: completeDateStructure.current,
              last: completeDateStructure.last,
              indexCurrentDate: completeDateStructure.indexCurrentDate,
            },
          });
        }
      } catch (e) {
        if (!UtilsApi.isCancel(e)) {
          setError(true);
        }
      }
    };
    if (optionsSelected && productDetail) getCommodityData();

    return function () {
      CancelToken.cancel();
    };
  }, [optionsSelected, selectedDate]);

  const renderFromTo = (data, type) => {
    const { current, last } = data;
    if (current.length) {
      if (type === 'from') return GetMonth(current[0].date);
      return GetMonth(current[current.length - 1].date);
    }
    if (last.length) {
      if (type === 'from') return GetMonth(last[0].date);
      return GetMonth(last[last.length - 1].date);
    }
    return '';
  };

  // Method to handle the Highchart when the currentCommodity and highChartsOptions hooks exists to handle
  // the loading and avoid the first and unique render element
  const renderHighCharts = () =>
    movementSeasons && highChartsOptions ? (
      <div className="daily-graph-container">
        <p className="daily-graph-highcharts-ylabel"> {t('pkgUnit')} </p>
        <HighchartsReact highcharts={Highcharts} options={highChartsOptions} />
        <p className="daily-graph-custom-xaxis-month-label daily-xaxis-month-left">
          {renderFromTo(movementSeasons.data, 'from')}
        </p>
        <p className="daily-graph-custom-xaxis-month-label daily-xaxis-month-right">
          {renderFromTo(movementSeasons.data, 'to')}
        </p>
      </div>
    ) : (
      <NoData error={error} />
    );

  const renderSelectCascader = () => {
    if (productDetail)
      return (
        <VolumeSelectCascader
          id="daily-volume"
          origins={productDetail['origins_daily']}
          setOptionsSelected={setOptionsSeleted}
          styleClass="volume-select-cascader-column"
        />
      );
    return '';
  };

  // Return Highchart
  return (
    <div>
      <div className="daily-graph-header">
        <hr className="daily-divisor-responsive" />
        {renderSelectCascader()}
        <hr className="daily-divisor-responsive" />
      </div>
      {renderHighCharts()}
    </div>
  );
};

export default DailyGraph;
