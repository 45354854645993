import React from 'react';
import { BlackberryIcon, BlueberryIcon, RaspberryIcon } from '../../../assets/icons/assets';
import './InformationText.scss';

const InformationText = () => {
  return (
    <div className="informationtext-container">
      <BlackberryIcon styleClass="infotxt-icn infotxt-right-rotate black-berry-icon" />
      <RaspberryIcon styleClass="infotxt-icn infotxt-left-rotate rasp-berry rasp-berry-icon" />
      <div className="infotxt-middle-section">
        <div className="infotxt-middle-content-section">
          <div className="infotxt-middle-content-text">
            <p className="infotxt-main-text">Usámos la tecnología para cuidar la cadena de suministros</p>
            <p className="infotxt-secondary-text">¿Quieres saber más?</p>

            <a href="mailto:support@fridanet.com" className="infotxt-mailto-button">
              Contáctanos
            </a>
          </div>
        </div>
      </div>
      <BlueberryIcon styleClass="infotxt-icn infotxt-mirror blue-berry-icon" />
    </div>
  );
};

export default InformationText;
