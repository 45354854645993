/**
 * method to return the array ordeded from the defaultCommodity
 * @param {array} obj
 * @param {string} firstCommodity product name
 * @param {string} lastCommodity product name
 * @return {array}
 */
const ProductDefault = (obj, firstCommodity = '', lastCommodity = '') => {
  const objCopy = obj;
  for (const [i, commodity] of objCopy.entries()) {
    if (firstCommodity && commodity.abbr === firstCommodity) [objCopy[0], objCopy[i]] = [objCopy[i], objCopy[0]];

    if (lastCommodity && commodity.abbr === lastCommodity) {
      [objCopy[objCopy.length - 1], objCopy[i]] = [objCopy[i], objCopy[objCopy.length - 1]];
    }
  }
  return objCopy;
};

export default ProductDefault;
