import React from 'react';

/*eslint-disable*/
const TruckIcon = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 19 10">
    <defs>
      <path
        d="M14.6858851,4 L6.76653409,4 C5.96457449,4 5.5,4.275 5.5,5.075 L5.5,5 L2.21697791,5 C1.89118182,5 1.61550821,5.2 1.49020202,5.475 L0.0751837121,7.35 C0,7.5 0,7.625 0,7.775 L0.114849753,11.325 C0.114849753,11.725 0.390523364,12 0.791503162,12 L1.49020202,12 C1.69069192,12.925 2.56783523,13.6 3.49510101,13.6 C4.49755051,13.6 5.2995101,12.925 5.5,12 L10.490202,12 C10.6906919,12.925 11.5678352,13.6 12.495101,13.6 C13.4223668,13.6 14.2995101,12.925 14.5,12 L15.2267759,12 C15.7029394,12 16.1039192,11.6 16.1039192,11.125 L15.9890694,5.425 C16.0893144,4.625 15.4878447,4 14.6858851,4 Z M6,6 L6,7 L1.5,7 L2.22222222,6 L6,6 Z M5.5,11 C5.17701863,10.1869159 4.43167702,9.57009346 3.5621118,9.57009346 C2.69254658,9.57009346 1.97204969,10.1588785 1.6242236,11 L1,11 L1,8 L6,8 L6,11 L5.5,11 Z M3.4995651,12 C3.22394298,12 3,11.7758621 3,11.5 C3,11.2241379 3.22394298,11 3.4995651,11 C3.77518723,11 3.9991302,11.2241379 3.9991302,11.5 C4.01635658,11.7758621 3.77518723,12 3.4995651,12 L3.4995651,12 Z M12.5,12 C12.2241379,12 12,11.7758621 12,11.5 C12,11.2241379 12.2241379,11 12.5,11 C12.7758621,11 13,11.2241379 13,11.5 C13,11.7758621 12.7758621,12 12.5,12 Z M14.9261538,10 L14.5,10 C14.18,9.35074627 13.3615385,9 12.5,9 C11.6384615,9 11.0369231,9.32835821 10.6923077,10 L7,10 L7,5.06716418 C7,5.06716418 7,5 7.07384615,5 L14.9261538,5 C14.9261538,5 15,5 15,5.06716418 L15,10 L14.9261538,10 Z"
        id="path-1-crossing-icon"
      ></path>
    </defs>
    <g id="Sprint-12--Aug-03-2020" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="-01.2-Crossing-points-filtered--Sprint-12" transform="translate(-1036.000000, -635.000000)">
        <g id="Content" transform="translate(118.000000, 318.000000)">
          <g id="Shipping-point-report" transform="translate(40.000000, 101.000000)">
            <g id="Icn/Crossing" transform="translate(878.000000, 212.000000)">
              <mask id="mask-2-crossing" fill="white">
                <use xlinkHref="#path-1-crossing-icon"></use>
              </mask>
              <use id="truck-icon-to-fill" fill="#313233" fillRule="nonzero" xlinkHref="#path-1-crossing-icon"></use>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TruckIcon;
