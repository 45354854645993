import React from 'react';

const ErrorCommitmentMessage = () => (
  <div>
    <p className="status-message-text">
      Contacta al equipo de soporte de FRIDA, dinos un poco más de como podemos ayudarte.
    </p>
    <div className="status-message-contact">
      <div>
        <a className="link-to-mail-support-message" href="mailto:support@fridanet.com">
          Enviar mensaje
        </a>
      </div>
    </div>
  </div>
);

export default ErrorCommitmentMessage;
