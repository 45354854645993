import React from 'react';

/* eslint-disable */
const WeatherBrokenClouds = ({ styleClass, isWarning = false }) => (
  <svg className={styleClass} viewBox="0 0 24 24">
    <title>Broken Clouds</title>
    <defs>
      <path
        d="M5.65384615,20 L15.8076923,20 C18.2869231,20 20.4615385,17.6387368 20.4615385,14.9473684 C20.4615385,14.4673684 20.3913077,13.9983158 20.2635385,13.5511579 C21.845,13.1604211 23,11.7498947 23,10.1052632 C23,8.24252632 21.5632308,6.70736842 19.6729231,6.54652632 C19.2219231,5.05263158 17.8240769,4 16.2307692,4 C14.574,4 13.1719231,5.13178947 12.7725385,6.69810526 C12.3875385,6.59536842 11.9898462,6.52631579 11.5769231,6.52631579 C9.39469231,6.52631579 7.53992308,8.03621053 7.06184615,10.1178947 C6.60323077,9.96968421 6.13107692,9.89473684 5.65384615,9.89473684 C3.17461538,9.89473684 1,12.256 1,14.9473684 C1,17.6387368 3.17461538,20 5.65384615,20 Z M16.2307692,4.84210526 C17.5355385,4.84210526 18.6685385,5.76505263 18.9249231,7.03663158 C18.9646923,7.23536842 19.1982308,7.34989474 19.4038462,7.36842105 C20.9201538,7.36842105 22.1538462,8.59621053 22.1538462,10.1052632 C22.1538462,11.3945263 21.2230769,12.4968421 19.964,12.7595789 C19.2202308,11.1747368 17.7242308,10.0092632 16.0564615,9.90315789 C15.6875385,8.59873684 14.7593077,7.55621053 13.573,6.99031579 C13.8471538,5.74905263 14.9361538,4.84210526 16.2307692,4.84210526 Z M5.65384615,10.7368421 C6.18692308,10.7368421 6.71323077,10.8522105 7.21838462,11.0804211 C7.34192308,11.1368421 7.48492308,11.1292632 7.60169231,11.0627368 C7.72015385,10.9962105 7.798,10.8774737 7.81323077,10.7435789 C8.02646154,8.81936842 9.64515385,7.36842105 11.5769231,7.36842105 C13.3834615,7.36842105 14.9522308,8.64673684 15.3084615,10.4075789 C15.3490769,10.608 15.5233846,10.7418947 15.7357692,10.7452632 C15.7662308,10.7444211 15.7958462,10.7410526 15.8076923,10.7368421 C17.9865385,10.7368421 19.6153846,12.96 19.6153846,14.9473684 C19.6153846,16.9347368 17.9865385,19.1578947 15.8076923,19.1578947 L5.65384615,19.1578947 C3.475,19.1578947 1.84615385,16.9347368 1.84615385,14.9473684 C1.84615385,12.96 3.475,10.7368421 5.65384615,10.7368421 Z"
        id="path-weather-broken-clouds"
      ></path>
    </defs>
    <g id="Symbols-broken-clouds" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-weather-broken-clouds">
        <g id="Weather_Icons-broken-clouds">
          <mask id="mask-broken-clouds" fill="white">
            <use xlinkHref="#path-weather-broken-clouds"></use>
          </mask>
          <use
            id="Shape"
            stroke="#141414"
            strokeWidth="0.5"
            fill={isWarning ? '#e40d2b' : '#141414'}
            fillRule="nonzero"
            xlinkHref="#path-weather-broken-clouds"
          ></use>
          <g id="Neutral-/-900-broken-clouds" mask="url(#mask-broken-clouds)">
            <path d="M8,0 L24,0 L24,0 L24,24 L8,24 C3.581722,24 5.41083001e-16,20.418278 0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WeatherBrokenClouds;
