import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import LocationsOptions from '../../../../../utils/Highcharts/LocationsOptions';
import LocationsSerie from '../../../../../utils/Highcharts/LocationsSerie';
import NoData from '../../../../../components/NoData';
import PropTypes from 'prop-types';
import SelectCascaderContainer from '../../../../../components/SelectCascaderContainer';
import isEmptyObject from '../../../../../utils/common/isEmptyObject';
import utilsApi from '../../../../../utils/api/UtilsApi';
import { GetMonth } from '../../../../../utils/common/DateHandler';
import { connect } from 'react-redux';
import { generateDatesStructure } from '../../../../../utils/Highcharts/dataHandlers/locationGraph';
import { useTranslation } from 'react-i18next';
import './Locations.scss';
import shippingPointSelectors from '../../../../../utils/page_definition/shipping_point';

const Locations = ({ productDetail, selectedDate }) => {
  const [crossingBundle, setCrossingBundle] = useState();
  const [highChartsOptions, setHighChartsOptions] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);

  // i18n
  const { t } = useTranslation('locations');

  useEffect(() => {
    const updateGraph = () => {
      // only when the currentCommodity hook exists
      if (crossingBundle) {
        const { data } = crossingBundle;
        // ToolTipHeaderFormat
        const ToolTipHeaderFormat = '<span style="font-size: 15px"> {point.key}</span><br/><br/>';
        // get the Highchart options.
        const cloneHCH = { ...LocationsOptions };
        // array to parse the series array with the differents imports types: {Current, Future, Last}
        const hcSeries = [];
        // Parse the Serie for the Current Season data.
        // Right now the data from this service is broken.
        if (isEmptyObject(data)) {
          const crossingParsedData = generateDatesStructure(data.crossingInformation);
          const parsedSeries = crossingParsedData.map((crossingPoint, index) => {
            return LocationsSerie(data.crossingInformation[index].filterOptions, crossingPoint);
          });
          hcSeries.push(...parsedSeries);
        }
        //TODO: add the mocks for Future average and last series JUST FOR DEMO.
        // add the ToolTipHeaderFormat to the tooltip options.
        cloneHCH.tooltip.headerFormat = ToolTipHeaderFormat;
        // Set the series object.
        cloneHCH.series = hcSeries;
        setHighChartsOptions(cloneHCH);
      }
    };
    updateGraph();
  }, [crossingBundle]);

  // Hook to handle all filters at once and render graphic
  useEffect(() => {
    // service call to get crossing zones
    const CancelToken = utilsApi.cancelToken();
    const getCrossingInformation = async () => {
      const { abbr } = productDetail;
      const allCrossingCalls = await Promise.all(
        selectedFilters.map(({ location, packageType, productType }) =>
          utilsApi.commodityHistoricalPrices(abbr, location, selectedDate, productType, packageType, CancelToken.token),
        ),
      );
      const crossingInformation = allCrossingCalls.map((element, index) => ({
        informationRetrieved: element.data,
        filterOptions: {
          pckType: selectedFilters[index].packageType,
          prdType: selectedFilters[index].productType,
          location: selectedFilters[index].location,
        },
      }));
      //TODO: {FROM} {TO} i18n traduction for months
      setCrossingBundle({
        from: GetMonth(crossingInformation[0].informationRetrieved.from_date),
        to: GetMonth(crossingInformation[0].informationRetrieved.to_date),
        data: { crossingInformation },
      });
      setSelectedFilters([]);
    };
    if (selectedFilters.length) getCrossingInformation();

    return function () {
      CancelToken.cancel();
    };
  }, [selectedFilters, selectedDate]);

  const renderHighCharts = () =>
    //TODO: { from } { to } i18n pending
    crossingBundle && highChartsOptions ? (
      <div className="locations-graph-container" data-test={shippingPointSelectors.locationsGraphBody}>
        <HighchartsReact updateArgs={[true, true, true]} highcharts={Highcharts} options={highChartsOptions} />
        <p className="average-graph-custom-xaxis-month-label xaxis-month-left">{crossingBundle.from}</p>
        <p className="average-graph-custom-xaxis-month-label xaxis-month-right">{crossingBundle.to}</p>
      </div>
    ) : (
      //TODO: need handle the promise.All errors
      <NoData />
    );

  // Parse data according to filter selection
  const reportFilters = (data) => {
    setSelectedFilters(data);
  };

  const renderSelectCascader = () => (
    <SelectCascaderContainer productDetail={productDetail} getResults={reportFilters} id="locations-select-cascader" />
  );

  return (
    <div className="locations-container">
      <div className="locations-header">
        <div className="locations-graph-description">
          <p>{t('graphDescription')}</p>
        </div>
        {renderSelectCascader()}
      </div>
      {renderHighCharts()}
    </div>
  );
};

Locations.defaultProps = {
  productDetail: undefined,
};

Locations.propTypes = {
  productDetail: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date),
  slug: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  productDetail: state.products[state.products.active],
  selectedDate: state.selectedDate.date,
  slug: state.products.active,
});

export default connect(mapStateToProps)(Locations);
