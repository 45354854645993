import React, { useState, useEffect, useReducer } from 'react';
import Button from './Button';
import PropTypes from 'prop-types';
import VolumeSelectCascader from './VolumeSelectCascader';
import originsSelect from '../utils/Select/originsSelect';
import { AddSelect, RemoveSelect } from '../assets/icons/assets';
import { volumeProductTypeSelect } from '../utils/Select/productTypeSelect';
import { useTranslation } from 'react-i18next';
import './VolumeSelectCascaderContainer.scss';

const VolumeSelectCascaderContainer = ({ origins, getResults }) => {
  const [defaultOptions, setDefaultOptions] = useState();
  const [optionSelected, setOptionSelected] = useState();
  const [locationsState, setLocationsState] = useState();
  const initialState = { currentID: 1, options: [] };

  // i18n
  const { t } = useTranslation('selectCascaderContainer');

  useEffect(() => {
    if (origins.length) setLocationsState(origins);

    return function cleanup() {
      // clean the main state hook when the component is unmounted
      setLocationsState();
      dispatch({ type: 'CLEAN_STATE' });
    };
  }, [origins]);

  useEffect(() => {
    if (locationsState) {
      const locationsParsed = originsSelect(locationsState, 'entireMarket');

      const defaultLocation = locationsParsed[0].value;
      const currentLocation =
        defaultLocation !== 'entireMarket'
          ? locationsState.filter((origin) => origin.origin_slug === defaultLocation)[0]
          : defaultLocation;

      // data parsed and filtered
      const productTypesFiltered = volumeProductTypeSelect(currentLocation);

      // data select default
      const productTypeSelected = productTypesFiltered[0].value;

      const defaultOption = {
        location: defaultLocation,
        productType: productTypeSelected,
      };

      setDefaultOptions([defaultOption]);
      getResults([defaultOption]);
      dispatch({ type: 'ADD_DEFAULT_OPTION', option: { ...defaultOption, id: initialState.currentID } });
    }
  }, [locationsState]);

  useEffect(() => {
    if (optionSelected) dispatch({ type: 'UPDATE_SELECTED_OPTION', option: optionSelected, id: optionSelected.id });
  }, [optionSelected]);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'ADD_DEFAULT_OPTION':
        return {
          ...state,
          options: [action.option],
        };
      case 'ADD_SELECT_CASCADER':
        return { ...state, options: [...state.options, action.option], currentID: state.currentID + 1 };
      case 'CLEAN_STATE':
        return initialState;
      case 'REMOVE_SELECT_CASCADER':
        return { ...state, options: state.options.filter(({ id }) => id !== action.id) };
      case 'UPDATE_SELECTED_OPTION': {
        const allOptions = state.options;
        allOptions[state.options.findIndex(({ id }) => id === action.id)] = action.option;
        return { ...state, options: allOptions };
      }
      case 'CLEAR_SELECT_OPTIONS':
        return {
          ...state,
          options: defaultOptions,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAddClick = () => {
    if (state.options.length)
      dispatch({ type: 'ADD_SELECT_CASCADER', option: { ...state.options[0], id: state.currentID + 1 } });
  };

  const handleRemoveClick = (id) => {
    dispatch({ type: 'REMOVE_SELECT_CASCADER', id });
  };

  const handleApplyClick = () => {
    getResults(state.options);
  };

  const handleCancelClick = () => {
    if (defaultOptions) {
      dispatch({ type: 'CLEAR_SELECT_OPTIONS' });
      getResults(defaultOptions);
    }
  };

  const renderSelectCascader = () => {
    if (state && state.options.length) {
      return state.options.map((option) => {
        return (
          <div className={`volume-select-cascader-row-container-${state.options.length > 1}`} key={`${option.id}`}>
            <div className="volume-select-cascader-row">
              <VolumeSelectCascader
                actions={false}
                id={option.id}
                origins={locationsState}
                setOptionsSelected={setOptionSelected}
                styleClass="no-border-select no-arrow-select no-label-title-select ellipsis-text-minor box-shadow-select"
              />
              {state.options.length > 1 ? (
                <Button
                  onClick={() => handleRemoveClick(option.id)}
                  shape="complementary"
                  size="x-small"
                  styleClass="volume-select-cascader-button remove-volume-select-button"
                  type="destructive"
                >
                  <RemoveSelect styleClass="volume-select-button-icon remove-volume-select-button-icon" />
                </Button>
              ) : (
                ''
              )}
              <Button
                onClick={handleAddClick}
                shape="complementary"
                size="x-small"
                styleClass="volume-select-cascader-button add-volume-select-button select-cascader-button-desktop"
                type="secondary"
              >
                <AddSelect styleClass="volume-select-button-icon add-volume-select-button-icon" />
              </Button>
            </div>
            <hr className="dashboard-divisor dashboard-divisor-responsive" />
          </div>
        );
      });
    }
  };

  return (
    <div className="volume-select-cascader-container">
      {renderSelectCascader()}
      <Button
        onClick={handleAddClick}
        shape="complementary"
        size="x-small"
        styleClass="volume-select-cascader-button add-volume-select-button select-cascader-button-responsive"
        type="secondary"
      >
        <AddSelect styleClass="volume-select-button-icon add-volume-select-button-icon" />
      </Button>
      <Button
        styleClass="volume-select-apply-filters-button"
        size="small"
        type="secondary"
        onClick={() => handleApplyClick()}
      >
        {t('applyButton')}
      </Button>
      {state.options.length > 1 ? (
        <Button size="small" type="primary" shape="complementary" onClick={() => handleCancelClick()}>
          {t('clearButton')}
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

VolumeSelectCascaderContainer.propTypes = {
  getResults: PropTypes.func,
  origins: PropTypes.array,
};

export default VolumeSelectCascaderContainer;
