import { SET_SELECTED_PROGRAM, GET_PROGRAMS, GET_PROGRAM_DETAILS, CLEAN_PROGRAMS } from '../actions/programs/types';

//TODO initial id to 0, the id type TBD
const initialState = {
  id: null,
  programDetails: null,
  programs: null,
};

const selectedProgram = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROGRAM:
      return {
        ...state,
        id: action.selectedProgram,
      };
    case GET_PROGRAMS:
      return {
        ...state,
        programs: action.payload,
      };
    case GET_PROGRAM_DETAILS:
      return {
        ...state,
        programDetails: action.payload,
      };
    case CLEAN_PROGRAMS:
      return {
        ...state,
        id: undefined,
        programDetails: undefined,
      };
    default: {
      return state;
    }
  }
};

export default selectedProgram;
