import React, { useEffect, useState } from 'react';
import Checkbox from './Checkbox';
import PropTypes from 'prop-types';
import { SelectArrowUp, SelectArrowDown } from '../assets/icons/assets';
import './SelectCheckbox.scss';

const SelectCheckbox = ({ data, id, styleClass, setSelectedData, label, disabled }) => {
  const [checkboxData, setCheckboxData] = useState();
  const [floatingMenu, setFloatingMenu] = useState(false);

  useEffect(() => setCheckboxData(data), [data]);

  // adding event listener to hide the select and cleanup when is unmounted
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedData(checkboxData);
  }, [checkboxData]);

  const handleFlMenu = () => {
    setFloatingMenu((prevState) => !prevState);
  };

  // method to handle the outside click
  const handleClickOutside = (e) => {
    if (
      !e.target.classList.contains('select-checkbox-container') &&
      !e.target.classList.contains(`select-checkbox-button-component-${id}`) &&
      !e.target.classList.contains(`select-checkbox-button-text-${id}`) &&
      !e.target.classList.contains(`select-checkbox-floating-menu-${id}`) &&
      !e.target.classList.contains(`select-checkbox-floating-options-${id}`) &&
      !e.target.classList.contains(`select-checkbox-floating-option-${id}`) &&
      !e.target.classList.contains(`select-checkbox-floating-option-check-${id}`) &&
      !e.target.classList.contains('fk-checkbox-container') &&
      !e.target.classList.contains('fk-checkbox-input') &&
      !e.target.classList.contains('fk-checkbox-check-icon') &&
      !e.target.classList.contains('fk-checkbox-label')
    ) {
      setFloatingMenu(false);
    }
  };

  const handleCheckboxChange = (value, id) => {
    const optionIndex = checkboxData.findIndex((option) => option.id === id);
    const shadowArray = [...checkboxData];
    shadowArray[optionIndex].isActive = value;
    setCheckboxData(shadowArray);
  };

  const renderListMenu = () => {
    if (floatingMenu)
      return (
        <div className={`select-checkbox-floating-menu-${id}`}>
          <ul className={`select-checkbox-floating-options-${id}`}>
            {checkboxData.map((option, index) => (
              <li key={index} className={`select-checkbox-floating-option-${id}`}>
                <Checkbox
                  handleCheckboxChange={handleCheckboxChange}
                  idValue={option.id}
                  isActive={option.isActive}
                  label={option.label}
                  styleClass={`select-checkbox-floating-option-check-${id}`}
                  value={option.value}
                  disabled={disabled}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    return '';
  };

  // render the arrow icon depends if is active or no the modal floating
  const renderButtonIcon = () => {
    if (floatingMenu) return <SelectArrowUp styleClass="select-button-arrow-icon" />;
    return <SelectArrowDown styleClass="select-button-arrow-icon" />;
  };

  return (
    <div className={`select-checkbox-container ${styleClass}`}>
      <button
        className={`select-checkbox-button-component-${id} disabled-${disabled}`}
        onClick={handleFlMenu}
        disabled={disabled}
      >
        <p className={`select-checkbox-button-text-${id}`}>{label}</p>
        {renderButtonIcon()}
      </button>
      {renderListMenu()}
    </div>
  );
};

SelectCheckbox.defaultProps = {
  data: [],
  disabled: false,
  label: '',
  styleClass: 'Select Options',
};

SelectCheckbox.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  setSelectedData: PropTypes.func.isRequired,
  styleClass: PropTypes.string,
};

export default SelectCheckbox;
