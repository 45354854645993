import { GetDay } from '../../common/DateHandler';

/**
 * Fill mainstructure with proper data according to values in each serie
 * @param {Array} main structure to be use as pattern
 * @param {Array} given values to be placed according to main structure
 * @returns {Array} recived main structure with proper values placed
 */
const fillGraphValues = (mainStructure, givenValues) => {
  const fixDateGivenValues = givenValues.map((e) => ({ average_price: e.average_price, date: GetDay(e.report_date) }));
  return mainStructure.map((currVal) => {
    const receivedValue = fixDateGivenValues.find((element) => element.date === currVal.date);
    return receivedValue || currVal;
  });
};

/**
 * Create a set of dates according to a multiple groups of crossings
 * @param {Array} allSeries
 * @returns {Array} return all the arrays filled with missing data to fill the holes
 */
const generateDatesStructure = (allSeries) => {
  const allDates = allSeries
    .map(({ informationRetrieved }) => informationRetrieved.data.map((element) => element.report_date))
    .flat()
    .sort((a, b) => new Date(a) - new Date(b));

  const uniqueLabels = new Set(allDates.map((e) => e));

  // Create a structure to keep track of labels in graph
  const graphValues = [...uniqueLabels].map((date) => ({ average_price: null, date: GetDay(date) }));

  const filledGraph = allSeries.map(({ informationRetrieved }) =>
    fillGraphValues(graphValues, informationRetrieved.data),
  );
  return filledGraph;
};

export { generateDatesStructure };
