import React from 'react';
import PropTypes from 'prop-types';
import RowCalendar from '../../RowCalendar/RowCalendar';
import { useTranslation } from 'react-i18next';

const ProgramDelivery = ({ programData, programDetails, unit }) => {
  const { t } = useTranslation('mpCommittedProgram');
  const weekRowTitle = t('mpProgramDelivery:title');
  const { package_weight } = programData;

  const renderRowCalendar = () => (
    <RowCalendar
      data={programDetails}
      label={weekRowTitle}
      packageWeight={package_weight}
      programData={programData}
      unit={unit}
    />
  );

  return (
    <div className="program-delivery-container">
      <h3 className="row-calendar-title">{t('mpProgramDelivery:weeklyDelivery')}</h3>
      {renderRowCalendar()}
    </div>
  );
};

ProgramDelivery.propTypes = {
  programData: PropTypes.object.isRequired,
  programDetails: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
};

export default ProgramDelivery;
