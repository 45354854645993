import React, { useEffect, useState, useRef } from 'react';
import ProductIcon from '../../../utils/product/ProductIcons';
import { CancelStatusIcon } from '../../../assets/icons/assets';
import { setActiveProductAction } from '../../../redux/actions/products/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  commodityTogglerControlButtonSelected,
  commodityTogglerContainer,
  commodityTogglerList,
  commodityTogglerControlButton,
  commodityTogglerListButton,
} from '../../../utils/page_definition/commodity_toggler';
import PropTypes from 'prop-types';
import './style.scss';

const CommodityToggle = ({ mockData }) => {
  const activeProduct = useSelector((state) => state.products.active);
  const productList = useSelector((state) => state.products.productList);
  const dispatch = useDispatch();

  const productListData = mockData || productList;

  const [isOpen, setIsOpen] = useState(false);
  const trigger = useRef();
  const popover = useRef();

  const handleToggleClick = () => {
    if (productListData?.length) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleItemClick = (slug) => {
    setIsOpen(false);
    dispatch(setActiveProductAction(slug));
  };

  useEffect(() => {
    function handleClickOutside(ev) {
      if (trigger.current.contains(ev.target)) return;
      if (popover.current.contains(ev.target)) return;

      setIsOpen(false);
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);

      return function cleanup() {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpen]);

  return (
    <div className={`commodity-toggle navbar-anim ${isOpen ? 'active' : ''}`} data-test={commodityTogglerContainer}>
      <button
        className="commodity-toggle__control navbar-anim__control"
        onClick={handleToggleClick}
        ref={trigger}
        data-test={commodityTogglerControlButton}
      >
        {isOpen ? <CancelStatusIcon /> : ProductIcon(activeProduct, '', commodityTogglerControlButtonSelected)}
      </button>
      <div className="navbar-anim__popover" ref={popover}>
        <ul className="navbar-anim__list" data-test={commodityTogglerList}>
          {productListData?.map((product, index) => {
            const { abbr } = product;
            const isActive = abbr === activeProduct;
            return (
              <li key={index} className="navbar-anim__list-item">
                <button
                  className={`navbar-anim__list-item-btn ${isActive ? 'active' : ''}`}
                  onClick={() => handleItemClick(abbr)}
                  data-test={`${commodityTogglerListButton}-${index}`}
                >
                  {ProductIcon(abbr)}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

CommodityToggle.propTypes = {
  mockData: PropTypes.array,
};

export default CommodityToggle;
