import React, { useEffect, useState } from 'react';
import AveragePriceSerie from '../../../../../utils/Highcharts/AveragePriceSerie';
import AveragePricesOptions from '../../../../../utils/Highcharts/AveragePriceOptions';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import NoData from '../../../../../components/NoData';
import SelectCascader from '../../../../../components/SelectCascader';
import isEmptyObject from '../../../../../utils/common/isEmptyObject';
import shippingPointSelectors from '../../../../../utils/page_definition/shipping_point';
import utilsApi from '../../../../../utils/api/UtilsApi';
import { GetMonth } from '../../../../../utils/common/DateHandler';
import { generateDataAverage } from '../../../../../utils/Highcharts/dataHandlers/averageGraph';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './Average.scss';

const Average = () => {
  const { t } = useTranslation('average');
  const [averagePrice, setAveragePrice] = useState();
  const [error, setError] = useState();
  const [highChartsOptions, setHighChartsOptions] = useState();
  const [optionsSelected, setOptionsSeleted] = useState();

  const selectedDate = useSelector((state) => state.selectedDate.date);
  const activeProduct = useSelector((state) => state.products.active);
  const productDetail = useSelector((state) => state.products[state.products.active]);

  // expand highchart required for range serie
  HighchartsMore(Highcharts);

  // Make an API call when there is a change in date or crossingZone
  useEffect(() => {
    const CancelToken = utilsApi.cancelToken();
    if (optionsSelected) {
      const { location, productType, packageType } = optionsSelected;
      utilsApi
        .commodityHistoricalPrices(activeProduct, location, selectedDate, productType, packageType, CancelToken.token)
        .then((res) => {
          setAveragePrice({
            from: GetMonth(res.data.from_date),
            to: GetMonth(res.data.to_date),
            data: res.data,
          });
        })
        .catch((e) => {
          if (!utilsApi.isCancel(e)) {
            setError(e);
            setAveragePrice();
          }
        });
    }

    return function () {
      CancelToken.cancel();
    };
  }, [optionsSelected, selectedDate]);

  useEffect(() => {
    const updateGraph = () => {
      // only when the currentCommodity hook exists
      const { data } = averagePrice;
      // ToolTipHeaderFormat
      const ToolTipHeaderFormat = '<span style="font-size: 15px">{point.key}</span><br/><br/>';
      // get the Highchart options.
      const cloneHCH = { ...AveragePricesOptions };
      // array to parse the series array with the differents imports types: {Current, Future, Last}
      const hcSeries = [];
      // Parse the Serie for the Current Season data.
      if (isEmptyObject(data)) {
        let preData = {
          filledArea: data.data,
          filledLastSeason: data.data,
          filledLastFiveYears: data.data,
          filledAverage: [],
        };
        if (data.average_price) preData = generateDataAverage(data);
        hcSeries.push(AveragePriceSerie(`${t('priceRange')} ↓  `, 'area', preData.filledArea));
        hcSeries.push(AveragePriceSerie(t('lastSeasonAvg'), 'lastSeason', preData.filledLastSeason));
        hcSeries.push(AveragePriceSerie(t('lastFiveAvg'), 'lastYears', preData.filledLastFiveYears));
        if (preData.filledAverage.length > 0) {
          hcSeries.push(AveragePriceSerie('Fridanet Price', 'average', preData.filledAverage));
        }
      }
      // add the ToolTipHeaderFormat to the tooltip options.
      cloneHCH.tooltip.headerFormat = ToolTipHeaderFormat;
      // Set the series object.
      cloneHCH.series = [];
      cloneHCH.series = hcSeries;
      setHighChartsOptions(cloneHCH);
    };

    if (averagePrice) updateGraph();
  }, [averagePrice, t('lastFiveAvg')]);

  const renderHighCharts = () =>
    averagePrice && highChartsOptions ? (
      <div className="average-graph-container" data-test={shippingPointSelectors.shippingPointChartBody}>
        <p className="average-graph-custom-yaxis-label">{optionsSelected.packageType || ''}</p>
        <HighchartsReact updateArgs={[true, true, true]} highcharts={Highcharts} options={highChartsOptions} />
        <p className="average-graph-custom-xaxis-month-label xaxis-month-left">{averagePrice.from}</p>
        <p className="average-graph-custom-xaxis-month-label xaxis-month-right">{averagePrice.to}</p>
      </div>
    ) : (
      <NoData error={error} />
    );

  // RETURN HIGHCHART
  return (
    <div data-test={shippingPointSelectors.shippingPointChartContainer} className="average-container">
      <div className="average-graph-description">
        <p className="average-graph-description-text">{t('graphDescription')}</p>
      </div>
      <hr className="average-divisor-responsive" />
      <SelectCascader
        productDetail={productDetail}
        setOptionsSelected={setOptionsSeleted}
        styleClass="select-cascader-column"
      />
      <hr className="average-divisor-responsive" />
      {renderHighCharts()}
    </div>
  );
};

export default Average;
