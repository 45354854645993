import { arrayOf, oneOfType, node, string } from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';
import './style.scss';

const ScrollFeedback = ({ children, className, ...other }, ref) => {
  const hitAreaTopRef = useRef();
  const hitAreaBottomRef = useRef();
  const shadowTopRef = useRef();
  const shadowBottomRef = useRef();

  useEffect(() => {
    const hitAreaTop = hitAreaTopRef.current;
    const hitAreaBottom = hitAreaBottomRef.current;
    const shadowTop = shadowTopRef.current;
    const shadowBottom = shadowBottomRef.current;

    const options = {
      root: ref.current,
      rootMargin: '0px',
      threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
    };

    const intersectionCallback = (entries) => {
      for (let i = 0, len = entries.length; i < len; i++) {
        const { target, intersectionRatio } = entries[i];
        const el = target === hitAreaTop ? shadowTop : shadowBottom;
        el.style.opacity = 1 - intersectionRatio;
      }
    };

    const observer = new IntersectionObserver(intersectionCallback, options);
    observer.observe(hitAreaTop);
    observer.observe(hitAreaBottom);

    requestAnimationFrame(() => {
      ref.current.classList.add('active');
    });

    return () => {
      observer.unobserve(hitAreaTop);
      observer.unobserve(hitAreaBottom);
    };
  }, [ref]);

  return (
    <div ref={ref} className={`scroll-feedback ${className}`} {...other}>
      <div className="sf__iosWrapper">
        <div ref={shadowTopRef} className="sf__shadow --top"></div>
        <div ref={hitAreaTopRef} className="sf__hit-area"></div>
        {children}
        <div ref={hitAreaBottomRef} className="sf__hit-area"></div>
        <div ref={shadowBottomRef} className="sf__shadow --bottom"></div>
      </div>
    </div>
  );
};

ScrollFeedback.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
};

export default forwardRef(ScrollFeedback);
