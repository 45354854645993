import {
  CLEAN_PRODUCTS,
  SET_PRODUCTS,
  SET_ACTIVE_PRODUCT,
  SET_PRODUCT_DETAIL,
  SET_PRODUCTS_ERROR,
  SET_PRODUCTS_TEMP,
} from '../actions/products/types';

/**
 * This state is being used as a HashTable, where we are going to have
 * an abbr key for each product detail saved.
 *
 * Report Date is being used as a validation flag to identify wether our
 * product data is valid for a given date, otherwise we should re-request it from the server
 *
 * example:
 *      {
 *        active: 'rasp',
 *        products: [],
 *        error: false
 *        rasp: { abbr:'rasp', name:'raspberries', report_date: Date, locations:[], origins:[], weather:[] }
 *      }
 */
const initialState = { active: '', error: undefined, productList: [], tempUnit: 'f' };

const products = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_PRODUCTS:
      return {
        ...state,
        productList: undefined,
        active: undefined,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        productList: action.payload,
      };
    case SET_ACTIVE_PRODUCT:
      return {
        ...state,
        active: action.payload,
      };
    case SET_PRODUCT_DETAIL: {
      const stateCopy = { ...state };
      const key = action.payload['abbr'];
      if (key) stateCopy[key] = action.payload;
      return stateCopy;
    }
    case SET_PRODUCTS_ERROR:
      return {
        ...state,
        productList: [],
        error: action.payload,
      };
    case SET_PRODUCTS_TEMP: {
      return {
        ...state,
        tempUnit: action.payload,
      };
    }
    default:
      return state;
  }
};

export default products;
