import { array, func } from 'prop-types';
import React from 'react';
import { ExtraYearsCancel } from 'assets/icons/assets';

/*
 * @see {@link https://docs.google.com/document/d/1euHkr2brZYFLV4SSMShzYJIUVYXAYomvrFdSlW5dpMQ/edit#|SPECS}
 */
const SelectedYearsList = ({ selectedYearsList = [], removeYear = () => {} }) => {
  return (
    <div>
      <ul className="df" role="list">
        {selectedYearsList.map((year, index) => {
          return (
            <li key={index} className="df aic h-l py-s pr-s pl-base mr-base neutral-300-bg br-m">
              <span className="fs-6 mr-base neutral-800 fw600">{year.label}</span>
              <button className="square-m dif aic p0 border-none transparent-bg" onClick={() => removeYear(year.id)}>
                <ExtraYearsCancel witdh={12} height={12} className="primary-900" />
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SelectedYearsList.propTypes = {
  selectedYearsList: array,
  removeYear: func,
};

export default SelectedYearsList;
