import { SET_VOLUME_REPORT } from '../actions/volumeReport/types';

const initialState = { id: '', label: '', value: '' };

const volumeReport = (state = initialState, action) => {
  switch (action.type) {
    case SET_VOLUME_REPORT: {
      return {
        ...state,
        id: action.volumeReport.id,
        label: action.volumeReport.label,
        value: action.volumeReport.value,
      };
    }
    default: {
      return state;
    }
  }
};

export default volumeReport;
