import React from 'react';
import EventDetails from '../eventDetails/EventDetails';
import PropTypes from 'prop-types';
import Tooltip from '../../../../../components/Tooltip';
import WeekRow from './WeekRow';
import { InfoStatusIcon } from '../../../../../assets/icons/assets';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './RowCalendar.scss';

const RowCalendar = ({ packageWeight, currentDate, programData, label, totalBoxes, data, unit }) => {
  // to commit and committed destructuring.
  const boxesPallet = data.boxes_pallet || programData.boxes_pallet;
  const deliveryDays = data.delivery_days || data.commitment_details;
  const palletsTruck = data.pallets_truck || programData.pallets_truck;
  const commitedBoxes = totalBoxes || programData.commited_boxes;

  const { t } = useTranslation('mpRowCalendar');

  const renderWeekRow = () => {
    if (deliveryDays && deliveryDays.length) {
      const { date } = currentDate;

      return deliveryDays.map((week, index) => {
        const weekData = {
          boxes: commitedBoxes,
          days: week,
          week: index + 1,
        };
        const eventData = week.find((day) => day.id);

        // data for commit program
        if (data.delivery_days) weekData.date = week[0].date;
        // data for my programs
        else if (data.commitment_details) {
          weekData.date = week[0].delivery_date;
          weekData.boxes = week.filter((day) => day.boxes > 0)[0].boxes; // We could avoid this if we had delivery day at the programData
        }
        return (
          <div key={index}>
            <WeekRow
              boxesPallet={boxesPallet}
              currentDate={date}
              data={weekData}
              eventData={eventData}
              packageWeight={packageWeight}
              palletsTruck={palletsTruck}
              unit={unit}
            />
            {eventData && eventData.event ? <EventDetails eventData={eventData} /> : ''}
          </div>
        );
      });
    } else return '';
  };

  return (
    <div className="row-calendar-container">
      <span>
        <h4 className="row-clndr-header">{label}</h4>
        <Tooltip
          customClass="market-place-tooltip row-calendar-tooltip"
          text={t('mpRowCalendar:tooltipDescription')}
          title={t('mpRowCalendar:tooltipTitle')}
        >
          <InfoStatusIcon styleClass="market-place-tooltip-icon" />
        </Tooltip>
      </span>
      <div className="row-clndr-content">{renderWeekRow()}</div>
    </div>
  );
};

RowCalendar.defaultProps = {
  label: '',
  programData: undefined,
  totalBoxes: undefined,
};

RowCalendar.propTypes = {
  currentDate: PropTypes.object.isRequired,
  data: PropTypes.object,
  label: PropTypes.string,
  packageWeight: PropTypes.number.isRequired,
  programData: PropTypes.object,
  totalBoxes: PropTypes.number,
  unit: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currentDate: state.currentDate.currentDate,
});

export default connect(mapStateToProps)(RowCalendar);
