import React from 'react';
import { NavLink } from 'react-router-dom';
import WebStorage from '../utils/WebStorage/WebStorage';
import { User } from '../assets/icons/assets';
import { ROUTES } from '../constants/routes';
import './LoginButton.scss';

const LoginButton = () => {
  const accessToken = WebStorage.getCookieValue('access_token');
  const userPhoto = WebStorage.getCookieValue('user_photo');

  const renderUserPhoto = () => {
    if (userPhoto && userPhoto.length && userPhoto !== 'null')
      return <img alt="user profile" className="login-button-user-photo" src={userPhoto} />;
    return <User styleClass="login-button-user-icon" />;
  };

  if (accessToken)
    return (
      <NavLink className="login-button-container navbar-button" activeClassName="navbar-active" to={ROUTES.MANAGEMENT}>
        <span className="navbar-button__content-wrapper">
          <span className="navbar-button__content">
            <button className="login-button">{renderUserPhoto()}</button>
          </span>
        </span>
      </NavLink>
    );

  return '';
};

export default LoginButton;
