import { bool, func, number, object, string } from 'prop-types';
import React from 'react';
import FlowButtonsContainer from '../detailNavigation/FlowButtonsContainer';
import fixDigits from '../../../../../utils/common/fixDigits';
import handleContentButtons from './handleContentButtons';
import priceHandler from '../../../../../utils/marketplace/price/priceHandler';
import unitHandler from '../../../../../utils/marketplace/units/unitsHandler';
import { toFixed } from '../../../../../utils/common/numberHandler';
import { useTranslation } from 'react-i18next';
import './CommitResume.scss';

const CommitResume = ({
  commitedBoxes,
  currentStep,
  disableNextButton,
  handleSuggestCommitReq,
  nextStep,
  packagingMaterial,
  prevStep,
  programData,
  programDetails,
  selectedDestination,
  unit,
}) => {
  const { t } = useTranslation(['units', 'mpCommitmentResume', 'mpFlowButtons']);
  const { estimatedReturn, unitIcons, rawValue } = unitHandler;
  const { pallets_truck, boxes_pallet, package_weight, program_type, price, weeks } = programData;

  // units value
  const unitValue = toFixed(rawValue(unit, commitedBoxes, pallets_truck, boxes_pallet, package_weight)) || 0;

  // total producer return fixed
  const { producerReturn, unitReturn } = priceHandler(
    programDetails,
    commitedBoxes,
    price,
    packagingMaterial,
    selectedDestination,
  );
  const flatPrice = fixDigits(price);

  // render the type sale
  const renderSaleType = () => (program_type === 1 ? t('mpCommitmentResume:program') : 'Spot');

  // totalEntireReturn conditionated to producerReturn value
  const totalEntireReturn = producerReturn ? fixDigits(producerReturn * weeks) : '--';

  // estimated return on kg
  const estimatedReturnKg = estimatedReturn('kg', unitReturn, package_weight);
  const estimatedReturnKgParsed = toFixed(estimatedReturnKg) || '--';

  // method to handler the boxProgram click and make the commit suggest req
  const handleBoxProgramOnclick = () => {
    if (commitedBoxes) handleSuggestCommitReq();
  };

  // method to render the content buttons, used handleContentButtons method
  const renderContentButtons = () => (
    <div className="comment-resume-buttons-row">
      <FlowButtonsContainer>
        <div className="comment-resume-button">
          {handleContentButtons(
            disableNextButton,
            nextStep,
            prevStep,
            handleBoxProgramOnclick,
            t('mpFlowButtons:next'),
            t('mpFlowButtons:return'),
          )(currentStep)}
        </div>
      </FlowButtonsContainer>
    </div>
  );

  return (
    <div className="commit-resume-container">
      <div className="commit-resume-header">
        <h3 className="commit-resume-title">{t('mpCommitmentResume:title')}</h3>
      </div>
      <div className="commit-resume-content">
        <div className="commit-resume-content-inner">
          {/* total quantity */}
          <div className="commit-resume-content-inner-data">
            <h6>{t('mpCommitmentResume:totalQuantity')}</h6>
            <div className="commit-resume-inner-data-row">
              {unitIcons(unit, `commit-resume-content-unit-icon ${unit}-icon`)}
              <p className="commit-resume-content-unit-label">{parseFloat(unitValue).toLocaleString()}</p>
              <p className="commit-resume-content-unit-description-label capitalize-label">{t(`units:${unit}`)}</p>
            </div>
          </div>
          {/* Sale Type */}
          <div className="commit-resume-content-inner-data">
            <h6>{t('mpCommitmentResume:saleType')}</h6>
            <div className="commit-resume-inner-data-row">
              <p className="commit-resume-content-total-week">{renderSaleType()}</p>
            </div>
          </div>
          {/* Return per kg */}
          <div className="commit-resume-content-inner-data">
            <h6>{t('mpCommitmentResume:returnPerKg')}</h6>
            <div className="commit-resume-inner-data-row">
              <p className="commit-resume-content-estimated-kg price-label">{estimatedReturnKgParsed}</p>
              <p className="commit-resume-content-total-currency">USD</p>
            </div>
          </div>
          {/* flats price sale */}
          <div className="commit-resume-content-inner-data">
            <h6>{t('mpCommitmentResume:flatPrice')}</h6>
            <div className="commit-resume-inner-data-row ">
              <p className="commit-resume-content-total-return price-label">{flatPrice}</p>
              <p className="commit-resume-content-total-currency">USD</p>
            </div>
          </div>

          {/* total entire return */}
          <div className="commit-resume-content-inner-data">
            <h6>{t('mpCommitmentResume:totalSale')}</h6>
            <div className="commit-resume-inner-data-row">
              <p className="commit-resume-content-total-return price-label">{totalEntireReturn}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="commit-resume-footer">{renderContentButtons()}</div>
    </div>
  );
};

CommitResume.propTypes = {
  commitedBoxes: number.isRequired,
  currentStep: number.isRequired,
  disableNextButton: bool.isRequired,
  handleSuggestCommitReq: func.isRequired,
  nextStep: func.isRequired,
  packagingMaterial: number.isRequired,
  prevStep: func.isRequired,
  programData: object.isRequired,
  programDetails: object.isRequired,
  selectedDestination: number.isRequired,
  unit: string.isRequired,
};

export default CommitResume;
