import React from 'react';
import InformationText from './Text/InformationText';
import LoginForm from './Form/LoginForm';
import './Login.scss';

const Login = () => {
  return (
    <div className="login-container">
      <div className="login-inner-container">
        <div className="login-background login-background-1" />
        <div className="login-background login-background-2" />
        <div className="login-onboarding-container">
          <InformationText />
        </div>
        <div className="login-form-card-container">
          <LoginForm />
        </div>
        <div className="login-footer-copyrights">
          <p>FRIDA </p>
          <p>©2020 Copyright todos los derechos reservados</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
