/**
 * `react-hot-toast` wrapper for our custom success and error messages
 * @usage
 *  import Toast from './path/to/Toast'
 *
 *  Toast.success({
 *    title: 'My success title',
 *    message: 'My success message',
 *  })
 *
 *  Toast.error({
 *    title: 'My error title',
 *    message: (
 *      <>
 *        {t('translated string')}
 *        <br />
 *        <span className="fw700">I am bold</span>
 *      </>
 *    )
 *  })
 */
import React from 'react';
import toast from 'react-hot-toast';
import switchCaseHandler from '../../utils/common/switch';
import { SystemOkIcon, SystemErrorIcon, CancelStatusIcon } from '../../assets/icons/assets';
import './style.scss';

const internals = {
  getDynamicClassNames: (type) => {
    return switchCaseHandler({
      success: () => 'green-500-br',
      error: () => 'red-500-br',
    })('')(type);
  },
  getIcon: (type) => {
    const iconWrapperStyles = 'mr-m square-xl df aic jcc br50 white-100 ';

    return switchCaseHandler({
      success() {
        return (
          <div className={`green-800-bg ${iconWrapperStyles}`}>
            <SystemOkIcon />
          </div>
        );
      },
      error() {
        return (
          <div className={`red-900-bg ${iconWrapperStyles}`}>
            <SystemErrorIcon />
          </div>
        );
      },
    })(null)(type);
  },
};

/**
 * @param type {string} oneOf ['success', 'error']
 * @param title {string | node}
 * @param message {string | node}
 */
const Toast = ({ type, title, message }, options) =>
  toast.custom(
    (t) => (
      <div
        className={`toast ${internals.getDynamicClassNames(type)} ${
          t.visible ? 'toast--animate-enter' : 'toast--animate-leave'
        } df w100 py-l pr-xl pl-m neutral-800 white-100-bg br-m border`}
        {...t.ariaProps}
      >
        {internals.getIcon(type)}
        <div className="pr-2xl f1">
          <h1 className="fs1 fw700 pb-base">{title}</h1>
          <p className="fs-4">{message}</p>
        </div>
        <div>
          <button className="toast__close-btn transparent-bg border-none" onClick={() => toast.dismiss(t.id)}>
            <CancelStatusIcon styleClass="db" width={16} height={16} />
          </button>
        </div>
      </div>
    ),
    {
      duration: 8000,
      ...options,
    },
  );

export default {
  success: (args, options) => Toast({ ...args, type: 'success' }, options),
  error: (args, options) => Toast({ ...args, type: 'error' }, options),
};
