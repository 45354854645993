import PropTypes from 'prop-types';
import PrettyBytes from 'pretty-bytes';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../../../../components/Button';
import Spinner from '../../../../../components/Spinner';
import ScrollingFeedback from '../../../../../components/ScrollingFeedback';
import DocumentCard from './DocumentCard';
import utilsApi from '../../../../../utils/api/UtilsApi';
import { setOptionAction } from '../../../../../redux/actions/management/option/actions';
import { ACCEPTED_FILES, MAX_FILE_SIZE } from '../../../../../constants/orchards';
import './index.scss';

const { cancelToken, getOrchardInfo } = utilsApi;

const OrchardDetails = ({ orchardId }) => {
  const [orchardInfo, setOrchardInfo] = useState({});
  const scrollRef = useRef(undefined);
  const dispatch = useDispatch();
  const { t } = useTranslation('mgOrchardsDetails');

  useEffect(() => {
    setOrchardInfo({});
    //TODO: check if a erorr handler is necessary
    const CancelToken = cancelToken();
    getOrchardInfo(CancelToken.token, orchardId).then((res) => setOrchardInfo(res.data));

    return () => {
      CancelToken.cancel();
    };
  }, [orchardId]);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [orchardInfo]);

  const {
    address,
    city,
    name,
    state,
    certificate,
    corrective_action_report,
    pesticide_analysis,
    audit_report,
    field_questionnaire,
    microbiological_analysis,
    sketch_map,
  } = orchardInfo;

  return (
    <div className="h100 br-m df fdc">
      <div className="primary-100-bg br-inherit">
        <div className="orchard-details__header-top p-l df">
          <div className="f1">
            <p className="neutral-800 fw400">{t('mgOrchardsDetails:certificates')}</p>
            <h4 className="orchard-details__header-top-title neutral-900 fw600">{name ?? '—'}</h4>
          </div>
          <div className="gt-xs-hide ml-base">
            <Button
              onClick={() => {
                dispatch(setOptionAction({ type: null, option: null }));
              }}
              shape="complementary"
              size="small"
              type="primary"
            >
              {t('mpFlowButtons:close')}
            </Button>
          </div>
        </div>
        <div className="orchard-details__header-sub primary-50-bg">
          <p className="fs-4 fw700 neutral-700 comma-items">
            {address || city || state ? (
              <>
                <span>{address}</span>
                <span>{city}</span>
                <span>{state}</span>
              </>
            ) : (
              '—'
            )}
          </p>
        </div>
      </div>
      <div className="orchard-details__content f1 df fdc br-m white-100-bg">
        {Object.keys(orchardInfo).length ? (
          <ScrollingFeedback ref={scrollRef} className="scroll-y inherit-bg br-inherit">
            <div className="orchard-details__content-inner px-xl">
              <h5 className="fs1 pb-m fw-700 neutral-900">{t('title')}</h5>
              <p className="orchard-details__content-intro-text fs-2 pb-m neutral-700">{t('introText')}</p>
              <p className="fs-6 neutral-900 pb-base">
                {t('maxFileSizeText')} <b>{PrettyBytes(MAX_FILE_SIZE)}</b>
              </p>
              <p className="fs-6 neutral-900 pb-l">
                {t('supportedFormats')} <b>{ACCEPTED_FILES.join(', ').toUpperCase()}</b>
              </p>
              <DocumentCard
                className="mb-l"
                data={certificate}
                title={t('certificate')}
                type="certificate"
                orchardId={orchardId}
              />
              <DocumentCard
                className="mb-l"
                data={corrective_action_report}
                title={t('corrective_action_report')}
                type="corrective_action_report"
                orchardId={orchardId}
              />
              <DocumentCard
                className="mb-l"
                data={pesticide_analysis}
                title={t('pesticide_analysis')}
                type="pesticide_analysis"
                orchardId={orchardId}
              />
              <DocumentCard
                className="mb-l"
                data={audit_report}
                title={t('audit_report')}
                type="audit_report"
                orchardId={orchardId}
              />
              <DocumentCard
                className="mb-l"
                data={field_questionnaire}
                title={t('field_questionnaire')}
                type="field_questionnaire"
                orchardId={orchardId}
              />
              <DocumentCard
                className="mb-l"
                data={microbiological_analysis}
                title={t('microbiological_analysis')}
                type="microbiological_analysis"
                orchardId={orchardId}
              />
              <DocumentCard data={sketch_map} title={t('sketch_map')} type="sketch_map" orchardId={orchardId} />
            </div>
          </ScrollingFeedback>
        ) : (
          <div className="orchard-details__spinner df fdc aic">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

OrchardDetails.propTypes = {
  orchardId: PropTypes.number,
};

export default OrchardDetails;
