const marketHeader = {
  marketHeaderContainer: 'market-header-container',
  datePickerButton: 'date-picker-button',
  weekDescription: 'week-description',
  marketDifferentContainer: 'market-different-container',
  weekDifferentDescription: 'week-different-description',
  resetDateButton: 'reset-date-button',
};

module.exports = marketHeader;
