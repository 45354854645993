import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProductIcon from '../../../../utils/product/ProductIcons';
import mockProductStorage from '../../../../utils/mock-data/mockProductStorage';
import './ProductStorage.scss';

const ProductStorage = () => {
  const [productStorage, setProductStorage] = useState();
  const activeProduct = useSelector((state) => state.products.active);

  // i18n
  const { t } = useTranslation('fruits');

  useEffect(() => {
    //TODO: make the endpoint call here
    if (activeProduct) {
      setProductStorage(mockProductStorage[activeProduct]);
    }
  }, [activeProduct]);

  const renderProductStorage = () => {
    if (!productStorage) {
      return <div className="product-storage--loading df aic">Loading...</div>;
    }

    return (
      <div className="product-storage-header">
        {ProductIcon(activeProduct, 'product-storage-icon')}
        <h2>{t(productStorage.name)}</h2>
      </div>
    );
  };

  return <div>{renderProductStorage()}</div>;
};

export default ProductStorage;
