import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import CommodityToggler from '../CommodityToggler';
import ChangeLanguage from '../../../components/ChangeLanguage';
import LoginButton from '../../../components/LoginButton';
import { cleanProductAction } from '../../../redux/actions/products/actions';
import WebStorage from '../../../utils/WebStorage/WebStorage';
import navbarSelector from '../../../utils/page_definition/navbar';
import { ROUTES } from '../../../constants/routes';
import {
  NavbarIntelligence,
  NavbarIntelligenceInactive,
  NavbarMarketplace,
  NavbarMarketplaceInactive,
} from '../../../assets/icons/assets';
import './Navbar.scss';

const { navbarContainer, navbarButtonWrapper, navbarButton, navbarButtonIcon } = navbarSelector;

const internals = {
  renderIntelligenceIcon(isActive = false) {
    const Icon = isActive ? NavbarIntelligence : NavbarIntelligenceInactive;
    return (
      <Icon dataTest={`${navbarButtonIcon}-market`} width={32} height={32} className="navbar-button__content-icon" />
    );
  },
  renderMarketplaceIcon(isActive = false) {
    const Icon = isActive ? NavbarMarketplace : NavbarMarketplaceInactive;
    return <Icon dataTest={`${navbarButtonIcon}-bid`} width={32} height={32} className="navbar-button__content-icon" />;
  },
};

const Navbar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const accessToken = WebStorage.getCookieValue('access_token');

  const isActive = (path) => path === pathname;

  const activeNavHandler = (path) => {
    if (isActive(path)) return false;

    dispatch(cleanProductAction());
  };

  return (
    <div data-test={navbarContainer} className="navbar-container">
      <div className="navbar__buttons">
        <div data-test={`${navbarButtonWrapper}-market`} className="navbar-button-wrapper">
          <NavLink
            data-test={`${navbarButton}-market`}
            onClick={() => activeNavHandler(ROUTES.INTELLIGENCE)}
            activeClassName="navbar-active"
            className="navbar-button"
            exact
            to={ROUTES.INTELLIGENCE}
          >
            <span className="navbar-button__content-wrapper">
              <span className="navbar-button__content">
                {internals.renderIntelligenceIcon(isActive(ROUTES.INTELLIGENCE))}
              </span>
            </span>
          </NavLink>
        </div>
        <div data-test={`${navbarButtonWrapper}-bid`} className="navbar-button-wrapper">
          <NavLink
            data-test={`${navbarButton}-bid`}
            onClick={() => activeNavHandler(ROUTES.MARKETPLACE)}
            activeClassName="navbar-active"
            className="navbar-button"
            to={accessToken ? ROUTES.MARKETPLACE : ROUTES.LOGIN_MARKETPLACE}
          >
            <span className="navbar-button__content-wrapper">
              <span className="navbar-button__content">
                {internals.renderMarketplaceIcon(isActive(ROUTES.MARKETPLACE) || isActive(ROUTES.LOGIN_MARKETPLACE))}
              </span>
            </span>
          </NavLink>
        </div>
      </div>
      <div className="navbar-actions-wrapper">
        <CommodityToggler />
        <ChangeLanguage />
        <LoginButton />
      </div>
    </div>
  );
};

const NavbarWithRouter = withRouter(Navbar);
export default NavbarWithRouter;
