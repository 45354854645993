import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoData from '../../../../../components/NoData';
import getVolumeOptions from '../../../../../utils/Highcharts/VolumeOptions';
import generateDateStructure from '../../../../../utils/Highcharts/dataHandlers/weeklyGraph';
import isEmptyObject from '../../../../../utils/common/isEmptyObject';
import utilsApi from '../../../../../utils/api/UtilsApi';
import { VolumeSerieWeeklyFilter } from '../../../../../utils/Highcharts/VolumeSerie';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentDateAction } from '../../../../../redux/actions/currentDate/actions';
import { getWeek } from '../../../../../utils/common/DateHandler';
import VolumeSelectCascaderContainer from '../../../../../components/VolumeSelectCascaderContainer';
import { useTranslation } from 'react-i18next';
import './WeeklyLocations.scss';

const WeeklyLocations = () => {
  const { t } = useTranslation('volume');
  const [error, setError] = useState();
  const [highChartsOptions, setHighChartsOptions] = useState();
  const [originBundle, setOriginBundle] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);

  const dispatch = useDispatch();

  const currentDate = useSelector((state) => state.currentDate.currentDate);
  const selectedDate = useSelector((state) => state.selectedDate.date);
  const productDetail = useSelector((state) => state.products[state.products.active]);

  useEffect(() => {
    if (!currentDate) dispatch(getCurrentDateAction());
  }, [currentDate, getCurrentDateAction]);

  useEffect(() => {
    const updateGraph = () => {
      // only when the currentCommodity hook exists
      const { data } = originBundle;
      // get the Highchart options.
      const cloneHCH = { ...getVolumeOptions(t('weeklyXAxis')) };
      // Tooltip header
      const ToolTipHeaderFormat = `<span style="font-size: 15px">${t('week')} {point.key}</span><br/><br/>`;
      // array to parse the series array with the differents imports types: {Current, Future, Last}
      const hcSeries = [];
      // Parse the Serie for the Current Season data.
      // Right now the data from this service is broken.
      let week = currentDate.week;
      if (selectedDate) week = getWeek(selectedDate);

      if (isEmptyObject(data)) {
        // const completeWeekStructure = generateDateStructure(data[0].informationRetrieved, [], week);
        const completeWeekStructure = data.map((volumeData) =>
          generateDateStructure(volumeData.informationRetrieved, [], week),
        );

        const originParsedData = completeWeekStructure.map((serieData, index) => {
          return VolumeSerieWeeklyFilter(data[index].filterOptions, serieData.current);
        });

        hcSeries.push(...originParsedData);
      }

      cloneHCH.tooltip.headerFormat = ToolTipHeaderFormat;
      cloneHCH.series = hcSeries;
      // Set the plotLine {CURRENT WEEK}
      setHighChartsOptions(cloneHCH);
    };
    if (originBundle && currentDate) updateGraph();
  }, [originBundle, t('weeklyXAxis')]);

  useEffect(() => {
    const CancelToken = utilsApi.cancelToken();
    const getOriginsInformation = async () => {
      const { abbr } = productDetail;
      const allOriginsCalls = await Promise.all(
        selectedFilters.map(({ location, productType }) =>
          utilsApi.productVolume(abbr, location, productType, 'weekly', selectedDate, CancelToken.token),
        ),
      );

      const originInformation = allOriginsCalls.map((element, index) => ({
        informationRetrieved: element.data.current,
        filterOptions: {
          prdType: selectedFilters[index].productType,
          location: selectedFilters[index].location,
        },
      }));

      if (originInformation)
        setOriginBundle({
          data: originInformation,
        });
      else {
        setOriginBundle();
        setError({ error: true });
      }
      setSelectedFilters([]);
    };

    if (selectedFilters.length) getOriginsInformation();

    return function () {
      CancelToken.cancel();
    };
  }, [selectedFilters, selectedDate]);

  const renderHighCharts = () =>
    originBundle && highChartsOptions ? (
      <div className="wkly-lcs-graph-inner-container">
        <p className="wkly-lcs-graph-highcharts-ylabel">{t('pkgUnit')}</p>
        <HighchartsReact updateArgs={[true, true, true]} highcharts={Highcharts} options={highChartsOptions} />
      </div>
    ) : (
      <NoData error={error} />
    );

  // Parse data according to filter selection
  const reportFilters = (data) => {
    setSelectedFilters(data);
  };

  const renderSelectCascader = () => {
    if (productDetail)
      return (
        <VolumeSelectCascaderContainer
          getResults={reportFilters}
          id="locations-select-cascader"
          origins={productDetail['origins_weekly']}
        />
      );
    return '';
  };

  return (
    <div className="weekly-locations-graph-container">
      <div className="wkly-lcs-header">
        <div className="wkly-lcs-filter-container">{renderSelectCascader()}</div>
      </div>
      {renderHighCharts()}
    </div>
  );
};

export default WeeklyLocations;
