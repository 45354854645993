import React from 'react';

/*eslint-disable*/
const User = ({ styleClass, dataTest }) => (
  <svg className={styleClass} viewBox="0 0 24 24" data-test={dataTest}>
    <g id="Symbols-user-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-system-user-icon" fillRule="nonzero">
        <g id="Icn/Producer-user-icon">
          <rect id="ViewBox-user-icon" x="0" y="0" width="24" height="24"></rect>
          <g id="user-alt-user-icon" transform="translate(2.121212, 0.000000)" fill="#ffffff">
            <path
              d="M9.87878788,12.2505348 C14.8342535,12.2505348 18.8787879,16.3008163 18.8787879,21.2633233 L18.8787879,21.2633233 L18.8787879,22.357994 C18.8787879,23.2702196 18.1500429,24 17.2391118,24 L17.2391118,24 L2.51846399,24 C1.60753282,24 0.878787879,23.2702196 0.878787879,22.357994 L0.878787879,22.357994 L0.878787879,21.2633233 C0.878787879,16.3008163 4.92332229,12.2505348 9.87878788,12.2505348 Z M9.87878788,14.111475 C5.90712796,14.111475 2.70065023,17.3225089 2.70065023,21.2998124 L2.70065023,21.2998124 L2.70065023,22.2120379 L17.0569255,22.2120379 L17.0569255,21.2998124 C17.0569255,17.3225089 13.8504478,14.111475 9.87878788,14.111475 Z M10.5704229,0.184958875 C13.3800776,0.184958875 15.678886,2.55337993 15.678886,5.43192244 C15.678886,8.31046495 13.4165666,10.678886 10.6069119,10.678886 L9.92350424,10.678886 L9.92350424,10.678886 C7.11384953,10.678886 4.81504113,8.31046495 4.81504113,5.43192244 C4.81504113,2.55337993 7.07736051,0.184958875 9.88701522,0.184958875 L10.5704229,0.184958875 L10.5704229,0.184958875 Z M10.6782892,2.00760355 L9.70582695,2.01187925 C7.96688178,2.10925037 6.60300322,3.60034349 6.60300322,5.43192244 C6.60300322,7.30167374 8.024324,8.81659903 9.815032,8.85622797 L10.7881002,8.85196562 C12.5270453,8.7545945 13.8909239,7.26350138 13.8909239,5.43192244 C13.8909239,3.56196024 12.4692825,2.0469041 10.6782892,2.00760355 Z"
              id="Combined-Shape-user-icon"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default User;
