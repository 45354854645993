import { SET_SELECTED_PROGRAM, GET_PROGRAMS, GET_PROGRAM_DETAILS, CLEAN_PROGRAMS } from './types';
import utilsApi from '../../../utils/api/UtilsApi';
const { getPrograms, getProgramDetails } = utilsApi;

export const setSelectedProgramAction = (selectedProgram) => ({ type: SET_SELECTED_PROGRAM, selectedProgram });

/* @param {boolean} isCommited to set is_committed to true or false, default is false.
/* @param {boolean} isFinished to set is_finished to true or false, default is false.
 * @param {function} loaderCallback callback to hide loader when is fullfilled the promise;
 */
export const getProgramsAction = (isCommited = false, isFinished = false, loaderCallback = () => {}, commodity) => (
  dispatch,
) => {
  getPrograms(isCommited, isFinished, commodity).then((res) => {
    loaderCallback();
    dispatch({
      type: GET_PROGRAMS,
      payload: res.data,
    });
  });
  //TODO: Error Handler Required
};

/* @param {boolean} queryParam to set is_committed to true or false, default is false.
 * TODO: delete the id === 4 harcoded validation and use queryParam
 */
export const getProgramDetailsAction = (queryParam, loaderCallback = () => {}) => (dispatch, getState) => {
  loaderCallback();
  getProgramDetails(getState().programs.id, queryParam).then((res) => {
    loaderCallback();
    dispatch({ type: GET_PROGRAM_DETAILS, payload: res.data });
  });
  //TODO: Error Handler Required
};

export const cleanProgramsAction = () => ({ type: CLEAN_PROGRAMS });
