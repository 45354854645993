import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FilterSelect from './FilterSelect';
import { setShippingPointAction } from '../redux/actions/shippingPoint/actions';
import crossingsSelect from '../utils/Select/crossingsSelect';
import packageTypeSelect from '../utils/Select/packageTypeSelect';
import selectBoxSelector from '../utils/page_definition/select_menu';
import { productTypeSelect } from '../utils/Select/productTypeSelect';
import './SelectCascader.scss';

const SelectCascader = ({ actions = true, id, productDetail, setOptionsSelected, styleClass = '' }) => {
  const [locations, setLocations] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [producTypes, setProductTypes] = useState([]);
  const [productDetailState, setProductDetailState] = useState();
  const [locationFiltered, setLocationFiltered] = useState({});

  const dispatch = useDispatch();

  // i18n
  const { t } = useTranslation('selectCascader');

  //SELECTED STATES
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedPackageType, setSelectedPackageType] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');

  // main useEffect to set and clean the productDetailState
  useEffect(() => {
    if (productDetail) setProductDetailState(productDetail);

    return function cleanup() {
      // clean the main state hook when the component is unmounted
      setProductDetailState();
      setSelectedLocation('');
    };
  }, [productDetail]);

  // Fill the selects data and get the default values
  useEffect(() => {
    if (productDetailState) {
      const locationsParsed = crossingsSelect(productDetailState.crossings);

      // data selected default
      const defaultLocation = locationsParsed.length > 0 ? locationsParsed[0].value : 0;

      // set Select default Data
      setLocations(locationsParsed);

      // set default data selected
      setSelectedLocation(defaultLocation);
      // We need to set default location to show information in MARKET BEHAVIOR table
      if (defaultLocation) dispatch(setShippingPointAction(locationsParsed[0]));
    }
  }, [productDetailState]);

  // fill the selects cascade if the locations changes
  useEffect(() => {
    if (selectedLocation) {
      //Crossing data filtered by selectedLocation
      const currentLocation = productDetailState.crossings.filter((crossing) => crossing.slug === selectedLocation)[0];

      //Select data
      const productTypesFiltered = productTypeSelect(currentLocation);
      const packageTypesFiltered = packageTypeSelect(currentLocation[productTypesFiltered[0].value]);

      //data selected
      const productTypeSelected = productTypesFiltered[0].value || '';
      const packageTypeSelected = packageTypesFiltered[0].value || '';

      //set Select data
      setProductTypes(productTypesFiltered);
      setPackageTypes(packageTypesFiltered);

      setLocationFiltered(currentLocation);
      // CB from father component to execute API calls from there
      setOptionsSelected({
        id,
        location: selectedLocation,
        productType: productTypeSelected,
        packageType: packageTypeSelected,
      });
    }
  }, [selectedLocation]);

  // fill the package select if the product type changes
  useEffect(() => {
    if (selectedProductType) {
      // package filtered by selectedProductType
      const packageTypesFiltered = packageTypeSelect(locationFiltered[selectedProductType]);
      //data selected
      const packageTypeSelected = packageTypesFiltered[0].value;

      // fill the packageType select and set the first value as selected
      setPackageTypes(packageTypesFiltered);
      // setSelectedPackageType(packageTypeSelected);

      // CB from father component to execute API calls from there
      setOptionsSelected({
        id,
        location: selectedLocation,
        productType: selectedProductType,
        packageType: packageTypeSelected,
      });
      setSelectedProductType('');
    }
  }, [selectedProductType]);

  useEffect(() => {
    if (selectedPackageType) {
      // package filtered by selectedProductType
      const productTypesFiltered = productTypeSelect(locationFiltered);
      //data selected
      const productTypeSelected = productTypesFiltered[0].value;

      // CB from father component to execute API calls from there
      setOptionsSelected({
        id,
        location: selectedLocation,
        productType: productTypeSelected,
        packageType: selectedPackageType,
      });
      setSelectedPackageType('');
    }
  }, [selectedPackageType]);

  return (
    <div className={`select-cascader ${styleClass}`}>
      <div className="select-row" data-test={selectBoxSelector.selectLocationContainer}>
        <p className="select-label-title">{t('location')}</p>
        <FilterSelect
          actionToExecute={actions ? 'shippingPoint' : undefined}
          data={locations}
          setSelectedData={setSelectedLocation}
          id={`sp-crossing-${id}`}
        />
      </div>
      <div className="select-row" data-test={selectBoxSelector.selectTypeContainer}>
        <p className="select-label-title">{t('type')}</p>
        <FilterSelect
          actionToExecute="productType"
          data={producTypes}
          setSelectedData={setSelectedProductType}
          id={`sp-product-${id}`}
        />
      </div>
      <div className="select-row" data-test={selectBoxSelector.selectLabelContainer}>
        <p className="select-label-title">{t('packageType')}</p>
        <FilterSelect
          actionToExecute="packageType"
          data={packageTypes}
          setSelectedData={setSelectedPackageType}
          id={`sp-package-${id}`}
        />
      </div>
    </div>
  );
};

SelectCascader.propTypes = {
  actions: PropTypes.bool,
  id: PropTypes.number,
  productDetail: PropTypes.object,
  setOptionsSelected: PropTypes.func.isRequired,
  styleClass: PropTypes.string,
};

export default SelectCascader;
