import React from 'react';

/* eslint-disable */
const MarketIcon = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 16 16" version="1.1">
    <title>Market</title>
    <defs>
      <path
        d="M1.58970359,2 C1.93915757,2 2.1575663,2.2234375 2.17940718,2.5484375 L2.17940718,2.5484375 L2.17940718,13.821875 C2.17940718,13.8625 2.22308892,13.9234375 2.28861154,13.9234375 L2.28861154,13.9234375 L14.3884555,13.9234375 C14.7379095,13.9234375 14.9781591,14.1265625 15,14.4515625 C15,14.7765625 14.7597504,15 14.4102964,15 L14.4102964,15 L2.28861154,15 C1.58970359,15 1,14.4515625 1,13.821875 L1,13.821875 L1,2.5484375 C1,2.2234375 1.24024961,2 1.58970359,2 Z M14.17603,7 C14.6086142,7 15,7.35447761 15,7.76492537 L15,7.76492537 L15,10.0037313 C15,10.3022388 14.7734082,10.5074627 14.4438202,10.5074627 C14.1142322,10.5074627 13.8876404,10.3022388 13.8876404,10.0037313 L13.8876404,10.0037313 L13.8876404,8.71641791 L12.3014981,9.91044776 C12.2397004,9.94776119 12.0749064,10.0037313 11.9719101,10.0037313 L11.9719101,10.0037313 L9.23220974,10.0037313 L7.68726592,11.7947761 C7.58426966,11.9440299 7.41947566,12 7.25468165,12 L7.25468165,12 L4.55617978,12 C4.22659176,12 4,11.7947761 4,11.4962687 C4,11.1977612 4.22659176,10.9925373 4.55617978,10.9925373 L4.55617978,10.9925373 L6.98689139,10.9925373 L8.53183521,9.20149254 C8.63483146,9.05223881 8.79962547,8.99626866 8.96441948,8.99626866 L8.96441948,8.99626866 L11.7659176,8.99626866 L13.0842697,8.00746269 L11.7041199,8.00746269 C11.3745318,8.00746269 11.1479401,7.80223881 11.1479401,7.50373134 C11.1479401,7.20522388 11.3745318,7 11.7041199,7 L11.7041199,7 Z"
        id="market-icon"
      ></path>
    </defs>
    <g id="Symbols-market-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-system-market">
        <g id="Icn/Package-market">
          <rect id="Icon-viewport-market" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="16" height="16"></rect>
          <mask id="mask-2-market-icon" fill="white">
            <use xlinkHref="#market-icon"></use>
          </mask>
          <g id="Combined-Shape-marketicon" fillRule="nonzero"></g>
          <g id="market-color-id" mask="url(#mask-2-market-icon)" fill="#141414">
            <g transform="translate(-2.000000, -2.000000)" id="Neutral/900/market-icon">
              <path d="M8,0 L20,0 L20,0 L20,20 L8,20 C3.581722,20 5.41083001e-16,16.418278 0,12 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MarketIcon;
