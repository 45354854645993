import React from 'react';
import PropTypes from 'prop-types';
import { Usa, Mexico } from './assets';

const Flags = ({ flag, styleClass }) => {
  const flagDictionary = {
    1: <Mexico styleClass={styleClass} />,
    2: <Usa styleClass={styleClass} />,
  };

  return flagDictionary[flag];
};

Flags.propTypes = {
  flag: PropTypes.number.isRequired,
};

export default Flags;
