import React from 'react';

/*eslint-disable*/
const AddSelect = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 16 16" version="1.1">
    <title>Add</title>
    <g id="add-symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-add" fill="#141414" fillRule="nonzero">
        <g id="icon-add-plus">
          <path
            d="M14.9125,6.9875 L8.975,6.9875 L8.975,1.0125 C8.975,0.4125 8.5625,0 7.9625,0 C7.3625,0 6.95,0.4125 6.95,1.0125 L6.95,7.025 L1.0125,7.025 C0.4125,6.9875 0,7.4 0,8 C0,8.6 0.4125,9.0125 1.0125,9.0125 L6.9875,9.0125 L6.9875,15.025 C6.9875,15.625 7.4,16.0375 8,16.0375 C8.6,16.0375 9.0125,15.625 9.0125,15.025 L9.0125,8.975 L14.9875,8.975 C15.5875,8.975 16,8.5625 16,7.9625 C15.925,7.4 15.5125,6.9875 14.9125,6.9875 Z"
            id="icon-add-path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default AddSelect;
