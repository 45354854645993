/**
 * https://stackoverflow.com/a/59154364
 */
import React, { useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children, className = 'root-portal', element = 'div', appendTo = document.body }) => {
  const [container] = useState(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  });

  React.useEffect(() => {
    appendTo.appendChild(container);
    return () => {
      appendTo.removeChild(container);
    };
  }, [container, appendTo]);

  return createPortal(children, container);
};

export default Portal;
