const commodityTogglerSelector = {
  anotherComponent: 'another-component',
  commodityTogglerContainer: 'commodity-toggler-container',
  commodityTogglerControlButton: 'commodity-toggler-control-button',
  commodityTogglerControlButtonSelected: 'commodity-toggler-control-button-selected',
  commodityTogglerList: 'commodity-toggler-list',
  commodityTogglerListButton: 'commodity-toggler-list-button',
};

module.exports = commodityTogglerSelector;
