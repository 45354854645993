import { string } from 'prop-types';
import React from 'react';

const SystemWarning = ({ dataTest, ...other }) => (
  <svg data-test={dataTest} width={24} height={24} viewBox="0 0 16 16" {...other}>
    <defs>
      <path
        d="M8 14.4A6.4 6.4 0 108 1.6a6.4 6.4 0 000 12.8zM8 16A8 8 0 118 0a8 8 0 010 16zm-.8-4.8h1.6v1.6H7.2v-1.6zm0-8h1.6v6.4H7.2V3.2z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#a" />
    </g>
  </svg>
);

SystemWarning.propTypes = {
  dataTest: string,
};

export default SystemWarning;
