import React from 'react';

/*eslint-disable*/
const FridaLogoTextWhite = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 60 23" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-22.800464, -32.000000)" fillRule="nonzero">
        <g transform="translate(23.000000, 32.000000)">
          <path
            d="M6.2487437,5.75537382 L6.2487437,6.18741794 L8.80046443,6.18741794 L8.80046443,9.38215103 L6.2487437,9.38215103 L6.2487437,18.6288029 C6.2487437,21.5107476 4.685716,23 1.65051134,23 L0.800464429,23 L0.800464429,19.8052669 L1.32858372,19.8052669 C2.34058194,19.8052669 2.89240143,19.2527291 2.89240143,18.2442135 L2.89240143,9.38215103 L1.12199704,9.38215103 L1.12199704,6.18741794 L2.89240143,6.18741794 L2.89240143,5.37078441 C2.89240143,2.48925235 4.45542913,1 7.49142379,1 L8.80046443,1 L8.80046443,4.19390779 L7.8117714,4.19390779 C6.80016818,4.19390779 6.2487437,4.74644559 6.2487437,5.75537382"
            fill="#FFFFFF"
          ></path>
          <polygon fill="#FFFFFF" points="24.8004644 18 28.8004644 18 28.8004644 6 24.8004644 6"></polygon>
          <path
            d="M23.8004644,2.48560184 C23.8004644,1.06162411 24.8774532,0 26.2871741,0 C27.7234756,0 28.8004644,1.06162411 28.8004644,2.48560184 C28.8004644,3.93885583 27.7234756,5 26.2871741,5 C24.8774532,5 23.8004644,3.93885583 23.8004644,2.48560184 L23.8004644,2.48560184 Z"
            fill="#E40D2B"
          ></path>
          <path
            d="M40.0571748,12.4345336 C40.0571748,10.6637717 38.6981012,9.33580583 36.8522282,9.33580583 C35.0310255,9.33580583 33.6723924,10.6637717 33.6723924,12.4345336 C33.6723924,14.204873 35.0310255,15.5328388 36.8522282,15.5328388 C38.6981012,15.5328388 40.0571748,14.204873 40.0571748,12.4345336 M43.8004644,18.7050843 L40.416217,18.7050843 L40.416217,16.8853105 C39.415745,18.1887705 37.9284735,19 36.1081519,19 C32.5190522,19 29.8004644,16.1721046 29.8004644,12.4345336 C29.8004644,8.69611755 32.5190522,5.86822215 36.1081519,5.86822215 C37.7240618,5.86822215 39.0826948,6.50791043 40.0571748,7.540538 L40.0571748,1 L43.8004644,1"
            fill="#FFFFFF"
          ></path>
          <path
            d="M54.1805329,12.5004183 C54.1805329,10.747426 52.9380223,9.43278636 51.2495133,9.43278636 C49.583969,9.43278636 48.3414584,10.747426 48.3414584,12.5004183 C48.3414584,14.2529923 49.583969,15.5676319 51.2495133,15.5676319 C52.9380223,15.5676319 54.1805329,14.2529923 54.1805329,12.5004183 L54.1805329,12.5004183 Z M58.8004644,15.2752574 L58.8004644,18.7080438 L57.8379619,18.7080438 C56.1502587,18.7080438 55.024586,18.099453 54.5088877,16.9065315 C53.5939261,18.1969112 52.2337719,19 50.5690333,19 C47.2866942,19 44.8004644,16.2004826 44.8004644,12.5004183 C44.8004644,8.79951737 47.2866942,6 50.5690333,6 C52.0468312,6 53.2893418,6.63326898 54.1805329,7.65553411 L54.1805329,6.29195624 L57.6038832,6.29195624 L57.6038832,14.2776705 C57.6038832,14.9105212 57.9556056,15.2752574 58.5651771,15.2752574 L58.8004644,15.2752574 Z"
            fill="#FFFFFF"
          ></path>
          <path
            d="M22.8004644,15.8066387 L22.8004644,19 L22.0453946,19 C18.9811269,19 17.4048028,17.2332975 17.4048028,14.289358 C17.4048028,13.3153849 17.5997246,12.2965243 18.0525243,11.2772403 L15.3546935,10.9147529 L13.9087207,19 L10.8004644,19 L12.4845404,10.0995798 C11.8791932,9.64647057 11.5127563,8.87660836 11.5127563,8.06101176 C11.5127563,6.83804033 12.3332036,6 13.5628656,6 C14.7287644,6 15.5488081,6.77028568 15.6573671,7.78956969 L22.4558201,8.60431936 L22.4558201,9.39747223 C21.2685324,11.1188638 20.6426033,12.6132773 20.6426033,13.8819831 C20.6426033,15.1273983 21.2253509,15.8066387 22.3262758,15.8066387 L22.8004644,15.8066387 Z"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default FridaLogoTextWhite;
