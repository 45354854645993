import isEmptyObject from '../common/isEmptyObject';

/**
 * @param {Object} data
 * @return {array}
 */
export const productTypeSelect = (data) => {
  const baseArray = [];
  if (data && isEmptyObject(data)) {
    const { conventional, organic } = data;
    if (conventional.length) baseArray.push({ id: 'conventional', label: 'Conventional', value: 'conventional' });
    if (organic.length) baseArray.push({ id: 'organic', label: 'Organic', value: 'organic' });
  }
  return baseArray;
};

export const volumeProductTypeSelect = (data) => {
  const baseArray = [];
  const { itemAll, itemConventional, itemOrganic } = {
    itemAll: { id: 'all', label: 'ALL', value: 'all' },
    itemConventional: { id: 'conventional', label: 'Conventional', value: 'conventional' },
    itemOrganic: { id: 'organic', label: 'Organic', value: 'organic' },
  };

  // Parse product type select when data exists
  if (data && isEmptyObject(data)) {
    const { have_conventional = undefined, have_organic = undefined } = data;
    if (have_conventional) baseArray.push(itemConventional);
    if (have_organic) baseArray.push(itemOrganic);
    if (baseArray.length > 1) baseArray.unshift(itemAll);
  }

  // if data is equal to entireMarket so add the hardcoded values
  if (data === 'entireMarket') baseArray.push(itemAll, itemConventional, itemOrganic);

  return baseArray;
};
