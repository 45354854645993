import axios from 'axios';
import WebStorage from '../WebStorage/WebStorage';

const apiUrl = process.env.REACT_APP_API_SERVICE;
const apiV1 = process.env.REACT_APP_API_VERSION;
const apiv2 = process.env.REACT_APP_API_VERSION_2;
const endpointBase_1 = `${apiUrl}/${apiV1}`;
const endpointBase_2 = `${apiUrl}/${apiv2}`;

axios.interceptors.request.use((req) => {
  const authToken = WebStorage.getCookieValue('access_token');
  if (authToken.length > 10 && !req.url.includes('jwt/create')) req.headers.Authorization = `Bearer ${authToken}`;
  return req;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config?.url || '';
    const originalParams = error.config?.params || {};
    const originalData = error.config?.data || {};
    const originalMethod = error.config?.method || 'get';
    const originalHeaders = error.config?.headers || {};
    const refreshToken = WebStorage.getCookieValue('refresh_token');
    const fromRefresh = new RegExp('(refresh|create)').test(originalRequest);
    if (refreshToken.length && error.response && error.response.status === 401 && !fromRefresh) {
      return utilsApi
        .refreshToken(refreshToken)
        .then((res) => {
          if (res && res.status === 200) {
            WebStorage.setCookie('access_token', res.data.access);
            return axios(originalRequest, {
              params: originalParams,
              data: originalData,
              method: originalMethod,
              headers: originalHeaders,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    } else if (fromRefresh) {
      WebStorage.saveAccessCookie({ access: '', refresh: '' });
    }
    return Promise.reject(error);
  },
);

const utilsApi = {
  /*
   * Generate Cancel token
   * @return {object} CancelToken object
   */
  cancelToken: () => axios.CancelToken.source(),

  /*
   * check if is canceled
   * @param {object} object to check
   * @return {function}
   */
  isCancel: (object) => axios.isCancel(object),
  /**
   * get information for weather table
   * @param {string} slug product
   * @param {string} temUnit temperature unit string
   * @param {Date} report_date date string to manage the report
   * @returns {object} res - result object
   */
  weatherInfo: (slug, tempUnit, report_date, CancelToken) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    if (tempUnit) options.params.units = tempUnit;
    if (report_date) options.params.report_date = report_date.toISOString();
    return axios.get(`${endpointBase_1}/commodity/${slug}/weather`, options);
  },

  /**
   * get forecast info
   * @param {string} locationSlug
   * @param {string} productSlug
   * @param {string} temUnit temperature unit string
   * @returns {promise}
   */
  // https://guacamole.vuelorama.com/api/v1/commodity/rasp/tala/weather-forecast
  forecastInfo: (locationSlug, productSlug, tempUnit, report_date, CancelToken) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    if (tempUnit) options.params.units = tempUnit;
    if (report_date) options.params.report_date = report_date.toISOString();
    return axios.get(`${endpointBase_1}/commodity/${productSlug}/${locationSlug}/weather-forecast`, options);
  },

  /**
   * Get the volum behavior of an specific commodity
   * @param {string} commodity Slug string
   * @param {date} Optional report date (false default)
   * @return {promise} resulr or error object
   */
  getVolumeBehavior: (productSlug, report_date = false, CancelToken) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    const endpoint = `${endpointBase_1}/commodity/${productSlug}/volume-behavior`;
    if (report_date) options.params.report_date = report_date.toISOString();
    return axios.get(endpoint, options);
  },

  /**
   * get prices from the commodity data for the Market Behavior
   * @param {string} commodity string to get the data.
   * @param {string} report_date report date (false default)
   * @return {object} result or error object
   */
  commodityPrices: (commodity, location, report_date = false, CancelToken) => {
    const options = { params: {} };
    const endpoint = `${endpointBase_1}/commodity/${commodity}/${location}/prices`;
    options.cancelToken = CancelToken;
    if (report_date) options.params.report_date = report_date.toISOString();
    return axios.get(endpoint, options);
  },

  /**
   * get retail prices from the commodity
   * @param {string} commodity string to get the data.
   * @param {string} isOrganic python boolean {True, False} to set if is organic the commodity.
   * @param {date} date for data retrieve
   * @return {promise} result or error object.
   */
  commodityRetailPrices: (commodity, isOrganic, report_date = false, CancelToken) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    const endpoint = `${endpointBase_1}/commodity/${commodity}/retail?organic=${isOrganic}`;
    if (report_date) options.params.report_date = report_date.toISOString();
    return axios.get(endpoint, options);
  },

  /**
   * get average prices from commodity
   * @param {string} commodity string to get the data.
   * @param {string} crossing  string to select crossing zone
   * @param {date} date for data retrieve
   * @param {string} productType True if is organic False if not [python boolean]
   * @return {promise} result or error object.
   */
  commodityHistoricalPrices: (
    commodity,
    crossing,
    report_date = false,
    productType = undefined,
    packageType = undefined,
    CancelToken,
  ) => {
    const options = { params: {} };
    const endpoint = `${endpointBase_1}/commodity/${commodity}/${crossing}/historical-prices`;
    options.cancelToken = CancelToken;
    if (productType) options.params.is_organic = productType === 'organic' ? 'True' : 'False';
    if (report_date) options.params.report_date = report_date.toISOString();
    if (packageType) options.params.package = packageType;
    return axios.get(endpoint, options);
  },

  /**
   * get product import (Weekly)
   * @param {string} productSlug slug string to get data.
   * @param {string} origin origin slug
   * @param {string} productType
   * @param {string} report_type Wether "weekly" or "daily" type
   * @param {date} report_date for data retrieve
   * @return {promise} result or error object
   */
  productVolume: (
    productSlug,
    origin = undefined,
    productType = undefined,
    report_type,
    report_date = false,
    CancelToken,
  ) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    const endpoint = `${endpointBase_1}/commodity/${productSlug}/movements`;
    if (report_type) options.params.report_type = report_type;
    if (origin !== 'entireMarket') options.params.location = origin;
    if (productType !== 'all') options.params.is_organic = productType === 'organic' ? 'true' : 'false';
    if (report_date) options.params.report_date = report_date.toISOString();
    return axios.get(endpoint, options);
  },

  /**
   * get the products list
   * @param {string} CancelToken
   * @return {promise} result or error object.
   */
  getProducts: (CancelToken) => {
    const options = {
      params: {
        module: 'intelligence',
      },
    };
    options.cancelToken = CancelToken;
    return axios.get(`${endpointBase_1}/commodity`, options);
  },

  /*
   * get the marketplace commodities
   * @param {string} CancelToken
   * @return {promise}
   */
  getMarketplaceCommodities: (CancelToken) => {
    const options = {
      params: {
        module: 'marketplace',
      },
    };
    options.cancelToken = CancelToken;
    return axios.get(`${endpointBase_1}/commodity`, options);
  },

  /**
   * Get Product detail from a given product abbr
   * @param {string} product abbre
   * @param {date} date for data retrieve
   * @return {promise} result or error object
   */
  getProductDetail: (product_abbr, report_date = false, tempUnit = 'imperial', CancelToken) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    const endpoint = `${endpointBase_1}/commodity/${product_abbr}`;
    if (tempUnit) options.params.units = tempUnit;
    if (report_date) options.params.report_date = report_date.toISOString();
    return axios.get(endpoint, options);
  },

  /**
   * get the current date, along with start and end day of the week
   * @return {promise}
   */
  getCurrentDate: () => axios.get(`${endpointBase_1}/utilities/current-date`),

  /*
   * get all crossing zones from a given commodity
   * @param {string} dommodity string
   * @return {promise}
   */
  getCrossingZones: (commodity) => axios.get(`${endpointBase_1}/commodity/${commodity}/crossings`),

  /*
   * get the top 6 production zone percentage
   * @param {string} commodity string
   * @param {date} report date
   * @return {promise}
   */
  getProductionZone: (commodity, report_date = false, CancelToken) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    const endpoint = `${endpointBase_1}/commodity/${commodity}/movements-origins`;
    if (report_date) options.params.report_date = report_date.toISOString();
    return axios.get(endpoint, options);
  },

  /*
   * get the product origins
   * @param {string} product
   * @return {promise}
   */
  getProductOrigins: (product) => axios.get(`${endpointBase_1}/commodity/${product}/origins`),

  /*
   * login
   * @param {string} email
   * @param {string} password
   * @return {promise}
   */
  login: (email, password) =>
    axios.post(`${apiUrl}/auth/jwt/create`, {
      email,
      password,
    }),

  refreshToken: (refresh) => axios.post(`${apiUrl}/auth/jwt/refresh/`, { refresh }),

  /**
   * send email to user with password reset link
   * @url https://djoser.readthedocs.io/en/latest/base_endpoints.html#reset-password
   * @param {string} email
   * @return {promise}
   */
  resetPassword: (email) => axios.post(`${apiUrl}/auth/users/reset_password/`, { email }),

  /**
   * finish reset password process
   * @url https://djoser.readthedocs.io/en/latest/base_endpoints.html#reset-password-confirmation
   * @param {string} uid
   * @param {string} token
   * @param {string} new_password
   * @param {string} re_new_password
   * @return {promise}
   */
  resetPasswordConfirm: (uid, token, new_password, re_new_password) =>
    axios.post(`${apiUrl}/auth/users/reset_password_confirm/`, { uid, token, new_password, re_new_password }),

  /*
   * Get the programs list
   * @param {boolean} commited
   * @param {boolean} finished
   * @param {string} commodity
   * @return {promise}
   */
  getPrograms: (commited = false, finished = false, commodity) => {
    const options = { params: {} };
    options.params['is_committed'] = commited;
    if (commodity) options.params['commodity'] = commodity;
    if (finished) options.params['status'] = 5;
    const endpoint = `${endpointBase_1}/marketplace/programs/`;
    return axios.get(endpoint, options);
  },

  /*
   * Get progam details
   */
  // /api/v1/marketplace/programs/{{program_id}}?is_committed=true
  getProgramDetails: (id, commited = false) => {
    const options = { params: {} };
    options.params['is_committed'] = commited;
    const endpoint = `${endpointBase_1}/marketplace/programs/${id}/`;
    return axios.get(endpoint, options);
  },

  /*
   * Get projection detail
   * @param {string} CancelToken
   * @param {boolean} programType
   * @param {number} programID
   * @param {number} week
   */
  getProjectionDetails: (CancelToken, commited, programID, week) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    options.params['is_committed'] = commited;
    options.params['program_id'] = programID;
    options.params['current_week'] = week;
    const endpoint = `${endpointBase_1}/marketplace/projections`;
    return axios.get(endpoint, options);
  },

  /*
   * check the commitment sugggestion
   * commitmentSuggestions
   * @param {string} CancelToken
   * @param {number} id required
   * @param {number} quantity required
   * @return {promise}
   */
  commitmentSuggestions: (CancelToken, id, quantity) => {
    const options = { params: {} };
    options.cancelToken = CancelToken;
    options.params.quantity = quantity;
    const endpoint = `${endpointBase_1}/marketplace/commiments/${id}/suggestions`;
    return axios.get(endpoint, options);
  },

  /*
   * create commiment
   * @param {number} boxes
   * @param {number} program id
   * @param {number} packaging_material
   * @param {number} destination
   * @return {promise}
   */
  createCommitment: (boxes, program, packaging_material, destination) =>
    axios.post(`${endpointBase_1}/marketplace/commiments/`, {
      boxes,
      program,
      packaging_material,
      destination,
    }),

  /*
   * get event details
   * @param {number} commitment_id
   * @return {promise}
   * */
  eventDetails: (commitment_id) => {
    const options = { params: {} };
    options.params.detail = commitment_id;
    const endpoint = `${endpointBase_1}/marketplace/events`;
    return axios.get(endpoint, options);
  },

  /*
   * @return {promise}
   */
  getProfileInfo: () => axios.get(`${apiUrl}/auth/users/me/`),

  /*
   * get trends for volume and price
   * @param {string} serieType
   * @param {string} weeklyDaily
   * @param {number} end_day
   * @param {number} end_week
   * @param {number} start_day
   * @param {number} start_week
   * @param {number} month
   * @param {number} year
   * @param {string} commodity
   * @param {string} is_organic
   * @param {string} serieType
   * @param {string} weeklyDaily
   * @param {string} origin
   * @return {promise}
   */
  getVolumeAndPrice: (
    serieType,
    weeklyDaily,
    amount_days,
    end_week,
    start_day,
    start_week,
    month,
    years,
    commodity,
    is_organic,
    crossing,
    origin,
    packageType,
  ) => {
    const options = {
      params: {
        years,
        serie_type: serieType,
      },
    };

    if (weeklyDaily === 'daily') {
      options.params.start_day = start_day;
      options.params.amount_days = amount_days;
      options.params.month = month;
    } else {
      options.params.start_week = start_week;
      options.params.end_week = end_week;
    }

    if (is_organic) options.params.is_organic = is_organic;
    if (origin) options.params.origin = origin;
    if (crossing && packageType) {
      options.params.crossing = crossing;
      options.params.package = packageType;
    }
    const endpoint = `${endpointBase_2}/commodity/${commodity}/trends/${weeklyDaily}`;
    return axios.get(endpoint, options);
  },

  /*
   * get years information
   * @param {string} commodity
   * @param {array} years array of active years
   * @return {promise}
   */
  getCommodityInformation: (commodity, years) => {
    const options = {
      params: {
        years: years.join(),
      },
    };
    const endpoint = `${endpointBase_2}/commodity/${commodity}/information`;
    return axios.get(endpoint, options);
  },

  /*
   * get orchard list
   * @param {string} CancelToken
   * @return { promise }
   */
  getOrchards: (CancelToken) => {
    const options = {};
    options.cancelToken = CancelToken;
    const endpoint = `${endpointBase_1}/marketplace/orchards/`;
    return axios.get(endpoint, options);
  },

  /*
   * get orchard info
   * @param {string} CancelToken
   * @param {number} OrchardId
   * @return { promise }
   */
  getOrchardInfo: (CancelToken, OrchardId) => {
    const options = {};
    options.cancelToken = CancelToken;
    const endpoint = `${endpointBase_1}/marketplace/orchards/${OrchardId}/`;
    return axios.get(endpoint, options);
  },

  /*
   * @param {string} filePath
   * @param {string} fileName
   * @param {object} fileData
   */
  uploadDocument: (filePath, fileData) => {
    const endpointBase = `${endpointBase_1}/documents/${filePath}`;

    return axios.post(endpointBase, fileData);
  },
};
export default utilsApi;
