import { GetDay } from '../common/DateHandler';
import stringHandlers from '../common/stringHandlers';

const lineFormat = (name, data, field) => {
  const baseArray = [];
  data.forEach((element) => {
    baseArray.push([GetDay(element.date), element[field]]);
  });
  return { name, data: baseArray };
};

/**
 * @param {string} name line name
 * @param {Array} dataArray graph data
 * @return graph parsed data
 */
const LocationsSerie = (name, dataArray) => {
  if (Array.isArray(dataArray)) {
    const { location, pckType, prdType } = name;
    const serieName = stringHandlers.slashCombiner([location, prdType, pckType], [12, 0, 14]);
    return lineFormat(serieName, dataArray, 'average_price');
  }

  return { name: '', data: [] };
};

export default LocationsSerie;
