import React from 'react';

/*eslint-disable*/
const RemoveSelect = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 16 16">
    <title>Remove</title>
    <g id="remove-symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-remove" fill="#141414" fillRule="nonzero">
        <path
          d="M12.9125,7 L3.0125,7.03703704 C2.4125,7 2,7.40740741 2,8 C2,8.59259259 2.4125,9 3.0125,9 L12.9875,8.96296296 C13.5875,8.96296296 14,8.55555556 14,7.96296296 C13.925,7.40740741 13.5125,7 12.9125,7 Z"
          id="Path-Remove-icon"
        ></path>
      </g>
    </g>
  </svg>
);

export default RemoveSelect;
