import React from 'react';
import { WarningIcon } from '../assets/icons/assets';
import { useTranslation } from 'react-i18next';
import './Error.scss';

const Error = () => {
  // i18n
  const { t } = useTranslation('error');

  return (
    <div className="error-container">
      <div className="error-header">
        <WarningIcon styleClass="error-header-icon" fillColor="#F02929" />
        <h3 className="error-header-title">{t('headerTitle')}</h3>
      </div>
      <div className="error-content">
        <p>
          {t('errorContent')}
          <a href="mailto:support@fridanet.com" target="_blank" rel="noopener noreferrer">
            {t('sendEmail')}
          </a>
          {t('supportTeam')}
        </p>
      </div>
    </div>
  );
};

export default Error;
