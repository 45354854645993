const toggleSwitchSelectors = {
  toggleContainer: 'toggle-switch-container',
  toggleFalseValue: 'toggle-switch-false-value',
  toggleInput: 'toggle-switch-input',
  toggleInputContainer: 'toggle-switch-input-container',
  toggleLabel: 'toggle-switch-label',
  toggleTrueValue: 'toggle-switch-true-value',
};

module.exports = toggleSwitchSelectors;
