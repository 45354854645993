import React from 'react';

/*eslint-disable*/
const StepperCircle = ({ styleClass, dataTest }) => (
  <svg data-test={dataTest} className={styleClass} viewBox="0 0 8 8" version="1.1">
    <g id="Symbols-circle-stepper" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-divider-circle-stepper" fill="#120DD1" fillRule="nonzero">
        <path
          d="M4.01219181,0 C6.07119514,0 7.7668419,1.55571449 7.98780819,3.55581959 L7.98854351,3.70716473 C7.9961353,3.80784604 8,3.9095676 8,4.01219181 C8,4.11276359 7.99628834,4.21246846 7.98899453,4.31117692 L7.98770945,4.44507328 C7.89384557,5.29295422 7.53496649,6.06093492 6.99575069,6.66433693 C6.94517344,6.71990786 6.89385525,6.77466309 6.84104892,6.82794091 L6.80381644,6.86501863 C6.18121944,7.47698583 5.35920434,7.88671878 4.44418041,7.98780819 L4.31113286,7.98899779 C4.21243879,7.99628945 4.11274862,8 4.01219181,8 C3.9095676,8 3.80784604,7.9961353 3.70716473,7.98854351 L3.55492672,7.98770945 C1.55525151,7.76633723 -1.33226763e-15,6.07088875 4.4408921e-16,4.01219181 C4.4408921e-16,2.94109411 0.420992118,1.96832439 1.10653313,1.25032587 L1.25032587,1.10653313 C1.96832439,0.420992118 2.94109411,0 4.01219181,0 Z M4,1 C2.34314575,1 1,2.34314575 1,4 C1,5.65685425 2.34314575,7 4,7 C5.65685425,7 7,5.65685425 7,4 C7,2.34314575 5.65685425,1 4,1 Z"
          id="Icn/Divider/Circle-stepper"
        ></path>
      </g>
    </g>
  </svg>
);

export default StepperCircle;
