import React from 'react';

/*eslint-disable*/
const SelectArrowDown = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 12 12" version="1.1">
    <title>Select arrow down</title>
    <defs>
      <path
        d="M1.29196901,3.29349718 C1.65174981,2.93270558 2.21833451,2.90449178 2.6104552,3.20918163 L2.70463006,3.29228978 L6.0003424,6.586 L9.29513299,3.29252666 C9.65540802,2.93222967 10.2220309,2.90479426 10.6137335,3.21002253 L10.7077944,3.29325997 C11.067696,3.65393081 11.0951013,4.22117617 10.790208,4.61330915 L10.7070619,4.70747334 L6.70730573,8.70747334 C6.34738026,9.06742077 5.78142098,9.09519935 5.3896953,8.79074529 L5.29561381,8.70771023 L1.29317509,4.70771023 C0.902746462,4.31751949 0.902206482,3.68435474 1.29196901,3.29349718 Z"
        id="path-select-arrow-down"
      ></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-select-arrow-down">
        <mask id="mask-select-arrow-down" fill="white">
          <use xlinkHref="#path-select-arrow-down"></use>
        </mask>
        <use id="Path" fill="#141414" fillRule="nonzero" xlinkHref="#path-select-arrow-down"></use>
      </g>
    </g>
  </svg>
);

export default SelectArrowDown;
