import React from 'react';
import { bool, node } from 'prop-types';
import Spinner from '../Spinner';
import './style.scss';

const ButtonLoading = ({ children, loading = false }) => {
  return (
    <button
      type="submit"
      className="button-loading pr fk-button button-secondary-main button-small"
      {...(loading && { 'data-loading': true })}
      disabled={loading}
    >
      <span className="button-loading__spinner pa w100 h100">
        <span className="df aic jcc h100">
          <Spinner className="spinner--l" />
        </span>
      </span>
      <span className="button-loading__text">{children}</span>
    </button>
  );
};

ButtonLoading.propTypes = {
  /**
   * The content of the component.
   */
  children: node,
  /**
   * If `true` the component is disabled and the spinner is show.
   * @default false
   */
  loading: bool,
};

export default ButtonLoading;
