import { string } from 'prop-types';
import React from 'react';

const SystemOkIcon = ({ dataTest, ...other }) => (
  <svg data-test={dataTest} width={16} heigh={16} viewBox="0 0 16 16" {...other}>
    <path d="M10.176 5.432l-3.432 3.44-1.32-1.32A.8.8 0 104.296 8.68l1.88 1.888a.8.8 0 001.128 0l4-4a.8.8 0 10-1.128-1.136zM8 0a8 8 0 100 16A8 8 0 008 0zm0 14.4A6.4 6.4 0 118 1.6a6.4 6.4 0 010 12.8z" />
  </svg>
);

SystemOkIcon.propTypes = {
  dataTest: string,
};

export default SystemOkIcon;
