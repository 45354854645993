import React from 'react';
import EmptyProgramList from './card/EmptyProgramList';
import PropTypes from 'prop-types';
import OpportunityCard from './card/OpportunityCard';

/*
 * @prop {bool} opportunityCommited false for available opportunities, true for my opportunities
 */
const OpportunityList = ({ opportunityCommited, opportunities }) => {
  const renderopportunities = () => {
    if (opportunities && opportunities.length)
      return opportunities.map((program) => {
        return <OpportunityCard data={program} key={program.id} opportunityCommited={opportunityCommited} />;
      });

    // empty state
    return <EmptyProgramList />;
  };

  return <div className="available-opportunities-container">{renderopportunities()}</div>;
};

OpportunityList.defaultProps = {
  opportunityCommited: false,
};

OpportunityList.propTypes = {
  getopportunitiesAction: PropTypes.func,
  opportunityCommited: PropTypes.bool,
  opportunities: PropTypes.array,
};

export default OpportunityList;
