import React from 'react';

/* eslint-disable*/
const ArrowBottom = ({ styleClass, fillColor, dataTest, customId }) => (
  <svg data-test={dataTest} className={styleClass} viewBox="0 0 24 24">
    <title>Arrow Bottom</title>
    <defs>
      <polygon id="path-arrow-bottom-table" points="1.77635684e-15 10 5 5 1.77635684e-15 0"></polygon>
    </defs>
    <g id="03-Icons-arrow-bottom-table" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-arrow-bottom-table">
        <g
          id="Icon-/-Arrow-bottom-table"
          transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
        >
          <rect
            id="ViewBox-arrow-bottom-table"
            fillRule="nonzero"
            x="-1.27412786e-14"
            y="0"
            width="24"
            height="24"
          ></rect>
          <g id="Icon-arrow-bottom-table" transform="translate(10.000000, 7.000000)">
            <mask id={`mask-2-arrow-bottom-table-${customId}`} fill="white">
              <use xlinkHref="#path-arrow-bottom-table"></use>
            </mask>
            <use
              id="Icon-Path-arrow-bottom-table"
              fill="#313233"
              fillRule="nonzero"
              xlinkHref="#path-arrow-bottom-table"
            ></use>
            <g id="fill-color-arrow-bottom-table" mask={`url(#mask-2-arrow-bottom-table-${customId})`} fill={fillColor}>
              <g transform="translate(-66.000000, -63.000000)">
                <path d="M8,0 L136,0 L136,0 L136,135 L8,135 C3.581722,135 5.41083001e-16,131.418278 0,127 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ArrowBottom.defaultProps = {
  customId: '',
};
export default ArrowBottom;
