/*
 * method to handler if value is a number
 * @param {number} value
 * @param {string} type
 */

const isNumber = {
  /*
   * manage the class to render if the value is number
   * @return {string}
   */
  typeRequired: (value, type) => (!isNaN(value) ? type : ''),
  /*
   * manage the decimal to render if the value is number and different to movement-imported
   * @return {number}
   */
  toFloat: (value, type) => (type !== 'movement-imported' && !isNaN(value) ? value.toFixed(2) : value),
};

export default isNumber;
