import React from 'react';
import PropTypes from 'prop-types';
import './options.scss';
import '../../../../styles/components/management/_optionsLists.scss';

const Options = ({ children }) => {
  return <div className="options__container">{children}</div>;
};

Options.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Options;
