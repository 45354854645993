import switchCaseHandler from '../../utils/common/switch';

const initialState = { confirmProgram: false };

const confirmProgram = (state = initialState, action) => {
  return switchCaseHandler({
    SET_CONFIRM_PROGRAM: () => ({ ...state, confirmProgram: action.confirmProgram }),
  })(state)(action.type);
};

export default confirmProgram;
