import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoData from '../../../../../components/NoData';
import VolumeOptions from '../../../../../utils/Highcharts/VolumeOptionsDaily';
import isEmptyObject from '../../../../../utils/common/isEmptyObject';
import utilsApi from '../../../../../utils/api/UtilsApi';
import { VolumeSerieDailyFilter } from '../../../../../utils/Highcharts/VolumeSerie';
import { useSelector, useDispatch } from 'react-redux';
import { generateDateStructureLocations } from '../../../../../utils/Highcharts/dataHandlers/dailyGraph';
import { getCurrentDateAction } from '../../../../../redux/actions/currentDate/actions';
import VolumeSelectCascaderContainer from '../../../../../components/VolumeSelectCascaderContainer';
import { useTranslation } from 'react-i18next';
import './DailyLocations.scss';

const DailyLocations = () => {
  const { t } = useTranslation('volume');
  const [error, setError] = useState();
  const [highChartsOptions, setHighChartsOptions] = useState();
  const [originBundle, setOriginBundle] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);

  const dispatch = useDispatch();

  const currentDate = useSelector((state) => state.currentDate.currentDate);
  const selectedDate = useSelector((state) => state.selectedDate.date);
  const activeProduct = useSelector((state) => state.products.active);
  const productDetail = useSelector((state) => state.products[activeProduct]);

  useEffect(() => {
    if (!currentDate) dispatch(getCurrentDateAction());
  }, [currentDate, getCurrentDateAction]);

  useEffect(() => {
    const updateGraph = () => {
      // only when the currentCommodity hook exists
      if (originBundle && currentDate) {
        const { data } = originBundle;
        // get the Highchart options.
        const cloneHCH = { ...VolumeOptions };
        // array to parse the series array with the differents imports types: {Current, Future, Last}
        const hcSeries = [];

        if (isEmptyObject(data)) {
          const isEmptyIndex = data.findIndex((element) => !element.informationRetrieved.length);
          const completeDateStructure = generateDateStructureLocations(data);
          const originParsedData = completeDateStructure.map((serieData, index) =>
            VolumeSerieDailyFilter(data[index].filterOptions, serieData, isEmptyIndex === index),
          );

          hcSeries.push(...originParsedData);
        }

        cloneHCH.series = hcSeries;
        // Set the plotLine {CURRENT WEEK}
        setHighChartsOptions(cloneHCH);
      }
    };
    updateGraph();
  }, [originBundle]);

  useEffect(() => {
    const CancelToken = utilsApi.cancelToken();
    const getOriginsInformation = async () => {
      const { abbr } = productDetail;

      const allOriginsCalls = await Promise.all(
        selectedFilters.map(({ location, productType }) =>
          utilsApi.productVolume(abbr, location, productType, 'daily', selectedDate, CancelToken.token),
        ),
      );

      const originInformation = allOriginsCalls.map((element, index) => ({
        informationRetrieved: element.data.current,
        filterOptions: {
          prdType: selectedFilters[index].productType,
          location: selectedFilters[index].location,
        },
      }));

      if (originInformation) {
        try {
          setOriginBundle({
            data: originInformation,
          });
        } catch (err) {
          setOriginBundle();
          setError({ error: true });
        }
      }
      setSelectedFilters([]);
    };
    if (selectedFilters.length) getOriginsInformation();

    return function () {
      CancelToken.cancel();
    };
  }, [selectedFilters, selectedDate]);

  const renderHighCharts = () =>
    originBundle && highChartsOptions ? (
      <div className="dly-lcs-graph-inner-container">
        <p className="dly-lcs-graph-highcharts-ylabel"> {t('pkgUnit')} </p>
        <HighchartsReact updateArgs={[true, true, true]} highcharts={Highcharts} options={highChartsOptions} />
      </div>
    ) : (
      <NoData error={error} />
    );

  // Parse data according to filter selection
  const reportFilters = (data) => {
    setSelectedFilters(data);
  };

  const renderSelectCascader = () => {
    if (productDetail)
      return (
        <VolumeSelectCascaderContainer
          getResults={reportFilters}
          id="locations-select-cascader"
          origins={productDetail['origins_daily']}
        />
      );
    return '';
  };

  return (
    <div className="daily-locations-graph-container">
      <div className="dly-lcs-header">
        <div className="dly-lcs-filter-container">{renderSelectCascader()}</div>
      </div>
      {renderHighCharts()}
    </div>
  );
};

export default DailyLocations;
