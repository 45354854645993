const SeriesColorsSet1 = ['#946d00', '#1f4ff1', '#fa6400', '#4cca16', '#000000', '#fe9dd8'];

const SeriesColorsSet2 = ['#2c9ac0', '#0c177d', '#619100', '#f08d08', '#75d547', '#ffbc5b'];

const SeriesColorsSet3 = ['#f02929', '#8e8e8e', '#4cca16', '#000000', '#d80000', '#7047ec'];

const SeriesColorsSet4 = ['#5727eb', '#000000', '#ffbc5b', '#ab0050', '#5febb9', '#fa6400'];

export const LocationsSerie = [...SeriesColorsSet4, ...SeriesColorsSet1, ...SeriesColorsSet3, ...SeriesColorsSet2];

export const WeeklySerie = [...SeriesColorsSet2, ...SeriesColorsSet4, ...SeriesColorsSet2, ...SeriesColorsSet3];

export const DailySerie = [...SeriesColorsSet1, ...SeriesColorsSet2, ...SeriesColorsSet3, ...SeriesColorsSet4];
