import React from 'react';

/* eslint-disable */
const WarningIcon = ({ dataTest, styleClass, fillColor }) => (
  <svg className={styleClass} data-test={dataTest} version="1.1" viewBox="0 0 24 24">
    <title>Warning Icon</title>
    <defs>
      <path
        d="M10,18 C14.418278,18 18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 Z M10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 Z M9,14 L11,14 L11,16 L9,16 L9,14 Z M9,4 L11,4 L11,12 L9,12 L9,4 Z"
        id="path-warning"
      ></path>
    </defs>
    <g id="03-Icons-warning" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-warning">
        <g id="Icn/Warning">
          <rect id="ViewBox-warning" fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
          <g id="Icon" transform="translate(2.000000, 2.000000)">
            <mask id="mask-warning" fill="white">
              <use xlinkHref="#path-warning"></use>
            </mask>
            <use id="Icon-Shape-warning" fill="#ffffff" fillRule="nonzero" xlinkHref="#path-warning"></use>
            <g id="Neutral-/-900" mask="url(#mask-warning)" fill={fillColor}>
              <path d="M8,0 L20,0 L20,0 L20,20 L8,20 C3.581722,20 5.41083001e-16,16.418278 0,12 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WarningIcon;
