import { string } from 'prop-types';
import React from 'react';

const Visibility = ({ dataTest, ...other }) => (
  <svg data-test={dataTest} width={24} heigh={24} viewBox="0 0 24 24" {...other}>
    <path d="M12 5.75c6.45 0 11.55 5.646 11.775 5.869.3.371.3.891 0 1.225-.225.26-5.325 5.906-11.775 5.906S.45 13.104.225 12.881a.946.946 0 010-1.225C.45 11.396 5.55 5.75 12 5.75zm0 1.857c-4.425 0-8.287 3.269-9.75 4.643 1.425 1.374 5.325 4.643 9.75 4.643 4.462 0 8.325-3.269 9.75-4.643-1.425-1.374-5.325-4.643-9.75-4.643zm0 1.143c1.67 0 3 1.33 3 3s-1.367 3-3 3-3-1.33-3-3 1.33-3 3-3zm0 1.899a1.101 1.101 0 100 2.202 1.101 1.101 0 000-2.202z" />
  </svg>
);

Visibility.propTypes = {
  dataTest: string,
};

export default Visibility;
