export const productSlug = {
  Asparagus: 'asp',
  Blackberries: 'blkberi-v',
  Blueberries: 'bluby',
  Celery: 'cel',
  Raspberries: 'rasp',
  Strawberries: 'strby',
};

export const productName = (value) => Object.keys(productSlug).find((key) => productSlug[key] === value);
