const marketEN = {
  '11LbCartonsHigherOthersAboutSteady': '11 LB CARTONS HIGHER, OTHERS ABOUT STEADY.',
  '11LbJumboLowerOthersAboutSteady': '11 LB JUMBO LOWER, OTHERS ABOUT STEADY.',
  '11LbSlightlyLowerOtherAboutSteady': '11 LB SLIGHTLY LOWER, OTHER ABOUT STEADY.',
  '11PoundCarton/cratesSlightlyLowerOthersAboutSteady': '11 POUND CARTON/CRATES SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '11PoundCartons/cratesBunchedSlightlyLowerOthersAboutSteady':
    '11 POUND CARTONS/CRATES BUNCHED SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '11PoundJumboAndSmallLower11PoundLargeAndStandardSteadyOthersHigher':
    '11 POUND JUMBO AND SMALL LOWER, 11 POUND LARGE AND STANDARD STEADY, OTHERS HIGHER.',
  '11PoundJumboSlightlyLowerOthersHigher': '11 POUND JUMBO SLIGHTLY LOWER, OTHERS HIGHER.',
  '11PoundSlightlyHigherOthersAboutSteady': '11 POUND SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '11PoundSlightlyHigherOthersSteady': '11 POUND SLIGHTLY HIGHER, OTHERS STEADY.',
  '11PoundSlightlyLowerOthersAboutSteady': '11 POUND SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '11PoundSmallAnd28PoundLargeSlightlyLowerOthersAboutSteady':
    '11 POUND SMALL AND 28 POUND LARGE SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '11PoundStandardAndLargeSteadyOthersLower': '11 POUND STANDARD AND LARGE STEADY, OTHERS LOWER.',
  '11PoundStandardAndLargeSteadyOthersSlightlyLower': '11 POUND STANDARD AND LARGE STEADY, OTHERS SLIGHTLY LOWER.',
  '121-pintBasketsSteadyOthersLower': '12 1-PINT BASKETS STEADY, OTHERS LOWER.',
  '121-pintCupsLowerOthersAboutSteady': '12 1-PINT CUPS LOWER, OTHERS ABOUT STEADY.',
  '121-pintCupsSlightlyLowerOthersAboutSteady': '12 1-PINT CUPS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '121-pintCupsWithLidsSlightlyHigherOthersAboutSteady':
    '12 1-PINT CUPS WITH LIDS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '121-pintsSlightlyHigherOthersAboutSteady': '12 1-PINTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '126-ounceCupsSlightlyHigherOthersAboutSteady': '12 6-OUNCE CUPS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '18-ounceContainersAboutSteadyOthersSlightlyHigher': '18-OUNCE CONTAINERS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '18-ounceContainersSlightlyLowerOthersAboutSteady': '18-OUNCE CONTAINERS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '18-ounceHigherOrganicSteadyOthersSlightlyHigher': '18-OUNCE HIGHER, ORGANIC STEADY, OTHERS SLIGHTLY HIGHER.',
  '20LbSlightlyHigherOthersAboutSteady': '20 LB SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '21/2-3DozenSlightlyHigherOthersAboutSteady': '2 1/2-3 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '21/2And3DozenAboutSteadyOthersSlightlyLower': '2 1/2 AND 3 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '21/2And3DozenLowerOthersSlightlyLower': '2 1/2 AND 3 DOZEN LOWER, OTHERS SLIGHTLY LOWER.',
  '21/2And3DozenSlightlyHigherOthersAboutSteady': '2 1/2 AND 3 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '21/2And3DozenSlightlyLowerOthersAboutSteady': '2 1/2 AND 3 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '21/2CartonsSlightlyLowerOthersAboutSteady': '2 1/2 CARTONS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '21/2DozenAboutSteadyOthersSlightlyHigher': '2 1/2 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '21/2DozenAboutSteadyOthersSlightlyLower': '2 1/2 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '21/2DozenAndHeartsAboutSteadyOthersSlightlyHigher': '2 1/2 DOZEN AND HEARTS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '21/2DozenAndHeartsSlightlyHigherOthersAboutSteady': '2 1/2 DOZEN AND HEARTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '21/2DozenLowerOthersAboutSteady': '2 1/2 DOZEN LOWER, OTHERS ABOUT STEADY.',
  '21/2DozenSlightlyHigherOthersAboutSteady': '2 1/2 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '21/2DozenSlightlyLowerOthersAboutSteady': '2 1/2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '21/2To3DozenAboutSteadyOthersSlightlyHigher': '2 1/2 TO 3 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '2-1/2To3DozenAboutSteadyOthersSlightlyHigher': '2-1/2 TO 3 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '2-21/2DozenAboutSteadyOthersSlightlyHigher': '2-2 1/2 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '2-21/2DozenAboutSteadyOthersSlightlyLower': '2-2 1/2 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '2-21/2DozenHigher3DozenSlightlyHigherHeartsAboutSteady':
    '2-2 1/2 DOZEN HIGHER, 3 DOZEN SLIGHTLY HIGHER, HEARTS ABOUT STEADY.',
  '2-21/2DozenHigherOthersAboutSteady': '2-2 1/2 DOZEN HIGHER, OTHERS ABOUT STEADY.',
  '2-21/2DozenHigherOthersSlightlyHigher': '2-2 1/2 DOZEN HIGHER, OTHERS SLIGHTLY HIGHER.',
  '2-21/2DozenLowerOthersAboutSteady': '2-2 1/2 DOZEN LOWER, OTHERS ABOUT STEADY.',
  '2-21/2DozenLowerOthersSlightlyLower': '2-2 1/2 DOZEN LOWER, OTHERS SLIGHTLY LOWER.',
  '2-21/2DozenSlightlyHigherOthersAboutSteady': '2-2 1/2 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '2-21/2DozenSlightlyLower3DozenLowerHeartsAboutSteady':
    '2-2 1/2 DOZEN SLIGHTLY LOWER, 3 DOZEN LOWER, HEARTS ABOUT STEADY.',
  '2-21/2DozenSlightlyLowerOthersAboutSteady': '2-2 1/2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '2-21/2DozenSlightlyLowerOthersLower': '2-2 1/2 DOZEN SLIGHTLY LOWER, OTHERS LOWER.',
  '2-3DozenAboutSteadyOthersSlightlyLower': '2-3 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '2-3DozenSlightlyHigherHeartsAboutSteady': '2-3 DOZEN SLIGHTLY HIGHER, HEARTS ABOUT STEADY.',
  '2-3DozenSlightlyHigherOthersAboutSteady': '2-3 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '2-3DozenSlightlyLowerOthersAboutSteady': '2-3 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '28LbCartonHigher11LbCartonSteady': '28 LB CARTON HIGHER, 11 LB CARTON STEADY.',
  '28LbCartonLower11LbCartonSteady': '28 LB CARTON LOWER, 11 LB CARTON STEADY.',
  '28LbCartonsSluightlyHigher11LbSlightlyLower': '28 LB CARTONS SLUIGHTLY HIGHER, 11 LB SLIGHTLY LOWER.',
  '28LbLargeSlightlyLowerOthersAboutSteadyFirmUndertoneForNextWeek':
    '28 LB LARGE SLIGHTLY LOWER, OTHERS ABOUT STEADY FIRM UNDERTONE FOR NEXT WEEK.',
  '28LbSlightlyHigherOthersAboutSteady': '28 LB SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '28LbSlightlyLowerOthersAboutSteady': '28 LB SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '28LbStandardAboutSteadyOthersSlightlyLower': '28 LB STANDARD ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '28PoundBunchedHigher11PoundAboutSteady': '28 POUND BUNCHED HIGHER, 11 POUND ABOUT STEADY.',
  '28PoundBunchedLowerOthersAboutSteady': '28 POUND BUNCHED LOWER, OTHERS ABOUT STEADY.',
  '28PoundBunchedLowerOthersSteady': '28 POUND BUNCHED LOWER, OTHERS STEADY.',
  '28PoundBunchedSlightlyHigherOthersSteady': '28 POUND BUNCHED SLIGHTLY HIGHER, OTHERS STEADY.',
  '28PoundBunchedSlightlyLower11PoundAboutSteady': '28 POUND BUNCHED SLIGHTLY LOWER, 11 POUND ABOUT STEADY.',
  '28PoundBunchedSlightlyLowerOthersAboutSteady': '28 POUND BUNCHED SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '28PoundCartons/cratesHigher11PoundCartons/cratesAboutSteady':
    '28 POUND CARTONS/CRATES HIGHER, 11 POUND CARTONS/CRATES ABOUT STEADY.',
  '28PoundCartonsHigherOthersAboutSteady': '28 POUND CARTONS HIGHER, OTHERS ABOUT STEADY.',
  '28PoundCartonsLowerOthersAboutSteady': '28 POUND CARTONS LOWER, OTHERS ABOUT STEADY.',
  '28PoundHigher11PoundAboutSteady': '28 POUND HIGHER, 11 POUND ABOUT STEADY.',
  '28PoundHigherOthersAboutSteady': '28 POUND HIGHER, OTHERS ABOUT STEADY.',
  '28PoundHigherOthersSteady': '28 POUND HIGHER, OTHERS STEADY.',
  '28PoundLower11PoundAboutSteady': '28 POUND LOWER, 11 POUND ABOUT STEADY.',
  '28PoundLower11PoundSlightlyLower': '28 POUND LOWER, 11 POUND SLIGHTLY LOWER.',
  '28PoundLowerOthersSlightlyLower': '28 POUND LOWER, OTHERS SLIGHTLY LOWER.',
  '28PoundPyramidCartons/cartesSlightlyHigherOthersAboutSteady':
    '28 POUND PYRAMID CARTONS/CARTES SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '28PoundPyramidCartons/cratesSlightlyHigherOthersAboutSteady':
    '28 POUND PYRAMID CARTONS/CRATES SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '28PoundPyramidCartons/cratesSlightlyLowerOthersAboutSteady':
    '28 POUND PYRAMID CARTONS/CRATES SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '28PoundSlightlyHigher11PoundLower': '28 POUND SLIGHTLY HIGHER, 11 POUND LOWER.',
  '28PoundSlightlyHigherOthersSteady': '28 POUND SLIGHTLY HIGHER, OTHERS STEADY.',
  '28PoundSlightlyLower11PoundSlightlyHigher': '28 POUND SLIGHTLY LOWER, 11 POUND SLIGHTLY HIGHER.',
  '28PoundStandardAndLargeHigherOthersAboutSteady': '28 POUND STANDARD AND LARGE HIGHER, OTHERS ABOUT STEADY.',
  '28PoundStandardAndLargeHigherOthersSteady': '28 POUND STANDARD AND LARGE HIGHER, OTHERS STEADY.',
  '28PoundStandardAndLargeLowerOthersSteady': '28 POUND STANDARD AND LARGE LOWER, OTHERS STEADY.',
  '28PoundStandardAndLargeSlightlyHigherOthersSteady': '28 POUND STANDARD AND LARGE SLIGHTLY HIGHER, OTHERS STEADY.',
  '28PoundStandardAndLargeSlightlyLowerOthersAboutSteady':
    '28 POUND STANDARD AND LARGE SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '28PoundStandardAndLargeSlightlyLowerOthersSteady': '28 POUND STANDARD AND LARGE SLIGHTLY LOWER, OTHERS STEADY.',
  '28PoundSteadyOthersLower': '28 POUND STEADY, OTHERS LOWER.',
  '2And21/2DozenHigher3DozenSlightlyHigherHeartsAboutSteady':
    '2 AND 21/2 DOZEN HIGHER, 3 DOZEN SLIGHTLY HIGHER, HEARTS ABOUT STEADY.',
  '2And21/2DozenHigher3DozenSlightlyHigherOthersAboutSteady':
    '2 AND 2 1/2 DOZEN HIGHER, 3 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '2And21/2DozenHigherOthersSlightlyHigher': '2 AND 2 1/2 DOZEN HIGHER, OTHERS SLIGHTLY HIGHER.',
  '2And21/2DozenLower3DozenSlightlyLowerHeartsAboutSteady':
    '2 AND 2 1/2 DOZEN LOWER, 3 DOZEN SLIGHTLY LOWER, HEARTS ABOUT STEADY.',
  '2And21/2DozenSlightlyHigherOthersAboutSteady': '2 AND 2 1/2 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '2And21/2DozenSlightlyLower3DozenLowerHeartsAboutSteady':
    '2 AND 2 1/2 DOZEN SLIGHTLY LOWER, 3 DOZEN LOWER, HEARTS ABOUT STEADY.',
  '2And21/2DozenSlightlyLowerOthersAboutSteady': '2 AND 2 1/2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '2And2-1/2DozenSlightlyLowerOthersAboutSteady': '2 AND 2-1/2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '2And3DozenAboutSteadyOthersSlightlyLower': '2 AND 3 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '2And3DozenLowerOthersSlightlyLower': '2 AND 3 DOZEN LOWER, OTHERS SLIGHTLY LOWER.',
  '2And3DozenSlightlyHigherOthersAboutSteady': '2 AND 3 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '2And4DozenAboutSteadyOthersSlightlyHigher': '2 AND 4 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '2And4DozenSlightlyHigherOthersAboutSteady': '2 AND 4 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '2DozenAboutSteadyOthersSlightlyHigher': '2 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '2DozenAboutSteadyOthersSlightlyLower': '2 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '2DozenHigher3DozenSlightlyLowerOthersAboutSteady': '2 DOZEN HIGHER, 3 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '2DozenHigherHeartsAboutSteadyOthersSlightlyHigher': '2 DOZEN HIGHER, HEARTS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '2DozenHigherOthersAboutSteady': '2 DOZEN HIGHER, OTHERS ABOUT STEADY.',
  '2DozenHigherOthersSlightlyHigher': '2 DOZEN HIGHER, OTHERS SLIGHTLY HIGHER.',
  '2DozenLower21/2 -3DozenSlightlyLowerHeartsAboutSteady':
    '2 DOZEN LOWER, 2 1/ 2 - 3 DOZEN SLIGHTLY LOWER, HEARTS ABOUT STEADY.',
  '2DozenLower21/2DozenAboutSteadyOthersSlightlyLower':
    '2 DOZEN LOWER, 2 1/2 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '2DozenLowerOthersAboutSteady': '2 DOZEN LOWER, OTHERS ABOUT STEADY.',
  '2DozenLowerOthersSlightlyLower': '2 DOZEN LOWER, OTHERS SLIGHTLY LOWER.',
  '2DozenSlighlyHigherOthersAboutSteady': '2 DOZEN SLIGHLY HIGHER, OTHERS ABOUT STEADY.',
  '2DozenSlightlhyLowerOthersAboutSteady': '2 DOZEN SLIGHTLHY LOWER, OTHERS ABOUT STEADY.',
  '2DozenSlightlyHigherOthersAboutSteady': '2 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '2DozenSlightlyHigherOthersHigher': '2 DOZEN SLIGHTLY HIGHER, OTHERS HIGHER.',
  '2DozenSlightlyLowerOthersAboutSteady': '2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '3DozenAboutSteadyOthersLower': '3 DOZEN ABOUT STEADY, OTHERS LOWER.',
  '3DozenAboutSteadyOthersSlightlyHigher': '3 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '3DozenAboutSteadyOthersSlightlyLower': '3 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '3DozenAndHeartsSlightlyHigherOthersAboutSteady': '3 DOZEN AND HEARTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '3DozenAndOrganicLowerOthersAboutSteady': '3 DOZEN AND ORGANIC LOWER, OTHERS ABOUT STEADY.',
  '3DozenHigherHeartsAboutSteadyOthersSlightlyHigher': '3 DOZEN HIGHER, HEARTS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '3DozenHigherOthersAboutSteady': '3 DOZEN HIGHER, OTHERS ABOUT STEADY.',
  '3DozenHigherOthersSlightlyHigher': '3 DOZEN HIGHER, OTHERS SLIGHTLY HIGHER.',
  '3DozenLower2DozenAboutSteadyOthersSlightlyLower': '3 DOZEN LOWER, 2 DOZEN ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  '3DozenLowerOthersAboutSteady': '3 DOZEN LOWER, OTHERS ABOUT STEADY.',
  '3DozenLowerOthersSlightlyLower': '3 DOZEN LOWER, OTHERS SLIGHTLY LOWER.',
  '3DozenSlightlyHigherOrganic2DozenSlightlyLowerOthersAboutSteady':
    '3 DOZEN SLIGHTLY HIGHER, ORGANIC 2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '3DozenSlightlyHigherOthersAboutSteady': '3 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '3DozenSlightlyLowerHeartsAboutSteadyOthersLower': '3 DOZEN SLIGHTLY LOWER, HEARTS ABOUT STEADY, OTHERS LOWER.',
  '3DozenSlightlyLowerOthersAboutSteady': '3 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '4DozenAboutSteadyHeartsSlightlyLowerOthersLower': '4 DOZEN ABOUT STEADY, HEARTS SLIGHTLY LOWER, OTHERS LOWER.',
  '4DozenAboutSteadyOthersSlightlyHigher': '4 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '4DozenAndHeartsAboutSteadyOthersSlightlyHigher': '4 DOZEN AND HEARTS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  '4DozenHigherHeartsSlightlyHigherOthersAboutSteady': '4 DOZEN HIGHER, HEARTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '4DozenHigherOthersSlightlyHigher': '4 DOZEN HIGHER, OTHERS SLIGHTLY HIGHER.',
  '4DozenSlightlyHigherOthersAboutSteady': '4 DOZEN SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '4DozenSlightlyLowerOthersAboutSteady': '4 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '6OunceConventionalDemoralizedOthersBarelySteady': '6 OUNCE CONVENTIONAL DEMORALIZED, OTHERS BARELY STEADY.',
  '6OunceConventionalLowerOthersBarelySteady': '6 OUNCE CONVENTIONAL LOWER, OTHERS BARELY STEADY.',
  '6-ounceConventionalSlightlyHigherOthersAboutSteady': '6-OUNCE CONVENTIONAL SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '6-ounceCupsConventionalAboutSteadyOthersLower': '6-OUNCE CUPS CONVENTIONAL ABOUT STEADY, OTHERS LOWER.',
  '6-ounceCupsSlightlyHigherOthersAboutSteady': '6-OUNCE CUPS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  '6-ounceCupsSlightlyHigherPintsAboutSteady': '6-OUNCE CUPS SLIGHTLY HIGHER, PINTS ABOUT STEADY.',
  '6-ounceCupsSlightlyLowerOthersAboutSteady': '6-OUNCE CUPS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  '6OunceHigherPintsFirm': '6 OUNCE HIGHER, PINTS FIRM.',
  '6OunceHigherPintsLower': '6 OUNCE HIGHER, PINTS LOWER.',
  '6OunceHigherPintsSteady': '6 OUNCE HIGHER, PINTS STEADY.',
  '6OunceLowerPintsAboutSteady': '6 OUNCE LOWER, PINTS ABOUT STEADY.',
  '6OunceLowerPintsSteady': '6 OUNCE LOWER, PINTS STEADY.',
  '6-ounceSlightlyHigherPintsAboutSteady': '6-OUNCE SLIGHTLY HIGHER, PINTS ABOUT STEADY.',
  '6OunceSlightyHigherOthersAboutSteady': '6 OUNCE SLIGHTY HIGHER, OTHERS ABOUT STEADY.',
  '6-ozLowerPintsSteady': '6-OZ LOWER; PINTS STEADY.',
  '6OzSlightlyHigherOthersAboutSteady': '6 OZ SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  aboutConventional6OunceLowerOthersAboutSteady: 'ABOUT CONVENTIONAL 6 OUNCE LOWER, OTHERS ABOUT STEADY.',
  aboutSteady: 'ABOUT STEADY.',
  aboutSteadyFirmUndertone: 'ABOUT STEADY FIRM UNDERTONE.',
  barelySteady: 'BARELY STEADY.',
  bestConventionalHigherBestOrganicSlightlyHigher: 'BEST CONVENTIONAL HIGHER, BEST ORGANIC SLIGHTLY HIGHER.',
  conventioanls6OzAboutSteadyOthersSlightlyHigher: 'CONVENTIOANLS 6 OZ ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  'conventional121-pintsAnd6-ounceCupsLowerOthersAboutSteady':
    'CONVENTIONAL 12 1-PINTS AND 6-OUNCE CUPS LOWER, OTHERS ABOUT STEADY.',
  'conventional121-pintsHigherOthersAboutSteady': 'CONVENTIONAL 12 1-PINTS HIGHER, OTHERS ABOUT STEADY.',
  'conventional121-pintsSlightlyLowerOthersAboutSteady': 'CONVENTIONAL 12 1-PINTS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  'conventional12-6OzCupsWithLidsLowerOthersAboutSteady':
    'CONVENTIONAL 12-6 OZ CUPS WITH LIDS LOWER, OTHERS ABOUT STEADY.',
  'conventional1-pintsSlightlyHigherOthersAboutSteady': 'CONVENTIONAL 1-PINTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  conventional6OunceAboutSteadyOthersSlightlyLower: 'CONVENTIONAL 6 OUNCE ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  conventional6OunceAboutStedayOthersSlightlyHigher: 'CONVENTIONAL 6 OUNCE ABOUT STEDAY, OTHERS SLIGHTLY HIGHER.',
  'conventional6-ounceCupsLowerOthersAboutSteady': 'CONVENTIONAL 6-OUNCE CUPS LOWER, OTHERS ABOUT STEADY.',
  conventional6OunceHigherOthersAboutSteady: 'CONVENTIONAL 6 OUNCE HIGHER, OTHERS ABOUT STEADY.',
  conventional6OunceLowerOthersBarelySteady: 'CONVENTIONAL 6 OUNCE LOWER, OTHERS BARELY STEADY.',
  conventional6OunceLowerOthersSteady: 'CONVENTIONAL 6 OUNCE LOWER, OTHERS STEADY.',
  conventional6OunceSlightlyLowerOrganicSlightlyHigherOthersAboutSteady:
    'CONVENTIONAL 6 OUNCE SLIGHTLY LOWER, ORGANIC SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  conventional6OunceSlightlyLowerOthersAboutSteady: 'CONVENTIONAL 6 OUNCE SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  conventional6OzAboutSteadyOthersSlightlyHigher: 'CONVENTIONAL 6 OZ ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  conventional6OzSlightlyHigherOthersAboutSteady: 'CONVENTIONAL 6 OZ SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  'conventional6-ozSlightlyHigherOthersSteady': 'CONVENTIONAL 6-OZ SLIGHTLY HIGHER; OTHERS STEADY.',
  conventional6OzSlightlyLowerOthersAboutSteady: 'CONVENTIONAL 6 OZ SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  'conventional6-ozSlightlyLowerOthersSteady': 'CONVENTIONAL 6-OZ SLIGHTLY LOWER; OTHERS STEADY.',
  'conventional6-ozSteadyOthersHigher': 'CONVENTIONAL 6-OZ STEADY; OTHERS HIGHER.',
  conventionalAboutSteady: 'CONVENTIONAL ABOUT STEADY.',
  conventionalAboutSteadyOrganicHigher: 'CONVENTIONAL ABOUT STEADY, ORGANIC HIGHER.',
  conventionalAboutSteadyOrganicLower: 'CONVENTIONAL ABOUT STEADY, ORGANIC LOWER.',
  conventionalAboutSteadyOrganicSlightlyHigher: 'CONVENTIONAL ABOUT STEADY, ORGANIC SLIGHTLY HIGHER.',
  conventionalAboutSteadyOrganicSlightlyLower: 'CONVENTIONAL ABOUT STEADY, ORGANIC SLIGHTLY LOWER.',
  conventionalAboutSteadyOrganicSteady: 'CONVENTIONAL ABOUT STEADY, ORGANIC STEADY.',
  conventionalBarelySteadyOrganicSteady: 'CONVENTIONAL BARELY STEADY, ORGANIC STEADY.',
  'conventionalFlats126-ounceCupsSlightlyLowerOthersAboutSteady':
    'CONVENTIONAL FLATS 12 6-OUNCE CUPS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  conventionalHigherOrganicAboutSteady: 'CONVENTIONAL HIGHER, ORGANIC ABOUT STEADY.',
  conventionalHigherOrganicBarelySteady: 'CONVENTIONAL HIGHER, ORGANIC BARELY STEADY.',
  conventionalHigherOrganicLower: 'CONVENTIONAL HIGHER, ORGANIC LOWER.',
  conventionalHigherOrganicsAboutSteady: 'CONVENTIONAL HIGHER; ORGANICS ABOUT STEADY.',
  conventionalHigherOrganicSlightlyHigher: 'CONVENTIONAL HIGHER, ORGANIC SLIGHTLY HIGHER.',
  conventionalHigherOrganicSteady: 'CONVENTIONAL HIGHER, ORGANIC STEADY.',
  conventionalLargeLowerOthersAboutSteady: 'CONVENTIONAL LARGE LOWER, OTHERS ABOUT STEADY.',
  conventionalLower: 'CONVENTIONAL LOWER.',
  conventionalLowerOrganicAboutSteady: 'CONVENTIONAL LOWER, ORGANIC ABOUT STEADY.',
  conventionalLowerOrganicBarelySteady: 'CONVENTIONAL LOWER, ORGANIC BARELY STEADY.',
  conventionalLowerOrganicSlightlyLower: 'CONVENTIONAL LOWER, ORGANIC SLIGHTLY LOWER.',
  conventionalLowerOrganicSteady: 'CONVENTIONAL LOWER, ORGANIC STEADY.',
  conventionalLowerOthersBarelySteady: 'CONVENTIONAL LOWER, OTHERS BARELY STEADY.',
  conventionalLowerOthersSteady: 'CONVENTIONAL LOWER; OTHERS STEADY.',
  conventionalMuchHigherOrganicAboutSteady: 'CONVENTIONAL MUCH HIGHER, ORGANIC ABOUT STEADY.',
  conventionalMuchHigherOrganicHigher: 'CONVENTIONAL MUCH HIGHER, ORGANIC HIGHER.',
  conventionalMuchHigherOrganicSteady: 'CONVENTIONAL MUCH HIGHER, ORGANIC STEADY.',
  conventionalMuchLowerOrganicAboutSteady: 'CONVENTIONAL MUCH LOWER, ORGANIC ABOUT STEADY.',
  conventionalMuchLowerOrganicBarelySteady: 'CONVENTIONAL MUCH LOWER, ORGANIC BARELY STEADY.',
  conventionalMuchLowerOrganicSlightlyLower: 'CONVENTIONAL MUCH LOWER, ORGANIC SLIGHTLY LOWER.',
  conventionalMuchLowerOrganicSteady: 'CONVENTIONAL MUCH LOWER, ORGANIC STEADY.',
  conventionalPintHigherOthersSteady: 'CONVENTIONAL PINT HIGHER; OTHERS STEADY.',
  conventionalPintsAboutSteadyOthersLower: 'CONVENTIONAL PINTS ABOUT STEADY, OTHERS LOWER.',
  'conventionalPintsAnd6-ounceCupsAboutSteadyOthersSlightlyLower':
    'CONVENTIONAL PINTS AND 6-OUNCE CUPS ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  'conventionalPintsAnd6-ounceCupsSlightlyHigherOthersAboutSteady':
    'CONVENTIONAL PINTS AND 6-OUNCE CUPS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  'conventionalPintsAnd6-ounceCupsSlightlyLowerOthersAboutSteady':
    'CONVENTIONAL PINTS AND 6-OUNCE CUPS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  conventionalPintsAndOrganic6OunceSlightlyLowerOthersAboutSteady:
    'CONVENTIONAL PINTS AND ORGANIC 6 OUNCE SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  'conventionalPintsAndOrganic6-ozHigherConventional6-ozAboutSteady':
    'CONVENTIONAL PINTS AND ORGANIC 6-OZ HIGHER; CONVENTIONAL 6-OZ ABOUT STEADY.',
  conventionalPintsAndOrganic6OzHigherOthersAboutSteady:
    'CONVENTIONAL PINTS AND ORGANIC 6 OZ HIGHER, OTHERS ABOUT STEADY.',
  conventionalPintsHigherOthersSlightlyHigher: 'CONVENTIONAL PINTS HIGHER, OTHERS SLIGHTLY HIGHER.',
  conventionalPintSlighltyHigherOthersAboutSteady: 'CONVENTIONAL PINT SLIGHLTY HIGHER; OTHERS ABOUT STEADY.',
  conventionalPintSlightlyHigherOthersSteady: 'CONVENTIONAL PINT SLIGHTLY HIGHER; OTHERS STEADY.',
  conventionalPintsLower6OunceSlightlyLowerOrganicBarelySteady:
    'CONVENTIONAL PINTS LOWER, 6 OUNCE SLIGHTLY LOWER, ORGANIC BARELY STEADY.',
  conventionalPintsLowerOthersAboutSteady: 'CONVENTIONAL PINTS LOWER, OTHERS ABOUT STEADY.',
  conventionalPintsLowerOthersBarelySteady: 'CONVENTIONAL PINTS LOWER, OTHERS BARELY STEADY.',
  conventionalPintsLowerOthersSteady: 'CONVENTIONAL PINTS LOWER; OTHERS STEADY.',
  conventionalPintsSlightlyHigherOthersAboutSteady: 'CONVENTIONAL PINTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  conventionalPintsSlightlyHigherOthersSteady: 'CONVENTIONAL PINTS SLIGHTLY HIGHER; OTHERS STEADY.',
  conventionalPintsSlightlyLowerOthersAboutSteady: 'CONVENTIONAL PINTS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  conventionalPintsSlightlyLowerOthersSteady: 'CONVENTIONAL PINTS SLIGHTLY LOWER; OTHERS STEADY.',
  conventionalSlightlyHigher: 'CONVENTIONAL SLIGHTLY HIGHER.',
  conventionalSlightlyHigherOrganicAboutSteady: 'CONVENTIONAL SLIGHTLY HIGHER, ORGANIC ABOUT STEADY.',
  conventionalSlightlyHigherOrganicLower: 'CONVENTIONAL SLIGHTLY HIGHER, ORGANIC LOWER.',
  conventionalSlightlyHigherOrganicSlightlyLower: 'CONVENTIONAL SLIGHTLY HIGHER, ORGANIC SLIGHTLY LOWER.',
  conventionalSlightlyHigherOrganicSteady: 'CONVENTIONAL SLIGHTLY HIGHER, ORGANIC STEADY.',
  conventionalSlightlyLowerOrganicAboutSteady: 'CONVENTIONAL SLIGHTLY LOWER, ORGANIC ABOUT STEADY.',
  conventionalSlightlyLowerOrganicBarelySteady: 'CONVENTIONAL SLIGHTLY LOWER, ORGANIC BARELY STEADY.',
  conventionalSlightlyLowerOrganicLower: 'CONVENTIONAL SLIGHTLY LOWER, ORGANIC LOWER.',
  conventionalSlightlyLowerOrganicSlightlyHigher: 'CONVENTIONAL SLIGHTLY LOWER, ORGANIC SLIGHTLY HIGHER.',
  conventionalSlightlyLowerOrganicSteady: 'CONVENTIONAL SLIGHTLY LOWER, ORGANIC STEADY.',
  conventionalSlightlyLowerOthersAboutSteady: 'CONVENTIONAL SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  conventionalStandardAndLargeLowerOthersAboutSteady: 'CONVENTIONAL STANDARD AND LARGE LOWER, OTHERS ABOUT STEADY.',
  conventionalStandardLowerOthersSteady: 'CONVENTIONAL STANDARD LOWER, OTHERS STEADY.',
  conventionalSteadyOrganicAboutSteady: 'CONVENTIONAL STEADY, ORGANIC ABOUT STEADY.',
  coventionalHigherOthersSteady: 'COVENTIONAL HIGHER, OTHERS STEADY.',
  fairlyGood: 'FAIRLY GOOD.',
  firm: 'FIRM.',
  firstReport: 'FIRST REPORT.',
  'flats121-pintBasketsAboutSteadyOthersHigher': 'FLATS 12 1-PINT BASKETS ABOUT STEADY, OTHERS HIGHER.',
  'flats121-pintBasketsAboutSteadyOthersLower': 'FLATS 12 1-PINT BASKETS ABOUT STEADY, OTHERS LOWER.',
  'flats121-pintBasketsHigherOthersAboutSteady': 'FLATS 12 1-PINT BASKETS HIGHER, OTHERS ABOUT STEADY.',
  'flats121-pintBasketsHigherOthersSteady': 'FLATS 12 1-PINT BASKETS HIGHER, OTHERS STEADY.',
  'flats121-pintBasketsLowerOthersSteady': 'FLATS 12 1-PINT BASKETS LOWER, OTHERS STEADY.',
  'flats121-pintBasketsSteadyOthersHigher': 'FLATS 12 1-PINT BASKETS STEADY, OTHERS HIGHER.',
  'flats121-pintBasketsSteadyOthersLower': 'FLATS 12 1-PINT BASKETS STEADY, OTHERS LOWER.',
  'flats121-pintBasketsSteadyOthersSlightlyLower': 'FLATS 12 1-PINT BASKETS STEADY, OTHERS SLIGHTLY LOWER.',
  'flats121-pintCupsWithLidsLowerOthersAboutSteady': 'FLATS 12 1-PINT CUPS WITH LIDS LOWER, OTHERS ABOUT STEADY.',
  'flats121-pintCupsWithLidsSlightlyHigherOthersAboutSteady':
    'FLATS 12 1-PINT CUPS WITH LIDS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  'flats126-ounceCupsSlightlyHigherOthersAboutSteady': 'FLATS 12 6-OUNCE CUPS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  'flats126-ounceCupsSlightlyLowerPintsAboutSteady': 'FLATS 12 6-OUNCE CUPS SLIGHTLY LOWER, PINTS ABOUT STEADY.',
  'flats126-ounceCupsWithLidsHigherOthersAboutSteady': 'FLATS 12 6-OUNCE CUPS WITH LIDS HIGHER, OTHERS ABOUT STEADY.',
  'flats126-ounceCupsWithLidsHigherOthersSteady': 'FLATS 12 6-OUNCE CUPS WITH LIDS HIGHER, OTHERS STEADY.',
  'flats126-ounceCupsWithLidsLowerOthersAboutSteady': 'FLATS 12 6-OUNCE CUPS WITH LIDS LOWER, OTHERS ABOUT STEADY.',
  'flats126-ounceCupsWithLidsSlightlyHigherPintsAboutSteady':
    'FLATS 12 6-OUNCE CUPS WITH LIDS SLIGHTLY HIGHER, PINTS ABOUT STEADY.',
  'flats126-ounceSlightlyHigherOthersAboutSteady': 'FLATS 12 6-OUNCE SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  'flats81-poundContainersLowerOthersAboutSteady': 'FLATS 8 1-POUND CONTAINERS LOWER, OTHERS ABOUT STEADY.',
  'flats81-poundContainersLowerOthersSteady': 'FLATS 8 1-POUND CONTAINERS LOWER, OTHERS STEADY.',
  'flats81-poundContainersWithLidsHigherOthersSteady': 'FLATS 8 1-POUND CONTAINERS WITH LIDS HIGHER, OTHERS STEADY.',
  'flats81-poundContainersWithLidsLowerOthersAboutSteady':
    'FLATS 8 1-POUND CONTAINERS WITH LIDS LOWER, OTHERS ABOUT STEADY.',
  'flats81-poundContainersWithLidsLowerOthersSteady': 'FLATS 8 1-POUND CONTAINERS WITH LIDS LOWER, OTHERS STEADY.',
  'flats81-poundContainersWithLidsSlightlyHigherOthersSteady':
    'FLATS 8 1-POUND CONTAINERS WITH LIDS SLIGHTLY HIGHER, OTHERS STEADY.',
  'flats81-poundContainerswithLidsSlightlyHigherOthersSteady':
    'FLATS 8 1-POUND CONTAINERSWITH LIDS SLIGHTLY HIGHER, OTHERS STEADY.',
  'flats81-poundContainersWithLidsSlightlyLowerOrganicAboutSteady':
    'FLATS 8 1-POUND CONTAINERS WITH LIDS SLIGHTLY LOWER, ORGANIC ABOUT STEADY.',
  'flats81-poundContainersWithLidsSlightlyLowerOthersAboutSteady':
    'FLATS 8 1-POUND CONTAINERS WITH LIDS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  'flats81-poundContainersWithLidsSlightlyLowerOthersSteady':
    'FLATS 8 1-POUND CONTAINERS WITH LIDS SLIGHTLY LOWER, OTHERS STEADY.',
  'flats81-poundContainersWithLidsSmall-mediumLowerOthersAboutSteady':
    'FLATS 8 1-POUND CONTAINERS WITH LIDS SMALL-MEDIUM LOWER, OTHERS ABOUT STEADY.',
  'fltas121-pintBasketsLowerOthersSteady': 'FLTAS 12 1-PINT BASKETS LOWER, OTHERS STEADY.',
  'flts121-pintCupsLower6-ounceAboutSteady': 'FLTS 12 1-PINT CUPS LOWER, 6-OUNCE ABOUT STEADY.',
  heartsAboutSteady2DozenSlighltyLowerOthersLower: 'HEARTS ABOUT STEADY, 2 DOZEN SLIGHLTY LOWER, OTHERS LOWER.',
  heartsAboutSteady3DozenSlightlyLowerOthersLower: 'HEARTS ABOUT STEADY, 3 DOZEN SLIGHTLY LOWER, OTHERS LOWER.',
  heartsAboutSteadyOthersHigher: 'HEARTS ABOUT STEADY, OTHERS HIGHER.',
  heartsAboutSteadyOthersLower: 'HEARTS ABOUT STEADY, OTHERS LOWER.',
  heartsAboutSteadyOthersSlghtlyLower: 'HEARTS ABOUT STEADY, OTHERS SLGHTLY LOWER.',
  heartsAboutSteadyOthersSlightlyHigher: 'HEARTS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  heartsAboutSteadyOthersSlightlyLower: 'HEARTS ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  'heartsAnd21/2DozenSlightlyLower3DozenLowerOthersSlightlyHigher':
    'HEARTS AND 2 1/2 DOZEN SLIGHTLY LOWER, 3 DOZEN LOWER, OTHERS SLIGHTLY HIGHER.',
  'heartsAnd21/2DozenSlightlyLowerOthersAboutSteady': 'HEARTS AND 2 1/2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  heartsAnd4DozenAboutSteadyOthersSlightlyHigher: 'HEARTS AND 4 DOZEN ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  heartsHigherOthersSlightlyHigher: 'HEARTS HIGHER, OTHERS SLIGHTLY HIGHER.',
  heartsLower2DozenSlightlyLowerOthersAboutSteady: 'HEARTS LOWER, 2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  heartsLower3DozenSlightlyLowerOthersAboutSteady: 'HEARTS LOWER, 3 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  heartsLowerOthersAboutSteady: 'HEARTS LOWER, OTHERS ABOUT STEADY.',
  heartsLowerOthersMuchLower: 'HEARTS LOWER, OTHERS MUCH LOWER.',
  heartsLowerOthersSlightlyLower: 'HEARTS LOWER, OTHERS SLIGHTLY LOWER.',
  heartsSlightlyHigher3DozenSlightlyLowerOthersAboutSteady:
    'HEARTS SLIGHTLY HIGHER, 3 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  heartsSlightlyHigherOrganicHeartsSlightlyLowerOthersAboutSteady:
    'HEARTS SLIGHTLY HIGHER, ORGANIC HEARTS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  heartsSlightlyHigherOthersAboutSteady: 'HEARTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  heartsSlightlyHigherOthersHigher: 'HEARTS SLIGHTLY HIGHER, OTHERS HIGHER.',
  heartsSlightlyLower2DozenAboutSteadyOthersLower: 'HEARTS SLIGHTLY LOWER, 2 DOZEN ABOUT STEADY, OTHERS LOWER.',
  heartsSlightlyLower3DozenAboutSteadyOthersLower: 'HEARTS SLIGHTLY LOWER, 3 DOZEN ABOUT STEADY, OTHERS LOWER.',
  heartsSlightlyLowerBunchedAboutSteady: 'HEARTS SLIGHTLY LOWER, BUNCHED ABOUT STEADY.',
  heartsSlightlyLowerOthersAboutSteady: 'HEARTS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  heartsSlightlyLowerOthersLower: 'HEARTS SLIGHTLY LOWER, OTHERS LOWER.',
  heartsSlightlyLowerOthersLowerOrganicHeartsSlightlyLowerOthersMuchLower:
    'HEARTS SLIGHTLY LOWER, OTHERS LOWER; ORGANIC HEARTS SLIGHTLY LOWER, OTHERS MUCH LOWER.',
  higher: 'HIGHER.',
  jumboAboutSteadyOthersSlightlyLower: 'JUMBO ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  jumboAndLargeLowerOthersSteady: 'JUMBO AND LARGE LOWER, OTHERS STEADY.',
  jumboAndLargeSizeHigherOthersSteady: 'JUMBO AND LARGE SIZE HIGHER, OTHERS STEADY.',
  jumboLowerOthersAboutSteady: 'JUMBO LOWER, OTHERS ABOUT STEADY.',
  jumboLowerOthersHigher: 'JUMBO LOWER, OTHERS HIGHER.',
  jumboSlightlyLowerOthersAboutSteady: 'JUMBO SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  jumboSteadyOthersLower: 'JUMBO STEADY, OTHERS LOWER.',
  largeAboutSteadyOthersLower: 'LARGE ABOUT STEADY, OTHERS LOWER.',
  largeAndJumboHigherOthersAboutSteady: 'LARGE AND JUMBO HIGHER, OTHERS ABOUT STEADY.',
  largeHigherOthersSteady: 'LARGE HIGHER, OTHERS STEADY.',
  largeSlightlyHigherOthersAboutSteady: 'LARGE SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  largeSlightlyLowerOthersAboutSteady: 'LARGE SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  largeSlightlyLowerStandardSteady: 'LARGE SLIGHTLY LOWER, STANDARD STEADY.',
  lower: 'LOWER.',
  mediumConventionalAboutSteadyOthersSlightlyLower: 'MEDIUM CONVENTIONAL ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  'mediumConventionalSlightlyHigherMedium-largeOrganicSlightlyLowerOthersAboutSteady':
    'MEDIUM CONVENTIONAL SLIGHTLY HIGHER, MEDIUM-LARGE ORGANIC SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  mediumConventionalSlightlyHigherOthersAboutSteady: 'MEDIUM CONVENTIONAL SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  'medium-largeConventionalSlightlyHigherOthersSteady': 'MEDIUM-LARGE CONVENTIONAL SLIGHTLY HIGHER, OTHERS STEADY.',
  'medium-largeHigherOthersAboutSteady': 'MEDIUM-LARGE HIGHER, OTHERS ABOUT STEADY.',
  'medium-largeLowerOthersSteady': 'MEDIUM-LARGE LOWER, OTHERS STEADY.',
  'medium-largeSlightlyHigherOthersSteady': 'MEDIUM-LARGE SLIGHTLY HIGHER, OTHERS STEADY.',
  'mediumSlightlyHigherSmall-mediumAboutSteady': 'MEDIUM SLIGHTLY HIGHER, SMALL-MEDIUM ABOUT STEADY.',
  moderate: 'MODERATE.',
  muchHigher: 'MUCH HIGHER.',
  muchLower: 'MUCH LOWER.',
  'organic12-1PintsLowerOthersAboutSteady': 'ORGANIC 12-1 PINTS LOWER., OTHERS ABOUT STEADY.',
  'organic12-6OunceLowerConventionalPintsSlightlyLowerOthersAboutSteady':
    'ORGANIC 12-6 OUNCE LOWER, CONVENTIONAL PINTS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  organic2DozenAndHeartsSlightlyLowerOthersAboutSteady:
    'ORGANIC 2 DOZEN AND HEARTS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  organic6ozAboutSteadyOthersSlightlyLower: 'ORGANIC 6OZ ABOUT STEADY ,OTHERS SLIGHTLY LOWER.',
  'organic6-ozHigherOthersSteady': 'ORGANIC 6-OZ HIGHER; OTHERS STEADY.',
  organic6OzSlightlyHigherOthersAboutSteady: 'ORGANIC 6 OZ SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  organicAboutSteadyConventionalSlightlyLower: 'ORGANIC ABOUT STEADY, CONVENTIONAL SLIGHTLY LOWER.',
  organicAboutSteadyOhtersLower: 'ORGANIC ABOUT STEADY, OHTERS LOWER.',
  organicAboutSteadyOtherLower: 'ORGANIC ABOUT STEADY, OTHER LOWER.',
  organicAboutSteadyOthersHigher: 'ORGANIC ABOUT STEADY, OTHERS HIGHER.',
  organicAboutSteadyOthersLower: 'ORGANIC ABOUT STEADY, OTHERS LOWER.',
  organicAboutSteadyOthersSlightlyHigher: 'ORGANIC ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  organicAboutSteadyOthersSlightlyLower: 'ORGANIC ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  organicAndHeartsAboutSteadyOthersSlightlyHigher: 'ORGANIC AND HEARTS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  'organicAndMedium-largeAboutSteadyOthersSlightlyLower':
    'ORGANIC AND MEDIUM-LARGE ABOUT STEADY, OTHERS SLIGHTLY LOWER.',
  organicBarelySteadyConventionalAboutSteady: 'ORGANIC BARELY STEADY, CONVENTIONAL ABOUT STEADY.',
  organicBarelySteadyConventionalSteady: 'ORGANIC BARELY STEADY, CONVENTIONAL STEADY.',
  organicGoodOthersSteady: 'ORGANIC GOOD, OTHERS STEADY.',
  organicHigherConventionalAboutSteady: 'ORGANIC HIGHER, CONVENTIONAL ABOUT STEADY.',
  organicHigherConventionalFirm: 'ORGANIC HIGHER, CONVENTIONAL FIRM.',
  organicHigherConventionalLower: 'ORGANIC HIGHER, CONVENTIONAL LOWER.',
  organicHigherConventionalSlightlyHigher: 'ORGANIC HIGHER, CONVENTIONAL SLIGHTLY HIGHER.',
  organicHigherConventionalSlightlyLower: 'ORGANIC HIGHER, CONVENTIONAL SLIGHTLY LOWER.',
  organicHigherConventionalSteady: 'ORGANIC HIGHER, CONVENTIONAL STEADY.',
  organicHigherOthersAboutSteady: 'ORGANIC HIGHER, OTHERS ABOUT STEADY.',
  organicHigherOthersLower: 'ORGANIC HIGHER, OTHERS LOWER.',
  organicHigherOthersSlightlyHigher: 'ORGANIC HIGHER, OTHERS SLIGHTLY HIGHER.',
  organicHigherOthersSlightlyLower: 'ORGANIC HIGHER, OTHERS SLIGHTLY LOWER.',
  organicHigherOthersSteady: 'ORGANIC HIGHER, OTHERS STEADY.',
  organicLower: 'ORGANIC LOWER.',
  'organicLower2-21/2DozenSlightlyLowerOthersAboutSteady':
    'ORGANIC LOWER, 2-2 1/2 DOZEN SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  'organicLower2-3DozenSlightlyLowerHeartsAboutSteady': 'ORGANIC LOWER, 2-3 DOZEN SLIGHTLY LOWER, HEARTS ABOUT STEADY.',
  organicLowerConventionalAboutSteady: 'ORGANIC LOWER, CONVENTIONAL ABOUT STEADY.',
  organicLowerConventionalBarelySteady: 'ORGANIC LOWER, CONVENTIONAL BARELY STEADY.',
  organicLowerConventionalSlightlyLower: 'ORGANIC LOWER, CONVENTIONAL SLIGHTLY LOWER.',
  organicLowerConventionalSteady: 'ORGANIC LOWER, CONVENTIONAL STEADY.',
  organicLowerOtherAboutSteady: 'ORGANIC LOWER, OTHER ABOUT STEADY.',
  organicLowerOthersAboutSteady: 'ORGANIC LOWER, OTHERS ABOUT STEADY.',
  organicLowerOthersBarelySteady: 'ORGANIC LOWER, OTHERS BARELY STEADY.',
  organicLowerOthersFirm: 'ORGANIC LOWER, OTHERS FIRM.',
  organicLowerOthersGood: 'ORGANIC LOWER, OTHERS GOOD.',
  organicLowerOthersSlightlyHigher: 'ORGANIC LOWER, OTHERS SLIGHTLY HIGHER.',
  organicLowerOthersSteady: 'ORGANIC LOWER, OTHERS STEADY.',
  organicLowerOtherSteady: 'ORGANIC LOWER, OTHER STEADY.',
  organicMuchLowerOthersAboutSteady: 'ORGANIC MUCH LOWER, OTHERS ABOUT STEADY.',
  organicPintsAboutSteadyOthersSlightlyHigher: 'ORGANIC PINTS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  organicPintsHigherOthersAboutSteady: 'ORGANIC PINTS HIGHER; OTHERS ABOUT STEADY.',
  organicPintsSlightlyLowerOthersAboutSteady: 'ORGANIC PINTS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  organicsAboutSteadyOthersLower: 'ORGANICS ABOUT STEADY, OTHERS LOWER.',
  organicsAboutSteadyOthersSlightlyHigher: 'ORGANICS ABOUT STEADY, OTHERS SLIGHTLY HIGHER.',
  organicSlightlyHigherConventionalAboutSteady: 'ORGANIC SLIGHTLY HIGHER, CONVENTIONAL ABOUT STEADY.',
  organicSlightlyHigherConventionalSlightlyLower: 'ORGANIC SLIGHTLY HIGHER, CONVENTIONAL SLIGHTLY LOWER.',
  organicSlightlyHigherConventionalSteady: 'ORGANIC SLIGHTLY HIGHER, CONVENTIONAL STEADY.',
  organicSlightlyHigherOthersAboutSteady: 'ORGANIC SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  organicSlightlyHigherOthersSlightlyLower: 'ORGANIC SLIGHTLY HIGHER, OTHERS SLIGHTLY LOWER.',
  organicSlightlyHigherOthersSteady: 'ORGANIC SLIGHTLY HIGHER, OTHERS STEADY.',
  organicSlightlyLowerConventionalAboutSteady: 'ORGANIC SLIGHTLY LOWER, CONVENTIONAL ABOUT STEADY.',
  organicSlightlyLowerConventionalSteady: 'ORGANIC SLIGHTLY LOWER, CONVENTIONAL STEADY.',
  organicSlightlyLowerOthersAboutSteady: 'ORGANIC SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  organicSlightlyLowerOthersSlightlyHigher: 'ORGANIC SLIGHTLY LOWER, OTHERS SLIGHTLY HIGHER.',
  organicSlightlyLowerOthersSteady: 'ORGANIC SLIGHTLY LOWER, OTHERS STEADY.',
  organicSliightlyHigherOthersAboutSteady: 'ORGANIC SLIIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  organicSteadyConventionalAboutSteady: 'ORGANIC STEADY, CONVENTIONAL ABOUT STEADY.',
  organicSteadyConventionalLower: 'ORGANIC STEADY, CONVENTIONAL LOWER.',
  organicSteadyOtherLower: 'ORGANIC STEADY, OTHER LOWER.',
  organicSteadyOthersAboutSteady: 'ORGANIC STEADY, OTHERS ABOUT STEADY.',
  organicSteadyOthersHigher: 'ORGANIC STEADY, OTHERS HIGHER.',
  organicSteadyOtherSlightlyHigher: 'ORGANIC STEADY, OTHER SLIGHTLY HIGHER.',
  organicSteadyOthersLower: 'ORGANIC STEADY, OTHERS LOWER.',
  organicSteadyOthersSlightlyHigher: 'ORGANIC STEADY, OTHERS SLIGHTLY HIGHER.',
  organicSteadyOthersSlightlyLower: 'ORGANIC STEADY, OTHERS SLIGHTLY LOWER.',
  'pintsAndOrganic6-ozHigherOthersSteady': 'PINTS AND ORGANIC 6-OZ HIGHER; OTHERS STEADY.',
  pintsBarelySteadyOthersSlightlyLower: 'PINTS BARELY STEADY, OTHERS SLIGHTLY LOWER.',
  'pintsHigher6-ozAboutSteady': 'PINTS HIGHER; 6-OZ ABOUT STEADY.',
  pintsHigherOthersAboutSteady: 'PINTS HIGHER, OTHERS ABOUT STEADY.',
  pintsLower6OunceSteady: 'PINTS LOWER, 6 OUNCE STEADY.',
  'pintsLower6-ozSteady': 'PINTS LOWER, 6-OZ STEADY.',
  pintsLowerOthersAboutSteady: 'PINTS LOWER, OTHERS ABOUT STEADY.',
  pintsLowerOthersBarelySteady: 'PINTS LOWER, OTHERS BARELY STEADY.',
  pintsLowerOthersSteady: 'PINTS LOWER, OTHERS STEADY.',
  'pintsSlightlyHigher6-ounceAboutSteady': 'PINTS SLIGHTLY HIGHER, 6-OUNCE ABOUT STEADY.',
  pintsSlightlyHigherOthersAboutSteady: 'PINTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  pintsSlightlyLowerOthersAboutSteady: 'PINTS SLIGHTLY LOWER, OTHERS ABOUT STEADY.',
  pintsSlightlyLowerOthersSteady: 'PINTS SLIGHTLY LOWER, OTHERS STEADY.',
  'pyramidCartons/crates28PoundsBunchedLowerOthersAboutSteady':
    'PYRAMID CARTONS/CRATES 28 POUNDS BUNCHED LOWER, OTHERS ABOUT STEADY.',
  'pyramidCartons/cratesStandardLowerOthersAboutSteady': 'PYRAMID CARTONS/CRATES STANDARD LOWER, OTHERS ABOUT STEADY.',
  slighltyLower: 'SLIGHLTY LOWER.',
  slightlyHigher: 'SLIGHTLY HIGHER.',
  slightlyHigherOrganicAboutSteady: 'SLIGHTLY HIGHER, ORGANIC ABOUT STEADY.',
  slightlyHigherOrganicLower: 'SLIGHTLY HIGHER; ORGANIC LOWER.',
  slightlyHigherOrganicSteady: 'SLIGHTLY HIGHER; ORGANIC STEADY.',
  slightlyLower: 'SLIGHTLY LOWER.',
  smallAboutSteadyOthersLower: 'SMALL ABOUT STEADY, OTHERS LOWER.',
  smallLowerOthersAboutSteady: 'SMALL LOWER, OTHERS ABOUT STEADY.',
  'small/mediumAboutSteadyOthersHigher': 'SMALL/MEDIUM ABOUT STEADY, OTHERS HIGHER.',
  'small-mediumConventionalSlightlyHigherOthersAboutSteady':
    'SMALL-MEDIUM CONVENTIONAL SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  'small-mediumLowerOthersAboutSteady': 'SMALL-MEDIUM LOWER, OTHERS ABOUT STEADY.',
  'small-mediumSlightlyHigherOthersAboutSteady': 'SMALL-MEDIUM SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  smallSizeLowerOthersSteady: 'SMALL SIZE LOWER, OTHERS STEADY.',
  smallSizeSteadyOthersHigher: 'SMALL SIZE STEADY, OTHERS HIGHER.',
  smallSizeSteadyOthersLower: 'SMALL SIZE STEADY, OTHERS LOWER.',
  ssteady: 'SSTEADY.',
  standardAndLargeAboutSteadySmallLower: 'STANDARD AND LARGE ABOUT STEADY, SMALL LOWER.',
  standardAndLargeLowerOthersAboutSteady: 'STANDARD AND LARGE LOWER, OTHERS ABOUT STEADY.',
  standardAndLargeSizeSlightlyHigherSmallSizeLowerOthersSteady:
    'STANDARD AND LARGE SIZE SLIGHTLY HIGHER, SMALL SIZE LOWER, OTHERS STEADY.',
  standardLowerOthersAboutSteady: 'STANDARD LOWER, OTHERS ABOUT STEADY.',
  standardSizeSlightlyLowerOthersSteady: 'STANDARD SIZE SLIGHTLY LOWER, OTHERS STEADY.',
  standardSlightlyHigherLargeAboutSteady: 'STANDARD SLIGHTLY HIGHER, LARGE ABOUT STEADY.',
  standardSlightlyHigherOthersAboutSteady: 'STANDARD SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  standardSlightlyLowerLargeAboutSteady: 'STANDARD SLIGHTLY LOWER, LARGE ABOUT STEADY.',
  steady: 'STEADY.',
  whiteAboutSteadyGreenSlightlyLower: 'WHITE ABOUT STEADY, GREEN SLIGHTLY LOWER.',
};

const marketES = {
  '11LbCartonsHigherOthersAboutSteady': '11 LB CARTONES superior, a otros acerca estable.',
  '11LbJumboLowerOthersAboutSteady': '11 LB JUMBO más bajo y otros ACERCA DE CONSTANTE.',
  '11LbSlightlyLowerOtherAboutSteady': '11 LB ligeramente inferior, sí acerca estable.',
  '11PoundCarton/cratesSlightlyLowerOthersAboutSteady':
    '11 LA CAJA DE LIBRA / cajas ligeramente inferior, a otros acerca estable.',
  '11PoundCartons/cratesBunchedSlightlyLowerOthersAboutSteady':
    '11 CAJAS DE LIBRA / CRATES agrupado ligeramente inferior, a otros acerca estable.',
  '11PoundJumboAndSmallLower11PoundLargeAndStandardSteadyOthersHigher':
    '11 JUMBO LIBRA Y PEQUEÑA BAJA, 11 LIBRA GRANDE Y ESTÁNDAR CONSTANTE, otros SUPERIOR.',
  '11PoundJumboSlightlyLowerOthersHigher': 'LIBRA 11 JUMBO ligeramente inferior, OTROS SUPERIOR.',
  '11PoundSlightlyHigherOthersAboutSteady': '11 LIBRA ligeramente superior, a otros acerca estable.',
  '11PoundSlightlyHigherOthersSteady': '11 LIBRA ligeramente superior, OTROS estable.',
  '11PoundSlightlyLowerOthersAboutSteady': '11 LIBRA ligeramente inferior, a otros acerca estable.',
  '11PoundSmallAnd28PoundLargeSlightlyLowerOthersAboutSteady':
    'LIBRA 11 PEQUEÑAS Y 28 LIBRAS DE GRAN ligeramente inferior, a otros acerca estable.',
  '11PoundStandardAndLargeSteadyOthersLower': 'LIBRA 11 estándar y en grandes CONSTANTE, el resto en minúsculas.',
  '11PoundStandardAndLargeSteadyOthersSlightlyLower':
    'LIBRA 11 estándar y en grandes CONSTANTE, otros ligeramente inferior.',
  '121-pintBasketsSteadyOthersLower': '12 canastas 1-PINT firme, OTROS inferior.',
  '121-pintCupsLowerOthersAboutSteady': '12 1-PINT CUPS LOWER, otros acerca estable.',
  '121-pintCupsSlightlyLowerOthersAboutSteady': '12 tazas 1-PINT ligeramente inferior, a otros acerca estable.',
  '121-pintCupsWithLidsSlightlyHigherOthersAboutSteady':
    '12 tazas 1 pinta con tapas ligeramente superior, a otros acerca estable.',
  '121-pintsSlightlyHigherOthersAboutSteady': '12 1-PINTS ligeramente superior, a otros acerca estable.',
  '126-ounceCupsSlightlyHigherOthersAboutSteady': '12 tazas de 6 onzas ligeramente superior, a otros acerca estable.',
  '18-ounceContainersAboutSteadyOthersSlightlyHigher': 'CONTAINERS 18 onzas SOBRE firme, OTROS ligeramente superior.',
  '18-ounceContainersSlightlyLowerOthersAboutSteady':
    'CONTAINERS 18 onzas ligeramente inferior, a otros acerca estable.',
  '18-ounceHigherOrganicSteadyOthersSlightlyHigher': '18 ONZAS SUPERIOR, orgánico firme, OTROS ligeramente superior.',
  '20LbSlightlyHigherOthersAboutSteady': '20 LB ligeramente superior, a otros acerca estable.',
  '21/2-3DozenSlightlyHigherOthersAboutSteady': '2 1 / 2-3 DOZEN ligeramente superior, a otros acerca estable.',
  '21/2And3DozenAboutSteadyOthersSlightlyLower': '2 1/2 y 3 DOZEN ACERCA CONSTANTE, otros ligeramente inferior.',
  '21/2And3DozenLowerOthersSlightlyLower': '2 1/2 y 3 DOZEN más bajo y otros ligeramente inferior.',
  '21/2And3DozenSlightlyHigherOthersAboutSteady': '2 1/2 y 3 DOZEN ligeramente superior, a otros acerca estable.',
  '21/2And3DozenSlightlyLowerOthersAboutSteady': '2 1/2 y 3 DOZEN ligeramente inferior, a otros acerca estable.',
  '21/2CartonsSlightlyLowerOthersAboutSteady': '2 1/2 CARTONS ligeramente inferior, a otros acerca estable.',
  '21/2DozenAboutSteadyOthersSlightlyHigher': '2 1/2 DOZEN ACERCA firme, OTROS ligeramente superior.',
  '21/2DozenAboutSteadyOthersSlightlyLower': '2 1/2 DOZEN ACERCA CONSTANTE, otros ligeramente inferior.',
  '21/2DozenAndHeartsAboutSteadyOthersSlightlyHigher':
    '2 media docena y corazones acerca firme, OTROS ligeramente superior.',
  '21/2DozenAndHeartsSlightlyHigherOthersAboutSteady':
    '2 media docena y CORAZONES ligeramente superior, a otros acerca estable.',
  '21/2DozenLowerOthersAboutSteady': '2 1/2 DOZEN LOWER, otros acerca estable.',
  '21/2DozenSlightlyHigherOthersAboutSteady': '2 1/2 DOZEN ligeramente superior, a otros acerca estable.',
  '21/2DozenSlightlyLowerOthersAboutSteady': '2 1/2 DOZEN ligeramente inferior, a otros acerca estable.',
  '21/2To3DozenAboutSteadyOthersSlightlyHigher': '2 1/2 a 3 DOZEN ACERCA firme, OTROS ligeramente superior.',
  '2-1/2To3DozenAboutSteadyOthersSlightlyHigher': '2-1 / 2 a 3 DOZEN ACERCA firme, OTROS ligeramente superior.',
  '2-21/2DozenAboutSteadyOthersSlightlyHigher': '2-2 1/2 DOZEN ACERCA firme, OTROS ligeramente superior.',
  '2-21/2DozenAboutSteadyOthersSlightlyLower': '2-2 1/2 DOZEN ACERCA CONSTANTE, otros ligeramente inferior.',
  '2-21/2DozenHigher3DozenSlightlyHigherHeartsAboutSteady':
    '2-2 1/2 DOZEN superior, 3 DOZEN ligeramente superior, corazones acerca estable.',
  '2-21/2DozenHigherOthersAboutSteady': '2-2 1/2 DOZEN Superior, a otros acerca estable.',
  '2-21/2DozenHigherOthersSlightlyHigher': '2-2 1/2 DOZEN superior, OTROS ligeramente superior.',
  '2-21/2DozenLowerOthersAboutSteady': '2-2 1/2 DOZEN LOWER, otros acerca estable.',
  '2-21/2DozenLowerOthersSlightlyLower': '2-2 1/2 DOZEN más bajo y otros ligeramente inferior.',
  '2-21/2DozenSlightlyHigherOthersAboutSteady': '2-2 1/2 DOZEN ligeramente superior, a otros acerca estable.',
  '2-21/2DozenSlightlyLower3DozenLowerHeartsAboutSteady':
    '2-2 1/2 DOZEN ligeramente inferior, 3 DOZEN LOWER, corazones acerca estable.',
  '2-21/2DozenSlightlyLowerOthersAboutSteady': '2-2 1/2 DOZEN ligeramente inferior, a otros acerca estable.',
  '2-21/2DozenSlightlyLowerOthersLower': '2-2 1/2 DOZEN ligeramente inferior, OTROS inferior.',
  '2-3DozenAboutSteadyOthersSlightlyLower': '2-3 DOZEN ACERCA CONSTANTE, otros ligeramente inferior.',
  '2-3DozenSlightlyHigherHeartsAboutSteady': '2-3 DOZEN ligeramente superior, corazones acerca estable.',
  '2-3DozenSlightlyHigherOthersAboutSteady': '2-3 DOZEN ligeramente superior, a otros acerca estable.',
  '2-3DozenSlightlyLowerOthersAboutSteady': '2-3 DOZEN ligeramente inferior, a otros acerca estable.',
  '28LbCartonHigher11LbCartonSteady': '28 LB LA CAJA SUPERIOR, 11 LB CARTÓN estable.',
  '28LbCartonLower11LbCartonSteady': '28 LB LA CAJA INFERIOR, 11 LB CARTÓN estable.',
  '28LbCartonsSluightlyHigher11LbSlightlyLower': '28 LB CARTONES SLUIGHTLY superior, 11 LB ligeramente inferior.',
  '28LbLargeSlightlyLowerOthersAboutSteadyFirmUndertoneForNextWeek':
    '28 LB GRANDE ligeramente inferior, a otros acerca de matiz FIRMA estable para la próxima semana.',
  '28LbSlightlyHigherOthersAboutSteady': '28 LB ligeramente superior, a otros acerca estable.',
  '28LbSlightlyLowerOthersAboutSteady': '28 LB ligeramente inferior, a otros acerca estable.',
  '28LbStandardAboutSteadyOthersSlightlyLower': '28 LB estándar sobre ESTABLIZADOS, otros ligeramente inferior.',
  '28PoundBunchedHigher11PoundAboutSteady': '28 LIBRA agrupado superior, 11 LIBRA SOBRE estable.',
  '28PoundBunchedLowerOthersAboutSteady': '28 LIBRA agrupado más bajo y otros ACERCA DE CONSTANTE.',
  '28PoundBunchedLowerOthersSteady': '28 LIBRA agrupado más bajo y otros CONSTANTE.',
  '28PoundBunchedSlightlyHigherOthersSteady': '28 LIBRA agrupado ligeramente superior, OTROS estable.',
  '28PoundBunchedSlightlyLower11PoundAboutSteady': '28 LIBRA agrupado ligeramente inferior, 11 LIBRA SOBRE estable.',
  '28PoundBunchedSlightlyLowerOthersAboutSteady': '28 LIBRA agrupado ligeramente inferior, a otros acerca estable.',
  '28PoundCartons/cratesHigher11PoundCartons/cratesAboutSteady':
    '28 CAJAS DE LIBRA / CRATES superior, 11 CAJAS DE LIBRA / cajas ACERCA DE CONSTANTE.',
  '28PoundCartonsHigherOthersAboutSteady': 'LIBRA 28 CAJAS superior, a otros acerca estable.',
  '28PoundCartonsLowerOthersAboutSteady': 'LIBRA 28 CAJAS más bajo y otros SOBRE estable.',
  '28PoundHigher11PoundAboutSteady': 'LIBRA 28 Superior, 11 LIBRA SOBRE estable.',
  '28PoundHigherOthersAboutSteady': 'LIBRA 28 superior, a otros acerca estable.',
  '28PoundHigherOthersSteady': 'LIBRA 28 superior, y otros CONSTANTE.',
  '28PoundLower11PoundAboutSteady': 'LIBRA 28 BAJA, 11 LIBRA SOBRE estable.',
  '28PoundLower11PoundSlightlyLower': 'LIBRA 28 BAJA, 11 LIBRA ligeramente inferior.',
  '28PoundLowerOthersSlightlyLower': '28 LIBRA más bajo y otros ligeramente inferior.',
  '28PoundPyramidCartons/cartesSlightlyHigherOthersAboutSteady':
    'LIBRA 28 PIRÁMIDE Cajas / Cartes ligeramente superior, a otros acerca estable.',
  '28PoundPyramidCartons/cratesSlightlyHigherOthersAboutSteady':
    'LIBRA 28 PIRÁMIDE cartones / cajas ligeramente superior, a otros acerca estable.',
  '28PoundPyramidCartons/cratesSlightlyLowerOthersAboutSteady':
    'LIBRA 28 PIRÁMIDE cartones / cajas ligeramente inferior, a otros acerca estable.',
  '28PoundSlightlyHigher11PoundLower': '28 LIBRA ligeramente superior, 11 LIBRA INFERIOR.',
  '28PoundSlightlyHigherOthersSteady': '28 LIBRA ligeramente superior, OTROS estable.',
  '28PoundSlightlyLower11PoundSlightlyHigher': '28 LIBRA ligeramente inferior, 11 LIBRA ligeramente superior.',
  '28PoundStandardAndLargeHigherOthersAboutSteady': 'LIBRA 28 estándar y en grandes Superior, a otros acerca estable.',
  '28PoundStandardAndLargeHigherOthersSteady': 'LIBRA 28 Normal y Grande superior, y otros estable.',
  '28PoundStandardAndLargeLowerOthersSteady': 'LIBRA 28 estándar y en grandes más bajo y otros estable.',
  '28PoundStandardAndLargeSlightlyHigherOthersSteady':
    'LIBRA 28 estándar y en grandes ligeramente superior, OTROS estable.',
  '28PoundStandardAndLargeSlightlyLowerOthersAboutSteady':
    'LIBRA 28 estándar y en grandes ligeramente inferior, a otros acerca estable.',
  '28PoundStandardAndLargeSlightlyLowerOthersSteady':
    'LIBRA 28 estándar y en grandes ligeramente inferior, OTROS estable.',
  '28PoundSteadyOthersLower': 'LIBRA 28 CONSTANTE, el resto en minúsculas.',
  '2And21/2DozenHigher3DozenSlightlyHigherHeartsAboutSteady':
    '2 Y 21/2 DOZEN superior, 3 DOZEN ligeramente superior, corazones acerca estable.',
  '2And21/2DozenHigher3DozenSlightlyHigherOthersAboutSteady':
    '2 Y 2 1/2 DOZEN superior, 3 DOZEN ligeramente superior, a otros acerca estable.',
  '2And21/2DozenHigherOthersSlightlyHigher': '2 y 2 1/2 DOZEN superior, OTROS ligeramente superior.',
  '2And21/2DozenLower3DozenSlightlyLowerHeartsAboutSteady':
    '2 Y 2 1/2 DOZEN LOWER, 3 DOZEN ligeramente inferior, corazones acerca estable.',
  '2And21/2DozenSlightlyHigherOthersAboutSteady': '2 Y 2 1/2 DOZEN ligeramente superior, a otros acerca estable.',
  '2And21/2DozenSlightlyLower3DozenLowerHeartsAboutSteady':
    '2 Y 2 1/2 DOZEN ligeramente inferior, 3 DOZEN LOWER, corazones acerca estable.',
  '2And21/2DozenSlightlyLowerOthersAboutSteady': '2 Y 2 1/2 DOZEN ligeramente inferior, a otros acerca estable.',
  '2And2-1/2DozenSlightlyLowerOthersAboutSteady': '2 Y 2-1 / 2 docenas ligeramente inferior, a otros acerca estable.',
  '2And3DozenAboutSteadyOthersSlightlyLower': '2 Y 3 DOZEN ACERCA CONSTANTE, otros ligeramente inferior.',
  '2And3DozenLowerOthersSlightlyLower': '2 Y 3 DOZEN más bajo y otros ligeramente inferior.',
  '2And3DozenSlightlyHigherOthersAboutSteady': '2 Y 3 DOZEN ligeramente superior, a otros acerca estable.',
  '2And4DozenAboutSteadyOthersSlightlyHigher': '2 Y 4 DOZEN ACERCA firme, OTROS ligeramente superior.',
  '2And4DozenSlightlyHigherOthersAboutSteady': '2 Y 4 DOZEN ligeramente superior, a otros acerca estable.',
  '2DozenAboutSteadyOthersSlightlyHigher': '2 docenas ACERCA DE OTROS firme, ligeramente superior.',
  '2DozenAboutSteadyOthersSlightlyLower': '2 docenas ACERCA DE CONSTANTE, otros ligeramente inferior.',
  '2DozenHigher3DozenSlightlyLowerOthersAboutSteady':
    '2 DOZEN superior, 3 DOZEN ligeramente inferior, a otros acerca estable.',
  '2DozenHigherHeartsAboutSteadyOthersSlightlyHigher':
    '2 docenas superior, corazones acerca firme, OTROS ligeramente superior.',
  '2DozenHigherOthersAboutSteady': '2 DOZEN Superior, a otros acerca estable.',
  '2DozenHigherOthersSlightlyHigher': '2 DOZEN SUPERIOR, otros ligeramente superior.',
  '2DozenLower21/2 -3DozenSlightlyLowerHeartsAboutSteady':
    '2 DOZEN LOWER, 2 1/2 -, 3 DOCENAS ligeramente inferior, corazones acerca estable.',
  '2DozenLower21/2DozenAboutSteadyOthersSlightlyLower':
    '2 DOZEN LOWER, 2 1/2 DOZEN ACERCA CONSTANTE, otros ligeramente inferior.',
  '2DozenLowerOthersAboutSteady': '2 DOZEN LOWER, otros acerca estable.',
  '2DozenLowerOthersSlightlyLower': '2 DOZEN más bajo y otros ligeramente inferior.',
  '2DozenSlighlyHigherOthersAboutSteady': '2 docenas ligeramente mayores, otros acerca estable.',
  '2DozenSlightlhyLowerOthersAboutSteady': '2 docenas de SLIGHTLHY más bajo y otros SOBRE estable.',
  '2DozenSlightlyHigherOthersAboutSteady': '2 docenas ligeramente superior, a otros acerca estable.',
  '2DozenSlightlyHigherOthersHigher': '2 DOZEN ligeramente superior, OTROS MAYOR.',
  '2DozenSlightlyLowerOthersAboutSteady': '2 docenas ligeramente inferior, a otros acerca estable.',
  '3DozenAboutSteadyOthersLower': '3 DOZEN ACERCA CONSTANTE, otros inferior.',
  '3DozenAboutSteadyOthersSlightlyHigher': '3 DOZEN ACERCA firme, OTROS ligeramente superior.',
  '3DozenAboutSteadyOthersSlightlyLower': '3 DOZEN ACERCA CONSTANTE, otros ligeramente inferior.',
  '3DozenAndHeartsSlightlyHigherOthersAboutSteady':
    '3 docena y CORAZONES ligeramente superior, a otros acerca estable.',
  '3DozenAndOrganicLowerOthersAboutSteady': '3 docena y orgánica inferior, a otros acerca estable.',
  '3DozenHigherHeartsAboutSteadyOthersSlightlyHigher':
    '3 DOZEN superior, corazones acerca firme, OTROS ligeramente superior.',
  '3DozenHigherOthersAboutSteady': '3 DOZEN Superior, a otros acerca estable.',
  '3DozenHigherOthersSlightlyHigher': '3 DOZEN SUPERIOR, otros ligeramente superior.',
  '3DozenLower2DozenAboutSteadyOthersSlightlyLower':
    '3 DOZEN LOWER, 2 DOZEN ACERCA CONSTANTE, otros ligeramente inferior.',
  '3DozenLowerOthersAboutSteady': '3 DOZEN LOWER, otros acerca estable.',
  '3DozenLowerOthersSlightlyLower': '3 DOZEN más bajo y otros ligeramente inferior.',
  '3DozenSlightlyHigherOrganic2DozenSlightlyLowerOthersAboutSteady':
    '3 DOZEN ligeramente superior, ORGANIC 2 DOZEN ligeramente inferior, a otros acerca estable.',
  '3DozenSlightlyHigherOthersAboutSteady': '3 DOZEN ligeramente superior, a otros acerca estable.',
  '3DozenSlightlyLowerHeartsAboutSteadyOthersLower':
    '3 DOZEN ligeramente inferior, corazones acerca CONSTANTE, otros LOWER.',
  '3DozenSlightlyLowerOthersAboutSteady': '3 DOZEN ligeramente inferior, a otros acerca estable.',
  '4DozenAboutSteadyHeartsSlightlyLowerOthersLower':
    '4 DOZEN ACERCA CONSTANTE, corazones ligeramente inferior, OTROS inferior.',
  '4DozenAboutSteadyOthersSlightlyHigher': '4 DOZEN ACERCA firme, OTROS ligeramente superior.',
  '4DozenAndHeartsAboutSteadyOthersSlightlyHigher': '4 docena y corazones acerca firme, OTROS ligeramente superior.',
  '4DozenHigherHeartsSlightlyHigherOthersAboutSteady':
    '4 DOZEN SUPERIOR, corazones ligeramente superior, a otros acerca estable.',
  '4DozenHigherOthersSlightlyHigher': '4 DOZEN SUPERIOR, otros ligeramente superior.',
  '4DozenSlightlyHigherOthersAboutSteady': '4 DOZEN ligeramente superior, a otros acerca estable.',
  '4DozenSlightlyLowerOthersAboutSteady': '4 DOZEN ligeramente inferior, a otros acerca estable.',
  '6OunceConventionalDemoralizedOthersBarelySteady': '6 onzas CONVENCIONAL Desmoralizados, otros apenas estable.',
  '6OunceConventionalLowerOthersBarelySteady': '6 onzas CONVENCIONAL LOWER, otros apenas estable.',
  '6-ounceConventionalSlightlyHigherOthersAboutSteady':
    '6 onzas CONVENCIONAL ligeramente superior, a otros acerca estable.',
  '6-ounceCupsConventionalAboutSteadyOthersLower': 'CUPS 6 onzas convencional sobre CONSTANTE, otros inferior.',
  '6-ounceCupsSlightlyHigherOthersAboutSteady': 'CUPS 6 onzas ligeramente superior, a otros acerca estable.',
  '6-ounceCupsSlightlyHigherPintsAboutSteady': 'CUPS 6 onzas ligeramente superior, PINTS SOBRE estable.',
  '6-ounceCupsSlightlyLowerOthersAboutSteady': 'CUPS 6 onzas ligeramente inferior, a otros acerca estable.',
  '6OunceHigherPintsFirm': '6 onzas FIRM superior, pintas.',
  '6OunceHigherPintsLower': '6 onzas superior, PINTS LOWER.',
  '6OunceHigherPintsSteady': '6 onzas superior, PINTS constante.',
  '6OunceLowerPintsAboutSteady': '6 onzas LOWER, PINTS ACERCA constante.',
  '6OunceLowerPintsSteady': '6 onzas LOWER, PINTS constante.',
  '6-ounceSlightlyHigherPintsAboutSteady': '6 onzas ligeramente superior, PINTS SOBRE estable.',
  '6OunceSlightyHigherOthersAboutSteady': '6 onzas levemente superior, a otros acerca estable.',
  '6-ozLowerPintsSteady': '6-OZ inferior; PINTS constante.',
  '6OzSlightlyHigherOthersAboutSteady': '6 OZ ligeramente superior, a otros acerca estable.',
  aboutConventional6OunceLowerOthersAboutSteady: 'ACERCA CONVENCIONAL 6 onzas más bajo y otros ACERCA DE CONSTANTE.',
  aboutSteady: 'ACERCA DE CONSTANTE.',
  aboutSteadyFirmUndertone: 'Undertone firme sobre CONSTANTE.',
  barelySteady: 'APENAS estable.',
  bestConventionalHigherBestOrganicSlightlyHigher: 'MEJOR CONVENCIONAL SUPERIOR, MEJOR ORGÁNICA ligeramente superior.',
  conventioanls6OzAboutSteadyOthersSlightlyHigher:
    'CONVENTIOANLS 6 OZ ACERCA DE CONSTANTE, otros ligeramente superior.',
  'conventional121-pintsAnd6-ounceCupsLowerOthersAboutSteady':
    '12 convencional 1-pintas y CUPS 6 onzas LOWER, otros acerca estable.',
  'conventional121-pintsHigherOthersAboutSteady': 'CONVENCIONAL 12 1-PINTS Superior, a otros acerca estable.',
  'conventional121-pintsSlightlyLowerOthersAboutSteady':
    'CONVENCIONAL 12 1-PINTS ligeramente inferior, a otros acerca estable.',
  'conventional12-6OzCupsWithLidsLowerOthersAboutSteady':
    'Ventosas tradicionales 12-6 onzas con tapas más bajas, otros acerca estable.',
  'conventional1-pintsSlightlyHigherOthersAboutSteady':
    'Convencional 1-PINTS ligeramente superior, a otros acerca estable.',
  conventional6OunceAboutSteadyOthersSlightlyLower:
    'CONVENCIONAL 6 onzas ACERCA DE CONSTANTE, otros ligeramente inferior.',
  conventional6OunceAboutStedayOthersSlightlyHigher: 'CONVENCIONAL 6 onzas SOBRE STEDAY, otros ligeramente superior.',
  'conventional6-ounceCupsLowerOthersAboutSteady': 'CONVENCIONALES CUPS 6 onzas inferior, a otros acerca estable.',
  conventional6OunceHigherOthersAboutSteady: 'CONVENCIONAL 6 onzas superior, a otros acerca estable.',
  conventional6OunceLowerOthersBarelySteady: 'CONVENCIONAL 6 onzas INFERIOR, otros apenas estable.',
  conventional6OunceLowerOthersSteady: 'CONVENCIONAL 6 onzas más bajo y otros estable.',
  conventional6OunceSlightlyLowerOrganicSlightlyHigherOthersAboutSteady:
    'CONVENCIONAL 6 onzas ligeramente inferior, ORGÁNICOS ligeramente superior, a otros acerca estable.',
  conventional6OunceSlightlyLowerOthersAboutSteady:
    'CONVENCIONAL 6 onzas ligeramente inferior, a otros acerca estable.',
  conventional6OzAboutSteadyOthersSlightlyHigher: 'CONVENCIONAL 6 OZ ACERCA DE CONSTANTE, otros ligeramente superior.',
  conventional6OzSlightlyHigherOthersAboutSteady: 'CONVENCIONAL 6 OZ ligeramente superior, a otros acerca estable.',
  'conventional6-ozSlightlyHigherOthersSteady': 'CONVENCIONAL 6-OZ SLIGHTLY superior; OTROS constante.',
  conventional6OzSlightlyLowerOthersAboutSteady: 'CONVENCIONAL 6 OZ ligeramente inferior, a otros acerca estable.',
  'conventional6-ozSlightlyLowerOthersSteady': 'CONVENCIONAL 6-OZ SLIGHTLY inferior; OTROS constante.',
  'conventional6-ozSteadyOthersHigher': 'CONVENCIONAL 6-OZ CONSTANTE; OTROS SUPERIOR.',
  conventionalAboutSteady: 'Convencional acerca constante.',
  conventionalAboutSteadyOrganicHigher: 'Convencional acerca constante, ORGÁNICA MAYOR.',
  conventionalAboutSteadyOrganicLower: 'Convencional acerca constante, orgánica inferior.',
  conventionalAboutSteadyOrganicSlightlyHigher: 'Convencional sobre CONSTANTE, ORGÁNICA ligeramente superior.',
  conventionalAboutSteadyOrganicSlightlyLower: 'Convencional sobre CONSTANTE, ORGÁNICA ligeramente inferior.',
  conventionalAboutSteadyOrganicSteady: 'Convencional sobre CONSTANTE, ORGÁNICA estable.',
  conventionalBarelySteadyOrganicSteady: 'CONVENCIONAL APENAS CONSTANTE, ORGÁNICA estable.',
  'conventionalFlats126-ounceCupsSlightlyLowerOthersAboutSteady':
    'CONVENCIONAL PISOS 12 tazas de 6 onzas ligeramente inferior, a otros acerca estable.',
  conventionalHigherOrganicAboutSteady: 'CONVENCIONAL superior, es orgánico estable.',
  conventionalHigherOrganicBarelySteady: 'CONVENCIONAL superior, ORGÁNICA APENAS constante.',
  conventionalHigherOrganicLower: 'CONVENCIONAL superior, orgánica inferior.',
  conventionalHigherOrganicsAboutSteady: 'CONVENCIONAL superior; ORGÁNICA SOBRE estable.',
  conventionalHigherOrganicSlightlyHigher: 'CONVENCIONAL superior, ORGÁNICA ligeramente superior.',
  conventionalHigherOrganicSteady: 'CONVENCIONAL superior, ORGÁNICA estable.',
  conventionalLargeLowerOthersAboutSteady: 'CONVENCIONAL inferior grande, otros acerca estable.',
  conventionalLower: 'CONVENCIONAL INFERIOR.',
  conventionalLowerOrganicAboutSteady: 'CONVENCIONAL INFERIOR, ORGÁNICA SOBRE estable.',
  conventionalLowerOrganicBarelySteady: 'CONVENCIONAL INFERIOR, ORGÁNICA APENAS constante.',
  conventionalLowerOrganicSlightlyLower: 'CONVENCIONAL INFERIOR, ORGÁNICA ligeramente inferior.',
  conventionalLowerOrganicSteady: 'CONVENCIONAL INFERIOR, ORGÁNICA estable.',
  conventionalLowerOthersBarelySteady: 'CONVENCIONAL INFERIOR, otros apenas constante.',
  conventionalLowerOthersSteady: 'CONVENCIONAL inferior; OTROS constante.',
  conventionalMuchHigherOrganicAboutSteady: 'CONVENCIONAL mucho más alto, es orgánico estable.',
  conventionalMuchHigherOrganicHigher: 'CONVENCIONAL mucho más alta, orgánicos por encima.',
  conventionalMuchHigherOrganicSteady: 'CONVENCIONAL mucho mayor, ORGÁNICA estable.',
  conventionalMuchLowerOrganicAboutSteady: 'CONVENCIONAL mucho más bajo, es orgánico estable.',
  conventionalMuchLowerOrganicBarelySteady: 'CONVENCIONAL mucho más bajo, ORGÁNICA APENAS estable.',
  conventionalMuchLowerOrganicSlightlyLower: 'CONVENCIONAL mucho más bajo, ORGÁNICA ligeramente inferior.',
  conventionalMuchLowerOrganicSteady: 'CONVENCIONAL mucho más bajo, ORGÁNICA estable.',
  conventionalPintHigherOthersSteady: 'CONVENCIONAL PINT superior; OTROS constante.',
  conventionalPintsAboutSteadyOthersLower: 'PINTS convencional sobre CONSTANTE, otros más bajos.',
  'conventionalPintsAnd6-ounceCupsAboutSteadyOthersSlightlyLower':
    'CONVENCIONAL pintas y 6 onzas CUPS SOBRE firme, OTROS ligeramente inferior.',
  'conventionalPintsAnd6-ounceCupsSlightlyHigherOthersAboutSteady':
    'CONVENCIONAL pintas y CUPS 6 onzas ligeramente superior, a otros acerca estable.',
  'conventionalPintsAnd6-ounceCupsSlightlyLowerOthersAboutSteady':
    'CONVENCIONAL pintas y CUPS 6 onzas ligeramente inferior, a otros acerca estable.',
  conventionalPintsAndOrganic6OunceSlightlyLowerOthersAboutSteady:
    'CONVENCIONAL pintas y ORGÁNICA 6 onzas ligeramente inferior, a otros acerca estable.',
  'conventionalPintsAndOrganic6-ozHigherConventional6-ozAboutSteady':
    'CONVENCIONAL PINTS y ​​orgánicos 6-OZ superior; CONVENCIONAL 6-OZ ACERCA estable.',
  conventionalPintsAndOrganic6OzHigherOthersAboutSteady:
    'CONVENCIONAL pintas y ORGÁNICA 6 OZ superior, a otros acerca estable.',
  conventionalPintsHigherOthersSlightlyHigher: 'CONVENCIONAL PINTS superior, y otros ligeramente superior.',
  conventionalPintSlighltyHigherOthersAboutSteady: 'CONVENCIONAL PINT slighlty superior; Otros acerca constante.',
  conventionalPintSlightlyHigherOthersSteady: 'CONVENCIONAL PINT SLIGHTLY superior; OTROS constante.',
  conventionalPintsLower6OunceSlightlyLowerOrganicBarelySteady:
    'CONVENCIONAL PINTS INFERIOR, 6 onzas ligeramente inferior, ORGÁNICA APENAS estable.',
  conventionalPintsLowerOthersAboutSteady: 'CONVENCIONAL PINTS más bajo y otros SOBRE constante.',
  conventionalPintsLowerOthersBarelySteady: 'CONVENCIONAL PINTS INFERIOR, otros apenas constante.',
  conventionalPintsLowerOthersSteady: 'CONVENCIONAL PINTS inferior; OTROS constante.',
  conventionalPintsSlightlyHigherOthersAboutSteady:
    'PINTS CONVENCIONALES ligeramente superior, a otros acerca estable.',
  conventionalPintsSlightlyHigherOthersSteady: 'PINTS CONVENCIONALES ligeramente superior; OTROS constante.',
  conventionalPintsSlightlyLowerOthersAboutSteady: 'PINTS CONVENCIONALES ligeramente inferior, a otros acerca estable.',
  conventionalPintsSlightlyLowerOthersSteady: 'PINTS CONVENCIONALES ligeramente inferior; OTROS constante.',
  conventionalSlightlyHigher: 'CONVENCIONAL ligeramente superior.',
  conventionalSlightlyHigherOrganicAboutSteady: 'CONVENCIONAL ligeramente superior, es orgánico estable.',
  conventionalSlightlyHigherOrganicLower: 'CONVENCIONAL ligeramente superior, orgánica inferior.',
  conventionalSlightlyHigherOrganicSlightlyLower: 'CONVENCIONAL ligeramente superior, ORGÁNICA ligeramente inferior.',
  conventionalSlightlyHigherOrganicSteady: 'CONVENCIONAL ligeramente superior, ORGÁNICA estable.',
  conventionalSlightlyLowerOrganicAboutSteady: 'CONVENCIONAL ligeramente inferior, es orgánico estable.',
  conventionalSlightlyLowerOrganicBarelySteady: 'CONVENCIONAL ligeramente inferior, ORGÁNICA APENAS estable.',
  conventionalSlightlyLowerOrganicLower: 'CONVENCIONAL ligeramente inferior, orgánica inferior.',
  conventionalSlightlyLowerOrganicSlightlyHigher: 'CONVENCIONAL ligeramente inferior, ORGÁNICA ligeramente superior.',
  conventionalSlightlyLowerOrganicSteady: 'CONVENCIONAL ligeramente inferior, ORGÁNICA estable.',
  conventionalSlightlyLowerOthersAboutSteady: 'CONVENCIONAL ligeramente inferior, a otros acerca estable.',
  conventionalStandardAndLargeLowerOthersAboutSteady:
    'CONVENCIONAL estándar y en grandes INFERIOR, otros acerca estable.',
  conventionalStandardLowerOthersSteady: 'CONVENCIONAL estándar más bajo, otros constante.',
  conventionalSteadyOrganicAboutSteady: 'CONVENCIONAL CONSTANTE, es orgánico estable.',
  coventionalHigherOthersSteady: 'Coventional superior, y otros constante.',
  fairlyGood: 'BASTANTE BUENO.',
  firm: 'FIRMA.',
  firstReport: 'PRIMER INFORME.',
  'flats121-pintBasketsAboutSteadyOthersHigher': 'PISOS 12 canastas 1-PINT ACERCA firme, OTROS MAS.',
  'flats121-pintBasketsAboutSteadyOthersLower': 'PISOS 12 canastas 1-PINT SOBRE CONSTANTE, otros inferior.',
  'flats121-pintBasketsHigherOthersAboutSteady': 'PISOS 12 canastas 1-PINT Superior, a otros acerca estable.',
  'flats121-pintBasketsHigherOthersSteady': 'PISOS 12 CESTAS 1-PINT SUPERIOR, otros estable.',
  'flats121-pintBasketsLowerOthersSteady': 'PISOS 12 canastas 1-PINT más bajo y otros estable.',
  'flats121-pintBasketsSteadyOthersHigher': 'PISOS 12 canastas 1-PINT firme, OTROS MAS.',
  'flats121-pintBasketsSteadyOthersLower': 'PISOS 12 canastas 1-PINT firme, OTROS inferior.',
  'flats121-pintBasketsSteadyOthersSlightlyLower': 'PISOS 12 canastas 1-PINT firme, OTROS ligeramente inferior.',
  'flats121-pintCupsWithLidsLowerOthersAboutSteady':
    'PISOS 12 tazas 1 pinta con tapas inferior, a otros acerca estable.',
  'flats121-pintCupsWithLidsSlightlyHigherOthersAboutSteady':
    'PISOS 12 tazas 1 pinta con tapas ligeramente superior, a otros acerca estable.',
  'flats126-ounceCupsSlightlyHigherOthersAboutSteady':
    'PISOS 12 tazas de 6 onzas ligeramente superior, a otros acerca estable.',
  'flats126-ounceCupsSlightlyLowerPintsAboutSteady':
    'PISOS 12 tazas de 6 onzas ligeramente inferior, PINTS SOBRE estable.',
  'flats126-ounceCupsWithLidsHigherOthersAboutSteady':
    'PISOS 12 tazas de 6 onzas con tapas más altas, a otros acerca estable.',
  'flats126-ounceCupsWithLidsHigherOthersSteady': 'PISOS 12 tazas de 6 onzas con tapa superior, OTROS estable.',
  'flats126-ounceCupsWithLidsLowerOthersAboutSteady':
    'PISOS 12 tazas de 6 onzas con tapas inferior, a otros acerca estable.',
  'flats126-ounceCupsWithLidsSlightlyHigherPintsAboutSteady':
    'PISOS 12 tazas de 6 onzas con tapas ligeramente superior, PINTS SOBRE estable.',
  'flats126-ounceSlightlyHigherOthersAboutSteady': 'PISOS 12 de 6 onzas ligeramente superior, a otros acerca estable.',
  'flats81-poundContainersLowerOthersAboutSteady': 'PISOS 8 1 libras CONTAINERS LOWER, otros acerca estable.',
  'flats81-poundContainersLowerOthersSteady': 'PISOS 8 1 libras CONTAINERS más bajo y otros estable.',
  'flats81-poundContainersWithLidsHigherOthersSteady':
    'PISOS 8 1 libras recipientes con tapas superior, OTROS estable.',
  'flats81-poundContainersWithLidsLowerOthersAboutSteady':
    'PISOS 8 1 libras recipientes con tapas LOWER, otros acerca estable.',
  'flats81-poundContainersWithLidsLowerOthersSteady':
    'PISOS 8 1 libras recipientes con tapas más bajo y otros estable.',
  'flats81-poundContainersWithLidsSlightlyHigherOthersSteady':
    'PISOS 8 1 libras recipientes con tapas ligeramente superior, OTROS estable.',
  'flats81-poundContainerswithLidsSlightlyHigherOthersSteady':
    'PISOS 8 1 libras CONTAINERSWITH LIDS ligeramente superior, OTROS estable.',
  'flats81-poundContainersWithLidsSlightlyLowerOrganicAboutSteady':
    'PISOS 8 1 libras recipientes con tapas ligeramente inferior, ORGANIC ACERCA estable.',
  'flats81-poundContainersWithLidsSlightlyLowerOthersAboutSteady':
    'PISOS 8 1 libras recipientes con tapas ligeramente inferior, a otros acerca estable.',
  'flats81-poundContainersWithLidsSlightlyLowerOthersSteady':
    'PISOS 8 1 libras recipientes con tapas ligeramente inferior, OTROS estable.',
  'flats81-poundContainersWithLidsSmall-mediumLowerOthersAboutSteady':
    'PISOS 8 1 libras recipientes con tapas pequeñas y medianas LOWER, otros acerca estable.',
  'fltas121-pintBasketsLowerOthersSteady': 'FLTAS 12 canastas 1-PINT más bajo y otros estable.',
  'flts121-pintCupsLower6-ounceAboutSteady': 'FLTS 12 1-PINT CUPS LOWER, de 6 onzas ACERCA estable.',
  heartsAboutSteady2DozenSlighltyLowerOthersLower:
    'Corazones acerca CONSTANTE, 2 docenas de slighlty más bajo y otros INFERIOR.',
  heartsAboutSteady3DozenSlightlyLowerOthersLower:
    'Corazones acerca CONSTANTE, 3 DOCENA ligeramente inferior, el resto en minúsculas.',
  heartsAboutSteadyOthersHigher: 'Corazones acerca ESTABLIZADOS, otros más elevados.',
  heartsAboutSteadyOthersLower: 'Corazones acerca CONSTANTE, otros más bajos.',
  heartsAboutSteadyOthersSlghtlyLower: 'Corazones acerca firme, OTROS slghtly inferior.',
  heartsAboutSteadyOthersSlightlyHigher: 'Corazones acerca firme, OTROS ligeramente superior.',
  heartsAboutSteadyOthersSlightlyLower: 'Corazones acerca firme, OTROS ligeramente inferior.',
  'heartsAnd21/2DozenSlightlyLower3DozenLowerOthersSlightlyHigher':
    'CORAZONES Y 2 1/2 DOZEN ligeramente inferior, 3 DOZEN inferior, OTROS ligeramente superior.',
  'heartsAnd21/2DozenSlightlyLowerOthersAboutSteady':
    'CORAZONES Y 2 1/2 DOZEN ligeramente inferior, a otros acerca estable.',
  heartsAnd4DozenAboutSteadyOthersSlightlyHigher: 'CORAZONES Y 4 docenas ACERCA DE OTROS firme, ligeramente superior.',
  heartsHigherOthersSlightlyHigher: 'CORAZONES superior, y otros ligeramente superior.',
  heartsLower2DozenSlightlyLowerOthersAboutSteady:
    'CORAZONES BAJA, 2 docenas ligeramente inferior, a otros acerca estable.',
  heartsLower3DozenSlightlyLowerOthersAboutSteady:
    'CORAZONES BAJA, 3 DOCENA ligeramente inferior, a otros acerca estable.',
  heartsLowerOthersAboutSteady: 'CORAZONES más bajo y otros SOBRE estable.',
  heartsLowerOthersMuchLower: 'CORAZONES más bajo y otros mucho más bajo.',
  heartsLowerOthersSlightlyLower: 'CORAZONES más bajo y otros ligeramente inferior.',
  heartsSlightlyHigher3DozenSlightlyLowerOthersAboutSteady:
    'CORAZONES ligeramente superior, 3 DOCENA ligeramente inferior, a otros acerca estable.',
  heartsSlightlyHigherOrganicHeartsSlightlyLowerOthersAboutSteady:
    'CORAZONES ligeramente superior, CORAZONES orgánicos: ligeramente más bajo y otros SOBRE estable.',
  heartsSlightlyHigherOthersAboutSteady: 'CORAZONES ligeramente superior, a otros acerca estable.',
  heartsSlightlyHigherOthersHigher: 'CORAZONES ligeramente superior, OTROS SUPERIOR.',
  heartsSlightlyLower2DozenAboutSteadyOthersLower:
    'CORAZONES ligeramente inferior, 2 docenas ACERCA DE CONSTANTE, el resto en minúsculas.',
  heartsSlightlyLower3DozenAboutSteadyOthersLower:
    'CORAZONES ligeramente inferior, 3 DOCENA ACERCA DE CONSTANTE, el resto en minúsculas.',
  heartsSlightlyLowerBunchedAboutSteady: 'CORAZONES ligeramente inferior, agrupados ACERCA DE CONSTANTE.',
  heartsSlightlyLowerOthersAboutSteady: 'CORAZONES ligeramente inferior, a otros acerca estable.',
  heartsSlightlyLowerOthersLower: 'CORAZONES ligeramente inferior, el resto en minúsculas.',
  heartsSlightlyLowerOthersLowerOrganicHeartsSlightlyLowerOthersMuchLower:
    'CORAZONES ligeramente inferior, OTROS inferior; CORAZONES orgánicos: ligeramente más bajo y otros mucho más bajo.',
  higher: 'MAYOR.',
  jumboAboutSteadyOthersSlightlyLower: 'JUMBO ACERCA DE CONSTANTE, otros ligeramente inferior.',
  jumboAndLargeLowerOthersSteady: 'JUMBO Y GRANDE más bajo y otros constante.',
  jumboAndLargeSizeHigherOthersSteady: 'JUMBO Y GRAN TAMAÑO superior, y otros constante.',
  jumboLowerOthersAboutSteady: 'JUMBO más bajo y otros ACERCA DE CONSTANTE.',
  jumboLowerOthersHigher: 'JUMBO más bajo y otros SUPERIOR.',
  jumboSlightlyLowerOthersAboutSteady: 'JUMBO ligeramente inferior, a otros acerca estable.',
  jumboSteadyOthersLower: 'JUMBO CONSTANTE, el resto en minúsculas.',
  largeAboutSteadyOthersLower: 'ACERCA DE GRAN CONSTANTE, el resto en minúsculas.',
  largeAndJumboHigherOthersAboutSteady: 'GRANDE Y JUMBO superior, a otros acerca estable.',
  largeHigherOthersSteady: 'GRAN superior, y otros CONSTANTE.',
  largeSlightlyHigherOthersAboutSteady: 'GRAN ligeramente superior, a otros acerca estable.',
  largeSlightlyLowerOthersAboutSteady: 'GRAN ligeramente inferior, a otros acerca estable.',
  largeSlightlyLowerStandardSteady: 'GRAN ligeramente inferior, ESTÁNDAR estable.',
  lower: 'INFERIOR.',
  mediumConventionalAboutSteadyOthersSlightlyLower: 'MEDIO convencional sobre CONSTANTE, otros ligeramente inferior.',
  'mediumConventionalSlightlyHigherMedium-largeOrganicSlightlyLowerOthersAboutSteady':
    'Medio convencional ligeramente superior, tamaño medio-grande ORGÁNICA ligeramente inferior, a otros acerca estable.',
  mediumConventionalSlightlyHigherOthersAboutSteady: 'Medio convencional ligeramente superior, a otros acerca estable.',
  'medium-largeConventionalSlightlyHigherOthersSteady':
    'Medio de gran CONVENCIONAL ligeramente superior, OTROS estable.',
  'medium-largeHigherOthersAboutSteady': 'Medio de gran SUPERIOR, otros acerca estable.',
  'medium-largeLowerOthersSteady': 'MEDIANO GRANDE más bajo y otros CONSTANTE.',
  'medium-largeSlightlyHigherOthersSteady': 'Medio de gran ligeramente superior, OTROS estable.',
  'mediumSlightlyHigherSmall-mediumAboutSteady': 'MEDIO ligeramente superior, pequeñas y medianas ACERCA DE CONSTANTE.',
  moderate: 'MODERAR.',
  muchHigher: 'MUCHO MÁS ALTO.',
  muchLower: 'MUCHO MÁS BAJO.',
  'organic12-1PintsLowerOthersAboutSteady': 'ORGÁNICA 12-1 PINTS INFERIOR., Otros acerca estable.',
  'organic12-6OunceLowerConventionalPintsSlightlyLowerOthersAboutSteady':
    'ORGÁNICA 12-6 ONZA INFERIOR, CONVENCIONAL PINTS ligeramente inferior, a otros acerca estable.',
  organic2DozenAndHeartsSlightlyLowerOthersAboutSteady:
    'ORGÁNICA 2 docena y CORAZONES ligeramente inferior, a otros acerca estable.',
  organic6ozAboutSteadyOthersSlightlyLower: 'ORGÁNICA SOBRE 6OZ CONSTANTE, otros ligeramente inferior.',
  'organic6-ozHigherOthersSteady': 'ORGANIC 6-OZ superior; OTROS constante.',
  organic6OzSlightlyHigherOthersAboutSteady: 'ORGÁNICA 6 OZ ligeramente superior, a otros acerca estable.',
  organicAboutSteadyConventionalSlightlyLower: 'ORGÁNICA SOBRE CONSTANTE, CONVENCIONAL ligeramente inferior.',
  organicAboutSteadyOhtersLower: 'ORGÁNICA SOBRE CONSTANTE, OHTERS INFERIOR.',
  organicAboutSteadyOtherLower: 'ORGÁNICA SOBRE CONSTANTE, OTRA BAJA.',
  organicAboutSteadyOthersHigher: 'ORGÁNICA SOBRE CONSTANTE, otros SUPERIOR.',
  organicAboutSteadyOthersLower: 'ORGÁNICA SOBRE CONSTANTE, el resto en minúsculas.',
  organicAboutSteadyOthersSlightlyHigher: 'ORGÁNICA SOBRE CONSTANTE, otros ligeramente superior.',
  organicAboutSteadyOthersSlightlyLower: 'ORGÁNICA SOBRE CONSTANTE, otros ligeramente inferior.',
  organicAndHeartsAboutSteadyOthersSlightlyHigher: 'ORGÁNICA Y CORAZONES SOBRE OTROS firme, ligeramente superior.',
  'organicAndMedium-largeAboutSteadyOthersSlightlyLower':
    'ORGÁNICA Y ACERCA DE MEDIANO GRANDE CONSTANTE, otros ligeramente inferior.',
  organicBarelySteadyConventionalAboutSteady: 'ORGÁNICA APENAS CONSTANTE, convencional sobre CONSTANTE.',
  organicBarelySteadyConventionalSteady: 'ORGÁNICA APENAS CONSTANTE, CONVENCIONAL estable.',
  organicGoodOthersSteady: 'Orgánica Bueno, OTROS estable.',
  organicHigherConventionalAboutSteady: 'ORGÁNICA superior, convencional sobre CONSTANTE.',
  organicHigherConventionalFirm: 'ORGÁNICA MAYOR, FIRMA convencional.',
  organicHigherConventionalLower: 'ORGÁNICA SUPERIOR, INFERIOR CONVENCIONAL.',
  organicHigherConventionalSlightlyHigher: 'ORGÁNICA MAYOR, CONVENCIONAL ligeramente superior.',
  organicHigherConventionalSlightlyLower: 'ORGÁNICA MAYOR, CONVENCIONAL ligeramente inferior.',
  organicHigherConventionalSteady: 'ORGÁNICA MAYOR, CONVENCIONAL estable.',
  organicHigherOthersAboutSteady: 'ORGÁNICA superior, a otros acerca estable.',
  organicHigherOthersLower: 'ORGÁNICA superior, y otros más bajos.',
  organicHigherOthersSlightlyHigher: 'ORGÁNICA superior, y otros ligeramente superior.',
  organicHigherOthersSlightlyLower: 'ORGÁNICA superior, y otros ligeramente inferior.',
  organicHigherOthersSteady: 'ORGÁNICA superior, y otros constante.',
  organicLower: 'Orgánica inferior.',
  'organicLower2-21/2DozenSlightlyLowerOthersAboutSteady':
    'Orgánica inferior, 2-2 1/2 docena ligeramente inferior, a otros acerca estable.',
  'organicLower2-3DozenSlightlyLowerHeartsAboutSteady':
    'Orgánica inferior, 2-3 DOZEN ligeramente inferior, corazones acerca estable.',
  organicLowerConventionalAboutSteady: 'Orgánica inferior, convencional sobre CONSTANTE.',
  organicLowerConventionalBarelySteady: 'Orgánica inferior, CONVENCIONAL APENAS constante.',
  organicLowerConventionalSlightlyLower: 'Orgánica inferior, CONVENCIONAL ligeramente inferior.',
  organicLowerConventionalSteady: 'Orgánica inferior, CONVENCIONAL estable.',
  organicLowerOtherAboutSteady: 'Orgánica inferior, sí acerca estable.',
  organicLowerOthersAboutSteady: 'Orgánica inferior, otros acerca constante.',
  organicLowerOthersBarelySteady: 'Orgánica inferior, otros apenas constante.',
  organicLowerOthersFirm: 'Orgánica inferior, otros FIRM.',
  organicLowerOthersGood: 'Orgánica inferior, bien a otros.',
  organicLowerOthersSlightlyHigher: 'Orgánica inferior, otros ligeramente superior.',
  organicLowerOthersSteady: 'Orgánica inferior, OTROS constante.',
  organicLowerOtherSteady: 'Orgánica inferior, OTRAS estable.',
  organicMuchLowerOthersAboutSteady: 'ORGÁNICA mucho más bajo, otros acerca estable.',
  organicPintsAboutSteadyOthersSlightlyHigher: 'PINTS ORGÁNICA SOBRE OTROS firme, ligeramente superior.',
  organicPintsHigherOthersAboutSteady: 'ORGANIC PINTS superior; Otros acerca constante.',
  organicPintsSlightlyLowerOthersAboutSteady: 'PINTS orgánicos: ligeramente más bajo y otros SOBRE estable.',
  organicsAboutSteadyOthersLower: 'ORGÁNICA SOBRE CONSTANTE, otros más bajos.',
  organicsAboutSteadyOthersSlightlyHigher: 'ORGÁNICA SOBRE OTROS firme, ligeramente superior.',
  organicSlightlyHigherConventionalAboutSteady: 'ORGÁNICA ligeramente superior, convencional sobre CONSTANTE.',
  organicSlightlyHigherConventionalSlightlyLower: 'ORGÁNICA ligeramente superior, CONVENCIONAL ligeramente inferior.',
  organicSlightlyHigherConventionalSteady: 'ORGÁNICA ligeramente superior, CONVENCIONAL estable.',
  organicSlightlyHigherOthersAboutSteady: 'ORGÁNICA ligeramente superior, a otros acerca estable.',
  organicSlightlyHigherOthersSlightlyLower: 'ORGÁNICA ligeramente superior, OTROS ligeramente inferior.',
  organicSlightlyHigherOthersSteady: 'ORGÁNICA ligeramente superior, OTROS estable.',
  organicSlightlyLowerConventionalAboutSteady: 'ORGÁNICA ligeramente inferior, convencional sobre CONSTANTE.',
  organicSlightlyLowerConventionalSteady: 'ORGÁNICA ligeramente inferior, CONVENCIONAL estable.',
  organicSlightlyLowerOthersAboutSteady: 'ORGÁNICA ligeramente inferior, a otros acerca estable.',
  organicSlightlyLowerOthersSlightlyHigher: 'Orgánicos: ligeramente más bajo y otros ligeramente superior.',
  organicSlightlyLowerOthersSteady: 'Orgánicos: ligeramente más bajo y otros CONSTANTE.',
  organicSliightlyHigherOthersAboutSteady: 'ORGÁNICA SLIIGHTLY superior, a otros acerca constante.',
  organicSteadyConventionalAboutSteady: 'ORGÁNICA CONSTANTE, convencional sobre CONSTANTE.',
  organicSteadyConventionalLower: 'ORGÁNICA constante, CONVENCIONAL INFERIOR.',
  organicSteadyOtherLower: 'ORGÁNICA CONSTANTE, OTRA BAJA.',
  organicSteadyOthersAboutSteady: 'ORGÁNICA CONSTANTE, otros acerca estable.',
  organicSteadyOthersHigher: 'ORGÁNICA CONSTANTE, otros SUPERIOR.',
  organicSteadyOtherSlightlyHigher: 'ORGÁNICA CONSTANTE, OTRAS ligeramente superior.',
  organicSteadyOthersLower: 'ORGÁNICA CONSTANTE, el resto en minúsculas.',
  organicSteadyOthersSlightlyHigher: 'ORGÁNICOS firme, OTROS ligeramente superior.',
  organicSteadyOthersSlightlyLower: 'ORGÁNICA CONSTANTE, otros ligeramente inferior.',
  'pintsAndOrganic6-ozHigherOthersSteady': 'PINTS y ​​orgánicos 6-OZ superior; OTROS constante.',
  pintsBarelySteadyOthersSlightlyLower: 'PINTS APENAS CONSTANTE, otros ligeramente inferior.',
  'pintsHigher6-ozAboutSteady': 'PINTS superior; 6-OZ ACERCA estable.',
  pintsHigherOthersAboutSteady: 'PINTS superior, a otros acerca estable.',
  pintsLower6OunceSteady: 'PINTS LOWER, 6 onzas estable.',
  'pintsLower6-ozSteady': 'PINTS LOWER, 6-OZ estable.',
  pintsLowerOthersAboutSteady: 'PINTS más bajo y otros SOBRE constante.',
  pintsLowerOthersBarelySteady: 'PINTS INFERIOR, otros apenas constante.',
  pintsLowerOthersSteady: 'PINTS más bajo y otros constante.',
  'pintsSlightlyHigher6-ounceAboutSteady': 'PINTS ligeramente superior, de 6 onzas ACERCA estable.',
  pintsSlightlyHigherOthersAboutSteady: 'PINTS ligeramente superior, a otros acerca estable.',
  pintsSlightlyLowerOthersAboutSteady: 'PINTS ligeramente inferior, a otros acerca estable.',
  pintsSlightlyLowerOthersSteady: 'PINTS ligeramente inferior, OTROS estable.',
  'pyramidCartons/crates28PoundsBunchedLowerOthersAboutSteady':
    'PIRÁMIDE Cajas / CRATES 28 libras agrupado más bajo y otros ACERCA DE CONSTANTE.',
  'pyramidCartons/cratesStandardLowerOthersAboutSteady':
    'PIRÁMIDE Cajas / CRATES estándar más bajo, otros acerca estable.',
  slighltyLower: 'Slighlty INFERIOR.',
  slightlyHigher: 'LIGERAMENTE MÁS ALTO.',
  slightlyHigherOrganicAboutSteady: 'Ligeramente superior, es orgánico estable.',
  slightlyHigherOrganicLower: 'LIGERAMENTE MÁS ALTO; Orgánica inferior.',
  slightlyHigherOrganicSteady: 'LIGERAMENTE MÁS ALTO; ORGÁNICA estable.',
  slightlyLower: 'LIGERAMENTE MENOR.',
  smallAboutSteadyOthersLower: 'PEQUEÑA SOBRE CONSTANTE, el resto en minúsculas.',
  smallLowerOthersAboutSteady: 'Inferior pequeño, otros acerca constante.',
  'small/mediumAboutSteadyOthersHigher': 'Small / Medium SOBRE firme, OTROS MAS.',
  'small-mediumConventionalSlightlyHigherOthersAboutSteady':
    'PEQUEÑA medio convencional ligeramente superior, a otros acerca estable.',
  'small-mediumLowerOthersAboutSteady': 'Pequeño-medio más bajo y otros SOBRE constante.',
  'small-mediumSlightlyHigherOthersAboutSteady': 'Pequeñas y medianas ligeramente superior, a otros acerca estable.',
  smallSizeLowerOthersSteady: 'PEQUEÑO TAMAÑO más bajo y otros CONSTANTE.',
  smallSizeSteadyOthersHigher: 'PEQUEÑO TAMAÑO CONSTANTE, otros SUPERIOR.',
  smallSizeSteadyOthersLower: 'PEQUEÑO TAMAÑO CONSTANTE, el resto en minúsculas.',
  ssteady: 'SSTEADY.',
  standardAndLargeAboutSteadySmallLower: 'ESTÁNDAR Y ACERCA DE GRAN CONSTANTE, inferior pequeño.',
  standardAndLargeLowerOthersAboutSteady: 'Normal y Grande INFERIOR, otros acerca estable.',
  standardAndLargeSizeSlightlyHigherSmallSizeLowerOthersSteady:
    'STANDARD Y GRAN TAMAÑO LIGERAMENTE SUPERIOR, tamaño pequeño más bajo y otros estable.',
  standardLowerOthersAboutSteady: 'Estándar más bajo, otros acerca constante.',
  standardSizeSlightlyLowerOthersSteady: 'El tamaño estándar ligeramente inferior, OTROS estable.',
  standardSlightlyHigherLargeAboutSteady: 'ESTÁNDAR ligeramente superior, GRANDE SOBRE estable.',
  standardSlightlyHigherOthersAboutSteady: 'ESTÁNDAR ligeramente superior, a otros acerca estable.',
  standardSlightlyLowerLargeAboutSteady: 'ESTÁNDAR ligeramente inferior, GRANDE SOBRE estable.',
  steady: 'ESTABLE.',
  whiteAboutSteadyGreenSlightlyLower: 'BLANCO SOBRE CONSTANTE, VERDE ligeramente inferior.',
};

export { marketEN, marketES };
