export const PARAM_VALUES = {
  MODAL: {
    LOGIN: 'login',
    RESET_PASSWORD: 'reset-password',
    RESET_PASSWORD_CONFIRM: 'reset-password-confirm',
  },
};

export const ROUTES = {
  LOGIN: '/login/',
  LOGIN_MARKETPLACE: '/login/marketplace/',
  CONTENT: '/content',
  INTELLIGENCE: '/content/intelligence/',
  MARKETPLACE: '/content/marketplace/',
  MANAGEMENT: '/content/management/',
  PARAMS: {
    MODAL: 'modal',
    MODAL_LOGIN: `modal=${PARAM_VALUES.MODAL.LOGIN}`,
    MODAL_RESET_PASSWORD: `modal=${PARAM_VALUES.MODAL.RESET_PASSWORD}`,
  },
};
