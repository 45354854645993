import React from 'react';
import PropTypes from 'prop-types';
import { LoaderGif } from '../assets/icons/assets';
import './UseLoader.scss';

const UseLoader = ({ label }) => {
  return (
    <div className="timer-gif-wrapper">
      <img src={LoaderGif} alt="loader-gif" />
      <p className="timer-label">{label}</p>
    </div>
  );
};

UseLoader.defaultProps = {
  label: '',
};

UseLoader.propTypes = {
  label: PropTypes.string,
};

export default UseLoader;
