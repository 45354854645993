import React from 'react';
import PropTypes from 'prop-types';
import documentsTypes from '../../../../../../utils/marketplace/documents/documentsTypes';
import { useTranslation } from 'react-i18next';
import Document from '../../../../../../components/Document';
import './ProgramDocumentation.scss';

const ProgramDocumentation = ({ programDetails }) => {
  const { t } = useTranslation('mpProgramDocumentation');
  const { documents } = programDetails;

  // documents types
  const { FOOD_SAFETY, PACKAGE_PROCESS, INSURANCE_POLICIES, TERMS_AND_CONDITIONS } = documentsTypes;

  const documentDescriptionHandler = (documentType) => {
    const doc = documents.find((doc) => doc.type === documentType);
    if (doc) return doc.comment;
    return '';
  };

  const documentFileHandler = (documentType) => {
    const doc = documents.find((doc) => doc.type === documentType);
    if (doc) return <Document file={doc.file} name={t('seeFile')} styleClass="prog-documentation-document-file" />;
    return '';
  };

  return (
    <div className="program-documentation-container">
      <h3>{t('title')}</h3>
      <div className="prog-documentation-content">
        {/* terms and conditions */}
        <div className="prog-documentation-document-raw">
          <h4 className="prog-documentation-document-label">{t('termsAndConditions')}</h4>
          <hr />
          <p className="prog-documentation-document-description">{documentDescriptionHandler(TERMS_AND_CONDITIONS)}</p>
          {documentFileHandler(TERMS_AND_CONDITIONS)}
        </div>

        {/* insurance policy */}
        <div className="prog-documentation-document-raw">
          <h4 className="prog-documentation-document-label">{t('insurancePolicy')}</h4>
          <hr />
          <p className="prog-documentation-document-description">{documentDescriptionHandler(INSURANCE_POLICIES)}</p>
          {documentFileHandler(INSURANCE_POLICIES)}
        </div>

        {/* food safety */}
        <div className="prog-documentation-document-raw">
          <h4 className="prog-documentation-document-label">{t('foodSafety')}</h4>
          <hr />
          <p className="prog-documentation-document-description">{documentDescriptionHandler(FOOD_SAFETY)}</p>
          {documentFileHandler(FOOD_SAFETY)}
        </div>

        {/* package process  */}
        <div className="prog-documentation-document-raw">
          <h4 className="prog-documentation-document-label">{t('packingMaterial')}</h4>
          <hr />
          <p className="prog-documentation-document-description">{documentDescriptionHandler(PACKAGE_PROCESS)}</p>
          {documentFileHandler(PACKAGE_PROCESS)}
        </div>
      </div>
    </div>
  );
};

ProgramDocumentation.propTypes = {
  programDetails: PropTypes.object.isRequired,
};

export default ProgramDocumentation;
