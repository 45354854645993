import React, { useRef, useState, useEffect } from 'react';
import { SelectArrowDown, SelectArrowUp } from '../assets/icons/assets';
import {
  selectMenuContainer,
  selectMenuMainButton,
  selectMenuText,
  selectMenuFloatingCard,
  selectMenuOption,
} from '../utils/page_definition/select_menu';
import { useTranslation } from 'react-i18next';
import { array, bool, func, element, number, string, oneOfType } from 'prop-types';
import './Select.scss';

const Select = ({
  data = [],
  disabled = false,
  helpBeforeText = '',
  helpText = '',
  endIcon = null,
  icon,
  id,
  optionSelectedProp,
  setSelectedData,
  styleClass,
}) => {
  const { t, i18n } = useTranslation('select');
  const [modal, setModal] = useState(false);
  const selectOptionLabel = t('select:selectOption');
  const [optionSelected, setOptionSelected] = useState({ label: selectOptionLabel });
  const [optionsList, setOptionsList] = useState();
  const trigger = useRef();
  const floatingMenuRef = useRef();

  // adding event listener to hide the select and cleanup when is unmounted
  useEffect(() => {
    const handleClickOutside = (ev) => {
      if (trigger.current.contains(ev.target)) return;
      if (floatingMenuRef?.current?.contains(ev.target)) return;
      setModal(false);
    };

    if (modal) {
      document.addEventListener('mousedown', handleClickOutside);

      return function cleanup() {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [id, modal]);

  useEffect(() => {
    if (data.length === 0) {
      setOptionSelected({ label: selectOptionLabel });
    }
  }, [i18n.language]);

  // adding the option list and set the first object as default
  useEffect(() => {
    if (data.length) {
      setOptionSelected(data[0]);
      setOptionsList(data);
      // send to the father the first value as default
      if (!optionSelectedProp) setSelectedData(data[0].value);
    } else {
      setOptionSelected({ label: selectOptionLabel });
      setOptionsList([]);
      // send to the father the first value as default
      setSelectedData(undefined);
    }
  }, [data]);

  // set the optionSelectedProp as optionSelected and call the father with the value
  useEffect(() => {
    if (optionSelectedProp && optionsList && optionsList.length > 0) {
      const optionSelectedPropFound = optionsList.find((option) => option.value === optionSelectedProp);
      if (optionSelectedPropFound) {
        setOptionSelected(optionSelectedPropFound);
        setSelectedData(optionSelectedProp);
      }
    }
  }, [optionSelectedProp, optionsList]);

  // method to handle the floating modal
  const handleFlMenu = () => {
    setModal((prevState) => !prevState);
  };

  // method to handle the option click
  const handleOptionClick = (option) => {
    setOptionSelected(option);
    // This is the setHookFather call
    setSelectedData(option.value);
    setModal(false);
  };

  // render list menu, with value and label for text
  const renderListMenu = () => {
    if (modal && optionsList.length)
      return (
        <div
          ref={floatingMenuRef}
          id={id}
          data-test={selectMenuFloatingCard}
          className={`sl-floating-modal-container-${id}`}
        >
          <ul className={`sl-floating-options-${id}`}>
            {optionsList.map((option) => (
              <li
                data-test={`${selectMenuOption}-${option.id}`}
                className={`df sl-floating-custom-option-${id} sl-floating-option-selected-${
                  optionSelected.id === option.id
                }`}
                key={option.id}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
                {option.endContent}
              </li>
            ))}
          </ul>
        </div>
      );
    return '';
  };

  const renderCustomLabelIcon = () => {
    if (optionsList && optionsList.length) return icon;
  };

  // render the arrow icon depends if is active or no the modal floating
  const renderButtonIcon = () => {
    if (modal) return <SelectArrowUp styleClass="select-button-arrow-icon" />;
    return <SelectArrowDown styleClass="select-button-arrow-icon" />;
  };

  return (
    <div data-test={selectMenuContainer} className={`select-component-container ${styleClass}`}>
      <button
        ref={trigger}
        data-test={selectMenuMainButton}
        className={`select-component-button-${id} select-component-button-active-${modal} disabled-${disabled}`}
        onClick={handleFlMenu}
        disabled={disabled}
      >
        {renderCustomLabelIcon()}
        <p data-test={selectMenuText} className={`select-component-button-text-${id}`}>
          {helpBeforeText} {optionSelected.label}
        </p>
        {helpText && (
          <p
            className={`select-selected-after-text-${helpText ? true : false} select-component-button-after-text-${id}`}
          >
            {helpText || ''}
          </p>
        )}
        {endIcon}
        {renderButtonIcon()}
      </button>
      {renderListMenu()}
    </div>
  );
};

Select.propTypes = {
  data: array,
  disabled: bool,
  helpBeforeText: string,
  helpText: string,
  /**
   * Element placed before the children.
   */
  icon: element,
  /**
   * Element placed after the children.
   */
  endIcon: element,
  id: string,
  optionSelectedProp: oneOfType([number, string]),
  setSelectedData: func,
  styleClass: string,
};

export default Select;
