import React from 'react';
import DocumentHandler from '../../../../../components/DocumentHandler';
import PropTypes from 'prop-types';
import RowCalendar from '../RowCalendar/RowCalendar';
import Tooltip from '../../../../../components/Tooltip';
import WebStorage from '../../../../../utils/WebStorage/WebStorage';
import documentsTypes from '../../../../../utils/marketplace/documents/documentsTypes';
import fixDigits from '../../../../../utils/common/fixDigits';
import priceHandler from '../../../../../utils/marketplace/price/priceHandler';
import stringHandlers from '../../../../../utils/common/stringHandlers';
import unitsHandler from '../../../../../utils/marketplace/units/unitsHandler';
import { PackageIcon, InfoStatusIcon, PalletIcon } from '../../../../../assets/icons/assets';
import { timeFormat, GetProgramDate } from '../../../../../utils/common/DateHandler';
import { useTranslation } from 'react-i18next';

const DetailCommitment = ({
  commitedBoxes,
  commitmentData,
  programData,
  programDetails,
  selectedDestination,
  selectedPackagingMaterial,
  unit,
}) => {
  const {
    calibration,
    destinations_data,
    documents,
    is_organic,
    packaging_materials,
    pallet_type,
    product_quality,
    pti_label,
  } = programDetails;

  // props destructor
  const { price, package_description, package_weight, product_abbr, program_type, start_date, weeks } = programData;

  const { rawValue } = unitsHandler;
  const { producerReturn } = priceHandler(
    programDetails,
    commitedBoxes,
    price,
    selectedPackagingMaterial,
    selectedDestination,
  );

  const { capitalizeFirstLetter } = stringHandlers;

  const totalEntireReturn = producerReturn * weeks;

  const { boxes_pallet, pallets_truck } = commitmentData;

  // destinaton fltered by selected
  const destinationData = destinations_data.find((destination) => destination.id === selectedDestination);

  // documents types
  const { FOOD_SAFETY, RETAIL_REQUIREMENT, PACKAGE_PROCESS } = documentsTypes;

  const { t } = useTranslation(['mpCommitment', 'mpFlowButtons', 'units', 'mpDetailCommitment', 'fruits', 'general']);
  const weekRowTitle = t('mpDetailCommitment:deliveryDays');

  const { getCookieValue } = WebStorage;
  const localeID = getCookieValue('localeId');
  const packingMaterialObj = packaging_materials.find((material) => material.id === selectedPackagingMaterial);

  // unit value
  const unitValue = (unitValue) => rawValue(unitValue, commitedBoxes, pallets_truck, boxes_pallet).toLocaleString();

  // row calendar
  const renderRowCalendar = () => (
    <div className="detail-commitment_row-calendar">
      <RowCalendar
        data={commitmentData}
        label={weekRowTitle}
        packageWeight={package_weight}
        totalBoxes={commitedBoxes}
        unit={unit}
      />
    </div>
  );

  /*
   * @param {object} element to render by program_ype
   * @param {number} programType to compare with program_type
   * @return {object || string}
   */
  const renderByProgramType = (element, programType) => (program_type === programType ? element : '');

  /*
   * method to render the commodity type label
   */
  const renderCommodityType = () => (is_organic ? t('mpCommitment:organic') : t('mpCommitment:conventional'));

  /*
   * TODO: use this as utils method
   * Methods to render the pti label
   */
  const renderPTILabel = (element) => (pti_label ? element : <></>);

  /*
   * methods to render the if the pti label is required
   */
  const renderRequiredPtiLabel = () => (pti_label ? t('general:yes') : t('general:no'));

  const renderWeeklyOrOneDeliveryLabel = () =>
    program_type === 1
      ? t('mpDetailCommitment:weeklyDeliveryAmout', { weeks })
      : t('mpDetailCommitment:oneDeliveryLabel');

  return (
    <div className="detail-commitment-container">
      <div className="opportunity-details-header">
        <h3 className="opportunity-details-header-title">
          <span className="neutral-color">{t('mpDetailCommitment:step2')}</span>
          <span className="title-separator neutral-color">-</span>
          <span className="second-title">{t('mpDetailCommitment:step2ProgramLabel')}</span>
        </h3>
      </div>
      <div className="oppotunity-details-content detail-commitment-content">
        {/* prices*/}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpDetailCommitment:prices')}</h4>
          <hr className="detail-prog-divisor" />

          <div className="content-data-inner-wrapper-raw grid-t-col-3">
            {/* sale price */}
            <div>
              <h5 className="content-data-inner-title">{`${t('mpDetailCommitment:salePrice')}`}</h5>
              <span>
                <p className="content-data-inner-value price-label">{fixDigits(price)}</p>
                <p className="content-data-inner-value-description">{`${t(
                  'mpDetailCommitment:usdPerBox',
                )} ${package_description}`}</p>
              </span>
            </div>

            {/* weeekly sale or one delivery price */}
            {renderByProgramType(
              <div>
                <h5 className="content-data-inner-title">{t('mpDetailCommitment:saleTotalWeekly')}</h5>
                <span className="content-data-inner-raw">
                  <p className="content-data-inner-value price-label">{fixDigits(producerReturn)}</p>

                  <Tooltip
                    customClass="market-place-tooltip weekly-sale-delivery-price"
                    text={t('mpDetailCommitment:tooltipDescription')}
                    title={t('mpDetailCommitment:tooltipTitle')}
                  >
                    <InfoStatusIcon styleClass="market-place-tooltip-icon" />
                  </Tooltip>
                </span>

                <p className="content-data-inner-value-description">{t('mpDetailCommitment:weeklyDelivery')}</p>
              </div>,
              1,
            )}

            {/* total opportunity sale price */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:saleTotalProgram')}</h5>
              <span>
                <p className="content-data-inner-value price-label">{fixDigits(totalEntireReturn)}</p>
                <p className="content-data-inner-value-description">{renderWeeklyOrOneDeliveryLabel()}</p>
              </span>
            </div>
          </div>
        </div>

        {/* Boxes quantity */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpDetailCommitment:numberOfBoxes')}</h4>
          <hr className="detail-prog-divisor" />
          {/* first row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* pallets quantity */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:quantityPallets')}</h5>
              <span className="content-data-inner-raw">
                <PalletIcon styleClass="content-data-inner-icon" />
                <p className="content-data-inner-value spacing-left">{unitValue('pallet')}</p>
              </span>
            </div>

            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:totalBoxes')}</h5>
              <span className="content-data-inner-raw">
                <p className="content-data-inner-value">{commitedBoxes}</p>
              </span>
            </div>
          </div>

          {/* second row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* boxes per pallet */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:boxesPerPallet')}</h5>
              <span className="content-data-inner-raw">
                <p className="content-data-inner-value">{boxes_pallet}</p>
              </span>
            </div>

            {/* boxes description */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:presentation')}</h5>
              <span className="content-data-inner-raw">
                <PackageIcon styleClass="content-data-inner-icon box-icon" />
                <p className="content-data-inner-value spacing-left">
                  {t('mpDetailCommitment:boxDescription', { package_description })}
                </p>
              </span>
            </div>
          </div>

          {/* third row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* Fruit */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:fruitTitle')}</h5>
              <span className="content-data-inner-raw">
                <p className="content-data-inner-value">{t(`fruits:${product_abbr}`)}</p>
              </span>
            </div>

            {/* fruit type */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:presentation')}</h5>
              <span className="content-data-inner-raw">
                <p className="content-data-inner-value">{renderCommodityType()}</p>
              </span>
            </div>
          </div>
        </div>

        {/* packaging materials */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpDetailCommitment:packingMaterial')}</h4>
          <hr className="detail-prog-divisor" />
          {/* first row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-3">
            {/* pallet type */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:palletType')}</h5>
              <span>
                <p className="content-data-inner-value">{pallet_type}</p>
              </span>
            </div>

            {/* material package */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:packingSelectedMaterial')}</h5>
              <span>
                <p className="content-data-inner-value">{capitalizeFirstLetter(packingMaterialObj.name)}</p>
              </span>
            </div>

            {/* pti required */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:requirePTI')}</h5>
              <span>
                <p className="content-data-inner-value uppercase-label">{renderRequiredPtiLabel()}</p>
              </span>
            </div>
          </div>

          {/* second row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* pti label */}
            {renderPTILabel(
              <div>
                <h5 className="content-data-inner-title">{t('mpDetailCommitment:ptiLabel')}</h5>
                <span>
                  <p className="content-data-inner-value">{capitalizeFirstLetter(pti_label)}</p>
                </span>
              </div>,
            )}
          </div>

          {/* third row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* related documents */}
            <div>
              <DocumentHandler documents={documents} type={PACKAGE_PROCESS} />
            </div>
          </div>
        </div>

        {/* frida tip */}
        <div className="content-data-wrapper frida-tip-wrapper">
          <div className="content-data-tip">
            <h5 className="content-data-inner-title frida-tip-title">Frida Tip:</h5>
            <span className="content-data-inner-raw">
              <p className="content-data-inner-tip-description">
                {t('mpDetailCommitment:tipDescription')}
                <a href="mailto:support@fridanet.com" className="content-data-inner-tip-anchor">
                  {t('general:contactTeam')}
                </a>
              </p>
            </span>
          </div>
        </div>

        {/* commodity description */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpDetailCommitment:fruitSpecifications')}</h4>
          <hr className="detail-prog-divisor" />
          {/* first raw */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* fruit quality */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:fruitQuality')}</h5>
              <span>
                <p className="content-data-inner-value">{product_quality}</p>
              </span>
            </div>

            {/* minimun gauge */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:minimumGauge')}</h5>
              <span>
                <p className="content-data-inner-value">{calibration}</p>
              </span>
            </div>
          </div>
          {/* second raw */}
          <div className="content-data-inner-wrapper-raw grid-t-col-3">
            {/* related documents */}
            <div>
              <DocumentHandler documents={documents} type={RETAIL_REQUIREMENT} />
            </div>
          </div>
        </div>

        {/* delivery place */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpDetailCommitment:deliveryPlace')}</h4>
          <hr className="detail-prog-divisor" />
          {/* first raw */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* place */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:placeLabel')}</h5>
              <span>
                <p className="content-data-inner-value">{destinationData.name}</p>
              </span>
            </div>

            {/* place direction */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:deliveryAddres')}</h5>
              <span>
                <p className="content-data-inner-value">{destinationData.address}</p>
              </span>
            </div>
          </div>

          {/* second raw */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* place phone*/}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:phone')}</h5>
              <span>
                <a className="content-data-inner-value" href={`tel:${destinationData.phone}`}>
                  {destinationData.phone}
                </a>
              </span>
            </div>
          </div>

          {/* third raw */}
          <div className="content-data-inner-wrapper-raw grid-t-col-3">
            {/* related documents */}
            <div>
              <DocumentHandler documents={documents} type={FOOD_SAFETY} />
            </div>
          </div>
        </div>

        {/* delivery time */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpDetailCommitment:deliveryTime')}</h4>
          <hr className="detail-prog-divisor" />
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* opportunity duration */}
            {renderByProgramType(
              <div>
                <h5 className="content-data-inner-title">{t('mpDetailCommitment:opportunityDuration')}</h5>
                <span>
                  <p className="content-data-inner-value">{t('mpDetailCommitment:programWeekDuration', { weeks })}</p>
                </span>
              </div>,
              1,
            )}

            {/* delivery date  */}
            {renderByProgramType(
              <div>
                <h5 className="content-data-inner-title">{t('mpDetailCommitment:deliveryDate')}</h5>
                <span>
                  <p className="content-data-inner-value capitalize-label">{GetProgramDate(start_date, localeID)}</p>
                </span>
              </div>,
              2,
            )}

            {/* deliver before time*/}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:deliverBeforeTime')}</h5>
              <span>
                <p className="content-data-inner-value">{timeFormat(start_date)}</p>
              </span>
            </div>
          </div>
        </div>

        {/* delivery dates */}
        {renderByProgramType(
          <div className="content-data-wrapper">
            <div className="content-data-inner-wrapper-raw">
              <div>
                <h5 className="content-data-inner-title">
                  {t('mpDetailCommitment:deliveryDates')}
                  <b>{destinationData.name}</b>
                </h5>
              </div>
            </div>
            {renderRowCalendar()}
          </div>,
          1,
        )}
      </div>
    </div>
  );
};

DetailCommitment.defaultProps = {
  commitedBoxes: 0,
  commitmentData: undefined,
};

DetailCommitment.propTypes = {
  commitedBoxes: PropTypes.number,
  commitmentData: PropTypes.object,
  programData: PropTypes.object,
  programDetails: PropTypes.object,
  selectedDestination: PropTypes.number.isRequired,
  selectedPackagingMaterial: PropTypes.number.isRequired,
  selectedProgram: PropTypes.number,
  unit: PropTypes.string.isRequired,
};

export default DetailCommitment;
