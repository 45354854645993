/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import ExternalDTPicker, { CalendarContainer } from 'react-datepicker';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';
import { CalendarIcon, EditCalendarIcon } from '../assets/icons/assets';
import { getYear, getMonth, subYears } from 'date-fns';
import './DatePicker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import marketHeader from '../utils/page_definition/market_header';

/*
 * DatePcker component
 * @prop {date} dateState Date received from the father
 * @prop {function} setDateState Function to set the date from the father
 * @return {React.Element}
 */
const DatePicker = ({ dateState, setDateState, setIsActive }) => {
  // Limit to only 5 years back
  const years = range(getYear(new Date()) - 5, getYear(new Date()) + 1, 1);
  // Overwrite the Month label to be same as the design
  const monthsDict = {
    en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    es: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  };

  // i18n
  const { t, i18n } = useTranslation('datePicker');

  const months = monthsDict[i18n.language];

  // handle the onChange from the date picker and sent it as hook function set to the father.
  const handleDatePickerChange = (date) => {
    if (date < new Date()) setDateState(date);
  };

  // Custom header
  const customHeader = (header) => (
    <div className="dt-picker-header">
      <select
        value={months[getMonth(header.date)]}
        onChange={({ target: { value } }) => header.changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select value={getYear(header.date)} onChange={({ target: { value } }) => header.changeYear(value)}>
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  // Custom Container
  const customContainer = (container) => (
    <div className="dt-picker-container">
      <CalendarContainer className={container.className}>
        <div className="dt-picker-container-children">{container.children}</div>
      </CalendarContainer>
    </div>
  );

  // Custom Input
  const CustomInput = forwardRef((input, ref) => (
    <button
      id="dt-picker-button"
      className="dt-picker-button"
      data-test={marketHeader.datePickerButton}
      onClick={input.onClick}
      ref={ref}
    >
      <span>{t('today')}:&#160;</span> {input.value}
    </button>
  ));

  const handleCalendarClose = () => setIsActive(false);
  const handleCalendarOpen = () => setIsActive(true);

  return (
    <div id="date-picker-container" className="date-picker-container">
      <CalendarIcon styleClass="dt-picker-calendar-icon-default" />
      <EditCalendarIcon styleClass="dt-picker-calendar-icon-edit" />
      <div className="dt-picker-inner-container">
        <ExternalDTPicker
          calendarClassName="dt-picker-component"
          calendarContainer={customContainer}
          customInput={<CustomInput />}
          dateFormat="E MMMM d, yyyy "
          maxDate={dateState}
          minDate={subYears(new Date(), 5)}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          onChange={handleDatePickerChange}
          renderCustomHeader={customHeader}
          selected={dateState}
          showDisabledMonthNavigation
        />
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  dateState: PropTypes.object.isRequired,
  setDateState: PropTypes.func.isRequired,
  setIsActive: PropTypes.func.isRequired,
};

export default DatePicker;
