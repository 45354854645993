import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { bool, object, string, objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import GraphNoData from 'components/GraphNoData';
import Loader from 'components/Loader';
import VolumeAndPricesDataHandler from 'utils/Highcharts/dataHandlers/VolumeAndPricesDataHandler';
import VolumeAndPricesGraphOptions from 'utils/Highcharts/VolumeAndPicesGraphOptions';
import switchCaseHandler from 'utils/common/switch';
import { GRAPH_VALUES } from '../../utils/graphSelectData';
import { TIME_PERIODS } from '../../utils/timeControls';

/*
 * @typedef {Object} yearTrend
 * @property {array} frida_forecast
 * @property {array} past_frida_forecast
 * @property {array} prices
 * @property {array} volume
 */

/*
 * @typedef {Object} trendsAPIData
 * @property {yearTrend}
 */

/*
 * @param {bool} loading default false
 * @param {trendsAPIData}
 * @param {string} time WEEKLY || DAILY
 * @param {string} chart ALL || VOLUME || PRICE
 * @returns {React.element} ChartRenderer
 * @see {@link https://docs.google.com/document/d/1ErXtfJciqzFvKSZU0a7X4iAnqquAzY6YOy0J55tPF2Y/edit#|SPECS}
 */
const ChartRenderer = ({ loading = false, trendsAPIData, time = '', chart = '', packageType = '' }) => {
  const [formattedTrendsData, setFormattedTrendsData] = useState(null);
  const [highchartsOptions, setHighchartsOptions] = useState(null);

  const { t, i18n } = useTranslation(['mpVolumeAndPrices']);

  const { volume, prices, completeWeekly, completeDaily, forecast } = VolumeAndPricesDataHandler;

  // Expand highchart required for range serie
  HighchartsMore(Highcharts);

  // Cleanup when the loading is true.
  useEffect(() => {
    if (loading) {
      setHighchartsOptions();
      setFormattedTrendsData();
    }
  }, [loading]);

  // Hook to format the trends data
  useEffect(() => {
    const formatTrendsData = () => {
      // Cleanup
      setFormattedTrendsData();
      setHighchartsOptions();
      const chartSeries = [];
      const trendsSeries = Object.keys(trendsAPIData);

      // Merge the days of each year serie to create a complete structure for all.
      const completeDataStructure = switchCaseHandler({
        weekly: () => completeWeekly(trendsAPIData),
        daily: () => completeDaily(trendsAPIData),
      })(trendsAPIData)(time);

      const parsedDataMovementSeries = completeDataStructure.map((serie, index) => {
        return volume(time, serie.volume, trendsSeries[index], 'movementSerie');
      });

      const parsedDataPriceSeries = completeDataStructure.map((serie, index) => {
        return prices(time, serie.prices, trendsSeries[index], 'priceSerie');
      });

      const futureDataSeries = completeDataStructure.find((serie) => serie.frida_forecast?.length)?.frida_forecast;
      const pastDataSeries = completeDataStructure.find((serie) => serie.past_frida_forecast?.length)
        ?.past_frida_forecast;

      if (futureDataSeries) {
        chartSeries.push(forecast('future', 'futureForecast', futureDataSeries));
      }

      if (pastDataSeries) {
        chartSeries.push(forecast('past', 'pastForecast', pastDataSeries));
      }

      if ([GRAPH_VALUES.ALL, GRAPH_VALUES.VOLUME].includes(chart)) {
        chartSeries.push(parsedDataMovementSeries.filter((e) => e));
      }

      if ([GRAPH_VALUES.ALL, GRAPH_VALUES.PRICES].includes(chart)) {
        chartSeries.push(parsedDataPriceSeries.filter((e) => e));
      }

      // Set formatTrendsData with all years array into one array
      setFormattedTrendsData(chartSeries.flat());
    };

    if (trendsAPIData && Object.keys(trendsAPIData).length > 0) formatTrendsData();
  }, [trendsAPIData, completeDaily, completeWeekly, forecast, volume, prices]);

  // update the chart data to re-render the highchart.
  useEffect(() => {
    const updateTrendsChart = () => {
      const cloneTrendsHCH = { ...VolumeAndPricesGraphOptions.volumeAndPrices };
      cloneTrendsHCH.xAxis.categories = undefined; // overwrited if the chart time is weekly

      // translate series name
      const handleSerieName = (serie) => {
        return switchCaseHandler({
          pastForecast: t('mpVolumeAndPrices:pastFridaForecast'),
          futureForecast: t('mpVolumeAndPrices:fridaForecast'),
        })(`${t(`mpVolumeAndPrices:${serie.name}`)} - ${serie.yearName}`)(serie.name);
      };

      // Serie name translated with i18n
      const translatedTrendsChartData = formattedTrendsData.map((serie) => ({
        ...serie,
        name: handleSerieName(serie),
      }));

      if (time === TIME_PERIODS.WEEKLY) {
        cloneTrendsHCH.xAxis.categories = ['53', [...Array(52).keys()].map((i) => `${i + 1}`)].flat();
      }
      cloneTrendsHCH.series = translatedTrendsChartData;
      setHighchartsOptions(cloneTrendsHCH);
    };

    if (formattedTrendsData && formattedTrendsData.length > 0) updateTrendsChart();
  }, [formattedTrendsData, t, i18n.language]);

  return (
    <div className="pt-l">
      <div className="df jcsb pb-base">
        <p className="fs-6 neutral-700 uppercase-label">
          {[GRAPH_VALUES.ALL, GRAPH_VALUES.VOLUME].includes(chart) ? '4.5 lbs cases' : packageType}
        </p>
        {[GRAPH_VALUES.ALL, GRAPH_VALUES.PRICES].includes(chart) && (
          <p className="fs-6 neutral-700 uppercase-label">{t('pricesInUsd')}</p>
        )}
      </div>
      {loading && <Loader />}
      {!loading && !highchartsOptions && <GraphNoData className="p-xl mb-l br-m border bsd primary-200-br" />}
      {!loading && highchartsOptions && (
        <HighchartsReact updateArgs={[true, true, true]} highcharts={Highcharts} options={highchartsOptions} />
      )}
    </div>
  );
};

ChartRenderer.propTypes = {
  chart: string,
  packageType: string,
  loading: bool,
  time: string,
  trendsAPIData: objectOf(object),
};

export default ChartRenderer;
