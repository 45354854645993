import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyStateMessage from '../../../../components/EmptyStateMessage';

const ManagementEmptyState = () => {
  const { t } = useTranslation('management');

  return (
    <div className="df aic jcc h100">
      <EmptyStateMessage title="👈" description={t('emptyStateMessage')} />
    </div>
  );
};

export default ManagementEmptyState;
