/*
 * @param {array} data
 * @return {array}
 */
const packageTypeSelect = (data) => {
  if (Array.isArray(data) && data.length) {
    return data.map((element, index) => {
      return { id: index, label: element, value: element };
    });
  }
  return [];
};

export default packageTypeSelect;
