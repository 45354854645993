/*
 * @param {array} data
 * @param {string} type string to add specific and special option
 * @param {string} entireMarketLabel
 * @return {array}
 */
const originsSelect = (data, type = undefined, entireMarketLabel = 'ENTIRE MARKET') => {
  const baseArray = [];
  if (Array.isArray(data) && data.length) {
    if (type === 'entireMarket')
      baseArray.push({ id: 'entireMarket', label: entireMarketLabel, value: 'entireMarket' });
    data.forEach((element) => {
      baseArray.push({ id: element.origin_slug, label: element.origin, value: element.origin_slug });
    });
  }
  return baseArray;
};
export default originsSelect;
