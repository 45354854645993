import { SET_SELECTED_DATE } from '../actions/selectedDate/types';

const initialState = { date: null };

const selectedDate = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_DATE: {
      return {
        ...state,
        date: action.selectedDate,
      };
    }
    default: {
      return state;
    }
  }
};

export default selectedDate;
