import React from 'react';
import switchCaseHandler from '../common/switch';
import OrchardDetails from '../../views/Content/Management/details/orchard';
import ManagementEmptyState from '../../views/Content/Management/emptyState';

const handlerOptionCases = (activeOption) =>
  switchCaseHandler({
    ORCHARD_DETAILS: function orchardDetails() {
      return <OrchardDetails orchardId={activeOption.option} />;
    },
  })(<ManagementEmptyState />)(activeOption.type);

export default handlerOptionCases;
