import { useState, useEffect } from 'react';
import utilsApi from 'utils/api/UtilsApi';

/*
 * @typedef {Object} useAPICommodityReturns
 * @property {boolean} loading
 * @property {error} error
 * @property {object} data
 */

/*
 * @param {string} commodity
 * @param {array} years
 * @returns {useAPICommodityReturns} the hook return
 * @see {@link https://docs.google.com/document/d/1YKbanF8LzC3YGivkXpof5OV6O9sFenVo2_3BJcUWVGM/edit#|Spec}
 */

const useAPICommodity = (commodity, years) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    async function getCommodityInformation() {
      try {
        setLoading(true);
        const res = await utilsApi.getCommodityInformation(commodity, years);
        setData(res.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (commodity && years.length) getCommodityInformation();
  }, [commodity, years]);

  return { loading, error, data };
};

export default useAPICommodity;
