import React from 'react';

/* eslint-disable */
const WeatherSnow = ({ styleClass, isWarning }) => (
  <svg className={styleClass} viewBox="0 0 24 24">
    <title>Weather Snow</title>
    <defs>
      <path
        d="M11,21.5 C11.2761424,21.5 11.5,21.7238576 11.5,22 C11.5,22.2761424 11.2761424,22.5 11,22.5 C10.7238576,22.5 10.5,22.2761424 10.5,22 C10.5,21.7238576 10.7238576,21.5 11,21.5 Z M9,19.5 C9.27614237,19.5 9.5,19.7238576 9.5,20 C9.5,20.2761424 9.27614237,20.5 9,20.5 C8.72385763,20.5 8.5,20.2761424 8.5,20 C8.5,19.7238576 8.72385763,19.5 9,19.5 Z M14,19.5 C14.2761424,19.5 14.5,19.7238576 14.5,20 C14.5,20.2761424 14.2761424,20.5 14,20.5 C13.7238576,20.5 13.5,20.2761424 13.5,20 C13.5,19.7238576 13.7238576,19.5 14,19.5 Z M12,17.5 C12.2761424,17.5 12.5,17.7238576 12.5,18 C12.5,18.2761424 12.2761424,18.5 12,18.5 C11.7238576,18.5 11.5,18.2761424 11.5,18 C11.5,17.7238576 11.7238576,17.5 12,17.5 Z M17,17.5 C17.2761424,17.5 17.5,17.7238576 17.5,18 C17.5,18.2761424 17.2761424,18.5 17,18.5 C16.7238576,18.5 16.5,18.2761424 16.5,18 C16.5,17.7238576 16.7238576,17.5 17,17.5 Z M7,17.5 C7.27614237,17.5 7.5,17.7238576 7.5,18 C7.5,18.2761424 7.27614237,18.5 7,18.5 C6.72385763,18.5 6.5,18.2761424 6.5,18 C6.5,17.7238576 6.72385763,17.5 7,17.5 Z M13,0 C15.472,0 17.635,1.663 18.294,4.009 C21.1,4.189 23.5,6.911 23.5,10 C23.5,13.196 20.93,16 18,16 L18,16 L6,16 C3.071,16 0.5,13.196 0.5,10 C0.5,6.804 3.07,4 6,4 C6.564,4 7.123,4.089 7.665,4.265 C8.23,1.793 10.421,0 13,0 Z M13,1 C10.717,1 8.805,2.723 8.553,5.008 C8.535,5.167 8.442,5.308 8.303,5.387 C8.163,5.467 7.995,5.474 7.849,5.408 C7.252,5.137 6.63,5 6,5 C3.425,5 1.5,7.64 1.5,10 C1.5,12.36 3.424,15 6,15 L6,15 L18,15 C20.575,15 22.5,12.36 22.5,10 C22.5,7.64 20.575,5 18,5 C17.986,5.005 17.951,5.008 17.915,5.01 C17.664,4.998 17.458,4.847 17.41,4.608 C16.99,2.518 15.135,1 13,1 Z"
        id="path-weather-sow"
      ></path>
    </defs>
    <g id="Symbols-weather-snow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-weather-snow">
        <g id="Weather_Icons-snow" transform="translate(0.000000, 0.500000)">
          <mask id="mask-weather-snow" fill="white">
            <use xlinkHref="#path-weather-sow"></use>
          </mask>
          <use
            id="Combined-Shape-weather-snow"
            stroke={isWarning ? '#e40d2b' : '#141414'}
            strokeWidth="0.5"
            fill={isWarning ? '#e40d2b' : '#141414'}
            fillRule="nonzero"
            xlinkHref="#path-weather-sow"
          ></use>
          <g id="Neutral-/-900-weather-snow" mask="url(#mask-weather-snow)" fill={isWarning ? '#e40d2b' : '#141414'}>
            <g transform="translate(0.000000, -1.000000)">
              <path d="M8,0 L24,0 L24,0 L24,24 L8,24 C3.581722,24 5.41083001e-16,20.418278 0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WeatherSnow;
