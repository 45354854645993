import React from 'react';

/*eslint-disable*/
const Timer = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 15 16">
    <g id="timer-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="timer-icon-inner" transform="translate(-151.000000, -432.000000)" fillRule="nonzero">
        <g id="timer-icon-inner-card" transform="translate(118.000000, 344.000000)">
          <g id="timer-icon-2" transform="translate(32.000000, 88.000000)">
            <rect id="ViewBox" x="0" y="0" width="16" height="16"></rect>
            <g id="timer-icon-3" transform="translate(1.333333, 0.000000)" fill="#141414">
              <path
                d="M9.09737828,0 C9.51685393,0 9.80524345,0.25 9.80524345,0.65 C9.80524345,1.05 9.51685393,1.325 9.09737828,1.325 L9.09737828,1.325 L7.70786517,1.325 L7.70786517,2.725 C11.1947566,3.075 14,5.875 14,9.325 C14,13 10.8539326,16 7,16 C3.14606742,16 0,13 0,9.35 C0,5.95 2.80524345,3.075 6.29213483,2.75 L6.29213483,2.75 L6.29213483,1.35 L4.90262172,1.35 C4.48314607,1.35 4.19475655,1.075 4.19475655,0.675 C4.19475655,0.275 4.48314607,0 4.90262172,0 L4.90262172,0 Z M7,4 C3.88732394,4 1.33333333,6.42310695 1.33333333,9.34582358 C1.33333333,12.2685402 3.88732394,14.6666667 7,14.6666667 C10.1126761,14.6666667 12.6666667,12.2435597 12.6666667,9.32084309 C12.6666667,6.39812646 10.1126761,4 7,4 Z M7.3415978,5.33333333 C7.73829201,5.33333333 8.01101928,5.60606061 8.01101928,6.00275482 L8.01101928,6.00275482 L8.01101928,8.65564738 L10.6391185,8.65564738 C11.0358127,8.65564738 11.3085399,8.90358127 11.3333333,9.30027548 C11.3333333,9.6969697 11.0606061,9.96969697 10.6639118,9.96969697 L10.6639118,9.96969697 L8.01101928,9.96969697 L8.01101928,10.6639118 C8.01101928,11.0606061 7.73829201,11.3333333 7.3415978,11.3333333 C6.94490358,11.3333333 6.67217631,11.0606061 6.67217631,10.6639118 L6.67217631,10.6639118 L6.67217631,9.99449036 L6.00275482,9.99449036 C5.60606061,9.99449036 5.33333333,9.72176309 5.33333333,9.32506887 C5.33333333,8.92837466 5.60606061,8.65564738 6.00275482,8.65564738 L6.00275482,8.65564738 L6.67217631,8.65564738 L6.67217631,6.00275482 C6.67217631,5.60606061 6.94490358,5.33333333 7.3415978,5.33333333 Z"
                id="Combined-Shape-Timer-Icon"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Timer;
