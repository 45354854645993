import React from 'react';

/*eslint-disable*/
const SystemUpdate = ({ styleClass }) => {
  return (
    <svg className={styleClass} viewBox="0 0 16 16" version="1.1">
      <g id="SymbolsUpdate" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icn-system-update">
          <rect id="Icon-viewport" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="16" height="16"></rect>
          <path
            d="M16,6.32888889 L9.97292598,6.32888889 L12.4086462,3.82222222 C9.98181547,1.42222222 6.05266102,1.33333333 3.62583033,3.73333333 C1.19899964,6.14222222 1.19899964,10.0266667 3.62583033,12.4355556 C6.05266102,14.8444444 9.98181547,14.8444444 12.4086462,12.4355556 C13.6176168,11.2444444 14.2221021,9.84888889 14.2221021,8.08888889 L16,8.08888889 C16,9.84888889 15.2177249,12.1333333 13.6531747,13.68 C10.5329638,16.7733333 5.4659547,16.7733333 2.34574382,13.68 C-0.76557758,10.5955556 -0.792246049,5.58222222 2.32796484,2.49777778 C5.44817572,-0.586666667 10.4529584,-0.586666667 13.5731693,2.49777778 L16,0 L16,6.32888889 Z M7.5,5 L7.5,8.94562334 L11,10.8766578 L10.28,12 L6,9.64190981 L6,5 L7.5,5 Z"
            id="Shape"
            fill="#141414"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default SystemUpdate;
