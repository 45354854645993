import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../../components/Select';
import Tooltip from '../../../../../components/Tooltip';
import destinationLogistics from '../../../../../utils/marketplace/selects/destinationsLogistics';
import fixDigits from '../../../../../utils/common/fixDigits';
import packagingMaterials from '../../../../../utils/marketplace/selects/packagingMaterials';
import priceHandler from '../../../../../utils/marketplace/price/priceHandler';
import unitsHandler from '../../../../../utils/marketplace/units/unitsHandler';
import { InfoStatusIcon } from '../../../../../assets/icons/assets';
import useProfileData from '../../../../../hooks/Profile/useProfileData';
import { useTranslation } from 'react-i18next';
import './BoxProgram.scss';

const internals = {
  /**
   * Generates emailto link to request packaging material for a producer.
   * @param producerName {string}
   * @param packagingMaterialName {string}
   * @param packagingMaterialId {number}
   */
  getRequestPackagingMaterialMailtoHref(producerName, packagingMaterialName, packagingMaterialId) {
    const to = 'support@fridanet.com';
    const subject = encodeURIComponent(`${producerName}: Packaging Material Request`);
    const body = encodeURIComponent(`Hi Frida team, this is a request for packaging material in the name of ${producerName} in order to join an opportunity.

Packaging Material: ${packagingMaterialName} (${packagingMaterialId})`);

    return `mailto:${to}?subject=${subject}&body=${body}`;
  },
};

const BoxProgram = ({
  errorData,
  programData,
  programDetails,
  selectedCommitedBoxes,
  selectedDestination,
  selectedPackagingMaterial,
  selectedProgram,
  setBoxCommitedBoxes,
  setSelectedDestination,
  setSelectedPackagingMaterial,
  unit,
}) => {
  const [unitSelect, setUnitSelect] = useState([]);
  const [packagingSelect, setPackagingSelect] = useState([]);
  const [destinationsSelect, setDestinationsSelect] = useState([]);
  const [unitSelectedOption, setUnitSelectedOption] = useState();
  const profile = useProfileData();
  const { price, weeks, pallets_truck, boxes_pallet, package_weight, program_type } = programData;
  const {
    commitment_limit,
    commitment_step,
    destinations_data,
    final_destination,
    min_commitment,
    packaging_materials,
  } = programDetails;

  const { t } = useTranslation(['units', 'mpBoxProgram', 'mpFlowButtons']);
  const { unitIcons, unitLabel, commitmentValue, estimatedReturn, estimatedReturnDescription } = unitsHandler;

  useEffect(() => {
    // clean

    setUnitSelectedOption();
    setBoxCommitedBoxes();
    setSelectedPackagingMaterial();

    setUnitSelect();
    setPackagingSelect();
  }, [selectedProgram]);

  useEffect(() => {
    if (programDetails && unit) {
      // fill packaging_materials select
      setPackagingSelect(packagingMaterials(packaging_materials));

      // fill destinations logistics select
      setDestinationsSelect(destinationLogistics(destinations_data));

      // fill unit select
      setUnitSelect(
        commitmentValue(
          unit,
          commitment_limit,
          pallets_truck,
          boxes_pallet,
          weeks,
          package_weight,
          min_commitment,
          commitment_step,
        ),
      );
    }
  }, [programDetails, unit]);

  // unit select handler
  const handleUnitSelect = (value) => {
    let commitedBoxesOption = undefined;
    if (value) {
      commitedBoxesOption = unitSelect.find((e) => e.value === value);
    }
    setUnitSelectedOption(commitedBoxesOption);
    setBoxCommitedBoxes(commitedBoxesOption?.value);
  };

  // packagingMaterial select handler
  const handlePackingSelect = (value) => {
    let packagingMaterialOption = undefined;
    if (value) {
      const option = packagingSelect.find((e) => e.value === value);
      packagingMaterialOption = option;
    }
    setSelectedPackagingMaterial(packagingMaterialOption);
  };

  // destinationLogistic select handler
  const handleDestinationSelect = (value) => {
    let destinationLogisticOption = undefined;
    if (value) {
      const option = destinationsSelect.find((e) => e.value === value);
      destinationLogisticOption = option.value;
    }
    setSelectedDestination(destinationLogisticOption);
  };

  // destinationsLogistics filtered
  const filterDestinationLabel = final_destination !== selectedDestination ? true : false;

  const textHandler = (value) => (selectedCommitedBoxes ? fixDigits(value) : '');

  if (programDetails) {
    const {
      custom,
      fridaFee,
      inspectionFee,
      priceFOB,
      producerReturn,
      totalInAndOut,
      totalLogistics,
      unitReturn,
      packagingMaterialPrice,
      destinationLogisticPrice,
    } = priceHandler(programDetails, selectedCommitedBoxes, price, selectedPackagingMaterial, selectedDestination);

    /*
     * method to render elements by program_type 1: program 2: spot
     * @param {React.Element} elementToRender
     * @param {number} type
     * @return {React.Element}
     */
    const renderIfIsProgramOpportunity = (elementToRender, type) => (program_type === type ? elementToRender : <></>);
    const selectedPackagingMaterialOption = packagingSelect.find(
      (packageOption) => packageOption.id === selectedPackagingMaterial,
    );

    return (
      <div className="detail-commitment-container box-program-container">
        <div className="opportunity-details-header">
          <h3 className="opportunity-details-header-title">
            <span className="neutral-color">{t('mpBoxProgram:step1')}</span>
            <span className="title-separator neutral-color">-</span>
            <span className="second-title">{t('mpBoxProgram:step1Label')}</span>
          </h3>
        </div>
        <div className="bx-prog-content">
          {/* left side */}
          <div className="bx-prog-content-left">
            <div className="bx-prog-content-title-row">
              <h3>{t('mpBoxProgram:selectAmount')}</h3>

              <Tooltip
                customClass="market-place-tooltip unit-tooltip"
                text={t('mpBoxProgram:selectTooltipDescription')}
                title={t('mpBoxProgram:selectTooltipTitle')}
              >
                <InfoStatusIcon styleClass="market-place-tooltip-icon" />
              </Tooltip>
            </div>

            <div className="bx-prog-contnt-select-container">
              {/* unit select */}
              <Select
                data={unitSelect}
                helpText={(() => {
                  let result = t(unitLabel(unit));
                  if (unitSelectedOption?.id === 0) {
                    result += ` ${t('mpBoxProgram:selectHelpText')}`;
                  }
                  return result;
                })()}
                icon={unitIcons(unit, 'bx-prog-cntnt-slect-icon')}
                id="bx-prog-contnt-unit-select"
                setSelectedData={handleUnitSelect}
                styleClass={`bx-prog-contnt-select bx-prog-contnt-select-error-${!!errorData}`}
              />
              <p className="bx-prog-cntnt-select-error">{errorData}</p>
            </div>

            {/* destinations select */}
            <h3>{t('mpBoxProgram:destinationLogistics')}</h3>
            <div className="bx-prog-contnt-select-container">
              <Select
                data={destinationsSelect}
                id="bx-prog-contnt-packaging-select"
                setSelectedData={handleDestinationSelect}
                styleClass="bx-prog-contnt-select packing-material-select"
              />
            </div>

            {/* packaging materials select */}
            <h3>{t('mpBoxProgram:selectPackaging')}</h3>
            <div className="bx-prog-contnt-select-container">
              <Select
                data={packagingSelect}
                id="bx-prog-contnt-destination-select"
                setSelectedData={handlePackingSelect}
                styleClass="bx-prog-contnt-select packing-material-select"
                endIcon={(() => {
                  return selectedPackagingMaterialOption?.endContent ? (
                    <div className="f1 jcfe mr-m">{selectedPackagingMaterialOption.endContent}</div>
                  ) : null;
                })()}
              />
            </div>
            {/* end selects */}
            {selectedPackagingMaterialOption?.assigned === false && (
              <div className="red-50-bg pb-m p-l br-m mb-m">
                <h6 className="fs-base red-900 pb-m">{t('mpBoxProgram:packagingMaterialRequestTitle')}</h6>
                <p className="fs-3 neutral-300 pb-m">{t('mpBoxProgram:packagingMaterialRequestDescription')}</p>
                <a
                  href={internals.getRequestPackagingMaterialMailtoHref(
                    profile?.producer?.name,
                    selectedPackagingMaterialOption.label,
                    selectedPackagingMaterialOption.id,
                  )}
                  className="fk-button button-primary-main button-small tdn dib"
                >
                  {t('mpBoxProgram:packagingMaterialRequestCTA')}
                </a>
              </div>
            )}
            <div className="frida-tip-container">
              <p className="frida-tip-title">FRIDA Tip:</p>
              <p className="frida-tip-text">
                {t('mpBoxProgram:weRecomend')}
                <b>{t('mpBoxProgram:doNotCommit')}</b>
                {t('mpBoxProgram:weeklyProduction')}
              </p>
              <p className="frida-tip-text">{t('mpBoxProgram:remember')}</p>
            </div>
          </div>
          {/* right side */}
          <div className="bx-prog-content-right">
            <h3>{t('mpBoxProgram:returnProducer')}</h3>
            {renderIfIsProgramOpportunity(<h5>{t('mpBoxProgram:estimatedPerWeek')}</h5>, 1)}
            <hr className="bx-program-divisor" />
            <div className="bx-prog-contnt-data-row">
              <p className="bx-prog-contnt-data-value">
                {t('mpBoxProgram:salePrice')} FOB {destinations_data[0].name}
              </p>
              <h3 className="price-label">{textHandler(priceFOB)}</h3>
            </div>
            <div className="bx-prog-contnt-data-row">
              <div className="bx-prog-contnt-dta-rw-inline">
                <p className="bx-prog-contnt-data-value">
                  {t('mpBoxProgram:logistics')} {destinations_data[0].name} - CEDIS
                </p>
                <Tooltip
                  customClass="market-place-tooltip"
                  text={t('mpBoxProgram:infoTooltipDescription')}
                  title={t('mpBoxProgram:infoTooltipTitle')}
                >
                  <InfoStatusIcon styleClass="market-place-tooltip-icon" />
                </Tooltip>
              </div>
              <h3 className="price-label">{textHandler(totalLogistics)}</h3>
            </div>

            {filterDestinationLabel && (
              <div className="bx-prog-contnt-data-row">
                <div className="bx-prog-contnt-dta-rw-inline">
                  <p className="bx-prog-contnt-data-value">{t('mpBoxProgram:nationalLogistics')}</p>
                </div>
                <h3 className="price-label">{textHandler(destinationLogisticPrice)}</h3>
              </div>
            )}

            <div className="bx-prog-contnt-data-row">
              <p className="bx-prog-contnt-data-value">{t('mpBoxProgram:fridaFee')}</p>
              <h3 className="price-label">{textHandler(fridaFee)}</h3>
            </div>
            <div className="bx-prog-contnt-data-row">
              <p className="bx-prog-contnt-data-value">{t('mpBoxProgram:customExpenses')}</p>
              <h3 className="price-label">{textHandler(custom)}</h3>
            </div>
            <div className="bx-prog-contnt-data-row">
              <p className="bx-prog-contnt-data-value">In&Out {t('mpBoxProgram:cold')}</p>
              <h3 className="price-label">{textHandler(totalInAndOut)}</h3>
            </div>
            <div className="bx-prog-contnt-data-row">
              <p className="bx-prog-contnt-data-value">{t('mpBoxProgram:inspection')}</p>
              <h3 className="price-label">{textHandler(inspectionFee)}</h3>
            </div>
            <div className="bx-prog-contnt-data-row">
              <p className="bx-prog-contnt-data-value">{t('mpBoxProgram:packagingMaterial')}</p>
              <h3 className="price-label">{textHandler(packagingMaterialPrice)}</h3>
            </div>
            <hr />
            <div className="bx-prog-contnt-data-row-total">
              <div className="bx-prog-cntnt-data-flat-row">
                <p className="bx-prog-contnt-data-label end">{t('mpBoxProgram:estimatedReturnPrice')}</p>
                <Tooltip
                  customClass="market-place-tooltip"
                  text={t('mpBoxProgram:returnTooltipDescription')}
                  title={t('mpBoxProgram:returnTooltipTitle')}
                >
                  <InfoStatusIcon styleClass="market-place-tooltip-icon" />
                </Tooltip>
              </div>
              <p className="bx-prog-cntnt-data-value">{textHandler(producerReturn)}</p>
            </div>
            <div className="bx-prog-contnt-data-row-total">
              <p className="bx-prog-contnt-data-label">{t('mpBoxProgram:estimatedReturnUnit')}</p>
              <div className="bx-prog-cntnt-data-inner-row">
                <p className="bx-prog-cntnt-data-value">
                  {textHandler(estimatedReturn(unit, unitReturn, package_weight))}
                </p>
                <p className="bx-prog-contnt-data-description">
                  {t(`mpBoxProgram:${estimatedReturnDescription(unit)}`)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <div />;
};

BoxProgram.defaultProps = {
  errorData: '',
  selectedCommitedBoxes: undefined,
  selectedDestination: undefined,
  selectedPackagingMaterial: undefined,
  unit: 'truck',
};

BoxProgram.propTypes = {
  errorData: PropTypes.string,
  opportunityType: PropTypes.bool.isRequired,
  programData: PropTypes.object,
  programDetails: PropTypes.object,
  selectedCommitedBoxes: PropTypes.number,
  selectedDestination: PropTypes.number,
  selectedPackagingMaterial: PropTypes.number,
  setBoxCommitedBoxes: PropTypes.func.isRequired,
  setCommitmentData: PropTypes.func.isRequired,
  setSelectedDestination: PropTypes.func.isRequired,
  setSelectedPackagingMaterial: PropTypes.func.isRequired,
  unit: PropTypes.string,
};

export default BoxProgram;
