const selectBoxSelector = {
  selectMenuContainer: 'select-menu-container',
  selectMenuText: 'select-menu-text',
  selectMenuMainButton: 'select-menu-main-button',
  selectMenuFloatingCard: 'select-menu-floating-card',
  selectMenuOption: 'select-menu-option',
  notSelectMenuContext: 'not-select-menu-context',
  selectLocationContainer: 'select-location-container',
  selectsRow: 'selects-row',
  addSelectButton: 'add-select-button',
  clearButton: 'clear-button',
  selectTypeContainer: 'select-type-container',
  selectLabelContainer: 'select-label-container',
};

module.exports = selectBoxSelector;
