const internals = {
  weekSelect: {
    startingWeek: 1,
    endingWeek: 53,
    getStartingWeek() {
      const base = [];

      for (let i = this.startingWeek; i <= this.endingWeek - 1; i++) base.push({ id: `${i}`, label: i, value: i });
      return base;
    },

    getEndingWeek(startingWeek = internals.weekSelect.startingWeek) {
      const base = [];

      for (let i = internals.weekSelect.endingWeek; i > startingWeek; i--)
        base.push({ id: `${i}`, label: i, value: i });
      return base;
    },
  },
  daySelect: {
    getStartingDay: () => [...Array(31).keys()].map((i) => ({ id: `${i + 1}`, label: i + 1, value: i + 1 })),
    getEndingDay: (startingDay = 1, month) => {
      if (!startingDay) return;
      if (month === 12)
        return [...Array(31 - startingDay).keys()]
          .map((i) => ({ id: `${i + 1}`, label: i + 1, value: i + 1 }))
          .reverse();
      return [...Array(31).keys()].map((i) => ({ id: `${i + 1}`, label: i + 1, value: i + 1 })).reverse();
    },
  },
};

export const startingWeek = internals.weekSelect.getStartingWeek();
export const getEndingWeek = internals.weekSelect.getEndingWeek;
export const startingDay = internals.daySelect.getStartingDay();
export const getEndingDay = internals.daySelect.getEndingDay;
