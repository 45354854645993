const volumeBehaviorSelector = {
  volumeBehaviorBehaviorWeek: 'volume-behavior-last-week',
  volumeBehaviorBehaviorWeekValue: 'volume-behavior-last-week-value',
  volumeBehaviorBehaviorYear: 'volume-behavior-last-year',
  volumeBehaviorBehaviorYearValue: 'volume-behavior-last-year-value',
  volumeBehaviorBehavior: 'volume-behavior',
  volumeBehaviorContainer: 'volume-behavior-container',
  volumeBehaviorDetail: 'volume-behavior-detail',
  volumeProductZone: 'production-zone',
};

export default volumeBehaviorSelector;
