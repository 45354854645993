import React from 'react';
import ErrorCommitmentMessage from './ErrorCommitmentMessage';
import WarningCommitmentMessage from './WarningCommitmentMessage';
import switchCaseHandler from '../../common/switch';
import { CancelStatusIcon, CheckStatusIcon, InfoStatusIcon, StepperCircle } from '../../../assets/icons/assets';

const statusHandler = {
  /*
   * @param {string} status
   * @return {string}
   */
  commitmentStatus: (status) => {
    return switchCaseHandler({
      1: () => 'pending',
      2: () => 'pending',
      3: () => 'pending',
      4: () => 'success',
      5: () => 'error',
      6: () => 'warning',
      7: () => 'pending',
      8: () => 'success',
    })('')(status);
  },

  /*
   * @param {string} status
   * @param {string} styleClass
   * @return {React.Element}
   */
  dayCardIcons: (status, styleClass) => {
    return switchCaseHandler({
      success: function successCase() {
        return <CheckStatusIcon styleClass={`${styleClass} check-status-icon`} />;
      },
      error: function errorCase() {
        return <CancelStatusIcon styleClass={`${styleClass} cancel-status-icon`} />;
      },
      warning: function warningCase() {
        return <InfoStatusIcon styleClass={styleClass} />;
      },
    })('')(status);
  },

  /*
   * @param {string} status
   * @return {string}
   */
  logisticalEvents: (status) => {
    return switchCaseHandler({
      1: 'information',
      2: 'issue',
      3: 'error',
    })('')(status);
  },

  /*
   * @param {string} status
   * @return {React.Element}
   */
  commitmentMessages: (status) => {
    return switchCaseHandler({
      5: function errorCase() {
        return <ErrorCommitmentMessage />;
      },
      6: function warningCase() {
        return <WarningCommitmentMessage />;
      },
    })('')(status);
  },

  eventDetailStatus: (status, anchors) => {
    const { eventStepperIcon, eventWarningIcon, eventErrorIcon } = anchors;
    return switchCaseHandler({
      1: <StepperCircle styleClass="evnt-dtls-tggle-cntnt-dta-icon" dataTest={eventStepperIcon} />,
      2: <InfoStatusIcon styleClass="evnt-dtls-tggle-cntnt-dta-warning" dataTest={eventWarningIcon} />,
      3: <CancelStatusIcon styleClass="evnt-dtls-tggle-cntnt-dta-error" dataTest={eventErrorIcon} />,
    })('')(status);
  },
};

export default statusHandler;
