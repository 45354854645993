/*
 * @param {number} nummber
 * @param {number} fix
 * @return {number}
 */
export const toFixed = (number = 0, fix = 2) => {
  if (number && !Number.isInteger(number)) return Number.parseFloat(number).toFixed(fix);
  else return number;
};

/*
 * @param {number} number
 * @param {number} fix
 * @return {number}
 */
export const intFormat = (number = 0, fix = 2) => {
  if (number && number > 999) return (number / 1000).toFixed(fix) + 'K';
  return parseFloat(number).toLocaleString();
};
