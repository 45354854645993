/**
 * Highchart options object for Projection Graph
 */

const ProjectionGraphOptions = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      grouping: false,
    },
  },
  legend: {
    align: 'left',
    itemDistance: 35,
    itemMarginTop: 20,
    itemMarginBottom: 5,
    itemStyle: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 2.4,
      color: '#4e5052',
    },
    squareSymbol: true,
    symbolWidth: 12,
    symbolHeight: 12,
    symbolRadius: 6,
    x: 37,
  },
  yAxis: {
    title: '',
  },
  xAxis: {
    type: 'category',
    title: 'Número de la semana',
    plotLines: [
      {
        color: '#bca9f7',
        dashStyle: 'ShortDashDot',
        width: 2,
        label: {
          rotation: 0,
          textAlign: 'left',
          verticalAign: 'top',
        },
      },
    ],
  },

  tooltip: {
    backgroundColor: '#313233',
    padding: 20,
    borderRadius: 16,
    crosshairs: true,
    shared: true,
    style: {
      color: '#fff',
      fontSize: 14,
    },
    useHTML: true,
  },
  series: [],
};

export default ProjectionGraphOptions;
