const shippingPointSelectors = {
  shippingContainer: 'shipping-container',
  shippingPointButton: 'shipping-point-button',
  retailPricesButton: 'retail-prices-button',
  shippingPointChartContainer: 'shipping-point-chart-container',
  shippingPointChartBody: 'shipping-point-chart-body',
  retailPricesChartContainer: 'retail-prices-chart-container',
  locationsGraphBody: 'locations-graph-container',
  crossingZonesButton: 'crossing-zones-button',
};

module.exports = shippingPointSelectors;
