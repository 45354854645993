import stringHandlers from '../common/stringHandlers';

/**
 * Parse the commodity/imported object to the Highchart serie format;
 * @param {String} name is the serie name
 * @param {Array} dataArray is the commodity/imported.data array with the next format: {week: number, total: number}
 * @return {Object} {name: String, data: [[x, y]:number]}
 */
const VolumeSerieWeekly = (name, dataArray) => {
  if (typeof name === 'string' && Array.isArray(dataArray)) {
    const baseArray = [];

    dataArray.forEach((element) => {
      baseArray.push([`${element.week}`, element.total]);
    });
    // push the last total from dataArray into the currentWeek
    // baseArray.push([currentWeek, dataArray.pop().total]);

    return { name, data: baseArray };
  }
  return { name: '', data: [] };
};

const VolumeSerieWeeklyFilter = (name, dataArray) => {
  if (Array.isArray(dataArray)) {
    const { location, prdType } = name;
    const serieName = stringHandlers.slashCombiner([location, prdType], [12, 0]);

    const baseArray = [];

    dataArray.forEach((element) => {
      baseArray.push([`${element.week}`, element.total]);
    });

    return { name: serieName, data: baseArray };
  }
  return { name: '', data: [] };
};

const VolumeSerieWeeklyRange = (name, dataArray, type, lowestKey = 'lowest_price', highestKey = 'highest_price') => {
  const averagePointFormat = {
    pointFormat: `
      <div class="hc-volume-range-custom-tooltip">
        <span style="color:{point.color}">●</span>
        <div>
          <p>{series.name}: </p>
          <b>{point.high} - {point-low}</b>
        </div>
      </div>`,
  };

  if (typeof name === 'string' && Array.isArray(dataArray)) {
    const baseArray = [];
    dataArray.forEach((element) => {
      baseArray.push([`${element.week}`, element[lowestKey], element[highestKey]]);
    });

    const areaRangeOptions = {
      name,
      data: baseArray,
      zIndex: 0,
      tooltip: averagePointFormat,
      type: 'arearange',
    };

    if (type === 'future') {
      areaRangeOptions.fillColor = '#120dd11A';
      areaRangeOptions.lineColor = '#120dd1';
      areaRangeOptions.color = '#120dd1';
    }

    if (type === 'past') {
      areaRangeOptions.fillColor = '#fe9dd81A';
      areaRangeOptions.lineColor = '#fe9dd8';
      areaRangeOptions.color = '#fe9dd8';
    }

    return areaRangeOptions;
  }
};

/**
 * Parse the commodity/imported object to the Highchart serie format;
 * @param {String} name is the serie name
 * @param {Array} dataArray is the commodity/imported.data array with the next format: {week: number, total: number}
 * @return {Object} {name: String, data: [[x, y]:number]}
 */
const VolumeSerieDaily = (name, dataArray) => {
  if (typeof name === 'string' && Array.isArray(dataArray)) {
    const baseArray = [];
    dataArray.forEach((element) => {
      baseArray.push([element.date, element.total]);
    });
    // push the last total from dataArray into the currentWeek
    // baseArray.push([currentWeek, dataArray.pop().total]);

    return { name, data: baseArray };
  }
  return { name: '', data: [] };
};

/**
 * Parse the commodity/imported object to the Highchart serie format;
 * @param {object} name is the serie name
 * @param {Array} dataArray is the commodity/imported.data array with the next format: {week: number, total: number}
 * @param {boolean} isEmptyIndex bqenqwnqwnejqwnejqwe
 * @return {Object} {name: String, data: [[x, y]:number]}
 */
const VolumeSerieDailyFilter = (name, dataArray, isEmptyIndex) => {
  if (Array.isArray(dataArray)) {
    const { location, prdType } = name;
    const baseArray = [];
    let serieName = stringHandlers.slashCombiner([location, prdType], [12, 0]);

    dataArray.forEach((element) => baseArray.push([element.date, element.total]));
    if (isEmptyIndex) serieName = serieName.concat('  (NO DATA)');

    return { name: serieName, data: baseArray };
  }
  return { name: '', data: [] };
};

export { VolumeSerieDaily, VolumeSerieWeekly, VolumeSerieWeeklyRange, VolumeSerieWeeklyFilter, VolumeSerieDailyFilter };
