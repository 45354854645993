import { exact, number, oneOf, string } from 'prop-types';
import PrettyBytes from 'pretty-bytes';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Badge from '../../../../../../components/Badge';
import Toast from '../../../../../../components/Toast';
import Spinner from '../../../../../../components/Spinner';
import DocumentUpload from '../../../../../../components/documents/DocumentUpload';
import { GetProgramDate } from '../../../../../../utils/common/DateHandler';
import { getFileName } from '../../../../../../utils/common/stringHandlers';
import WebStorage from '../../../../../../utils/WebStorage/WebStorage';
import { getOrchardsListAction } from '../../../../../../redux/actions/management/orchards/actions';
import {
  ACCEPTED_FILES,
  DOCUMENT_STATUS,
  DOCUMENT_STATUS_MAP,
  MAX_FILE_SIZE,
} from '../../../../../../constants/orchards';
import './style.scss';

const internals = {
  successMessageDelayMS: 2000,
  getDynamicClassNames(status, isLoading) {
    if (isLoading || [undefined, DOCUMENT_STATUS.REJECTED, DOCUMENT_STATUS.EXPIRED].includes(status))
      return 'red-50-bg red-600-br';
    return 'neutral-50-bg neutral-50-br';
  },
  getBadge(status, t) {
    if (!status) return;
    return (
      <div className="ml-base">
        <Badge type={this._getBadgeType(status)} text={t(`status.${status.toLowerCase()}`)} />
      </div>
    );
  },
  getExpirationDate(status, expirationDate) {
    if (status === DOCUMENT_STATUS.PENDING) return '—';
    return GetProgramDate(expirationDate, WebStorage.getCookieValue('localeId'));
  },
  _getBadgeType(status) {
    if (status === DOCUMENT_STATUS.PENDING) return 'dark';
    if ([undefined, DOCUMENT_STATUS.REJECTED, DOCUMENT_STATUS.EXPIRED].includes(status)) return 'danger';
    if (status === DOCUMENT_STATUS.APPROVED) return 'success';
    if (status === DOCUMENT_STATUS.PROMPT_EXPIRATION) return 'warning';
  },
};

const DocumentCard = ({ title, data, type, orchardId, className = '' }) => {
  const [documentData, setDocumentData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('mgOrchardsDetails');
  const dispatch = useDispatch();
  const statusKey = DOCUMENT_STATUS_MAP[documentData?.status];
  const [fileName, fileExtension] = getFileName(documentData?.file)?.split('.');

  const handleDocumentUploadSuccess = (res) => {
    setDocumentData(res.data[type]);
    dispatch(getOrchardsListAction());
    setTimeout(() => {
      Toast.success({
        title: t('uploadSuccess.title'),
        message: t('uploadSuccess.message'),
      });
    }, internals.successMessageDelayMS);
  };

  const handleDocumentUploadError = (err) => {
    Toast.error({
      title: t('uploadError.title'),
      message: err?.message || t('uploadError.message'),
    });
  };

  useEffect(() => {
    setDocumentData(data);
  }, [data]);

  return (
    <div
      className={`document-card p-m border br-m
        ${isLoading ? 'document-card--loading' : ''}
        ${internals.getDynamicClassNames(statusKey, isLoading)} ${className}`}
    >
      <header className="document-card__header df pb-m">
        <h6 className="f1 fs-base neutral-900 fw700">{title}</h6>
        {internals.getBadge(statusKey, t)}
      </header>
      <div className={'pr pt-m pb-base'}>
        <div className={`document-card__content-inner df ${documentData?.file ? '' : 'fdc'}`}>
          {documentData?.file ? (
            <div className="f1 oh">
              <p className="fs-1 fw700 pb-m mr-m">
                <a className="df primary-700" href={`${documentData?.file}`} target="_blank" rel="noopener noreferrer">
                  <span className="ellipsis">{fileName}</span>
                  <span>.{fileExtension}</span>
                </a>
              </p>
              <div className="df">
                <div className="pr-l">
                  <p className="fs-4 neutral-600">{t('size')}:</p>
                  <p className="fs-3 neutral-700 fw700 capitalize-label">{PrettyBytes(documentData?.size)}</p>
                </div>
                <div>
                  <p className="fs-4 neutral-600">{t('expiresIn')}:</p>
                  <p className="fs-3 neutral-700 fw700 capitalize-label">
                    {internals.getExpirationDate(statusKey, documentData?.expiration_date)}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <p className="fs-2 neutral-700 fw700 pb-m">{t('noDataMessage')}</p>
          )}
          <DocumentUpload
            path={`orchard/${type}/${orchardId}`}
            haveFile={Boolean(documentData?.file)}
            showConfirmationDialog={
              [undefined, DOCUMENT_STATUS.EXPIRED, DOCUMENT_STATUS.REJECTED].includes(statusKey) === false
            }
            acceptFiles={ACCEPTED_FILES.map((f) => `.${f}`).join(',')}
            maxFileSize={MAX_FILE_SIZE}
            onLoading={(loading) => setIsLoading(loading)}
            onSuccess={handleDocumentUploadSuccess}
            onError={handleDocumentUploadError}
          />
        </div>
        <div className={'document-card__loading-msg pa df aic jcc'}>
          <Spinner />
          <span className="fs-4 neutral-800 ml-base">{t('loadingMessage')}&hellip;</span>
        </div>
      </div>
    </div>
  );
};

DocumentCard.propTypes = {
  title: string.isRequired,
  data: exact({
    expiration_date: string,
    file: string,
    size: number,
    status: oneOf(Object.keys(DOCUMENT_STATUS_MAP).map(Number)),
  }),
  type: string.isRequired,
  orchardId: number.isRequired,
  className: string,
};

export default DocumentCard;
