import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProfileInfoStatusBadge from 'components/ProfileInfo/StatusBadge';
import WebStorage from 'utils/WebStorage/WebStorage';
import { GetProgramDate } from 'utils/common/DateHandler';
import { CalendarIcon } from 'assets/icons/assets';
import { PRODUCER_STATUS_MAP } from 'constants/user';
import profileDefinitions from 'utils/page_definition/components/profile';
import './style.scss';

const { profileInfoPhotoImage } = profileDefinitions;

const internals = {
  getProfileImage(path) {
    if (!path) return;
    return <img alt="user profile" className="w100 h100 br-inherit" data-test={profileInfoPhotoImage} src={path} />;
  },
};

const ProfileInfo = ({ profile, currentDate }) => {
  const { t } = useTranslation(['general', 'mpMarketHeaderCard']);
  const { photo, producer } = profile;
  const { week, date } = currentDate;
  const statusMap = PRODUCER_STATUS_MAP[producer?.status];
  const localeID = WebStorage.getCookieValue('localeId');

  return (
    <div className="profile-info__container df aic">
      {Boolean(Object.keys(profile).length) && (
        <>
          <div className="profile-info__photo__wrapper square-l br50">{internals.getProfileImage(photo)}</div>
          <ProfileInfoStatusBadge status={statusMap} />
        </>
      )}
      <div className="profile-info__date__wrapper">
        <CalendarIcon styleClass="profile-info__date__icon" />
        <p className="fs-4 fw400 white-100 ml-s">
          {t('mpMarketHeaderCard:currentWeek')}
          <b className="fw600">{`${t('mpMarketHeaderCard:week')} ${week}: ${t('general:date', {
            date: GetProgramDate(date, localeID),
          })}`}</b>
        </p>
      </div>
    </div>
  );
};

ProfileInfo.propTypes = {
  profile: object.isRequired,
  currentDate: object.isRequired,
};

export default ProfileInfo;
