import React, { useState, useEffect } from 'react';
import Button from '../../../../../components/Button';
import PropTypes from 'prop-types';
import WebStorage from '../../../../../utils/WebStorage/WebStorage';
import welcomeMessageData from '../../../../../utils/page_definition/marketplace/welcomeMessage';
import { formatHumanDate } from '../../../../../utils/common/DateHandler';
import { useTranslation } from 'react-i18next';
import './WelcomeMessage.scss';

const WelcomeMessage = ({ programData, programId }) => {
  const [showDialog, setShowDialog] = useState(true);

  const { getCookieValue, setCookie } = WebStorage;
  const localeID = getCookieValue('localeId');
  const { t } = useTranslation(['mpWelcomeMessage', 'general']);
  const { welcomeMessage, welcomeMessageButton } = welcomeMessageData;
  const { start_date } = programData;

  useEffect(() => {
    const cookie = getCookieValue(`welcome-message-${programId}`);
    if (cookie.length) setShowDialog(false);
    else setShowDialog(true);
  }, [programId]);

  const handleAceptar = () => {
    setCookie(`welcome-message-${programId}`, 'closed');
    setShowDialog(false);
  };

  if (showDialog) {
    return (
      <div data-test={welcomeMessage} className="welcome-message">
        <h2>{t('mpWelcomeMessage:title')}</h2>
        <p className="welcome-message-label">
          {t('mpWelcomeMessage:message', { date: t('general:date', { date: formatHumanDate(start_date, localeID) }) })}
        </p>
        <Button
          dataTest={welcomeMessageButton}
          onClick={handleAceptar}
          size={'small'}
          styleClass={'welcome-acept-button'}
          type={'primary'}
        >
          {t('mpWelcomeMessage:accept')}
        </Button>
      </div>
    );
  } else return <div />;
};

WelcomeMessage.propTypes = {
  programData: PropTypes.object.isRequired,
  programId: PropTypes.number.isRequired,
};

export default WelcomeMessage;
