import React from 'react';

/*eslint-disable*/
const InfoStatusIcon = ({ styleClass, dataTest }) => (
  <svg data-test={dataTest} className={styleClass} viewBox="0 0 16 16">
    <title>Info status</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icn/Help">
        <rect id="Icon-viewport" fillOpacity="0" fill="#313233" x="0" y="0" width="16" height="16"></rect>
        <path
          d="M8,0 C12.4,0 16,3.6 16,8 C16,12.4 12.4,16 8,16 C3.6,16 0,12.4 0,8 C0,3.6 3.6,0 8,0 Z M8,1.325 C4.325,1.325 1.325,4.325 1.325,8 C1.325,11.675 4.325,14.675 8,14.675 C11.675,14.675 14.675,11.675 14.675,8 C14.675,4.325 11.675,1.325 8,1.325 Z M8,6 C8.55228475,6 9,6.44771525 9,7 L9,7 L9,12 C9,12.5522847 8.55228475,13 8,13 C7.44771525,13 7,12.5522847 7,12 L7,12 L7,7 C7,6.44771525 7.44771525,6 8,6 Z M8,3 C8.55228475,3 9,3.44771525 9,4 C9,4.55228475 8.55228475,5 8,5 C7.44771525,5 7,4.55228475 7,4 C7,3.44771525 7.44771525,3 8,3 Z"
          id="Combined-Shape"
          fill="#141414"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
);

export default InfoStatusIcon;
