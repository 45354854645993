// the version used for date-fns is 1.30 this is for react-datepicker
import { getISOWeek, startOfWeek, endOfWeek, format } from 'date-fns';
// best way to use the locales
import { en, es } from '../locale/dateLocale';
import switchCaseHandler from './switch';

// i18n dateFns packages
const localeOptions = switchCaseHandler({
  en: () => ({ locale: en }),
  es: () => ({ locale: es }),
})({ locale: en });

/**
 * @param {Date}
 * @return date label like '1 NOV 20'
 */
export const GetDayWeekRow = (isoDate, localeID) => format(new Date(isoDate), 'D MMM YY', localeOptions(localeID));

/**
 * @param {Date}
 * @return date label like 'NOV 01 20'
 */
export const GetProgramDate = (isoDate, localeID) => format(new Date(isoDate), 'MMM D YYYY', localeOptions(localeID));

/*
 * @param {Date}
 * @return date label like '2021'
 */
export const GetYear = (isoDate, localeID) => format(new Date(isoDate), 'YYYY', localeOptions(localeID));

/**
 * @param {Date}
 * @return date label like 'May 4'
 */
export const GetDay = (isoDate, localeID) => format(new Date(isoDate), 'MMM D', localeOptions(localeID));

/**
 * @param {Date} isoDate
 * @return {sting} weekday name like 'Monday'
 **/
export const GetDayName = (isoDate, localeID) => format(new Date(isoDate), 'ddd', localeOptions(localeID));

/**
 * @param {Date} isoDate
 * @return {sting} numeric day of month like '30'
 **/
export const GetDayNumber = (isoDate, localeID) => format(new Date(isoDate), 'D', localeOptions(localeID));

/**
 * @param {Date}
 * @return upper case month of received date
 */
export const GetMonth = (isoDate, localeID) => format(new Date(isoDate), 'MMM', localeOptions(localeID)).toUpperCase();

/**
 * @param {Date}
 * @return date label like 'Sat 1'
 */
export const GetDateWeatherLabel = (isoDate, language) =>
  new Date(isoDate).toLocaleString(language, { weekday: 'short', day: 'numeric' });

/*
 * @param {Date}
 * @return date label like Sun Aug 31 2014 00:00:00
 */
export const StartOfWeek = (isoDate) => startOfWeek(new Date(isoDate), { weekStartsOn: 0 });

/*
 * @param {Date}
 * @return date label like Sun Aug 31 2014 00:00:00
 */
export const EndOfWeek = (isoDate) => endOfWeek(new Date(isoDate), { weekStartsOn: 0 });

/*
 * @param {Date}
 * @return date label like Thu July 30, 2020
 */
export const formatHumanDate = (isoDate, localeID) =>
  format(new Date(isoDate), 'ddd MMMM D, YYYY', localeOptions(localeID));

/*
 * @param {Date}
 * @return date label like Thu July 30
 */
export const formatWeekDate = (isoDate, localeID) => format(new Date(isoDate), 'ddd MMMM D', localeOptions(localeID));

/*
 * @param {Date} isoDate
 * @return {String} label like 5:45 p.m
 */
export const timeFormat = (isoDate) => format(new Date(isoDate), 'h:mm a', localeOptions());
/**
 * @param {Date}
 * @return week
 */
export const getWeek = (date) => getISOWeek(EndOfWeek(date));

/*
 * @param {Date}
 * @return date label like Week 31: Sun July 26th to Sat August 1st
 */
export const GetSelectedWeek = (selectedDate, localeID) =>
  `${getISOWeek(EndOfWeek(selectedDate))}: ${formatWeekDate(StartOfWeek(selectedDate), localeID)} ${
    localeID === 'en' ? 'to' : 'hasta'
  }
  ${formatWeekDate(EndOfWeek(selectedDate), localeID)}`;
