import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import NoData from '../../../../../components/NoData';
import UtilsApi from '../../../../../utils/api/UtilsApi';
import getVolumeOptions from '../../../../../utils/Highcharts/VolumeOptions';
import VolumeSelectCascader from '../../../../../components/VolumeSelectCascader';
import generateDateStructure from '../../../../../utils/Highcharts/dataHandlers/weeklyGraph';
import { VolumeSerieWeekly, VolumeSerieWeeklyRange } from '../../../../../utils/Highcharts/VolumeSerie';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentDateAction } from '../../../../../redux/actions/currentDate/actions';
import { getWeek } from '../../../../../utils/common/DateHandler';
import { useTranslation } from 'react-i18next';
import './Weekly.scss';

const WeeklyGraph = () => {
  const { t } = useTranslation('volume');
  const [error, setError] = useState();
  const [highChartsOptions, setHighChartsOptions] = useState();
  const [movementSeasons, setVolumeSeasons] = useState();
  const [optionsSelected, setOptionsSeleted] = useState();

  const dispatch = useDispatch();

  const currentDate = useSelector((state) => state.currentDate.currentDate);
  const selectedDate = useSelector((state) => state.selectedDate.date);
  const productDetail = useSelector((state) => state.products[state.products.active]);

  // expand highchart required for range serie
  HighchartsMore(Highcharts);

  useEffect(() => {
    if (!currentDate) dispatch(getCurrentDateAction());
  }, [currentDate, getCurrentDateAction]);

  // Use effect to handle the highchart options with the series and plot data.
  useEffect(() => {
    const updateGraph = () => {
      // only when the currentCommodity hook exists
      if (movementSeasons && currentDate) {
        const { data } = movementSeasons;
        // get the Highchart options.
        const cloneHCH = { ...getVolumeOptions(t('weeklyXAxis')) };
        // Tooltip header
        const ToolTipHeaderFormat = `<span style="font-size: 15px">${t('week')} {point.key}</span><br/><br/>`;
        // array to parse the series array with the differents imports types: {Current, Future, Last}
        const hcSeries = [];
        // Parse the Serie for the Current Season data.
        // Right now the data from this service is broken.
        let week = currentDate.week;
        if (selectedDate) week = getWeek(selectedDate);

        const completeWeekStructure = generateDateStructure(
          data.current,
          data.last,
          week,
          data.forecast,
          data.past_forecast,
        );
        if (data.current.length) {
          const current = VolumeSerieWeekly(t('currSeason'), completeWeekStructure.current);
          current.zIndex = 1;
          hcSeries.push(current);
          cloneHCH.xAxis.plotLines[0].value = completeWeekStructure.currWeekPosition;
        }
        if (data.last.length) {
          const last = VolumeSerieWeekly(t('lastSeason'), completeWeekStructure.last);
          last.zIndex = 0;
          hcSeries.push(last);
        }

        if (data.forecast.length) {
          const futureAverage = VolumeSerieWeekly(t('forecast'), completeWeekStructure.forecastDataFuture);
          const futureRange = VolumeSerieWeeklyRange(
            t('range'),
            completeWeekStructure.forecastDataFuture,
            'future',
            'lowest',
            'highest',
          );

          futureAverage.zIndex = 1;
          futureAverage.color = '#120dd1';
          futureRange.linkedTo = ':previous';

          hcSeries.push(futureAverage);
          hcSeries.push(futureRange);
        }

        if (data.past_forecast.length) {
          const pastAverage = VolumeSerieWeekly(t('fridaPrevious'), completeWeekStructure.forecastDataPast);
          const pastRange = VolumeSerieWeeklyRange(
            t('previousWeeks'),
            completeWeekStructure.forecastDataPast,
            'past',
            'lowest',
            'highest',
          );

          pastAverage.zIndex = 1;
          pastAverage.color = '#fe9dd8';
          pastRange.linkedTo = ':previous';

          hcSeries.push(pastAverage);
          hcSeries.push(pastRange);
        }

        cloneHCH.tooltip.headerFormat = ToolTipHeaderFormat;

        cloneHCH.series = hcSeries;
        // Set the plotLine {CURRENT WEEK}
        setHighChartsOptions(cloneHCH);
      }
    };
    updateGraph();
  }, [movementSeasons, currentDate, t('currSeason')]);

  // Use effect to get the CommodityData
  useEffect(() => {
    // Method to get the Current week import
    const CancelToken = UtilsApi.cancelToken();
    const getCommodityData = async () => {
      const { abbr } = productDetail;
      const { location, productType } = optionsSelected;
      const volumes = await UtilsApi.productVolume(
        abbr,
        location,
        productType,
        'weekly',
        selectedDate,
        CancelToken.token,
      )
        .then((res) => res.data)
        .catch((e) => {
          if (!UtilsApi.isCancel(e)) {
            setError(e);
          }
        });
      if (volumes) {
        setVolumeSeasons({
          data: {
            current: volumes.current,
            last: volumes.last_season,
            forecast: volumes.forecast || [],
            past_forecast: volumes.past_forecast || [],
          },
        });
      } else {
        setError(new Error());
      }
    };

    if (optionsSelected && productDetail) getCommodityData();

    return function () {
      CancelToken.cancel();
    };
  }, [optionsSelected, selectedDate]);

  // Method to handle the Highchart when the currentCommodity and highChartsOptions hooks exists to handle
  // the loading and avoid the first and unique render element
  const renderHighCharts = () =>
    movementSeasons && highChartsOptions ? (
      <div className="weekly-graph-highcharts-container">
        <p className="weekly-graph-highcharts-ylabel">{t('pkgUnit')}</p>
        <HighchartsReact highcharts={Highcharts} options={highChartsOptions} />
      </div>
    ) : (
      <NoData error={error} />
    );

  const renderSelectCascader = () => {
    if (productDetail)
      return (
        <VolumeSelectCascader
          id="weekly-volume"
          origins={productDetail['origins_weekly']}
          setOptionsSelected={setOptionsSeleted}
          styleClass="volume-select-cascader-column"
        />
      );
    return '';
  };
  // Return Highchart
  return (
    <div className="daily-graph-main-container">
      <div className="weekly-graph-header">
        <hr className="weekly-divisor-responsive" />
        {renderSelectCascader()}
        <hr className="weekly-divisor-responsive" />
      </div>
      {renderHighCharts()}
    </div>
  );
};

export default WeeklyGraph;
