import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import OpportunityNotFound from './OpportunityNotFound';
import MarketHeaderCard from './header/MarketHeaderCard';
import OpportunityDetail from './opportunityDetail/OpportunityDetail';
import OpportunityTab from './opportunityTabs/OpportunityTab';
import { DialogProducerExpiredDocument } from 'components/Dialogs';
import UtilsApi from 'utils/api/UtilsApi';
import { getMarketplaceCommoditiesAction, setActiveProductAction } from 'redux/actions/products/actions';
import { setSelectedProgramAction } from 'redux/actions/programs/actions';
import './style.scss';

const MarketPlace = () => {
  const [opportunityCommited, setOpportunityCommited] = useState(false);
  const [opportunityNotFound, setOpportunityNotFound] = useState(false);
  const productList = useSelector((state) => state.products.productList);
  const selectedProgram = useSelector((state) => state.programs.id);
  const programs = useSelector((state) => state.programs.programs);
  const dispatch = useDispatch();
  const location = useLocation();
  const opportunityIdParam = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    const CancelToken = UtilsApi.cancelToken();
    dispatch(getMarketplaceCommoditiesAction(CancelToken.token));

    return () => {
      CancelToken.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    if (productList && productList.length) dispatch(setActiveProductAction(productList[0].abbr));
  }, [productList, dispatch]);

  useEffect(() => {
    if (opportunityIdParam) {
      const program = programs?.find((p) => p.id === Number(opportunityIdParam));
      if (program) dispatch(setSelectedProgramAction(program.id));
      else setOpportunityNotFound(true);
    }
  }, [programs, opportunityIdParam, dispatch]);

  return (
    <div className="market-place-container">
      <div className="mrkt-place-inner-container grid-2-columns__fixed">
        <div className="grid-fixed__left">
          <MarketHeaderCard />
          <OpportunityTab opportunityCommited={opportunityCommited} setOpportunityCommited={setOpportunityCommited} />
        </div>
        <div className="grid-fixed__right">
          {!selectedProgram && opportunityNotFound ? (
            <OpportunityNotFound onDismiss={() => setOpportunityNotFound(false)} />
          ) : (
            <OpportunityDetail opportunityCommited={opportunityCommited} />
          )}
        </div>
      </div>
      <DialogProducerExpiredDocument />
    </div>
  );
};

export default MarketPlace;
