import React, { useState, useEffect } from 'react';
import Document from '../../../../../components/Document';
import PropTypes from 'prop-types';
import eventDetailsSpect from '../../../../../utils/page_definition/marketplace/eventDetails';
import statusHandler from '../../../../../utils/marketplace/status/statusHandler';
import utilsApi from '../../../../../utils/api/UtilsApi';
import { GetDayWeekRow, timeFormat } from '../../../../../utils/common/DateHandler';
import './EventDetails.scss';

//TODO: i18n
const EventDetails = ({ eventData, infoMock }) => {
  const [eventState, setEventState] = useState();
  const [toggleMenu, setToggleMenu] = useState(false);
  const { eventDetails } = utilsApi;
  const { commitmentStatus, commitmentMessages, eventDetailStatus } = statusHandler;
  // page_definition
  const {
    eventStepperIcon,
    eventDocument,
    eventWarningIcon,
    eventErrorIcon,
    eventCollapsed,
    eventStepper,
  } = eventDetailsSpect;

  const {
    event: { date, last },
    id,
    status,
  } = eventData;

  const showDetails = 'Ver detalle',
    hideDetails = 'Ocultar detalle',
    errorMessageTitle = '¿Tienes algún problema?';

  const eventStatus = commitmentStatus(status);
  const statusMessage = commitmentMessages(status);

  useEffect(() => {
    if (infoMock.length) {
      setToggleMenu(true);
      setEventState(infoMock);
    }
  }, []);

  useEffect(() => {
    const getEventDetail = () => eventDetails(id).then((res) => setEventState(res.data));

    if (toggleMenu && !eventState && !infoMock.length) getEventDetail();
  }, [toggleMenu]);

  const handleToggleMenu = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  const renderDefault = () => {
    if (!toggleMenu) {
      return (
        <div data-test={eventCollapsed} className={`event-details-default event-status-${eventStatus}`}>
          <p className="evnt-dtls-dflt-txt">{last}</p>
          <p className="evnt-dtls-dflt-txt">{`${GetDayWeekRow(date)} • ${timeFormat(date)}`}</p>
          <button className="evnt-dlts-details-button" onClick={handleToggleMenu}>
            {showDetails}
          </button>
        </div>
      );
    }
    return '';
  };

  /*
   * @param {string} file
   * @return {React.Element} Document
   */
  const renderEvenDocument = (file) => {
    if (file)
      return (
        <Document
          dataTest={eventDocument}
          file={file}
          name="Descargar comprobante"
          styleClass="evnt-dtls-download-document"
        />
      );
    return '';
  };

  const renderToggleMenu = () => {
    if (toggleMenu && eventState)
      return (
        <div className="evnt-dtls-toggle-menu">
          <div className="evnt-dtls-tggle-header">
            <h3>Detalles de la entrega</h3>
            <button className="evnt-dlts-details-button" onClick={handleToggleMenu}>
              {hideDetails}
            </button>
          </div>
          <hr className="evnt-dtls-tggle-divisor" />
          <div className="evnt-dtls-tggle-content">
            {eventState.map((event, index) => {
              const { comment, created_at, type, attachments } = event;
              const file = attachments ? attachments[0]?.file || '' : '';
              return (
                <div className="evnt-dtls-tggle-cntnt-data-row" key={index}>
                  <div className="evnt-dtls-icon-cntnt">
                    {eventDetailStatus(type, { eventStepperIcon, eventWarningIcon, eventErrorIcon })}
                    <p className="evnt-dtls-dflt-txt">{comment}</p>
                  </div>
                  <div className="evnt-dtls-download-row">
                    <span>{renderEvenDocument(file)}</span>
                    <p className="evnt-dtls-dflt-txt">{`${GetDayWeekRow(created_at)} • ${timeFormat(created_at)}`}</p>
                  </div>
                  {eventState.length > 1 && index < eventState.length - 1 && (
                    <div className="evnt-dtls-dflt-stppr" data-test={eventStepper} />
                  )}
                </div>
              );
            })}
          </div>
          {eventStatus === 'error' ? <h5 className="error-message-title">{errorMessageTitle}</h5> : ''}
          <hr className="status-message-divisor" />
          <div className="status-message-container">{statusMessage}</div>
        </div>
      );
    return '';
  };

  return (
    <div className={`event-details-container event-status-${eventStatus}`}>
      {renderDefault()}
      {renderToggleMenu()}
    </div>
  );
};

EventDetails.defaultProps = {
  infoMock: [],
};

EventDetails.propTypes = {
  infoMock: PropTypes.array,
  eventData: PropTypes.object.isRequired,
};

export default EventDetails;
