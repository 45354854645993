export default {
  /**
   * Received N amoount of strings/rules and generates a fusion of them with specific format
   * @param { Array } allStrings each string to be concatenated
   * @param { Array } rules amount of characters to be matched with received strings
   * @returns { String } each received string splited according to selected rules, dash separated
   */
  slashCombiner: (allStrings, rules) => {
    if (!Array.isArray(allStrings) && !Array.isArray(rules) && allStrings.length !== rules.length) return null;

    return allStrings
      .map((element, index) => {
        if (!rules[index]) return element;

        const myRegex = new RegExp(`(.{${rules[index]}})...+`);
        return element.replace(myRegex, '$1...');
      })
      .join('  /  ')
      .toUpperCase();
  },

  /**
   * @param { String } str String to be camel case formated
   * @returns { String } same receibed string in camel case format
   */
  toKeyFormat: (str) => str.replace(/\s(\w)/g, (_, str) => str.toUpperCase()),

  /*
   * @param { String } str string to capitalize the first letter.
   * @returns { String } with first letter
   */
  capitalizeFirstLetter: (str) => (typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : ''),
};

/**
 * @param {string} str file path
 * @returns {string} text after the last slash ('/')
 */
export const getFileName = (str = '') => str.split('/').pop();
