import React from 'react';

/*eslint-disable*/
const CheckStatusIcon = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 16 16">
    <title>CheckStatusIcon</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icn/Help">
        <rect id="Icon-viewport" fillOpacity="0" fill="#313233" x="0" y="0" width="16" height="16"></rect>
        <path
          d="M4.4125,14.425 C4.2625,14.425 4.0875,14.37587 3.9625,14.253045 L0.1875,10.5437308 C-0.0625,10.2980809 -0.0625,9.90504094 0.1875,9.68395599 C0.4375,9.43830604 0.8375,9.43830604 1.0625,9.68395599 L4.4125,12.9756653 L14.9375,2.60923746 C15.1875,2.36358751 15.5875,2.36358751 15.8125,2.60923746 C16.0625,2.85488741 16.0625,3.24792733 15.8125,3.46901228 L4.8625,14.253045 C4.7375,14.37587 4.5875,14.425 4.4125,14.425 L4.4125,14.425 Z"
          id="Icn"
          fill="#141414"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
);

export default CheckStatusIcon;
