import { useCallback, useEffect, useState } from 'react';

/**
 * @param {string} key
 * @param {string} initialValue
 * @param {string} [storageType='sessionStorage'] - oneOf ['sessionStorage', 'localStorage']
 */
const useWebStorage = (key, initialValue = '', storageType = 'sessionStorage') => {
  const [value, setValue] = useState(() => window[storageType].getItem(key) || initialValue);

  const setItem = (newValue) => {
    setValue(newValue);
    window[storageType].setItem(key, newValue);
  };

  const handleStorage = useCallback(
    (ev) => {
      if (ev.key === key && ev.newValue !== value) {
        setValue(ev.newValue || initialValue);
      }
    },
    [value, initialValue, key],
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage]);

  return [value, setItem];
};

export default useWebStorage;
