import React, { useEffect, useState } from 'react';
import Forecast from './alertInfo/Forecast';
import NoData from '../../../../components/NoData';
import PropTypes from 'prop-types';
import ToggleSwitch from '../../../../components/ToggleSwitch';
import UtilsApi from '../../../../utils/api/UtilsApi';
import WeatherIcons from '../../../../utils/packages/WeatherIcons';
import stringHandlers from '../../../../utils/common/stringHandlers';
import weatherAlertsSelectors from '../../../../utils/page_definition/wather_alerts';
import { OkIcon, WarningIcon } from '../../../../assets/icons/assets';
import { TempUnits } from '../../../../utils/weather/TempUnits';
import { useDispatch, useSelector } from 'react-redux';
import { setTemperatureUnitAction } from '../../../../redux/actions/products/actions';
import { useTranslation } from 'react-i18next';
import './Weather.scss';

const Weather = ({ weatherInfoMock }) => {
  // If you have to set the warning color use the class: warning-red-color
  const [weatherInfo, setWeatherInfo] = useState(weatherInfoMock);
  const [activeForecast, setActiveForecast] = useState(undefined);
  const [tUnit, setTUnit] = useState(null);
  const [error, setError] = useState();
  const { t } = useTranslation('weatherAlerts');

  const productDetail = useSelector((state) => state.products[state.products.active]);
  const tempUnit = useSelector((state) => state.products.tempUnit);
  const selectedDate = useSelector((state) => state.selectedDate.date);

  const dispatch = useDispatch();

  useEffect(() => {
    const CancelToken = UtilsApi.cancelToken();
    const getWeatherInfo = () => {
      const { abbr } = productDetail;
      setError();
      UtilsApi.weatherInfo(abbr, TempUnits[tempUnit], selectedDate, CancelToken.token)
        .then((res) => {
          setTUnit(tempUnit);
          setWeatherInfo(res.data);
        })
        .catch((err) => {
          setWeatherInfo([]);
          setError(err);
        });
    };
    if (!weatherInfoMock.length && productDetail) getWeatherInfo();

    return function () {
      CancelToken.cancel();
    };
  }, [weatherInfoMock, tempUnit, selectedDate, productDetail]);

  useEffect(() => {}, [error]);

  /**
   * method to handler the row click and activate with activeForecast hook.
   * @param {rowID} string slug from forecast service managed as id.
   */
  const forecastOnClickHandler = (e, rowId) => {
    e.preventDefault();
    if (activeForecast === rowId) setActiveForecast('');
    else setActiveForecast(rowId);
  };

  /**
   * method to render the forecast Component
   * @param {string} slug to get the forecast info
   * @return {React.Element} td with the forecast object
   */
  const renderForecast = (locationSlug) => {
    return (
      <tr>
        <td
          data-test={weatherAlertsSelectors.forecastContainer}
          className="weather-forecast-daily-container"
          colSpan="6"
        >
          <Forecast locationSlug={locationSlug} productSlug={productDetail.abbr} tempUnit={tUnit} />
        </td>
      </tr>
    );
  };

  /**
   *
   * method to render the collapse button
   * @param {string} rowId slug from forecast service managed as id.
   * @param {number} warning_forecast
   * @return {string} label to set in weather row
   */
  const renderButtonCollapse = (rowId, warning_forecast) => {
    if (activeForecast === rowId && warning_forecast) return t('hideAlerts');
    if (activeForecast === rowId && !warning_forecast) return t('hideForecast');
    if (warning_forecast) return `${warning_forecast} ${t('moreAlerts')}`;
    return t('expandForecast');
  };

  const warningLogic = (label) => (label ? 'warning-red-color' : '');

  /**
   * method to render the table body
   */
  const renderTableBody = () => {
    // loading logic or error handler {INCOMPLETE}
    return weatherInfo.map((rowData, i) => {
      const { description, temp_max, location, temp_min, is_high_temp, is_low_temp, forecast_warnings } = rowData;

      return (
        <React.Fragment key={i}>
          <tr
            className={`${activeForecast === location.slug && 'weather-table-active-row'} weather-table-body-row`}
            data-test={`${weatherAlertsSelectors.weatherRow}-${i}`}
            onClick={(e) => forecastOnClickHandler(e, location.slug)}
            role="button"
          >
            <td data-test={weatherAlertsSelectors.weatherStatus} className="weather-table-status-row">
              {forecast_warnings ? (
                <WarningIcon
                  dataTest={weatherAlertsSelectors.warningIcon}
                  styleClass="weather-table-status-icon"
                  fillColor="#F02929"
                />
              ) : (
                <OkIcon dataTest={weatherAlertsSelectors.okIcon} styleClass="weather-table-status-icon" />
              )}
            </td>
            <td
              data-test={weatherAlertsSelectors.weatherGrowingRegion}
              className="weather-table-growing-region growing-text table-text-overflow row-left"
            >
              {location.full_name}
            </td>
            <td
              data-test={weatherAlertsSelectors.weatherDescription}
              className={`weather-table-description description-text table-text-overflow row-left desktop-layout ${
                forecast_warnings ? 'warning-red-color' : ''
              }`}
            >
              <div className="weather-description-row">
                <div className="weather-description-icon">
                  <WeatherIcons styleClass="weather-table-alert-type-icon" icon={description.toUpperCase()} />
                </div>
                <div className="weather-description-text">
                  {t(`weather.${stringHandlers.toKeyFormat(description)}`)}
                </div>
              </div>
            </td>
            <td
              id="weather-table-alert-text"
              className="desktop-layout"
              data-test={weatherAlertsSelectors.weatherTableAlertText}
            >
              {renderButtonCollapse(location.slug, forecast_warnings)}
            </td>
            <td
              data-test={weatherAlertsSelectors.weatherTableDegressMax}
              className={`weather-table-degrees ${warningLogic(is_high_temp)} `}
            >
              {`${Math.round(temp_max)}°${tUnit}`}
            </td>
            <td
              data-test={weatherAlertsSelectors.weatherTableDegressMin}
              className={`weather-table-degrees ${warningLogic(is_low_temp)}`}
            >
              {`${Math.round(temp_min)}°${tUnit}`}
            </td>
          </tr>
          {activeForecast === location.slug && renderForecast(location.slug)}
        </React.Fragment>
      );
    });
  };

  const renderTable = () => {
    if (weatherInfo.length) {
      return (
        <table data-test={weatherAlertsSelectors.weatherTable} className="weather-table">
          <thead className="weather-table-head">
            <tr className="weather-table-head-row">
              <th className="weather-table-status-row">{t('status')}</th>
              <th className="growing-text row-left">{t('region')}</th>
              <th className="description-text row-left desktop-layout">{t('condition')}</th>
              <th className="weather-table-alert desktop-layout" />
              <th className="weather-table-degrees">{t('highest')}</th>
              <th className="weather-table-degrees">{t('lowest')}</th>
            </tr>
          </thead>
          <tbody className="weather-table-body">{renderTableBody()}</tbody>
        </table>
      );
    } else return <NoData error={error} loading={false} />;
  };

  const handlerToggle = (value) => dispatch(setTemperatureUnitAction(value));

  return (
    <div data-test={weatherAlertsSelectors.weatherContainer} className="weather-container">
      <div className="weather-header">
        <div className="weather-header-title">
          <h3>{t('title')}</h3>
        </div>
        <div className="weather-header-description">
          <p>
            {' '}
            {t('description')} <b> {t('descriptionBold')} </b>{' '}
          </p>
          <ToggleSwitch
            falseValue="c"
            inheritHook={handlerToggle}
            label={t('units')}
            styleClass="forecast-toggle-labels"
            trueValue="f"
          />
        </div>
      </div>
      <div className="weather-content">{renderTable()}</div>
    </div>
  );
};

Weather.defaultProps = {
  productDetail: undefined,
  weatherInfoMock: [],
};

Weather.propTypes = {
  productDetail: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date),
  weatherInfoMock: PropTypes.array,
  tempUnit: PropTypes.string.isRequired,
  setTemperatureUnitAction: PropTypes.func,
};

export default Weather;
