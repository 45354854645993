import React from 'react';

/*eslint-disable*/
const SystemErrorIcon = ({ dataTest, ...other }) => {
  return (
    <svg width={16} height={16} data-test={dataTest} viewBox="0 0 16 16" {...other}>
      <path d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,5.87826808 15.1571453,3.84343678 13.6568542,2.34314575 C12.1565632,0.842854723 10.1217319,0 8,0 Z M8,14.4 C4.4653776,14.4 1.6,11.5346224 1.6,8 C1.6,4.4653776 4.4653776,1.6 8,1.6 C11.5346224,1.6 14.4,4.4653776 14.4,8 C14.4,9.69738553 13.7257162,11.3252506 12.5254834,12.5254834 C11.3252506,13.7257162 9.69738553,14.4 8,14.4 Z M11.6422018,10.1138952 L9.48623853,7.97266515 L11.6697248,5.80410023 C12.1100917,5.3667426 12.1100917,4.76537585 11.6697248,4.32801822 C11.2293578,3.89066059 10.6238532,3.89066059 10.1834862,4.32801822 L7.97247706,6.523918 L5.81651376,4.38268793 C5.40366972,3.91799544 4.79816514,3.91799544 4.35779817,4.35535308 C3.91743119,4.79271071 3.91743119,5.39407745 4.35779817,5.83143508 L6.5412844,8 L4.33027523,10.1958998 C3.88990826,10.6332574 3.88990826,11.2346241 4.33027523,11.6719818 C4.7706422,12.1093394 5.37614679,12.1093394 5.81651376,11.6719818 L8.05504587,9.44874715 L10.2385321,11.6173121 C10.6788991,12.0546697 11.2844037,12.0546697 11.7247706,11.6173121 C12.0825688,11.1526196 12.0825688,10.5512528 11.6422018,10.1138952 Z"></path>
    </svg>
  );
};

export default SystemErrorIcon;
