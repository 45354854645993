import React from 'react';

/* eslint-disable */
const PackageIcon = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 17 17">
    <title>Package</title>
    <g id="Workshop-package" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="01.0-Workshop-Tab-exploration-single-page" transform="translate(-158.000000, -415.000000)">
        <g id="Section-header-package" transform="translate(158.000000, 359.000000)">
          <g id="icn-package" transform="translate(0.000000, 56.000000)">
            <rect id="Icon-viewport" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="14" height="14"></rect>
            <path
              d="M13.9138462,4.26461538 L12.8153846,0.990769231 C12.6430769,0.409230769 12.1907692,0.0646153846 11.6092308,0.0646153846 L2.64923077,0.0646153846 C2.06769231,0.0646153846 1.61538462,0.409230769 1.44307692,0.926153846 L0.236923077,4.2 C0.172307692,4.37230769 0.172307692,4.54461538 0.172307692,4.65230769 L0.172307692,11.9538462 C0.172307692,12.7076923 0.753846154,13.2676923 1.48615385,13.2676923 L12.6861538,13.2676923 C13.3753846,13.2676923 14,12.6861538 14,11.9538462 L14,4.67384615 C13.9784615,4.54461538 13.9784615,4.37230769 13.9138462,4.26461538 Z M11.7384615,1.33538462 L12.6430769,4.09230769 L7.64615385,4.09230769 L7.64615385,1.22769231 L11.6092308,1.22769231 C11.6738462,1.22769231 11.7384615,1.27076923 11.7384615,1.33538462 Z M2.54153846,1.33538462 C2.54153846,1.27076923 2.60615385,1.22769231 2.64923077,1.22769231 L6.50461538,1.22769231 L6.50461538,4.09230769 L1.50769231,4.09230769 L2.54153846,1.33538462 Z M12.6430769,12.1261538 L1.50769231,12.1261538 C1.4,12.1261538 1.33538462,12.0615385 1.33538462,11.9538462 L1.33538462,5.23384615 L12.8153846,5.23384615 L12.8153846,11.9538462 C12.8153846,12.0830769 12.7723077,12.1261538 12.6430769,12.1261538 L12.6430769,12.1261538 Z"
              id="Shape-package"
              fill="#313233"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PackageIcon;
