import { string } from 'prop-types';
import React from 'react';

const NavbarMarketplaceInactive = ({ dataTest, ...other }) => (
  <svg data-test={dataTest} viewBox="0 0 32 32" {...other}>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <rect fill="#B8B6F1" x="13" width="15" height="28" rx="4" />
      <rect fill="#5956DF" width="11" height="7" rx="2" />
      <rect fill="#A09EED" y="11" width="10" height="7" rx="2" />
      <rect fill="#A09EED" y="21" width="8" height="7" rx="2" />
    </g>
  </svg>
);

NavbarMarketplaceInactive.propTypes = {
  dataTest: string,
};

export default NavbarMarketplaceInactive;
