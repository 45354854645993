// Matches main structure with values provided by API
const fillGraphValues = (mainStructure, givenValues) =>
  mainStructure.map((currVal) => {
    const receivedValue = givenValues.find((element) => element.week === currVal.week);
    return receivedValue || currVal;
  });

/**
 * Checks wether we have a 53 week value in our payload or not
 * @param {array} payload to check
 * @returns {Boolean}
 */
const verify53Weeks = (payload) => {
  return payload.filter((e) => e.week === 53).length > 0;
};

/**
 * Creates a set of dates to keep Date labels in graph properly handled
 * @param {Array} current Recent data
 * @param {Array} last Data from last season
 * @returns {Object} {Array, Array} Object {current, last} of Arrays with all possible labels
 */
const generateDateStructure = (currentData, lastData, currentWeek, forecastFuture = [], forecastPast = []) => {
  let is53Weeks = verify53Weeks(forecastFuture);
  if (!is53Weeks) is53Weeks = verify53Weeks(currentData);
  if (!is53Weeks) is53Weeks = verify53Weeks(lastData);
  if (!is53Weeks) is53Weeks = verify53Weeks(forecastPast);
  const weeksAmount = is53Weeks ? 53 : 52;
  const weeksSplit = is53Weeks ? 8 : 7;

  const allWeeks = [...Array(weeksAmount).keys()].map((i) => i + 1);

  // Select where to split the sequence to keep just 8 weeks 'in the future'
  let splitPoint = currentWeek + weeksSplit;
  if (splitPoint > weeksAmount) splitPoint -= weeksAmount;
  allWeeks.unshift(...allWeeks.splice(splitPoint));

  // This is where plotLine will be printed
  const currWeekPosition = allWeeks.indexOf(currentWeek);

  // Create a structure to keep track of labels in graph
  const graphValues = [...allWeeks].map((week) => ({ total: null, week }));

  const current = fillGraphValues(graphValues, currentData);
  if (lastData.length) {
    const last = fillGraphValues(graphValues, lastData);

    let forecastDataFuture = [];
    let forecastDataPast = [];
    if (forecastFuture.length) forecastDataFuture = fillGraphValues(graphValues, forecastFuture);
    if (forecastPast.length) forecastDataPast = fillGraphValues(graphValues, forecastPast);

    return { current, last, currWeekPosition, forecastDataFuture, forecastDataPast };
  }

  return { current, currWeekPosition };
};

export default generateDateStructure;
