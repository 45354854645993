import React from 'react';

/*eslint-disable*/
const CancelStatusIcon = ({ styleClass, dataTest, ...other }) => (
  <svg data-test={dataTest} className={styleClass} viewBox="0 0 16 16" {...other}>
    <g id="Symbols-cancel-status-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-system-cancel-status-icon" fill="#141414" fillRule="nonzero">
        <g id="Icn-cancel-status-icon" transform="translate(0.937771, 0.730664)">
          <path
            d="M14.4338172,13.0019259 L8.8211571,7.38926586 L14.4603337,1.75008928 C14.8845977,1.32582521 14.8845977,0.74246212 14.4603337,0.318198052 C14.0360696,-0.106066017 13.4527065,-0.106066017 13.0284424,0.318198052 L7.36274936,5.98389114 L1.75008928,0.37123106 C1.35234172,-0.0795495129 0.768978625,-0.0795495129 0.344714556,0.344714556 C-0.0795495129,0.768978625 -0.0795495129,1.35234172 0.344714556,1.77660579 L5.98389114,7.41578237 L0.318198052,13.0814755 C-0.106066017,13.5057395 -0.106066017,14.0891026 0.318198052,14.5133667 C0.74246212,14.9376308 1.32582521,14.9376308 1.75008928,14.5133667 L7.44229887,8.8211571 L13.0814755,14.4603337 C13.5057395,14.8845977 14.0891026,14.8845977 14.5133667,14.4603337 C14.8580812,14.0095531 14.8580812,13.42619 14.4338172,13.0019259 Z"
            id="Path-cancel-status-icon"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default CancelStatusIcon;
