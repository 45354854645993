import React from 'react';

/*eslint-disable*/
const CheckSmall = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 8 7">
    <g id="Symbols-check-small" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Checkbox/Active-check-small" transform="translate(-4.000000, -5.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="icn-system-check-small-icon" transform="translate(4.000000, 5.000000)">
          <path
            d="M1.23636364,3.85454545 C0.945454545,3.56363636 0.509090909,3.56363636 0.218181818,3.85454545 C-0.0727272727,4.14545455 -0.0727272727,4.58181818 0.218181818,4.87272727 L1.67272727,6.32727273 C1.81818182,6.47272727 1.96363636,6.54545455 2.18181818,6.54545455 C2.4,6.54545455 2.54545455,6.47272727 2.69090909,6.32727273 L7.78181818,1.23636364 C8.07272727,0.945454545 8.07272727,0.509090909 7.78181818,0.218181818 C7.49090909,-0.0727272727 7.05454545,-0.0727272727 6.76363636,0.218181818 L2.18181818,4.8 L1.23636364,3.85454545 Z"
            id="Icn/System/Check-Small-icon"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckSmall;
