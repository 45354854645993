import React from 'react';

/* eslint-disable */
const WeatherScatteredClouds = ({ styleClass, isWarning }) => (
  <svg className={styleClass} viewBox="0 0 24 24">
    <title>Scattered Clouds</title>
    <defs>
      <path
        d="M5.26086957,16 L16.7391304,16 C19.7493043,16 22,12.8157538 22,9.96923077 C22,7.21132308 19.8889565,4.13833846 17.0203478,3.94732308 C16.3890435,1.63741538 14.320087,0 11.9565217,0 C9.48965217,0 7.39295652,1.7664 6.85347826,4.19938462 C6.33408696,4.02609231 5.80034783,3.93846154 5.26086957,3.93846154 C2.24973913,3.93846154 0,7.12270769 0,9.96923077 C0,12.8157538 2.24973913,16 5.26086957,16 Z M5.28571429,4.92982456 C5.88571429,4.92982456 6.47809524,5.06442105 7.04761905,5.33066667 C7.18666667,5.39649123 7.34571429,5.38764912 7.48,5.31003509 C7.61238095,5.2314386 7.70095238,5.09291228 7.71714286,4.93670175 C7.95714286,2.69277193 9.77714286,1 11.952381,1 C13.9857143,1 15.7514286,2.49136842 16.152381,4.54568421 C16.1980952,4.77950877 16.4104762,4.93768421 16.6333333,4.93964912 C16.667619,4.93866667 16.7009524,4.93473684 16.7142857,4.92982456 C19.127619,4.92982456 21,7.63649123 21,9.96491228 C21,12.2933333 19.127619,15 16.7142857,15 L5.28571429,15 C2.87238095,15 1,12.2933333 1,9.96491228 C1,7.63649123 2.87238095,4.92982456 5.28571429,4.92982456 Z"
        id="path-weather-scattered-clouds"
      ></path>
    </defs>
    <g id="Symbols-scattered-clouds" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-weather-scattered-clouds">
        <g id="Weather_Icons-scattered-clouds" transform="translate(1.000000, 5.000000)">
          <mask id="mask-weather-scattered-clouds" fill="white">
            <use xlinkHref="#path-weather-scattered-clouds"></use>
          </mask>
          <use
            id="Shape-scattered-clouds"
            stroke={isWarning ? '#e40d2b' : '#141414'}
            strokeWidth="0.5"
            fill={isWarning ? '#e40d2b' : '#141414'}
            fillRule="nonzero"
            xlinkHref="#path-weather-scattered-clouds"
          ></use>
          <g id="Neutral-/-900-scattered-clouds" mask="url(#mask-weather-scattered-clouds)">
            <g transform="translate(-1.000000, -5.000000)">
              <path d="M8,0 L24,0 L24,0 L24,24 L8,24 C3.581722,24 5.41083001e-16,20.418278 0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WeatherScatteredClouds;
