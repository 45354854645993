import {
  SET_SHIPPING_POINT,
  GET_DEFAULT_CROSSING,
  SET_SP_PRODUCT_TYPE,
  SET_SP_PACKAGE_TYPE,
} from '../actions/shippingPoint/types';
import { combineReducers } from 'redux';

const initialState = { id: '', label: '', value: '' };

const Locations = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPPING_POINT: {
      return {
        ...state,
        id: action.shippingPoint.id,
        label: action.shippingPoint.label,
        value: action.shippingPoint.value,
      };
    }
    case GET_DEFAULT_CROSSING: {
      return {
        ...state,
        id: action.shippingPoint.id,
        label: action.shippingPoint.label,
        value: action.shippingPoint.value,
      };
    }
    default: {
      return state;
    }
  }
};

const ProductTypes = (state = initialState, action) => {
  switch (action.type) {
    case SET_SP_PRODUCT_TYPE: {
      return {
        ...state,
        id: action.productType.id,
        label: action.productType.label,
        value: action.productType.value,
      };
    }
    default: {
      return state;
    }
  }
};

const PackageType = (state = initialState, action) => {
  switch (action.type) {
    case SET_SP_PACKAGE_TYPE: {
      return {
        ...state,
        id: action.packageType.id,
        label: action.packageType.label,
        value: action.packageType.value,
      };
    }
    default: {
      return state;
    }
  }
};

const shippingPoint = combineReducers({
  Locations,
  ProductTypes,
  PackageType,
});

export default shippingPoint;
