import ProductDefault from '../../../utils/product/ProductDefault';
import utilsApi from '../../../utils/api/UtilsApi';
import {
  CLEAN_PRODUCTS,
  SET_PRODUCTS,
  SET_ACTIVE_PRODUCT,
  SET_PRODUCT_DETAIL,
  SET_PRODUCTS_ERROR,
  SET_PRODUCTS_TEMP,
} from './types';
import { TempUnits } from '../../../utils/weather/TempUnits';

const { getProducts, getProductDetail, getMarketplaceCommodities } = utilsApi;

export const getProductsAction = () => (dispatch) => {
  getProducts()
    .then((resp) => dispatch({ type: SET_PRODUCTS, payload: ProductDefault(resp.data, 'rasp', 'asp') }))
    .catch((err) => dispatch({ type: SET_PRODUCTS_ERROR, payload: err.message }));
};

export const setActiveProductAction = (active_product) => ({ type: SET_ACTIVE_PRODUCT, payload: active_product });

export const cleanProductAction = () => ({ type: CLEAN_PRODUCTS });

/**
 * This action checks if we already have that product detail, if so, we also validate if the
 * report date its also the same. This is to avoid redundant api calls
 */
export const getProductDetailAction = (CancelToken) => (dispatch, getState) => {
  const reportDate = getState().selectedDate.date;
  const activeTab = getState().products.active;
  const tempUnit = getState().products.tempUnit;
  //TODO: Previous ProductDetail state validation? according with Chrome, this call is being cached
  getProductDetail(activeTab, reportDate, TempUnits[tempUnit], CancelToken).then((resp) => {
    const product_detail = resp.data;
    product_detail['report_date'] = reportDate;
    dispatch({
      type: SET_PRODUCT_DETAIL,
      payload: product_detail,
    });
  });
};

/*
 * getMarketplaceCommoditiesAction to make the backend request.
 * @param {string} CancelToken token to handle the cancel
 */
export const getMarketplaceCommoditiesAction = (CancelToken) => (dispatch) => {
  getMarketplaceCommodities(CancelToken)
    .then((resp) => dispatch({ type: SET_PRODUCTS, payload: ProductDefault(resp.data, 'bluby') }))
    .catch((err) => dispatch({ type: SET_PRODUCTS_ERROR, payload: err.message }));
};

export const setTemperatureUnitAction = (tempUnit) => ({ type: SET_PRODUCTS_TEMP, payload: tempUnit });
