import React from 'react';
import {
  WeatherBrokenClouds,
  WeatherClearSky,
  WeatherFewClouds,
  WeatherHail,
  WeatherHighTemperature,
  WeatherLowTemperature,
  WeatherMist,
  WeatherRain,
  WeatherScatteredClouds,
  WeatherShowerRain,
  WeatherSnow,
  WeatherSunny,
  WeatherThunderstorm,
} from '../../assets/icons/assets';

const WeatherIcons = ({ icon, styleClass, isWarning = false }) => {
  const dictionary = {
    'BROKEN CLOUDS': <WeatherBrokenClouds styleClass={styleClass} isWarning={isWarning} />,
    'CLEAR SKY': <WeatherClearSky styleClass={styleClass} isWarning={isWarning} />,
    'FEW CLOUDS': <WeatherFewClouds styleClass={styleClass} isWarning={isWarning} />,
    HAIL: <WeatherHail styleClass={styleClass} isWarning={isWarning} />,
    'HEAVY INTENSITY RAIN': <WeatherShowerRain styleClass={styleClass} isWarning={isWarning} />,
    'VERY HEAVY RAIN': <WeatherShowerRain styleClass={styleClass} isWarning={isWarning} />,
    'HIGH TEMPERATURE': <WeatherHighTemperature styleClass={styleClass} isWarning={isWarning} />,
    'LIGHT RAIN': <WeatherRain styleClass={styleClass} isWarning={isWarning} />,
    'LOW TEMPERATURE': <WeatherLowTemperature styleClass={styleClass} isWarning={isWarning} />,
    MIST: <WeatherMist styleClass={styleClass} isWarning={isWarning} />,
    'MODERATE RAIN': <WeatherRain styleClass={styleClass} isWarning={isWarning} />,
    RAIN: <WeatherRain styleClass={styleClass} isWarning={isWarning} />,
    'SCATTERED CLOUDS': <WeatherScatteredClouds styleClass={styleClass} isWarning={isWarning} />,
    'SHOWER RAIN': <WeatherShowerRain styleClass={styleClass} isWarning={isWarning} />,
    SNOW: <WeatherSnow styleClass={styleClass} isWarning={isWarning} />,
    SUNNY: <WeatherSunny styleClass={styleClass} isWarning={isWarning} />,
    THUNDERSTORM: <WeatherThunderstorm styleClass={styleClass} isWarning={isWarning} />,
  };

  if (dictionary[icon]) return dictionary[icon];
  return dictionary['SUNNY'];
};

export default WeatherIcons;
