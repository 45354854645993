/*
 * package type select parsed
 * @param {array} packages
 * @return {array}
 */
const packagesTypesSelectData = (packages) =>
  packages.map((packageType) => {
    const packageReplaced = packageType.package.replace(/ /g, '-');
    return {
      id: packageReplaced,
      label: packageType.package,
      value: packageType.package,
    };
  });

export default packagesTypesSelectData;
