import React, { useEffect, useRef } from 'react';
import { bool, element, string, node } from 'prop-types';
import Portal from '../../lib/Portal';
import { FridaLogoText } from '../../assets/icons/assets';
import './style.scss';

const Modal = ({ children, className, icon, focused = true, footer, noTransition = false }) => {
  const elRef = useRef();

  useEffect(() => {
    const previousFocusedEl = document.activeElement;
    document.body.style.overflow = 'hidden';

    requestAnimationFrame(() => {
      elRef.current.classList.add('modal--active');
    });

    return () => {
      document.body.style.overflow = '';
      previousFocusedEl.focus();
    };
  }, []);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (focused) {
        elRef.current.focus();
      }
    });
  }, [focused]);

  return (
    <Portal appendTo={window['modal-backdrop']}>
      <div ref={elRef} className={`modal w100 h100 ${className} ${noTransition && 'no-transition'}`} tabIndex="-1">
        <div className="modal__dialog br-l">
          <div className="modal__content primary-100-bg br-inherit">
            <div className="modal__body p-xl white-100-bg br-inherit">
              <div className="modal__header df pb-m">
                <div className="modal__header-icon f1">{icon}</div>
                <div>
                  <FridaLogoText styleClass="modal__header-logo" />
                </div>
              </div>
              {children}
            </div>
            <div>{footer}</div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

Modal.propTypes = {
  /**
   * The content of the component.
   */
  children: node.isRequired,
  /**
   *
   */
  className: string,
  /**
   * If false the modal will be not focused
   * @default true
   */
  focused: bool,
  /**
   * Element placed before the header’s logo.
   */
  icon: element,
  /**
   * HTML element or component
   */
  footer: element,
  /**
   * If `true` the component will not show the onmount transition.
   * @default false
   */
  noTransition: bool,
};

export default Modal;
