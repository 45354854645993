/*
 * method to return array
 * @param {number} object.minAmmount Minimun number of boxes to start the list
 * @param {number} object.stepsBase amount of steps
 * @param {number} object.stepsIncrement amount of boxes on each step
 * @param {number} object.factor number to multiply in order to get the boxes number if exist. ex: pallet and trucks, FALSE by default
 * @param {number} object.divide number to divide in order to get the boxes number if exist. ex: Kgs, lbs, FALSE by default
 * @param {array}
 *
 */
const unitsArrayHandler = ({ minAmmount, stepsBase, stepsIncrement, factor = false, divide = false }) => {
  if (stepsBase > 0 && stepsIncrement)
    return [...Array(stepsBase).keys()].map((e) => {
      const label = minAmmount + stepsIncrement * e;
      let value = label;
      // according to https://floating-point-gui.de/basic/ a good way to manage the floating point when the needed is
      // just the integer is applying a round to that number.
      if (divide) value = Math.round(label / divide);
      else if (factor) value = label * factor;
      const parsedLabel = label % 1 === 0 ? label : label.toFixed(2);
      return { id: e, label: parseFloat(parsedLabel).toLocaleString(), value };
    });
  else return [];
};

export default unitsArrayHandler;
