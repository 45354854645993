import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

/*
 * Button
 *
 * props:
 *  - children: {object} required, to render inside the button.
 *  - disabled: {bool} default: {false} to specify if the button is disabled or not.
 *  - onClick: {func}  required, onClick function as props.
 *  - shape: {string} default: {main}, 2 options: {'main' || 'complementary'} the shape type for the button.
 *  - size: {string} required, 4 options: {'big', 'medium', 'small', 'x-small'} the differents types for the button.
 *  - styleClass: {string} default: {'empty string'} 'for custom classes'.
 *  - type: {string} required, 4 options: {'primary', 'secondary', 'disabled', 'destructive'}.
 *
 */
const Button = (props) => {
  const { children, disabled, onClick, shape, size, styleClass, type, dataTest } = props;

  /**
   * variable to set the className{css class} to control the differents states, types and etc.
   */
  const className = `fk-button button-${type}-${shape} button-${size} ${styleClass}`;

  /**
   * Method to handle the onClick action.
   */
  const handleOnClick = (e) => {
    e.preventDefault();
    onClick(e.target);
  };

  return (
    <button data-test={dataTest} disabled={disabled} className={className} onClick={handleOnClick}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  dataTest: '',
  disabled: false,
  shape: 'main',
  styleClass: '',
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  shape: PropTypes.string,
  size: PropTypes.string.isRequired,
  styleClass: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default Button;
