import React from 'react';

/* eslint-disable */
const WeatherHighTemperature = ({ styleClass, isWarning }) => (
  <svg className={styleClass} viewBox="0 0 24 24">
    <title>Weather high temperature</title>
    <defs>
      <path
        d="M6,0 C8,0 9.2051121,0.901934259 9.29546404,3 L9.3,13.606 L9.38623615,13.6747993 C10.4612433,14.5672467 11.1372363,15.8428666 11.2741853,17.2265785 L11.2935121,17.487224 L11.3,17.75 C11.3,20.6726854 8.92268542,23.05 6,23.05 C3.07731458,23.05 0.7,20.6726854 0.7,17.75 C0.7,16.1677586 1.4043808,14.6788027 2.61376385,13.6747993 L2.7,13.606 L2.7,3 C2.7,0.947137201 4,0 6,0 Z M6,1.5 C5,1.5 4.37012413,2.29422691 4.30450345,3.53180193 L4.3,14.01 C4.3,14.2771574 4.16702187,14.5256739 3.94502958,14.674201 C2.91486014,15.3646829 2.3,16.5143682 2.3,17.75 C2.3,19.7903146 3.95968542,21.45 6,21.45 C8.04031458,21.45 9.7,19.7903146 9.7,17.75 C9.7,16.5143682 9.08513986,15.3646829 8.05517622,14.6743388 C7.86472071,14.5469117 7.73981457,14.3461243 7.70801394,14.123118 L7.7,14.01 L7.7,3.702 C7.7,2.38666537 7,1.5 6,1.5 Z M6,2.5 C6.55,2.5 7,2.783 7,3.5 L7,3.5 L7,15.711 C7.882,16.097 8.5,16.975 8.5,18 C8.5,19.381 7.381,20.5 6,20.5 C4.619,20.5 3.5,19.381 3.5,18 C3.5,16.975 4.118,16.097 5,15.711 L5,15.711 L5,3.5 C5,2.783 5.45,2.5 6,2.5 Z"
        id="path-weather-high-temperature"
      ></path>
    </defs>
    <g id="03-Icons-weather-high-temperature" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-weather-high-temperature">
        <g id="Weather_Icons-38" transform="translate(6.000000, 1.000000)">
          <mask id="mask-weather-high-temperature" fill="white">
            <use xlinkHref="#path-weather-high-temperature"></use>
          </mask>
          <use
            id="Combined-Shape-weather-high-temperature"
            fill={isWarning ? '#e40d2b' : '#231F20'}
            fillRule="nonzero"
            xlinkHref="#path-weather-high-temperature"
          ></use>
          <g
            id="Green-/-900-weather-high-temperature"
            mask="url(#mask-weather-high-temperature)"
            fill={isWarning ? '#e40d2b' : '#44e5052'}
          >
            <g transform="translate(-6.000000, -1.000000)">
              <path d="M8,0 L24,0 L24,0 L24,24 L8,24 C3.581722,24 5.41083001e-16,20.418278 0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WeatherHighTemperature;
