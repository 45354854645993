import React, { useState } from 'react';
import Message from '../../../../../../components/Message';
import ProductIcon from '../../../../../../utils/product/ProductIcons';
import PropTypes from 'prop-types';
import WebStorage from '../../../../../../utils/WebStorage/WebStorage';
import fixDigits from '../../../../../../utils/common/fixDigits';
import switchCaseHandler from '../../../../../../utils/common/switch';
import unitsHandler from '../../../../../../utils/marketplace/units/unitsHandler';
import useProgramTimer from '../../../../../../hooks/useProgramTimer';
import { Timer } from '../../../../../../assets/icons/assets';
import { GetProgramDate, timeFormat } from '../../../../../../utils/common/DateHandler';
import { connect } from 'react-redux';
import { setConfirmProgram } from '../../../../../../redux/actions/confirmProgram/actions';
import { setSelectedProgramAction } from '../../../../../../redux/actions/programs/actions';
import { toFixed, intFormat } from '../../../../../../utils/common/numberHandler';
import { useTranslation } from 'react-i18next';
import './OpportunityCard.scss';

const OpportunityCard = ({
  confirmProgram,
  data,
  programCommited,
  selectedProgram,
  setConfirmProgram,
  setSelectedProgramAction,
  unit,
}) => {
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const {
    boxes_pallet,
    destination_name,
    id,
    inscription_limit_minutes,
    is_organic,
    package_description,
    package_weight,
    pallets_truck,
    price,
    product_abbr,
    program_type,
    retailer_photo,
    start_date,
    total_boxes,
    weeks,
    skeleton_mode,
  } = data;

  const { t } = useTranslation(['unitsAbbr', 'Message', 'programsCard', 'general', 'fruits']);
  const { getCookieValue } = WebStorage;
  const localeID = getCookieValue('localeId');
  const spotProgramTimer = useProgramTimer(inscription_limit_minutes);
  const priceFixed = fixDigits(price);

  const { rawValue, unitLabel, unitIcons } = unitsHandler;

  const unitValue = (boxValue) => {
    const value = rawValue(unit, boxValue, pallets_truck, boxes_pallet, package_weight);
    if (unit === 'lb' || unit === 'kg') return intFormat(value);
    return parseFloat(toFixed(value)).toLocaleString() || 0;
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    if (confirmProgram) {
      setShowWarningMessage(true);
    } else {
      if (spotProgramTimer) setSelectedProgramAction(id);
    }
  };

  // onClick inherit events
  const handleOnPress = (e) => {
    const enterOrSpace = e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar' || e.which === 13 || e.which === 32;
    if (enterOrSpace) {
      e.preventDefault();
      if (confirmProgram) {
        setShowWarningMessage(true);
      } else {
        setSelectedProgramAction(id);
      }
    }
  };

  /* method to handle the message buttons actions
   * @param {boolean} action if true set the message to false and current moved to previous view
   */
  const handleMessageAction = (action) => {
    if (action) {
      setSelectedProgramAction(id);
      setConfirmProgram(false);
    }
    setShowWarningMessage(false);
  };

  /*
   * method to render commodity type
   */
  const renderCommodityType = () => (is_organic ? t('programsCard:organic') : t('programsCard:conventional'));

  // method to render warning message
  const renderWarningMessage = () => {
    if (showWarningMessage)
      return (
        <Message
          acceptText={t('Message:leave')}
          cancelText={t('Message:stay')}
          description={t('Message:leaveProgramMessageDescription')}
          handleMessage={handleMessageAction}
          title={t('Message:leaveProgramMessageTitle')}
          type="confirm"
        />
      );
    return '';
  };

  const renderProgramTimer = () => {
    if (spotProgramTimer)
      return (
        <div className="opportunity-card-content-header-time-info">
          <p className="opportunity-card-content-header-time-info-label">{t('programsCard:timeRemaining')}</p>
          <div className="opportunity-card-content-header-time-info-inner-row">
            <Timer styleClass="opportunity-card-content-header-time-info-icon" />
            <p className="opportunity-card-content-header-time-value">{spotProgramTimer}</p>
          </div>
        </div>
      );
    else
      return <div className="opportunity-card-content-header-time-info expired">{t('programsCard:expiredOffer')}</div>;
  };

  // method to return the program type
  // 1 normal
  // 2 spot
  const programTypeDictionary = (programType) => (programType === 'normal' ? 1 : 2);

  // method to render the program type
  const renderProgramTypeFlag = () =>
    switchCaseHandler({
      1: () => t('programsCard:program'),
      2: () => t('programsCard:spot'),
    })(t('programsCard:program'))(program_type);

  // method to add program type class
  const programTypeClass = () => {
    if (!spotProgramTimer && !skeleton_mode) return 'expired-program';
    else return program_type === 1 ? 'normal-program' : 'spot-program';
  };

  // method to render by program type
  const renderByProgramType = (programType, elementToRender) => (programType === program_type ? elementToRender : '');

  return (
    <div>
      <div
        className={`opportunity-card-wrapper opportunity-selected-${selectedProgram === id} ${programTypeClass()}
        opportunity-assigned-${programCommited}`}
        onClick={handleOnClick}
        onKeyPress={handleOnPress}
        role="button"
        tabIndex={0}
      >
        {/* program flag */}
        <div className={`opportunity-card-flag-type ${programTypeClass()}`}>
          <p className="opportunity-card-flag-type-label">{skeleton_mode ? '- - -' : renderProgramTypeFlag()}</p>
        </div>
        <div
          className={`opportunity-card-content-container ${programTypeClass()} opportunity-assigned-${programCommited}`}
        >
          <div
            className={`opportunity-card-content-header ${programTypeClass()} opportunity-assigned-${programCommited}`}
          >
            <div className="opportunity-card-content-header-info-container">
              <div className="opportunity-card-content-header-info">
                <div className="opportunity-card-content-header-img-container neutral-300-bg">
                  {!skeleton_mode && (
                    <img src={retailer_photo} alt="retail_image" className="opportunity-card-content-header-img" />
                  )}
                </div>
                <div className="opportunity-card-content-header-img-container neutral-300-bg ml-s">
                  {ProductIcon(product_abbr, 'square-m')}
                </div>

                <div className="oppotunity-card-content-header-opportunity-commodity-info-wrapper">
                  <p>
                    {skeleton_mode ? '- - -/- - -' : `${t(`fruits:${product_abbr}`)}/${renderCommodityType()}`}
                    &nbsp;&nbsp;•&nbsp;&nbsp;
                  </p>
                  <p className="opportunity-card-content-header-opportunity-id">
                    ID: <b>{skeleton_mode ? '- - -' : id}</b>
                  </p>
                </div>
              </div>

              {/* currently participating */}
              {programCommited && (
                <div className="opportunity-card-content-header-opportunity-status-container">
                  <div className="opportunity-card-content-header-opportunity-status">
                    <p>{t('programsCard:saleAssigned')}</p>
                  </div>
                </div>
              )}
            </div>

            {/* timer for sport program */}
            {!programCommited &&
              !skeleton_mode &&
              renderByProgramType(programTypeDictionary('spot'), renderProgramTimer())}

            {/* data */}
            <div className="opportunity-card-content-header-data-row grid">
              {/* units values */}
              <div className="opportunity-card-content-header-data-container col-4">
                <p className="opportunity-card-content-header-data-label">{t('programsCard:amount')}</p>
                <div className="opportunity-card-content-header-data-inner-row">
                  {skeleton_mode ? (
                    <p className="opportunity-card-content-header-data-unit-value">- - -</p>
                  ) : (
                    <>
                      <p className="opportunity-card-content-header-data-unit-value">
                        {unitValue(total_boxes / weeks)}
                      </p>
                      {unitIcons(unit, `opportunity-card-content-header-data-unit-icon ${unit}`)}
                      <p className="opportunity-card-content-header-data-unit-label">
                        {t(`unitsAbbr:${unitLabel(unit)}`)}
                      </p>
                    </>
                  )}
                </div>
                {skeleton_mode ? (
                  <p className="opportunity-card-content-header-data-unit-label-help-text">- - -</p>
                ) : (
                  renderByProgramType(
                    programTypeDictionary('normal'),
                    <p className="opportunity-card-content-header-data-unit-label-help-text">
                      {t('programsCard:perWeek')}
                    </p>,
                  )
                )}
              </div>

              {/* weeks value */}
              {renderByProgramType(
                programTypeDictionary('normal'),
                <div className="opportunity-card-content-header-data-container col-4">
                  <p className="opportunity-card-content-header-data-label">{t('programsCard:duration')}</p>
                  <div className="opportunity-card-content-header-data-inner-row">
                    {skeleton_mode ? (
                      <p className="opportunity-card-content-header-data-unit-value">- -</p>
                    ) : (
                      <>
                        <p className="opportunity-card-content-header-data-unit-value">{weeks}</p>
                        <p className="opportunity-card-content-header-data-unit-label middle-label">
                          {t('programsCard:weeks')}
                        </p>
                      </>
                    )}
                  </div>
                  <p className="opportunity-card-content-header-data-unit-label-help-text label-hidden">`&apos`</p>
                </div>,
              )}

              {/* prices value */}
              <div className="opportunity-card-content-header-data-container col-4">
                <p className="opportunity-card-content-header-data-label">{`FOB ${
                  skeleton_mode ? '' : destination_name
                }`}</p>
                <div className="opportunity-card-content-header-data-inner-row">
                  <p className="opportunity-card-content-header-data-unit-value price-label">
                    {skeleton_mode ? '- -.- -' : priceFixed}
                  </p>
                  <p className="opportunity-card-content-header-data-unit-label last-label">USD</p>
                </div>
                <p className="opportunity-card-content-header-data-unit-label-help-text">
                  {skeleton_mode
                    ? '- - -'
                    : t('programsCard:estimatedPerBox', { package_description: package_description })}
                </p>
              </div>
            </div>
          </div>

          {/* footer */}
          <div className={`opportunity-card-content-footer grid ${programTypeClass()}`}>
            {/* date */}
            <div className="opportunity-card-content-footer-data-container col-6">
              <p className="opportunity-card-content-footer-data-label">{t('programsCard:deliveryDate')}</p>
              <div className="opportunity-card-content-footer-data-inner-row">
                <p className="opportunity-card-content-footer-data-value">
                  {skeleton_mode ? (
                    '- - - • - - :- -'
                  ) : (
                    <>
                      <span className="capitalize-label">{`${GetProgramDate(start_date, localeID)} • `}</span>
                      {timeFormat(start_date, localeID)}
                    </>
                  )}
                </p>
              </div>
            </div>
            {/* package_description */}
            <div className="opportunity-card-content-footer-data-container col-6">
              <p className="opportunity-card-content-footer-data-label">{t('programsCard:presentation')}</p>
              <div className="opportunity-card-content-footer-data-inner-row">
                <p className="opportunity-card-content-footer-data-value">
                  {skeleton_mode ? '- - -' : t('programsCard:flatOf', { package_description })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{renderWarningMessage()}</div>
    </div>
  );
};

OpportunityCard.defaultProps = {
  programCommited: undefined,
  unit: 'truck',
};

OpportunityCard.propTypes = {
  confirmProgram: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  programCommited: PropTypes.bool,
  selectedProgram: PropTypes.number,
  setConfirmProgram: PropTypes.func.isRequired,
  setSelectedProgramAction: PropTypes.func.isRequired,
  unit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  confirmProgram: state.confirmProgram.confirmProgram,
  selectedProgram: state.programs.id,
  unit: state.unitSelected.unit,
});

export default connect(mapStateToProps, { setConfirmProgram, setSelectedProgramAction })(OpportunityCard);
