import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../../../components/Tooltip';
import WebStorage from '../../../../../utils/WebStorage/WebStorage';
import utilsApi from '../../../../../utils/api/UtilsApi';
import volumeBehaviorSelector from '../../../../../utils/page_definition/volume_behavior';
import { HelpIcon } from '../../../../../assets/icons/assets';
import { useDispatch, useSelector } from 'react-redux';
import { formatHumanDate } from '../../../../../utils/common/DateHandler';
import { renderTableVersus, tableFooter } from '../../../../../utils/Table/TableUtils';
import { setVolumeBehaviorAction } from '../../../../../redux/actions/volumeBehavior/actions';
import { useTranslation } from 'react-i18next';
import './VolumeBehavior.scss';

const VolumeBehavior = ({ infoMock = [] }) => {
  // local state using hook
  const [productionZone, setProductionZone] = useState(infoMock);
  // api util
  const { getProductionZone, getVolumeBehavior, cancelToken } = utilsApi;
  // selectors for unit tests
  const {
    volumeBehaviorContainer,
    volumeBehaviorBehavior,
    volumeBehaviorDetail,
    volumeBehaviorBehaviorWeek,
    volumeBehaviorBehaviorYear,
  } = volumeBehaviorSelector;

  const dispatch = useDispatch();

  const volumeBehavior = useSelector((state) => state.volumeBehavior.data);
  const selectedDate = useSelector((state) => state.selectedDate.date);
  const activeProduct = useSelector((state) => state.products.active);

  const { t } = useTranslation(['volumeBehavior', 'general']);
  const { getCookieValue } = WebStorage;
  const localeID = getCookieValue('localeId');

  // fetch data if infoMock for utests is empty array.
  useEffect(() => {
    const CancelToken = cancelToken();
    function fetchProductionZone() {
      getProductionZone(activeProduct, selectedDate, CancelToken.token).then((res) => setProductionZone(res.data));
    }

    if (!infoMock.length && activeProduct) {
      fetchProductionZone();
      getVolumeBehavior(activeProduct, selectedDate, CancelToken.token).then((res) =>
        dispatch(setVolumeBehaviorAction(res.data)),
      );
    }

    return function () {
      CancelToken.cancel();
    };
  }, [activeProduct, getProductionZone, selectedDate, getVolumeBehavior, setVolumeBehaviorAction]);

  /*
   * table behavior method.
   * @return {React.Element}
   */
  const vlTableBehavior = () => (
    <div>
      <table className="volume-behavior-table table-desktop" data-test={volumeBehaviorBehavior}>
        <thead>
          <tr>
            <th>
              {t('lastWeek')}
              <Tooltip title={t('calculate')} text={t('ttCalculate')} customClass="vl-behavior-tooltip">
                <HelpIcon styleClass="help-icon" customId="volume-desktop" />
              </Tooltip>
            </th>
            <th>
              {t('lastYear')}
              <Tooltip title={t('calculate')} text={t('ttCalculate')}>
                <HelpIcon styleClass="help-icon" customId="volume-desktop" />
              </Tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{renderTableVersus(volumeBehavior.last_week, volumeBehaviorBehaviorWeek)}</td>
            <td>{renderTableVersus(volumeBehavior.last_year, volumeBehaviorBehaviorYear)}</td>
          </tr>
        </tbody>
      </table>
      <table className="volume-behavior-table table-responsive">
        <tbody>
          <tr>
            <th className="volume-behavior-responsive-header">
              Last week
              <Tooltip title={t('calculate')} text={t('ttCalculate')} customClass="vl-behavior-tooltip">
                <HelpIcon styleClass="help-icon" customId="volume-responsive" />
              </Tooltip>
            </th>
            <th className="volume-behavior-responsive-header">
              Last year
              <Tooltip title={t('calculate')} text={t('ttCalculate')}>
                <HelpIcon styleClass="help-icon" customId="volume-responsive" />
              </Tooltip>
            </th>
          </tr>
          <tr>
            <td className="volume-behavior-responsive-data">
              {renderTableVersus(volumeBehavior.last_week, volumeBehaviorBehaviorWeek, 'responsive-last_week')}
            </td>
            <td className="volume-behavior-responsive-data">
              {renderTableVersus(volumeBehavior.last_year, volumeBehaviorBehaviorYear, 'responsive-last_year')}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  /*
   * table detail method.
   * @return {React.Element}
   */
  const vlTableDetail = () => (
    <div>
      <table className="table-desktop" data-test={volumeBehaviorDetail}>
        <thead>
          <tr>
            {productionZone &&
              productionZone
                .filter((e) => e.percentage)
                .map((element, index) => <th key={index}>{element.origin.replace(/-/g, ' ')}</th>)}
          </tr>
        </thead>
        <tbody>
          <tr>
            {productionZone &&
              productionZone
                .filter((e) => e.percentage)
                .map((element, index) => (
                  <td
                    className="vl-table-detail-data"
                    key={index}
                    data-test={`${volumeBehaviorSelector.volumeProductZone}-${index}`}
                  >
                    {element.percentage}
                  </td>
                ))}
          </tr>
        </tbody>
      </table>
      <div className="table-responsive">
        <table className="table-responsive-volume-detail-container">
          {productionZone &&
            productionZone
              .filter((e) => e.percentage)
              .map((element, index) => (
                <tbody key={index}>
                  <tr>
                    <th>{element.origin.replace(/-/g, ' ')}</th>
                  </tr>
                  <tr>
                    <td className="vl-table-detail-data">{element.percentage}</td>
                  </tr>
                </tbody>
              ))}
        </table>
      </div>
    </div>
  );

  /*
   * method to manage the different tables
   * @return {React.Element}
   */
  const vlTableContent = () => (
    <div className="vl-table-content">
      <div className="vl-table-content-behavior">
        <h3> {t('volBehaviorTitle')} </h3>
        <hr className="dashboard-divisor vl-table-divisor" />
        {vlTableBehavior()}
      </div>
      <div className="vl-table-content-detail">
        <h3> {t('volDetailTitle')} </h3>
        <hr className="dashboard-divisor vl-table-divisor" />
        {vlTableDetail()}
        <div className="vl-table-detail-footer">
          <p>
            {t('percentCalculate1')}
            <strong> {t('last15Days')} </strong>
            {t('percentCalculate2')}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div data-test={volumeBehaviorContainer} className="volume-table-container">
      <div className="vl-table-inner-container freshboard-table-internal-container">
        <div className="vl-table-content-container">{vlTableContent()}</div>
        <hr className="dashboard-divisor vl-table-divisor" />
        <div className="vl-tbl-date-container">
          {tableFooter(formatHumanDate(volumeBehavior.report_date, localeID), false, false, t('latestUpdate'))}
        </div>
      </div>
    </div>
  );
};

VolumeBehavior.propTypes = {
  infoMock: PropTypes.array,
};

export default VolumeBehavior;
