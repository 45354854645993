import React from 'react';

/* eslint-disable */
const StrawberryIcon = ({ styleClass, dataTest }) => (
  <svg className={styleClass} viewBox="0 0 24 24" data-test={dataTest}>
    <title>Strawberry Icon</title>
    <g id="03-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-strawberry" fillRule="nonzero">
        <g id="Icon" transform="translate(3.000000, 0.000000)">
          <path
            d="M10,3 C9.66001738,3.79261213 9.48969902,4.64761026 9.5,5.51 L9.5,5.51 L9,5.51 L8.5,5.51 L8.5,5.51 C8.51579089,4.64711066 8.34524492,3.79096986 8,3 L8,0.5 L10,0.5 L10,3 Z"
            id="Path"
            fill="#589F44"
          ></path>
          <path
            d="M4.05,2.54 C4.18,4.39 4.74,5.54 7.05,5.54 L8.05,5.54 L8.05,5.54 C7.19970508,5.61244994 6.36072931,5.78360099 5.55,6.05 L5.55,6 C3.5,6 1,6 1,2.5 C1.97719771,2.19799145 3.02280229,2.19799145 4,2.5 L4.05,2.54 Z"
            id="Path"
            fill="#417136"
          ></path>
          <path
            d="M12.49,6.05 C11.6824946,5.78435784 10.8469181,5.61321566 10,5.54 L10,5.54 L11,5.54 C13.26,5.54 13.82,4.43 14,2.54 L14,2.54 C14.9771977,2.23799145 16.0228023,2.23799145 17,2.54 C17,6 14.5,6 12.5,6 L12.49,6.05 Z"
            id="Path"
            fill="#417136"
          ></path>
          <path
            d="M14,0.5 C14,1.25 14,1.93 14,2.5 C13.86,4.35 13.3,5.5 11,5.5 L10,5.5 C11,5.5 13,3 14,0.5 Z"
            id="Path"
            fill="#8BC63F"
          ></path>
          <path
            d="M4,0.5 C5,3 7,5.5 8,5.5 L7,5.5 C4.74,5.5 4.18,4.39 4.05,2.5 C4,1.93 4,1.25 4,0.5 Z"
            id="Path"
            fill="#8BC63F"
          ></path>
          <path
            d="M14,0.5 C13,3 11,5.5 10,5.5 L10,5.5 L9.51,5.5 L9.51,5.5 C9.49793515,4.6416798 9.66481364,3.79025896 10,3 C10.6724758,1.40631862 12.272797,0.406117871 14,0.5 Z"
            id="Path"
            fill="#BCD63D"
          ></path>
          <path
            d="M8,3 C8.34524492,3.79096986 8.51579089,4.64711066 8.5,5.51 L8.5,5.51 L8.01,5.51 L8.01,5.51 C7.01,5.51 5.01,3.01 4.01,0.51 C5.73116853,0.418822149 7.32559218,1.41383841 8,3 L8,3 Z"
            id="Path"
            fill="#BCD63D"
          ></path>
          <path
            d="M9,5.5 L9.5,5.5 L9.99,5.5 C10.8402949,5.57244994 11.6792707,5.74360099 12.49,6.01 C15.49,7.01 17.49,9.1 17.49,11.46 C17.49,15.52 12.99,23.46 8.99,23.46 C4.99,23.46 0.49,15.52 0.49,11.46 C0.49,9.1 2.55,6.97 5.49,6.01 C6.30558282,5.75642015 7.14792404,5.59869092 8,5.54 L8.49,5.54 L9,5.5 Z"
            id="Path"
            fill="#D91F26"
          ></path>
          <path
            d="M10,3 C9.66001738,3.79261213 9.48969902,4.64761026 9.5,5.51 L9.5,5.51 L9,5.51 L8.5,5.51 L8.5,5.51 C8.51579089,4.64711066 8.34524492,3.79096986 8,3 L8,0.5 L10,0.5 L10,3 Z"
            id="Path"
            fill="#589F44"
          ></path>
          <path
            d="M4.05,2.54 C4.18,4.39 4.74,5.54 7.05,5.54 L8.05,5.54 L8.05,5.54 C7.19970508,5.61244994 6.36072931,5.78360099 5.55,6.05 L5.55,6 C3.5,6 1,6 1,2.5 C1.97719771,2.19799145 3.02280229,2.19799145 4,2.5 L4.05,2.54 Z"
            id="Path"
            fill="#417136"
          ></path>
          <path
            d="M12.49,6.05 C11.6824946,5.78435784 10.8469181,5.61321566 10,5.54 L10,5.54 L11,5.54 C13.26,5.54 13.82,4.43 14,2.54 L14,2.54 C14.9771977,2.23799145 16.0228023,2.23799145 17,2.54 C17,6 14.5,6 12.5,6 L12.49,6.05 Z"
            id="Path"
            fill="#417136"
          ></path>
          <path
            d="M14,0.5 C14,1.25 14,1.93 14,2.5 C13.86,4.35 13.3,5.5 11,5.5 L10,5.5 C11,5.5 13,3 14,0.5 Z"
            id="Path"
            fill="#8BC63F"
          ></path>
          <path
            d="M4,0.5 C5,3 7,5.5 8,5.5 L7,5.5 C4.74,5.5 4.18,4.39 4.05,2.5 C4,1.93 4,1.25 4,0.5 Z"
            id="Path"
            fill="#8BC63F"
          ></path>
          <path
            d="M14,0.5 C13,3 11,5.5 10,5.5 L10,5.5 L9.51,5.5 L9.51,5.5 C9.49793515,4.6416798 9.66481364,3.79025896 10,3 C10.6724758,1.40631862 12.272797,0.406117871 14,0.5 Z"
            id="Path"
            fill="#BCD63D"
          ></path>
          <path
            d="M8,3 C8.34524492,3.79096986 8.51579089,4.64711066 8.5,5.51 L8.5,5.51 L8.01,5.51 L8.01,5.51 C7.01,5.51 5.01,3.01 4.01,0.51 C5.73116853,0.418822149 7.32559218,1.41383841 8,3 L8,3 Z"
            id="Path"
            fill="#BCD63D"
          ></path>
          <path
            d="M9,5.5 L9.5,5.5 L9.99,5.5 C10.8402949,5.57244994 11.6792707,5.74360099 12.49,6.01 C15.49,7.01 17.49,9.1 17.49,11.46 C17.49,15.52 12.99,23.46 8.99,23.46 C4.99,23.46 0.49,15.52 0.49,11.46 C0.49,9.1 2.55,6.97 5.49,6.01 C6.30558282,5.75642015 7.14792404,5.59869092 8,5.54 L8.49,5.54 L9,5.5 Z"
            id="Path"
            fill="#D91F26"
          ></path>
          <path
            d="M17.5,11.5 C17.5,9.13 15.44,7.01 12.5,6.04 C11.6893785,5.77502996 10.8501776,5.60718978 10,5.54 L9.51,5.54 L8.51,5.54 L8.02,5.54 C7.17144141,5.5942012 6.33227543,5.74860774 5.52,6 C3.87489913,6.49673493 2.42920673,7.50136863 1.39,8.87 C2.18523787,8.29313902 3.06590622,7.84436932 4,7.54 C4.81216782,7.26065413 5.65480921,7.07936873 6.51,7 L7,7 L8,7 L8.49,7 C9.33680198,7.06797134 10.1726009,7.23580244 10.98,7.5 C13.98,8.5 15.98,10.59 15.98,12.96 C15.98,15.48 14.25,19.48 11.98,22.17 C15,19.79 17.5,14.55 17.5,11.5 Z"
            id="Path"
            fill="#F1594B"
          ></path>
          <path
            d="M11,19 C7.2,19 3,11.85 2.54,7.65 C1.3324001,8.57073631 0.598976717,9.98257632 0.54,11.5 C0.54,15.56 5.04,23.5 9.04,23.5 C12.33,23.5 15.96,18.13 17.14,13.96 C15.44,16.71 13.15,19 11,19 Z"
            id="Path"
            fill="#951B1E"
          ></path>
          <path
            d="M4.5,12.5 C5.05228475,12.5 5.5,12.0522847 5.5,11.5 L5.5,11 C5.5,10.4477153 5.05228475,10 4.5,10 C3.94771525,10 3.5,10.4477153 3.5,11 L3.5,11.19 C3.66,11.58 3.84,11.98 4.04,12.38 C4.17980667,12.4605055 4.3386828,12.5019515 4.5,12.5 L4.5,12.5 Z"
            id="Path"
            fill="#F1594B"
          ></path>
          <path
            d="M4,12.38 C3.8,11.98 3.62,11.58 3.46,11.19 L3.46,11.5 C3.46294215,11.8707587 3.67077995,12.2094573 4,12.38 Z"
            id="Path"
            fill="#B21F24"
          ></path>
          <rect id="Rectangle" fill="#F1594B" x="10" y="10.5" width="2" height="2.5" rx="1"></rect>
          <rect id="Rectangle" fill="#F5EB1A" x="10.5" y="11" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#F1594B" x="6.5" y="12.5" width="2" height="2.5" rx="1"></rect>
          <rect id="Rectangle" fill="#F5EB1A" x="7" y="13" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#F1594B" x="13" y="13.5" width="2" height="2.5" rx="1"></rect>
          <rect id="Rectangle" fill="#F5EB1A" x="13.5" y="14" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#F1594B" x="10" y="16" width="2" height="2.5" rx="1"></rect>
          <rect id="Rectangle" fill="#F5EB1A" x="10.5" y="16.5" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#F5EB1A" x="4" y="10.5" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#F1594B" x="7" y="7.5" width="2" height="2.5" rx="1"></rect>
          <rect id="Rectangle" fill="#F5EB1A" x="7.5" y="8" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#F1594B" x="13" y="8" width="2" height="2.5" rx="1"></rect>
          <rect id="Rectangle" fill="#F8BA17" x="13.5" y="8.5" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="3.5" y="15" width="2" height="2.5" rx="1"></rect>
          <rect id="Rectangle" fill="#F8BA17" x="4" y="15.5" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="6.5" y="18.5" width="2" height="2.5" rx="1"></rect>
          <rect id="Rectangle" fill="#F8BA17" x="7" y="19" width="1" height="1.5" rx="0.5"></rect>
          <path
            d="M12.13,5.37 C11.7610163,5.4646192 11.3808306,5.50835739 11,5.5 L10,5.5 L10,5.5 C10.846802,5.56797134 11.6826009,5.73580244 12.49,6 L12.49,6 C14.49,6 16.99,6 16.99,2.5 C17,2.5 16.61,4.81 12.13,5.37 Z"
            id="Path"
            fill="#345025"
          ></path>
          <path
            d="M5.86,5.37 C6.23221778,5.46552928 6.61583089,5.50927464 7,5.5 L8,5.5 L8,5.5 C7.15347914,5.56997015 6.31791142,5.73775484 5.51,6 L5.51,6 C3.49,6 1,6 1,2.5 C1,2.5 1.38,4.81 5.86,5.37 Z"
            id="Path"
            fill="#345025"
          ></path>
          <polygon id="Path" fill="#589F44" points="8.5 5.51 8.5 5.51 8.5 5.5"></polygon>
          <polygon id="Path" fill="#589F44" points="9.5 5.51 9.5 5.51 9.5 5.5"></polygon>
          <path
            d="M9.5,3 C9.16053575,3.78925947 8.99020972,4.64088962 9,5.5 L9,5.5 L9.49,5.5 L9.49,5.5 L9.49,5.5 C9.48336486,4.64017736 9.65710334,3.78851815 10,3 L10,2.14 C9.80369188,2.408376 9.63610641,2.696623 9.5,3 L9.5,3 Z"
            id="Path"
            fill="#345025"
          ></path>
          <path
            d="M8.5,3 C8.36327603,2.70008627 8.19570652,2.41521811 8,2.15 L8,3 C8.34083779,3.7923465 8.51119265,4.64752792 8.5,5.51 L8.5,5.51 L8.5,5.51 L8.99,5.51 L8.99,5.51 C9.00435027,4.64824143 8.83740809,3.79308861 8.5,3 L8.5,3 Z"
            id="Path"
            fill="#417136"
          ></path>
          <path
            d="M8,0.5 L8,2.14 C8.19570652,2.40521811 8.36327603,2.69008627 8.5,2.99 C8.84083779,3.7823465 9.01119265,4.63752792 9,5.5 L9,0.5 L8,0.5 Z"
            id="Path"
            fill="#508D40"
          ></path>
          <path
            d="M9,0.5 L9,5.5 C8.99020972,4.64088962 9.16053575,3.78925947 9.5,3 C9.63672397,2.70008627 9.80429348,2.41521811 10,2.15 L10,0.5 L9,0.5 Z"
            id="Path"
            fill="#63B446"
          ></path>
          <path
            d="M15,9 C14.9932755,8.54481746 14.6799669,8.1516204 14.2377789,8.04342547 C13.7955909,7.93523054 13.3361313,8.13934599 13.12,8.54 L13.54,8.82 C13.6111967,8.62753217 13.794786,8.49981785 14,8.5 C14.2761424,8.5 14.5,8.72385763 14.5,9 L14.5,9.5 C14.5048174,9.53985495 14.5048174,9.58014505 14.5,9.62 C14.63,9.75 14.75,9.89 14.87,10.03 C14.9588459,9.86770662 15.0036645,9.68498463 15,9.5 L15,9 Z"
            id="Path"
            fill="#F79984"
          ></path>
          <path
            d="M14.5,9.49999981 L14.5,8.99999981 C14.5,8.72385763 14.2761424,8.49999981 14,8.49999981 C13.794786,8.49981785 13.6111967,8.62753217 13.54,8.82 C13.8708892,9.06532268 14.1849292,9.33259073 14.48,9.62 C14.4915262,9.58096316 14.4982425,9.54066495 14.5,9.49999981 Z"
            id="Path"
            fill="#FDF1C2"
          ></path>
          <rect id="Rectangle" fill="#B21F24" x="11" y="13.5" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="4.5" y="8" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="8.5" y="15.5" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="9" y="21" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="2" y="12.5" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="12" y="19" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="7.5" y="10.5" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="13.5" y="11" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="10" y="8" width="1" height="1.5" rx="0.5"></rect>
          <rect id="Rectangle" fill="#B21F24" x="5" y="12.5" width="1" height="1.5" rx="0.5"></rect>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(14.002801, 8.996729) rotate(-45.020000) translate(-14.002801, -8.996729) "
            cx="14.0028006"
            cy="8.9967286"
            rx="1"
            ry="1.15"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(15.500444, 10.002558) rotate(-45.020000) translate(-15.500444, -10.002558) "
            cx="15.500444"
            cy="10.0025582"
            rx="1"
            ry="1"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(12.501480, 7.497469) rotate(-45.020000) translate(-12.501480, -7.497469) "
            cx="12.5014804"
            cy="7.49746908"
            rx="1"
            ry="1"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#D91F26"
            transform="translate(15.374360, 8.750939) rotate(-45.020000) translate(-15.374360, -8.750939) "
            cx="15.3743603"
            cy="8.75093924"
            rx="1"
            ry="1"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(11.867704, 9.753537) rotate(-45.020000) translate(-11.867704, -9.753537) "
            cx="11.8677041"
            cy="9.75353662"
            rx="1"
            ry="1"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#F6FAFE"
            transform="translate(12.365982, 2.248411) rotate(-45.020000) translate(-12.365982, -2.248411) "
            cx="12.3659824"
            cy="2.24841051"
            rx="1"
            ry="1"
          ></ellipse>
          <path
            d="M17.69,9.82 L17.22,10 L17.69,9.82 C17.3642909,8.99264732 16.8587692,8.24802747 16.21,7.64 L15.51,8.36 C16.0522405,8.86408812 16.475769,9.48230317 16.75,10.17 L16.75,10.17 C16.914834,10.5940671 16.9996003,11.0450242 17,11.5 C16.7448727,14.1052844 15.8255628,16.601538 14.33,18.75 C12.6,21.41 10.6,23 9,23 C8.44239377,22.9828975 7.89707114,22.8319906 7.41,22.56 L6.95,23.44 C7.57731401,23.7913596 8.28117553,23.983634 9,24 C13.23,24 18,15.85 18,11.5 C17.9960453,10.9261482 17.8911109,10.3574718 17.69,9.82 Z"
            id="Path"
            fill="#061C20"
          ></path>
          <path
            d="M1,11.5 C1,8.52 4.66,6 9,6 C11.0333626,5.96235748 13.0283473,6.55631088 14.71,7.7 L15.29,6.88 C15.04,6.7 14.77,6.54 14.5,6.38 C16.05,6.12 17.5,5.27 17.5,2.5 C17.5152674,2.27494066 17.3749128,2.0685368 17.16,2 C16.2908144,1.74038973 15.372727,1.68900424 14.48,1.85 C14.48,1.41 14.48,0.95 14.48,0.47 C14.4647514,0.213171832 14.2570922,0.0098389387 14,2.20743562e-16 C12.6968626,-0.0418996282 11.4329531,0.449219479 10.5,1.36 L10.5,0.5 C10.5,0.367391755 10.4473216,0.240214799 10.3535534,0.146446609 C10.2597852,0.0526784202 10.1326082,-7.13405029e-16 10,2.20743562e-16 L8,2.20743562e-16 C7.72385763,2.03830008e-16 7.5,0.223857625 7.5,0.5 L7.5,1.36 C6.56704688,0.449219479 5.30313741,-0.0418996282 4,-3.10949183e-16 C3.72385763,-3.51281504e-16 3.5,0.223857625 3.5,0.5 C3.5,0.98 3.5,1.44 3.5,1.88 C2.61607763,1.71243875 1.70524422,1.75352898 0.84,2 C0.636144564,2.06554563 0.498461828,2.25587176 0.5,2.47 C0.5,5.26 1.97,6.1 3.5,6.36 C1.4,7.59 0,9.45 0,11.5 C0,14.67 2.71,20.5 6.08,22.9 L6.67,22.09 C3.62,19.88 1,14.42 1,11.5 Z M4.57,2.73 C5.0368242,3.54628475 5.59363018,4.30770016 6.23,5 C5.09,4.76 4.7,4.1 4.57,2.73 Z M11.77,5 C12.4033597,4.32335652 12.9600665,3.57884501 13.43,2.78 C13.3,4.1 12.91,4.76 11.77,5 Z M16.49,2.94 C16.36,5.14 15.07,5.5 13.11,5.54 C13.9184143,4.93572773 14.3990542,3.98923677 14.41,2.98 C15.0872876,2.79107824 15.7989253,2.76028622 16.49,2.89 L16.49,2.94 Z M13.2,1.07 C12.12,3.35 10.55,5 10,5 C10.16,3.19 11.06,1.42 13.2,1.07 Z M9.5,1 L9.5,2.86 C9.2123312,3.53954034 9.04321797,4.26334498 9,5 L9,5 C8.96314301,4.26452221 8.80080398,3.5407607 8.52,2.86 L8.52,1 L9.5,1 Z M8,5 C7.47,5 5.9,3.35 4.82,1.07 C6.94,1.42 7.84,3.19 8,5 Z M1.51,2.89 C2.19415767,2.73047831 2.90584233,2.73047831 3.59,2.89 C3.60094576,3.89923677 4.0815857,4.84572773 4.89,5.45 C2.93,5.45 1.64,5.09 1.51,2.89 Z"
            id="Shape"
            fill="#061C20"
          ></path>
          <rect id="Rectangle" fill="#061C20" x="13.5" y="14" width="1" height="1"></rect>
          <rect id="Rectangle" fill="#061C20" x="4" y="10.5" width="1" height="1"></rect>
          <rect id="Rectangle" fill="#061C20" x="7" y="13" width="1" height="1"></rect>
          <rect id="Rectangle" fill="#061C20" x="4" y="15.5" width="1" height="1"></rect>
          <rect id="Rectangle" fill="#061C20" x="10.5" y="16.5" width="1" height="1"></rect>
          <rect id="Rectangle" fill="#061C20" x="10.5" y="11" width="1" height="1"></rect>
          <rect id="Rectangle" fill="#061C20" x="7.5" y="8" width="1" height="1"></rect>
          <rect id="Rectangle" fill="#061C20" x="13.5" y="8.5" width="1" height="1"></rect>
          <rect id="Rectangle" fill="#061C20" x="7" y="19" width="1" height="1"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default StrawberryIcon;
