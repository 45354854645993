import React, { useState } from 'react';
import Button from '../../../../components/Button';
import DailyGraph from './Graphs/Daily';
import DailyLocations from './Graphs/DailyLocations';
import WeeklyGraph from './Graphs/Weekly';
import WeeklyLocations from './Graphs/WeeklyLocations';
import volumeReportSelector from '../../../../utils/page_definition/volume_report';
import { useSelector } from 'react-redux';
import { productName } from '../../../../utils/product/ProductDictionary';
import { useTranslation } from 'react-i18next';
import './UsdaVolume.scss';

const UsdaVolume = () => {
  const activeProduct = useSelector((state) => state.products.active);

  const { t } = useTranslation('volume');
  const [selectedGraph, setSelectedGraph] = useState(0);

  const graphOptions = {
    WEEKLY: {
      name: t('btnWeekly'),
      graph: <WeeklyGraph />,
    },
    DAILY: {
      name: t('btnDaily'),
      graph: <DailyGraph />,
    },
    WEEKLYLOCATIONS: {
      name: t('btnWeeklyLoc'),
      graph: <WeeklyLocations />,
    },
    DAILYLOCATIONS: {
      name: t('btnDailyLoc'),
      graph: <DailyLocations />,
    },
  };
  const buttonOrder = ['WEEKLY', 'DAILY', 'WEEKLYLOCATIONS', 'DAILYLOCATIONS'];

  /**
   * Set selected option
   * @param {number} index
   */
  const changeOption = (index) => {
    setSelectedGraph(index);
  };

  const getButtonClassName = (i) => {
    let buttonClass = 'volume-category-button';
    if (!i) buttonClass += ' volume-first-button';
    if (i === buttonOrder.length - 1) buttonClass += ' volume-last-button';
    if (i === selectedGraph) buttonClass += ' volume-category-button-selected';

    return buttonClass;
  };

  /**
   * Renders all buttons according tto `buttonOrder` array
   */
  const renderButtons = () => (
    <div className="volume-report-buttons">
      {buttonOrder.map((option, index) => (
        <Button
          styleClass={getButtonClassName(index)}
          key={index}
          onClick={() => changeOption(index)}
          size="small"
          shape={index === selectedGraph ? 'main' : 'complementary'}
          type="secondary"
        >
          {graphOptions[option].name}
        </Button>
      ))}
    </div>
  );

  return (
    <div data-test={volumeReportSelector.volumeReportContainer} className="volume-graph-container">
      <div className="volume-graph-header">
        <div className="volume-graph-header-title">
          <h3>{t('title', { product: t(`fruits:${productName(activeProduct)}`) })}</h3>
          <select
            data-test={volumeReportSelector.productSelect}
            className="volume-graph-select"
            name="commodity"
            id="commodity-select"
          >
            <option value="Conventional"> {t('selConventional')} </option>
            <option value="Organic"> {t('selOrganic')} </option>
            <option value="All"> {t('selAll')} </option>
          </select>
        </div>
        <div className="volume-graph-header-description">
          <p>{t('description')}</p>
        </div>
      </div>
      {renderButtons()}
      {/* call the method to render the highchart */}
      <div data-test={volumeReportSelector.volumeReportGraph} className="volume-graph-content">
        {graphOptions[buttonOrder[selectedGraph]].graph}
      </div>
    </div>
  );
};

export default UsdaVolume;
