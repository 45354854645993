export const monthSelect = [
  {
    id: 'january',
    value: 1,
    label: 'january',
  },
  {
    id: 'february',
    value: 2,
    label: 'february',
  },
  {
    id: 'march',
    value: 3,
    label: 'march',
  },
  {
    id: 'april',
    value: 4,
    label: 'april',
  },
  {
    id: 'may',
    value: 5,
    label: 'may',
  },
  {
    id: 'june',
    value: 6,
    label: 'june',
  },
  {
    id: 'july',
    value: 7,
    label: 'july',
  },
  {
    id: 'august',
    value: 8,
    label: 'august',
  },
  {
    id: 'september',
    value: 9,
    label: 'september',
  },
  {
    id: 'october',
    value: 10,
    label: 'october',
  },
  {
    id: 'november',
    value: 11,
    label: 'november',
  },
  {
    id: 'december',
    value: 12,
    label: 'december',
  },
];
