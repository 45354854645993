import confirmProgram from './confirmProgram';
import currentDateReducer from './currentDate';
import products from './products';
import programs from './programs';
import selectedDate from './selectedDate';
import shippingPoint from './shippingPoint';
import unitSelected from './unitSelect';
import volumeBehavior from './volumeBehavior';
import volumeReport from './volumeReport';
import option from './management/option';
import orchards from './management/orchards';
import { combineReducers } from 'redux';

export default combineReducers({
  confirmProgram,
  currentDate: currentDateReducer,
  option,
  orchards,
  products,
  programs,
  selectedDate,
  shippingPoint,
  unitSelected,
  volumeBehavior,
  volumeReport,
});
