/*
 * method to parse the packagingMaterials data for select
 * @param {array} materials [id, name, logo, price, assigned]
 * @return {array} {id, label, value, assigned}
 */
import React from 'react';
import { SystemWarning } from '../../../assets/icons/assets';

const packagingMaterials = (materials) => {
  if (materials.length) {
    return materials.map(({ id, name, assigned }) => {
      const options = { id, label: name, value: id, assigned };

      if (assigned === false) {
        options.endContent = (
          <div className="df aic jcfe f1">
            <SystemWarning className="red-900" width={16} height={16} />
          </div>
        );
      }

      return options;
    });
  }

  return [];
};

export default packagingMaterials;
