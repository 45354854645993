const demandES = {
  '11PoundJumboAndSmallLightOthersGood': 'JUMBO 11 libra y pequeña luz, bien a otros.',
  '11PoundJumboLightOthersFairlyGood': '11 LUZ JUMBO LIBRA, otros bastante buena.',
  '121-pintsCupsGoodAtLowerPricesOthersGood': '12 PRECIOS 1-pintas CUPS GOOD a un menor, otros bien.',
  '126-ounceCupsFairlyGood121-pintsModerate': '12 tazas de 6 onzas bastante bueno, 12 1-PINTS moderado.',
  '21/2And3DozenFairlyGoodOthesModerate': '2 1/2 y 3 DOZEN bastante buena, othes moderado.',
  '21/2And3DozenGoodAtSlightlyLowerPricesOthersGood':
    '2 1/2 y 3 DOZEN GOOD AT ligeramente inferior PRECIOS, bien a otros.',
  '21/2DozenModerateOthersFairlyGood': '2 1/2 DOZEN moderado, OTROS bastante buena.',
  '2-21/2DozenFairlyGoodAtSlightlyLowerPricesOthersFairlyGood':
    '2-2 1/2 DOZEN bastante buena ligeramente precios más bajos, OTROS bastante buena.',
  '2-21/2DozenFairlyGoodAtSlightlyLowerPricesOthersFairlyGoodAtLowerPrices':
    '2-2 1/2 DOZEN bastante bueno en ligeramente inferior PRECIOS, otros justamente PRECIOS GOOD AT inferior.',
  '2-21/2DozenGoodAtSlightlyLowerPrices3DozenGoodHeartsModerate':
    '2-2 1/2 docena bueno en precios ligeramente más bajos, 3 DOZEN GOOD, corazones moderado.',
  '2-4DozenFairlyGoodHeartsModerate': '2-4 DOZEN bastante buena, corazones moderado.',
  '28PoundBunchedGoodAtLowerPricesOthersModerate': '28 LIBRA agrupado BUENO EN bajar los precios, otros moderado.',
  '28PoundBunchedGoodAtSlightlyLowerPricesOthersFairlyGood':
    '28 LIBRA agrupado bueno en precios ligeramente más bajos, otros bastante buena.',
  '28PoundStandardAndLargeFairlyGoodJumboVeryLightOthersLight':
    'LIBRA 28 estándar y en grandes bastante buena, JUMBO muy ligero, OTROS LUZ.',
  '28PoundStandardAndLargeFairlyGoodOthersLight': 'LIBRA 28 estándar y en grandes bastante buena, otros la luz.',
  '28PoundStandardAndLargeFairlyLightJumboVeryLightOthersLight':
    'LIBRA 28 estándar y en grandes bastante ligero, JUMBO muy ligero, OTROS luz.',
  '28PoundStandardAndLargeFairlyLightOthersVeryLight':
    'LIBRA 28 estándar y en grandes bastante ligero, otros muy ligero.',
  '28PoundStandardAndLargeModerateJumboVeryLightOthersLight':
    'LIBRA 28 estándar y en grandes MODERADO, JUMBO muy ligero, OTROS luz.',
  '28PoundStandardAndLargeModerateOthersVeryLight': 'LIBRA 28 estándar y en grandes MODERADO, otros muy ligero.',
  '28PoundStandardFairlyGoodAtLowerPricesOthersFairlyLight':
    '28 PRECIOS LIBRA ESTÁNDAR bastante buena a un menor, otros bastante ligero.',
  '28PoundStandardFairlyGoodOthersFairlyLight': 'LIBRA 28 Estrellas estándar bastante buenos, otros bastante ligero.',
  '28PoundStandardFairlyGoodOthersModerate': 'LIBRA 28 Estrellas estándar bastante buenos, otros moderado.',
  '2And21/2DozenFairlyGoodOthersModerate': '2 Y 2 1/2 DOZEN bastante buena, otros moderado.',
  '2And21/2DozenFairlyLightOthersModerate': '2 Y 2 1/2 DOZEN bastante ligero, otros moderado.',
  '2And21/2DozenLightOthersModerate': '2 Y 2 1/2 DOZEN LIGHT, otros moderado.',
  '2And21/2DozenModerateOthersFairlyGood': '2 Y 2 1/2 DOZEN moderado, OTROS bastante buena.',
  '2And21/2GoodOthersModerate': '2 Y 2 1/2 buenos, otros moderado.',
  '2DozenFairlyGoodAtSlightlyLowerPricesOthersFairlyGood':
    '2 docenas de bastante buena a precios ligeramente más bajo y otros bastante buena.',
  '2DozenFairlyGoodAtSlightlyLowerPricesOthersGood':
    '2 docenas de bastante buena a precios ligeramente más bajos, bien a otros.',
  '2DozenFairlyLightOthersModerate': '2 DOZEN bastante ligero, otros moderado.',
  '2DozenGoodAtSlightlyLowerPricesOthersGood': '2 docenas de bueno en precios ligeramente más bajos, buena OTROS.',
  '2DozenModerateOthersFairlyGood': '2 DOZEN moderado, OTROS bastante buena.',
  '3And4DozenModerateOthersGood': '3 Y 4 DOZEN moderado, bien a otros.',
  '3And4DozenModerateOthersVeryGood': '3 Y 4 DOZEN moderado, otros muy bueno ,.',
  '3DozenAndHeartsModerateOthersLight': '3 docena y CORAZONES moderada, otros LIGHT.',
  '3DozenModerateOthersFairlyGood': '3 DOZEN moderado, OTROS bastante buena.',
  '3DozenVeryGoodOthersGood': '3 DOZEN MUY BUENO, bien a otros.',
  '6-ounceCupsConventionalGoodOthersFairlyGood': 'CUPS 6 onzas CONVENCIONAL buenos, otros bastante buena.',
  '6-ounceCupsConventionalGoodOthersFairlyGoodAtLowerPrices':
    'CUPS 6 onzas CONVENCIONAL buenos, otros bastante buena AT precios más bajos.',
  bestExceedsSupplyOthersFairlyGood: 'MEJOR supera a la oferta, otros bastante buena.',
  bestExceedsSupplyOthersGood: 'MEJOR supera a la oferta, bien a otros.',
  bestExceedsSupplyOthersModerate: 'MEJOR supera a la oferta, otros moderado.',
  bestExceedsSupplyOthersVeryGood: 'MEJOR supera a la oferta, OTROS MUY BUENO.',
  bestFairlyGoodAtLowerPricesOthersFairlyLight: 'MEJORES PRECIOS bastante bueno a un menor, otros bastante ligero.',
  bestFairlyGoodAtLowerPricesOthersFairlyLightOrganicGood:
    'MEJORES PRECIOS a un menor bastante buena, otros bastante ligero; BUENA orgánica.',
  bestFairlyGoodAtLowerPricesOthersLight: 'MEJORES bastante buena a un menor PRECIOS, otros la luz.',
  bestFairlyGoodAtLowerPricesOthersVeryLight: 'MEJORES PRECIOS bastante bueno a un menor, otros muy ligero.',
  bestFairlyGoodOthersFairlyLight: 'MEJOR bastante buena, otros bastante ligero.',
  bestFairlyGoodOthersFairlyLightAtHigherPrices: 'MEJORES bastante buena, otros bastante ligero en mayor precios.',
  bestFairlyGoodOthersFairlyLightOrganicGood: 'MEJORES bastante buena, otros bastante ligero; BUENA orgánica.',
  bestFairlyGoodOthersLight: 'MEJOR bastante buena, otros LUZ.',
  bestFairlyGoodOthersLightAtHigherPrices: 'MEJOR bastante buena, otros LUZ en mayor PRECIOS.',
  bestFairlyGoodOthersLightButImproving: 'MEJOR bastante buena, otros LUZ pero mejorando.',
  bestFairlyGoodOthersLightOrganicBestGoodOthersModerate:
    'MEJOR bastante buena, otros encender; ORGÁNICA MEJOR buenos, otros moderado.',
  bestFairlyGoodOthersLightOrganicGood: 'MEJOR bastante buena, otros encender; BUENA orgánica.',
  bestFairlyGoodOthersLightOrganicModerate: 'MEJOR bastante buena, otros encender; ORGÁNICA moderado.',
  bestFairlyGoodOthersModerate: 'MEJOR bastante buena, otros moderado.',
  bestFairlyGoodOthersModerateOrganicModerate: 'MEJOR bastante buena, otros moderados; ORGÁNICA moderado.',
  bestFairlyGoodOthersModerateOrganicVeryGood: 'MEJOR bastante buena, otros moderados; BUENA muy orgánico.',
  bestFairlyGoodOthersVeryLight: 'MEJOR bastante buena, otros muy ligero.',
  bestFairlyLightOthersLight: 'MEJOR bastante ligero, otros la luz.',
  bestFairlyLightOthersLightOrganicBestModerateOthersLight:
    'MEJOR bastante ligero, otros encender; ORGÁNICA MEJOR moderado, OTROS LUZ.',
  bestFairlyLightOthersVeryLight: 'MEJORES bastante ligero, otros muy ligero.',
  bestFairlyLightOthersVeryLightOrganicGood: 'MEJORES bastante ligero, otros muy LUZ; BUENA orgánica.',
  bestGoodAtLowerPricesOthersFairlyLight: 'MEJORES PRECIOS EN BUENA más bajo y otros bastante ligero.',
  bestGoodAtLowerPricesOthersLight: 'MEJORES PRECIOS EN BUENA más bajo y otros LUZ.',
  bestGoodAtLowerPricesOthersModerate: 'MEJOR BUENO EN bajar los precios, otros moderado.',
  bestGoodAtLowerPricesOthersModerateOrganicGood: 'MEJOR BUENO EN bajar los precios, otros moderados; BUENA orgánica.',
  bestGoodAtLowerPricesOthersModerateOrganicLight: 'MEJOR BUENO EN bajar los precios, otros moderados; Luz orgánica.',
  bestGoodAtLowerPricesOthersModerateOrganicVeryGood:
    'MEJOR BUENO EN bajar los precios, otros moderados; BUENA muy orgánico.',
  bestGoodAtLowerPricesOthersVeryLight: 'MEJORES PRECIOS EN BUENA más bajo y otros muy ligero.',
  bestGoodAtSlightlyLowerPricesOthersFairlyLight: 'MEJOR BUENA a precios ligeramente más bajo y otros bastante ligero.',
  bestGoodAtSlightlyLowerPricesOthersLight: 'MEJOR BUENA a precios ligeramente más bajo y otros luz.',
  bestGoodAtSlightlyLowerPricesOthersModerate: 'MEJOR BUENA a precios ligeramente más bajo y otros moderados.',
  bestGoodAtSlightlyLowerPricesOthersModerateOrganicBestModerateOthersVeryLight:
    'MEJOR BUENA a precios ligeramente más bajo y otros moderados; ORGÁNICA MEJOR moderado, OTROS muy ligero.',
  bestGoodAtSlightlyLowerPricesOthersModerateOrganicVeryGood:
    'MEJOR BUENA a precios ligeramente más bajo y otros moderados; BUENA muy orgánico.',
  bestGoodAtSlightlyLowerPricesOthersVeryLight: 'MEJOR BUENA a precios ligeramente más bajo y otros muy ligero.',
  bestGoodOthersFairlyLight: 'MEJOR buenos, otros bastante ligero.',
  bestGoodOthersFairlyLightAtHigherPrices: 'MEJOR buenos, otros bastante ligero en mayor PRECIOS.',
  bestGoodOthersFairlyLightOrganicBestFairlyLightOthersVeryLight:
    'MEJOR buenos, otros bastante ligero; ORGÁNICA MEJOR bastante ligero, otros muy ligero.',
  bestGoodOthersFairlyLightOrganicBestModerateOthersLight:
    'MEJOR buenos, otros bastante ligero; ORGÁNICA MEJOR moderado, OTROS LUZ.',
  bestGoodOthersFairlyLightOrganicBestModerateOthersVeryLight:
    'MEJOR buenos, otros bastante ligero; ORGÁNICA MEJOR moderado, OTROS muy ligero.',
  bestGoodOthersFairlyLightOrganicExceedsSupply: 'MEJOR buenos, otros bastante ligero; ORGÁNICA supera a la oferta.',
  bestGoodOthersFairlyLightOrganicModerate: 'MEJOR buenos, otros bastante ligero; ORGÁNICA moderado.',
  bestGoodOthersLight: 'MEJOR buenos, otros LUZ.',
  bestGoodOthersLightAtHigherPrices: 'MEJORES buenos, otros LUZ A mayores precios.',
  bestGoodOthersLightOrganicLight: 'MEJOR buenos, otros la luz; Luz orgánica.',
  bestGoodOthersModerate: 'La mejor buena OTROS moderado.',
  bestGoodOthersModerateOrganicBestFairlyLightOthersVeryLight:
    'MEJOR buenos, otros moderados; ORGÁNICA MEJOR bastante ligero, otros muy ligero.',
  bestGoodOthersModerateOrganicBestModerateOthersLight:
    'MEJOR buenos, otros moderados; ORGÁNICA MEJOR moderado, OTROS LUZ.',
  bestGoodOthersModerateOrganicBestModerateOthersVeryLight:
    'MEJOR buenos, otros moderados; ORGÁNICA MEJOR moderado, OTROS muy ligero.',
  bestGoodOthersModerateOrganicExceedsSupply: 'MEJOR buenos, otros moderados; ORGÁNICA supera a la oferta.',
  bestGoodOthersModerateOrganicFairlyLight: 'MEJOR buenos, otros moderados; ORGÁNICA bastante ligero.',
  bestGoodOthersModerateOrganicFairlyLightAtHigherPrices:
    'MEJOR buenos, otros moderados; ORGÁNICOS bastante PRECIOS LUZ EN ALTAS.',
  bestGoodOthersModerateOrganicLight: 'MEJOR buenos, otros moderados; Luz orgánica.',
  bestGoodOthersModerateOrganicModerate: 'MEJOR buenos, otros moderados; ORGÁNICA moderado.',
  bestGoodOthersModerateOrganicVeryGood: 'MEJOR buenos, otros moderados; BUENA muy orgánico.',
  bestGoodOthersModerte: 'MEJOR buenos, otros MODERTE.',
  bestGoodOthersVeryLight: 'MEJOR buenos, otros muy ligero.',
  bestLightOthersPracticallyNoDemand: 'Mejor luz, otros prácticamente no DEMAND.',
  bestLightOthersVeryLight: 'Mejor luz, otros muy ligero.',
  bestLightOthersVeryLightOrganicBestFairlyLightOthersVeryLight:
    'Mejor luz, otros muy LUZ; ORGÁNICA MEJOR bastante ligero, otros muy ligero.',
  bestLightOthersVeryLightOrganicBestGoodOthersModerate:
    'Mejor luz, otros muy LUZ; ORGÁNICA MEJOR buenos, otros moderado.',
  bestLightOthersVeryLightOrganicBestModerateOthersVeryLight:
    'Mejor luz, otros muy LUZ; ORGÁNICA MEJOR moderado, OTROS muy ligero.',
  bestModerate: 'MEJOR moderado.',
  bestModerateOrganicBestModerateOthersLight: 'MEJOR moderada, ORGÁNICA MEJOR moderado, OTROS LUZ.',
  bestModerateOrganicBestModerateOthersVeryLight: 'MEJOR moderada, ORGÁNICA MEJOR moderado, OTROS muy ligero.',
  bestModerateOthersFairlyLight: 'MEJOR moderado, OTROS bastante ligero.',
  bestModerateOthersFairlyLightOrganicBestGoodOthersModerate:
    'MEJORES moderado, OTROS bastante ligero; ORGÁNICA MEJOR buenos, otros moderados ;.',
  bestModerateOthersLight: 'MEJOR moderado, OTROS LUZ.',
  bestModerateOthersLightAtHigherPrices: 'MEJOR moderado, otros encender un mayor PRECIOS.',
  bestModerateOthersLightAtHigherPricesOrganicModerate:
    'MEJORES PRECIOS DE LUZ, otros en mayor MODERADO; ORGÁNICA moderado.',
  bestModerateOthersLightAtSlightlyHigherPrices: 'MEJOR moderado, OTROS Luz en precios ligeramente más altos.',
  bestModerateOthersLightOrganicBestFairlyGoodOthersLight:
    'MEJOR MODERADO, otros encender; ORGÁNICA MEJOR bastante buena, otros la luz.',
  bestModerateOthersLightOrganicBestGoodOthersFairlyLight:
    'MEJOR MODERADO, otros encender; ORGÁNICA MEJOR buenos, otros bastante ligero.',
  bestModerateOthersLightOrganicBestGoodOthersLight:
    'MEJOR MODERADO, otros encender; ORGÁNICA MEJOR buenos, otros LUZ.',
  bestModerateOthersLightOrganicBestGoodOthersModerate:
    'MEJOR MODERADO, otros encender; ORGÁNICA MEJOR buenos, otros moderado.',
  bestModerateOthersLightOrganicExceedsSupply: 'MEJOR MODERADO, otros encender; ORGÁNICA supera a la oferta.',
  bestModerateOthersLightOrganicGood: 'MEJOR MODERADO, otros encender; BUENA orgánica.',
  bestModerateOthersLightOrganicModerate: 'MEJOR MODERADO, otros encender; ORGÁNICA moderado.',
  bestModerateOthersLightOrganicVeryGood: 'MEJOR MODERADO, otros encender; BUENA muy orgánico.',
  bestModerateOthersVeryLight: 'MEJOR moderada, OTROS muy ligero.',
  bestModerateOthersVeryLightAtSlightlyHigherPrices: 'MEJOR moderado, otros muy Luz en precios ligeramente más altos.',
  bestModerateOthersVeryLightButImproving: 'MEJOR MODERADO, otros muy ligero pero mejorando.',
  bestModerateOthersVeryLightOrganicGood: 'MEJORES moderado, otros muy LUZ; BUENA orgánica.',
  bestModertateOthersFairlyLight: 'MEJOR MODERTATE, otros bastante ligero.',
  bestVeryGoodAtSlightlyLowerPricesOthersModerate: 'MEJORES Muy buenos precios ligeramente más bajos, otros moderado.',
  bestVeryGoodOthersFairlyGood: 'MEJOR MUY BUENO, otros bastante buena.',
  bestVeryGoodOthersGood: 'MEJOR MUY BUENO, bien a otros.',
  bestVeryGoodOthersModerate: 'MEJOR MUY BUENO, otros moderado.',
  conveintionalFairlyLightOrganicLight: 'CONVEINTIONAL bastante ligero, luz orgánica.',
  'conventional121-pintsAnd6-ounceCupsGoodAtLowerPricesOthersGood':
    '12 convencional 1-pintas y CUPS 6 onzas GOOD AT precios más bajos, bien a otros.',
  'conventional121-pintsVeryGoodAtSlightlyLowerPricesOthersVeryGood':
    '12 convencional 1-PINTS muy bueno en precios ligeramente más bajo y otros muy bueno. .',
  conventionalBestFairlyGoodOthersModerateOrganicFairlyLight:
    'CONVENCIONAL MEJOR bastante buena, otros moderados; ORGÁNICA bastante ligero.',
  conventionalBestFairlyLightOthersLightOrganicLight:
    'CONVENCIONAL MEJOR bastante ligero, otros encender; Luz orgánica.',
  conventionalBestGoodOthersFairlyLightAtHigherPricesOrganicBestModerateOthersLight:
    'CONVENCIONAL MEJOR buenos, otros bastante ligero en mayor PRECIOS; ORGÁNICA MEJOR moderado, OTROS LUZ.',
  conventionalBestGoodOthersModerateOrganicFairlyLight:
    'CONVENCIONAL MEJOR buenos, otros moderados; ORGÁNICA bastante ligero.',
  conventionalBestModerateOthersFairlyLightOrganicFairlyLight:
    'CONVENCIONAL MEJOR moderado, OTROS bastante ligero; ORGÁNICA bastante ligero.',
  conventionalBestModerateOthersFairlyLightOrganicLight:
    'CONVENCIONAL MEJOR moderado, OTROS bastante ligero; Luz orgánica.',
  conventionalExceedsOfferingsOrganicVeryGood: 'CONVENCIONAL SUPERA OFRENDAS, buena muy orgánico.',
  conventionalExceedsSupplyOrganicFairlyGood: 'CONVENCIONAL supera a la oferta, ORGÁNICA bastante buena.',
  conventionalExceedsSupplyOrganicFairlyLight: 'CONVENCIONAL supera a la oferta, ORGÁNICA bastante ligero.',
  conventionalExceedsSupplyOrganicGood: 'CONVENCIONAL supera a la oferta, Orgánica Bueno.',
  conventionalExceedsSupplyOrganicGoodAtLowerPrices:
    'CONVENCIONAL supera a la oferta, Orgánica Bueno a un menor PRECIOS.',
  conventionalExceedsSupplyOrganicGoodAtSlightlyLowerPrices:
    'CONVENCIONAL supera a la oferta, Orgánica Bueno ligeramente precios más bajos.',
  conventionalExceedsSupplyOrganicModerate: 'CONVENCIONAL supera a la oferta, ORGÁNICA moderado.',
  conventionalFairlyGoodAtLowerPricesOrganicVeryGood:
    'CONVENCIONALES bastante buenos precios a un menor, Orgánica Bueno MUY.',
  conventionalFairlyGoodAtSlightlyLowerPricesOrganicFairlyGood:
    'CONVENCIONAL bastante buena a precios ligeramente más bajos, ORGÁNICA bastante buena.',
  conventionalFairlyGoodAtSlightlyLowerPricesOrganicGood:
    'CONVENCIONAL bastante buena a precios ligeramente más bajos, BUENA orgánica.',
  conventionalFairlyGoodOrganicFairlyLight: 'CONVENCIONAL bastante buena, ORGÁNICA bastante ligero.',
  conventionalFairlyGoodOrganicGood: 'CONVENCIONAL bastante buena, BUENA orgánica.',
  conventionalFairlyGoodOrganicLight: 'CONVENCIONAL bastante buena, luz orgánica.',
  conventionalFairlyGoodOrganicModerate: 'CONVENCIONAL bastante buena, ORGÁNICA moderado.',
  conventionalFairlyGoodOrganicVeryGood: 'CONVENCIONAL bastante buena, buena, muy ORGÁNICA.',
  conventionalFairlyLightOrganicGood: 'CONVENCIONAL bastante ligero, Orgánica Bueno.',
  conventionalFairlyLightOrganicLight: 'CONVENCIONAL bastante ligero, luz orgánica.',
  conventionalFairlyLightOrganicModerate: 'CONVENCIONAL bastante ligero, ORGÁNICA moderado.',
  conventionalFairlyLightOrganicVeryGood: 'CONVENCIONAL bastante ligero, bueno muy orgánico.',
  conventionalFairlyLightOrganicVeryLight: 'CONVENCIONAL bastante ligero, ORGÁNICA muy ligero.',
  conventionalGoodAtLowerPricesOrganicGood: 'PRECIOS CONVENCIONAL BUENO BUENO, a un menor orgánica.',
  conventionalGoodAtSlightlyLowerPricesOrganicGood:
    'CONVENCIONAL buena ya precios ligeramente más bajos, buena orgánica.',
  conventionalGoodOrganicBestGoodAtLowerPricesOthersModerate:
    'GOOD convencional; ORGÁNICA mayor bien a un menor PRECIOS, otros moderado.',
  conventionalGoodOrganicBestGoodOthersModerate: 'GOOD convencional; ORGÁNICA MEJOR buenos, otros moderado.',
  conventionalGoodOrganicFairlyGood: 'CONVENCIONAL BUENO, ORGÁNICA bastante buena.',
  conventionalGoodOrganicFairlyLight: 'BUENA convencional, la ecológica bastante ligero.',
  conventionalGoodOrganicGoodAtSlightlyLowerPrices:
    'CONVENCIONAL BUENO, bueno en ORGÁNICA ligeramente inferior PRECIOS.',
  conventionalGoodOrganicLight: 'CONVENCIONAL BUENO, luz orgánica.',
  conventionalGoodOrganicModerate: 'BUENA convencional, la ecológica moderada.',
  conventionalGoodOrganicVeryGood: 'CONVENCIONAL bueno, bueno muy orgánico.',
  conventionalLightOrganicFairlyLight: 'CONVENCIONAL LUZ, ORGÁNICA bastante ligero.',
  conventionalLightOrganicGood: 'LUZ CONVENCIONAL, Orgánica Bueno.',
  conventionalLightOrganicModerate: 'CONVENCIONAL LUZ, ORGÁNICA moderado.',
  conventionalLightOrganicVeryLight: 'CONVENCIONAL LUZ, ORGÁNICA muy ligero.',
  conventionalModerateForFairlyLightSuppliesOrganicGood:
    'CONVENCIONAL moderado para SUMINISTROS bastante ligero, buen orgánica.',
  conventionalModerateOrganicFairlyGood: 'CONVENCIONAL moderado, ORGÁNICA bastante buena.',
  conventionalModerateOrganicFairlyLight: 'CONVENCIONAL moderado, ORGÁNICA bastante ligero.',
  conventionalModerateOrganicGood: 'CONVENCIONAL moderado, Orgánica Bueno.',
  conventionalModerateOrganicLight: 'CONVENCIONAL moderado, luz orgánica.',
  conventionalModerateOrganicVeryGood: 'CONVENCIONAL moderado, bueno muy orgánico.',
  conventionalModerateOrganicVeryLight: 'CONVENCIONAL moderado, ORGÁNICA muy ligero.',
  conventionalPintsSlightlyHigherOthersAboutSteady:
    'PINTS CONVENCIONALES ligeramente superior, a otros acerca estable.',
  conventionalVeryGoodAtLowerPricesOrganicGoodAtSlightlyLowerPrices:
    'CONVENCIONALES muy buenos precios a un menor, Orgánica Bueno ligeramente precios más bajos.',
  conventionalVeryGoodAtLowerPricesOrganicVeryGood:
    'CONVENCIONALES Los precios son muy bueno a un menor, Orgánica Bueno MUY.',
  'conventionalVeryGoodAtSlightlyLowerPrices OrganicVeryGood':
    'CONVENCIONAL muy bueno en precios ligeramente más bajos, ORGÁNICA muy bueno.',
  conventionalVeryGoodAtSlightlyLowerPricesOrganicVeryGood:
    'CONVENCIONAL muy bueno en precios ligeramente más bajos, ORGÁNICA muy bueno.',
  conventionalVeryGoodOrganicBestGoodOthersModerate: 'Bueno muy convencional; ORGÁNICA MEJOR buenos, otros moderado.',
  conventionalVeryGoodOrganicFairlyGood: 'MUY BUENO convencional, la ecológica bastante buena.',
  conventionalVeryGoodOrganicGood: 'MUY BUENO convencional, BUENA orgánica.',
  conventionalVeryGoodOrganicGoodAtSlightlyLowerPrices:
    'CONVENCIONAL MUY BUENO, ORGÁNICA bueno en precios ligeramente más bajos.',
  conventionalVeryGoodOrganicModerate: 'MUY BUENO convencional, la ecológica moderada.',
  conventionalVeryLightOrganicFairlyLight: 'CONVENCIONAL muy ligero, ORGÁNICA bastante ligero.',
  conventionalVeryLightOrganicGood: 'CONVENCIONAL muy ligero, Orgánica Bueno.',
  conventionalVeryLightOrganicLight: 'CONVENCIONAL muy ligero, luz orgánica.',
  conventionalVeryLightorganicLight: 'CONVENCIONAL muy ligero, luz orgánica.',
  conventionalVeryLightOrganicModerate: 'CONVENCIONAL muy ligero, ORGÁNICA moderado.',
  conventionFairlyGoodAtSlightlyLowerPricesOrganicFairlyGood:
    'CONVENIO bastante buena a precios ligeramente más bajos, ORGÁNICA bastante buena.',
  convetionalBestGoodOthersModerateOrganicFairlyLight:
    'CONVETIONAL MEJOR buenos, otros moderados; ORGÁNICA bastante ligero.',
  convetionalGoodOrganicFairlyLight: 'BUENA CONVETIONAL, ORGÁNICA bastante ligero.',
  demandConventionalExceedsSupplyOrganicGood: 'La demanda convencional supera a la oferta, Orgánica Bueno.',
  demandConventionalGoodOrganicModerate: 'La demanda convencional BUENO, ORGÁNICA moderado.',
  demandConventionalVeryGoodOrganicGood: 'La demanda convencional Muy bueno, Bueno orgánica.',
  demandConventionalVeryGoodOrganicModerate: 'La demanda convencional MUY BUENO, ORGÁNICA moderado.',
  demandGood: 'Buena demanda.',
  exceedsAvailableSupplies: 'SUPERA suministros disponibles.',
  exceedsOfferings: 'SUPERA ofertas.',
  exceedsSupplies: 'SUPERA suministros.',
  exceedsSupply: 'Supera a la oferta.',
  failryLight: 'Failry LUZ.',
  fairlyGood: 'BASTANTE BUENO.',
  fairlyGoodAtLowerPrices: 'Bastante buenos precios a un menor.',
  fairlyGoodAtMuchLowerPrices: 'Bastante buena a precios mucho más bajos.',
  fairlyGoodAtSlightlyLowerPrices: 'Bastante buena a precios ligeramente más bajos.',
  fairlyGoodFor28PoundAtLowerPricesOthersModerate:
    'Bastante buena para el 28 libra a bajar los precios, otros moderado.',
  fairlyLight: 'BASTANTE LIGERO.',
  fairlyLightAndInFewHands: 'Bastante ligero Y EN MANOS pocos.',
  fairlyLightAtHigherPrices: 'Bastante PRECIOS LUZ EN ALTAS.',
  fairlyLightAtSlightlyHigherPrices: 'Bastante ligero A PRECIOS ligeramente superior.',
  fairlyLightatSlightlyHigherPrices: 'Bastante LIGHTAT precios ligeramente más altos.',
  fairlyLightForIncreasingSupplies: 'Bastante ligera para el aumento de los suministros.',
  fairyGood: 'Hada de la buena.',
  'flats121-pintBasketsGoodOthersBestGoodAtSlightlyLowerPricesOthersModerate':
    'PISOS 12 canastas 1 pinta buenos, otros MEJOR BUENO EN precios ligeramente más bajo y otros moderado.',
  'flats126-ounceCupsWithLidsVeryGoodAtLowerPricesOthersVeryGood':
    'PISOS 12 tazas de 6 onzas con tapas muy bueno en precios más bajos, otros muy bueno.',
  'flats81-lbContainersWithLidsGoodAtSlightlyLowerPricesOthersBestGoodOthersModerate':
    'PISOS 8 1-LB recipientes con tapas bueno en precios ligeramente más bajos, otros MEJOR buenos, otros moderado.',
  'flats81-poundContainersFairlyGoodAtLowerPricesOthersBestGoodOthersModerate':
    'PISOS 8 contenedores de 1 libra bastante buena a un menor PRECIOS, otros MEJOR buenos, otros moderado.',
  'flats81-poundContainersGoodAtLowerPricesOthersGood':
    'PISOS 8 1 libras CONTAINERS GOOD AT precios más bajos, bien a otros.',
  'flats81-poundContainersGoodAtSlightlyLowerPricesOthersGood':
    'PISOS 8 1 libras CONTAINERS bueno en precios ligeramente más bajos, otros bien.',
  'flts121-pintCupsFairlyGoodAtLowerPrices126-ounceCupsFairlyGood':
    'FLTS 12 tazas 1-PINT bastante bueno a un menor PRECIOS, 12 tazas de 6 onzas bastante buena.',
  good: 'BUENO.',
  goodAtLowerPrices: 'Buenos precios a un menor.',
  goodAtSlightlyLowerPrices: 'Bueno en precios ligeramente más bajos.',
  goodAtSlightlyLowerPricesOnBunched28PoundAboutSteadyOn11Pound:
    'Bueno en ligeramente inferior PRECIOS EN agrupado 28 LIBRA, ACERCA DE CONSTANTE EN 11 libra.',
  goodAtSlightlyPrices: 'Bueno en precios ligeramente.',
  goodExceptConventionalPintsGoodAtSlightlyLowerPrices:
    'Bueno, sino CONVENCIONAL PINTS bueno en precios ligeramente más bajos.',
  goodExeptConventional6OzGoodAtSlightlyLowerPrices:
    'BUENO, exepto CONVENCIONAL 6 OZ bueno en precios ligeramente más bajos.',
  goodForLightSupplies: 'BUENO PARA SUMINISTROS luz.',
  goodOrganicBestModerateOthersLight: 'BUENO; ORGÁNICA MEJOR moderado, OTROS LUZ.',
  'heartsAnd21/2DozenGoodAtSlightlyLowerPrices3DozenGoodAtLowerPricesOthersGood':
    'CORAZONES Y 2 1/2 DOZEN bueno en precios ligeramente más bajos, 3 PRECIOS DOZEN GOOD a un menor, otros bien.',
  heartsFairlyGoodAtLowerPricesOthersModerate: 'CORAZONES bastante buena a un menor PRECIOS, otros moderado.',
  heartsFairlyGoodAtSlightlyLowerPricesOthersFairlyGood:
    'CORAZONES EN bastante buena precios ligeramente más bajo y otros bastante buena.',
  heartsFairlyGoodOthersFairlyLight: 'CORAZONES bastante buena, otros bastante ligero.',
  heartsFairlyGoodOthersGood: 'CORAZONES bastante buena, bien a otros.',
  heartsFairlyGoodOthersModerate: 'CORAZONES bastante buena, otros moderado.',
  heartsFairlyGoodOthersVeryGood: 'CORAZONES bastante buena, otros muy buenos.',
  heartsFairlyLightOthersLight: 'CORAZONES bastante ligero, otros la luz.',
  heartsGoodAtSlightlyLowerPricesOthersAboutSteady:
    'CORAZONES bueno en precios ligeramente más bajos, otros acerca estable.',
  heartsGoodOthersFairlyGood: 'CORAZONES buenos, otros bastante buena.',
  heartsGoodOthersFairlyLight: 'CORAZONES buenos, otros bastante ligero.',
  heartsGoodOthersGoodAtSlightlyLowerPrices: 'CORAZONES buenos, otros buenos en ligeramente inferior PRECIOS.',
  heartsGoodOthersLight: 'CORAZONES buenos, otros LUZ.',
  heartsGoodOthersModerate: 'CORAZONES buenos, otros moderado.',
  heartsGoodOthersVeryGood: 'CORAZONES buenos, otros muy bueno.',
  heartsModerate2DozenLightOthersFairlyLight: 'CORAZONES moderada, 2 docenas de LUZ, otros bastante ligero.',
  heartsModerateOthersFairlyLight: 'CORAZONES moderados, otros bastante ligero.',
  heartsModerateOthersLight: 'CORAZONES moderados, otros LUZ.',
  heartsVeryLightOthersLight: 'CORAZONES muy ligero, OTROS luz.',
  jumboAndLargeSizeModerateOthersVeryLight: 'JUMBO Y GRAN TAMAÑO moderados, otros muy ligero.',
  jumboLightOthersFairlyGood: 'LUZ JUMBO, otros bastante buena.',
  jumboLightOthersModerate: 'JUMBO LUZ, otros moderado.',
  jumboVeryLightOthersLight: 'JUMBO muy ligero, OTROS luz.',
  largeAndJumboFairlyGoodOthersFairlyLight: 'GRANDE Y JUMBO bastante buena, otros bastante ligero.',
  largeAndJumboModerateStandardFairlyLight: 'GRANDE Y JUMBO moderado, ESTÁNDAR bastante ligero.',
  largeGoodOthersFairlyGood: 'BIEN GRANDE, otros bastante buena.',
  largeModerateOthersFairlyLight: 'GRAN MODERADO, otros bastante ligero.',
  light: 'LIGERO.',
  lightAtHigherPrices: 'PRECIOS DE LUZ EN ALTAS.',
  lightAtSlightlyHigherPrices: 'LUZ A PRECIOS ligeramente superior.',
  lightDemandAtSlightlyHigherPrices: 'DEMANDA EN LA LUZ precios ligeramente más altos.',
  lower: 'INFERIOR.',
  moderate: 'MODERAR.',
  moderateOrganicBestFairlyGoodOthersLight: 'MODERAR; ORGÁNICA MEJOR bastante buena, otros la luz.',
  moderateOrganicGood: 'MODERADO, BUENA orgánica.',
  moderateOrganicVeryGood: 'MODERAR; BUENA muy orgánico.',
  moderste: 'MODERSTE.',
  modertate: 'MODERTATE.',
  organic6OzGoodOthersGoodAtSlightlyLowerPrices: 'ORGÁNICA 6 oz Bueno, bien a otros a precios ligeramente más bajos.',
  'organicAndMedium-largeExceedSupplyOthersVeryGoodAtSlightlyLowerPrices':
    'ORGÁNICA Y MEDIANO GRANDE superior a la oferta, otros muy buenos en precios ligeramente más bajos.',
  organicBestFairlyLightOthersVeryLightOthersGood: 'ORGÁNICA MEJOR bastante ligero, otros muy LUZ; BUENA OTROS.',
  organicBestFairlyLightOthersVeryLightOthersModerate: 'ORGÁNICA MEJOR bastante ligero, otros muy LUZ; OTROS moderado.',
  organicBestGoodAtLowerPricesOthersModerateOthersBestModerateOthersLight:
    'ORGÁNICA mayor bien a un menor PRECIOS, otros moderados; OTROS MEJOR moderado, otros encender.',
  organicBestGoodAtLowerPricesOthersModerateOthersExceedSupply:
    'ORGÁNICA mayor bien a un menor PRECIOS, otros moderados; OTROS superior a la oferta.',
  organicBestGoodOthersFairlyLightOthersVeryGood: 'ORGÁNICA MEJOR buenos, otros bastante ligero; OTROS MUY BUENO.',
  organicBestGoodOthersModerateConventionalBestModerateOthersVeryLight:
    'ORGÁNICA MEJOR buenos, otros moderados; CONVENCIONAL MEJOR moderado, OTROS muy ligero.',
  organicBestGoodOthersModerateOthersBestModerateOthersLight:
    'ORGÁNICA MEJOR buenos, otros moderados; OTROS MEJOR moderado, otros encender.',
  organicBestGoodOthersModerateOthersExceedSupply:
    'ORGÁNICA MEJOR buenos, otros moderados, otros superior a la oferta.',
  organicBestModerateOthersLightConventionalBestGoodOthersModerate:
    'ORGÁNICA MEJOR moderado, otros encender; CONVENCIONAL MEJOR buenos, otros moderado.',
  organicBestModerateOthersLightConventionalBestModerateOthersFairlyLight:
    'ORGÁNICA MEJOR moderado, otros encender; CONVENCIONAL MEJOR moderado, OTROS bastante ligero.',
  organicBestModerateOthersLightOthersBestFairlyGoodOthersLight:
    'ORGÁNICA MEJOR moderado, otros encender; OTROS MEJOR bastante buena, otros la luz.',
  organicBestModerateOthersLightOthersBestGoodOthersLight:
    'ORGÁNICA MEJOR moderado, otros encender; OTROS MEJOR buenos, otros la luz.',
  organicBestModerateOthersLightOthersBestGoodOthersModerate:
    'ORGÁNICA MEJOR moderado, otros encender; OTROS MEJOR buenos, otros moderado.',
  organicBestModerateOthersLightOthersGood: 'ORGÁNICA MEJOR moderado, otros encender; BUENA OTROS.',
  organicBestModerateOthersVeryLightOthersBestGoodOthersVeryLight:
    'ORGÁNICA MEJOR moderado, otros muy ligeras; OTROS MEJOR buenos, otros muy ligero.',
  organicBestModerateOthersVeryLightOthersBestModerateOthersLight:
    'ORGÁNICA MEJOR moderado, otros muy ligeras; OTROS MEJOR moderado, otros encender.',
  organicBestModerateOthersVeryLightOthersBestModerateOthersLightAtHigherPrices:
    'ORGÁNICA MEJOR moderado, otros muy ligeras; Los otros mejor MODERADO, otros LUZ EN los precios más altos.',
  organicBestModerateOthersVeryLightOthersModerate: 'ORGÁNICA MEJOR moderado, otros muy ligeras; OTROS moderado.',
  organicBestVeryGoodOthersModerateOthersExceedSupply:
    'ORGÁNICA MEJOR MUY BUENO, otros moderados; OTROS superior a la oferta.',
  organicExceedsSupply: 'ORGÁNICA supera a la oferta.',
  organicExceedsSupplyOthersBestGoodAtLowerPricesOthersModerate:
    'ORGÁNICA supera a la oferta, los otros mejor bueno en bajar los precios, otros moderado.',
  organicExceedsSupplyOthersBestGoodOthersModerate:
    'ORGÁNICA supera a la oferta, los otros mejor buenos, otros moderado.',
  organicExceedsSupplyOthersGood: 'ORGÁNICA supera a la oferta, bien a otros.',
  organicExceedsSupplyOthersVeryGood: 'ORGÁNICA supera a la oferta, OTROS MUY BUENO.',
  'organicExceedSupply121-pintBasketsGoodOthersBestGoodAtLowerPricesOthersModerate':
    'ORGÁNICA superior a la oferta, 12 1-PINT CESTAS buenos, otros MEJOR bueno en bajar los precios, otros moderado.',
  organicExceedSupplyOthersBestGoodOthersModerate:
    'ORGÁNICA superior a la oferta, los otros mejor buenos, otros moderado.',
  organicExceedSupplyOthersGood: 'ORGÁNICA superior a la oferta, bien a otros.',
  organicExceedSupplyOthersVeryGood: 'ORGÁNICA superior a la oferta, OTROS MUY BUENO.',
  organicExceedSupplyOthersVeryGoodAtSlightlyLowerPrices:
    'ORGÁNICA superior a la oferta, otros muy buenos en precios ligeramente más bajos.',
  organicFairlyGoodAtLowerPricesOthersBestFairlyGoodOthersModerate:
    'ORGÁNICA bastante buena a un menor PRECIOS, otros MEJOR bastante buenos, otros moderado.',
  organicFairlyGoodAtSlightlyLowerPricesOthersFairlyGood:
    'ORGÁNICA bastante buena a precios ligeramente más bajo y otros bastante buena.',
  organicFairlyGoodConventionalBestModerateOthersLight:
    'ORGÁNICA bastante buena, CONVENCIONAL MEJOR moderado, otros encender.',
  organicFairlyGoodConventionalBestModerateOthersVeryLight:
    'ORGÁNICA bastante buena, CONVENCIONAL MEJOR moderado, OTROS muy ligero.',
  organicFairlyGoodConventionalFairlyLight: 'ORGÁNICA bastante buena, CONVENCIONAL bastante ligero.',
  organicFairlyGoodConventionalModerate: 'ORGÁNICA bastante buena, CONVENCIONAL moderado.',
  organicFairlyGoodOthersBestFairlyGoodOthersLight:
    'ORGÁNICA bastante buena, los otros mejor bastante buena, otros LUZ.',
  organicFairlyGoodOthersBestGoodAtLowerPricesOthersSlow:
    'ORGÁNICA bastante buena, los otros mejor BUENO EN bajar los precios, otros lentos.',
  organicFairlyGoodOthersBestModerateOthersLight: 'ORGÁNICA bastante buena, los otros mejor MODERADO, otros LUZ.',
  organicFairlyGoodOthersFairlyLight: 'ORGÁNICA bastante buena, otros bastante ligero.',
  organicFairlyGoodOthersLight: 'ORGÁNICA bastante buena, otros LUZ.',
  organicFairlyGoodOthersModerate: 'ORGÁNICA bastante buena, otros moderado.',
  organicFairlyGoodOthersVeryLight: 'ORGÁNICA bastante buena, otros muy ligero.',
  organicFairlyLightConventionalBestModerateOthersLight:
    'ORGANIC bastante ligero; CONVENCIONAL MEJOR moderado, OTROS LUZ.',
  organicFairlyLightConventionalLight: 'ORGÁNICA bastante ligero, CONVENCIONAL LUZ.',
  organicFairlyLightConventionalVeryLight: 'ORGÁNICA bastante ligero, CONVENCIONAL muy ligero.',
  organicFairlyLightOthersModerate: 'ORGÁNICA bastante ligero, otros moderado.',
  organicFairlyLightToGoodOthersVeryGood: 'ORGÁNICA bastante ligero a bueno; OTROS MUY BUENO.',
  organicFairlySlowOthersFairlyGood: 'ORGÁNICA bastante lento, otros bastante buena.',
  organicGoodAtLowerPricesConventionalFairlyGood: 'PRECIOS Orgánica Bueno a un menor, CONVENCIONAL bastante buena.',
  organicGoodAtLowerPricesOthersBestGoodOthersModerate:
    'PRECIOS Orgánica Bueno a un menor, los otros mejor buenos, otros moderado.',
  organicGoodAtLowerPricesOthersBestModerateOthersLight:
    'GOOD ORGANIC a un menor PRECIOS; OTROS MEJOR moderado, otros encender.',
  organicGoodAtLowerPricesOthersExceedsSupply: 'Orgánica Bueno a un menor PRECIOS, OTROS supera a la oferta.',
  organicGoodAtLowerPricesOthersGood: 'PRECIOS Orgánica Bueno a un menor, bien a otros.',
  organicGoodAtLowerPricesOthersModerate: 'GOOD ORGANIC a un menor PRECIOS; OTROS moderado.',
  organicGoodAtLowerPricesOthersVeryGod: 'PRECIOS Orgánica Bueno a un menor, otros muy Dios.',
  organicGoodAtLowerPricesOthersVeryGood: 'PRECIOS Orgánica Bueno a un menor, otros muy buenos.',
  organicGoodAtSlightlyLowerPricesOthersGood: 'Orgánica Bueno a precios ligeramente más bajos, bien a otros.',
  organicGoodConventionalBestGoodOthersModerate: 'GOOD orgánico; CONVENCIONAL MEJOR buenos, otros moderado.',
  organicGoodConventionalBestModerateOthersLight: 'GOOD orgánico; CONVENCIONAL MEJOR moderado, OTROS LUZ.',
  organicGoodConventionalFairlyGood: 'BUENA ORGÁNICA, CONVENCIONAL bastante buena.',
  organicGoodConventionalFairlyLight: 'BUENA ORGÁNICA, CONVENCIONAL bastante ligero.',
  organicGoodConventionalGoodAtSlightlyLowerPrices:
    'Orgánica Bueno, bueno en CONVENCIONAL ligeramente inferior PRECIOS.',
  organicGoodConventionalLight: 'Orgánica Bueno, CONVENCIONAL LUZ.',
  organicGoodConventionalModerate: 'BUENA ORGÁNICA, CONVENCIONAL moderado.',
  organicGoodConventionalVeryLight: 'BUENA ORGÁNICA, CONVENCIONAL muy ligero.',
  organicGoodOthersBestFairlyLightOthersVeryLight: 'Orgánica Bueno, los otros mejor bastante ligero, otros muy ligero.',
  organicGoodOthersBestGoodAtLowerPrices: 'Orgánica Bueno, los otros mejor BUENO EN bajar los precios.',
  organicGoodOthersBestGoodAtLowerPricesOthersFairlyLight:
    'Orgánica Bueno, los otros mejor BUENO EN bajar los precios, otros bastante ligero.',
  organicGoodOthersBestGoodAtLowerPricesOthersLight:
    'Orgánica Bueno, los otros mejor BUENO EN bajar los precios, otros la luz.',
  organicGoodOthersBestGoodAtLowerPricesOthersModerate:
    'Orgánica Bueno, los otros mejor BUENO EN bajar los precios, otros moderado.',
  organicGoodOthersBestLightOthersVeryLight: 'GOOD orgánico; OTROS mejor luz, otros muy ligero.',
  organicGoodOthersBestModerateOthersFairlyLight: 'GOOD orgánico; Los otros mejor OTROS moderado, bastante ligero.',
  organicGoodOthersBestModerateOthersLight: 'BUENA ORGÁNICA, los otros mejor moderado, OTROS LUZ.',
  organicGoodOthersBestModerateOthersVeryLight: 'GOOD orgánico; Los otros mejor OTROS moderado, muy ligero.',
  organicGoodOthersExceedsSupply: 'Orgánica Bueno, OTROS supera a la oferta.',
  organicGoodOthersExceedSupply: 'Orgánica Bueno, OTROS superior a la oferta.',
  organicGoodOthersFairlyGood: 'Orgánica Bueno, otros bastante buena.',
  organicGoodOthersGoodAtLowerPrices: 'Orgánica Bueno, bien a otros a un menor PRECIOS.',
  organicGoodOthersLight: 'Orgánica Bueno, otros LUZ.',
  organicGoodOthersModerate: 'Orgánica Bueno, otros moderado.',
  organicGoodOthersVeryGood: 'Orgánica Bueno, OTROS MUY BUENO.',
  organicGoodOthersVeryLight: 'ORGÁNICA buenos, otros muy ligero.',
  organicLightConventionalBestModerateOthersLight: 'ORGANIC LUZ; CONVENCIONAL MEJOR moderado, OTROS LUZ.',
  organicLightConventionalFairlyGood: 'Luz orgánica, CONVENCIONAL bastante buena.',
  organicLightConventionalFairlyLight: 'Luz orgánica, CONVENCIONAL bastante ligero.',
  organicLightConventionalModerate: 'Luz orgánica, CONVENCIONAL moderado.',
  organicLightConventionalVeryLight: 'Luz orgánica, CONVENCIONAL muy ligero.',
  organicLightOthersBestGoodAtLowerPricesOthersModerate:
    'ORGANIC LUZ; OTROS MEJOR bueno en bajar los precios, otros moderado.',
  organicLightOthersBestGoodOthersModerate: 'Orgánica ligera, otros MEJOR buenos, otros moderado.',
  organicLightOthersBestModerateOthersFairlyLight: 'ORGANIC LUZ; Los otros mejor OTROS moderado, bastante ligero.',
  organicLightOthersFairlyLight: 'ORGANIC LUZ; OTROS bastante ligero.',
  organicLightOthersGood: 'Luz orgánica, bien a otros.',
  organicLightOthersModerate: 'Orgánica ligera, otros moderado.',
  organicLightOthersVeryGood: 'Orgánica ligera, otros muy bueno.',
  organicLightOthersVeryLight: 'Orgánica ligera, otros muy ligero.',
  organicLowerOthersAboutSteady: 'Orgánica inferior, otros acerca constante.',
  organicModerateConventionalBestGoodOthersModerate: 'ORGANIC moderada, CONVENCIONAL MEJOR buenos, otros moderado.',
  organicModerateConventionalBestModerateOthersLight: 'ORGANIC moderada, CONVENCIONAL MEJOR moderado, OTROS LUZ.',
  organicModerateConventionalFairlyLight: 'ORGÁNICA moderado, CONVENCIONAL bastante ligero.',
  organicModerateConventionalGood: 'ORGÁNICA moderado, CONVENCIONAL BUENO.',
  organicModerateConventionalLight: 'ORGÁNICA moderado, CONVENCIONAL LUZ.',
  organicModerateConventionalSlow: 'ORGÁNICA moderado, CONVENCIONAL LENTO.',
  organicModerateConventionalVeryLight: 'ORGÁNICA moderado, CONVENCIONAL muy ligero.',
  organicModerateOthersBestExceedsSupplyOthersGood:
    'ORGANIC moderada, Los otros mejor supera a la oferta, bien a otros.',
  organicModerateOthersBestFairlyLightOthersLight: 'ORGANIC moderada, OTROS MEJOR bastante ligero, otros la luz.',
  organicModerateOthersBestFairlyLightOthersVeryLight:
    'ORGANIC moderada, OTROS MEJOR bastante ligero, otros muy ligero.',
  organicModerateOthersBestGoodAtLowerPricesOthersFairlyLight:
    'ORGÁNICA moderado, los otros mejor BUENO EN bajar los precios, otros bastante ligero.',
  organicModerateOthersBestGoodAtSlightlyLowerPricesOthersModerate:
    'ORGANIC moderada, OTROS MEJOR bueno en precios ligeramente más bajo y otros moderados.',
  organicModerateOthersBestGoodOthersModerate: 'ORGÁNICA moderado, los otros mejor buenos, otros moderado.',
  organicModerateOthersBestLightOthersVeryLight: 'ORGANIC moderada, OTROS mejor luz, otros muy ligero.',
  organicModerateOthersBestModerateOthersFairlyLightAtHigherPrices:
    'ORGANIC moderada, Los otros mejor OTROS moderado, bastante ligero en mayor precios.',
  organicModerateOthersBestModerateOthersLight: 'ORGÁNICA moderado, los otros mejor moderado, otros encender.',
  organicModerateOthersBestModerateOthersVeryLight: 'ORGANIC moderada, Los otros mejor OTROS moderado, muy ligero.',
  organicModerateOthersExceedSupply: 'ORGÁNICA moderado, OTROS superior a la oferta.',
  organicModerateOthersFairlyGood: 'ORGÁNICA moderado, OTROS bastante buena.',
  organicModerateOthersFairlyLight: 'ORGÁNICA moderado, OTROS bastante ligero.',
  organicModerateOthersGood: 'ORGÁNICA moderado, bien a otros.',
  organicModerateOthersGoodAtLowerPrices: 'ORGÁNICA moderado, bien a otros a un menor PRECIOS.',
  organicModerateOthersGoood: 'ORGÁNICA moderado, OTROS buena conexión.',
  organicModerateOthersLight: 'ORGÁNICA moderado, OTROS LUZ.',
  organicModerateOthersVeryGood: 'ORGÁNICA moderado, OTROS MUY BUENO.',
  organicModerateToGoodOthersVeryGood: 'ORGÁNICA moderado a bueno; OTROS MUY BUENO.',
  organicsVeryLightOthersLight: 'ORGÁNICOS muy ligero, OTROS luz.',
  organicVeryGoodAtSlightlyLowerPricesConventionalVeryGood:
    'ORGÁNICA muy bueno en precios ligeramente más bajos, CONVENCIONAL muy bueno.',
  organicVeryGoodConventionalBestGoodOthersModerate: 'GOOD muy orgánico; CONVENCIONAL MEJOR buenos, otros moderado.',
  organicVeryGoodConventionalBestVeryGoodOthersModerate:
    'GOOD muy orgánico; CONVENCIONAL MEJOR MUY BUENO, otros moderado.',
  organicVeryGoodConventionalFairlyGood: 'BUENA muy orgánico, CONVENCIONAL bastante buena.',
  organicVeryGoodOthersBestFairlyGoodAtSlightlyLowerPricesOthersLight:
    'GOOD muy orgánico; OTROS MEJOR bastante buena a precios ligeramente más bajo y otros luz.',
  organicVeryGoodOthersBestGoodAtLowerPricesOthersModerate:
    'ORGÁNICA muy buenos, otros MEJORES PRECIOS EN BUENA más bajo y otros moderado.',
  organicVeryGoodOthersBestGoodAtSlightlyLowerPricesOthersModerate:
    'GOOD muy orgánico; OTROS MEJOR bueno en precios ligeramente más bajo y otros moderados.',
  organicVeryGoodOthersBestGoodOthersFairlyLight: 'GOOD muy orgánico; OTROS MEJOR buenos, otros bastante ligero.',
  organicVeryGoodOthersBestGoodOthersModerate: 'GOOD muy orgánico; OTROS MEJOR buenos, otros moderado.',
  organicVeryGoodOthersBestLightOthersVeryLight: 'GOOD muy orgánico; OTROS mejor luz, otros muy ligero.',
  organicVeryGoodOthersBestModerateOthersLight: 'GOOD muy orgánico; OTROS MEJOR moderado, otros encender.',
  organicVeryGoodOthersExceedSupply: 'ORGÁNICA muy buenos, otros superior a la oferta.',
  organicVeryGoodOthersGood: 'BUENA muy orgánico, bien a otros.',
  organicVeryGoodOthersGoodAtLowerPrices: 'ORGÁNICA MUY BUENO, BUENO EN OTROS bajar los precios.',
  organicVeryGoodOthersModerate: 'BUENA muy orgánico, otros moderado.',
  organicVeryGoodOthersVeryGoodAtLowerPrices: 'BUENA muy orgánico, otros muy buenos para bajar los precios.',
  organicVeryLight6OunceConventionalDemoralizedOthersBarelySteady:
    'ORGÁNICA muy ligero, de 6 onzas CONVENCIONAL desmoralizado, otros apenas estable.',
  organicVeryLightConventionalLight: 'ORGÁNICA muy ligero, CONVENCIONAL LUZ.',
  organicVeryLightOthersBestGoodOthersModerate: 'ORGÁNICA muy ligero, los otros mejor buenos, otros moderado.',
  organicVeryLightOthersBestModerateOthersLight: 'ORGÁNICA muy ligero, los otros mejor moderado, otros encender.',
  organicVeryLightOthersGood: 'ORGÁNICA muy ligero, bien a otros.',
  organicVeryLightOthersModerate: 'ORGÁNICA muy ligera, moderada OTROS.',
  pintsGoodAtLowerPricesOthersGood: 'PRECIOS PINTS BUENA a un menor, bien a otros.',
  pintsGoodOthersFairlyGood: 'PINTS buenos, otros bastante buena.',
  pintsVeryLightOrganicFairlyLight: 'PINTS muy ligero, ORGÁNICA bastante ligero.',
  pintsVeryLightOthersFairlyLight: 'PINTS muy ligero, OTROS bastante ligero.',
  'small/mediumFruitLightOthersModerate': 'Small / Medium FRUTOS DE LUZ, otros moderado.',
  'small/mediumFruitVeryLightOthersLight': 'Small / Medium fruta muy LUZ, otros la luz.',
  smallSizeLightOthersFairlyGood: 'PEQUEÑO TAMAÑO DE LUZ, otros bastante buena.',
  veryGood: 'MUY BIEN.',
  veryGoodAtLowerPrice: 'PRECIO MUY BUENO a un menor.',
  veryGoodAtLowerPrices: 'Los precios son muy bueno a un menor.',
  veryGoodAtLowerPricesOrganicBestGoodOthersFairlyLight:
    'PRECIOS a un menor muy bueno; ORGÁNICA MEJOR buenos, otros bastante ligero.',
  veryGoodAtSlightlyLowerPrices: 'Muy buenas a precios ligeramente más bajos.',
  veryGoodOrganicBestGoodOthersFairlyLight: 'MUY BIEN; ORGÁNICA MEJOR buenos, otros bastante ligero.',
  veryGoodOrganicBestGoodOthersModerate: 'MUY BIEN; ORGÁNICA MEJOR buenos, otros moderado.',
  veryLight: 'MUY LIGERO.',
};

const demandEN = {
  '11PoundJumboAndSmallLightOthersGood': '11 POUND JUMBO AND SMALL LIGHT, OTHERS GOOD.',
  '11PoundJumboLightOthersFairlyGood': '11 POUND JUMBO LIGHT, OTHERS FAIRLY GOOD.',
  '121-pintsCupsGoodAtLowerPricesOthersGood': '12 1-PINTS CUPS GOOD AT LOWER PRICES, OTHERS GOOD.',
  '126-ounceCupsFairlyGood121-pintsModerate': '12 6-OUNCE CUPS FAIRLY GOOD, 12 1-PINTS MODERATE.',
  '21/2And3DozenFairlyGoodOthesModerate': '2 1/2 AND 3 DOZEN FAIRLY GOOD, OTHES MODERATE.',
  '21/2And3DozenGoodAtSlightlyLowerPricesOthersGood': '2 1/2 AND 3 DOZEN GOOD AT SLIGHTLY LOWER PRICES, OTHERS GOOD.',
  '21/2DozenModerateOthersFairlyGood': '2 1/2 DOZEN MODERATE, OTHERS FAIRLY GOOD.',
  '2-21/2DozenFairlyGoodAtSlightlyLowerPricesOthersFairlyGood':
    '2-2 1/2 DOZEN FAIRLY GOOD AT SLIGHTLY LOWER PRICES, OTHERS FAIRLY GOOD.',
  '2-21/2DozenFairlyGoodAtSlightlyLowerPricesOthersFairlyGoodAtLowerPrices':
    '2-2 1/2 DOZEN FAIRLY GOOD AT SLIGHTLY LOWER PRICES, OTHERS FAIRLY GOOD AT LOWER PRICES.',
  '2-21/2DozenGoodAtSlightlyLowerPrices3DozenGoodHeartsModerate':
    '2-2 1/2 DOZEN GOOD AT SLIGHTLY LOWER PRICES, 3 DOZEN GOOD, HEARTS MODERATE.',
  '2-4DozenFairlyGoodHeartsModerate': '2-4 DOZEN FAIRLY GOOD, HEARTS MODERATE.',
  '28PoundBunchedGoodAtLowerPricesOthersModerate': '28 POUND BUNCHED GOOD AT LOWER PRICES, OTHERS MODERATE.',
  '28PoundBunchedGoodAtSlightlyLowerPricesOthersFairlyGood':
    '28 POUND BUNCHED GOOD AT SLIGHTLY LOWER PRICES, OTHERS FAIRLY GOOD.',
  '28PoundStandardAndLargeFairlyGoodJumboVeryLightOthersLight':
    '28 POUND STANDARD AND LARGE FAIRLY GOOD, JUMBO VERY LIGHT, OTHERS LIGHT.',
  '28PoundStandardAndLargeFairlyGoodOthersLight': '28 POUND STANDARD AND LARGE FAIRLY GOOD, OTHERS LIGHT.',
  '28PoundStandardAndLargeFairlyLightJumboVeryLightOthersLight':
    '28 POUND STANDARD AND LARGE FAIRLY LIGHT, JUMBO VERY LIGHT, OTHERS LIGHT.',
  '28PoundStandardAndLargeFairlyLightOthersVeryLight': '28 POUND STANDARD AND LARGE FAIRLY LIGHT, OTHERS VERY LIGHT.',
  '28PoundStandardAndLargeModerateJumboVeryLightOthersLight':
    '28 POUND STANDARD AND LARGE MODERATE, JUMBO VERY LIGHT, OTHERS LIGHT.',
  '28PoundStandardAndLargeModerateOthersVeryLight': '28 POUND STANDARD AND LARGE MODERATE, OTHERS VERY LIGHT.',
  '28PoundStandardFairlyGoodAtLowerPricesOthersFairlyLight':
    '28 POUND STANDARD FAIRLY GOOD AT LOWER PRICES, OTHERS FAIRLY LIGHT.',
  '28PoundStandardFairlyGoodOthersFairlyLight': '28 POUND STANDARD FAIRLY GOOD, OTHERS FAIRLY LIGHT.',
  '28PoundStandardFairlyGoodOthersModerate': '28 POUND STANDARD FAIRLY GOOD, OTHERS MODERATE.',
  '2And21/2DozenFairlyGoodOthersModerate': '2 AND 2 1/2 DOZEN FAIRLY GOOD, OTHERS MODERATE.',
  '2And21/2DozenFairlyLightOthersModerate': '2 AND 2 1/2 DOZEN FAIRLY LIGHT, OTHERS MODERATE.',
  '2And21/2DozenLightOthersModerate': '2 AND 2 1/2 DOZEN LIGHT, OTHERS MODERATE.',
  '2And21/2DozenModerateOthersFairlyGood': '2 AND 2 1/2 DOZEN MODERATE, OTHERS FAIRLY GOOD.',
  '2And21/2GoodOthersModerate': '2 AND 2 1/2 GOOD, OTHERS MODERATE.',
  '2DozenFairlyGoodAtSlightlyLowerPricesOthersFairlyGood':
    '2 DOZEN FAIRLY GOOD AT SLIGHTLY LOWER PRICES, OTHERS FAIRLY GOOD.',
  '2DozenFairlyGoodAtSlightlyLowerPricesOthersGood': '2 DOZEN FAIRLY GOOD AT SLIGHTLY LOWER PRICES, OTHERS GOOD.',
  '2DozenFairlyLightOthersModerate': '2 DOZEN FAIRLY LIGHT, OTHERS MODERATE.',
  '2DozenGoodAtSlightlyLowerPricesOthersGood': '2 DOZEN GOOD AT SLIGHTLY LOWER PRICES, OTHERS GOOD.',
  '2DozenModerateOthersFairlyGood': '2 DOZEN MODERATE, OTHERS FAIRLY GOOD.',
  '3And4DozenModerateOthersGood': '3 AND 4 DOZEN MODERATE, OTHERS GOOD.',
  '3And4DozenModerateOthersVeryGood': '3 AND 4 DOZEN MODERATE, OTHERS VERY GOOD,.',
  '3DozenAndHeartsModerateOthersLight': '3 DOZEN AND HEARTS MODERATE, OTHERS LIGHT.',
  '3DozenModerateOthersFairlyGood': '3 DOZEN MODERATE, OTHERS FAIRLY GOOD.',
  '3DozenVeryGoodOthersGood': '3 DOZEN VERY GOOD, OTHERS GOOD.',
  '6-ounceCupsConventionalGoodOthersFairlyGood': '6-OUNCE CUPS CONVENTIONAL GOOD, OTHERS FAIRLY GOOD.',
  '6-ounceCupsConventionalGoodOthersFairlyGoodAtLowerPrices':
    '6-OUNCE CUPS CONVENTIONAL GOOD, OTHERS FAIRLY GOOD AT LOWER PRICES.',
  bestExceedsSupplyOthersFairlyGood: 'BEST EXCEEDS SUPPLY, OTHERS FAIRLY GOOD.',
  bestExceedsSupplyOthersGood: 'BEST EXCEEDS SUPPLY, OTHERS GOOD.',
  bestExceedsSupplyOthersModerate: 'BEST EXCEEDS SUPPLY, OTHERS MODERATE.',
  bestExceedsSupplyOthersVeryGood: 'BEST EXCEEDS SUPPLY, OTHERS VERY GOOD.',
  bestFairlyGoodAtLowerPricesOthersFairlyLight: 'BEST FAIRLY GOOD AT LOWER PRICES, OTHERS FAIRLY LIGHT.',
  bestFairlyGoodAtLowerPricesOthersFairlyLightOrganicGood:
    'BEST FAIRLY GOOD AT LOWER PRICES, OTHERS FAIRLY LIGHT; ORGANIC GOOD.',
  bestFairlyGoodAtLowerPricesOthersLight: 'BEST FAIRLY GOOD AT LOWER PRICES, OTHERS LIGHT.',
  bestFairlyGoodAtLowerPricesOthersVeryLight: 'BEST FAIRLY GOOD AT LOWER PRICES, OTHERS VERY LIGHT.',
  bestFairlyGoodOthersFairlyLight: 'BEST FAIRLY GOOD, OTHERS FAIRLY LIGHT.',
  bestFairlyGoodOthersFairlyLightAtHigherPrices: 'BEST FAIRLY GOOD, OTHERS FAIRLY LIGHT AT HIGHER PRICES.',
  bestFairlyGoodOthersFairlyLightOrganicGood: 'BEST FAIRLY GOOD, OTHERS FAIRLY LIGHT; ORGANIC GOOD.',
  bestFairlyGoodOthersLight: 'BEST FAIRLY GOOD, OTHERS LIGHT.',
  bestFairlyGoodOthersLightAtHigherPrices: 'BEST FAIRLY GOOD, OTHERS LIGHT AT HIGHER PRICES.',
  bestFairlyGoodOthersLightButImproving: 'BEST FAIRLY GOOD, OTHERS LIGHT BUT IMPROVING.',
  bestFairlyGoodOthersLightOrganicBestGoodOthersModerate:
    'BEST FAIRLY GOOD, OTHERS LIGHT; ORGANIC BEST GOOD, OTHERS MODERATE.',
  bestFairlyGoodOthersLightOrganicGood: 'BEST FAIRLY GOOD, OTHERS LIGHT; ORGANIC GOOD.',
  bestFairlyGoodOthersLightOrganicModerate: 'BEST FAIRLY GOOD, OTHERS LIGHT; ORGANIC MODERATE.',
  bestFairlyGoodOthersModerate: 'BEST FAIRLY GOOD, OTHERS MODERATE.',
  bestFairlyGoodOthersModerateOrganicModerate: 'BEST FAIRLY GOOD, OTHERS MODERATE; ORGANIC MODERATE.',
  bestFairlyGoodOthersModerateOrganicVeryGood: 'BEST FAIRLY GOOD, OTHERS MODERATE; ORGANIC VERY GOOD.',
  bestFairlyGoodOthersVeryLight: 'BEST FAIRLY GOOD, OTHERS VERY LIGHT.',
  bestFairlyLightOthersLight: 'BEST FAIRLY LIGHT, OTHERS LIGHT.',
  bestFairlyLightOthersLightOrganicBestModerateOthersLight:
    'BEST FAIRLY LIGHT, OTHERS LIGHT; ORGANIC BEST MODERATE, OTHERS LIGHT.',
  bestFairlyLightOthersVeryLight: 'BEST FAIRLY LIGHT, OTHERS VERY LIGHT.',
  bestFairlyLightOthersVeryLightOrganicGood: 'BEST FAIRLY LIGHT, OTHERS VERY LIGHT; ORGANIC GOOD.',
  bestGoodAtLowerPricesOthersFairlyLight: 'BEST GOOD AT LOWER PRICES, OTHERS FAIRLY LIGHT.',
  bestGoodAtLowerPricesOthersLight: 'BEST GOOD AT LOWER PRICES, OTHERS LIGHT.',
  bestGoodAtLowerPricesOthersModerate: 'BEST GOOD AT LOWER PRICES, OTHERS MODERATE.',
  bestGoodAtLowerPricesOthersModerateOrganicGood: 'BEST GOOD AT LOWER PRICES, OTHERS MODERATE; ORGANIC GOOD.',
  bestGoodAtLowerPricesOthersModerateOrganicLight: 'BEST GOOD AT LOWER PRICES, OTHERS MODERATE; ORGANIC LIGHT.',
  bestGoodAtLowerPricesOthersModerateOrganicVeryGood: 'BEST GOOD AT LOWER PRICES, OTHERS MODERATE; ORGANIC VERY GOOD.',
  bestGoodAtLowerPricesOthersVeryLight: 'BEST GOOD AT LOWER PRICES, OTHERS VERY LIGHT.',
  bestGoodAtSlightlyLowerPricesOthersFairlyLight: 'BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS FAIRLY LIGHT.',
  bestGoodAtSlightlyLowerPricesOthersLight: 'BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS LIGHT.',
  bestGoodAtSlightlyLowerPricesOthersModerate: 'BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS MODERATE.',
  bestGoodAtSlightlyLowerPricesOthersModerateOrganicBestModerateOthersVeryLight:
    'BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS MODERATE; ORGANIC BEST MODERATE, OTHERS VERY LIGHT.',
  bestGoodAtSlightlyLowerPricesOthersModerateOrganicVeryGood:
    'BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS MODERATE; ORGANIC VERY GOOD.',
  bestGoodAtSlightlyLowerPricesOthersVeryLight: 'BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS VERY LIGHT.',
  bestGoodOthersFairlyLight: 'BEST GOOD, OTHERS FAIRLY LIGHT.',
  bestGoodOthersFairlyLightAtHigherPrices: 'BEST GOOD, OTHERS FAIRLY LIGHT AT HIGHER PRICES.',
  bestGoodOthersFairlyLightOrganicBestFairlyLightOthersVeryLight:
    'BEST GOOD, OTHERS FAIRLY LIGHT; ORGANIC BEST FAIRLY LIGHT, OTHERS VERY LIGHT.',
  bestGoodOthersFairlyLightOrganicBestModerateOthersLight:
    'BEST GOOD, OTHERS FAIRLY LIGHT; ORGANIC BEST MODERATE, OTHERS LIGHT.',
  bestGoodOthersFairlyLightOrganicBestModerateOthersVeryLight:
    'BEST GOOD, OTHERS FAIRLY LIGHT; ORGANIC BEST MODERATE, OTHERS VERY LIGHT.',
  bestGoodOthersFairlyLightOrganicExceedsSupply: 'BEST GOOD, OTHERS FAIRLY LIGHT; ORGANIC EXCEEDS SUPPLY.',
  bestGoodOthersFairlyLightOrganicModerate: 'BEST GOOD, OTHERS FAIRLY LIGHT; ORGANIC MODERATE.',
  bestGoodOthersLight: 'BEST GOOD, OTHERS LIGHT.',
  bestGoodOthersLightAtHigherPrices: 'BEST GOOD, OTHERS LIGHT AT HIGHER PRICES.',
  bestGoodOthersLightOrganicLight: 'BEST GOOD, OTHERS LIGHT; ORGANIC LIGHT.',
  bestGoodOthersModerate: 'BEST GOOD OTHERS MODERATE.',
  bestGoodOthersModerateOrganicBestFairlyLightOthersVeryLight:
    'BEST GOOD, OTHERS MODERATE; ORGANIC BEST FAIRLY LIGHT, OTHERS VERY LIGHT.',
  bestGoodOthersModerateOrganicBestModerateOthersLight:
    'BEST GOOD, OTHERS MODERATE; ORGANIC BEST MODERATE, OTHERS LIGHT.',
  bestGoodOthersModerateOrganicBestModerateOthersVeryLight:
    'BEST GOOD, OTHERS MODERATE; ORGANIC BEST MODERATE, OTHERS VERY LIGHT.',
  bestGoodOthersModerateOrganicExceedsSupply: 'BEST GOOD, OTHERS MODERATE; ORGANIC EXCEEDS SUPPLY.',
  bestGoodOthersModerateOrganicFairlyLight: 'BEST GOOD, OTHERS MODERATE; ORGANIC FAIRLY LIGHT.',
  bestGoodOthersModerateOrganicFairlyLightAtHigherPrices:
    'BEST GOOD, OTHERS MODERATE; ORGANIC FAIRLY LIGHT AT HIGHER PRICES.',
  bestGoodOthersModerateOrganicLight: 'BEST GOOD, OTHERS MODERATE; ORGANIC LIGHT.',
  bestGoodOthersModerateOrganicModerate: 'BEST GOOD, OTHERS MODERATE; ORGANIC MODERATE.',
  bestGoodOthersModerateOrganicVeryGood: 'BEST GOOD, OTHERS MODERATE; ORGANIC VERY GOOD.',
  bestGoodOthersModerte: 'BEST GOOD, OTHERS MODERTE.',
  bestGoodOthersVeryLight: 'BEST GOOD, OTHERS VERY LIGHT.',
  bestLightOthersPracticallyNoDemand: 'BEST LIGHT, OTHERS PRACTICALLY NO DEMAND.',
  bestLightOthersVeryLight: 'BEST LIGHT, OTHERS VERY LIGHT.',
  bestLightOthersVeryLightOrganicBestFairlyLightOthersVeryLight:
    'BEST LIGHT, OTHERS VERY LIGHT; ORGANIC BEST FAIRLY LIGHT, OTHERS VERY LIGHT.',
  bestLightOthersVeryLightOrganicBestGoodOthersModerate:
    'BEST LIGHT, OTHERS VERY LIGHT; ORGANIC BEST GOOD, OTHERS MODERATE.',
  bestLightOthersVeryLightOrganicBestModerateOthersVeryLight:
    'BEST LIGHT, OTHERS VERY LIGHT; ORGANIC BEST MODERATE, OTHERS VERY LIGHT.',
  bestModerate: 'BEST MODERATE.',
  bestModerateOrganicBestModerateOthersLight: 'BEST MODERATE; ORGANIC BEST MODERATE, OTHERS LIGHT.',
  bestModerateOrganicBestModerateOthersVeryLight: 'BEST MODERATE; ORGANIC BEST MODERATE, OTHERS VERY LIGHT.',
  bestModerateOthersFairlyLight: 'BEST MODERATE, OTHERS FAIRLY LIGHT.',
  bestModerateOthersFairlyLightOrganicBestGoodOthersModerate:
    'BEST MODERATE, OTHERS FAIRLY LIGHT; ORGANIC BEST GOOD, OTHERS MODERATE;.',
  bestModerateOthersLight: 'BEST MODERATE, OTHERS LIGHT.',
  bestModerateOthersLightAtHigherPrices: 'BEST MODERATE, OTHERS LIGHT AT HIGHER PRICES.',
  bestModerateOthersLightAtHigherPricesOrganicModerate:
    'BEST MODERATE, OTHERS LIGHT AT HIGHER PRICES; ORGANIC MODERATE.',
  bestModerateOthersLightAtSlightlyHigherPrices: 'BEST MODERATE, OTHERS LIGHT AT SLIGHTLY HIGHER PRICES.',
  bestModerateOthersLightOrganicBestFairlyGoodOthersLight:
    'BEST MODERATE, OTHERS LIGHT; ORGANIC BEST FAIRLY GOOD, OTHERS LIGHT.',
  bestModerateOthersLightOrganicBestGoodOthersFairlyLight:
    'BEST MODERATE, OTHERS LIGHT; ORGANIC BEST GOOD, OTHERS FAIRLY LIGHT.',
  bestModerateOthersLightOrganicBestGoodOthersLight: 'BEST MODERATE, OTHERS LIGHT; ORGANIC BEST GOOD, OTHERS LIGHT.',
  bestModerateOthersLightOrganicBestGoodOthersModerate:
    'BEST MODERATE, OTHERS LIGHT; ORGANIC BEST GOOD, OTHERS MODERATE.',
  bestModerateOthersLightOrganicExceedsSupply: 'BEST MODERATE, OTHERS LIGHT; ORGANIC EXCEEDS SUPPLY.',
  bestModerateOthersLightOrganicGood: 'BEST MODERATE, OTHERS LIGHT; ORGANIC GOOD.',
  bestModerateOthersLightOrganicModerate: 'BEST MODERATE, OTHERS LIGHT; ORGANIC MODERATE.',
  bestModerateOthersLightOrganicVeryGood: 'BEST MODERATE, OTHERS LIGHT; ORGANIC VERY GOOD.',
  bestModerateOthersVeryLight: 'BEST MODERATE, OTHERS VERY LIGHT.',
  bestModerateOthersVeryLightAtSlightlyHigherPrices: 'BEST MODERATE, OTHERS VERY LIGHT AT SLIGHTLY HIGHER PRICES.',
  bestModerateOthersVeryLightButImproving: 'BEST MODERATE, OTHERS VERY LIGHT BUT IMPROVING.',
  bestModerateOthersVeryLightOrganicGood: 'BEST MODERATE, OTHERS VERY LIGHT; ORGANIC GOOD.',
  bestModertateOthersFairlyLight: 'BEST MODERTATE, OTHERS FAIRLY LIGHT.',
  bestVeryGoodAtSlightlyLowerPricesOthersModerate: 'BEST VERY GOOD AT SLIGHTLY LOWER PRICES, OTHERS MODERATE.',
  bestVeryGoodOthersFairlyGood: 'BEST VERY GOOD, OTHERS FAIRLY GOOD.',
  bestVeryGoodOthersGood: 'BEST VERY GOOD, OTHERS GOOD.',
  bestVeryGoodOthersModerate: 'BEST VERY GOOD, OTHERS MODERATE.',
  conveintionalFairlyLightOrganicLight: 'CONVEINTIONAL FAIRLY LIGHT, ORGANIC LIGHT.',
  'conventional121-pintsAnd6-ounceCupsGoodAtLowerPricesOthersGood':
    'CONVENTIONAL 12 1-PINTS AND 6-OUNCE CUPS GOOD AT LOWER PRICES, OTHERS GOOD.',
  'conventional121-pintsVeryGoodAtSlightlyLowerPricesOthersVeryGood':
    'CONVENTIONAL 12 1-PINTS VERY GOOD AT SLIGHTLY LOWER PRICES, OTHERS VERY GOOD. .',
  conventionalBestFairlyGoodOthersModerateOrganicFairlyLight:
    'CONVENTIONAL BEST FAIRLY GOOD, OTHERS MODERATE; ORGANIC FAIRLY LIGHT.',
  conventionalBestFairlyLightOthersLightOrganicLight: 'CONVENTIONAL BEST FAIRLY LIGHT, OTHERS LIGHT; ORGANIC LIGHT.',
  conventionalBestGoodOthersFairlyLightAtHigherPricesOrganicBestModerateOthersLight:
    'CONVENTIONAL BEST GOOD, OTHERS FAIRLY LIGHT AT HIGHER PRICES; ORGANIC BEST MODERATE, OTHERS LIGHT.',
  conventionalBestGoodOthersModerateOrganicFairlyLight:
    'CONVENTIONAL BEST GOOD, OTHERS MODERATE; ORGANIC FAIRLY LIGHT.',
  conventionalBestModerateOthersFairlyLightOrganicFairlyLight:
    'CONVENTIONAL BEST MODERATE, OTHERS FAIRLY LIGHT; ORGANIC FAIRLY LIGHT.',
  conventionalBestModerateOthersFairlyLightOrganicLight:
    'CONVENTIONAL BEST MODERATE, OTHERS FAIRLY LIGHT; ORGANIC LIGHT.',
  conventionalExceedsOfferingsOrganicVeryGood: 'CONVENTIONAL EXCEEDS OFFERINGS, ORGANIC VERY GOOD.',
  conventionalExceedsSupplyOrganicFairlyGood: 'CONVENTIONAL EXCEEDS SUPPLY, ORGANIC FAIRLY GOOD.',
  conventionalExceedsSupplyOrganicFairlyLight: 'CONVENTIONAL EXCEEDS SUPPLY, ORGANIC FAIRLY LIGHT.',
  conventionalExceedsSupplyOrganicGood: 'CONVENTIONAL EXCEEDS SUPPLY, ORGANIC GOOD.',
  conventionalExceedsSupplyOrganicGoodAtLowerPrices: 'CONVENTIONAL EXCEEDS SUPPLY, ORGANIC GOOD AT LOWER PRICES.',
  conventionalExceedsSupplyOrganicGoodAtSlightlyLowerPrices:
    'CONVENTIONAL EXCEEDS SUPPLY, ORGANIC GOOD AT SLIGHTLY LOWER PRICES.',
  conventionalExceedsSupplyOrganicModerate: 'CONVENTIONAL EXCEEDS SUPPLY, ORGANIC MODERATE.',
  conventionalFairlyGoodAtLowerPricesOrganicVeryGood: 'CONVENTIONAL FAIRLY GOOD AT LOWER PRICES, ORGANIC VERY GOOD.',
  conventionalFairlyGoodAtSlightlyLowerPricesOrganicFairlyGood:
    'CONVENTIONAL FAIRLY GOOD AT SLIGHTLY LOWER PRICES, ORGANIC FAIRLY GOOD.',
  conventionalFairlyGoodAtSlightlyLowerPricesOrganicGood:
    'CONVENTIONAL FAIRLY GOOD AT SLIGHTLY LOWER PRICES, ORGANIC GOOD.',
  conventionalFairlyGoodOrganicFairlyLight: 'CONVENTIONAL FAIRLY GOOD, ORGANIC FAIRLY LIGHT.',
  conventionalFairlyGoodOrganicGood: 'CONVENTIONAL FAIRLY GOOD, ORGANIC GOOD.',
  conventionalFairlyGoodOrganicLight: 'CONVENTIONAL FAIRLY GOOD, ORGANIC LIGHT.',
  conventionalFairlyGoodOrganicModerate: 'CONVENTIONAL FAIRLY GOOD, ORGANIC MODERATE.',
  conventionalFairlyGoodOrganicVeryGood: 'CONVENTIONAL FAIRLY GOOD, ORGANIC VERY GOOD.',
  conventionalFairlyLightOrganicGood: 'CONVENTIONAL FAIRLY LIGHT, ORGANIC GOOD.',
  conventionalFairlyLightOrganicLight: 'CONVENTIONAL FAIRLY LIGHT, ORGANIC LIGHT.',
  conventionalFairlyLightOrganicModerate: 'CONVENTIONAL FAIRLY LIGHT, ORGANIC MODERATE.',
  conventionalFairlyLightOrganicVeryGood: 'CONVENTIONAL FAIRLY LIGHT, ORGANIC VERY GOOD.',
  conventionalFairlyLightOrganicVeryLight: 'CONVENTIONAL FAIRLY LIGHT, ORGANIC VERY LIGHT.',
  conventionalGoodAtLowerPricesOrganicGood: 'CONVENTIONAL GOOD AT LOWER PRICES, ORGANIC GOOD.',
  conventionalGoodAtSlightlyLowerPricesOrganicGood: 'CONVENTIONAL GOOD AT SLIGHTLY LOWER PRICES, ORGANIC GOOD.',
  conventionalGoodOrganicBestGoodAtLowerPricesOthersModerate:
    'CONVENTIONAL GOOD; ORGANIC BEST GOOD AT LOWER PRICES, OTHERS MODERATE.',
  conventionalGoodOrganicBestGoodOthersModerate: 'CONVENTIONAL GOOD; ORGANIC BEST GOOD, OTHERS MODERATE.',
  conventionalGoodOrganicFairlyGood: 'CONVENTIONAL GOOD, ORGANIC FAIRLY GOOD.',
  conventionalGoodOrganicFairlyLight: 'CONVENTIONAL GOOD, ORGANIC FAIRLY LIGHT.',
  conventionalGoodOrganicGoodAtSlightlyLowerPrices: 'CONVENTIONAL GOOD, ORGANIC GOOD AT SLIGHTLY LOWER PRICES.',
  conventionalGoodOrganicLight: 'CONVENTIONAL GOOD, ORGANIC LIGHT.',
  conventionalGoodOrganicModerate: 'CONVENTIONAL GOOD, ORGANIC MODERATE.',
  conventionalGoodOrganicVeryGood: 'CONVENTIONAL GOOD, ORGANIC VERY GOOD.',
  conventionalLightOrganicFairlyLight: 'CONVENTIONAL LIGHT, ORGANIC FAIRLY LIGHT.',
  conventionalLightOrganicGood: 'CONVENTIONAL LIGHT, ORGANIC GOOD.',
  conventionalLightOrganicModerate: 'CONVENTIONAL LIGHT, ORGANIC MODERATE.',
  conventionalLightOrganicVeryLight: 'CONVENTIONAL LIGHT, ORGANIC VERY LIGHT.',
  conventionalModerateForFairlyLightSuppliesOrganicGood:
    'CONVENTIONAL MODERATE FOR FAIRLY LIGHT SUPPLIES, ORGANIC GOOD.',
  conventionalModerateOrganicFairlyGood: 'CONVENTIONAL MODERATE, ORGANIC FAIRLY GOOD.',
  conventionalModerateOrganicFairlyLight: 'CONVENTIONAL MODERATE, ORGANIC FAIRLY LIGHT.',
  conventionalModerateOrganicGood: 'CONVENTIONAL MODERATE, ORGANIC GOOD.',
  conventionalModerateOrganicLight: 'CONVENTIONAL MODERATE, ORGANIC LIGHT.',
  conventionalModerateOrganicVeryGood: 'CONVENTIONAL MODERATE, ORGANIC VERY GOOD.',
  conventionalModerateOrganicVeryLight: 'CONVENTIONAL MODERATE, ORGANIC VERY LIGHT.',
  conventionalPintsSlightlyHigherOthersAboutSteady: 'CONVENTIONAL PINTS SLIGHTLY HIGHER, OTHERS ABOUT STEADY.',
  conventionalVeryGoodAtLowerPricesOrganicGoodAtSlightlyLowerPrices:
    'CONVENTIONAL VERY GOOD AT LOWER PRICES, ORGANIC GOOD AT SLIGHTLY LOWER PRICES.',
  conventionalVeryGoodAtLowerPricesOrganicVeryGood: 'CONVENTIONAL VERY GOOD AT LOWER PRICES, ORGANIC VERY GOOD.',
  'conventionalVeryGoodAtSlightlyLowerPrices OrganicVeryGood':
    'CONVENTIONAL VERY GOOD AT SLIGHTLY LOWER PRICES , ORGANIC VERY GOOD.',
  conventionalVeryGoodAtSlightlyLowerPricesOrganicVeryGood:
    'CONVENTIONAL VERY GOOD AT SLIGHTLY LOWER PRICES, ORGANIC VERY GOOD.',
  conventionalVeryGoodOrganicBestGoodOthersModerate: 'CONVENTIONAL VERY GOOD; ORGANIC BEST GOOD, OTHERS MODERATE.',
  conventionalVeryGoodOrganicFairlyGood: 'CONVENTIONAL VERY GOOD, ORGANIC FAIRLY GOOD.',
  conventionalVeryGoodOrganicGood: 'CONVENTIONAL VERY GOOD, ORGANIC GOOD.',
  conventionalVeryGoodOrganicGoodAtSlightlyLowerPrices:
    'CONVENTIONAL VERY GOOD, ORGANIC GOOD AT SLIGHTLY LOWER PRICES.',
  conventionalVeryGoodOrganicModerate: 'CONVENTIONAL VERY GOOD, ORGANIC MODERATE.',
  conventionalVeryLightOrganicFairlyLight: 'CONVENTIONAL VERY LIGHT, ORGANIC FAIRLY LIGHT.',
  conventionalVeryLightOrganicGood: 'CONVENTIONAL VERY LIGHT, ORGANIC GOOD.',
  conventionalVeryLightOrganicLight: 'CONVENTIONAL VERY LIGHT, ORGANIC LIGHT.',
  conventionalVeryLightorganicLight: 'CONVENTIONAL VERY LIGHT,ORGANIC LIGHT.',
  conventionalVeryLightOrganicModerate: 'CONVENTIONAL VERY LIGHT, ORGANIC MODERATE.',
  conventionFairlyGoodAtSlightlyLowerPricesOrganicFairlyGood:
    'CONVENTION FAIRLY GOOD AT SLIGHTLY LOWER PRICES, ORGANIC FAIRLY GOOD.',
  convetionalBestGoodOthersModerateOrganicFairlyLight: 'CONVETIONAL BEST GOOD, OTHERS MODERATE; ORGANIC FAIRLY LIGHT.',
  convetionalGoodOrganicFairlyLight: 'CONVETIONAL GOOD, ORGANIC FAIRLY LIGHT.',
  demandConventionalExceedsSupplyOrganicGood: 'DEMAND CONVENTIONAL EXCEEDS SUPPLY, ORGANIC GOOD.',
  demandConventionalGoodOrganicModerate: 'DEMAND CONVENTIONAL GOOD, ORGANIC MODERATE.',
  demandConventionalVeryGoodOrganicGood: 'DEMAND CONVENTIONAL VERY GOOD, ORGANIC GOOD.',
  demandConventionalVeryGoodOrganicModerate: 'DEMAND CONVENTIONAL VERY GOOD, ORGANIC MODERATE.',
  demandGood: 'DEMAND GOOD.',
  exceedsAvailableSupplies: 'EXCEEDS AVAILABLE SUPPLIES.',
  exceedsOfferings: 'EXCEEDS OFFERINGS.',
  exceedsSupplies: 'EXCEEDS SUPPLIES.',
  exceedsSupply: 'EXCEEDS SUPPLY.',
  failryLight: 'FAILRY LIGHT.',
  fairlyGood: 'FAIRLY GOOD.',
  fairlyGoodAtLowerPrices: 'FAIRLY GOOD AT LOWER PRICES.',
  fairlyGoodAtMuchLowerPrices: 'FAIRLY GOOD AT MUCH LOWER PRICES.',
  fairlyGoodAtSlightlyLowerPrices: 'FAIRLY GOOD AT SLIGHTLY LOWER PRICES.',
  fairlyGoodFor28PoundAtLowerPricesOthersModerate: 'FAIRLY GOOD FOR 28 POUND AT LOWER PRICES, OTHERS MODERATE.',
  fairlyLight: 'FAIRLY LIGHT.',
  fairlyLightAndInFewHands: 'FAIRLY LIGHT AND IN FEW HANDS.',
  fairlyLightAtHigherPrices: 'FAIRLY LIGHT AT HIGHER PRICES.',
  fairlyLightAtSlightlyHigherPrices: 'FAIRLY LIGHT AT SLIGHTLY HIGHER PRICES.',
  fairlyLightatSlightlyHigherPrices: 'FAIRLY LIGHTAT SLIGHTLY HIGHER PRICES.',
  fairlyLightForIncreasingSupplies: 'FAIRLY LIGHT FOR INCREASING SUPPLIES.',
  fairyGood: 'FAIRY GOOD.',
  'flats121-pintBasketsGoodOthersBestGoodAtSlightlyLowerPricesOthersModerate':
    'FLATS 12 1-PINT BASKETS GOOD, OTHERS BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS MODERATE.',
  'flats126-ounceCupsWithLidsVeryGoodAtLowerPricesOthersVeryGood':
    'FLATS 12 6-OUNCE CUPS WITH LIDS VERY GOOD AT LOWER PRICES, OTHERS VERY GOOD.',
  'flats81-lbContainersWithLidsGoodAtSlightlyLowerPricesOthersBestGoodOthersModerate':
    'FLATS 8 1-LB CONTAINERS WITH LIDS GOOD AT SLIGHTLY LOWER PRICES, OTHERS BEST GOOD, OTHERS MODERATE.',
  'flats81-poundContainersFairlyGoodAtLowerPricesOthersBestGoodOthersModerate':
    'FLATS 8 1-POUND CONTAINERS FAIRLY GOOD AT LOWER PRICES, OTHERS BEST GOOD, OTHERS MODERATE.',
  'flats81-poundContainersGoodAtLowerPricesOthersGood': 'FLATS 8 1-POUND CONTAINERS GOOD AT LOWER PRICES, OTHERS GOOD.',
  'flats81-poundContainersGoodAtSlightlyLowerPricesOthersGood':
    'FLATS 8 1-POUND CONTAINERS GOOD AT SLIGHTLY LOWER PRICES, OTHERS GOOD.',
  'flts121-pintCupsFairlyGoodAtLowerPrices126-ounceCupsFairlyGood':
    'FLTS 12 1-PINT CUPS FAIRLY GOOD AT LOWER PRICES, 12 6-OUNCE CUPS FAIRLY GOOD.',
  good: 'GOOD.',
  goodAtLowerPrices: 'GOOD AT LOWER PRICES.',
  goodAtSlightlyLowerPrices: 'GOOD AT SLIGHTLY LOWER PRICES.',
  goodAtSlightlyLowerPricesOnBunched28PoundAboutSteadyOn11Pound:
    'GOOD AT SLIGHTLY LOWER PRICES ON BUNCHED 28 POUND, ABOUT STEADY ON 11 POUND.',
  goodAtSlightlyPrices: 'GOOD AT SLIGHTLY PRICES.',
  goodExceptConventionalPintsGoodAtSlightlyLowerPrices: 'GOOD EXCEPT CONVENTIONAL PINTS GOOD AT SLIGHTLY LOWER PRICES.',
  goodExeptConventional6OzGoodAtSlightlyLowerPrices: 'GOOD, EXEPT CONVENTIONAL 6 OZ GOOD AT SLIGHTLY LOWER PRICES.',
  goodForLightSupplies: 'GOOD FOR LIGHT SUPPLIES.',
  goodOrganicBestModerateOthersLight: 'GOOD; ORGANIC BEST MODERATE, OTHERS LIGHT.',
  'heartsAnd21/2DozenGoodAtSlightlyLowerPrices3DozenGoodAtLowerPricesOthersGood':
    'HEARTS AND 2 1/2 DOZEN GOOD AT SLIGHTLY LOWER PRICES, 3 DOZEN GOOD AT LOWER PRICES, OTHERS GOOD.',
  heartsFairlyGoodAtLowerPricesOthersModerate: 'HEARTS FAIRLY GOOD AT LOWER PRICES, OTHERS MODERATE.',
  heartsFairlyGoodAtSlightlyLowerPricesOthersFairlyGood:
    'HEARTS FAIRLY GOOD AT SLIGHTLY LOWER PRICES, OTHERS FAIRLY GOOD.',
  heartsFairlyGoodOthersFairlyLight: 'HEARTS FAIRLY GOOD, OTHERS FAIRLY LIGHT.',
  heartsFairlyGoodOthersGood: 'HEARTS FAIRLY GOOD, OTHERS GOOD.',
  heartsFairlyGoodOthersModerate: 'HEARTS FAIRLY GOOD, OTHERS MODERATE.',
  heartsFairlyGoodOthersVeryGood: 'HEARTS FAIRLY GOOD, OTHERS VERY GOOD.',
  heartsFairlyLightOthersLight: 'HEARTS FAIRLY LIGHT, OTHERS LIGHT.',
  heartsGoodAtSlightlyLowerPricesOthersAboutSteady: 'HEARTS GOOD AT SLIGHTLY LOWER PRICES, OTHERS ABOUT STEADY.',
  heartsGoodOthersFairlyGood: 'HEARTS GOOD, OTHERS FAIRLY GOOD.',
  heartsGoodOthersFairlyLight: 'HEARTS GOOD, OTHERS FAIRLY LIGHT.',
  heartsGoodOthersGoodAtSlightlyLowerPrices: 'HEARTS GOOD, OTHERS GOOD AT SLIGHTLY LOWER PRICES.',
  heartsGoodOthersLight: 'HEARTS GOOD, OTHERS LIGHT.',
  heartsGoodOthersModerate: 'HEARTS GOOD, OTHERS MODERATE.',
  heartsGoodOthersVeryGood: 'HEARTS GOOD, OTHERS VERY GOOD.',
  heartsModerate2DozenLightOthersFairlyLight: 'HEARTS MODERATE, 2 DOZEN LIGHT, OTHERS FAIRLY LIGHT.',
  heartsModerateOthersFairlyLight: 'HEARTS MODERATE, OTHERS FAIRLY LIGHT.',
  heartsModerateOthersLight: 'HEARTS MODERATE, OTHERS LIGHT.',
  heartsVeryLightOthersLight: 'HEARTS VERY LIGHT, OTHERS LIGHT.',
  jumboAndLargeSizeModerateOthersVeryLight: 'JUMBO AND LARGE SIZE MODERATE, OTHERS VERY LIGHT.',
  jumboLightOthersFairlyGood: 'JUMBO LIGHT, OTHERS FAIRLY GOOD.',
  jumboLightOthersModerate: 'JUMBO LIGHT, OTHERS MODERATE.',
  jumboVeryLightOthersLight: 'JUMBO VERY LIGHT, OTHERS LIGHT.',
  largeAndJumboFairlyGoodOthersFairlyLight: 'LARGE AND JUMBO FAIRLY GOOD, OTHERS FAIRLY LIGHT.',
  largeAndJumboModerateStandardFairlyLight: 'LARGE AND JUMBO MODERATE, STANDARD FAIRLY LIGHT.',
  largeGoodOthersFairlyGood: 'LARGE GOOD, OTHERS FAIRLY GOOD.',
  largeModerateOthersFairlyLight: 'LARGE MODERATE, OTHERS FAIRLY LIGHT.',
  light: 'LIGHT.',
  lightAtHigherPrices: 'LIGHT AT HIGHER PRICES.',
  lightAtSlightlyHigherPrices: 'LIGHT AT SLIGHTLY HIGHER PRICES.',
  lightDemandAtSlightlyHigherPrices: 'LIGHT DEMAND AT SLIGHTLY HIGHER PRICES.',
  lower: 'LOWER.',
  moderate: 'MODERATE.',
  moderateOrganicBestFairlyGoodOthersLight: 'MODERATE; ORGANIC BEST FAIRLY GOOD, OTHERS LIGHT.',
  moderateOrganicGood: 'MODERATE, ORGANIC GOOD.',
  moderateOrganicVeryGood: 'MODERATE; ORGANIC VERY GOOD.',
  moderste: 'MODERSTE.',
  modertate: 'MODERTATE.',
  organic6OzGoodOthersGoodAtSlightlyLowerPrices: 'ORGANIC 6 OZ GOOD, OTHERS GOOD AT SLIGHTLY LOWER PRICES.',
  'organicAndMedium-largeExceedSupplyOthersVeryGoodAtSlightlyLowerPrices':
    'ORGANIC AND MEDIUM-LARGE EXCEED SUPPLY, OTHERS VERY GOOD AT SLIGHTLY LOWER PRICES.',
  organicBestFairlyLightOthersVeryLightOthersGood: 'ORGANIC BEST FAIRLY LIGHT, OTHERS VERY LIGHT; OTHERS GOOD.',
  organicBestFairlyLightOthersVeryLightOthersModerate: 'ORGANIC BEST FAIRLY LIGHT, OTHERS VERY LIGHT; OTHERS MODERATE.',
  organicBestGoodAtLowerPricesOthersModerateOthersBestModerateOthersLight:
    'ORGANIC BEST GOOD AT LOWER PRICES, OTHERS MODERATE; OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicBestGoodAtLowerPricesOthersModerateOthersExceedSupply:
    'ORGANIC BEST GOOD AT LOWER PRICES, OTHERS MODERATE; OTHERS EXCEED SUPPLY.',
  organicBestGoodOthersFairlyLightOthersVeryGood: 'ORGANIC BEST GOOD, OTHERS FAIRLY LIGHT; OTHERS VERY GOOD.',
  organicBestGoodOthersModerateConventionalBestModerateOthersVeryLight:
    'ORGANIC BEST GOOD, OTHERS MODERATE; CONVENTIONAL BEST MODERATE, OTHERS VERY LIGHT.',
  organicBestGoodOthersModerateOthersBestModerateOthersLight:
    'ORGANIC BEST GOOD, OTHERS MODERATE; OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicBestGoodOthersModerateOthersExceedSupply: 'ORGANIC BEST GOOD, OTHERS MODERATE, OTHERS EXCEED SUPPLY.',
  organicBestModerateOthersLightConventionalBestGoodOthersModerate:
    'ORGANIC BEST MODERATE, OTHERS LIGHT; CONVENTIONAL BEST GOOD, OTHERS MODERATE.',
  organicBestModerateOthersLightConventionalBestModerateOthersFairlyLight:
    'ORGANIC BEST MODERATE, OTHERS LIGHT; CONVENTIONAL BEST MODERATE, OTHERS FAIRLY LIGHT.',
  organicBestModerateOthersLightOthersBestFairlyGoodOthersLight:
    'ORGANIC BEST MODERATE, OTHERS LIGHT; OTHERS BEST FAIRLY GOOD, OTHERS LIGHT.',
  organicBestModerateOthersLightOthersBestGoodOthersLight:
    'ORGANIC BEST MODERATE, OTHERS LIGHT; OTHERS BEST GOOD, OTHERS LIGHT.',
  organicBestModerateOthersLightOthersBestGoodOthersModerate:
    'ORGANIC BEST MODERATE, OTHERS LIGHT; OTHERS BEST GOOD, OTHERS MODERATE.',
  organicBestModerateOthersLightOthersGood: 'ORGANIC BEST MODERATE, OTHERS LIGHT; OTHERS GOOD.',
  organicBestModerateOthersVeryLightOthersBestGoodOthersVeryLight:
    'ORGANIC BEST MODERATE, OTHERS VERY LIGHT; OTHERS BEST GOOD, OTHERS VERY LIGHT.',
  organicBestModerateOthersVeryLightOthersBestModerateOthersLight:
    'ORGANIC BEST MODERATE, OTHERS VERY LIGHT; OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicBestModerateOthersVeryLightOthersBestModerateOthersLightAtHigherPrices:
    'ORGANIC BEST MODERATE, OTHERS VERY LIGHT; OTHERS BEST MODERATE, OTHERS LIGHT AT HIGHER PRICES.',
  organicBestModerateOthersVeryLightOthersModerate: 'ORGANIC BEST MODERATE, OTHERS VERY LIGHT; OTHERS MODERATE.',
  organicBestVeryGoodOthersModerateOthersExceedSupply: 'ORGANIC BEST VERY GOOD, OTHERS MODERATE; OTHERS EXCEED SUPPLY.',
  organicExceedsSupply: 'ORGANIC EXCEEDS SUPPLY.',
  organicExceedsSupplyOthersBestGoodAtLowerPricesOthersModerate:
    'ORGANIC EXCEEDS SUPPLY, OTHERS BEST GOOD AT LOWER PRICES, OTHERS MODERATE.',
  organicExceedsSupplyOthersBestGoodOthersModerate: 'ORGANIC EXCEEDS SUPPLY, OTHERS BEST GOOD, OTHERS MODERATE.',
  organicExceedsSupplyOthersGood: 'ORGANIC EXCEEDS SUPPLY, OTHERS GOOD.',
  organicExceedsSupplyOthersVeryGood: 'ORGANIC EXCEEDS SUPPLY, OTHERS VERY GOOD.',
  'organicExceedSupply121-pintBasketsGoodOthersBestGoodAtLowerPricesOthersModerate':
    'ORGANIC EXCEED SUPPLY, 12 1-PINT BASKETS GOOD, OTHERS BEST GOOD AT LOWER PRICES, OTHERS MODERATE.',
  organicExceedSupplyOthersBestGoodOthersModerate: 'ORGANIC EXCEED SUPPLY, OTHERS BEST GOOD, OTHERS MODERATE.',
  organicExceedSupplyOthersGood: 'ORGANIC EXCEED SUPPLY, OTHERS GOOD.',
  organicExceedSupplyOthersVeryGood: 'ORGANIC EXCEED SUPPLY, OTHERS VERY GOOD.',
  organicExceedSupplyOthersVeryGoodAtSlightlyLowerPrices:
    'ORGANIC EXCEED SUPPLY, OTHERS VERY GOOD AT SLIGHTLY LOWER PRICES.',
  organicFairlyGoodAtLowerPricesOthersBestFairlyGoodOthersModerate:
    'ORGANIC FAIRLY GOOD AT LOWER PRICES, OTHERS BEST FAIRLY GOOD, OTHERS MODERATE.',
  organicFairlyGoodAtSlightlyLowerPricesOthersFairlyGood:
    'ORGANIC FAIRLY GOOD AT SLIGHTLY LOWER PRICES, OTHERS FAIRLY GOOD.',
  organicFairlyGoodConventionalBestModerateOthersLight:
    'ORGANIC FAIRLY GOOD, CONVENTIONAL BEST MODERATE, OTHERS LIGHT.',
  organicFairlyGoodConventionalBestModerateOthersVeryLight:
    'ORGANIC FAIRLY GOOD, CONVENTIONAL BEST MODERATE, OTHERS VERY LIGHT.',
  organicFairlyGoodConventionalFairlyLight: 'ORGANIC FAIRLY GOOD, CONVENTIONAL FAIRLY LIGHT.',
  organicFairlyGoodConventionalModerate: 'ORGANIC FAIRLY GOOD, CONVENTIONAL MODERATE.',
  organicFairlyGoodOthersBestFairlyGoodOthersLight: 'ORGANIC FAIRLY GOOD, OTHERS BEST FAIRLY GOOD, OTHERS LIGHT.',
  organicFairlyGoodOthersBestGoodAtLowerPricesOthersSlow:
    'ORGANIC FAIRLY GOOD, OTHERS BEST GOOD AT LOWER PRICES, OTHERS SLOW.',
  organicFairlyGoodOthersBestModerateOthersLight: 'ORGANIC FAIRLY GOOD, OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicFairlyGoodOthersFairlyLight: 'ORGANIC FAIRLY GOOD, OTHERS FAIRLY LIGHT.',
  organicFairlyGoodOthersLight: 'ORGANIC FAIRLY GOOD, OTHERS LIGHT.',
  organicFairlyGoodOthersModerate: 'ORGANIC FAIRLY GOOD, OTHERS MODERATE.',
  organicFairlyGoodOthersVeryLight: 'ORGANIC FAIRLY GOOD, OTHERS VERY LIGHT.',
  organicFairlyLightConventionalBestModerateOthersLight:
    'ORGANIC FAIRLY LIGHT; CONVENTIONAL BEST MODERATE, OTHERS LIGHT.',
  organicFairlyLightConventionalLight: 'ORGANIC FAIRLY LIGHT, CONVENTIONAL LIGHT.',
  organicFairlyLightConventionalVeryLight: 'ORGANIC FAIRLY LIGHT, CONVENTIONAL VERY LIGHT.',
  organicFairlyLightOthersModerate: 'ORGANIC FAIRLY LIGHT, OTHERS MODERATE.',
  organicFairlyLightToGoodOthersVeryGood: 'ORGANIC FAIRLY LIGHT TO GOOD; OTHERS VERY GOOD.',
  organicFairlySlowOthersFairlyGood: 'ORGANIC FAIRLY SLOW, OTHERS FAIRLY GOOD.',
  organicGoodAtLowerPricesConventionalFairlyGood: 'ORGANIC GOOD AT LOWER PRICES, CONVENTIONAL FAIRLY GOOD.',
  organicGoodAtLowerPricesOthersBestGoodOthersModerate:
    'ORGANIC GOOD AT LOWER PRICES, OTHERS BEST GOOD, OTHERS MODERATE.',
  organicGoodAtLowerPricesOthersBestModerateOthersLight:
    'ORGANIC GOOD AT LOWER PRICES; OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicGoodAtLowerPricesOthersExceedsSupply: 'ORGANIC GOOD AT LOWER PRICES, OTHERS EXCEEDS SUPPLY.',
  organicGoodAtLowerPricesOthersGood: 'ORGANIC GOOD AT LOWER PRICES, OTHERS GOOD.',
  organicGoodAtLowerPricesOthersModerate: 'ORGANIC GOOD AT LOWER PRICES; OTHERS MODERATE.',
  organicGoodAtLowerPricesOthersVeryGod: 'ORGANIC GOOD AT LOWER PRICES, OTHERS VERY GOD.',
  organicGoodAtLowerPricesOthersVeryGood: 'ORGANIC GOOD AT LOWER PRICES, OTHERS VERY GOOD.',
  organicGoodAtSlightlyLowerPricesOthersGood: 'ORGANIC GOOD AT SLIGHTLY LOWER PRICES, OTHERS GOOD.',
  organicGoodConventionalBestGoodOthersModerate: 'ORGANIC GOOD; CONVENTIONAL BEST GOOD, OTHERS MODERATE.',
  organicGoodConventionalBestModerateOthersLight: 'ORGANIC GOOD; CONVENTIONAL BEST MODERATE, OTHERS LIGHT.',
  organicGoodConventionalFairlyGood: 'ORGANIC GOOD, CONVENTIONAL FAIRLY GOOD.',
  organicGoodConventionalFairlyLight: 'ORGANIC GOOD, CONVENTIONAL FAIRLY LIGHT.',
  organicGoodConventionalGoodAtSlightlyLowerPrices: 'ORGANIC GOOD, CONVENTIONAL GOOD AT SLIGHTLY LOWER PRICES.',
  organicGoodConventionalLight: 'ORGANIC GOOD, CONVENTIONAL LIGHT.',
  organicGoodConventionalModerate: 'ORGANIC GOOD, CONVENTIONAL MODERATE.',
  organicGoodConventionalVeryLight: 'ORGANIC GOOD, CONVENTIONAL VERY LIGHT.',
  organicGoodOthersBestFairlyLightOthersVeryLight: 'ORGANIC GOOD, OTHERS BEST FAIRLY LIGHT, OTHERS VERY LIGHT.',
  organicGoodOthersBestGoodAtLowerPrices: 'ORGANIC GOOD, OTHERS BEST GOOD AT LOWER PRICES.',
  organicGoodOthersBestGoodAtLowerPricesOthersFairlyLight:
    'ORGANIC GOOD, OTHERS BEST GOOD AT LOWER PRICES, OTHERS FAIRLY LIGHT.',
  organicGoodOthersBestGoodAtLowerPricesOthersLight: 'ORGANIC GOOD, OTHERS BEST GOOD AT LOWER PRICES, OTHERS LIGHT.',
  organicGoodOthersBestGoodAtLowerPricesOthersModerate:
    'ORGANIC GOOD, OTHERS BEST GOOD AT LOWER PRICES, OTHERS MODERATE.',
  organicGoodOthersBestLightOthersVeryLight: 'ORGANIC GOOD; OTHERS BEST LIGHT, OTHERS VERY LIGHT.',
  organicGoodOthersBestModerateOthersFairlyLight: 'ORGANIC GOOD; OTHERS BEST MODERATE, OTHERS FAIRLY LIGHT.',
  organicGoodOthersBestModerateOthersLight: 'ORGANIC GOOD, OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicGoodOthersBestModerateOthersVeryLight: 'ORGANIC GOOD; OTHERS BEST MODERATE, OTHERS VERY LIGHT.',
  organicGoodOthersExceedsSupply: 'ORGANIC GOOD, OTHERS EXCEEDS SUPPLY.',
  organicGoodOthersExceedSupply: 'ORGANIC GOOD, OTHERS EXCEED SUPPLY.',
  organicGoodOthersFairlyGood: 'ORGANIC GOOD, OTHERS FAIRLY GOOD.',
  organicGoodOthersGoodAtLowerPrices: 'ORGANIC GOOD, OTHERS GOOD AT LOWER PRICES.',
  organicGoodOthersLight: 'ORGANIC GOOD, OTHERS LIGHT.',
  organicGoodOthersModerate: 'ORGANIC GOOD, OTHERS MODERATE.',
  organicGoodOthersVeryGood: 'ORGANIC GOOD, OTHERS VERY GOOD.',
  organicGoodOthersVeryLight: 'ORGANIC GOOD, OTHERS VERY LIGHT.',
  organicLightConventionalBestModerateOthersLight: 'ORGANIC LIGHT; CONVENTIONAL BEST MODERATE, OTHERS LIGHT.',
  organicLightConventionalFairlyGood: 'ORGANIC LIGHT, CONVENTIONAL FAIRLY GOOD.',
  organicLightConventionalFairlyLight: 'ORGANIC LIGHT, CONVENTIONAL FAIRLY LIGHT.',
  organicLightConventionalModerate: 'ORGANIC LIGHT, CONVENTIONAL MODERATE.',
  organicLightConventionalVeryLight: 'ORGANIC LIGHT, CONVENTIONAL VERY LIGHT.',
  organicLightOthersBestGoodAtLowerPricesOthersModerate:
    'ORGANIC LIGHT; OTHERS BEST GOOD AT LOWER PRICES, OTHERS MODERATE.',
  organicLightOthersBestGoodOthersModerate: 'ORGANIC LIGHT, OTHERS BEST GOOD, OTHERS MODERATE.',
  organicLightOthersBestModerateOthersFairlyLight: 'ORGANIC LIGHT; OTHERS BEST MODERATE, OTHERS FAIRLY LIGHT.',
  organicLightOthersFairlyLight: 'ORGANIC LIGHT; OTHERS FAIRLY LIGHT.',
  organicLightOthersGood: 'ORGANIC LIGHT, OTHERS GOOD.',
  organicLightOthersModerate: 'ORGANIC LIGHT, OTHERS MODERATE.',
  organicLightOthersVeryGood: 'ORGANIC LIGHT, OTHERS VERY GOOD.',
  organicLightOthersVeryLight: 'ORGANIC LIGHT, OTHERS VERY LIGHT.',
  organicLowerOthersAboutSteady: 'ORGANIC LOWER, OTHERS ABOUT STEADY.',
  organicModerateConventionalBestGoodOthersModerate: 'ORGANIC MODERATE; CONVENTIONAL BEST GOOD, OTHERS MODERATE.',
  organicModerateConventionalBestModerateOthersLight: 'ORGANIC MODERATE; CONVENTIONAL BEST MODERATE, OTHERS LIGHT.',
  organicModerateConventionalFairlyLight: 'ORGANIC MODERATE, CONVENTIONAL FAIRLY LIGHT.',
  organicModerateConventionalGood: 'ORGANIC MODERATE, CONVENTIONAL GOOD.',
  organicModerateConventionalLight: 'ORGANIC MODERATE, CONVENTIONAL LIGHT.',
  organicModerateConventionalSlow: 'ORGANIC MODERATE, CONVENTIONAL SLOW.',
  organicModerateConventionalVeryLight: 'ORGANIC MODERATE, CONVENTIONAL VERY LIGHT.',
  organicModerateOthersBestExceedsSupplyOthersGood: 'ORGANIC MODERATE; OTHERS BEST EXCEEDS SUPPLY, OTHERS GOOD.',
  organicModerateOthersBestFairlyLightOthersLight: 'ORGANIC MODERATE; OTHERS BEST FAIRLY LIGHT, OTHERS LIGHT.',
  organicModerateOthersBestFairlyLightOthersVeryLight: 'ORGANIC MODERATE; OTHERS BEST FAIRLY LIGHT, OTHERS VERY LIGHT.',
  organicModerateOthersBestGoodAtLowerPricesOthersFairlyLight:
    'ORGANIC MODERATE, OTHERS BEST GOOD AT LOWER PRICES, OTHERS FAIRLY LIGHT.',
  organicModerateOthersBestGoodAtSlightlyLowerPricesOthersModerate:
    'ORGANIC MODERATE; OTHERS BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS MODERATE.',
  organicModerateOthersBestGoodOthersModerate: 'ORGANIC MODERATE, OTHERS BEST GOOD, OTHERS MODERATE.',
  organicModerateOthersBestLightOthersVeryLight: 'ORGANIC MODERATE; OTHERS BEST LIGHT, OTHERS VERY LIGHT.',
  organicModerateOthersBestModerateOthersFairlyLightAtHigherPrices:
    'ORGANIC MODERATE; OTHERS BEST MODERATE, OTHERS FAIRLY LIGHT AT HIGHER PRICES.',
  organicModerateOthersBestModerateOthersLight: 'ORGANIC MODERATE, OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicModerateOthersBestModerateOthersVeryLight: 'ORGANIC MODERATE; OTHERS BEST MODERATE, OTHERS VERY LIGHT.',
  organicModerateOthersExceedSupply: 'ORGANIC MODERATE, OTHERS EXCEED SUPPLY.',
  organicModerateOthersFairlyGood: 'ORGANIC MODERATE, OTHERS FAIRLY GOOD.',
  organicModerateOthersFairlyLight: 'ORGANIC MODERATE, OTHERS FAIRLY LIGHT.',
  organicModerateOthersGood: 'ORGANIC MODERATE, OTHERS GOOD.',
  organicModerateOthersGoodAtLowerPrices: 'ORGANIC MODERATE, OTHERS GOOD AT LOWER PRICES.',
  organicModerateOthersGoood: 'ORGANIC MODERATE, OTHERS GOOOD.',
  organicModerateOthersLight: 'ORGANIC MODERATE, OTHERS LIGHT.',
  organicModerateOthersVeryGood: 'ORGANIC MODERATE, OTHERS VERY GOOD.',
  organicModerateToGoodOthersVeryGood: 'ORGANIC MODERATE TO GOOD; OTHERS VERY GOOD.',
  organicsVeryLightOthersLight: 'ORGANICS VERY LIGHT, OTHERS LIGHT.',
  organicVeryGoodAtSlightlyLowerPricesConventionalVeryGood:
    'ORGANIC VERY GOOD AT SLIGHTLY LOWER PRICES, CONVENTIONAL VERY GOOD.',
  organicVeryGoodConventionalBestGoodOthersModerate: 'ORGANIC VERY GOOD; CONVENTIONAL BEST GOOD, OTHERS MODERATE.',
  organicVeryGoodConventionalBestVeryGoodOthersModerate:
    'ORGANIC VERY GOOD; CONVENTIONAL BEST VERY GOOD, OTHERS MODERATE.',
  organicVeryGoodConventionalFairlyGood: 'ORGANIC VERY GOOD, CONVENTIONAL FAIRLY GOOD.',
  organicVeryGoodOthersBestFairlyGoodAtSlightlyLowerPricesOthersLight:
    'ORGANIC VERY GOOD; OTHERS BEST FAIRLY GOOD AT SLIGHTLY LOWER PRICES, OTHERS LIGHT.',
  organicVeryGoodOthersBestGoodAtLowerPricesOthersModerate:
    'ORGANIC VERY GOOD, OTHERS BEST GOOD AT LOWER PRICES, OTHERS MODERATE.',
  organicVeryGoodOthersBestGoodAtSlightlyLowerPricesOthersModerate:
    'ORGANIC VERY GOOD; OTHERS BEST GOOD AT SLIGHTLY LOWER PRICES, OTHERS MODERATE.',
  organicVeryGoodOthersBestGoodOthersFairlyLight: 'ORGANIC VERY GOOD; OTHERS BEST GOOD, OTHERS FAIRLY LIGHT.',
  organicVeryGoodOthersBestGoodOthersModerate: 'ORGANIC VERY GOOD; OTHERS BEST GOOD, OTHERS MODERATE.',
  organicVeryGoodOthersBestLightOthersVeryLight: 'ORGANIC VERY GOOD; OTHERS BEST LIGHT, OTHERS VERY LIGHT.',
  organicVeryGoodOthersBestModerateOthersLight: 'ORGANIC VERY GOOD; OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicVeryGoodOthersExceedSupply: 'ORGANIC VERY GOOD, OTHERS EXCEED SUPPLY.',
  organicVeryGoodOthersGood: 'ORGANIC VERY GOOD, OTHERS GOOD.',
  organicVeryGoodOthersGoodAtLowerPrices: 'ORGANIC VERY GOOD, OTHERS GOOD AT LOWER PRICES.',
  organicVeryGoodOthersModerate: 'ORGANIC VERY GOOD, OTHERS MODERATE.',
  organicVeryGoodOthersVeryGoodAtLowerPrices: 'ORGANIC VERY GOOD, OTHERS VERY GOOD AT LOWER PRICES.',
  organicVeryLight6OunceConventionalDemoralizedOthersBarelySteady:
    'ORGANIC VERY LIGHT, 6 OUNCE CONVENTIONAL DEMORALIZED, OTHERS BARELY STEADY.',
  organicVeryLightConventionalLight: 'ORGANIC VERY LIGHT, CONVENTIONAL LIGHT.',
  organicVeryLightOthersBestGoodOthersModerate: 'ORGANIC VERY LIGHT, OTHERS BEST GOOD, OTHERS MODERATE.',
  organicVeryLightOthersBestModerateOthersLight: 'ORGANIC VERY LIGHT, OTHERS BEST MODERATE, OTHERS LIGHT.',
  organicVeryLightOthersGood: 'ORGANIC VERY LIGHT, OTHERS GOOD.',
  organicVeryLightOthersModerate: 'ORGANIC VERY LIGHT, OTHERS MODERATE.',
  pintsGoodAtLowerPricesOthersGood: 'PINTS GOOD AT LOWER PRICES, OTHERS GOOD.',
  pintsGoodOthersFairlyGood: 'PINTS GOOD, OTHERS FAIRLY GOOD.',
  pintsVeryLightOrganicFairlyLight: 'PINTS VERY LIGHT, ORGANIC FAIRLY LIGHT.',
  pintsVeryLightOthersFairlyLight: 'PINTS VERY LIGHT, OTHERS FAIRLY LIGHT.',
  'small/mediumFruitLightOthersModerate': 'SMALL/MEDIUM FRUIT LIGHT, OTHERS MODERATE.',
  'small/mediumFruitVeryLightOthersLight': 'SMALL/MEDIUM FRUIT VERY LIGHT, OTHERS LIGHT.',
  smallSizeLightOthersFairlyGood: 'SMALL SIZE LIGHT, OTHERS FAIRLY GOOD.',
  veryGood: 'VERY GOOD.',
  veryGoodAtLowerPrice: 'VERY GOOD AT LOWER PRICE.',
  veryGoodAtLowerPrices: 'VERY GOOD AT LOWER PRICES.',
  veryGoodAtLowerPricesOrganicBestGoodOthersFairlyLight:
    'VERY GOOD AT LOWER PRICES; ORGANIC BEST GOOD, OTHERS FAIRLY LIGHT.',
  veryGoodAtSlightlyLowerPrices: 'VERY GOOD AT SLIGHTLY LOWER PRICES.',
  veryGoodOrganicBestGoodOthersFairlyLight: 'VERY GOOD; ORGANIC BEST GOOD, OTHERS FAIRLY LIGHT.',
  veryGoodOrganicBestGoodOthersModerate: 'VERY GOOD; ORGANIC BEST GOOD, OTHERS MODERATE.',
  veryLight: 'VERY LIGHT.',
};

export { demandEN, demandES };
