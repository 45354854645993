import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Management from 'views/Content/Management/';
import Market from 'views/Content/Market/Market';
import MarketPlace from 'views/Content/MarketPlace/';
import Sider from 'views/Sider/Sider';
import { PrivateRoute } from 'utils/router/PrivateRoute';
import { ROUTES } from 'constants/routes';
import './ContentWrapper.scss';

const MainWrapper = () => {
  return (
    <div className="content-shell">
      <div className="grid-layout">
        <div className="sider-wrapper">
          <Sider />
        </div>
        <div className="content-wrapper">
          <Route exact path="/content">
            <Redirect to={ROUTES.INTELLIGENCE} />
          </Route>
          <Route exact path={ROUTES.INTELLIGENCE}>
            <Market />
          </Route>
          <PrivateRoute exact path={ROUTES.MANAGEMENT} component={Management} />
          <PrivateRoute exact path={ROUTES.MARKETPLACE} component={MarketPlace} redirectTo={ROUTES.LOGIN_MARKETPLACE} />
        </div>
      </div>
    </div>
  );
};

export default MainWrapper;
