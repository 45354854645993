import React from 'react';

/*eslint-disable*/
const EditCalendarIcon = ({ styleClass }) => {
  return (
    <svg className={styleClass} viewBox="0 0 16 16">
      <title>Edit date</title>
      <defs>
        <path
          d="M13.8625,9.1125 C14.0125,8.9625 14.2375,8.9625 14.3875,9.1125 L14.3875,9.1125 L15.8875,10.6125 C16.0375,10.7625 16.0375,10.9875 15.8875,11.1375 L15.8875,11.1375 L12.1375,14.8875 C12.0625,14.9625 11.9875,15 11.875,15 L11.875,15 L10.375,15 C10.15,15 10,14.85 10,14.625 L10,14.625 L10,13.125 C10,13.0125 10.0375,12.9375 10.1125,12.8625 L10.1125,12.8625 Z M14.125,2 C14.584375,2 15,2.44970414 15,2.9704142 L15,2.9704142 L15,7 L13.977,7 L13.9775281,6 L2,6 L2,13 L8,13 L8,14 L1.875,14 C1.4484375,14 1.05959821,13.6122449 1.00622608,13.1588921 L1,13.0532544 L1,2.94674556 C1,2.37869822 1.415625,2 1.875,2 L1.875,2 Z M14,3 L2,3 L2,5 L14,5 L14,3 Z"
          id="path-editcalendar"
        ></path>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icn/System/Custom-Date">
          <rect id="Icon-viewport-edit" fillOpacity="0" fill="#fff" x="0" y="0" width="16" height="16"></rect>
          <mask id="mask-editcalendar" fill="white">
            <use xlinkHref="#path-editcalendar"></use>
          </mask>
          <g id="Combined-Shape" fillRule="nonzero"></g>
          <g id="Group" mask="url(#mask-editcalendar)">
            <g transform="translate(-2.000000, -2.000000)" id="Neutral-/-900-editcalendar">
              <rect id="Neutral/600-editcalendar" fill="#120dd1" x="0" y="0" width="20" height="20"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EditCalendarIcon;
