const shippingPointBehaviorSelector = {
  shippingPointBehaviorContainer: 'shipping-point-behavior-container',
  shippingPointBehaviorHeader: 'shipping-point-behavior-header',
  shippingPointBehaviorTable: 'shipping-point-behavior-table',
  shippingPointBehaviorRow: 'shipping-point-behavior-row',
  shippingPointBehaviorHighestPrice: 'shipping-point-behavior-highest-row',
  shippingPointBehaviorLowestPrice: 'shipping-point-behavior-lowest-row',
  shippingPointBehaviorPrice3Days: 'shipping-point-behavior-price-3-days',
  shippingPointBehaviorPrice3DaysLabel: 'shipping-point-behavior-price-3-days-label',
  shippingPointBehaviorPrice7Days: 'shipping-point-behavior-price-7-days',
  shippingPointBehaviorPrice7DaysLabel: 'shipping-point-behavior-price-7-days-label',
  shippingPointBehaviorMovementWeek: 'shipping-point-behavior-week',
  shippingPointBehaviorMovementYear: 'shipping-point-behavior-year',
  marketBehaviourArrowdown: 'shipping-point-behavior-arrowdown',
  marketBehaviourArrowtop: 'shipping-point-behavior-arrowtop',
  shippingPointBehaviorFooter: 'shipping-point-behavior-footer',
  latestUpdated: 'latest-updated',
  demanded: 'demanded',
  market: 'market',
};

module.exports = shippingPointBehaviorSelector;
