/*
 * @usage
 *
 * import Message from 'path/to/Message';
 *
 * handleMessageAction receives action param,
 * this param is a bool if the param is true so the message was
 * accepted if not was canceled.
 * eg:
 *
 * handleMessageAction = (action) => {
 *  if (action) {
 *    accept button was pressed
 *    return;
 * }
 * cancel button was pressed;
 *  return;
 * };
 *
 * <Message
 *    acceptText={textToAccept}
 *    cancelText={textToCancel}
 *    description={descriptionText}
 *    handleMessage={handleMessageAction}
 *    title={titleText}
 *    type="alert || confirm"
 *  />
 *);
 *
 */
import React, { useEffect, useRef } from 'react';
import { func, node, oneOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import switchCaseHandler from 'utils/common/switch';
import './Message.scss';

const Message = ({
  title,
  description,
  handleMessage,
  acceptText,
  cancelText,
  type = 'alert',
  acceptButtonShape = 'main',
  acceptButtonType = 'destructive',
  cancelButtonShape = 'complementary',
  cancelButtonType = 'primary',
}) => {
  const elRef = useRef();
  const { t } = useTranslation('Message');

  const aceptLabel = acceptText || t('Message:accept');
  const cancelLabel = cancelText || t('Message:cancel');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    requestAnimationFrame(() => {
      elRef.current.classList.add('message-container--active');
    });
  }, []);

  const handleCancelMessage = () => {
    document.body.style.overflow = 'unset';
    handleMessage(false);
  };

  const handleAceptMessage = () => {
    document.body.style.overflow = 'unset';
    handleMessage(true);
  };

  const handleMessageButtons = () => {
    return switchCaseHandler({
      alert: function AlertMessage() {
        return (
          <div className="message-card-content-button-container">
            <Button onClick={handleCancelMessage} shape="complementary" size="medium" type="primary">
              {aceptLabel}
            </Button>
          </div>
        );
      },
      confirm: function ConfirmMessage() {
        return (
          <div className="message-card-content-button-container">
            <Button onClick={handleCancelMessage} size="medium" shape={cancelButtonShape} type={cancelButtonType}>
              {cancelLabel}
            </Button>
            <Button onClick={handleAceptMessage} size="medium" shape={acceptButtonShape} type={acceptButtonType}>
              {aceptLabel}
            </Button>
          </div>
        );
      },
    })('')(type);
  };

  return (
    <div ref={elRef} className="message-container w100 h100 black-50-bg">
      <div className="message-card">
        <div className="message-card-content">
          <h3 className="message-card-content-title">{title}</h3>
          <p className="message-card-content-description">{description}</p>
          <div className="message-card-content-footer">{handleMessageButtons()}</div>
        </div>
      </div>
    </div>
  );
};

Message.propTypes = {
  title: string.isRequired,
  description: node.isRequired,
  handleMessage: func.isRequired,
  /**
   * Dialog’s type:
   * - alert: Alerts are urgent interruptions, requiring acknowledgement, that inform the user about a situation.
   * - confirm: Confirmation dialogs require users to explicitly confirm their choice before an option is committed.
   * @default 'alert'
   */
  type: oneOf(['alert', 'confirm']),
  /**
   * Accept button text
   * @default t('Message:accept') Aceptrar|Accept
   */
  acceptText: string,
  /**
   * Cancel button text
   * @default t('Message:cancel') Cancelar|Cancel
   */
  cancelText: string,
  /**
   * Accept button shape prop
   * @see components/Button
   * @default 'main'
   */
  acceptButtonShape: oneOf(['main', 'complementary']),
  /**
   * Accept button type prop
   * @see components/Button
   * @default 'destructive'
   */
  acceptButtonType: oneOf(['primary', 'secondary', 'disabled', 'destructive']),
  /**
   * Cancel button type prop
   * @see components/Button
   * @default 'complementary'
   */
  cancelButtonShape: oneOf(['main', 'complementary']),
  /**
   * Cancel button type prop
   * @see components/Button
   * @default 'primary'
   */
  cancelButtonType: oneOf(['primary', 'secondary', 'disabled', 'destructive']),
};

export default Message;
