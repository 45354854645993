import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './FilterSelect';
import originsSelect from '../utils/Select/originsSelect';
import { volumeProductTypeSelect } from '../utils/Select/productTypeSelect';
import { useTranslation } from 'react-i18next';
import './VolumeSelectCascader.scss';

const VolumeSelectCascader = ({ id, origins, setOptionsSelected, setShippingPoint, styleClass }) => {
  const [locations, setLocations] = useState([]);
  const [producTypes, setProductTypes] = useState([]);
  const [locationsState, setLocationsState] = useState();

  //SELECTED STATES
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');

  // i18n
  const { i18n, t } = useTranslation('selectCascader');

  // main useEffect to set and clean the locationsState
  useEffect(() => {
    if (origins) {
      setLocationsState(origins);
    }

    return function cleanup() {
      // clean the main state hook when the component is unmounted
      setLocationsState();
      setSelectedLocation('');
    };
  }, [origins]);

  // Fill the selects data and get the default values
  useEffect(() => {
    if (locationsState) {
      const locationsParsed = originsSelect(locationsState, 'entireMarket', t('selectCascader:entireMarket'));

      // data selected default
      const defaultLocation = locationsParsed.length > 0 ? locationsParsed[0].value : 0;

      // set Select default Data
      setLocations(locationsParsed);

      // set default data selected
      setSelectedLocation(defaultLocation);
      // We need to set default location to show information in MARKET BEHAVIOR table
      if (setShippingPoint) setShippingPoint(locationsParsed[0]);
    }
  }, [locationsState]);

  useEffect(() => {
    if (locations) {
      const locationsParsed = locations.map((location) => {
        if (location.id === 'entireMarket') return { ...location, label: t('selectCascader:entireMarket') };
        return location;
      });
      setLocations(locationsParsed);
    }
    if (producTypes) {
      const productTypeParsed = producTypes.map((product) => ({
        ...product,
        label: t(`selectCascader:${product.id}`),
      }));
      setProductTypes(productTypeParsed);
    }
  }, [i18n.language]);

  // fill the selects cascade if the locations changes
  useEffect(() => {
    if (selectedLocation) {
      //Origin data filtered by selectedLocation
      const currentLocation =
        selectedLocation !== 'entireMarket'
          ? locationsState.filter((origin) => origin.origin_slug === selectedLocation)[0]
          : selectedLocation;

      //Select data
      const productTypesFiltered = volumeProductTypeSelect(currentLocation);
      const productTypeTranslated = productTypesFiltered.map((productType) => ({
        ...productType,
        label: t(`selectCascader:${productType.id}`),
      }));

      ////data selected
      const productTypeSelected = productTypeTranslated[0].value || '';

      ////set Select data
      setProductTypes(productTypeTranslated);

      //setLocationFiltered(currentLocation);
      //// CB from father component to execute API calls from there
      setOptionsSelected({
        id,
        location: selectedLocation,
        productType: productTypeSelected,
      });
    }
  }, [selectedLocation]);

  // fill the package select if the product type changes
  useEffect(() => {
    if (selectedProductType) {
      setOptionsSelected({
        id,
        location: selectedLocation,
        productType: selectedProductType,
      });
      setSelectedProductType('');
    }
  }, [selectedProductType]);

  return (
    <div className={`volume-select-cascader ${styleClass}`}>
      <div className="volume-select-row">
        <p className="volume-select-label-title">{t('location')}</p>
        <FilterSelect data={locations} setSelectedData={setSelectedLocation} id={`sp-origin-${id}`} />
      </div>
      <div className="volume-select-row">
        <p className="volume-select-label-title">{t('type')}</p>
        <FilterSelect
          actionToExecute="productType"
          data={producTypes}
          setSelectedData={setSelectedProductType}
          id={`sp-product-${id}`}
        />
      </div>
    </div>
  );
};

VolumeSelectCascader.defaultProps = {
  id: undefined,
  origins: undefined,
  setShippingPoint: undefined,
  styleClass: '',
};

VolumeSelectCascader.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  origins: PropTypes.array,
  setOptionsSelected: PropTypes.func.isRequired,
  setShippingPoint: PropTypes.func,
  styleClass: PropTypes.string,
};

export default VolumeSelectCascader;
