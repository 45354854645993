import React from 'react';
import MyOpportunityDetails from './DetailCommitment/MyOpportunityDetails';
import ProgramDocumentation from './DetailCommitment/ProgramDocumentation';
import PropTypes from 'prop-types';
import Tab from '../../../../../components/Tab';
import WelcomeMessage from './WelcomeMessage';
import { useTranslation } from 'react-i18next';
import './CommittedOpportunity.scss';

const CommittedOpportunity = ({ programData, programDetails, unit }) => {
  const { t } = useTranslation('mpCommittedProgram');

  const optionsObject = {
    opportunityDetails: function ProgramDetailsCase() {
      return <MyOpportunityDetails programData={programData} programDetails={programDetails} unit={unit} />;
    },
    programDocumentation: function ProgramDocumentationCase() {
      return <ProgramDocumentation programDetails={programDetails} />;
    },
  };

  // generating the options for Tab by program_type
  const optionsOrder = ['opportunityDetails', 'programDocumentation'];

  const optionsTitle = {
    0: t('opportunityDetails'),
    1: t('programDocumentation'),
  };
  const renderTab = () => {
    if (programData && programDetails)
      return <Tab optionsObject={optionsObject} optionsOrder={optionsOrder} optionsTitle={optionsTitle} />;
    return '';
  };

  return (
    <div className="committed-program-container">
      {/* welcome message */}
      <WelcomeMessage programId={programData.id} programData={programData} />
      {/* content */}

      <div className="commttd-prog-content">{renderTab()}</div>

      {/* footer help message */}
      <div className="commttd-prog-footer-help">
        <h3>{t('mpCommittedProgram:haveIssue')}</h3>
        <hr />
        <p className="commtd-prog-message-label">{t('mpCommittedProgram:helpMessage')}</p>
        <div>
          <a className="link-to-mail-support-message" href="mailto:support@fridanet.com">
            {t('mpCommittedProgram:sendMessage')}
          </a>
        </div>
      </div>
    </div>
  );
};

CommittedOpportunity.propTypes = {
  programData: PropTypes.object.isRequired,
  programDetails: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
};

export default CommittedOpportunity;
