import React, { useState } from 'react';
import { bool } from 'prop-types';
import Modal from '../Modal';
import ResetPasswordForm from './Form';
import ResetPasswordSuccessMessage from './SuccessMessage';
import './style.scss';

const ModalResetPassword = ({ noTransition = false }) => {
  const [successEmail, setSuccessEmail] = useState();

  const handleSuccess = (email) => {
    setSuccessEmail(email);
  };

  return (
    <Modal className="modal-reset-password" noTransition={noTransition} focused={false}>
      <div className="modal-reset-password__body pt-l px-xl pb-xl primary-50-bg br-inherit">
        {successEmail ? (
          <ResetPasswordSuccessMessage email={successEmail} />
        ) : (
          <ResetPasswordForm onSuccess={handleSuccess} />
        )}
      </div>
    </Modal>
  );
};

ModalResetPassword.propTypes = {
  /**
   * If `true` the component will not show the onmount transition.
   * @deafult false
   */
  noTransition: bool,
};

export default ModalResetPassword;
