/*
 * modern switch case to reuse and evaluate objects keys
 * each option of the object should be a function to execute.
 * should receive the object cases and last param the default value if not.
 */

/*
 * @param {function} f
 * @return {function.call}
 */
const executeIfFunction = (f) => (typeof f === 'function' ? f() : f);

const switchCase = (cases) => (defaultCase) => (key) =>
  Object.prototype.hasOwnProperty.call(cases, key) ? cases[key] : defaultCase;

/*
 * @param {Object} cases
 * @param {*} defaultCase
 * @param {string} key
 */
const switchCaseHandler = (cases) => (defaultCase) => (key) => executeIfFunction(switchCase(cases)(defaultCase)(key));

export default switchCaseHandler;
