// Matches main structure with values provided by API
const fillGraphValues = (mainStructure, givenValues) =>
  mainStructure.map((currVal) => {
    const receivedValue = givenValues.find((element) => element.week == currVal[0]);
    return receivedValue ? [currVal[0], receivedValue.price] : currVal;
  });

// Extracts just week attribute from given object
const getWeekNumber = ({ week }) => week;

// Create main structure to avoid 'new' labels at the end of the graph
const generateMainStructure = (sortedWeeks) => sortedWeeks.map((week) => [`${week}`, null]);

/**
 * Creates a set of dates to keep Date labels in graph properly handled
 * @param {Array} organic data
 * @param {Array} traditional data
 * @returns {Object}  Object {organic, traditional} information with proper structure for graph
 */
const generateWeekStructure = (organic, traditional, ...names) => {
  const lastWeek = organic[organic.length - 1].week;

  const allWeekNumbers = new Set([...organic.map(getWeekNumber), ...traditional.map(getWeekNumber)]);
  const sortedWeeks = [...allWeekNumbers].sort((a, b) => a - b);

  const mainStructure = generateMainStructure(sortedWeeks);

  // Select where to split the sequence to keep just 8 weeks 'in the future'
  const splitPoint = sortedWeeks.indexOf(lastWeek) + 1;
  mainStructure.unshift(...mainStructure.splice(splitPoint));

  const organicStructure = fillGraphValues(mainStructure, organic);
  const traditionalStructure = fillGraphValues(mainStructure, traditional);

  return {
    organic: {
      data: organicStructure,
      name: names[0],
    },
    traditional: {
      data: traditionalStructure,
      name: names[1],
    },
  };
};

export default generateWeekStructure;
