/*
 * packagingMaterial handler
 * @param {array} packaging_materials
 * @param {number} packagingMaterial selected
 * @param {number} commitedBoxes
 * @return {number} packagingMaterialPrice
 */
const packagingMaterialPriceHandler = (packaging_materials, packagingMaterial, commitedBoxes) => {
  let packagingMaterialPrice = 0;
  if (packagingMaterial && commitedBoxes) {
    packagingMaterialPrice =
      packaging_materials.find((material) => material.id === packagingMaterial)?.price * commitedBoxes;
  }
  return packagingMaterialPrice;
};

/*
 * destinationLogistic handler
 * @param {array} destinations_data
 * @param {number} destinationLogistic selected
 * @param {number} commitedBoxes
 * @return {number} destinationLogisticPrice
 */
const destinationLogisticPriceHandler = (destinations_data, final_destination, destinationLogistic, commitedBoxes) => {
  let destinationLogisticPrice = 0;
  const filterDestination = final_destination !== destinationLogistic ? true : false;

  if (destinationLogistic && commitedBoxes && filterDestination) {
    destinationLogisticPrice =
      destinations_data.find((destination) => destination.id === destinationLogistic)?.base_price * commitedBoxes;
  }
  return destinationLogisticPrice;
};

/*
 * @param {object} progamDetail
 * @param {number} commitedBoxes
 * @param {number} price
 * @return {object}
 */
const priceHandler = (programDetail, commitedBoxes, price, packagingMaterial, destinationLogistic) => {
  const {
    destinations_data,
    fee,
    final_destination,
    import_taxes,
    inspection_fee,
    logistical_fee,
    packaging_materials,
    transports_fee,
  } = programDetail;
  const priceFOB = price * commitedBoxes;
  const totalLogistics = logistical_fee * commitedBoxes;
  const fridaFee = fee * priceFOB;
  const custom = import_taxes;
  const totalInAndOut = transports_fee * commitedBoxes;
  const inspectionFee = inspection_fee * commitedBoxes;
  const packagingMaterialPrice = packagingMaterialPriceHandler(packaging_materials, packagingMaterial, commitedBoxes);
  const destinationLogisticPrice = destinationLogisticPriceHandler(
    destinations_data,
    final_destination,
    destinationLogistic,
    commitedBoxes,
  );

  const producerReturn =
    priceFOB -
    totalLogistics -
    fridaFee -
    custom -
    totalInAndOut -
    inspectionFee -
    packagingMaterialPrice -
    destinationLogisticPrice;
  const unitReturn = producerReturn / commitedBoxes;

  const pricesObject = {
    custom,
    fridaFee,
    inspectionFee,
    priceFOB,
    producerReturn,
    totalInAndOut,
    totalLogistics,
    unitReturn,
    packagingMaterialPrice,
    destinationLogisticPrice,
  };

  return pricesObject;
};

export default priceHandler;
