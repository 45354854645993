import { WeeklySerie } from './SeriesColors';

const VolumeAndPricesGraphOptions = {
  volumeAndPrices: {
    legend: {
      align: 'left',
      itemDistance: 35,
      itemMarginTop: 20,
      itemMarginBottom: 5,
      itemStyle: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: 2.4,
        color: '#4e5052',
      },
      squareSymbol: true,
      symbolWidth: 12,
      symbolHeight: 12,
      symbolRadius: 6,
      x: 37,
    },
    colors: WeeklySerie,
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      allowDecimals: false,
      title: {
        text: '',
      },
    },
    yAxis: [
      {
        title: {
          text: '',
        },
      },
      {
        labels: {
          format: '$ {value}',
        },
        title: {
          text: '',
        },
        opposite: true,
      },
    ],
    tooltip: {
      backgroundColor: '#313233',
      padding: 20,
      borderRadius: 16,
      crosshairs: true,
      shared: true,
      style: {
        color: '#fff',
        fontSize: 14,
      },
      useHTML: true,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
    },
    series: [],
  },
};

export default VolumeAndPricesGraphOptions;
