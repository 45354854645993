import { GET_CURRENT_DATE } from '../actions/currentDate/types';

const initialState = false;

const currentDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.payload,
      };
    default:
      return state;
  }
};
export default currentDateReducer;
