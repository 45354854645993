import React from 'react';
import DayCard from '../card/DayCard';
import PropTypes from 'prop-types';
import WebStorage from '../../../../../utils/WebStorage/WebStorage';
import statusHandler from '../../../../../utils/marketplace/status/statusHandler';
import unitsHandler from '../../../../../utils/marketplace/units/unitsHandler';
import weekRow from '../../../../../utils/page_definition/marketplace/weekRow';
import { GetDayWeekRow } from '../../../../../utils/common/DateHandler';
import { intFormat } from '../../../../../utils/common/numberHandler';
import { useTranslation } from 'react-i18next';
import './WeekRow.scss';

const WeekRow = ({ data, currentDate, eventData, palletsTruck, packageWeight, boxesPallet, unit }) => {
  const { boxes, week, date, days } = data;
  const { unitIcons, rawValue } = unitsHandler;
  const { commitmentStatus } = statusHandler;
  const { weekRowContainer, weekRowHeaderStatus, weekRowTitle, weekRowHeaderPackageValue } = weekRow;

  const boxesFiltered = rawValue(unit, boxes, palletsTruck, boxesPallet, packageWeight);

  const { t } = useTranslation(['mpWeekRow', 'general']);
  const { getCookieValue } = WebStorage;
  const localeID = getCookieValue('localeId');

  const renderDailyRow = () =>
    days && days.length
      ? days.map((day, index) => (
          <DayCard
            boxesPallet={boxesPallet}
            data={day}
            eventData={eventData}
            key={index}
            palletsTruck={palletsTruck}
            packageWeight={packageWeight}
            currentDate={currentDate}
            unit={unit}
          />
        ))
      : '';

  const renderStatus = () => {
    if (eventData) {
      const { status } = eventData;
      const weekStatus = commitmentStatus(status);
      const weekStatusLabel = t(weekStatus);

      return (
        <div className={`week-row-status status-${weekStatus}`} data-test={weekRowHeaderStatus}>
          {weekStatusLabel}
        </div>
      );
    }
    return '';
  };

  return (
    <div className="week-row-container" data-test={weekRowContainer}>
      <div className="week-rw-header">
        <h5 className="week-rw-hdr-title" data-test={weekRowTitle}>
          {`${t('mpWeekRow:week')} ${week} - ${t('general:date', {
            date: GetDayWeekRow(date, localeID).toUpperCase(),
          })}`}
        </h5>
        <div className="week-rw-hdr-package-container">
          {unitIcons(unit, 'week-rw-hdr-package-icon')}
          <p className="week-rw-hdr-package-value" data-test={weekRowHeaderPackageValue}>
            {intFormat(boxesFiltered)}
          </p>
          {renderStatus()}
        </div>
      </div>
      <hr className="week-rw-divisor" />
      <div className="week-rw-content">{renderDailyRow()}</div>
    </div>
  );
};

WeekRow.defaultProps = {
  eventData: undefined,
};

WeekRow.propTypes = {
  boxesPallet: PropTypes.number.isRequired,
  currentDate: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  eventData: PropTypes.object,
  packageWeight: PropTypes.number.isRequired,
  palletsTruck: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};

export default WeekRow;
