import React from 'react';
import Button from '../../../../../components/Button';
import switchCaseHandler from '../../../../../utils/common/switch';

/*
 * handleContentButtons method to return the flow buttons
 * @param {bool} disableNextButton
 * @param {function} nextStep
 * @param {function} prevStep
 * @param {function} handleBoxProgramOnclick
 * @param {string} nextLabel
 * @param {string} prevLabel
 * @return {React.Element}
 */
const handleContentButtons = (disableNextButton, nextStep, prevStep, handleBoxProgramOnclick, nextLabel, prevLabel) =>
  switchCaseHandler({
    0: function BoxProgramButtons() {
      return (
        <div className="commit-resume-box-program-buttons">
          <Button
            disabled={disableNextButton}
            type={`${disableNextButton ? 'disabled' : 'secondary'}`}
            size="small"
            onClick={handleBoxProgramOnclick}
            styleClass="commit-resume-content-buttons"
          >
            {nextLabel}
          </Button>
        </div>
      );
    },
    1: function DetailCommitmentButtons() {
      return (
        <div className="commit-resume-detail-commit-buttons">
          <Button onClick={() => prevStep()} shape="complementary" size="small" styleClass="" type="secondary">
            {prevLabel}
          </Button>

          <Button
            disabled={disableNextButton}
            type={`${disableNextButton ? 'disabled' : 'secondary'}`}
            size="small"
            onClick={() => nextStep()}
            styleClass="commit-resume-content-buttons"
          >
            {nextLabel}
          </Button>
        </div>
      );
    },
    2: function ConfirmCommitmentButtons() {
      return (
        <div className="commit-resume-detail-commit-buttons">
          <Button onClick={() => prevStep()} shape="complementary" size="small" styleClass="" type="secondary">
            {prevLabel}
          </Button>
        </div>
      );
    },
  })(<></>);

export default handleContentButtons;
