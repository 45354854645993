import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Provider } from 'react-redux';
import store from './redux/store';
import GoogleTagManager from './utils/GoogleTManager/GoogleTManager';
import './i18n/i18n';
import { version } from '../package.json';

GoogleTagManager.init();

// https://docs.sentry.io/platforms/javascript/guides/react/
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: `${process.env.REACT_APP_SENTRY_PROJECT_NAME}@${version}`,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control from 0 to 1.
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

if (window.Cypress) {
  window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
