import React from 'react';

/* eslint-disable */
const OkIcon = ({ dataTest, styleClass }) => (
  <svg data-test={dataTest} className={styleClass} version="1.1" viewBox="0 0 24 24">
    <title>Ok Icon</title>
    <defs>
      <path
        d="M12.72,6.79 L8.43,11.09 L6.78,9.44 C6.53561012,9.15462384 6.1518816,9.03031806 5.78658103,9.11818987 C5.42128045,9.20606167 5.13606167,9.49128045 5.04818987,9.85658103 C4.96031806,10.2218816 5.08462384,10.6056101 5.37,10.85 L7.72,13.21 C7.90871883,13.3971865 8.16419702,13.5015368 8.43,13.5000167 C8.69233988,13.4988954 8.94373936,13.3947442 9.13,13.21 L14.13,8.21 C14.3193127,8.0222334 14.4257983,7.76663754 14.4257983,7.5 C14.4257983,7.23336246 14.3193127,6.9777666 14.13,6.79 C13.7399625,6.40227641 13.1100375,6.40227641 12.72,6.79 Z M10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,7.3478351 18.9464316,4.80429597 17.0710678,2.92893219 C15.195704,1.0535684 12.6521649,0 10,0 Z M10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 C14.418278,2 18,5.581722 18,10 C18,12.1217319 17.1571453,14.1565632 15.6568542,15.6568542 C14.1565632,17.1571453 12.1217319,18 10,18 Z"
        id="path-ok"
      ></path>
    </defs>
    <g id="03-Icons-ok" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
      <g id="icn-ok">
        <g id="Icn/Ok">
          <rect id="ViewBox-ok" fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
          <g id="Icon-ok" transform="translate(2.000000, 2.000000)">
            <mask id="mask-icon-ok" fill="white">
              <use xlinkHref="#path-ok"></use>
            </mask>
            {/* TODO: set fill to FFFFFF to avoid pixelated */}
            <use id="Icon-Shape-ok" fill="#ffffff" fillRule="nonzero" xlinkHref="#path-ok"></use>
            <g id="Neutral-/-900" mask="url(#mask-icon-ok)" fill="#4cca16">
              <path d="M8,0 L20,0 L20,0 L20,20 L8,20 C3.581722,20 5.41083001e-16,16.418278 0,12 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default OkIcon;
