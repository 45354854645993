/**
 * HighChart options object for the RetailPrices
 */
const RetailPricesOptions = {
  legend: {
    align: 'left',
    itemDistance: 20,
    itemMarginTop: 20,
    itemMarginBottom: 5,
    itemStyle: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 2.4,
      color: '#4e5052',
    },
    squareSymbol: true,
    symbolWidth: 12,
    symbolHeight: 12,
    symbolRadius: 6,
    x: 37,
  },
  colors: ['#ffbc5b', '#4cca16', '#f02929', '#141414', '#2c9ac0', '#5727eb'],
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
    allowDecimals: false,
    title: {
      text: 'Week Number',
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return `$${this.value.toFixed(2)}`;
      },
    },
  },
  tooltip: {
    backgroundColor: '#313233',
    pointFormat:
      '<span style="color:{point.color}">●</span> {series.name}: <b style="margin-left: 5px">{point.y}</b><br/>',
    padding: 20,
    borderRadius: 16,
    valuePrefix: '$',
    shared: true,
    style: {
      color: '#fff',
      fontSize: 14,
    },
    useHTML: true,
  },
  plotOptions: {
    series: {
      connectNulls: true,
      marker: {
        enabled: false,
        symbol: 'circle',
      },
    },
  },
};

const getRetailPricesOptions = (xAxis) => {
  const filledOptions = RetailPricesOptions;
  filledOptions.xAxis.title.text = xAxis;
  return filledOptions;
};

export default getRetailPricesOptions;
