const mockProductStorage = {
  strby: {
    name: 'Strawberries',
    storage: '4,200 Boxes of 12 clamshells each',
    crossing: 'SALINAS WATSONVILLE CALIFORNIA (CASW)',
  },
  bluby: {
    name: 'Blueberries',
    storage: '3,100 Boxes of 12 clamshells each',
    crossing: 'OREGON AND WASHINGTON (ORWA)',
  },
  rasp: {
    name: 'Raspberries',
    storage: '4,500 Boxes of 12 clamshells each',
    crossing: 'SALINAS WATSONVILLE CALIFORNIA (CASW)',
  },
  'blkberi-v': {
    name: 'Blackberries',
    storage: '4,000 Boxes of 12 clamshells each',
    crossing: 'SALINAS WATSONVILLE CALIFORNIA (CASW)',
  },
  cel: {
    name: 'Celery',
    storage: '1,500 Boxes of 12 clamshells each',
    crossing: 'SALINAS WATSONVILLE CALIFORNIA (CASW)',
  },
  asp: {
    name: 'Asparagus',
  },
};

export default mockProductStorage;
