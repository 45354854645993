import { GET_CURRENT_DATE } from './types';
import UtilsApi from '../../../utils/api/UtilsApi';

export const getCurrentDateAction = () => (dispatch) => {
  UtilsApi.getCurrentDate().then((resp) => {
    dispatch({
      type: GET_CURRENT_DATE,
      payload: resp.data,
    });
  });
  //TODO: Error Handler required
};
