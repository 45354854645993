import React, { useState } from 'react';
import { bool } from 'prop-types';
import { useLocation } from 'react-router-dom';
import Modal from '../Modal';
import ResetPasswordConfirmForm from './Form';
import ResetPasswordConfirmSuccessMessage from './SuccessMessage';
import ResetPasswordConfirmInvalidLink from './InvalidLink';
import './style.scss';

const ModalResetPasswordConfirm = ({ noTransition = false }) => {
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const uid = new URLSearchParams(location.search).get('uid');
  const token = new URLSearchParams(location.search).get('token');

  const renderContent = () => {
    if (success) return <ResetPasswordConfirmSuccessMessage />;
    if (error) return <ResetPasswordConfirmInvalidLink />;
    if (uid && token)
      return (
        <ResetPasswordConfirmForm
          uid={uid}
          token={token}
          onSuccess={() => setSuccess(true)}
          onInvalidToken={() => setError(true)}
        />
      );
    return <ResetPasswordConfirmInvalidLink />;
  };

  return (
    <Modal className="modal-reset-password-confirm" noTransition={noTransition}>
      <div className="modal-reset-password-confirm__body pt-l px-xl pb-xl primary-50-bg br-inherit">
        {renderContent()}
      </div>
    </Modal>
  );
};

ModalResetPasswordConfirm.propTypes = {
  /**
   * If `true` the component will not show the onmount transition.
   * @deafult false
   */
  noTransition: bool,
};

export default ModalResetPasswordConfirm;
