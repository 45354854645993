import React from 'react';
import switchCaseHandler from '../../common/switch';
import unitsArrayHandler from '../../Select/units/unitsArrayHandler';
import { TruckIcon, PackageIcon, PalletIcon, KgIcon, LbIcon } from '../../../assets/icons/assets';

/**
 * units dictionary (SINGLETON)
 */

const unitsHandler = {
  /*
   * icons handler
   * @param { string } unit to return icon
   * @param { string } styleClass custom styleClass
   * @return {React.element} icon for unit.
   */
  unitIcons: (unit = 'truck', styleClass = '') => {
    const iconsDictionary = switchCaseHandler({
      box: function boxIcon() {
        return <PackageIcon styleClass={`unit-package-icon ${styleClass}`} />;
      },
      kg: function kgIcon() {
        return <KgIcon styleClass={`unit-kg-icon ${styleClass}`} />;
      },
      lb: function lbIcon() {
        return <LbIcon styleClass={`unit-lb-icon ${styleClass}`} />;
      },
      pallet: function palletIcon() {
        return <PalletIcon styleClass={`unit-pallet-icon ${styleClass}`} />;
      },
      truck: function truckIcon() {
        return <TruckIcon styleClass={`unit-truck-icon ${styleClass}`} />;
      },
    })(undefined);

    return iconsDictionary(unit);
  },

  /*
   * label handler
   * TODO: still pending i18n
   * @param {string} unit to return the label
   * @return {string}
   */
  unitLabel: (unit = 'truck') => {
    const labelDictionary = switchCaseHandler({
      box: () => 'box',
      kg: () => 'kg',
      lb: () => 'lb',
      pallet: () => 'pallet',
      truck: () => 'truck',
    })('');

    return labelDictionary(unit);
  },

  /*
   * raw value used on available program card without commitment_limit
   * @param {string} unit to return the value.
   * @param {number} total_boxes
   * @param {number} pallets_truck
   * @param {number} boxes_pallet
   * @param {number} package_weight
   * @return {number}
   */
  rawValue: (unit = 'truck', total_boxes, pallets_truck, boxes_pallet, package_weight) => {
    const basePallet = total_boxes / boxes_pallet;
    const baseTruck = basePallet / pallets_truck;
    const kgs = total_boxes * package_weight;
    const lbs = kgs * 2.204623;

    const rawValueDictionary = switchCaseHandler({
      box: () => total_boxes,
      kg: () => kgs,
      lb: () => lbs,
      pallet: () => basePallet,
      truck: () => baseTruck,
    })(undefined);

    return rawValueDictionary(unit);
  },

  /*
   * value to commit with limit per week
   * @param {string} unit to return the value.
   * @param {number} commitment_limit
   * @param {number} pallets_truck
   * @param {number} boxes_pallet
   * @param {number} weeks
   * @param {number} package_weight
   * @return {number}
   */
  commitmentValue: (
    unit = 'truck',
    commitment_limit,
    pallets_truck,
    boxes_pallet,
    weeks,
    package_weight,
    minCommitment,
    minCommitmentSteps,
  ) => {
    const maxWeeklyLimit = commitment_limit / weeks;
    const stepsBase = Math.trunc((maxWeeklyLimit - minCommitment) / minCommitmentSteps) + 1;
    const stepsIncrement = unitsHandler.rawValue(unit, minCommitmentSteps, pallets_truck, boxes_pallet, package_weight);
    const minAmmount = unitsHandler.rawValue(unit, minCommitment, pallets_truck, boxes_pallet, package_weight);

    const lbs = package_weight * 2.204623;

    const commitmentValueDictionary = switchCaseHandler({
      box: () => unitsArrayHandler({ minAmmount, stepsBase, stepsIncrement }),
      kg: () => unitsArrayHandler({ minAmmount, stepsBase, stepsIncrement, divide: package_weight }),
      lb: () => unitsArrayHandler({ minAmmount, stepsBase, stepsIncrement, divide: lbs }),
      pallet: () => unitsArrayHandler({ minAmmount, stepsBase, stepsIncrement, factor: boxes_pallet }),
      truck: () => unitsArrayHandler({ minAmmount, stepsBase, stepsIncrement, factor: pallets_truck * boxes_pallet }),
    })([]);

    return commitmentValueDictionary(unit);
  },

  /*
   * TODO: i18n
   * used on confirm commitment for the unit description
   * @param {string} unit to return the value.
   * @param {number} pallets_truck
   * @param {number} boxes_pallet
   * @param {string} package_description
   * @return {string}
   */
  unitDescriptions: (unit = 'truck') => {
    return switchCaseHandler({
      box: () => 'box',
      pallet: () => 'pallet',
      truck: () => 'truck',
      lb: 'lb',
      kg: 'kg',
    })('')(unit);
  },

  /*
   * @param {string} unit
   * @return {number}
   */
  estimatedReturn: (unit = 'truck', unitReturn, package_weight) => {
    const priceKgs = unitReturn / package_weight;
    const pricelbs = unitReturn / (package_weight * 2.204623);
    return switchCaseHandler({
      kg: () => priceKgs,
      lb: () => pricelbs,
    })(unitReturn)(unit);
  },

  /*
   * TODO: i18n
   * @param {string} unit
   * @return {string}
   */
  estimatedReturnDescription: (unit = 'truck') => {
    return switchCaseHandler({
      kg: 'USDK',
      lb: 'USDL',
    })('USDC')(unit);
  },
};

export default unitsHandler;
