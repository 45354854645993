import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import WebStorage from 'utils/WebStorage/WebStorage';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ component: Component, redirectTo = '/login', ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (WebStorage.getCookieValue('access_token')) {
        return <Component {...props} />;
      }

      const { pathname, search, hash } = props?.location;
      const redirectAfterLogin = pathname + search + hash;

      return (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { redirectAfterLogin },
          }}
        />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
};
