import React, { useState } from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ButtonLoading from '../../../../components/ButtonLoading';
import Input from '../../../../components/Input';
import { ROUTES } from '../../../../constants/routes';
import utilsApi from '../../../../utils/api/UtilsApi';

const internals = {
  emailID: 'modal-reset-password-email',
};

const ResetPasswordForm = ({ onSuccess }) => {
  const { t } = useTranslation('modalResetPassword');
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);

  const handleOnSubmit = async (ev) => {
    ev.preventDefault();
    setErrorLogin(false);

    const email = ev.target[internals.emailID].value;

    if (!email) return;

    setLoading(true);

    try {
      await utilsApi.resetPassword(email);
      setLoading(false);
      onSuccess(email);
    } catch (err) {
      setLoading(false);
      setErrorLogin(t('error'));
    }
  };

  return (
    <>
      <h1 className="neutral-900 fs3 fw700 pb-m">{t('heading')}</h1>
      <p className="neutral-900 fs-3 pb-l">{t('description')}</p>
      <form onSubmit={handleOnSubmit}>
        <Input
          focused={true}
          error={errorLogin && true}
          id={internals.emailID}
          name={internals.emailID}
          label={t('email')}
          placeholder="ie. francisco@fridanet.com"
          required={true}
          type="email"
        />
        {errorLogin && <p className="fs-3 red-900 pt-base">{errorLogin}</p>}
        <div className="df pt-l">
          <div className="mr-m">
            <ButtonLoading loading={loading}>{t('formActionButton')}</ButtonLoading>
          </div>
          <Link
            className="fk-button button-primary-main button-small fw600 tdn dib"
            to={{
              search: ROUTES.PARAMS.MODAL_LOGIN,
            }}
          >
            {t('formActionCancel')}
          </Link>
        </div>
      </form>
    </>
  );
};

ResetPasswordForm.propTypes = {
  /**
   * Callback fired when the component endpoint call is ok.
   * @param {string} email The verified email by the endpoint.
   */
  onSuccess: func.isRequired,
};

export default ResetPasswordForm;
