import React from 'react';

/*eslint-disable */
const FridaLogoText = ({ styleClass, fillColor }) => {
  return (
    <svg className={styleClass} viewBox="0 0 139 55">
      <title>Frida Logo</title>
      <g id="frida-logo-text" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Sprint-27-Marketplace-Step-005.3-frida-logo-text" transform="translate(-348.000000, -1746.000000)">
          <g id="logo-frida-text" transform="translate(348.000000, 1746.000000)">
            <path
              d="M13.7929,13.1534 L13.7929,14.2004 L20.2529,14.2004 L20.2529,21.9424 L13.7929,21.9424 L13.7929,44.3504 C13.7929,51.3344 9.8359,54.9434 2.1519,54.9434 L-0.0001,54.9434 L-0.0001,47.2014 L1.3369,47.2014 C3.8989,47.2014 5.2959,45.8624 5.2959,43.4184 L5.2959,21.9424 L0.8139,21.9424 L0.8139,14.2004 L5.2959,14.2004 L5.2959,12.2214 C5.2959,5.2384 9.2529,1.6294 16.9389,1.6294 L20.2529,1.6294 L20.2529,9.3694 L17.7499,9.3694 C15.1889,9.3694 13.7929,10.7084 13.7929,13.1534"
              id="FLT-Fill-1"
              fill="#120DD1"
            ></path>
            <polygon
              id="FLT-Path"
              fill="#120DD1"
              points="56.729 43.8844 65.226 43.8844 65.226 14.2004 56.729 14.2004"
            ></polygon>
            <path
              d="M55.739,5.1794 C55.739,2.2124 58.008,0.0004 60.978,0.0004 C64.004,0.0004 66.273,2.2124 66.273,5.1794 C66.273,8.2074 64.004,10.4184 60.978,10.4184 C58.008,10.4184 55.739,8.2074 55.739,5.1794 L55.739,5.1794 Z"
              id="FLT-Path"
              fill="#E40D2B"
            ></path>
            <path
              d="M92.0321,29.0431 C92.0321,24.8521 88.9471,21.7091 84.7571,21.7091 C80.6231,21.7091 77.5391,24.8521 77.5391,29.0431 C77.5391,33.2331 80.6231,36.3761 84.7571,36.3761 C88.9471,36.3761 92.0321,33.2331 92.0321,29.0431 M100.5291,43.8841 L92.8471,43.8841 L92.8471,39.5771 C90.5761,42.6621 87.2001,44.5821 83.0681,44.5821 C74.9211,44.5821 68.7501,37.8891 68.7501,29.0431 C68.7501,20.1951 74.9211,13.5021 83.0681,13.5021 C86.7361,13.5021 89.8201,15.0161 92.0321,17.4601 L92.0321,1.9801 L100.5291,1.9801"
              id="FLT-Fill-6"
              fill="#120DD1"
            ></path>
            <path
              d="M127.3983,29.0431 C127.3983,24.8521 124.3143,21.7091 120.1233,21.7091 C115.9893,21.7091 112.9053,24.8521 112.9053,29.0431 C112.9053,33.2331 115.9893,36.3761 120.1233,36.3761 C124.3143,36.3761 127.3983,33.2331 127.3983,29.0431 L127.3983,29.0431 Z M138.8653,35.6771 L138.8653,43.8841 L136.4763,43.8841 C132.2873,43.8841 129.4933,42.4291 128.2133,39.5771 C125.9423,42.6621 122.5663,44.5821 118.4343,44.5821 C110.2873,44.5821 104.1163,37.8891 104.1163,29.0431 C104.1163,20.1951 110.2873,13.5021 118.4343,13.5021 C122.1023,13.5021 125.1863,15.0161 127.3983,17.4601 L127.3983,14.2001 L135.8953,14.2001 L135.8953,33.2921 C135.8953,34.8051 136.7683,35.6771 138.2813,35.6771 L138.8653,35.6771 Z"
              id="FLT-Fill-7"
              fill="#120DD1"
            ></path>
            <path
              d="M52.3251,36.3429 L52.3251,43.8839 L50.4541,43.8839 C42.8611,43.8839 38.9551,39.7119 38.9551,32.7599 C38.9551,30.4599 39.4381,28.0539 40.5601,25.6469 L33.8751,24.7909 L30.2921,43.8839 L22.5901,43.8839 L26.7631,22.8659 C25.2631,21.7959 24.3551,19.9779 24.3551,18.0519 C24.3551,15.1639 26.3881,13.1849 29.4351,13.1849 C32.3241,13.1849 34.3561,15.0039 34.6251,17.4109 L51.4711,19.3349 L51.4711,21.2079 C48.5291,25.2729 46.9781,28.8019 46.9781,31.7979 C46.9781,34.7389 48.4221,36.3429 51.1501,36.3429 L52.3251,36.3429 Z"
              id="FLT-Fill-8"
              fill="#120DD1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FridaLogoText;
