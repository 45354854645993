import { GetDay } from '../../common/DateHandler';

// Matches main structure with values provided by API
const fillGraphValues = (mainStructure, givenValues) => {
  const fixDateGivenValues = givenValues.map((e) => ({ total: e.total, date: GetDay(e.date) }));
  return mainStructure.map((currVal) => {
    const receivedValue = fixDateGivenValues.find((element) => element.date === currVal.date);
    return receivedValue || currVal;
  });
};

/**
 * Creates a set of dates to keep Date labels in graph properly handled
 * @param {Array} allSeries
 * @returns {Array} return all the arrays filled with data
 */
const generateDateStructureLocations = (allOriginsData) => {
  const allDates = allOriginsData
    .map((originData) => originData.informationRetrieved.map((element) => element.date))
    .flat()
    .sort((a, b) => new Date(a) - new Date(b));

  const uniqueLabels = new Set(allDates.map((e) => e));

  // Create a structure to keep track of labels in graph
  const graphValues = [...uniqueLabels].map((date) => ({ total: null, date: GetDay(date) }));

  const filledGraph = allOriginsData.map((serie) => fillGraphValues(graphValues, serie.informationRetrieved));
  return filledGraph;
};

/**
 * Creates a set of dates to keep Date labels in graph properly handled
 * @param {Array} current Recent data
 * @param {Array} last Data from last season
 * @returns {Object} {Array, Array} Object {current, last} of Arrays with all possible labels
 */
const generateDateStructure = (currentData, lastData, currentDate) => {
  // Combine current and last dates into same array and set the currentDate always
  // Reduce currentDate year to make them match with all dates
  const parsedCurrentDate = new Date(currentDate);
  parsedCurrentDate.setFullYear(parsedCurrentDate.getFullYear() - 1);

  let allDates = [
    new Date(parsedCurrentDate).toISOString().split('.')[0] + 'Z',
    ...lastData.map((e) => e.date),
    ...currentData.map(({ date }) => {
      const thisDate = new Date(date);
      // Reduce year in current values to make them match with previous season
      thisDate.setFullYear(thisDate.getFullYear() - 1);
      return thisDate.toISOString().split('.')[0] + 'Z';
    }),
  ];

  // Sort dates (oldest to newest)
  allDates = allDates.sort((a, b) => new Date(a) - new Date(b));
  // generate uniquelabels with parsed data
  const uniqueLabels = new Set(allDates.map((e) => GetDay(e)));
  // Create a structure to keep track of labels in graph
  const graphValues = [...uniqueLabels].map((date) => ({ total: null, date: date }));
  // get the index of the current date
  const indexCurrentDate = graphValues.findIndex((element) => element.date === GetDay(currentDate));

  const current = fillGraphValues(graphValues, currentData);
  const last = fillGraphValues(graphValues, lastData);

  return { current, last, indexCurrentDate };
};
export { generateDateStructureLocations, generateDateStructure };
