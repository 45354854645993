import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getCurrentDateAction } from '../../redux/actions/currentDate/actions';

const useCurrentDate = () => {
  const currentDate = useSelector((state) => state.currentDate.currentDate);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentDate) dispatch(getCurrentDateAction());
  }, [dispatch]);

  return currentDate;
};

export default useCurrentDate;
