const weatherAlertsSelectors = {
  weatherContainer: 'weather-container',
  weatherDescription: 'weather-description',
  weatherTable: 'weather-table',
  weatherRow: 'weather-body-row',
  weatherStatus: 'weather-status',
  weatherGrowingRegion: 'weather-growing-region',
  weatherTableAlertText: 'weather-table-alert-text',
  weatherTableDegressMax: 'weather-table-degress-max',
  weatherTableDegressMin: 'weather-table-degress-min',
  forecastContainer: 'forecast-container',
  warningIcon: 'warning-icon',
  okIcon: 'ok-icon',
};

module.exports = weatherAlertsSelectors;
