/*
 * originSelectData
 * @param {array} crossing
 * @return {array}
 */
const originSelectData = (crossings) =>
  crossings.map((crossing) => ({
    id: crossing.slug,
    label: crossing.name,
    value: crossing.slug,
  }));

export default originSelectData;
