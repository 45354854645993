import { demandEN } from './dynamic/demandTone';
import { marketEN } from './dynamic/marketTone';
import { SPECIAL_CHARACTERS } from './../../constants/validations';

export default {
  loader: {
    loading: 'Loading information...',
  },
  general: {
    contactTeam: 'contact the FRIDA team',
    date: '{{date}}',
    no: 'no',
    yes: 'yes',
  },
  select: {
    selectOption: 'Select',
  },
  fruits: {
    'blkberi-v': 'Blackberries',
    Asparagus: 'Asparagus',
    Blackberries: 'Blackberries',
    Blueberries: 'Blueberries',
    Raspberries: 'Raspberries',
    Strawberries: 'Strawberries',
    asp: 'Asparagus',
    bluby: 'Blueberries',
    rasp: 'Raspberries',
    strby: 'Strawberries',
  },
  usdaPrices: {
    title: 'Shipping point prices and Volume (USDA)',
    paragraph:
      'Check prices and/or volumes daily or weekly. You can filter them by location, type of fruit, and type of packaging.',
    volumePrices: 'Volume and prices',
    volumeLocation: 'Volume',
    priceLocation: 'Price',
  },
  average: {
    graphDescription:
      "Daily reported maximum and minimum prices. You can compare them to last season's average prices and the average price of the last 5 years.",
    priceRange: 'Price range, highest to lowest',
    lastSeasonAvg: 'Last season avg price',
    lastFiveAvg: 'Average price last 5 years',
  },
  volume: {
    title: 'Volume (USDA)',
    description:
      'Check the reported daily volume. You can analyze it by crossing and type of fruit per day or per week. You can also compare the current season, last season, and the calculated volume based on our algorithm. Plus, compare between several variables on a single graph per day or per week.',
    graphDescription:
      "This graph contains 52 weeks so that you can have a complete picture of the the volume's behaviour.",
    btnWeekly: 'Weekly',
    btnDaily: 'Daily',
    btnWeeklyLoc: 'Weekly between variables',
    btnDailyLoc: 'Daily between variables',
    selConventional: 'Conventional',
    selOrganic: 'Organic',
    selAll: 'All',
    currSeason: 'Current Season',
    lastSeason: 'Last Season',
    forecast: "Frida's Forecast",
    previousWeeks: "Frida's forecast for previous weeks",
    range: "Frida's range",
    fridaPrevious: "Frida's forecast for previous weeks",
    pkgUnit: '4.5 lb cases',
    weeklyXAxis: 'Week Number',
    week: 'Week',
  },
  volumeBehavior: {
    calculate: 'Reported movement in the current week compared to data from the same day the previous week.',
    last15Days: 'last 15 days ',
    lastWeek: 'Last week',
    lastYear: 'Last year',
    latestUpdate: 'Latest update:',
    percentCalculate1: 'The displayed percentage is calculated using the ',
    percentCalculate2: 'of information',
    ttCalculate:
      'Reported movement in the current week compared to data from the same day the previous week, as reported by the USDA.',
    volBehaviorTitle: 'Volume Behavior',
    volDetailTitle: 'Volume Detail',
  },
  locations: {
    graphDescription: 'Compare prices between different variables.',
  },
  selectCascaderContainer: {
    applyButton: 'Apply Filters',
    clearButton: 'Clear',
  },
  selectCascader: {
    all: 'ALL',
    conventional: 'CONVENTIONAL',
    entireMarket: 'ENTIRE MARKET',
    location: 'Location',
    organic: 'ORGANIC',
    packageType: 'Package',
    type: 'Type',
  },
  shippingPoint: {
    averageButton: 'Between periods',
    locationButton: 'Between variables',
    paragraph: 'Check daily reported prices. You can filter them by location, type of fruit, and type of packaging.',
    title: 'Shipping point prices (USDA)',
  },
  shippingPointBehavior: {
    calculateMsg: 'This calculation was made using the movement of {{commodity}} at the crossing of',
    daysAgo: 'days ago',
    demand: 'Demand',
    dynamic: { ...demandEN, ...marketEN },
    headerTitle: 'Shipping point behavior',
    highest: 'Highest',
    latestUpdate: 'Latest update:',
    lowest: 'Lowest',
    market: 'Market',
    priceVersus: 'Price vs',
    tablePrice: 'price',
  },
  retail: {
    conventionalLabel: 'Conventional',
    headerDescription: 'Retail prices of organic and conventional fruit per week.',
    headerTitle: 'Retail prices (USDA)',
    organicLabel: 'Organic',
    week: 'Week',
    weekNumber: 'Week number',
    yaxisLabel: 'Retail prices in USD',
  },
  tableFooter: {
    aboutSteady: 'TBD',
    conventionalModerateOrganicVeryGood: 'TBD',
    demandLabel: 'Demanda',
    fairlyLight: 'TBD',
    good: 'TBD',
    higher: 'TBD',
    light: 'TBD',
    marketLabel: 'Mercado',
    moderate: 'TBD',
    slightlyHigher: 'TBD',
    steady: 'TBD',
  },
  error: {
    errorContent:
      'We are having trouble retrieving the market information. Please try again later. If the problem persists, feel free to',
    headerTitle: 'We are having some trouble gathering all the data',
    sendEmail: 'send an email',
    supportTeam: ' to the Frida support team 🤖.',
  },
  weatherAlerts: {
    title: 'Weather Alerts',
    hideAlerts: 'Hide alerts -',
    hideForecast: 'Hide forecast -',
    moreAlerts: 'more alerts +',
    expandForecast: 'Expand forecast',
    status: 'Status',
    region: 'Region',
    condition: 'Weather',
    highest: 'Highest',
    lowest: 'Lowest',
    description: 'Alerts for the next 14 days in the most relevant production areas.',
    descriptionBold: 'Red alerts can have an impact on volume.',
    units: 'Temperature',
    weather: {
      blizzard: 'Blizzard',
      blowingSnow: 'Blowing Snow',
      brokenClouds: 'broken Clouds',
      clear: 'Clear',
      cloudy: 'Cloudy',
      fewClouds: 'Few Clouds',
      fog: 'Fog',
      freezingFog: 'Freezing Fog',
      heavyFreezingDrizzle: 'Heavy freezing drizzle',
      intensityRain: 'heavy intensity rain',
      heavyRain: 'Heavy rain',
      heavyRainAtTimes: 'Heavy rain at times',
      heavySnow: 'Heavy snow',
      icePellets: 'Ice pellets',
      lightDrizzle: 'Light drizzle',
      lightFreezingRain: 'Light freezing rain',
      lightRain: 'Light rain',
      lightRainShower: 'Light rain shower',
      lightSleet: 'Light sleet',
      lightSleetShowers: 'Light sleet showers',
      lightSnow: 'Light snow',
      lightSnowShowers: 'Light snow showers',
      mist: 'Mist',
      moderateOrHeavyRainShower: 'Moderate or heavy rain shower',
      moderateOrHeavyRainWithThunder: 'Moderate or heavy rain with thunder',
      moderateOrHeavySleet: 'Moderate or heavy sleet',
      moderateOrHeavySnowShowers: 'Moderate or heavy snow showers',
      moderateOrHeavySnowWithThunder: 'Moderate or heavy snow with thunder',
      moderateRain: 'Moderate rain',
      moderateRainAtTimes: 'Moderate rain at times',
      moderateSnow: 'Moderate snow',
      overcast: 'Overcast',
      overcastClouds: 'overcast clouds',
      partlyCloudy: 'Partly cloudy',
      patchyHeavySnow: 'Patchy heavy snow',
      patchyLightDrizzle: 'Patchy light drizzle',
      patchyLightRain: 'Patchy light rain',
      patchyLightRainWithThunder: 'Patchy light rain with thunder',
      patchyLightSnow: 'Patchy light snow',
      patchyModerateSnow: 'Patchy moderate snow',
      patchyRainPossible: 'Patchy rain possible',
      patchySleetPossible: 'Patchy sleet possible',
      patchySnowPossible: 'Patchy snow possible',
      scatteredClouds: 'scattered clouds',
      skyIsClear: 'Sky Is Clear',
      snow: 'Snow',
      sunny: 'Sunny',
      thunderyOutbreaksPossible: 'Thundery outbreaks possible',
      torrentialRainShower: 'Torrential rain shower',
    },
  },
  marketInformation: {
    title: 'Intelligence',
    currWeek: 'Current week',
    diffWeekMsg1: 'We are displaying market information for',
    diffWeekMsg2: 'Week',
    reset: "Go back to today's date",
  },
  datePicker: {
    today: 'Today',
  },
  units: {
    box: 'flats',
    kg: 'kilograms',
    lb: 'pounds',
    pallet: 'pallets',
    truck: 'trucks',
    unit: 'unit',
  },
  marketPlace: {
    checkboxLabel: 'Show finished opportunities',
    header: 'Marketplace',
    myOppotunities: 'My opportunities',
    programDetails: 'Details of the opportunity',
    opportunityAvailables: 'Available opportunities',
    selectProgram: 'Select an opportunity',
    welcome: 'Welcome',
  },
  mpDeepLinking: {
    notFoundTitle: 'Opportunity not available',
    notFoundDescription:
      'It seems that the opportunity you are looking for is no longer available; use the list on the left to browse available opportunities.',
    notFoundDescriptionMobile:
      'It seems that the opportunity you are looking for is no longer available; use the <b>“See other opportunities”</b> button to browse available opportunities.',
    notFoundCloseTextMobile: 'See more opportunities',
    notFoundCTATitle: 'Need help?',
    notFoundCTADescription: 'Contact the FRIDA team if you need help with this or any other opportunity.',
    notFoundCTAButtonText: 'Contact FRIDA',
  },
  //TODO: this is labels than need to be abbreviated and translated
  unitsAbbr: {
    box: 'flats',
    kg: 'kg',
    lb: 'lb',
    pallet: 'pallets',
    truck: 'trucks',
    unit: 'unit',
  },
  programsCard: {
    // new program
    amount: 'Amount',
    deliveryDate: 'Date and time of your first delivery',
    presentation: 'Presentation',
    flatOf: 'Flat of {{package_description}}.',
    deliveredDay: 'It must be delivered on: {{date}}',
    estimatedPerBox: 'Per flat of {{package_description}}',
    saleAssigned: 'Sale assigned to me',
    timeRemaining: 'Time remaining to join',
    expiredOffer: 'Expired Opportunity',
    conventional: 'Conventional',
    organic: 'Organic',
    // old program
    amountOf: 'Amount of',
    closeDeliveryTooltipDescription:
      'These opportunities have deadlines to join. After the closing date, you will not be able to participate in the opportunity.',
    closeDeliveryTooltipTitle: 'Closing date of a opportunity',
    closeProgram: 'Opportunity closure',
    created: 'Open to participate',
    destination: 'Destination',
    duration: 'Duration',
    finished: 'finished opportunity',
    firstDelivery: 'First delivery',
    firstDeliveryTootltipDescription:
      'On this date, we will be waiting for your first delivery in the corresponding cooler.',
    firstDeliveryTootltipTitle: 'First installment of the opportunity',
    paused: 'Paused opportunity',
    per: 'per',
    perWeek: 'Per week',
    price: 'Price',
    program: 'Program',
    registeredProducers: 'Registered growers',
    sheduled: 'Scheduled opportunity',
    type: 'Type',
    spot: 'Spot',
    started: 'Opportunity started',
    weeks: 'Weeks',
  },
  mpMarketHeaderCard: {
    currentWeek: 'Current week: ',
    title: 'Marketplace',
    week: 'week',
    statusTooltipWarningTitle: 'Some of your documents are about to expire',
    statusTooltipWarningDescription:
      'Some of your documents expire in less than 30 days; if they expire, you’ll not be able to participate in your opportunities. It will negatively affect your qualification if you cannot fulfill your commitments.',
    statusTooltipWarningDescriptionLinkMore: 'See more',
    statusTooltipErrorTitle: 'Some of your documents have expired',
    statusTooltipErrorDescription:
      'Some of your documents have expired. You need to update them as soon as possible to keep fulfilling your current opportunities and join new opportunities.',
    statusTooltipErrorDescriptionLinkMore: 'See more',
    statusTooltipUnderReviewTitle: 'Some of your documents are pending review',
    statusTooltipUnderReviewDescription:
      'Once the review process is finished, you’ll be able to participate in FRIDA’s marketplace.',
  },
  mpStepper: {
    boxAmount: 'Number of flats',
    deliveryDays: 'Delivery days',
    checkCommitment: 'Check commitment',
  },
  mpBoxProgram: {
    USDC: 'USD per flat',
    USDK: 'USD per Kilogram',
    USDL: 'USD per Pound',
    boxAmount: 'Number of flats',
    boxesOf: 'Flats of',
    cold: 'and cold',
    comissions: 'Frida commissions',
    commitmentMsg: 'Committed flats in other opportunities',
    customExpenses: 'Customs expenses',
    doNotCommit: ' DO NOT commit more than 50% ',
    estimatedPerWeek: 'All estimated returns are for each weekly delivery',
    estimatedReturnPrice: 'Estimated return to grower',
    estimatedReturnUnit: 'Estimated return per unit ',
    fridaFee: 'Frida Fees',
    infoTooltipDescription:
      'The estimated return is after delivery to this location and once all logistics and transportation costs have been covered',
    infoTooltipTitle: 'This is the location where you will make your deliveries',
    inspection: 'Inspection',
    logistics: 'Logistics',
    packagingMaterial: 'Packaging material',
    nationalLogistics: 'National Logistics',
    destinationLogistics: 'Select the destination of delivery *',
    myCommitment: 'I agree to the opportunity with',
    recommendedAmount: 'Number of cases recommended',
    remember: 'Remember: in the graph, you can see how the selected quantity impacts your production.',
    returnProducer: 'Breakdown of the return to grower',
    returnTooltipDescription:
      'This is the estimated total return that you will receive once all deliveries are carried out in a timely manner',
    returnTooltipTitle: 'Return at the end of the opportunity',
    salePrice: 'Sale price',
    selectAmount: 'Select the amount *',
    selectPackaging: 'Packaging material *',
    selectHelpText: '(min. amount)',
    selectTooltipDescription:
      'Committing only a percentage of your production to Marketplace opportunities allows you to offer the remainder in the Spot Market',
    selectTooltipTitle: "Why can't I commit 100%?",
    step1: 'Step 1 of 3',
    step1Label: 'I want to commit to the opportunity with',
    totalProd: 'Your total production captured',
    weRecomend: 'We recommend you',
    weeklyProduction: 'of your total production available for each week.',
    packagingMaterialRequestTitle: 'You don’t have this packaging material registered',
    packagingMaterialRequestDescription:
      'If you are interested in being part of this opportunity and use this packaging material, please get in touch with the FRIDA’s team; they will help you register it in your user account.',
    packagingMaterialRequestCTA: 'Request new packaging material',
  },
  mpCommittedProgram: {
    haveIssue: 'Do you have an issue?',
    helpMessage: 'Contact the FRIDA support team and tell us a little more about how we can help you.',
    programDelivery: 'Scheduled Deliveries',
    opportunityDetails: 'Opportunity details',
    programDocumentation: 'Documentation',
    sendMessage: 'Send Message',
  },
  mpProgramDelivery: {
    title: 'What days should I make my deliveries?',
    weeklyDelivery: 'Weekly deliveries',
  },
  mpProgramDetails: {
    loaderMessage: 'Loading data',
  },
  mpMyProgramDetails: {
    bloomPercentage: 'Bloom percentage',
    box: 'Flats of {{package_description}}',
    boxesPerPallet: 'Flats per pallet',
    calibration: 'Minimum gauge',
    deliverBeforeTime: 'Deliver before',
    deliveryAddres: 'Delivery address',
    deliveryDate: 'Delivery date in {{destination}}',
    deliveryPlace: 'Delivery place',
    deliveryPoint: 'Delivery point',
    deliveryTime: 'Delivery date and time',
    fruitCharacteristics: 'What characteristics should the fruit have?',
    fruitQuality: 'Fruit quality',
    fruitSpecifications: 'Characteristics of the fruit',
    minimumGauge: 'Minimum gauge',
    oneExhibition: 'in 1 delivery',
    opportunityDuration: 'Duration of the opportunity',
    packageCharacteristics: 'What characteristics should the packaging material have?',
    packagingMaterial: 'Packaging material',
    packingMaterial: 'Packing and packaging material',
    packingSelectedMaterial: 'Selected packing material',
    pallet: 'Pallets of {{boxes_pallet}} flats',
    palletType: 'Pallet type',
    phone: 'Phone number',
    placeLabel: 'Place',
    productQuality: 'Fruit quality',
    programDuration: '{{weeks}} weeks opportunity',
    programWeekDuration: '{{weeks}} Weeks',
    ptiLabel: 'PTI label',
    quantityPallets: 'Quantity in Pallets',
    quantityToDeliver: 'Quantity to be delivered',
    saleType: 'Type of sale',
    shouldDeliver: 'What quantity should I deliver?',
    title: 'Opportunity summary',
    totalQuantity: 'Total quantity',
    truck: 'Trucks of {{pallets_truck}} pallets',
    weeklyVolume: 'Weekly volume',
    weeks: 'weeks',
    whereDeliver: 'Where should I make my deliveries?',
  },
  mpEmptyProgramList: {
    description: 'At this time you do not have opportunities available for {{fruit}}',
  },
  mpProgramDocumentation: {
    foodSafety: 'Food safety policy',
    insurancePolicy: 'Insurance policy',
    packingMaterial: 'Packing and packaging material',
    seeFile: 'See document',
    termsAndConditions: 'Terms and conditions agreement',
    title: 'Downloads for the opportunity',
  },
  mpCommitment: {
    bloom: 'Bloom percentage',
    cmtDetail: 'Detail of the commitment to the opportunity',
    conventional: 'Conventional',
    deliveryPlace: 'Delivery will be made to the cooler located in',
    fruit: 'Fruit',
    organic: 'Organic',
    price: 'The agreed price is',
    type: 'Type',
  },
  mpWeekRow: {
    error: 'error',
    pending: 'pending',
    success: 'success',
    warning: 'warning',
    week: 'WEEK',
  },
  mpDetailCommitment: {
    boxDescription: '{{package_description}} flats.',
    boxesPerPallet: 'Flats per pallet',
    commitedVolume: 'Committed volume',
    deliverBeforeTime: 'Deliver before',
    deliveryAddres: 'Delivery address',
    deliveryDate: 'Delivery date in {{destination}}',
    deliveryDates: 'Delivery dates in {{destination}}',
    deliveryDays: 'Delivery days',
    deliveryPlace: 'Delivery point',
    deliveryTime: 'Delivery date and time',
    estimatedReturnProducer: 'Estimated return to grower',
    fruitQuality: 'Fruit quality',
    fruitSpecifications: 'Characteristics of the fruit',
    fruitTitle: 'Fruit',
    minimumGauge: 'Minimum gauge',
    numberOfBoxes: 'Number of flats',
    oneDeliveryLabel: 'For each delivery',
    opportunityDuration: 'Duration of the opportunity',
    packingMaterial: 'Packing and packaging material',
    packingSelectedMaterial: 'Selected packing material',
    palletType: 'Pallet type',
    phone: 'Phone number',
    placeLabel: 'Place',
    presentation: 'Presentation',
    price: 'Price',
    prices: 'Prices',
    programWeekDuration: '{{weeks}} Weeks',
    ptiLabel: 'PTI label',
    quantityBoxes: 'Quantity in flats of',
    quantityPallets: 'Quantity in Pallets',
    relatedDocuments: 'Related documents',
    requirePTI: 'Requires PTI tag',
    salePrice: 'Sale price',
    saleTotalProgram: 'Total sale price',
    saleTotalWeekly: 'Weekly sale price',
    step2: 'Step 2 of 3',
    step2ProgramLabel: 'Detail of commitment to the opportunity',
    tipDescription: "If you need help validating your PTI label or are interested in knowing the buyer's label format,",
    tooltipDescription:
      'This is the estimated total return that you will receive once all deliveries are carried out in a timely manner',
    tooltipTitle: 'Return at the end of the opportunity',
    totalBoxes: 'Total flats',
    usdPerBox: 'Per flats of',
    weeklyDelivery: 'By weekly delivery',
    weeklyDeliveryAmout: '{{weeks}} weekly deliveries',
  },
  mpFlowButtons: {
    close: 'Close',
    commit: 'Accept agreement',
    next: 'Next →',
    return: '← Back',
  },
  Message: {
    accept: 'Accept',
    cancel: 'Cancel',
    leave: 'Leave the opportunity',
    leaveProgramMessageTitle: 'You are about to leave the commitment process',
    leaveProgramMessageDescription:
      'You are about to leave the opportunity. Your selected amount and your space in the opportunity will be deleted if you choose "Leave the opportunity." If you do not want to leave, click, "Stay here"',
    stay: 'Stay here',
    acceptUpdateFile: 'Update approved document',
    updateFileDescription:
      'Remember that any new document will have to go through the review process again. If you update an approved document, you will not be allowed to participate in opportunities until the new document has been approved.',
    updateFileTitle: 'Are you sure you want to update your document?',
  },
  errorStatusDescriptions: {
    boxesOverLimit:
      'The selected amount exceeds the available volume. Use the dropdown to adjust the volume and continue the process to join the opportunity.',
    destinationNotFound: 'Destination not found.',
    notProducerMember: 'The user is not a member of the grower.',
    producerAlreadyCommitedProgram: 'The grower has already committed to the opportunity.',
    producerNotValidated: 'The grower is not validated.',
    programNotFound: 'Opportunity not found.',
  },
  errorStatusTitle: {
    boxesOverLimit: 'You cannot commit that amount.',
    destinationNotFound: 'Something went wrong',
    generalError: 'Something went wrong',
    notProducerMember: 'Something went wrong',
    producerAlreadyCommitedProgram: 'Something went wrong',
    producerNotValidated: 'Something went wrong',
    programNotFound: 'Something went wrong',
  },
  projectionGraph: {
    harvest: 'Harvest',
    title: 'Harvest curve',
    volume: 'Volume',
  },
  selectProgram: {
    description: 'Select one of the opportunities from the list',
  },
  mpRowCalendar: {
    tooltipTitle: 'These are the delivery days recommended by FRIDA',
    tooltipDescription: 'Contact the FRIDA team If you need to make your deliveries on a different day',
  },
  mpConfirmCommitment: {
    acceptContract: 'Accept the agreement',
    certificatesPresented: 'and that the certificates presented by me correspond to the committed harvest.',
    confirmError: 'To continue, you must read and accept the terms and conditions.',
    confirmIhaveRead: 'I confirm that I have read and understand everything stipulated in it',
    estimatedTotalReturn: 'Estimated total return',
    pressButton: 'By pressing the button',
    programDuration: 'Duration of the opportunity',
    programOverview: 'Opportunity overview',
    signContract: ' "Accept the agreement" ',
    step3: 'Step 3 of 3',
    step3LabelProgram: 'Commit to the opportunity',
    step3LabelSpot: 'Commit to the spot sale',
    toastMessage: 'Review the details of your opportunity and remember that your first delivery is on:',
    toastTitle: 'You are already part of this opportunity!',
    termsAndConditions: 'Terms and conditions',
    weeklyVolume: 'Weekly volume',
    weeks: 'Weeks',
  },
  mpProgress: {
    commited: 'Commited {{unit}}',
  },
  mpWelcomeMessage: {
    message: 'Review the details of your opportunity and remember that your first delivery is the day: {{date}}',
    title: 'You are already part of this opportunity!',
    accept: 'Accept',
  },
  mpVolumeAndPrices: {
    applyButton: 'Apply',
    chartData: 'Chart data',
    conventional: 'Conventional',
    crossingPoint: 'Crossing point',
    daily: 'Daily',
    daysToShow: 'Date range',
    endingDay: 'Ending day',
    endingWeek: 'Ending week',
    extraYears: 'Selected years',
    fridaForecast: 'Frida forecast',
    movementSerie: 'Volume',
    noData: 'We are sorry. There was no data',
    onlyPrices: 'Only prices',
    onlyVolume: 'Only volume',
    organic: 'Organic',
    origins: 'Origin',
    packageType: 'Package',
    pastFridaForecast: "Frida's forecast for previous weeks",
    prevYears: 'Years',
    priceSerie: 'Price',
    pricesInUsd: 'Prices in Usd',
    selectCheckbox: 'Select',
    selectData: 'Select a year',
    startingDate: 'Staring date',
    startingDay: 'Starting day',
    startingWeek: 'Starting week',
    timeAndPeriod: 'Time and period',
    type: 'Type',
    viewAll: 'All',
    volumeAndPrices: 'Volume and prices',
    weekly: 'Weekly',
  },
  months: {
    april: 'April',
    august: 'August',
    december: 'December',
    february: 'February',
    january: 'January',
    july: 'July',
    june: 'June',
    march: 'March',
    may: 'May',
    november: 'November',
    october: 'October',
    september: 'September',
  },
  graphNoData: {
    title: 'No information available',
    filtersDescriptions:
      'The selected filter combination has no information to show, try changing the origin or year in which you are doing the search.',
  },
  mpCommitmentResume: {
    conventional: 'Conventional',
    estimatedTotalReturn: 'Estimated total return',
    flatPrice: 'Selling price per flat',
    oneExhibition: 'One exhibition',
    organic: 'Organic',
    program: 'Program',
    returnPerKg: 'Return per kg',
    saleType: 'Type of sale',
    title: 'Sale summary',
    totalQuantity: 'Total quantity',
    totalSale: 'Total sale',
    type: 'Type',
    week: 'Weeks',
  },
  management: {
    title: 'Management',
    logout: 'Logout',
    userOptions: {
      title: 'User options',
    },
    emptyStateMessage: 'Select one of the options from the left',
  },
  mgOrchards: {
    listTitle: 'Orchards certificates',
    alertTag: '{{alerts}} Alerts',
  },
  mgOrchardsDetails: {
    certificates: 'Certificates',
    title: 'Food safety',
    introText:
      'In this section, you can upload your food safety certificates that correspond with each orchard. Remember to keep those documents updated and approved to keep doing commits on new opportunities.',
    maxFileSizeText: 'Maximum file size',
    supportedFormats: 'Supported formats',
    certificate: 'Orchards certificates',
    corrective_action_report: 'Corrective Action Report',
    pesticide_analysis: 'Pesticide Analysis',
    audit_report: 'Audit Report',
    field_questionnaire: 'Field Questionnaire',
    microbiological_analysis: 'Microbiological Analysis',
    sketch_map: 'Sketch Map',
    status: {
      pending: 'Pending',
      rejected: 'Rejected',
      approved: 'Approved',
      prompt_expiration: 'Next to expire',
      expired: 'Expired',
    },
    noDataMessage: 'There is no document related to this orchard.',
    expiresIn: 'Expiration date',
    size: 'Size',
    loadingMessage: 'Loading document',
    uploadSuccess: {
      title: 'Your document has been uploaded successfully',
      message:
        'We will review your document. Once everything is ready, you can participate again in the FRIDA Marketplace.',
    },
    uploadError: {
      title: 'We couldn’t upload your file',
      message: 'It looks like there was a problem with your file. Please try again.',
    },
  },
  documentUpload: {
    uploadFile: 'Upload file',
    update: 'Update',
    maxFileSizeMessage: 'The size of your file should not be more than {{size}}',
  },
  loginModal: {
    welcomeTo: 'Welcome to',
    heading: 'FRIDA’s Marketplace',
    description:
      'Marketplace is where you can place opportunities (if you are a buyer), and you can also participate in opportunities by selling your fruit (if you are a producer).',
    loginAsProducer: 'Log in as a producer',
    email: 'Email',
    password: 'Password',
    errorLoginMessage: 'Email or password used is not valid',
    loginButtonText: 'Log in',
    backButtonText: 'Back to Intelligence',
    backButtonTextShort: 'Go to Intelligence',
    interestedInTitle: 'Are you interested in a FRIDA account?',
    interestedInDescription: 'Send us your details, and we will contact you',
    interestedInCTA: 'I want to know more',
    loginAsMarketerTitle: 'Do you want to log in as a trusted vendor?',
    loginAsMarketerButton: 'Log in as a trusted vendor',
    forgotPassword: 'Forgot password?',
  },
  modalResetPassword: {
    heading: 'Enter your email',
    description: 'Enter the email used to create your account',
    error: 'Something went wrong. Please try again later',
    email: 'Email',
    formActionButton: 'Recover password',
    formActionCancel: 'Cancel',
    successHeading: 'Check your email',
    successDescription: 'We have sent you an email to {{email}}',
    successActionButton: 'Return to login',
  },
  modalResetPasswordConfirm: {
    heading: 'Create Password',
    description: 'Your password must have 8 characters, an uppercase, a lower case, a number, and a special character',
    newPassword: 'New password',
    reNewPassword: 'Confirm password',
    formActionButton: 'Create password',
    formActionCancel: 'Cancel',
    error: 'Something went wrong. Please try again later',
    errorClientNotMatch: 'The new password and its confirmation must match',
    errorClientInvalidPassword: `Your password must include the following security requirements:
      • Minimum 8 characters
      • At least one special character: ${SPECIAL_CHARACTERS}
      • One upper case and one lower case letter
      • One number
    `,
    successHeading: 'You are all set',
    successDescription: 'Your password has been updated successfully. Now, you can log in by clicking the button.',
    successActionButton: 'Return to login',
    invalidLinkTitle: 'Invalid Link',
    invalidLinkDescription: 'It seems that the link used to create a new password is no longer valid.',
    invalidLinkActionButton: 'Request new password',
  },
  dialogProducerExpiredDocuments: {
    title: 'Some of your documents have expired',
    description:
      'Some of your documents have expired. And you need to update them as soon as possible to be able to <b>continue fulfilling your current commitments and to join new opportunities</b>',
    okButtonText: 'Update my documents',
    cancelButtonText: 'I do not want to update',
  },
};
