import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toggleSwitchSelectors from '../utils/page_definition/toggle_switch';
import './ToggleSwitch.scss';

/*
 * toggle switch component
 * @param {string} label label component
 * @param {string} trueValue true value label
 * @param {boolean} disabled
 * @param {string} falseValue false value label
 * @param {string} styleClass custom class to add extra styles for true/false label
 * @param {func} inheritHook father function to manage the value state
 * @return {React.element}
 */
const ToggleSwitch = ({ label, disabled, trueValue, falseValue, styleClass, inheritHook }) => {
  const [activeLabel, setActiveLabel] = useState(true);

  /*
   * handler to manage the onchange from the input "checkbox"
   * and send the activeValue to the father
   */
  const handlerOnChange = () => {
    inheritHook(!activeLabel ? trueValue : falseValue);
    setActiveLabel(!activeLabel);
  };

  /*
   * method to handle the active value styles
   * @param {string} label
   * @return {string} the active class name
   */
  const handlerActiveLabel = (label) => (label === activeLabel ? 'toggle-switch-active-label' : '');

  return (
    <div data-test={`${toggleSwitchSelectors.toggleContainer}`} className={`toggle-switch-container ${styleClass}`}>
      <h4 data-test={`${toggleSwitchSelectors.toggleLabel}`} className="toggle-switch-title">
        {label}
      </h4>
      <p
        data-test={`${toggleSwitchSelectors.toggleTrueValue}`}
        className={`toggle-switch-true-value ${styleClass} ${handlerActiveLabel(true)}`}
      >
        {trueValue}
      </p>
      <label data-test={`${toggleSwitchSelectors.toggleInputContainer}`} className="toggle-switch">
        <input id="toggle-switch-input" onChange={handlerOnChange} type="checkbox" disabled={disabled} />
        <span
          data-test={`${toggleSwitchSelectors.toggleInput}`}
          className={`toggle-switch-slider slider-round disabled-${disabled}`}
        />
      </label>
      <p
        data-test={`${toggleSwitchSelectors.toggleFalseValue}`}
        className={`toggle-switch-false-value ${styleClass} ${handlerActiveLabel(false)}`}
      >
        {falseValue}
      </p>
    </div>
  );
};

ToggleSwitch.defaultProps = {
  styleClass: '',
  disabled: false,
};

ToggleSwitch.propTypes = {
  styleClass: PropTypes.string,
  disabled: PropTypes.bool,
  falseValue: PropTypes.string.isRequired,
  inheritHook: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  trueValue: PropTypes.string.isRequired,
};

export default ToggleSwitch;
