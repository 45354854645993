import { GetDay } from '../common/DateHandler';
/**
 * pointFormat to overrite the arearange tooltip label.
 */
const averagePointFormat = {
  pointFormat:
    '<span style="color:{point.color}">●</span> {series.name}: <b style="margin-left: 5px">{point.high} - {point-low}</b><br/>',
};
const areaFormat = (name, data) => {
  const baseArray = [];
  data.forEach((element) => {
    baseArray.push([GetDay(element.report_date), element.lowest_price, element.highest_price]);
  });
  return {
    name,
    data: baseArray,
    zIndex: 0,
    fillColor: '#F9C9171A',
    lineColor: '#F9C917',
    tooltip: averagePointFormat,
    type: 'arearange',
  };
};

const lineFormat = (name, data, field) => {
  const baseArray = [];
  data.forEach((element) => {
    if (element[field] > 0) baseArray.push([GetDay(element.report_date), element[field]]);
    else baseArray.push([GetDay(element.report_date), null]);
  });
  return { name, data: baseArray, dashStyle: 'shortdot' };
};

/**
 * @param {string} name line name
 * @param {string} type [area, lastSeason, lastYears]
 * @param {Array} dataArray graph data
 * @return graph parsed data
 */
const AveragePriceSerie = (name, type, dataArray) => {
  if (typeof name === 'string' && Array.isArray(dataArray)) {
    if (type === 'area') {
      return areaFormat(name, dataArray);
    }
    if (type === 'lastSeason') {
      return lineFormat(name, dataArray, 'last_season_price');
    }
    if (type === 'lastYears') {
      return lineFormat(name, dataArray, 'last_five_years_price');
    }
    if (type === 'average') {
      return lineFormat(name, dataArray, 'price');
    }
  }
  return { name: '', data: [] };
};

export default AveragePriceSerie;
