const profileDefinitions = {
  profileInfoCheckBadge: 'profile-info-check-badge',
  profileInfoWarningBadge: 'profile-info-warning-badge',
  profileInfoErrorBadge: 'profile-info-error-badge',
  profileInfoContainer: 'profile-info-container',
  profileInfoNoPhotoIcon: 'profile-info-no-photo-icon',
  profileInfoPhotoImage: 'profile-info-photo-image',
};

export default profileDefinitions;
