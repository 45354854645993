import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Options from './options';
import Header from './header';
import OrchardsLists from './options/orchards';
import ManagementDetails from './details';
import WebStorage from '../../../utils/WebStorage/WebStorage';
import { setActiveProductAction } from '../../../redux/actions/products/actions';
import { Settings } from '../../../assets/icons/assets';

const Management = () => {
  const productActive = useSelector((state) => state.products.active);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('management');

  useEffect(() => {
    if (!productActive) {
      dispatch(setActiveProductAction('rasp'));
    }
  }, [dispatch, productActive]);

  const handleLogout = (e) => {
    e.preventDefault();
    WebStorage.deleteCookie('access_token');
    WebStorage.deleteCookie('refresh_token');
    WebStorage.deleteCookie('user_photo');
    history.push('/');
  };

  return (
    <div className="grid-2-columns__fixed">
      <div className="grid-fixed__left df fdc">
        <Header title={t('title')} icon={<Settings />} />
        <div className="f1">
          <Options>
            <OrchardsLists />
          </Options>
        </div>
        <div className="pb-3xl">
          <h5 className="option-list__header__title pb-m">{t('userOptions.title')}</h5>
          <button className="option-list__button__container warning-true mb0" onClick={handleLogout}>
            <span className="option-list__button__label red-900">{t('logout')}</span>
          </button>
        </div>
      </div>
      <div className="grid-fixed__right">
        <ManagementDetails />
      </div>
    </div>
  );
};

export default Management;
