import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NoData from '../../../../components/NoData';
import usePrevious from '../../../../hooks/usePrevious';
import UtilsApi from '../../../../utils/api/UtilsApi';
import WebStorage from '../../../../utils/WebStorage/WebStorage';
import isEmptyObject from '../../../../utils/common/isEmptyObject';
import isNumber from '../../../../utils/product/isNumberProductData';
import shippingPointBehaviorSelector from '../../../../utils/page_definition/shipping_point_behavior';
import stringHandlers from '../../../../utils/common/stringHandlers';
import { formatHumanDate } from '../../../../utils/common/DateHandler';
import { renderTableVersus, tableFooter, crossingMessage } from '../../../../utils/Table/TableUtils';
import './ShippingPointBehavior.scss';

/**
 * @prop {object} infoMock not required.
 */
const ShippingPointBehavior = ({ infoMock = {} }) => {
  const location = useSelector((state) => state.shippingPoint.Locations);
  const selectedDate = useSelector((state) => state.selectedDate.date);
  const activeProduct = useSelector((state) => state.products.active);

  const [shippingPointBehavior, setShippingPointBehavior] = useState(infoMock);
  const [error, setError] = useState();
  const prevLocation = usePrevious(location);

  // i18n
  const { t } = useTranslation(['shippingPointBehavior', 'general']);
  const { getCookieValue } = WebStorage;
  const localeID = getCookieValue('localeId');

  const { toKeyFormat } = stringHandlers;

  let date = '',
    market = '',
    demand = '';

  const stringHandler = (string) => toKeyFormat(string.toLowerCase()).replace(/\.|,/g, '');

  if (isEmptyObject(shippingPointBehavior)) {
    const { market_tone, demand_tone } = shippingPointBehavior.data;
    date = shippingPointBehavior.date;
    if (market_tone && demand_tone) {
      market = t(`dynamic.${stringHandler(market_tone)}`).toUpperCase();
      demand = t(`dynamic.${stringHandler(demand_tone)}`).toUpperCase();
    }
  }

  // use effect to call the util api for the backend request
  useEffect(() => {
    const CancelToken = UtilsApi.cancelToken();
    const getShippingPointBehavior = () => {
      setError();
      // clean the error hook
      UtilsApi.commodityPrices(activeProduct, location.value, selectedDate, CancelToken.token)
        .then((res) =>
          setShippingPointBehavior({
            data: res.data,
            date: t('general:date', { date: formatHumanDate(res.data.report_date, localeID) }),
          }),
        )
        .catch((err) => {
          if (!UtilsApi.isCancel(err)) {
            setShippingPointBehavior([]);
            setError(err);
          }
        });
    };
    if (!isEmptyObject(infoMock) && location.value && location !== prevLocation) getShippingPointBehavior();

    return function () {
      CancelToken.cancel();
    };
  }, [infoMock, activeProduct, location, selectedDate]);

  useEffect(() => {}, [error]);

  /**
   * method to manage the table body render
   * @return {React.Element} return the table body row.
   */
  const renderTable = () => {
    if (isEmptyObject(shippingPointBehavior)) {
      const { highest_price, lowest_price, price_vs_3_days_ago, price_vs_7_days_ago } = shippingPointBehavior.data;
      return (
        <>
          <table
            data-test={shippingPointBehaviorSelector.shippingPointBehaviorTable}
            className="shipping-point-behavior-table table-desktop"
          >
            <thead className="shipping-point-behavior-table-head">
              <tr className="shipping-point-behavior-table-head-row">
                <th className="table-highest-price">
                  <strong>{`${t('highest')} ↑`}</strong>
                </th>
                <th className="table-lowest-price">
                  <strong>{`${t('lowest')} ↓`}</strong>
                </th>
                <th className="table-price-vs-3-days">
                  {t('priceVersus')} <strong>3 {t('daysAgo')}</strong>
                </th>
                <th className="table-price-vs-7-days">
                  {t('priceVersus')} <strong>7 {t('daysAgo')}</strong>
                </th>
              </tr>
            </thead>
            <tbody className="shipping-point-behavior-table-body">
              <tr
                data-test={shippingPointBehaviorSelector.shippingPointBehaviorRow}
                className="shipping-point-behavior-table-body-row"
              >
                <td
                  data-test={shippingPointBehaviorSelector.shippingPointBehaviorHighestPrice}
                  className="shipping-point-behavior-table-price"
                >
                  {isNumber.toFloat(highest_price, '')}
                </td>
                <td
                  data-test={shippingPointBehaviorSelector.shippingPointBehaviorLowestPrice}
                  className="shipping-point-behavior-table-price"
                >
                  {isNumber.toFloat(lowest_price, '')}
                </td>
                <td data-test={shippingPointBehaviorSelector.shippingPointBehaviorPrice3DaysLabel}>
                  {renderTableVersus(
                    price_vs_3_days_ago,
                    shippingPointBehaviorSelector.shippingPointBehaviorPrice3Days,
                  )}
                </td>
                <td data-test={shippingPointBehaviorSelector.shippingPointBehaviorPrice7DaysLabel}>
                  {renderTableVersus(
                    price_vs_7_days_ago,
                    shippingPointBehaviorSelector.shippingPointBehaviorPrice7Days,
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="table-responsive shipping-point-dual-table">
            <table
              data-test={shippingPointBehaviorSelector.shippingPointBehaviorTable}
              className="shipping-point-behavior-table table-responsive"
            >
              <thead className="shipping-point-behavior-table-head">
                <tr>
                  <th className="table-highest-price">
                    <strong>{t('highest')}</strong> {t('tablePrice')} ↑
                  </th>
                  <th className="table-lowest-price">
                    <strong>{t('lowest')}</strong> {t('tablePrice')} ↓
                  </th>
                </tr>
              </thead>
              <tbody className="shipping-point-behavior-table-body">
                <tr>
                  <td
                    data-test={shippingPointBehaviorSelector.shippingPointBehaviorHighestPrice}
                    className="shipping-point-behavior-table-price"
                  >
                    {isNumber.toFloat(highest_price, '')}
                  </td>
                  <td
                    data-test={shippingPointBehaviorSelector.shippingPointBehaviorLowestPrice}
                    className="shipping-point-behavior-table-price"
                  >
                    {isNumber.toFloat(lowest_price, '')}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table-responsive">
              <thead className="shipping-point-behavior-table-head">
                <tr>
                  <th className="table-price-vs-3-days">
                    {t('priceVersus')} <strong>3 {t('daysAgo')}</strong>
                  </th>
                  <th className="table-price-vs-7-days">
                    {t('priceVersus')} <strong>7 {t('daysAgo')}</strong>
                  </th>
                </tr>
              </thead>
              <tbody className="shipping-point-behavior-table-body">
                <tr>
                  <td>
                    {renderTableVersus(
                      price_vs_3_days_ago,
                      shippingPointBehaviorSelector.shippingPointBehaviorPrice3Days,
                      'spb-price_vs_3_days_ago',
                    )}
                  </td>
                  <td>
                    {renderTableVersus(
                      price_vs_7_days_ago,
                      shippingPointBehaviorSelector.shippingPointBehaviorPrice7Days,
                      'spb-price_vs_7_days_ago',
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    } else return <NoData error={error} />;
  };

  /**
   *  method that creates the information string that mention from/to places according to commodity
   *  @return {React.Element}
   */
  const renderCrossingMessage = () => {
    if (isEmptyObject(shippingPointBehavior)) {
      const { commodity, crossing } = shippingPointBehavior.data;
      return crossingMessage(t('calculateMsg', { commodity: t(`fruits:${commodity}`) }), crossing);
    }
    return '';
  };

  return (
    <div
      data-test={shippingPointBehaviorSelector.shippingPointBehaviorContainer}
      className="shipping-point-behavior-container"
    >
      <div className="shipping-point-behavior-internal-container freshboard-table-internal-container">
        <div
          data-test={shippingPointBehaviorSelector.shippingPointBehaviorHeader}
          className="shipping-point-behavior-header"
        >
          <div className="shipping-point-behavior-header-title">
            <h3>{t('headerTitle')}</h3>
          </div>
        </div>

        <hr className="dashboard-divisor shipping-point-behavior-divisor" />
        <div className="shipping-point-behavior-content">
          {renderTable()}
          <div
            data-test={shippingPointBehaviorSelector.shippingPointBehaviorFooter}
            className="shipping-point-behavior-footer"
          >
            {renderCrossingMessage()}
          </div>
        </div>
        <hr className="dashboard-divisor shipping-point-behavior-divisor" />
        <div>{tableFooter(date, demand, market, t('latestUpdate'), t('demand'), t('market'))}</div>
      </div>
    </div>
  );
};

ShippingPointBehavior.propTypes = {
  infoMock: PropTypes.object,
};

export default ShippingPointBehavior;
