import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SelectArrowDown, SelectArrowUp } from '../assets/icons/assets';
import { setShippingPointAction, setProductType, setPackageType } from '../redux/actions/shippingPoint/actions';
import { setVolumeReportAction } from '../redux/actions/volumeReport/actions';
import PropTypes from 'prop-types';
import './FilterSelect.scss';

import {
  selectMenuContainer,
  selectMenuMainButton,
  selectMenuText,
  selectMenuFloatingCard,
  selectMenuOption,
} from '../utils/page_definition/select_menu';

const FilterSelect = ({
  actionToExecute,
  data,
  id,
  setPackageType,
  setProductType,
  setSelectedData,
  setShippingPointAction,
  setVolumeReportAction,
}) => {
  const [modal, setModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState({ label: 'Select Option' });
  const [optionsList, setOptionsList] = useState();

  // Object to select with state will be set when using this Select
  const allActions = {
    shippingPoint: setShippingPointAction,
    volumeReport: setVolumeReportAction,
    productType: setProductType,
    packageType: setPackageType,
  };

  // This is basically a callback
  const action = allActions[actionToExecute];

  // adding event listener to hide the select and cleanup when is unmounted
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // adding the option list and set the first object as default
  useEffect(() => {
    if (data.length) {
      setOptionSelected(data[0]);
      setOptionsList(data);
    } else {
      setOptionSelected({ label: 'Select Option' });
      setOptionsList([]);
    }
  }, [data]);

  // method to handle the outside click
  const handleClickOutside = (e) => {
    if (
      !e.target.classList.contains(`sl-filter-floating-modal-container-${id}`) &&
      !e.target.classList.contains(`sl-filter-floating-options-${id}`) &&
      !e.target.classList.contains(`sl-filter-floating-custom-option-${id}`) &&
      !e.target.classList.contains(`filter-select-component-button-${id}`) &&
      !e.target.classList.contains(`filter-select-component-button-text-${id}`)
    ) {
      setModal(false);
    }
  };

  // method to handle the floating modal
  const handleFlMenu = () => {
    setModal((prevState) => !prevState);
  };

  // method to handle the option click
  const handleOptionClick = (e) => {
    const target = e.target.getAttribute('data-value');
    const extractedValue = optionsList.find((e) => e.value === target);
    setOptionSelected(extractedValue);
    // This is the setHookFather call
    setSelectedData(extractedValue.value);
    if (actionToExecute) action(extractedValue);
    setModal(false);
  };

  // render list menu, with value and label for text
  const renderListMenu = () => {
    if (modal && optionsList.length)
      return (
        <div data-test={selectMenuFloatingCard} className={`sl-filter-floating-modal-container-${id}`}>
          <ul className={`sl-filter-floating-options-${id}`}>
            {optionsList.map((option) => (
              <li
                data-test={`${selectMenuOption}-${option.id}`}
                className={`sl-filter-floating-custom-option-${id} sl-filter-floating-option-selected-${
                  optionSelected.id === option.id
                }`}
                data-value={option.value}
                key={option.id}
                onClick={handleOptionClick}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      );
    return '';
  };

  // render the arrow icon depends if is active or no the modal floating
  const renderButtonIcon = () => {
    if (modal) return <SelectArrowUp styleClass="filter-select-button-arrow-icon" />;
    return <SelectArrowDown styleClass="filter-select-button-arrow-icon" />;
  };

  return (
    <div data-test={selectMenuContainer} className="filter-select-component-container">
      <button
        data-test={selectMenuMainButton}
        className={`select-component-button-${id} filter-select-component-button-active-${modal}`}
        onClick={handleFlMenu}
      >
        <p data-test={selectMenuText} className={`filter-select-component-button-text-${id}`}>
          {optionSelected.label}
        </p>
        {renderButtonIcon()}
      </button>
      {renderListMenu()}
    </div>
  );
};

FilterSelect.defaultProps = {
  actionToExecute: undefined,
  data: [],
};

FilterSelect.propTypes = {
  actionToExecute: PropTypes.string,
  data: PropTypes.array,
  id: PropTypes.string.isRequired,
  setPackageType: PropTypes.func.isRequired,
  setProductType: PropTypes.func.isRequired,
  setSelectedData: PropTypes.func.isRequired,
  setShippingPointAction: PropTypes.func.isRequired,
  setVolumeReportAction: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  setShippingPointAction,
  setVolumeReportAction,
  setProductType,
  setPackageType,
})(FilterSelect);
