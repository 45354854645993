import React from 'react';
import Router from '../Router';
import './MainLayout.scss';

const MainLayout = () => {
  return (
    <div className="main-layout">
      <Router />
    </div>
  );
};

export default MainLayout;
