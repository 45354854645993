import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Portal from 'lib/Portal';
import Message from 'components/Message';
import useProfileData from 'hooks/Profile/useProfileData';
import useWebStorage from 'hooks/useWebStorage';
import { ROUTES } from 'constants/routes';
import { PRODUCER_STATUS, PRODUCER_STATUS_MAP } from 'constants/user';

const DialogProducerExpiredDocument = () => {
  const { producer } = useProfileData();
  const [expiredDialogDismissed, setExpiredDialogDismissed] = useWebStorage('expiredDialogDismissed', false);
  const { t } = useTranslation('dialogProducerExpiredDocuments');
  const history = useHistory();
  const [showExpiredDialog, setShowExpiredDialog] = useState(false);

  useEffect(() => {
    if (PRODUCER_STATUS_MAP[producer?.status] === PRODUCER_STATUS.ERROR) {
      setShowExpiredDialog(expiredDialogDismissed === false);
    }
  }, [producer, expiredDialogDismissed]);

  if (showExpiredDialog === false) {
    return null;
  }

  return (
    <Portal>
      <Message
        type="confirm"
        title={t('title')}
        description={<Trans t={t} i18nKey="description" components={{ b: <b /> }} />}
        acceptText={t('okButtonText')}
        acceptButtonType="secondary"
        cancelText={t('cancelButtonText')}
        cancelButtonType="destructive"
        handleMessage={(action) => {
          setExpiredDialogDismissed(true);
          if (action) {
            history.push(ROUTES.MANAGEMENT);
          }
        }}
      />
    </Portal>
  );
};

export default DialogProducerExpiredDocument;
