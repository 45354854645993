import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../../../../../components/Button';
import Checkbox from '../../../../../components/Checkbox';
import Message from '../../../../../components/Message';
import Toast from '../../../../../components/Toast';
import confirm_commitment_definitions from '../../../../../utils/page_definition/marketplace/confirmCommitment';
import errorDictionary from '../../../../../utils/errorDictionary/errorDictionary';
import WebStorage from '../../../../../utils/WebStorage/WebStorage';
import { formatHumanDate } from '../../../../../utils/common/DateHandler';
import utilsApi from '../../../../../utils/api/UtilsApi';
import { getProgramsAction, cleanProgramsAction } from '../../../../../redux/actions/programs/actions';
import { setConfirmProgram } from '../../../../../redux/actions/confirmProgram/actions';
import './ConfirmCommitment.scss';

const ConfirmCommitment = ({
  commitedBoxes,
  getProgramsAction,
  programData,
  selectedDestination,
  selectedPackagingMaterial,
  selectedProgram,
  cleanProgramsAction,
  setConfirmProgram,
  testMode,
}) => {
  const [checkboxState, setCheckboxState] = useState(false);
  const [confirmToContinueState, setConfirmToContinueState] = useState(false);
  const [errorStatusCode, setErrorStatusCode] = useState();
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const localeID = WebStorage.getCookieValue('localeId');

  const { start_date, program_type } = programData;
  const {
    confirmCommitmentCheckbox,
    confirmCommitmentConfirmButton,
    confirmCommitmentConfirmToContinue,
    confirmCommitmentContainer,
  } = confirm_commitment_definitions;

  useEffect(() => {
    setConfirmProgram(true);
  }, []);

  const { createCommitment } = utilsApi;

  const { t } = useTranslation([
    'mpFlowButtons',
    'errorStatusDescriptions',
    'erorrStatusTitle',
    'mpConfirmCommitment',
    'units',
  ]);

  const handleCheckbox = (checkboxState) => {
    setCheckboxState(checkboxState);
  };

  const renderCheckBox = () => (
    <Checkbox
      dataTest={confirmCommitmentCheckbox}
      handleCheckboxChange={handleCheckbox}
      styleClass={`error-${confirmToContinueState}`}
      value="confirm-cmtmnt-checkbox"
    />
  );

  // method to create the commitment
  const createCommitmentRequest = async () => {
    try {
      const res = await createCommitment(
        commitedBoxes,
        selectedProgram,
        selectedPackagingMaterial,
        selectedDestination,
      );
      if (res.status === 201) {
        setConfirmProgram(false);
        setShowWarningMessage(false);
        getProgramsAction();
        cleanProgramsAction();

        Toast.success({
          title: t('mpConfirmCommitment:toastTitle'),
          message: (
            <>
              {t('mpConfirmCommitment:toastMessage')}
              <br />
              <span className="fw700">{formatHumanDate(start_date, localeID)}</span>
            </>
          ),
        });
      }
    } catch (err) {
      setShowWarningMessage(true);
      setErrorStatusCode(err.response.data.errors.code);
    }
  };

  const handleOnConfirm = () => {
    if (checkboxState && selectedProgram && !testMode) {
      createCommitmentRequest();
    } else setConfirmToContinueState(true);
  };

  /* method to handle the message buttons actions
   * @param {boolean} action if true set the message to false and current moved to previous view
   */
  const handleMessageAction = (action) => {
    if (action) {
      setConfirmProgram(false);
    }
    setShowWarningMessage(false);
  };

  // method to render warning message
  const renderWarningMessage = () => {
    if (showWarningMessage && errorStatusCode) {
      return (
        <Message
          description={t(`errorStatusDescriptions:${t(errorDictionary(errorStatusCode))}`)}
          handleMessage={handleMessageAction}
          title={t(`errorStatusTitle:${t(errorDictionary(errorStatusCode))}`)}
          type="alert"
        />
      );
    }
    return '';
  };

  const renderConfirmToContinue = () => {
    if (confirmToContinueState)
      return (
        <div className="cnfirm-cmtmnt-render-to-confirm" data-test={confirmCommitmentConfirmToContinue}>
          <p>{`👆 ${t('mpConfirmCommitment:confirmError')}`}</p>
        </div>
      );
    return '';
  };

  const renderContentButtons = () => (
    <Button
      dataTest={confirmCommitmentConfirmButton}
      onClick={handleOnConfirm}
      size="small"
      styleClass="cnfirm-cmtmnt-confirm-button"
      type="secondary"
    >
      {t('mpFlowButtons:commit')}
    </Button>
  );

  const renderOpportunityLabel = () =>
    program_type === 1 ? t('mpConfirmCommitment:step3LabelProgram') : t('mpConfirmCommitment:step3LabelSpot');

  return (
    <div className="detail-commitment-container confirm-commitment-container" data-test={confirmCommitmentContainer}>
      <div className="opportunity-details-header">
        <h3 className="opportunity-details-header-title">
          <span className="neutral-color">{t('mpConfirmCommitment:step3')}</span>
          <span className="title-separator neutral-color">-</span>
          <span className="second-title">{renderOpportunityLabel()}</span>
        </h3>
      </div>
      <div className="cnfirm-cmtmnt-content">
        {/* Confirm */}
        <div className="cnfirm-cmtmnt-cntnt-confirm">
          <h3>{t('mpConfirmCommitment:acceptContract')}</h3>
          <div className="cntnt-confirm-description">
            {renderCheckBox()}
            <p className="cntnt-confirm-description-label">
              {t('mpConfirmCommitment:confirmIhaveRead')}
              <a href="" target="_blank" rel="noopener noreferrer">
                {t('mpConfirmCommitment:termsAndConditions')}
              </a>
              {t('mpConfirmCommitment:certificatesPresented')}
            </p>
          </div>
          {renderConfirmToContinue()}
          {renderContentButtons()}
        </div>
      </div>
      <div>{renderWarningMessage()}</div>
    </div>
  );
};

ConfirmCommitment.defaultProps = {
  testMode: false,
};

ConfirmCommitment.propTypes = {
  commitedBoxes: PropTypes.number.isRequired,
  getProgramsAction: PropTypes.func,
  programData: PropTypes.object.isRequired,
  programDetails: PropTypes.object.isRequired,
  selectedDestination: PropTypes.number.isRequired,
  selectedPackagingMaterial: PropTypes.number.isRequired,
  selectedProgram: PropTypes.number,
  setConfirmProgram: PropTypes.func.isRequired,
  cleanProgramsAction: PropTypes.func.isRequired,
  testMode: PropTypes.bool,
  unit: PropTypes.string.isRequired,
};

export default connect(() => ({}), {
  getProgramsAction,
  setConfirmProgram,
  cleanProgramsAction,
})(ConfirmCommitment);
