import React from 'react';
import { ArrowTop, ArrowBottom, DemandIcon, SystemUpdate, MarketIcon } from '../../assets/icons/assets';
import { latestUpdated, demanded, market } from '../../utils/page_definition/shipping_point_behavior';

/**
 * method to manage the logic for the increase/decrease icon
 * @param {String} td table data to fill with the sign type
 * @return {React.Element} return the element to render inside the td.
 */
export const renderTableIcon = (td, dataTest, customId) => {
  // object to manage the Icon {React.Element}
  const arrowPrice = {
    '1': (
      <ArrowTop styleClass="table-versus-behavior-icon" fillColor="#4cca16" dataTest={dataTest} customId={customId} />
    ),
    '-1': (
      <ArrowBottom
        styleClass="table-versus-behavior-icon"
        fillColor="#f02929"
        dataTest={dataTest}
        customId={customId}
      />
    ),
    '0': '',
  };
  return <div>{arrowPrice[Math.sign(td)]}</div>;
};

/**
 * method to manage the render of the element inside the td
 * @param {String} td table data to fill with the sign type
 * @return {React.Element} return the element to render inside the td.
 * if the td is NaN or 0 should render '---'
 */
export const renderTableData = (td) => {
  if (+td) return <div className="table-versus-behavior-percentage">{td}</div>;
  return <div>—</div>;
};

/*
 * method to render the table versus data
 * @param {object} data
 * @param {string} dataTest
 * @param {string} customId
 * @return {React.Element}
 */
export const renderTableVersus = (data, dataTest, customId) => (
  <div className="table-versus-behavior-price-versus" data-test={`${dataTest}-value`}>
    {renderTableData(data)}
    {renderTableIcon(data, dataTest, customId)}
  </div>
);

/**
 * method to render the table data.
 * @param {Date} report_date
 * @param {string} Optional demand_tone
 * @param {string} Optional market_tone
 * @return {React.Element}
 */
export const tableFooter = (
  report_date,
  demand_tone = false,
  market_tone = false,
  latestUpdate,
  demandLabel,
  marketLabel,
) => (
  <div className="table-date">
    <span>
      <SystemUpdate styleClass="table-date-calendar-icon" />
      <p data-test={latestUpdated}>
        {latestUpdate} <strong> {report_date}</strong>
      </p>
    </span>
    {demand_tone && (
      <span>
        <DemandIcon styleClass="table-date-states-icon" />
        <p data-test={demanded}>
          {demandLabel}:<strong>{demand_tone}</strong>
        </p>
      </span>
    )}
    {market_tone && (
      <span data-test={market}>
        <MarketIcon styleClass="table-date-states-icon" />
        <p>
          {marketLabel}:<strong>{market_tone}</strong>
        </p>
      </span>
    )}
  </div>
);

/*
 * method to render the crossing message
 * @param {string} msg to display
 * @param {string} crossing
 */
export const crossingMessage = (msg, crossing) => (
  <p>
    {msg}: <br />
    <strong>{crossing}</strong>
  </p>
);
