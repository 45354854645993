import { WeeklySerie } from './SeriesColors';

/**
 * HighChart options object for the CommodityImport Volume
 */
const VolumeOptions = {
  legend: {
    align: 'left',
    itemDistance: 35,
    itemStyle: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 2.4,
      color: '#4e5052',
    },
    squareSymbol: true,
    symbolWidth: 12,
    symbolHeight: 12,
    symbolRadius: 6,
    x: 33,
  },
  colors: WeeklySerie,
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
    allowDecimals: false,
    title: {
      text: 'Week Number',
    },
    plotLines: [
      {
        color: '#bca9f7',
        dashStyle: 'ShortDashDot',
        width: 2,
      },
    ],
  },
  yAxis: {
    title: {
      text: '',
    },
  },
  tooltip: {
    backgroundColor: '#313233',
    borderRadius: 16,
    crosshairs: true,
    pointFormat: `
      <div class="hc-volume-custom-tooltip">
        <span style="color:{point.color}">●</span>
        <div>
          <p> {series.name}:</p>
          <b>{point.y}</b>
        </div>
      </div>
    `,
    padding: 20,
    shared: true,
    style: {
      color: '#fff',
      fontSize: 14,
    },
    useHTML: true,
  },
  plotOptions: {
    series: {
      connectNulls: true,
      marker: {
        enabled: false,
        symbol: 'circle',
      },
    },
  },
};

const getVolumeOptions = (xText) => {
  const filledVolumeOptions = VolumeOptions;
  filledVolumeOptions.xAxis.title.text = xText;
  return filledVolumeOptions;
};

export default getVolumeOptions;
