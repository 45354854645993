import React from 'react';

const WarningCommitmentMessage = () => (
  <div>
    <p className="status-message-text">
      Al parecer tu entrega fue rechazada, <b>necesitas contactar al aquipo de FRIDA</b> para poder tomar acción sobre
      los siguientes pasos. Para contactar al equipo de FRIDA puedes usar los siguientes métodos:
    </p>
    <div className="status-message-contact">
      <div>
        <h5>Enviar correo electrónico</h5>
        <a className="link-to-mail-support-message" href="mailto:support@fridanet.com">
          Enviar mensaje
        </a>
      </div>
      <div>
        <h5>Télefono</h5>
        <a className="phone-tag" href="tel:331 287 8927">
          331 287 8927
        </a>
      </div>
    </div>
  </div>
);

export default WarningCommitmentMessage;
