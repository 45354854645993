import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckSmall } from '../assets/icons/assets';
import './Checkbox.scss';

/*
 * @prop {object} data received
 * @prop {func} handleCheckboxChange
 * @return {React.Element}
 */
const Checkbox = ({ dataTest, handleCheckboxChange, isActive, label, styleClass, value, idValue, disabled }) => {
  const [checked, setChecked] = useState(isActive);

  const toggleCheckboxChange = () => {
    handleCheckboxChange(!checked, idValue);
    setChecked(!checked);
  };

  return (
    <div className="fk-checkbox-container" disabled={disabled}>
      <span className={`fk-checkbox ${styleClass} checkbox-${checked}`}>
        <input
          checked={checked}
          className="fk-checkbox-input"
          data-test={dataTest}
          id={value}
          onChange={toggleCheckboxChange}
          type="checkbox"
        />
        <CheckSmall styleClass="fk-checkbox-check-icon" />
      </span>
      <label className="fk-checkbox-label" htmlFor={value}>
        {label}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  dataTest: '',
  disabled: false,
  idValue: '',
  isActive: false,
  label: '',
  styleClass: '',
};

Checkbox.propTypes = {
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  handleCheckboxChange: PropTypes.func,
  idValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  styleClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
};

export default Checkbox;
