import React from 'react';
import ProgramDelivery from './ProgramDelivery';
import PropTypes from 'prop-types';
import WebStorage from '../../../../../../utils/WebStorage/WebStorage';
import stringHandlers from '../../../../../../utils/common/stringHandlers';
import unitsHandler from '../../../../../../utils/marketplace/units/unitsHandler';
import { PalletIcon } from '../../../../../../assets/icons/assets';
import { timeFormat, GetProgramDate } from '../../../../../../utils/common/DateHandler';
import { useTranslation } from 'react-i18next';
import './MyOpportunityDetails.scss';

const MyOpportunityDetails = ({ programData, programDetails, unit }) => {
  const { t } = useTranslation(['mpMyProgramDetails', 'units', 'fruits', 'general', 'mpCommitment']);

  const { rawValue } = unitsHandler;
  const { boxes_pallet, pallets_truck, product_abbr, start_date, weeks } = programData;

  const {
    calibration,
    commitment_destination,
    commitment_details,
    is_organic,
    packaging_material,
    pallet_type,
    product_quality,
    pti_label,
  } = programDetails;

  const { getCookieValue } = WebStorage;
  const localeID = getCookieValue('localeId');

  const { capitalizeFirstLetter } = stringHandlers;

  const { program_type } = programData;

  const commitedBoxes = commitment_details.map((week) => week.find((days) => days.boxes > 0))[0].boxes;

  // unit value
  const unitValue = (unitValue) => rawValue(unitValue, commitedBoxes, pallets_truck, boxes_pallet).toLocaleString();

  /*
   * method to render the commodity type label
   */
  const renderCommodityType = () => (is_organic ? t('mpCommitment:organic') : t('mpCommitment:conventional'));

  const renderSaleType = () =>
    program_type === 1 ? t('mpMyProgramDetails:programDuration', { weeks }) : t('mpMyProgramDetails:oneExhibition');

  /*
   * @param {object} element to render by program_type
   * @param {number} programType to compare with program_type
   * @return {object || string}
   */
  const renderByProgramType = (element, programType) => (program_type === programType ? element : '');

  /*
   * TODO: use this as utils method
   * Methods to render the pti label
   */
  const renderPTILabel = (element) => (pti_label ? element : <></>);

  return (
    <div className="program-details-container">
      <div className="opportunity-details-header">
        <h3 className="opportunity-details-header-title">{t('title')}</h3>
      </div>
      <div className="oppotunity-details-content prog-details-content">
        {/* Boxes quantity */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpMyProgramDetails:quantityToDeliver')}</h4>
          <hr className="detail-prog-divisor" />
          {/* first row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* pallets quantity */}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:quantityPallets')}</h5>
              <span className="content-data-inner-raw">
                <PalletIcon styleClass="content-data-inner-icon" />
                <p className="content-data-inner-value spacing-left">{unitValue('pallet')}</p>
              </span>
            </div>

            {/* Type of sale */}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:saleType')}</h5>
              <span className="content-data-inner-raw">
                <p className="content-data-inner-value">{renderSaleType()}</p>
              </span>
            </div>
          </div>

          {/* second row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* Fruit */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:fruitTitle')}</h5>
              <span className="content-data-inner-raw">
                <p className="content-data-inner-value">{t(`fruits:${product_abbr}`)}</p>
              </span>
            </div>

            {/* fruit type */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:presentation')}</h5>
              <span className="content-data-inner-raw">
                <p className="content-data-inner-value">{renderCommodityType()}</p>
              </span>
            </div>
          </div>
        </div>

        {/* delivery place */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpMyProgramDetails:deliveryPlace')}</h4>
          <hr className="detail-prog-divisor" />
          {/* first raw */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* place */}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:placeLabel')}</h5>
              <span>
                <p className="content-data-inner-value">{commitment_destination.name}</p>
              </span>
            </div>

            {/* place direction */}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:deliveryAddres')}</h5>
              <span>
                <p className="content-data-inner-value">{commitment_destination.address}</p>
              </span>
            </div>
          </div>

          {/* second raw */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* place phone*/}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:phone')}</h5>
              <span>
                <a className="content-data-inner-value" href={`tel:${commitment_destination.phone}`}>
                  {commitment_destination.phone}
                </a>
              </span>
            </div>
          </div>
        </div>

        {/* delivery time */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpMyProgramDetails:deliveryTime')}</h4>
          <hr className="detail-prog-divisor" />
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* opportunity duration */}
            {renderByProgramType(
              <div>
                <h5 className="content-data-inner-title">{t('mpMyProgramDetails:opportunityDuration')}</h5>
                <span>
                  <p className="content-data-inner-value">{t('mpMyProgramDetails:programWeekDuration', { weeks })}</p>
                </span>
              </div>,
              1,
            )}

            {/* delivery date  */}
            {renderByProgramType(
              <div>
                <h5 className="content-data-inner-title">{t('mpMyProgramDetails:deliveryDate')}</h5>
                <span>
                  <p className="content-data-inner-value">{GetProgramDate(start_date, localeID)}</p>
                </span>
              </div>,
              2,
            )}

            {/* deliver before time*/}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:deliverBeforeTime')}</h5>
              <span>
                <p className="content-data-inner-value">{timeFormat(start_date)}</p>
              </span>
            </div>
          </div>
        </div>

        {/* commodity description */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpMyProgramDetails:fruitSpecifications')}</h4>
          <hr className="detail-prog-divisor" />
          {/* first raw */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* fruit quality */}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:fruitQuality')}</h5>
              <span>
                <p className="content-data-inner-value capitalize-label">{product_quality}</p>
              </span>
            </div>

            {/* minimun gauge */}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:minimumGauge')}</h5>
              <span>
                <p className="content-data-inner-value">{calibration}</p>
              </span>
            </div>
          </div>
        </div>

        {/* packaging materials */}
        <div className="content-data-wrapper">
          <h4 className="content-data-title">{t('mpMyProgramDetails:packingMaterial')}</h4>
          <hr className="detail-prog-divisor" />
          {/* first row */}
          <div className={`content-data-inner-wrapper-raw grid-t-col-${pti_label ? '3' : '2'}`}>
            {/* boxes per pallet */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:boxesPerPallet')}</h5>
              <span className="content-data-inner-raw">
                <p className="content-data-inner-value">{boxes_pallet}</p>
              </span>
            </div>

            {/* pallet type */}
            <div>
              <h5 className="content-data-inner-title">{t('mpDetailCommitment:palletType')}</h5>
              <span>
                <p className="content-data-inner-value">{capitalizeFirstLetter(pallet_type)}</p>
              </span>
            </div>

            {/* pti label */}

            {renderPTILabel(
              <div>
                <h5 className="content-data-inner-title">{t('mpMyProgramDetails:ptiLabel')}</h5>
                <span>
                  <p className="content-data-inner-value">{pti_label}</p>
                </span>
              </div>,
            )}
          </div>

          {/* second row */}
          <div className="content-data-inner-wrapper-raw grid-t-col-2">
            {/* material package */}
            <div>
              <h5 className="content-data-inner-title">{t('mpMyProgramDetails:packingSelectedMaterial')}</h5>
              <span>
                <p className="content-data-inner-value capitalize-label">{packaging_material.name}</p>
              </span>
            </div>
          </div>
        </div>

        {renderByProgramType(
          <ProgramDelivery programData={programData} programDetails={programDetails} unit={unit} />,
          1,
        )}
      </div>
    </div>
  );
};

MyOpportunityDetails.propTypes = {
  programData: PropTypes.object.isRequired,
  programDetails: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
};

export default MyOpportunityDetails;
