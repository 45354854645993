import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Select from 'components/Select';
import ProfileInfo from 'components/ProfileInfo';
import useProfileData from 'hooks/Profile/useProfileData';
import useCurrentDate from 'hooks/CurrentDate/useCurrentDate';
import unitsHandler from 'utils/marketplace/units/unitsHandler';
import { unitsValues } from 'utils/Select/unitsSelect';
import { setUnitSelect } from 'redux/actions/unitSelect/actions';
import ProductIcon from 'utils/product/ProductIcons';
import { marketplaceVersion } from '../../../../../package.json';
import './MarketHeaderCard.scss';

//TODO: helpBeforeText should change with i18n
const MarketHeaderCard = ({ infoMock }) => {
  const [units, setUnits] = useState([]);

  const activeProduct = useSelector((state) => state.products.active);
  const unit = useSelector((state) => state.unitSelected.unit);

  const { t, i18n } = useTranslation(['units', 'mpMarketHeaderCard', 'general']);
  const { unitIcons } = unitsHandler;

  const dispatch = useDispatch();

  let profile = useProfileData();
  const currentDate = useCurrentDate();

  if (infoMock) profile = infoMock;

  useEffect(() => {
    setUnits(unitsValues.map((e) => ({ ...e, label: t(`units:${e.value}`) })));
  }, [i18n.language, t]);

  const onSelectChange = (value) => {
    dispatch(setUnitSelect(value));
  };

  const renderProfileInfo = () => {
    if (profile && currentDate) return <ProfileInfo profile={profile} currentDate={currentDate} />;
    return '';
  };

  return (
    <div data-test="marketplace-container" className="market-header-card-container">
      <div className="mrkt-hdr-card-header">
        <div className="mrkt-hdr-card-header-inner-row">
          <div className="mrkt-hdr-card-header-inner-wrapper">
            <div className="mrkt-hdr-card-commodity-wrapper">
              {ProductIcon(activeProduct, 'mrkt-hdr-card-commodity-icon')}
            </div>
            <div>
              <h3>{t('mpMarketHeaderCard:title')}</h3>
              <p className="mrkt-hdr-card-version-wrapper">{marketplaceVersion}</p>
            </div>
          </div>
          <Select
            data={units}
            helpBeforeText={`${t('units:unit')}:`}
            icon={unitIcons(unit, 'mrkt-hdr-unit-select-icon')}
            id="mrkt-hdr-card-header-unit-select"
            setSelectedData={onSelectChange}
            styleClass="mrkt-hdr-unit-select"
          />
        </div>
      </div>
      <div className="mrkt-hdr-card-footer df">{renderProfileInfo()}</div>
    </div>
  );
};

MarketHeaderCard.propTypes = {
  infoMock: PropTypes.object,
};

export default MarketHeaderCard;
