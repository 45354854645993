import { bool } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoginModalForm from './Form';
import Modal from '../Modal';
import Button from '../../../components/Button';
import { NavbarMarketplace } from '../../../assets/icons/assets';
import './style.scss';

const ModalLogin = ({ noTransition = false }) => {
  const { t } = useTranslation('loginModal');

  return (
    <Modal
      className="modal-login"
      icon={<NavbarMarketplace width={48} height={48} />}
      focused={false}
      footer={
        <div className="login-modal__footer pt-m px-3xl pb-l">
          <h2 className="fs1 fw700 pb-m">{t('loginAsMarketerTitle')}</h2>
          <Button onClick={() => {}} type="disabled" shape="main" size="small" disabled={true}>
            {t('loginAsMarketerButton')}
          </Button>
        </div>
      }
      noTransition={noTransition}
    >
      <div className="modal-login__grid">
        <div>
          <p className="neutral-600">{t('welcomeTo')}</p>
          <h1 className="neutral-900 fs3 fw700">{t('heading')}</h1>
          <p className="modal-login__description neutral-900 fs-2 pt-m sm-hide xs-hide">{t('description')}</p>
        </div>
        <div className="modal-login__form">
          <div className="modal-login__form-content primary-50-bg br-l p-xl">
            <h2 className="primary-900 fs1 pb-l fw700">{t('loginAsProducer')}</h2>
            <LoginModalForm />
          </div>
        </div>
        <div>
          <div className="neutral-50-bg neutral-900 p-l br-l">
            <h2 className="fs1 pb-base fw700">{t('interestedInTitle')}</h2>
            <p className="fs-2 pb-m">{t('interestedInDescription')}</p>
            <a
              href="https://www.fridanet.com/#ContactForm"
              className="fk-button button-primary-complementary button-small fw600 tdn dib"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('interestedInCTA')}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalLogin.propTypes = {
  /**
   * If `true` the component will not show the onmount transition.
   * @deafult false
   */
  noTransition: bool,
};

export default ModalLogin;
