import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input from 'components/Input';
import WebStorage from 'utils/WebStorage/WebStorage';
import loginForm from 'utils/page_definition/login_form';
import utilsApi from 'utils/api/UtilsApi';
import { ROUTES } from 'constants/routes';
import { FridaLogoText } from 'assets/icons/assets';
import './LoginForm.scss';

const LoginForm = () => {
  const [errorLogin, setErrorLogin] = useState(false);
  const history = useHistory();

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const email = event.target['fk-email'].value;
    const password = event.target['fk-password'].value;

    if (email && password) {
      try {
        const res = await utilsApi.login(email, password);
        WebStorage.saveAccessCookie(res.data);

        const userRes = await utilsApi.getProfileInfo();
        WebStorage.setCookie('user_photo', userRes.data.photo, 86400 * 7);

        const redirectTo = history?.location?.state?.redirectAfterLogin;
        history.replace(redirectTo || ROUTES.MARKETPLACE);
      } catch (error) {
        if (error.response.status === 401) setErrorLogin(true);
      }
    }
  };
  return (
    <div className="login-form-container">
      <div className="lf-header">
        <div className="lf-header-title">
          <h5>Bienvenido a</h5>
        </div>
        <div className="lf-header-inner">
          <div className="lf-header-logo">
            <FridaLogoText styleClass="lf-logo" />
          </div>
          <div className="lf-header-forgot" />
        </div>
      </div>
      <div className="lf-title pb-base">
        <h1>Inicia sesión</h1>
      </div>
      <div data-test={`${loginForm.loginFormContainer}`} className="lf-content">
        <form className="lf-content-form" onSubmit={handleOnSubmit}>
          <Input
            dataTest={`${loginForm.inputEmail}`}
            error={errorLogin}
            id="fk-email"
            label="Correo electrónico"
            name="fk-email"
            placeholder="francisco@gmail.com"
            required={true}
            type="email"
          />
          <Input
            dataTest={`${loginForm.inputPassword}`}
            error={errorLogin}
            id="fk-password"
            label="Contraseña"
            name="fk-password"
            placeholder="*******"
            required={true}
            type="password"
          />
          <p className={`lf-content-form-error is-error-${errorLogin}`}>
            La contraseña o el correo electrónico que usaste no son válidos
          </p>
          <input
            data-test={`${loginForm.submitForm}`}
            type="submit"
            className="lf-c-form-submit-button"
            value="Iniciar Sesión"
          />
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
