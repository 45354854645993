import switchCaseHandler from '../../utils/common/switch';

const initialState = { unit: 'truck' };

const unitSelected = (state = initialState, action) => {
  const options = switchCaseHandler({
    SET_UNIT_SELECT: () => ({ ...state, unit: action.unit }),
  })(state);

  return options(action.type);
};

export default unitSelected;
