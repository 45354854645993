import { useEffect, useState } from 'react';
import UtilsApi from '../../utils/api/UtilsApi';
import profileData from '../../utils/profile/profileData';

const useProfileData = () => {
  const [profile, setProfile] = useState({});
  useEffect(() => {
    function getUserProfile() {
      UtilsApi.getProfileInfo()
        .then((res) => {
          if (res.data.photo) profileData(res.data.photo);
          setProfile(res.data);
        })
        .catch(() => {});
    }

    getUserProfile();
  }, []);

  return profile;
};

export default useProfileData;
