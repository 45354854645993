import React from 'react';

/*eslint-disable*/
const PalletIcon = ({ styleClass }) => (
  <svg className={styleClass} viewBox="0 0 16 16">
    <g id="icon-pallet-symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn-system-pallet">
        <rect id="Icon-viewport-pallet-icon" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="16" height="16"></rect>
        <path
          d="M16,11 L16,15 L12,15 L12,14 L10,14 L10,15 L6,15 L6,14 L4,14 L4,15 L0,15 L0,11 L16,11 Z M15,12 L1,12 L1,14 L3,14 L3,13 L7,13 L7,14 L9,14 L9,13 L13,13 L13,14 L15,14 L15,12 Z"
          id="Rectangle-pallet-icon"
          fill="#141414"
          fillRule="nonzero"
        ></path>
        <path
          d="M16,6 L16,10 L12,10 L12,9 L10,9 L10,10 L6,10 L6,9 L4,9 L4,10 L0,10 L0,6 L16,6 Z M15,7 L1,7 L1,9 L3,9 L3,8 L7,8 L7,9 L9,9 L9,8 L13,8 L13,9 L15,9 L15,7 Z"
          id="Rectangle-pallet-icon-2"
          fill="#141414"
          fillRule="nonzero"
        ></path>
        <path
          d="M16,1 L16,5 L12,5 L12,4 L10,4 L10,5 L6,5 L6,4 L4,4 L4,5 L0,5 L0,1 L16,1 Z M15,2 L1,2 L1,4 L3,4 L3,3 L7,3 L7,4 L9,4 L9,3 L13,3 L13,4 L15,4 L15,2 Z"
          id="Rectangle-pallet-icon-3"
          fill="#141414"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
);

export default PalletIcon;
